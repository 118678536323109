<div class="pearl-clock-wrapper">
  <div class="pearl-clock-center"></div>
  <div class="pearl-clock-hand" [style]="handStyle()"></div>
  <div class="pearl-clock-minutes" [class.active]="view() === 'minute'">
    @for (minute of minutes; track minute.value) {
      <div
        class="pearl-clock-cell"
        [class]="minute.class"
        [class.pearl-clock-cell-selected]="activeMinute() === minute.value"
        [class.pearl-clock-cell-hovered]="hoveredMinute() === minute.value"
        [style]="minute.style">
        {{ minute.displayValue }}
      </div>
    }
  </div>
  <div class="pearl-clock-hours" [class.active]="view() === 'hour'">
    @for (hour of hours; track hour.value) {
      <div
        class="pearl-clock-cell"
        [class.pearl-clock-cell-selected]="hour.value === activeHour()"
        [style]="hour.style">
        {{ hour.displayValue }}
      </div>
    }
  </div>
</div>
