import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { Observable, Observer, Subscription, firstValueFrom, retry, timer } from 'rxjs';
import pako from 'pako';

import { Config } from '../config/config';
import { FilterHelper } from '../filters/filter-helper';
import { getLocalRequestInfo, loadLocally } from './local-loader';
import { DataPoint, FailedResponse, GetQueryOptions, PostQueryOptions, QueryStringBuilderInputs, ResponseWithMetadata,
  ServerResponse, SuccessResponseWithData } from './data-loader.types';
import { CancellablePromise, DeepReadonly, DrawioDiagramRequestParams, EntityDefinition, EntityDefinitionParam,
  FiltersApplied, FiltersState, HeavyDataSeries, HeavyEndpointType, HeavyQuery, HeavyQueryBase, LinkEntitiesQuery,
  LinkEntitiesResponse, LinkedTableQuery, NumOrString, OptionValue, SomeEntity } from '../helpers/types';
import { ErrorService } from '../error-pages/error.service';
import { RefDataProvider } from './ref-data-provider';
import { isComplexAggregation, isSimpleAggregation } from '../graph/chart-types';
import { PersistentCacheService } from './persistent-cache.service';
import { InMemoryCacheService } from './in-memory-cache.service';
import { DataHelpers } from '../helpers/data-helpers';

@Injectable()
export class DataLoader {
  private config: Config;
  private http: HttpClient;
  private persistentCacheService: PersistentCacheService;
  private inMemoryCacheService: InMemoryCacheService;

  // Pending queries
  private pendingQueries: { [url: string]: CancellablePromise<any> } = {};
  private heavyQueries: { [requestorId: string]: string } = {};

  /**
   * Keys in HeavyQuery operation to be passed to the endpoint
   */
  private static HEAVY_KEYS_MAPPING: { [key: string]: string } = {
    'nullGroupTitle': '_ngt',
    'nullSplitTitle': '_nst',
    'configFiltersPath': '_filtersPath',
    'componentId': '_componentId',
    'tabFilterSelected': 'tabFilterSelected',
  };

  constructor(injector: Injector) {
    this.config = injector.get(Config);
    this.http = injector.get(HttpClient);
    this.persistentCacheService = injector.get(PersistentCacheService);
    this.inMemoryCacheService = injector.get(InMemoryCacheService);
  }

  public static buildQueryString(values: QueryStringBuilderInputs): string {
    const qsParts: string[] = [];

    for (const [key, value] of Object.entries(values)) {
      if (Array.isArray(value) && value.length > 0) {
        const arrayValue = value.join(',');
        qsParts.push(`${key}=${arrayValue}`);
      } else if (!Array.isArray(value) && value != undefined) {
        qsParts.push(`${key}=${value}`);
      }
    }

    return qsParts.join('&');
  }

  private static isFailedResponse(response: ServerResponse): response is FailedResponse {
    return (response as FailedResponse).success === false || (response as FailedResponse).__success === false;
  }

  private static isSuccessResponseWithData(response: ServerResponse): response is SuccessResponseWithData {
    return (response as SuccessResponseWithData).__success === true
      && (response as SuccessResponseWithData).data != null;
  }

  private static isResponseWithMetadata(response: ServerResponse): response is ResponseWithMetadata {
    return (response as ResponseWithMetadata).__hasMetaData === true;
  }

  /**
   * Cancel pending queries (including heavy ones)
   *
   * @return {void}
   */
  public cancelPendingRequests(): void {
    for (const url in this.pendingQueries) {
      /** We never want to cancel the search endpoint call. */
      if (url === this.config.appConfig.searchEndpoint) {
        continue;
      }

      /*
       * FIXME: `CancellablePromise.cancel` should trigger the `reject` method with an appropriate error
       * else the promise stays pending forever and `finally` is never executed ...
       */
      this.pendingQueries[url].cancel();
    }
    // ... so for the moment pendingQueries must be emptied manually
    this.pendingQueries = {};

    /*
     * Empty heavy queries ('cancel' prevents chain to execute 'finally')
     * FIXME: same...
     */
    this.heavyQueries = {};
  }

  /**
   * This function must be used to cancel promises.
   * @param promise the promise to cancel
   */
  public cancelRequest(promise: Promise<unknown>): void {
    if (this.isCancellablePromise(promise)) {
      promise.cancel();
    } else {
      console.warn("Can't cancel a non-cancellable promise.");
    }
  }

  private isCancellablePromise<T>(promise: Promise<T>): promise is CancellablePromise<T> {
    return (promise as any).cancel != null;
  }

  /**
   * Cancel pending queries and clear in-memory cache
   *
   * @return {void}
   */
  public cancelPendingRequestsAndClearCache(): void {
    // Cancel pending queries
    this.cancelPendingRequests();

    // Empty cache (queries only, not entities)
    this.inMemoryCacheService.clear();
  }

  /**
   * We declare multiple signatures for better typing. When forceUpdate is false, we can use a cached entry:
   * the return type is readonly, avoiding unfortunate mutations
   */
  public getOrPost<T>(
    basePath: string,
    queryParams: URLSearchParams,
    options?: { afterCancel?: () => void; forceUpdate?: false; timeToLive?: number },
  ): Promise<DeepReadonly<T>>;

  public getOrPost<T>(
    basePath: string,
    queryParams: URLSearchParams,
    options?: { afterCancel?: () => void; forceUpdate: true; timeToLive?: number },
  ): Promise<T>;

  /**
   * Will send a GET query to the provided endpoint, at the condition that the URL is small enough (<2000 chars).
   * Otherwise, will send a POST with the query params as request body. This is to circumvent URL size limitations,
   * but retain debug capabilities by keeping GET queries as much as possible, which are nicer to work with
   */
  public getOrPost<T>(
    basePath: string,
    queryParams: URLSearchParams,
    {
      afterCancel = null,
      forceUpdate = false,
      timeToLive = 3600,
    }: GetQueryOptions = {},
  ): Promise<T | DeepReadonly<T>> {
    const options = { afterCancel, forceUpdate, timeToLive };
    const queryParamsStr = queryParams.toString();
    if (queryParamsStr.length < 1900) return this.get<T>(`${basePath}?${queryParamsStr}`, options);
    return this.post<object, T>(basePath, Object.fromEntries(queryParams), { ...options, cacheResult: true });
  }

  /**
   * We declare multiple signatures for better typing. When forceUpdate is false, we can use a cached entry:
   * the return type is readonly, avoiding unfortunate mutations.
   * The postProcessFunction can be passed in case the endpoint result cannot be copied (too large)
   * and need some post-processing that will be cached (so no need to re-apply post processing on next data loader call)
   */
  public get<T>(
    url: string,
    options?: GetQueryOptions & { forceUpdate?: false },
  ): Promise<DeepReadonly<T>>;

  public get<T>(
    url: string,
    options?: GetQueryOptions & { forceUpdate?: false; postProcessFunction?: null },
  ): Promise<DeepReadonly<T>>;

  public get<T>(
    url: string,
    options: GetQueryOptions & { forceUpdate: true },
  ): Promise<T>;

  public get<T>(
    url: string,
    options?: GetQueryOptions,
  ): Promise<T>;

  public async get<T>(
    url: string,
    {
      afterCancel = null,
      forceUpdate = false,
      timeToLive = 3600,
      retryDelayProvider,
      retryCount = 0,
    }: GetQueryOptions = {},
  ): Promise<T | DeepReadonly<T>> {
    /*
     * In dev mode we can activate mocks, which means that we check if the requested url
     * is listed as mockable and in that case we will replace the URL by a mock file
     */
    if (this.config.devSettings?.mocksActive) {
      const endpointMocks = JSON.parse(localStorage.getItem('spinmocks'));
      for (const mockEndpointPrefix in endpointMocks) {
        if (url.startsWith(mockEndpointPrefix)) {
          url = endpointMocks[mockEndpointPrefix];
        }
      }
    }

    // Local request, load locally
    const localRequestInfos = getLocalRequestInfo(url);
    if (localRequestInfos !== null) {
      return new CancellablePromise<T>(async (resolve, reject) => {
        if (localRequestInfos.datasetName !== 'userVesselFleet') {
          await RefDataProvider.loadRefDataset(localRequestInfos.datasetName);
        }
        try {
          const result = loadLocally(url) as T;
          resolve(result);
        } catch (err: unknown) {
          reject(err as string);
        }
      });
    }

    if (
      this.config.devSettings?.disablePersistentCache !== true && await this.persistentCacheService.hasValidCache(url)
    ) {
      return this.persistentCacheService.retrieve<T>(url);
    }

    if (!forceUpdate && this.inMemoryCacheService.hasValidCache(url)) {
      return this.inMemoryCacheService.retrieve<T>(url);
    }

    // Promise & observable
    const promise = this.buildCancellablePromise<null, T>('GET', url, null, {
      afterCancel,
      retryDelayProvider,
      retryCount,
    });

    if (this.inMemoryCacheService.shouldCache(url)) {
      this.inMemoryCacheService.store(url, promise, timeToLive);
    }

    return promise;
  }

  /**
   * Handle requests error and send to Datadog
   *
   * FIXME: when `cancel` method will be adjusted to call `finally`, be sure
   * that this method is not called!
   *
   * @param {string}   url         Endpoint URL
   * @param {string}   error       Error message
   */
  private httpHandleError(
    url: string,
    error: string,
  ): void {
    ErrorService.datadogHandle({
      type: 'error',
      message: 'Error while loading endpoint',
      url,
      error: `[Dataloader](postProcessServerResponse): ${error}`,
    });
  }

  /**
   * Format response & resolve (or reject with error)
   * Note: the response data is stored in persistent cache here because it's the place where metadata like serverTime
   * are available.
   *
   * @param  {ServerResponse} response    Endpoint result
   * @param  {Function}       resolve     Resolve
   * @param  {Function}       reject      Reject
   * @param  {string}         url         Endpoint URL
   * @return {void}
   */
  private postProcessServerResponse(
    response: ServerResponse,
    resolve: (x: unknown) => void,
    reject: (x: string) => void,
    url: string,
  ): void {
    if (response && DataLoader.isFailedResponse(response)) {
      // if the user specified some action to be done when an error occurs
      const errorMessage = response.error ?? response.message;
      this.httpHandleError(url, errorMessage);
      reject('Error while loading endpoint: ' + errorMessage);
      return;
    }

    if (DataLoader.isResponseWithMetadata(response)) {
      /*
       * __getters contains small JS methods allowed to be executed when receiving the data.
       * They are received as strings and are compiled into callable objects using the Function constructor.
       */
      if (response.__getters) {
        for (const getter in response.__getters) {
          const getterText = response.__getters[getter];
          if (typeof getterText !== 'string') {
            console.error('__getters in received response with mapping should be of type string[]');
            continue;
          }
          response.__getters[getter] = Function('value', 'return ' + getterText);
        }
      }

      this.postProcessDataset(response);

      if (this.config.devSettings?.disablePersistentCache !== true && this.persistentCacheService.shouldCache(url)) {
        this.persistentCacheService.addToStoringQueue(url, response);
      }

      resolve(response.data);
      return;
    }

    if (response && DataLoader.isSuccessResponseWithData(response)) {
      resolve(response.data);
      return;
    }

    resolve(response);
    return;
  }

  public async loadUserVesselFleet(): Promise<void> {
    const userVesselFleetIds = await this.get<number[]>('/base/filters/user-fleet-specs', { forceUpdate: true })
      .catch(() => {
        console.info('Error while getting the user vessel fleet specs');
        return [];
      });
    RefDataProvider.restrictCurrentFleet(userVesselFleetIds);
  }

  public async clearCacheAndReloadUserVesselFleetSpecs(): Promise<void> {
    this.cancelPendingRequestsAndClearCache();
    await this.loadUserVesselFleet();
  }

  public getText(url: string): Promise<string> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'text/html, application/xhtml+xml, */*',
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      responseType: 'text' as any,
    };
    const observable = this.http.get<string>(url, httpOptions);
    return firstValueFrom<string>(observable).catch(() => {
      throw new Error(`Disconnected or bad URL: ${url}`);
    });
  }

  /**
   * @description Creates a cancellable promise encapsulating a HttpClient.get or HttpClient.post request.
   * Cancelling the returned promise will automatically cancel the underlying http request.
   */
  private buildCancellablePromise<K, T>(
    method: 'GET' | 'POST',
    url: string,
    data: K,
    {
      afterCancel = null,
      gzipped = false,
      retryDelayProvider,
      retryCount = 0,
    }: PostQueryOptions & GetQueryOptions,
  ): CancellablePromise<T> {
    // Keep reference of the request observable and subscription for later cancellation
    let requestObservable: Observable<T>;
    let requestSubscription: Subscription;

    const cancellablePromise: CancellablePromise<T> = new CancellablePromise<T>((resolve, reject) => {
      requestObservable = this.buildObservable(method, url, data, { gzipped, retryDelayProvider, retryCount });

      const requestObserver: Partial<Observer<T>> = {
        next: (response: T) => {
          if (response === null) {
            resolve(null);
            return;
          }
          const result = response as ServerResponse;
          this.postProcessServerResponse(result, resolve, reject, url);
        },
        error: (err: any) => {
          if (err.status === 403 || err.status === 401) {
            window.location.href = '/';
          }

          this.httpHandleError(url, err.message);
          reject('Error while loading endpoint: ' + err.message);
        },
      };

      requestSubscription = requestObservable.subscribe(requestObserver);
    });

    // Add get request to pending queries until resolved or cancelled
    if (method === 'GET') {
      this.pendingQueries[url] = cancellablePromise;
      cancellablePromise.finally(() => {
        delete this.pendingQueries[url];
      });
    }

    cancellablePromise.cancel = (): void => {
      // Unsubscribe from HttpClient.<get|post> subscription, which automatically cancels the underlying http request
      requestSubscription.unsubscribe();

      // If GET request, also remove it from pending queries as 'finally' will never be called
      if (method === 'GET') {
        delete this.pendingQueries[url];
      }

      if (afterCancel) {
        afterCancel();
      }
    };

    return cancellablePromise;
  }

  private buildObservable<T, K = null>(
    method: 'GET' | 'POST',
    url: string,
    data: K,
    {
      gzipped = false,
      retryCount = 0,
      retryDelayProvider,
    }: PostQueryOptions & GetQueryOptions,
  ): Observable<T> {
    let requestObservable: Observable<T>;
    const fullUrl = this.config.completeUrl(url);

    if (method === 'GET') {
      requestObservable = this.http.get<T>(fullUrl);
    } else {
      if (gzipped) {
        let headers = new HttpHeaders();
        headers = headers.append('Content-Encoding', 'gzip');
        headers = headers.set('Content-Type', 'application/json');

        const options = { headers };
        const compressedBody = pako.gzip(JSON.stringify(data)).buffer;
        requestObservable = this.http.post<T>(fullUrl, compressedBody, options);
      } else {
        requestObservable = this.http.post<T>(fullUrl, data);
      }
    }

    return requestObservable.pipe(
      retry({
        delay: (error: HttpErrorResponse, count: number) => {
          if (error.status === 401 || error.status === 403) {
            window.location.href = '/';
            throw error;
          }
          if (retryDelayProvider) return retryDelayProvider(error, count);
          if (count > retryCount || error.status !== 500) throw error; // get out of the retries
          // exponential step-back maxing out at 1 minute.
          return timer(Math.min(60000, 2 ** count * 1000));
        },
      }),
    );
  }

  public getObservable<T>(
    url: string,
    {
      retryCount = 0,
      retryDelayProvider,
    }: GetQueryOptions = {},
  ): Observable<T> {
    return this.buildObservable<T>('GET', url, null, { retryCount, retryDelayProvider });
  }

  public postObservable<T, K>(
    url: string,
    data: K,
    {
      gzipped = false,
      retryCount = 0,
      retryDelayProvider,
    }: PostQueryOptions = {},
  ): Observable<T> {
    return this.buildObservable<T, K>('POST', url, data, { gzipped, retryCount, retryDelayProvider });
  }

  /**
   * Get cancellable POST request promise
   *
   * @param  {string}   url          Endpoint URL
   * @param  {K}        data         Payload
   * @param  {PostQueryOptions} options optional params
   * @return {Promise<T>}
   */
  public post<K, T>(
    url: string,
    data: K,
    { afterCancel, gzipped, cacheResult, retryDelayProvider }: PostQueryOptions = { cacheResult: true },
  ): Promise<T> {
    const urlHash = url + DataHelpers.generateHashFromObject(data);

    // Use cache mechanism if cacheResult is true
    if (cacheResult && this.inMemoryCacheService.hasValidCache(urlHash)) {
      return this.inMemoryCacheService.retrieve<T>(urlHash);
    }

    // Promise & observable
    const promise = this.buildCancellablePromise<K, T>(
      'POST',
      url,
      data,
      {
        gzipped,
        afterCancel,
        retryDelayProvider: retryDelayProvider,
      },
    );

    if (cacheResult && this.inMemoryCacheService.shouldCache(url)) {
      this.inMemoryCacheService.store(urlHash, promise, 60); // 1 minute cache
    }

    return promise;
  }

  public postFile<K>(url: string, data: FormData): Promise<K | void> {
    const observable = this.http.post<K>(url, data);
    return firstValueFrom<K | void>(observable).catch((error: HttpErrorResponse) => {
      if (error.status === 403 || error.status === 401) {
        window.location.href = '/';
      } else {
        throw new Error(`Disconnected or bad URL: ${url}`);
      }
    });
  }

  get linkEntitiesUrl(): string {
    return '/base/db/link-entities';
  }

  public async linkEntities(query: LinkEntitiesQuery): Promise<LinkEntitiesResponse> {
    return this.post<LinkEntitiesQuery, LinkEntitiesResponse>(this.linkEntitiesUrl, query);
  }

  updateOrderUrl(entityName: string): string {
    return `/base/db/update/order/${entityName}`;
  }

  updateUrl(entityName: string): string {
    return `/base/db/update/${entityName}`;
  }

  deleteUrl(entityName: string): string {
    return `/base/db/delete/${entityName}`;
  }

  updateCollectionUrl(entityName: string): string {
    return `/base/db/updateCollection/${entityName}`;
  }

  entitiesDataUrl(entityName: string): string {
    return `/base/db/table/${entityName}`;
  }

  tableDefinitionUrl(entityName: string): string {
    return '/base/db/definition/table/' + entityName;
  }

  public static drawioDiagramUrl(drawioParams: DrawioDiagramRequestParams): string {
    let url = '/spindjango/reporting/config/';
    if (drawioParams.cloudUrl) {
      url += 'compare-diagram?';
    } else {
      url += 'create-diagram?';
    }
    url += Object.keys(drawioParams).map(function(k) {
      return encodeURIComponent(k) + '=' + encodeURIComponent(drawioParams[k]);
    }).join('&');
    return url;
  }

  get posibleValuesUrl(): string {
    return '/base/db/linked-collection-values';
  }

  uploadFileUrl(fileName: string): string {
    return `/spindjango/upload-file/${fileName}`;
  }

  public async getPossibleValues(query: LinkedTableQuery): Promise<OptionValue[] | SomeEntity[]> {
    return this.post<LinkedTableQuery, OptionValue[] | SomeEntity[]>(this.posibleValuesUrl, query);
  }

  entityDefinitionUrl(entityName: string, forCreation: boolean): string {
    const rootUrl = '/base/db/definition';
    return forCreation ? `${rootUrl}/create/${entityName}` : `${rootUrl}/edit/${entityName}`;
  }

  entityDataUrl(entityName: string, entity: SomeEntity): string {
    const entityId = entity['id'];
    let entityUrl = `/base/db/load/${entityName}/${entityId}`;
    /*
     * if we have vesselId as part of the data-object, we will add it to the query
     * for sharded entities, vesselId is required to load the entity from the correct shard
     */
    if ('vesselId' in entity) {
      entityUrl += `?vesselId=${entity['vesselId'] as string}`;
    }
    return entityUrl;
  }

  public async getTable(entity: string): Promise<SomeEntity[]> {
    return this.get<SomeEntity[]>(this.entitiesDataUrl(entity), { forceUpdate: true });
  }

  public async getTableDefinition(entity: string): Promise<DeepReadonly<EntityDefinition>> {
    return this.get<EntityDefinition>(this.tableDefinitionUrl(entity));
  }

  public getEntityDefinition(
    entityName: string,
    forCreation: boolean,
  ): Promise<EntityDefinition> {
    return this.get<EntityDefinitionParam>(this.entityDefinitionUrl(entityName, forCreation), { forceUpdate: true })
      .then(res => new EntityDefinition(res));
  }

  public getEntity(
    entityName: string,
    entity: SomeEntity,
  ): Promise<SomeEntity> {
    return this.get<SomeEntity>(this.entityDataUrl(entityName, entity), { forceUpdate: true });
  }

  public updateEntity(
    entityName: string,
    dto: SomeEntity,
  ): Promise<unknown> {
    return this.post(this.updateUrl(entityName), dto);
  }

  public async getEntitiesWithIds(entityName: string, ids: string[] | number[]): Promise<SomeEntity[]> {
    const url = this.entitiesDataUrl(entityName) + '?ids=' + ids.join(',');
    return this.get<SomeEntity[]>(url, { forceUpdate: true });
  }

  /**
   * Query heavy analytics endpoints
   */
  public queryHeavyEndpoint<T = HeavyDataSeries>(query: HeavyQuery): Promise<DeepReadonly<T>> {
    /*
     * split and groupby are both passed to the _g option to heavy analytics
     * heavy analytics supports grouping by one value only
     * we inject the _groupby value only in case there is not one already specified in the url.
     * that can be a case for charts without grouping where the group is specified in the config
     */
    if (!query.searchParams.has('_g')) {
      const groupByValue = (query.group !== undefined ? query.group + ',' : ',') + (query.split ? query.split : '');
      query.searchParams.set('_g', groupByValue);
    }

    // Serialize operation
    const op = query.operation;
    if (isSimpleAggregation(op)) {
      // Pass variable (_c for /column/) and operation (_m for /mode/) if not already set
      if (op.variable && !query.searchParams.has('_c')) {
        query.searchParams.set('_c', op.variable);
      }
      if (op.operation && !query.searchParams.has('_m')) {
        query.searchParams.set('_m', op.operation);
      }
    } else if (isComplexAggregation(op)) {
      // Directly pass serialized operation, will be passed by the endpoint
      query.searchParams.set('_cmplx', op.serialized);
    } else {
      // Pass all non-null parameters of the nested operation
      for (const key in op) {
        if (op[key]) query.searchParams.set(key, op[key]);
      }
    }

    // Append options
    for (const key in query) {
      const queryName = DataLoader.HEAVY_KEYS_MAPPING[key];
      if (queryName && query[key] && !query.searchParams.has(queryName)) {
        query.searchParams.set(queryName, query[key]);
      }
    }

    DataLoader.completeHeavyQueryWithFilterState(query);
    const url = `${query.baseUrl}?${query.searchParams.toString()}`;
    /*
     * If query is not finished, cancel it, and force a fresh request be deleting cache entry
     * This is to cancel ongoing queries from the same component+series that did not return yet
     */
    if (query.requestorId in this.heavyQueries) {
      // Cancel and delete ongoing query
      const prevUrl = this.heavyQueries[query.requestorId];
      if (this.pendingQueries[prevUrl]) {
        /*
         * Cancel (and delete from pending list)
         * FIXME: @see above, `CancellablePromise.cancel` should trigger `reject` then `finally` (and clear cache)
         */
        this.pendingQueries[prevUrl].cancel();
        // ...and delete cache entry to avoid serving cancelled query from cache
        this.inMemoryCacheService.delete(prevUrl);
      }
      // Remove from heavy queries list
      delete this.heavyQueries[query.requestorId];
    }
    let promise: Promise<DeepReadonly<T>>;
    /** Send GET or POST query for analytics (based on URL size), but always GET for heavy-custom (for now) */
    if (query.endpointType === 'heavy') {
      promise = this.getOrPost<T>(query.baseUrl, query.searchParams);
    } else {
      promise = this.get<T>(url);
    }
    this.heavyQueries[query.requestorId] = url;
    // When the call responds, delete from heavyQueries, meaning that there is no pending query from requestor
    promise.finally(() => {
      if (this.heavyQueries[query.requestorId] === url) {
        delete this.heavyQueries[query.requestorId];
      }
    });

    return promise;
  }

  /**
   * @description This method iterate over filtersState to get only the filters to apply to the query
   *              It parse the filtersState array and add the wanted key/value pairs inside a new object
   *
   * @param filtersState  component filtersState
   * @param filtersConfig config details of each filtersState
   * @param endpointType  type of endpoint, alter reduce behavior
   *
   * @returns The filtersState with only ones to apply
   */
  public static getFiltersStateToApplyForHeavyQuery(
    filtersState: FiltersState,
    filtersConfig: FiltersApplied,
    endpointType: HeavyEndpointType,
  ): FiltersState {
    if (!filtersState) return {};

    const filtersToApply: FiltersState = {};

    for (let filterId in filtersState) {
      const filterValue = filtersState[filterId];
      const filterConfig = filtersConfig?.[filterId];
      if (!filterConfig) continue;
      if (filterConfig.prop) {
        /** If we have "prop", update the filterId with it */
        filterId = filterConfig.prop;
      } else if (endpointType === 'heavy' && !FilterHelper.fieldIdIsForRefDataChaining(filterId)) {
        /**
         * heavy analytics filter needs prop. If there is no prop, it will give nodata
         * this can happen on dashboards with mix of heavy and light charts, where light charts can be filtered
         * on some filters but heavy cannot - in such case the heavy chart should show "nodata"
         * this rule applies only for standard "heavy" charts. For "heavy-custom" charts we pass all filters.
         * An exception exists for ref data chaining, sending all ID of dataset, when the filterId ends with "Ids"
         */
        console.warn(
          'Heavy analytics filters need prop, otherwise the query is not passed to server: ' + filterId,
        );
        return filtersToApply;
      }
      // if the filter is a checkbox with a false value we don't want to filter with this value
      if (FilterHelper.hasCheckboxBehavior(filterConfig.filterType) && !filterValue[0]) {
        continue;
      } // if the filter is a multi with a empty list, we don't want to filter
      else if (filterConfig.filterType === 'multi' && filterValue && filterValue.length === 0) {
        continue;
      } // if the filter is a doubledate/intersection with a null value we don't want to filter
      else if (
        FilterHelper.isEraFilterType(filterConfig)
        && (!filterValue || (filterValue.length === 1 && !filterValue[0]))
      ) {
        continue;
      }
      filtersToApply[filterId] = filterValue;
    }
    return filtersToApply;
  }

  /**
   * @description This method aims to add relevant query.filtersState to query params (i.e query.searchParams).
   *              Get relevant params using the function DataLoader.getFiltersStateToApplyForHeavyQuery()
   *
   * @param query
   */
  public static completeHeavyQueryWithFilterState<T extends HeavyQueryBase>(query: T): void {
    const filters = DataLoader.getFiltersStateToApplyForHeavyQuery(
      query.filtersState,
      query.filterConfig,
      query.endpointType,
    );

    for (const filterId in filters) {
      const filterValue = filters[filterId];
      query.searchParams.set(filterId, filterValue.join(','));
    }
  }

  public postProcessDataset(result: ResponseWithMetadata): void {
    const data = (Array.isArray(result.data) ? result.data : Object.values(result.data)) as DataPoint[];
    if (data) {
      data.forEach(d => this.postProcessDataPoint(d, result));
    }
  }

  /**
   * Post process provided data element, by settings additional in it according to the response metadata
   *
   * @param  {DataPoint}            d             data element
   * @param  {ResponseWithMetadata} response      Endpoint response with metadata
   */
  private postProcessDataPoint(d: DataPoint, response: ResponseWithMetadata): void {
    /*
     * /!\ SFM ONLY /!\
     * If projectIds is null, it means accessible to all
     * If contains projectIds, only accessible for those projects
     * We do this in order to be able to filter the same way as if those information were returned by the endpoint
     */
    const projectIds = d.projectIds as number[];
    if (response.__injectOsvProjects && projectIds !== undefined && RefDataProvider.config.product === 'osv') {
      const accessible = RefDataProvider.config.userInfo.accessibleOsvProjects;
      if (projectIds === null) {
        d.projectIds = accessible.map(osvProject => osvProject.id);
        d.projects = accessible.map(osvProject => osvProject.title);
        d.projectTooltip = 'All projects';
      } else {
        d.projects = accessible.filter(project => projectIds.includes(project.id)).map(p => p.title);
        d.projectTooltip = d.projects;
      }
    }

    if (response.__injectToAll) {
      for (const toBeInjected in response.__injectToAll) {
        d[toBeInjected] = response.__injectToAll[toBeInjected];
      }
    }

    // Inject mapping information if provided
    if (response.__propertyToEntityMappings) {
      d.__propertyToEntityMappings = response.__propertyToEntityMappings;
    }

    if (response.__knotsToMs) {
      for (const msField in response.__knotsToMs) {
        const knotsField = response.__knotsToMs[msField];
        const knotsValue = d[knotsField] as number;

        if (knotsValue) {
          d[msField] = knotsValue * 0.514444;
        }
      }
    }

    if (response.__additionalMappings) {
      for (const injectedField in response.__additionalMappings) {
        const propForInjection = response.__additionalMappings[injectedField].prop;
        const foundValue = response.__additionalMappings[injectedField].dataset[d[propForInjection] as NumOrString];
        if (foundValue) {
          d[injectedField] = foundValue.title;
        }
      }
    }

    if (response.__getters) {
      for (const getter in response.__getters) {
        const currentGetter = response.__getters[getter];

        if (typeof currentGetter === 'string') {
          console.error('Getter string should have already been transformed into a function.');
          continue;
        }

        /*
         * getter is a function directly applied on the value in dataset
         * the alternative would be to execute the getter on the fly
         * memory-wise it might be a bit better (depending on the result of the getter)
         * but from perf point of view, it will be evaluated multiple times
         */
        d[getter] = currentGetter(d);
      }
    }
  }
}
