import { Component, computed, HostListener, Input, input, model, signal } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';

import { PearlIconComponent } from '../../icons/pearl-icon.component';
import { FormField } from '../form-field';
import { PearlButtonComponent } from '../../buttons/pearl-button.component';

type FormHooks = 'change' | 'blur' | 'submit';
type FormOptions = {
  name?: string;
  standalone?: boolean;
  updateOn?: FormHooks;
};

@Component({
  selector: 'pearl-number',
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, PearlIconComponent, PearlButtonComponent, FormsModule],
  templateUrl: './pearl-number.component.html',
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
      appearance: 'outline',
    },
  }],
  host: { '[class.hide-buttons]': 'hideButtons()' },
  styleUrls: ['./pearl-number.component.scss', '../pearl-form-field.component.scss'],
})
export class PearlNumberComponent extends FormField {
  @HostListener('keydown', ['$event'])
  updateValue(event: KeyboardEvent): void {
    switch (event.key) {
      case 'ArrowUp':
        this.onIncreaseDecreaseButtonClick(event, 'asc');
        break;
      case 'ArrowDown':
        this.onIncreaseDecreaseButtonClick(event, 'desc');
        break;
      default:
        break;
    }
  }

  public readonly disabled = input(false);
  public readonly required = input(false);
  public readonly updateOn = input<FormHooks>('change');
  public readonly step = input(1);

  public readonly hideButtons = signal(true);

  @Input()
  set hideNumberButtons(hideNumberButtons: boolean) {
    this.hideButtons.set(typeof hideNumberButtons === 'undefined' || hideNumberButtons);
  }

  public readonly ngModelOptions = computed<FormOptions>(() => ({
    standalone: true,
    updateOn: this.updateOn(),
  }));

  public readonly buttonSize = computed(() => this.small() ? 'small' : 'default');

  private interval: null | number = null;
  private iteration = 0;

  public readonly value = model<number | null>(null);

  public onIncreaseDecreaseButtonClick(event: Event, direction: 'asc' | 'desc', step = this.step()): void {
    // Prevent keyboard opening on mobile
    event.stopPropagation();
    event.preventDefault();
    const round = direction === 'asc' ? Math.floor : Math.ceil;
    this.value.set(round(this.value()) + (direction === 'asc' ? step : -step));
  }

  public touchStart(event: Event, direction: 'asc' | 'desc'): void {
    let currentStep = this.step();
    this.interval = setInterval(() => {
      this.onIncreaseDecreaseButtonClick(event, direction, currentStep);
      this.iteration++;

      // Every half second, increase the step size
      if (this.iteration % 5 === 0) currentStep += this.step();
    }, 100);
  }

  public touchEnd(): void {
    clearInterval(this.interval);
    this.iteration = 0;
  }

  public isNumericCharacter(event: KeyboardEvent): boolean {
    return /-|\.|[0-9]|,/.test(event.key);
  }

  public setNumber(event: string | number): void {
    let cleanValue = event.toString().replace(',', '.').replace(/[^\d.-]/g, '');

    // Only keep the first occurrence of "."
    const firstDotIndex = cleanValue.indexOf('.');
    if (firstDotIndex > -1) {
      cleanValue = cleanValue.substring(0, firstDotIndex + 1)
        + cleanValue.substring(firstDotIndex + 1, cleanValue.length).replaceAll('.', '');
    }

    // We want the parsed value to be null in case clean value is an empty string, but Number("") return 0
    const parsedValue = cleanValue === '' || isNaN(Number(cleanValue)) ? null : cleanValue;
    this.value.set(parsedValue !== null ? Number(parsedValue) : null);
  }
}
