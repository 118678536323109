import * as WeatherLayers from 'weatherlayers-gl';

import { BaseComponentSettings, BaseEndpointPattern, BaseEndpointType, Button, Color, ComponentSettings,
  ConditionedMessage, DashboardState, DatabaseEntity, DatabaseOverview, DateTimezone, EntityAction, EntityButton,
  EntityFieldDefinition, EntityTableCategory, Era, Feature, FieldSettings, Fieldset, HistoricalModeStyle, LayerId,
  LayerTooltipSettings, LayerType, MenuItem, PlaybackMode, ScheduleLayerFiltering, SearchEngineConfig, SpecName,
  SubscriptionListTypeConfig, SummaryType, TableType, TilesLayerOptions } from './types';
import { SelectorConfig } from '../selector/selector.types';
import { AdditionalProperty, PageLinkSettings, PunctualShapeSettings, RigAvailabilitySettings, ScheduleFillConfig,
  ScheduleGraphSettings, ScheduleShape } from '../schedule/schedule-types';
import { ChartComponentSettings, FilterMetric, SelectableValue } from '../graph/chart-types';
import { DrawingOrderBy, MapMenuSettings } from './map-types';
import { ColorDefinition, LayerLegendConfig } from './legend-types';
import { RefDataConfigs } from '../data-loader/ref-data.types';
import { PearlIcon } from '../shared/pearl-components';
import { PhaseFieldSettings } from '../pages/phase-details.types';

/**
 * The basic type for any layer configuration, MapLayerSettings and ScheduleLayerSettings
 * are the most notable types that extends this type
 */
export interface LayerSettings {
  id: LayerId;
  filename?: string;
  defaultPeriod?: Era;
  fieldsets?: Fieldset[];
  /** Layer visible (state) */
  visible?: boolean;
  /** Layer URL (either data or tiles for map) */
  url?: string;
  title: string;
  /** Calculated length of data (state) */
  total?: number;
  /** Determine if the number of elements is shown in the sidebar. True by default  */
  showCount?: boolean;
  toggleColor?: Color;
  dialog?: any;
  vessel?: FieldSettings;
  heavy?: boolean;
  liveLayer?: boolean;
  mainLayer?: boolean;
  fields?: EntityFieldDefinition[];
  checkable?: boolean;
  hideSidebarLayer?: boolean;
  fixedColorBy?: {
    variable: string;
    colors: ColorDefinition[];
  };
  selector?: SelectorConfig;
  entityFilterId?: string;
  conditionedMessage?: ConditionedMessage;
  punctualShapes?: PunctualShapeSettings[];
  specificFilters?: { [propId: string]: string[] };
  drawingLayerId?: LayerId;
  drawingLayerTitle?: string;
  partialLoading?: boolean;
  vesselOrderUrl?: string;
  rangeUrl?: string;
  heavyDataUrl?: string;
  ignoreSelectFilter?: boolean;
  alwaysVisible?: boolean;
  locLabel?: string;
  tooltip?: LayerTooltipSettings;
  legend?: LayerLegendConfig;
  link?: {
    url: string;
    text: string;
  };
  buttons?: Button[];
  /** For schedule only - If true, the layer's data is updated every time the schedule interval is modified */
  updateWithInterval?: boolean;

  /** Order drawing of elements by a list of fields. There is at least an ordering on area. */
  drawingOrderBy?: DrawingOrderBy;
}

/**
 * Map layer config
 */
export interface MapLayerSettings extends LayerSettings {
  /** If not defined, is "geometries" type */
  layerType?: LayerType;
  z?: number;
  /** 1 or 2 */
  labelPriority?: number;
  /** If true, will be shown by default on the map */
  default?: boolean;
  /** Minimal zoom for which we will begin rendering labels */
  zoomMin?: number;
  tilesLayerOptions?: TilesLayerOptions;
  /** If true, this layer will be excluded from autofit calculations (when landing on the map) */
  excludedFromAutofit?: boolean;
  /** Used in VesselManager. Required in MapLayerSettings for config validator that uses this file to generate schema */
  endpointConfig?: object;
  /** True for showing a point associated with a shape (an entity must contain a geometry + lat/long)*/
  showPointAlongsideShape?: boolean;
  /**
   * If undefined, disable default behavior to show a checkbox to hide the geometry (only applicable if
   * showPointAlongsideShape = true)
   */
  hideShapeLabel?: string;
  /** If set to true and if hideShapeLabel is set, the checkbox to hide the geometry will be checked by default */
  hideShapeDefault?: boolean;
  /** If true, disabled the possibility for the user to switch from a layer mode to another one */
  switchModeDisabled?: boolean;
  /**
   * If defined, this layer will be reloaded every X milliseconds.
   * On OSV, this number is not taken into account for latest position layer and ETA layer (it will always be 60s)
   */
  autoReloadTimer?: number;

  /** ====== Vessel / rig layers only ======= */
  /** The group this layer belongs to (as in "radio button group"). Typically 'vessels' / 'rigs' */
  layerGroup?: string;
  historicalModeStyle?: HistoricalModeStyle;

  /** ====== Historical positions only ======= */
  /** Max number of traces */
  maxTraces?: number;
  playmodeLabel?: string;
  activityUrl?: string;
  activityProp?: string;
  activityColors?: ColorDefinition[];
  arrowActivities?: number[];
}

/**
 * Union type for all available dashboard without layers
 */
type DashboardWithoutLayersTypes =
  | 'generic'
  | 'dpr-report'
  | 'dpr-selected-vessel'
  | 'subscription'
  | 'comparator'
  | 'windfarm-simulation';
export type DashboardWithoutLayerSettings =
  | DashboardGenericSettings
  | DprReportSettings
  | SelectedVesselSettings
  | SubscriptionDashboardSettings
  | ComparatorDashboardSettings
  | WindFarmSimulationSettings;

/**
 * Union type for all available dashboard *with* layers
 */
type DashboardWithLayersTypes =
  | 'daily'
  | 'database'
  | 'map'
  | 'quality-check'
  | 'schedule'
  | 'search-engine'
  | 'search-specs'
  | 'voyage-optimizer';
export type DashboardWithLayersSettings =
  | DailyDashboardSettings
  | DatabaseDashboardSettings
  | MapDashboardSettings
  | QualityCheckDashboardSettings
  | ScheduleDashboardSettings
  | SearchEngineDashboardSettings
  | SearchSpecsDashboardSettings
  | VoyageOptimizerDashboardSettings;

export type DashboardSettings =
  | DashboardWithoutLayerSettings
  | DashboardWithLayersSettings
  | MultiComponentSettings;

export interface IHaveTimezone {
  timezone?: DateTimezone;
}

/**
 * Dashboard common settings
 */
export interface DashboardCommonSettings extends IHaveTimezone {
  type: DashboardWithoutLayersTypes | DashboardWithLayersTypes | 'page';
  init?: DashboardState;
  buttons?: Button[];
  commonFilters?: Fieldset[];
  selector?: SelectorConfig;
  selectors?: SelectorConfig[];
  /** List of all ref datasets that are used in this page. Computed upon receiving config */
  usedRefDatasets?: string[];
}

/**
 * Dashboard without layers (abstract)
 */
interface DashboardBaseSettings extends DashboardCommonSettings {
  type: DashboardWithoutLayersTypes;
}

/**
 * Dashboard with layers
 */
export interface DashboardLayerSettings<T extends LayerSettings = LayerSettings> extends DashboardCommonSettings {
  type: DashboardWithLayersTypes;
  layers: { [layerId: string]: T };
}

/**
 * Generic dashboard configuration
 */
export interface DashboardGenericSettings extends DashboardBaseSettings {
  type: 'generic';
}

/**
 * Comparator dashboard settings
 */
export interface ComparatorDashboardSettings extends DashboardBaseSettings {
  type: 'comparator';
  comparatorSettings: ComparatorSettings;
}

export interface SearchBarSettings {
  searchLabel: string;
  typeTitle?: string;
  subtitle?: string;
}

/**
 * Comparator settings
 */
export interface ComparatorSettings {
  title: string;
  searchBar: SearchBarSettings;
  /* Fieldsets to use in the comparator table, if not specified, selector fieldsets would be used. */
  specificFieldsets?: Fieldset[];
  itemsUrl: string;
  bestValueColor: Color;
  colorScale: Color[];
  entityName: SpecName;
  itemIcon?: PearlIcon;
  editButton: EntityButton;
  majorFields: string[];
  noExport?: boolean;
}

/**
 * Map shared settings
 * Common properties for both dashboards (Map & VoyageOptimizer) and
 * components wrapper (MapWrapperComponent)
 */
export interface MapSharedSettings {
  layers: { [layerId: string]: MapLayerSettings };
  /** Max zoom possible when doing autofit */
  autofitMaxZoom?: number;
  playbackMode?: PlaybackMode;
  /** Settings for the map menu. If not defined, the menu with all sections will be displayed, only on big maps. */
  menuSettings?: MapMenuSettings;
}

/**
 * Map dashboard settings
 */
export interface MapDashboardSettings extends MapSharedSettings, DashboardLayerSettings<MapLayerSettings> {
  type: 'map';
  // Repeated from MapSharedSettings as JSON schema generator makes DashboardLayerSettings override it
  layers: { [layerId: string]: MapLayerSettings };
}

/**
 * Daily dashboard settings
 */
export interface DailyDashboardSettings extends DashboardLayerSettings {
  type: 'daily';
  dailyConfig?: object;
  url: string;
}

/**
 * Search engine dashboard settings
 */
export interface SearchEngineDashboardSettings extends DashboardLayerSettings {
  type: 'search-engine';
  searchEngineConfig: SearchEngineConfig;
  url: string;
  filtersUrl: string;
}

/**
 * Search specs dashboard settings
 */
export interface SearchSpecsDashboardSettings extends DashboardLayerSettings {
  type: 'search-specs';
  components: WrapperComponentSettings<ComponentSettings>[];
  searchField: string;
  url: string;
  groupByProperty?: string;
}

/**
 * Quality check dashboard settings
 */
export interface QualityCheckDashboardSettings extends DashboardLayerSettings {
  type: 'quality-check';
  url: string;
  exportLinkColumns: string[];
}

/**
 * DPR report settings
 */
export interface DprReportSettings extends DashboardBaseSettings {
  type: 'dpr-report';
}

/**
 * DPR selected vessel settings
 */

export interface ReportListField {
  additionalClasses?: string;
  propValue?: string;
  subPropValue?: string;
  tooltip?: string;
  hideTablet?: boolean;
}

export interface SelectedVesselSettings extends DashboardBaseSettings {
  type: 'dpr-selected-vessel';
  allRowFields: { [fieldId: string]: ReportListField };
  displayedFieldsByGroupType: { [groupType: string]: string[] };
}

/**
 * Subscription dashboard settings
 */
export interface SubscriptionDashboardSettings extends DashboardBaseSettings {
  type: 'subscription';
  subscriptionFields: {
    [type: string]: SubscriptionListTypeConfig;
  };
}

/**
 * Windfarm simulation dashboard settings
 */
export interface WindFarmSimulationSettings extends DashboardBaseSettings {
  type: 'windfarm-simulation';
  simulationEndpoint: string;
  mainParameters: Fieldset;
  advancedParameters: Fieldset;
  vesselsParameters: Fieldset;
  results: MultiComponentSettings;
}

/**
 * Page config
 *
 * Main interface for page configurations, used as an entry point to generate `page` schema.
 * The following line (not yet parsed) defines the filename under which it will be stored
 * in `spinsymfony/src/Spinergie/BaseBundle/Resources/config-schemas/`*
 */
export interface PageConfig {
  /** Page ID */
  id: string;
  /** Page title */
  title: string;
  /** Optional subtitle */
  subtitle?: string;
  /** Optional type title */
  typeTitle?: string;
  /** Parameter name in URL (?managerId=...) */
  idField?: string;
  /** Also known as */
  aliases?: string[];
  /** Optional icon */
  icon?: PearlIcon;
  /** Skip user fleet? */
  skipUserFleet?: boolean;
  /** Skip persistent filters? */
  skipPersistentFilters?: boolean;
  /** Skip save current analysis */
  skipSaveCurrentAnalysis?: boolean;
}

/**
 * Application config
 *
 * Main interface for project configurations, used as an entry point to generate `config` schema.
 */
export interface AppConfig {
  refDataConfig: RefDataConfigs;

  /* Timezones */
  /** Default timezone */
  timezone?: DateTimezone;
  /** Enable timezone picker */
  timezoneSelector?: boolean;

  /* UI */
  /** Footer text */
  bottomText?: string;

  /* Dashboard, pages & menus */
  /** Dashboard list */
  dashboards: PageConfig[];
  /** Enabled dashboards */
  enabledDashboards: string[];
  /** Dashboard appearing in right menu */
  rightMenuDashboards: MenuItem[];
  /** Ordered list of right menu panels */
  rightMenuPanels: string[];
  /** Dashboards to use as default */
  defaultDashboards: string[];
  /** Pages list */
  pages: PageConfig[];
  /** Panels list */
  panels: MenuItem[];
  /** Dashboard settings */
  settings: { [panelId: string]: DashboardSettings | EmptySettings };
  /** Links to external apps to be used in the menu */
  links?: { id: string; title: string; [parameters: string]: string }[];

  /* Endpoints */
  searchEndpoint?: string;

  database: DatabaseOverview;
  /** Vessel fleet filters */
  fleetBuilder?: SelectorConfig;

  /** User features */
  features: Feature[];

  weatherLayersConfig?: WeatherLayerConfig[];
}

/**
 * Empty settings, required for some special pages/dashboards (like user-admin)
 */
export interface EmptySettings {
  type?: 'empty';
}

/**
 * Database dashboard settings
 */
export interface DatabaseDashboardSettings extends DashboardLayerSettings {
  type: 'database';
  entities: DatabaseEntity[];
}

export interface WrapperComponentSettings<T extends ComponentSettings> {
  /** Wrapper ID FIXME: should be only mandatory on Component */
  id: string;
  /** Optional colspan & rowspan (for grid layouts) */
  colspan?: number;
  rowspan?: number | 'auto';
  /** Component config */
  component: T;
  /** Optional section name */
  section?: string;
  /** Initially active (for development purpose, in conjunction with `onlyActive`) */
  active?: boolean;
  /** Hovering flag (state) */
  over?: boolean;
  /** Hidden (due to filtering conditions, state) */
  filteredOut?: boolean;
}

/**
 * Filter contains url with conditions that determines
 * whether or not we want to display the content of a component
 * and the message to display if component is hidden
 *
 * ```typescript
 * {
 *   "filter": "@local<item>vessel:[:vesselId:]?maxConnectionWaveHeightClc
 *              ||maxRegularConnectionWaveHeightClc",
 *   "message": "No data - coming soon"
 * }
 * ```
 */
export interface Filter {
  condition: string;
  message: string;
}

export interface SummaryComponentSettings extends BaseComponentSettings {
  type: 'summary';
  /** Summary endpoint type */
  summaryType: SummaryType;
  /** Mandatory endpoint */
  endpoint: BaseEndpointPattern;
  /** Tabs list */
  tabs?: Fieldset[];
  /** Entity name & ID */
  entity?: string;
  entityId?: string;
  /** Buttons list */
  buttons?: Button[];
  /** Force single column display */
  forceOneColumn?: boolean;
}

export interface PhaseDetailsComponentSettings extends BaseComponentSettings {
  type: 'phase-details';
  /** Mandatory endpoint */
  endpoint: BaseEndpointPattern;
  title: string;
  fields?: PhaseFieldSettings[];
  buttons?: Button[];
}

export interface PictureComponentSettings extends BaseComponentSettings {
  type: 'image';
  /** Mandatory endpoint */
  endpoint: BaseEndpointPattern;
  /** Image URI column name */
  imageURIField: string;
  /** Entity name (vessel by default) */
  entity?: string;
}

export interface PictureWrapperComponentDialog {
  type: 'dialog' | 'component';
  component: PictureComponentSettings;
}

export interface WidgetComponentSettings extends BaseComponentSettings {
  type: 'widget';
  /** The endpoint is used to retrieve data to be injected in the script src url, e.g. the SHOM code for the port */
  endpoint: BaseEndpointPattern;
  /** Widget JS source */
  scriptSrc: string;
}

export interface InviteComponentSettings extends BaseComponentSettings {
  type: 'invite';
}

export interface KpisComponentSettings extends BaseComponentSettings {
  type: 'kpis';
  /** Optional subtitle */
  subtitle?: string;
  /** Endpoint type */
  endpointType?: BaseEndpointType;
  /** Endpoint (except if dataKey is defined) */
  endpoint?: BaseEndpointPattern;
  /** Fields settings */
  fields: FieldSettings[];
  /** Dataset key */
  dataKey?: string;
}

export interface NavigationComponentConfig {
  /** vertical mode will invoke the sidebar-navigation, while horizontal will lead to the tab-navigation */
  mode: 'horizontal' | 'vertical';
  dashboards: NavigationComponentDashboardConfig[];
  /** sections is optional as it can only be used for the vertical mode */
  sections?: NavigationComponentSectionConfig[];
}

export interface NavigationComponentSectionConfig {
  id: string;
  title: string;
}

export interface NavigationComponentDashboardConfig {
  section?: string;
  title: string;
  pageConfigId: string;
  description?: string;
}

/**
 * Components page settings
 */
export interface MultiComponentSettings extends DashboardCommonSettings {
  /** Optional page title */
  title?: string;
  type: 'page';
  cols: number;
  /** Optional page height */
  rowHeight?: string;
  sections?: SectionConfig[];
  components: WrapperComponentSettings<ComponentSettings>[];
  /** Array of fieldsets to appear on a filtering bar (on the left or on top) */
  fieldsets?: Fieldset[];
  onlyActive?: boolean;
  /** Sidebar or tab navigation */
  navigation?: NavigationComponentConfig;
  /**
   * Used to populate filters. Either:
   * 1. using the heavy analytics system (URL prefixed with /base/filters/analytics)
   *    In this case, the endpoint will return the whole content of fieldset, that will be replaced by the TS.
   * 2. Using custom endpoint.
   *    The endpoint will return data in the form filterId -> 'values' -> [data], with each entry being in the
   *    valid format for the field type (i.e {title, value} for 'multi' filter type for instance).
   *    The returned entry keys must correspond to field IDs as defined in the JSON config because they are used
   *    to find which filter values fo fill.
   */
  populateUrl?: string;
  reloadConfigEachTime?: boolean;

  // Config for the page header, including a mandatory endpoint (replaced pageEntityEndpoint)
  pageHeader?: PageHeaderConfig;
  // @Deprecated SP-8486 should be removed when all pages are migrated to new header
  isNewHeaderPage?: boolean;
  hideSidebar?: boolean;
  /** An icon can be displayed in a component page and set in the config */
  icon?: PearlIcon;
}

/**
 * This interface acts as a mapping, which means all the fields, expect the endpoint and buttons, point to the name of
 * the variable to look for in order to find the correct value.
 * i.e: `pageTitle` might have as a value `windfarm`, which means the code will look for the `windfarm` attribute
 * to fetch the value, which could be `Saint Brieuc` for instance.
 */
export interface PageHeaderConfig {
  /**
   * Endpoint returning basic info about entities that are shown as pages (on entity pages).
   * Page system can call it to obtain the title of the entity
   */
  endpoint?: string;
  // This allows to override the static icon defined in the page config
  overrideIconProp?: string;
  pageTitleProp?: string;
  pageSubtitleProp?: string;
  headerDescriptionProp?: string;
  statusProp?: string;
  // This allows to override the meta config typeTitle if set
  typeTitleProp?: string;
  // Defines a color mapping for the status
  statusColors?: ColorDefinition[];
  // This lists all the header buttons config
  buttons?: Button[];
}

/**
 * This type defines section display condition
 *
 * - alwaysVisible (default): section is always displayed
 * - hideOnNoData: section is hidden if there is no data -- only to be used with heavy components
 * - collapseOnNoData: section is collapsed if there is no data
 */
export type SectionVisibilityType = 'alwaysVisible' | 'hideOnNoData' | 'collapseOnNoData';

export type SectionConfig = {
  name: string;
  filter?: string;
  visibilityType?: SectionVisibilityType;
  isCollapsed?: boolean;
  title?: string;
  description?: string;
  details?: string;
};

export type TableCategoryLayout = 'compact' | 'none';
export type ColumnsWithoutCategoryPosition = 'first' | 'last';

/**
 * Defines the table style when categories are specified
 * - layout: defines whether the table should be compact, or keep it's ordinary layout (`none`). Default behavior is `compact`
 * - columnsWithoutCategoryPosition: defines where the columns assigned to no category should be placed, either as the
 * first or last columns. Default behavior is `first`
 */
export interface TableCategoryStyle {
  layout?: TableCategoryLayout;
  columnsWithoutCategoryPosition?: ColumnsWithoutCategoryPosition;
}

export interface EntityTableComponentSettings extends BaseComponentSettings {
  type: 'table';
  /** Component title */
  title: string;
  /** Endpoint type (for tableType: query) */
  endpointType?: BaseEndpointType;
  /** Endpoint (for tableType: query) */
  endpoint?: BaseEndpointPattern;
  /** Table endpoint type */
  tableType: TableType;
  /** Entity name */
  entity?: string;
  /** Dataset */
  dataKey?: string;
  /**
   * Category is used to describe multiple columns under one title
   * Each EntityFieldDefition can have a category that states under which title it should be displayed
   */
  /** Available modes */
  modes?: SelectableValue[];
  /** Entity categories */
  categories?: EntityTableCategory[];
  /**
   * Optional param only considered when we have categories
   * Allows to specify whether the table should be compact (default behavior when categories specified) or should
   * expand to be displayed like the ordinary table with no categories
   */
  categoryStyle?: TableCategoryStyle;
  idField?: string;
  /** Columns definitions */
  columns?: Fieldset[];
  additionColumns?: Fieldset[];
  /** Display options */
  hideHeader?: boolean;
  hideSearchbar?: boolean;
  /** Buttons lists */
  buttons?: Button[];
  headerButtons?: Button[];
  /** Permissions */
  canChooseColumns?: boolean;
  canChooseRows?: boolean;
  noExport?: boolean;
  /** Show description column */
  showDescriptionColumn?: boolean;
  selectFilter?: FilterMetric;
  formTable?: boolean;
  filename?: string;
  selectableRowPropId?: string;
  /**
   * When true, column visibility is automatically set based on the data. Columns with data will be displayed,
   * columns without data will be hidden.
   * EXCLUSIVE with canChooseColumns (user (de)selected columns will be overridden).
   */
  autoColumnsBasedOnData?: boolean;
}

export interface ScheduleLayerSettings extends LayerSettings {
  shape?: ScheduleShape;
  fill?: ScheduleFillConfig[];
  rigAvailability?: RigAvailabilitySettings;
  stroke?: string;
  radius?: {
    rx: number;
    ry: number;
  };
  toggleColor?: Color;
  addBorder?: boolean;
  filtering?: ScheduleLayerFiltering;
  populateUrl?: string;
  // a layer can override global group by and id property
  groupByProperty?: string;
  idProperty?: string;
  fixedGroupOrder?: string[];
  titleProperty?: string;
  opacity?: number;
  filteredOpacity?: number;
  titleLink?: PageLinkSettings;
  groupId?: string;
  groupTitle?: string;
  overlay?: boolean;
  concatenateDescription?: string;
  colorParam?: Color;
  notHappeningContractProp?: string;
  highlightedContractProp?: string;
  sampling?: boolean;
  doubleClick?: EntityAction;
  additionalProperties?: AdditionalProperty[];
}

/**
 * Schedule dashboard settings
 */
export interface ScheduleDashboardSettings extends DashboardLayerSettings<ScheduleLayerSettings> {
  type: 'schedule';
  layers: { [layerId: string]: ScheduleLayerSettings };
  graph?: ScheduleGraphSettings;
  tableSchedule?: EntityTableComponentSettings;
  rigAvailability?: RigAvailabilitySettings;
}

/**
 * Dev settings (dropdown menu)
 */
export interface DevSettings {
  showToolbox: boolean;
  vesselAutoreload: boolean;
  internetAutoreload: boolean;
  mocksActive: boolean;
  disablePersistentCache: boolean;
}

export type SearchedConfigResult = {
  path: string;
  value: unknown;
};

/**
 * Voyage optimizer settings
 */
export interface VoyageOptimizerDashboardSettings extends MapSharedSettings, DashboardLayerSettings<MapLayerSettings> {
  type: 'voyage-optimizer';
  layers: { [layerId: string]: MapLayerSettings };
  weatherChartSettings: ChartComponentSettings;
}

export enum UrlSubdirectory {
  'page' = 'PAGE',
  'dpr' = 'REPORTING',
  'analysts' = 'ANALYSTS',
}

type WeatherDataset =
  | 'gfs/wind_10m_above_ground'
  | 'gfs/wind_100m_above_ground'
  | 'gfs/wind_gust_surface'
  | 'gfs/temperature_2m_above_ground'
  | 'gfs/apparent_temperature_2m_above_ground'
  | 'gfs/relative_humidity_2m_above_ground'
  | 'gfs/pressure_mean_sea_level'
  | 'gfs/geopotential_height_500mb'
  | 'gfs/precipitation_3h_accumulation_surface'
  | 'gfs/snow_depth_surface'
  | 'gfs/precipitable_water_entire_atmosphere'
  | 'gfs/cloud_water_entire_atmosphere'
  | 'gfs/cloud_cover_entire_atmosphere'
  | 'gfs/cloud_cover_low_cloud_layer'
  | 'gfs/cloud_cover_middle_cloud_layer'
  | 'gfs/cloud_cover_high_cloud_layer'
  | 'gfs/reflectivity_1000m_above_ground'
  | 'gfs/downward_short_wave_radiation_flux_surface'
  | 'gfs/convective_available_potential_energy_surface'
  | 'gfswave/waves'
  | 'gfswave/swell'
  | 'gfswave/swell2'
  | 'gfswave/swell3'
  | 'gfswave/significant_wave_height'
  | 'cmems_phy/currents'
  | 'cmems_phy_merged/tidal_currents'
  | 'cmems_sst/sea_surface_temperature'
  | 'cmems_sst/sea_ice_fraction'
  | 'cams/carbon_monoxide_10m_above_ground'
  | 'cams/sulphur_dioxide_10m_above_ground'
  | 'cams/nitrogen_dioxide_10m_above_ground'
  | 'cams/ozone_10m_above_ground'
  | 'cams/particulate_matter_2p5um_10m_above_ground'
  | 'cams/particulate_matter_10um_10m_above_ground';

export interface WeatherLayerConfig {
  id: string;
  title: string;
  dataset?: WeatherDataset;
  config?: WeatherMapLayerConfig;
}

interface CommonWeatherLayerParams {
  /** Between 0 and 1 */
  opacity: number;
}

/** Not all possibilities are exposed, as some are hard-coded to avoid perf problems. */
interface ParticleLayerParams extends CommonWeatherLayerParams {
  width: number;
  /** White by default */
  color?: [number, number, number];
  maxAge?: number;
  numParticles?: number;
  speedFactor?: number;
}

interface GridLayerParams extends CommonWeatherLayerParams {
  style: WeatherLayers.GridStyle;
  /** Must be between 0 and 3, declared as a float, otherwise the layer flickers when dragging */
  density: number;
  textSize?: number;
  /** See https://docs.weatherlayers.com/weatherlayers-gl/layers/grid-layer#iconbounds*/
  iconBounds?: [number, number];
  iconSize?: number | [number, number];
  /** Default is transparent black */
  iconColor?: [number, number, number];
  /** Default is white */
  textColor?: [number, number, number];
  /** Default is 1 */
  textOutlineWidth?: number;
  /** Default is black */
  textOutlineColor?: [number, number, number];
}

export interface WeatherMapLayerConfig {
  /**
   * The sampling of available data. For instance, 3 means "take a point every 3 hours".
   * Leaving undefined means take all available
   */
  datetimeStep?: number;
  unitSystem?: WeatherLayers.UnitSystem;
  raster?: CommonWeatherLayerParams;
  grid?: GridLayerParams;
  particles?: ParticleLayerParams;
}
