<aside class="dashboard-sidebar full-height" data-testid="left-sidebar" md-ele>
  <div class="sidebar-box">
    <div class="reset-filters-container">
      <pearl-button size="small" type="alternative" (click)="onreset($event)">Reset to default</pearl-button>
      <ng-content />
    </div>

    <div class="sidebar-filters">
      @for (layer of layerList; track layer.id) {
        <sidebar-layer
          [layerSetting]="layersSettings ? layersSettings[layer.id] : null"
          [layer]="layer"
          [layerNumber]="layerList.length"
          [onaction]="onaction"
          (onbubblinglayer)="onbubblinglayer(layer.id, $event)"
          (onbubblingfilters)="onbubblingfilters(layer.id, $event)"
          (onswitchlayermode)="handleSwitchLayerMode($event)"
          (onButtonClick)="buttonClick($event)"
          (onToggleShapeVisibility)="toggleShapeVisibility($event)">
        </sidebar-layer>
      }
    </div>
    <div class="sidebar-buttons">
      <ng-container *ngFor="let button of buttons">
        <ng-container [ngSwitch]="button.type">
          <pearl-button [icon]="button.icon" type="alternative" *ngSwitchCase="'url'" (click)="windowOpen(button.url)">
            {{ button.text }}
          </pearl-button>

          <pearl-button
            *ngSwitchCase="'addModal'"
            type="alternative"
            [icon]="button.icon"
            (click)="buttonClick(button)">
            {{ button.text }}
          </pearl-button>

          <div *ngSwitchCase="'separator'">
            <br />
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</aside>
