import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, NgZone, OnInit, QueryList, ViewChild,
  ViewChildren, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatBadgeModule } from '@angular/material/badge';
import { MatTabsModule } from '@angular/material/tabs';
import { NgClass, NgFor, NgIf, NgStyle, NgSwitch, NgSwitchCase, NgSwitchDefault } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { remove, uniqBy } from 'lodash-es';

import { FieldsetCollection } from '../filters/fieldsets-collection';
import { AppInfoService } from '../app/app-info-service';
import { Config } from '../config/config';
import { DatabaseHelper } from '../database/database-helper';
import { FilterColumnsDialogComponent, SelectableColumn } from '../database/filter-columns.dialog';
import { SelectorHelper } from './selector.helper';
import { AdvancedModelConfig, SelectableEntityInfo, SelectorActionConfig, SelectorFullConfig, SelectorItem,
  SelectorTab } from './selector.types';
import { BrowserHelper } from '../helpers/browser-helper';
import { hideTooltip } from '../helpers/d3-helpers';
import { DataHelpers } from '../helpers/data-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { NavigationHelper } from '../helpers/navigation-helper';
import { PluralWordPipe, SafeHtmlPipe, SearchResultAdvancedPipe, UpperFirstLetterPipe } from '../helpers/pipes';
import { ActionEvent, Button, ExportConfig, ExportData, FieldOrdering, FieldSettings, FilterApplied, LayerFilter,
  ListTooltipSettings, MultiOption } from '../helpers/types';
import { ListTooltipComponent } from '../shared/list-tooltip';
import { FieldsHelperService } from '../shared/services/helpers/fields-helper.service';
import { AdvancedFilteringModel, AdvancedModelResults, VesselsUpdateData } from './advanced-filtering-model';
import { VesselFleetDialog } from './vessel-fleet-dialog';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { SpinLinkComponent } from '../shared/spin-link';
import { FieldButtonComponent } from '../shared/field-button';
import { SelectorFieldsetComponent } from './selector-fieldset.component';
import { WfiVesselSelectorComponent } from './wfi-vessel-selector';
import { WTIVesselSelectorComponent } from './wti-vessel-selector';
import { SelectorActionsComponent } from './selector-actions.component';
import { FilterHelper } from '../filters/filter-helper';
import { SpinTooltipDirective } from '../shared';
import { PearlButtonComponent, PearlFormFieldComponent, PearlIconComponent } from '../shared/pearl-components';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { RefDataProvider, getChained } from '../data-loader/ref-data-provider';
import { RawDataPoint } from '../graph/chart-types';
import { PearlAutocompleteComponent } from '../shared/pearl-components/components/forms/pearl-select/pearl-autocomplete.component';

@Component({
  selector: 'advanced-filters',
  templateUrl: 'selector.component.html',
  styleUrls: ['selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PearlButtonComponent,
    PearlIconComponent,
    MatIconModule,
    NgIf,
    MatTabsModule,
    NgFor,
    MatBadgeModule,
    NgClass,
    NgSwitch,
    NgSwitchCase,
    WTIVesselSelectorComponent,
    WfiVesselSelectorComponent,
    NgStyle,
    SelectorActionsComponent,
    MatInputModule,
    PearlFormFieldComponent,
    FormsModule,
    MatCheckboxModule,
    SelectorFieldsetComponent,
    MatProgressSpinnerModule,
    MatTooltipModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    FieldButtonComponent,
    SpinLinkComponent,
    NgSwitchDefault,
    ListTooltipComponent,
    SafeHtmlPipe,
    PluralWordPipe,
    UpperFirstLetterPipe,
    SearchResultAdvancedPipe,
    SpinTooltipDirective,
    PearlAutocompleteComponent,
  ],
})
export class SelectorComponent implements OnInit {
  @ViewChildren(SelectorFieldsetComponent)
  protected $fieldsets: QueryList<SelectorFieldsetComponent>;
  @ViewChild(ListTooltipComponent)
  public $listTooltip: ListTooltipComponent;
  @ViewChild('advancedFilteringModel')
  public $advancedFilteringModel: AdvancedFilteringModel<object, object, object, any>;
  @ViewChild(CdkVirtualScrollViewport)
  public viewPort: CdkVirtualScrollViewport;

  private ngZone: NgZone;
  private cdRef: ChangeDetectorRef;
  private appInfoService: AppInfoService;
  private productAnalyticsService: ProductAnalyticsService;
  private dataLoader: DataLoader;
  private readonly config: Config;
  private removableEntities: MultiOption<unknown>[] = [];
  private selectorConfig: SelectorFullConfig;
  private browserHelper: BrowserHelper;
  private interceptEvent = false;

  // Two list to keep track of additional columns added to the results table
  private selectableColumns: SelectableColumn[];
  private allSpecsFields: FieldSettings[];
  public fieldsetCollection: FieldsetCollection;

  public modelCapableVessels: SelectorItem[] = [];

  /**
   * Current dataset - depends on the tab, without filters applied, but after applying standard filters
   */
  public currentDataset: SelectorItem[];

  /* Main Specs Dataset, stored that it can be used and re-used when switching tab */
  public mainDataset: readonly SelectorItem[];

  // filtered is the dictionary of data filtered on pre filtered data with advanced filters
  public filteredData: { [id: number]: SelectorItem };

  /*
   * filteredResult holds the same data as filteredData but as an orderer list to be displayed.
   * It changes every time the filters change.
   */
  public filteredResults: SelectorItem[] = [];

  /**
   * selectedResults are checked + additional results.
   * Checked results are the ones the user manually selected among the filteredResults.
   * It changes every time the user check, remove or add an item.
   */
  public selectedResults: SelectorItem[];

  /**
   * Number of checked results - the number of items that is checked and applied.
   * It is affected by the "really checked results - the items on which the user clicked"
   * and also by additional entities
   * If there are no additional and no manually clicked items this will be the full dataset
   */
  public resultsCount: number;

  public checkedResultsCount: number;

  public actions: SelectorActionConfig[] = [];

  public entityInfo: SelectableEntityInfo;

  // flag that indicates when all filters have initialized
  public filtersInitialized = false;
  public allowNulls: boolean;

  public additionalEntities: SelectorItem[] = [];
  public excludedEntities: SelectorItem[] = [];
  public addOnEntities: MultiOption<SelectorItem>[] = [];
  public numberFilterApplied: number;
  public searchResult: string = '';

  /*
   * The text and the disabler of the button which allows to select directly all the items
   * that meet the requirements of additional model
   */
  public advancedModelSelectButtonText = '';

  /** List of spec fields that the user can add to the standard output */
  public additionalFields: FieldSettings[] = [];

  private searchResultPipe = new SearchResultAdvancedPipe();

  /**
   * All columns that are shown in the table when filtering with advanced model:
   * - output columns of the model
   * - additional columns that the user might have added from specs
   */
  public get allAdvancedModelColumns(): FieldSettings[] {
    const columnsFromAdvancedModel = this.$advancedFilteringModel?.resultsFields
      ? this.$advancedFilteringModel.resultsFields
      : [];

    return [...columnsFromAdvancedModel, ...this.additionalFields];
  }

  /** Retrieve all applied filters. Filter states are stored in fieldsets. */
  public get savedFilters(): LayerFilter {
    const filters: LayerFilter = {};
    this.$fieldsets?.forEach($fs => Object.assign(filters, $fs.appliedFilters));
    return filters;
  }

  public dialog: MatDialog;

  public tabs: SelectorTab[] = [];
  public selectedTabIndex = 0;
  public selectedModelConfig: AdvancedModelConfig;

  constructor(
    public dialogRef: MatDialogRef<SelectorComponent>,
    injector: Injector,
    public readonly fieldsHelperService: FieldsHelperService,
  ) {
    this.ngZone = injector.get(NgZone);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.appInfoService = injector.get(AppInfoService);
    this.dataLoader = injector.get(DataLoader);
    this.config = injector.get(Config);
    this.dialog = injector.get(MatDialog);
    this.browserHelper = injector.get(BrowserHelper);
    this.productAnalyticsService = injector.get(ProductAnalyticsService);
  }

  public get entityFilterId(): string {
    return this.selectorConfig.advancedFieldsets.entityFilterId;
  }

  ngOnInit(): void {
    this.productAnalyticsService.trackModal(this.entityInfo.name + ' selection');
  }

  public initialize(selectorFullConfig: SelectorFullConfig): void {
    this.selectorConfig = selectorFullConfig;
    this.filtersInitialized = false;
    this.fieldsetCollection = selectorFullConfig.advancedFieldsets;
    this.checkIfAllowEmptyValues(selectorFullConfig.appliedFilters);
    this.entityInfo = SelectorHelper.buildEntityInfo(
      selectorFullConfig.advancedFieldsets.selectorConfig.entity,
      this.config,
    );
    this.setTabs(selectorFullConfig);
  }

  private setTabs(selectorFullConfig: SelectorFullConfig): void {
    this.tabs.push({
      id: 'advancedFilters',
      title: this.entityInfo.name + ' filters',
      model: null,
    });
    selectorFullConfig.advancedFieldsets.selectorConfig.models?.forEach(model =>
      this.tabs.push({ model, id: model.id, title: model.title })
    );
  }

  private checkIfAllowEmptyValues(appliedFilters: LayerFilter): void {
    if (!appliedFilters) {
      this.allowNulls = false;
      return;
    }
    const filterValues = Object.values(appliedFilters);
    if (!filterValues.length) {
      this.allowNulls = false;
      return;
    }
    this.allowNulls = Object.values(appliedFilters).every(filter => filter.values.includes('__nullValues'));
  }

  /** This is the way to retrieve a spec from a selectorItem. */
  public getItemSpec<T>({ itemData }: SelectorItem, specName: string): T {
    return getChained(itemData, specName);
  }

  /** Builds the config of the buttons at the bottom of the selection. */
  private buildActionsConfig(): void {
    this.actions = [];

    const fleetButton = this.getFleetButton();
    if (fleetButton) {
      this.actions.push(fleetButton);
    }

    if (this.selectorConfig.fleetBuilderMode == null) {
      this.actions.push({
        label: 'Apply',
        onClick: () => this.onSave(),
        type: 'primary',
        disabled: this.disabledApply(),
        tooltip: this.disabledApply() ? 'You must select at least one value to save.' : null,
      });
    }

    if (this.advancedModelActive) {
      this.actions.push({
        label: 'Download XLSX',
        onClick: () => this.downloadVessels(),
        type: 'secondary',
        icon: 'download',
        disabled: this.config.trialModeDownloadsDisabled,
        tooltip: this.config.getXlsxDownloadTooltip(),
      });
    }
  }

  /**
   * Return null if not a vessel selector or fleet builder explicitly disabled. Return a disabled button if a fleet is
   * active.
   */
  private getFleetButton(): SelectorActionConfig {
    if (this.entityInfo.name !== 'vessel' || this.selectorConfig.fleetBuilderDisabled) {
      return null;
    }

    const fleetBuilderMode = this.selectorConfig.fleetBuilderMode;
    const alreadyInFleet = this.config.selectedFleets?.length > 0;
    const advancedModelWithoutSelection = this.advancedModelActive && this.selectedResults?.length === 0;

    const fleetButton: SelectorActionConfig = {
      label: fleetBuilderMode === 'edit' ? 'Save vessel fleet' : 'Create vessel fleet',
      type: fleetBuilderMode ? 'primary' : 'secondary',
      disabled: alreadyInFleet || advancedModelWithoutSelection,
      onClick: () => this.saveVesselFleet(),
      icon: 'vessel',
    };

    if (alreadyInFleet) {
      fleetButton.tooltip = `You cannot ${
        fleetBuilderMode ?? 'create'
      } a vessel fleet's composition if you are already applying a fleet`;
    } else if (advancedModelWithoutSelection) {
      fleetButton.tooltip = 'You must select at least one value to create a vessel fleet.';
    }

    return fleetButton;
  }

  public trackEntityResult = (_, entityResult: SelectorItem): string | number => {
    return entityResult.id;
  };

  public async populateAndSet(): Promise<void> {
    /*
     * Prepare the dataset - figure out which dataset should be used, set the booleans which govern
     * the status (checked, filteredIn) and populate the filters with the dataset
     */
    await this.loadLazyDatasets();
    const rawData = await this.loadRawData();
    this.populate(rawData);
    this.initMainDataset(rawData);
    this.currentDataset = [...this.mainDataset];

    /** Only keep filters that have a corresponding field */
    const filters = this.fieldsetCollection.extractFiltersAppliedOnMe(this.selectorConfig.appliedFilters);
    this.prefillAdditionalAndExcluded(filters);
    this.set(filters);
    this.filterResults(filters);
    this.filtersInitialized = true;

    /** If the tab index has changed before the dataset has been loaded, we must call switchTabDataset here. */
    if (this.selectedTabIndex > 0) {
      this.switchTabDataset();
    }
  }

  private async loadLazyDatasets(): Promise<void> {
    await Promise.all(
      Config.getLazyDatasetsToLoad(this.selectorConfig.advancedFieldsets.fieldsets)
        .map(datasetName => RefDataProvider.loadRefDataset(datasetName)),
    );
  }

  private async loadRawData(): Promise<readonly RawDataPoint[]> {
    const fullData = await this.dataLoader.get<RawDataPoint[]>(this.entityInfo.url);
    if (this.selectorConfig.entityIdsFromData?.size) {
      try {
        return fullData.filter(d => this.selectorConfig.entityIdsFromData.has(getChained(d, this.entityInfo.idProp)));
      } catch (e: any) {
        throw new ErrorWithFingerprint(`Original message: ${e.toString()}`, ['selector_load-main-dataset']);
      }
    }

    return fullData;
  }

  /* Mark selected items and add page urls. */
  private initMainDataset(rawData: readonly RawDataPoint[]): void {
    const selectedResultIds = this.selectorConfig.appliedFilters?.[this.entityFilterId]?.values.map(id => Number(id))
      ?? [];

    this.mainDataset = rawData.map(d => {
      const id = getChained<number>(d, this.entityInfo.idProp);

      return {
        title: getChained(d, this.entityInfo.titleProp),
        id,
        itemData: d,
        checked: selectedResultIds.includes(id),
        pageUrl: this.entityInfo.pageLinkHelper?.computeUrlFromDict(d),
      };
    });
  }

  /**
   * Handles the change of the tab.
   * when switching tabs we can take few assumptions:
   * - The advanced filters tab has already been initialized with the dataset loaded and fields structures populates
   * - if some items have been checked by the user, we want to keep them checked across tabs
   * - if we are going to an advanced-model tab, we have to restrict the vessel list to only vessels that are supported
   *   by the model
   */
  public async switchTabDataset(): Promise<void> {
    if (!this.currentDataset || this.currentDataset.length === 0) {
      /** If the dataset has not been loaded yet, this function should not be executed. */
      return;
    }

    if (this.advancedModelActive) {
      /*
       * advanced-filtering-model can provide items which do not exists in the original list
       * for example "fake future vessels"
       */
      const idProp = this.$advancedFilteringModel.idProp;
      const titleProp = this.$advancedFilteringModel.config.vesselTitle;
      this.$advancedFilteringModel.set(this.savedFilters);

      /* list of vessels provided by the "advanced-model", we will have to inject mainDataset specs into it */
      const modelVessels = await this.$advancedFilteringModel.getModelVessels();
      const mainDatasetPerVesselId = DataHelpers.toDict(this.mainDataset, d => this.getItemSpec<number>(d, 'vesselId'));

      /*
       * construct the currentDataset with the help of the specs above, using the list of vessels
       * provided by the current advanced-filtering-model
       */
      this.currentDataset = modelVessels.map(modelVessel => {
        const vesselId = modelVessel['vesselId'];
        /*
         * Each model can provide fields that enrich the specs, the same fields are provided
         * when we call the model and also when we call the "model-vessels" endpoint.
         * the same method is used here to inject the data (mainly specs updated values)
         */
        const itemData = { ...(mainDatasetPerVesselId[vesselId]?.itemData ?? {}), ...modelVessel };

        return {
          id: getChained(itemData, idProp),
          title: getChained(itemData, titleProp),
          checked: this.filteredData[vesselId]?.checked, // filteredData is still indexed by vesselId at this time
          itemData,
        };
      });

      // run the model to which we have switch to
      this.$advancedFilteringModel.valueChange();
    } else {
      /** We're going back from an advancedModel dataset to the mainDataset. */
      this.currentDataset = [...this.mainDataset];
    }

    // set the filters from state
    this.set(this.savedFilters);

    this.filterResults(this.savedFilters);
    this.updateAdvancedCapableVessels();
  }

  private prefillAdditionalAndExcluded(filters: LayerFilter): void {
    // Set additionalEntities and excludedEntities filters
    const addEntityIds: number[] = filters['additionalEntities']?.values.map(v => Number(v)) ?? [];
    const excludedEntityIds: number[] = filters['excludedEntities']?.values.map(v => Number(v)) ?? [];

    this.currentDataset.forEach(d => {
      const additional = addEntityIds.includes(d.id);
      const excluded = excludedEntityIds.includes(d.id);

      if (additional) {
        this.additionalEntities.push(d);
      }
      if (excluded) {
        this.excludedEntities.push(d);
      }
      this.addOnEntities.push({
        value: d.id,
        title: d.title,
        alreadyChosen: additional,
        data: d,
      });
      this.removableEntities.push({
        value: d.id,
        title: d.title,
        alreadyChosen: excluded,
        data: d,
      });
    });
  }

  public populate(data: readonly RawDataPoint[]): void {
    this.$fieldsets.forEach($fieldset => $fieldset.populate(data, false));
    this.cdRef.detectChanges();
  }

  /* Set filters on all fields */
  public set(filters: LayerFilter): void {
    this.$fieldsets.forEach($fieldset => $fieldset.set(filters, false));
    this.detectedChangesOnAlFieldsets();
  }

  public downloadVessels(): void {
    const exportConfig: ExportConfig = {
      filename: 'VesselsSelection',
      layerId: null,
      definition: null,
      // we have to add the vessel field for the export, it is not part of wtiResults
      columns: [...[{ id: 'vessel', title: 'Vessel' }], ...this.allAdvancedModelColumns],
    };
    const inputData: ExportData = {
      data: this.filteredResults.map(item => item.itemData),
      trackingInfo: { exportSource: 'advanced-filters', componentTitle: 'Advanced filters' },
    };

    const exportData = this.browserHelper.prepareExport({ config: exportConfig, exportData: inputData }, false);
    this.browserHelper.exportXls(exportData, exportConfig.filename);
  }

  public openFilterColumnsDialog(): void {
    if (this.selectableColumns == null) {
      // columns that are by default always part of this advanced model
      const columnsFromAdvancedModel: string[] = this.$advancedFilteringModel?.resultsFields?.map(f => f.id) ?? [];

      this.allSpecsFields = this.selectorConfig.advancedFieldsets.filters.filter(field =>
        FilterHelper.shouldShowInTable(field) && !columnsFromAdvancedModel.includes(field.id)
      );

      this.selectableColumns = this.allSpecsFields.map(field =>
        FilterHelper.createSelectableColumn(field, field.fieldsetTitle)
      );
    }

    const columnsDialog = this.dialog.open(FilterColumnsDialogComponent, {
      'width': '600px',
      'maxHeight': '750px',
      data: {
        default: [],
        allFields: this.selectableColumns,
        shownColumnsIds: this.additionalFields.map(vc => vc.id),
      },
    });

    columnsDialog.afterClosed().subscribe((result: string[]) => {
      this.setVisibleColumns(result);
    });
  }

  public setVisibleColumns(columns: string[]): void {
    if (!columns || columns.length === 0) {
      this.additionalFields = [];
    }

    if (columns && columns.length) {
      this.additionalFields = this.allSpecsFields.filter((entityFieldDef: FieldSettings) =>
        columns.includes(entityFieldDef.id)
      );
    }
    this.cdRef.detectChanges();
  }

  public closeAdvancedFilters(): void {
    this.ngZone.run(() => this.dialogRef.close());
  }

  public onAllowNullsChanged(): void {
    for (const filterValue in this.savedFilters) {
      const filter = this.savedFilters[filterValue];
      // Checkbox with false value are ignored
      if (FilterHelper.hasCheckboxBehavior(filter.filterType) && filter.values?.includes(false)) {
        delete this.savedFilters[filterValue];
      } else if (this.allowNulls && !FilterHelper.allowsNulls(filter)) {
        filter.values.push('__nullValues');
      } else if (!this.allowNulls && FilterHelper.allowsNulls(filter)) {
        const nullValueIndex = filter.values.indexOf('__nullValues');
        filter.values.splice(nullValueIndex, 1);
      }
    }

    if (!this.interceptEvent) {
      this.filterResults(this.savedFilters);
    }
  }

  public updateFilter(filter: FilterApplied): void {
    /*
     * we have to check the allow Nulls checkbox
     * because anytime we receive new filters event it is a new FilterApplied
     * coming directly from the filter (interval/checkbox etc) so it has lost the information
     */
    if (this.allowNulls && !FilterHelper.allowsNulls(filter)) {
      filter.values.push('__nullValues');
    }

    if (!this.interceptEvent) {
      this.filterResults(this.savedFilters);
    }
  }

  /**
   * Filters the data here to be able to show a result count directly on the selector
   * @param filter LayerFilter which contains all the filters to be applied
   * @param modelVesselData Results of advanced filtering model, variables to be injected into the dataset
   */
  private filterResults(filter: LayerFilter, modelVesselData: VesselsUpdateData = null): void {
    const newFilteredData: { [idProp: string]: SelectorItem } = {};

    try {
      this.currentDataset.forEach(item => {
        // determine if the item passes the filters
        const filteredIn = FilterHelper.filter(filter, item.itemData);

        // determine if the item is in additional or excluded list
        const excludedItem = this.excludedEntities.some(r => r.id === item.id);
        const additionalItem = this.additionalEntities.some(a => a.id === item.id);

        /*
         * we keep filtered data and checked data.
         * - If a value is checked and in the filtered values it will be visible with a green border
         * - If a value is checked but not in filtered values it will be visible but with a orange border
         * - If a value is not checked but in filtered values it will be only visible but without any border
         * - If the data was on previous filteredData and was checked (in different tab) it will still be checked
         */

        if ((!filteredIn && !item.checked) || excludedItem || additionalItem) {
          return;
        }

        newFilteredData[item.id] = Object.assign(item, { filteredIn });

        // this will copy the new advanced filtering model results - if there are any
        if (modelVesselData?.[item.id]) {
          Object.assign(newFilteredData[item.id].itemData, modelVesselData[item.id]);
        }
      });
    } catch (e: any) {
      throw new ErrorWithFingerprint(`Original message: ${e.toString()}`, ['selector_filter-results']);
    }

    this.filteredData = newFilteredData;
    this.numberFilterApplied = SelectorHelper.getNumberAdvancedFilterApplied(this.savedFilters);
    this.reOrderFilteredResults();
    this.afterSelectionOrFilterChange();
    this.cdRef.detectChanges();
  }

  public selectResults(): void {
    const idsToCheck = this.advancedModelActive ? this.capableVesselIds : this.visibleItemsIds;
    this.toggleIds(idsToCheck, true);
  }

  public canSelectAll(): boolean {
    return this.canSelect(this.filteredResults.length);
  }

  public canSelect(itemCount = this.checkedResultsCount + 1): boolean {
    if (this.selectorConfig.maxSelectedItemCount == null) {
      return true;
    }

    return itemCount <= this.selectorConfig.maxSelectedItemCount;
  }

  public getSelectAllTooltip(): string {
    if (this.canSelectAll()) {
      return '';
    }

    return SelectorHelper.computeMaxSelectionMsg(this.selectorConfig.maxSelectedItemCount)
      + ' Please select elements one by one';
  }

  public getMessageIfCannotSelect(result: SelectorItem = null): string {
    if (!this.canSelect() && !result?.checked) {
      return SelectorHelper.computeMaxSelectionMsg(this.selectorConfig.maxSelectedItemCount)
        + ' Please unselect an element to be able to select another.';
    }

    return '';
  }

  public get canExcludeItems(): boolean {
    return this.selectorConfig.canExcludeItems ?? true;
  }

  /** Retrieve ids of all vessels capable (compatible) with advanced-filtering-model */
  private get capableVesselIds(): Set<number> {
    return new Set(this.modelCapableVessels.map(v => v.id));
  }

  /** Apply search pipe on the filtered results, since we don't keep state of filtered search list */
  private get visibleItemsIds(): Set<number> {
    const results = this.searchResultPipe.transform(this.filteredResults, this.searchResult);
    return new Set(results.map(d => d.id));
  }

  /** Check given set of ids. */
  private toggleIds(ids: Set<number>, checked: boolean): void {
    ids.forEach(id => {
      this.filteredData[id].checked = checked;
    });
    if (this.advancedModelActive) {
      /** We also toggle in the the mainDataset for when we go back to the main tab */
      this.mainDataset.filter(d => ids.has(this.getItemSpec(d, this.$advancedFilteringModel.idProp))).forEach(d =>
        d.checked = checked
      );
    }
    this.afterSelectionOrFilterChange();
  }

  public get fieldOrdering(): FieldOrdering {
    return this.$advancedFilteringModel?.fieldOrdering;
  }

  /**
   * Orders the Filtered data. All filtered data is stored in the *filteredData* object which is a dictionary
   * based on ids. The results are then present in a list called **filteredResults** which is a list of entities
   */
  public reOrderFilteredResults(): void {
    if (this.$advancedFilteringModel?.fieldOrdering) {
      /** A specific ordering can be specified by a field on advanced model */
      this.reorderAdvancedModelResults(this.$advancedFilteringModel.fieldOrdering);
    } else {
      /**
       * Default ordering:
       * - Checked one on top
       * - Then sorted by title
       * Not using `lodash.orderBy` because it doesn't work well with booleans.
       */
      this.filteredResults = Object.values(this.filteredData).sort((a: SelectorItem, b: SelectorItem) => {
        if (a.checked === true) return -1;
        if (b.checked === true) return 1;
        return this.compareByTitleProp(a, b);
      });
    }
  }

  private compareByTitleProp(a: SelectorItem, b: SelectorItem): number {
    return a.title.localeCompare(b.title);
  }

  /** If a fieldOrdering is defined for a given advancedModel, it can be used here to order. */
  private reorderAdvancedModelResults({ propOrder, orderDirection }: FieldOrdering): void {
    const field = this.allAdvancedModelColumns.find(d => d.id === propOrder);
    this.filteredResults = Object.values(this.filteredData)
      .sort((itemA: SelectorItem, itemB: SelectorItem) => {
        const factor = orderDirection === 'desc' ? -1 : 1;

        if (field && field.id === 'meetRequirements') {
          const indexA = field.colorRanges.find(r => r.range[0] === this.getItemSpec(itemA, field.id))?.range[1];
          const indexB = field.colorRanges.find(r => r.range[0] === this.getItemSpec(itemB, field.id))?.range[1];
          if (indexA > indexB) {
            return 1 * factor;
          } else if (indexA < indexB) {
            return -1 * factor;
          }
        }

        const orderA = this.getItemSpec(itemA, propOrder);
        const orderB = this.getItemSpec(itemB, propOrder);
        /*
         * If we are sorting by button status (See orderByField function)
         * This is a fix to enable sorting by craneChart
         */
        if (!field && orderA && orderB) {
          if (orderA > orderB) {
            return 1 * factor;
          }
          if (orderA < orderB) {
            return -1 * factor;
          }
          return 0;
        }

        return DatabaseHelper.compare(itemA.itemData, itemB.itemData, orderDirection, field);
      });
  }

  public searchFilters(searchString: string): void {
    this.fieldsetCollection.fieldsets.forEach(fieldset => {
      fieldset.fields.forEach(field => {
        if (field.visible !== false && field.title.toLowerCase().includes(searchString.toLowerCase())) {
          field.filteredOut = false;
          fieldset.expanded = true;
        } else {
          field.filteredOut = true;
        }
      });
    });
    this.detectedChangesOnAlFieldsets();
  }

  public detectedChangesOnAlFieldsets(): void {
    if (this.filtersInitialized) {
      /*
       * TODO: we have to manually start change detection on all the children components
       * for some reason even if we call detectChanges on current level the child components are not
       * evaluated
       */
      this.$fieldsets.forEach(fieldset => fieldset.detectChanges());
    }
  }

  public resetFilters(): void {
    this.interceptEvent = true;
    this.$fieldsets.forEach(fieldset => fieldset.reset(false));
    this.interceptEvent = false;
    this.allowNulls = false;
    this.searchResult = '';
    this.filterResults(this.savedFilters);
    this.detectedChangesOnAlFieldsets();
  }

  public resetResultSelection(): void {
    // Re-init additional and excluded filters
    this.additionalEntities.forEach((d: SelectorItem) => {
      d.checked = false;
    });
    this.additionalEntities = [];
    this.excludedEntities = [];
    this.removableEntities.forEach(e => e.alreadyChosen = false);

    this.currentDataset.forEach(result => result.checked = false);
    this.mainDataset.forEach(result => result.checked = false);

    this.filterResults(this.savedFilters);
    this.cdRef.detectChanges();
  }

  /**
   * On page link click, we stop event propagation so the item is not "checked".
   */
  public onPageLinkClick(event: MouseEvent): void {
    event.stopPropagation();
  }

  /**
   * Disables or enables the apply button.
   *  - If we are in advanced-filtering model vessels need to be manually checked
   *  - If we are in standard-filtering model we just need at least one vessel in the filtered list, unless
   * mustSelectItems is true.
   */
  public disabledApply(): boolean {
    return (this.advancedModelActive || this.selectorConfig.mustSelectItems) && !this.selectedResults?.length;
  }

  public onSave(): void {
    const filtersToApply = this.getFiltersToApply();
    this.dialogRef.close(filtersToApply);
  }

  private getFiltersToApply(): LayerFilter {
    this.updateAdditionalFilters();
    this.updateExcludedFilters();

    const filters: LayerFilter = {
      ...this.savedFilters,
    };

    if (this.selectedResults.length > 0) {
      filters[this.entityFilterId] = this.buildEntityFilterFromSelectedResults();
    }

    return filters;
  }

  /**
   * Create a "multi" filter from selected results that would be propagated to the sidebar entity filter (e.g. vessel).
   */
  private buildEntityFilterFromSelectedResults(): FilterApplied {
    return {
      id: this.entityFilterId,
      filterType: 'multi', // filterType needs to be specified for vessel fleets!
      propValue: this.entityInfo.idProp, // propValue needs to be specified when creating a fleet from vessel selection!
      active: true,
      values: this.selectedResults.map(s => this.getItemSpec<number>(s, this.entityInfo.idProp)),
      /** We can define an autozoom when some entities are selected. It would be used on map. */
      autozoom: this.selectorConfig.advancedFieldsets.selectorConfig.entity.autozoom,
    };
  }

  /**
   * Update the list of checked results and associated possible actions
   * If we checked some items this number will be the length of the fill selection.
   * Otherwise the returned number will be the number of filtered items by other filters.
   */
  public afterSelectionOrFilterChange(): void {
    /*
     * first determine the number of "really checked results - those manually selected by the user"
     * determining the number of checked items makes sense in 2 cases:
     * - there is a entity filter on the dashboard which can be appliedFilters
     * - we are in advancedModel (even without the entityFilter on the dashboard)
     * the only way to save the fleet is to have selectedResults
     */
    const checkedItems = (this.entityFilterId && this.filteredResults.length > 0)
      ? this.filteredResults.filter(d => d.checked)
      : [];
    this.checkedResultsCount = checkedItems.length;

    const additionalEntitiesList = this.additionalEntities.map(v => v.id);

    // selected results are checked items among filtered + additional
    this.selectedResults = uniqBy(
      [...checkedItems, ...this.additionalEntities],
      d => d.id,
    );

    // in standard case if some items are checked, we show the checked + additional
    if (checkedItems?.length > 0) {
      this.resultsCount = this.selectedResults.length;
    } else {
      // if no items are checked, we show the number of filtered items + additional entities
      this.resultsCount = this.filteredResults.length + additionalEntitiesList.length;
    }

    this.buildActionsConfig();
  }

  /**
   * Trigger when the user manually select/unselect a result
   */
  public toggleResult(result: SelectorItem): void {
    if (!result.checked && !this.canSelect()) {
      return;
    }

    result.checked = !result.checked;
    this.toggleIds(new Set([result.id]), result.checked);
    // If the result was in the filtered list because it was checked we remove it from the result filtered list
    if (!result.checked && !result.filteredIn) {
      delete this.filteredData[result.id];
    }

    this.afterSelectionOrFilterChange();
    this.cdRef.detectChanges();
  }

  public removeFromResultList(event: MouseEvent, result: SelectorItem): void {
    event.preventDefault();
    event.stopPropagation();
    this.removableEntities.forEach(e => {
      if (result.id === e.value) {
        e.alreadyChosen = true;
      }
    });

    this.filterResults(this.savedFilters);
  }

  /** TODO: rethink the message without the disjonction on entityFilterId */
  public get userHelpText(): string {
    const subMessage = this.entityFilterId
      ? `You can select some of these ${this.entityInfo.name}s to use as a filter.`
      : '';
    return `Below is a list of ${this.entityInfo.name}s that match the current filters and dashboard data.\n
    ${subMessage} `;
  }

  public saveVesselFleet(): void {
    /** If some results are selected, there is no need to keep track of other filters. */
    const filtersToApply: LayerFilter = this.selectedResults.length > 0
      ? { vessel: this.buildEntityFilterFromSelectedResults() }
      : this.getFiltersToApply();

    if (this.selectorConfig.fleetBuilderMode === 'edit') {
      this.dialogRef.close(filtersToApply);
      return;
    }
    const dialogRef = this.dialog.open(VesselFleetDialog, {
      data: {
        filters: filtersToApply,
        canShareFleet: this.config.canShareFleet(),
        shared: false,
        client: this.config.userInfo.client,
      },
      panelClass: 'spin-dialog-box',
    });

    // the dialog will ask the user if she wants to created the fleet
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
      this.appInfoService.userCreateVesselFleet(result.title, filtersToApply, result.shared);
      if (this.selectorConfig.fleetBuilderMode === 'create') {
        this.dialogRef.close(filtersToApply);
      }
      const confirmCreation = this.dialog.open(VesselFleetDialog, {
        data: {
          title: result.title,
          filters: filtersToApply,
          created: true,
          client: this.config.userInfo.client,
        },
        panelClass: 'spin-dialog-box',
      });
      confirmCreation.afterClosed().subscribe(applyCreatedFleet => {
        if (applyCreatedFleet) {
          this.appInfoService.userEnterLastFleetCreated();
          this.dialogRef.close({}); // We reset the filters if applying the fleet
        } else {
          this.dialogRef.close(this.selectorConfig.appliedFilters); // We keep filters previously applied otherwise
        }
      });
    });
  }

  public updateExcludedFilters(): void {
    if (this.excludedEntities.length) {
      const excludedFilter: FilterApplied = {
        id: 'excludedEntities',
        filterType: 'forceExclude',
        active: true,
        values: this.excludedEntities.map(d => d.id),
        propValue: this.entityInfo.idProp,
      };
      this.savedFilters['excludedEntities'] = excludedFilter;
    } else if (this.savedFilters['excludedEntities']) {
      delete this.savedFilters['excludedEntities'];
    }
  }

  public updateAdditionalFilters(): void {
    if (this.additionalEntities.length) {
      const additionalFilter: FilterApplied = {
        id: 'additionalEntities',
        filterType: 'forceInclude',
        active: true,
        values: this.additionalEntities.map(d => d.id),
        propValue: this.entityInfo.idProp,
      };
      this.savedFilters['additionalEntities'] = additionalFilter;
    } else if (this.savedFilters['additionalEntities']) {
      delete this.savedFilters['additionalEntities'];
    }

    this.afterSelectionOrFilterChange();
  }

  public restoreExcludedEntities(): void {
    this.excludedEntities = [];
    this.removableEntities.forEach(v => v.alreadyChosen = false);
    this.updateExcludedFilters();
    this.filterResults(this.savedFilters);
  }

  public addAdditionalEntity(entity: SelectorItem): void {
    this.additionalEntities.push(entity);
    this.updateAdditionalFilters();
    this.removeExcludedEntity(entity);
  }

  /**
   * Removes the item from the list of excluded entities.
   */
  public removeExcludedEntity(entity: SelectorItem): void {
    // remove the entity from the entity list
    remove(this.excludedEntities, e => e.id === entity.id);

    // remove the alreadyChosen mark for spin-filter
    const foundEntity = this.removableEntities.find(r => r.value === entity.id);
    if (foundEntity) {
      foundEntity.alreadyChosen = false;
    }
    this.updateExcludedFilters();
    this.filterResults(this.savedFilters);
  }

  public showExcludedResults(event: MouseEvent): void {
    const tooltipOpts: ListTooltipSettings = {
      title: `Excluded ${this.entityInfo.name}s`,
      items: this.excludedEntities.map(v => v.title),
    };
    this.$listTooltip.setTooltip(tooltipOpts, event);
  }

  public hideExcludedResults(_event: MouseEvent): void {
    hideTooltip(this.$listTooltip);
  }

  /**
   * Find the tab by ID and open it. Made to be called by components/pages which are using advanced
   */
  public openTab(tab: string): void {
    const index = this.tabs.findIndex(d => d.id === tab);
    this.tabChanged({ index: index });
  }

  public get loading(): boolean {
    return this.$advancedFilteringModel?.loading;
  }

  public async tabChanged(event: { index: number }): Promise<void> {
    this.selectedTabIndex = event.index;
    this.selectedModelConfig = this.tabs[this.selectedTabIndex].model;

    /** We need to refresh the UI so that the $advancedFilteringModel component exists. */
    this.cdRef.detectChanges();
    await this.switchTabDataset();
  }

  public get advancedModelActive(): boolean {
    return this.selectedTabIndex > 0;
  }

  /**
   * Update the list of vessels that are capable of doing the job.
   * The model has results columns that have to fit within certain ranges to work correctly
   */
  public updateAdvancedCapableVessels(): void {
    // until the model has been applied, we consider that all vessels for this model can do the job
    if (!this.$advancedFilteringModel?.modelApplied) {
      this.modelCapableVessels = Object.values(this.filteredData);
    }

    this.modelCapableVessels = this.$advancedFilteringModel?.getCapableVessels(this.filteredData);

    if (this.modelCapableVessels?.length > 0) {
      this.advancedModelSelectButtonText =
        `Select ${this.modelCapableVessels.length} vessels that meet the requirements`;
    } else {
      this.advancedModelSelectButtonText = 'No vessel meets the requirements';
    }
  }

  /**
   * Handler for the event raised by one of the advanced filtering models
   */
  public onAdvancedModelResults(results: AdvancedModelResults): void {
    if (!results) {
      return;
    }

    const layerFilter: LayerFilter = {};
    results.filtersApplied.forEach(filter => layerFilter[filter.id] = filter);
    this.set(layerFilter);
    this.filterResults(this.savedFilters, results.vesselsData);
    this.updateAdvancedCapableVessels();
    /*
     * required order of fields can depend on calculated fields that are part of the model
     * and that are checked in the update of "capable vessels"
     */
    this.reOrderFilteredResults();
    this.cdRef.detectChanges();
  }

  public onAdvancedModelQuery(): void {
    // TODO: if we need an indicator (loading etc) while advanced model is running we can put it here
  }

  /**
   * Advanced-Filtering-Model notifies the advanced-filters about the reset
   * All "additional-model-data" has to be removed
   */
  public onAdvancedModelReset(removedFields: Array<string>): void {
    const columnsFromAdvancedModel = this.$advancedFilteringModel?.resultsFields
      ? this.$advancedFilteringModel.resultsFields
      : [];

    /*
     * we will clear from the result fields, all that have some coloring/logic assigned
     * this might no be exhaustive (there might be calculated field, which does not have coloring logic)
     * but we don't want to add new booleans to the config right now
     */
    const toBeCleared = columnsFromAdvancedModel.filter(field => field.colorRanges);

    this.currentDataset.forEach(vessel => {
      toBeCleared.forEach(field => {
        if (vessel[field.id]) {
          delete vessel[field.id];
        }
      });
    });

    this.filteredResults.forEach(vessel => {
      toBeCleared.forEach(field => {
        if (vessel[field.id] != null) {
          delete vessel[field.id];
        }
      });
    });

    removedFields.forEach(f => {
      if (this.savedFilters[f]) {
        delete this.savedFilters[f];
      }
    });

    this.modelCapableVessels = [];
    this.updateAdvancedCapableVessels();
    this.filterResults(this.savedFilters);
    this.cdRef.detectChanges();
  }

  public getFilterContentClass(): string[] {
    if (this.advancedModelActive) {
      return ['reduce-width-content'];
    }
    return ['full-width-content'];
  }

  public getResultContentClass(): string[] {
    if (this.advancedModelActive) {
      return ['wti-result-list'];
    }
    return ['standard-result-list'];
  }

  public getResultFieldValue(field: FieldSettings, { itemData }: SelectorItem): string {
    return DatabaseHelper.formatFieldValue(field, itemData);
  }

  public toggleOrderingByField(field: FieldSettings): void {
    const fieldId = field.button ? field.button.require : field.id;
    const fieldOrdering = this.$advancedFilteringModel.fieldOrdering;
    if (fieldOrdering.propOrder === fieldId) {
      fieldOrdering.orderDirection = fieldOrdering.orderDirection === 'asc' ? 'desc' : 'asc'; // inverse order
    } else {
      fieldOrdering.propOrder = fieldId;
      fieldOrdering.orderDirection = 'desc';
    }
    this.reOrderFilteredResults();
  }

  /**
   * Angular virtual scroll adds a translation to the parent div element.
   * This is an issue if we combine virtual scroll with a sticky div (sticky header usually)
   * This calculates the inverse of the translation that is added by angular,
   * so that it can be added to the sticky header
   */
  public get inverseOfTranslation(): string {
    if (!this.viewPort || !this.viewPort['_renderedContentOffset']) {
      return '-0px';
    }
    const offset = this.viewPort['_renderedContentOffset'];
    return `-${offset}px`;
  }

  public get resultsTitle(): string {
    if (this.advancedModelActive && this.selectedModelConfig?.description) {
      return `${this.entityInfo.name} selection - ${this.selectedModelConfig.description}`;
    }
    return `${this.entityInfo.name} selection`;
  }

  public getResultTooltip(result: SelectorItem): string {
    return result.checked && !result.filteredIn
      ? 'This selected values is orange because is not matching current filters'
      : this.getMessageIfCannotSelect(result);
  }

  public get selectedItemsInformation(): string {
    if (this.selectedResults.length > 0) {
      return `<strong>Selected results (${this.resultsCount})</strong>`;
    }

    if (this.advancedModelActive || this.selectorConfig.mustSelectItems) {
      return 'You must select some results';
    }

    return `Filtered results (${this.resultsCount})`;
  }

  public standardFiltersStyle(): { display: string } {
    if (this.advancedModelActive) {
      return {
        display: 'none',
      };
    }
    return {
      display: 'block',
    };
  }

  public onFieldButton(buttonEvent: ActionEvent): void {
    this.selectorConfig.onAction?.(buttonEvent);
  }

  public showButton(button: Button, { itemData }: SelectorItem): boolean {
    const show = DatabaseHelper.shouldShowButton(button, itemData, this.config);
    if (show && button.type === 'navigate') {
      return this.config.checkRightsFromUrl(button.href);
    }
    return show;
  }

  public externalUrlFromLink(item: SelectorItem, field: FieldSettings): string {
    return this.getItemSpec(item, `${field.id}__link`) ?? this.getItemSpec(item, field.id);
  }

  public externalLinkTitle({ itemData }: SelectorItem, field: FieldSettings): string {
    return field?.linkPropTitle ? NavigationHelper.getLinkTitle(field, itemData) : field.title;
  }

  public orderArrowClass(field: FieldSettings): { [param: string]: boolean } {
    return {
      'arrow-visible': this.fieldOrdering?.propOrder === field.id,
      'arrow-hidden': this.fieldOrdering?.propOrder !== field.id,
    };
  }

  public getResultItemClass(result: SelectorItem): { [param: string]: boolean } {
    return {
      'less-visible-result': this.checkedResultsCount > 0,
      'disabled-result': !this.entityFilterId || !result.checked && !this.canSelect(),
      'selected-result': result.checked,
      'green-border': result.checked && result.filteredIn,
      'orange-border': result.checked && !result.filteredIn,
      'result-array-row': this.advancedModelActive,
    };
  }
}
