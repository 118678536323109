import { Collection, Dexie, IndexableType, Table } from 'dexie';

import { DatabaseSchema, PersistentDbConfig } from './persistent-db.types';
import { ErrorWithFingerprint } from '../../helpers/sentry.helper';

export class PersistentDb<StrictDatabaseSchema> {
  /**
   * This class is responsible to:
   * - setup the Indexed Db thanks to Dexie;
   * - catch Dexie errors;
   * - provide simple get, put, bulkPut interface to interact with the Db.
   *
   * StrictDatabaseSchema is used to achieve strict typing on tableName on the put, get, etc. functions.
   */

  private _db: Dexie;

  constructor(private dbConfig: PersistentDbConfig<StrictDatabaseSchema>, private userEmail: string) {
  }

  /** This function must be used inside a try/catch block. */
  private async getDb(): Promise<Dexie> {
    if (!this._db?.isOpen()) {
      await this.setup();
    }
    return this._db;
  }

  /**
   * This async function must be awaited before use. It deletes previous databases and create a new one in case of major
   * version update and upgrade db in case of minor version update.
   */
  public async setup(): Promise<void> {
    if (!this.userEmail) {
      return;
    }

    this.deletePreviousDatabases();

    const dbName = this.buildDbName();
    this._db = new Dexie(dbName);
    this._db.version(this.dbConfig.databaseVersion.minor).stores(this.dbConfig.databaseSchema as DatabaseSchema)
      .upgrade(
        async tx => {
          await Promise.all(
            Object.keys(this.dbConfig.databaseSchema).map(async tableName => await tx.table(tableName).clear()),
          );
        },
      );

    try {
      await this._db.open();
    } catch (error) {
      /*
       *  This case can happen after an upgrade of DEXIE_MINOR_VERSION when user has multi tabs:
       *  - tab 1 and tab 2 are on same version
       *  - tab 1 is refreshed and gets new version, upgrades Dexie version
       *  - user goes on tab 2 without refreshing => Dexie tries to open db with older version number but can't handle
       *  downgrades => app is unusable
       */
      if (error instanceof Dexie.VersionError) {
        alert(`
        A new version of the application is available!
        This page will be refreshed to get the latest software updates.`);
        console.warn('Dexie downgrade detected - reloading the app to enforce new version.');
        window.location.reload();
      } else {
        console.error(error);
      }
    }
  }

  /**
   * Deletes all previous databases in case of DatabaseSchema.databaseVersion.major change.
   */
  private async deletePreviousDatabases(): Promise<void> {
    for (
      let version = 1;
      version < this.dbConfig.databaseVersion.major;
      version += 1
    ) {
      const dbNameToDelete = this.buildDbName(this.dbConfig.databaseName, version);
      await Dexie.delete(dbNameToDelete);
    }
  }

  private buildDbName(dbName = this.dbConfig.databaseName, version = this.dbConfig.databaseVersion.major): string {
    return 'spindb' + (dbName === '' ? '' : '_' + dbName) + '_v' + version + '_'
      + this.userEmail.replace(/\./g, '').replace('@', '_');
  }

  /**
   * Allows to use Dexie function chaining to query table items.
   * @param selector function e.g. reportTable => reportTable.where({countryId: 'uk'}).and({vesselId: 1}).delete()
   */
  public async query<ItemInterface>(
    tableName: keyof StrictDatabaseSchema,
    selector: (table: Table) => Promise<ItemInterface>,
  ): Promise<ItemInterface> {
    try {
      const db = await this.getDb();
      return selector(db.table<ItemInterface>(tableName as string));
    } catch (e) {
      PersistentDb.handleDexieErrors(e);
    }
  }

  /** Get first entry matching Dexie function chaining to query Collection. */
  public queryFirst<ItemInterface>(
    tableName: keyof StrictDatabaseSchema,
    selector: (table) => Collection,
  ): Promise<ItemInterface> {
    return this.query(tableName, (table: Table) => selector(table).first());
  }

  public queryAll<ItemInterface>(
    tableName: keyof StrictDatabaseSchema,
    selector: (table) => Collection = table => table,
  ): Promise<ItemInterface[]> {
    return this.query(tableName, (table: Table) => selector(table).toArray());
  }

  /** Get a single table item thanks to tableName and key. */
  public get<ItemInterface>(tableName: keyof StrictDatabaseSchema, key: IndexableType): Promise<ItemInterface> {
    return this.query(tableName, (table: Table) => table.get(key));
  }

  /** Put a single element. The key must appear in `item`. */
  public put<ItemInterface>(tableName: keyof StrictDatabaseSchema, item: ItemInterface): Promise<IndexableType> {
    return this.query(tableName, (table: Table) => table.put(item));
  }

  /** Put a bulk of items. */
  public bulkPut<ItemInterface>(
    tableName: keyof StrictDatabaseSchema,
    items: ItemInterface[],
  ): Promise<IndexableType> {
    return this.query(tableName, (table: Table) => table.bulkPut(items));
  }

  /** Delete a single table item thanks to its key. */
  public delete(tableName: keyof StrictDatabaseSchema, key: IndexableType): Promise<void> {
    return this.query(tableName, (table: Table) => table.delete(key));
  }

  /** Return all table records */
  public async queryAllTables(): Promise<{ tableName: keyof StrictDatabaseSchema; results: unknown[] }[]> {
    const tableNames = Object.keys(this.dbConfig.databaseSchema) as (keyof StrictDatabaseSchema)[];

    return Promise.all(tableNames.map(
      async (tableName): Promise<{ tableName: keyof StrictDatabaseSchema; results: unknown[] }> => {
        const tableResults = await this.queryAll(tableName);
        return {
          tableName: tableName,
          results: tableResults,
        };
      },
    ));
  }

  /** Clear all tables configured in `StrictDatabaseShema` of current db. */
  public clearAllTables(): Promise<void[]> {
    const tableNames = Object.keys(this.dbConfig.databaseSchema) as (keyof StrictDatabaseSchema)[];

    return Promise.all(tableNames.map(
      tableName => this.query(tableName, (table: Table) => table.clear()),
    ));
  }

  public static handleDexieErrors(error: Error): never {
    if (error.message.includes('Refresh the page to try again')) {
      location.reload();
    }
    /**
     * From @tourfl: For now we throw a global fingerprint that we'll refine later when we'll have different examples.
     */
    throw new ErrorWithFingerprint(`Dexie error occurred: ${error.message}`, ['dexie-error']);
  }
}
