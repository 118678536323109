import { Injector } from '@angular/core';

import { SelectorHelper } from 'src/selector/selector.helper';
import { DataLoader } from 'src/data-loader/data-loader';
import { RawDataPoint } from 'src/graph/chart-types';
import { SelectorConfig } from '../selector/selector.types';
import { FieldSettings, FieldValue, Fieldset, FilterApplied, LayerFilter } from '../helpers/types';
import { FilterHelper } from './filter-helper';

/**
 * Wraps an array of fieldsets. Provides methods to quickly look-up filters, get default state and others
 * It is meant to be used by SidebarFieldsetsGroup which holds 2 FieldsetsCollections (standard+advanced)
 */
export class FieldsetCollection {
  private dataLoader: DataLoader;
  private defaultValues: { [id: string]: FieldValue } = {};
  public filterById: { [id: string]: FieldSettings } = {};
  public fieldsets: Fieldset<FieldSettings>[];

  constructor(injector: Injector, fieldsets: Fieldset[]) {
    this.dataLoader = injector.get(DataLoader);

    if (fieldsets === null || fieldsets === undefined) {
      fieldsets = [];
    }
    fieldsets.forEach(fieldset => {
      fieldset.fields.forEach(field => {
        FilterHelper.initializeFieldTypeAndProp(field);
        if (field.default) {
          this.defaultValues[field.id] = field.default;
        }
        this.filterById[field.id] = field;
      });
    });

    this.fieldsets = fieldsets;
  }

  public findFilter(id: string): FieldSettings {
    return this.filterById[id];
  }

  public get filters(): FieldSettings[] {
    return Object.values(this.filterById);
  }

  public getDefaultAppliedFilters(): LayerFilter {
    const filters: LayerFilter = {};

    this.filters.forEach(field => {
      if (field.default) {
        const defaultValue = FilterHelper.getDefaultFieldValue(field);
        if (defaultValue == null) return;
        const filter: FilterApplied = {
          ...field,
          active: true,
          propValue: field.propValue ?? field.id,
          values: defaultValue,
        };
        filters[field.id] = filter;
      }
    });
    return filters;
  }

  public getAllFilterIds(): string[] {
    return Object.keys(this.filterById);
  }

  public getAllFilterIdsExceptThoseInOtherCollection(otherCollection: FieldsetCollection): string[] {
    return Object.keys(this.filterById).filter(filter => !otherCollection.findFilter(filter));
  }

  public extractFiltersAppliedOnMe(filters: LayerFilter): LayerFilter {
    return FilterHelper.getFiltersMatchingIds(filters, this.getAllFilterIds());
  }

  public removeFilter(filterId: string): void {
    for (const fieldset of this.fieldsets) {
      // Using an old style "for of" allows to return early.
      const fieldIndex = fieldset.fields.findIndex(f => f.id === filterId);
      if (fieldIndex > -1) {
        fieldset.fields.splice(fieldIndex, 1);
        delete this.filterById[filterId];
        return;
      }
    }
  }

  /**
   * Heavy populates all fieldset that have populateUrl defined.
   * Examples: Tender Scenario filter on tender analysis and schedule in Spinrig
   * Returns true if at least one fieldset was populated
   */
  public async heavyPopulateFieldsets(): Promise<boolean> {
    if (!this.fieldsets.some(fieldset => fieldset.populateUrl)) return false;
    for (const fieldset of this.fieldsets) {
      if (fieldset.populateUrl) {
        const values = await this.dataLoader.get<RawDataPoint[]>(fieldset.populateUrl);
        for (const field of fieldset.fields) {
          FilterHelper.populateField(field, values, false);
        }
      }
    }
    return true;
  }

  public static getFiltersApplicableOnFieldset(
    filters: LayerFilter,
    fieldset: Fieldset<FieldSettings>,
  ): LayerFilter {
    const filtersIds = fieldset.fields.map(f => f.id);
    return FilterHelper.getFiltersMatchingIds(filters, filtersIds);
  }
}

/**
 * Advanced Fieldsets collection differs from the standard Fieldsets collection in few ways:
 * - It maintains the state of the selected filters - it's easier then for sidebar to handle them
 * - It is linked to a selector which has implications:
 * - The selector can be linked to and entityId (eg. vessel). Filters that are part of this fieldset
 *   have to filter out (remove) the entityId filter
 * - It is aware of filters that are also part of standard filters.
 *   Visually the standard filters are visible also in the fieldset but Advanced Fieldset Collection
 *   splits them apart and knows which filters are only advanced. It can report easily to the sidebar
 *   which filters are really only advanced and what is their state
 */
export class AdvancedFieldsetCollection extends FieldsetCollection {
  public selectorButtonLabel: string;
  public selectorConfig: SelectorConfig;
  public appliedFiltersCount: number;
  private readonly advancedFiltersState: LayerFilter;
  private readonly filtersNotInStandard: string[];
  public entityFilterId: string;

  constructor(
    injector: Injector,
    selectorConfig: SelectorConfig,
    advancedState: LayerFilter = {},
    standardFilters: FieldsetCollection = null,
  ) {
    super(injector, selectorConfig.fieldsets);
    this.advancedFiltersState = advancedState;
    this.selectorConfig = selectorConfig;
    this.selectorButtonLabel = SelectorHelper.getSelectorButtonLabel(this.selectorConfig);
    if (standardFilters) {
      this.filtersNotInStandard = this.getAllFilterIdsExceptThoseInOtherCollection(standardFilters);
    } else {
      this.filtersNotInStandard = this.getAllFilterIds();
    }

    this.entityFilterId = this.selectorConfig.entity?.filterId;
    // permanently remove the field corresponding to the selector's entity
    if (this.entityFilterId) {
      this.removeFilter(this.entityFilterId);
    }
  }

  /**
   * Sets the state of the advanced filters from the outside. The collection only keeps in it's
   * state filters that are not present in the advanced filters
   */
  public setFilters(filters: LayerFilter): void {
    let appliedFilterCount = 0;
    for (const filterId of this.filtersNotInStandard) {
      if (filterId in filters) {
        this.advancedFiltersState[filterId] = filters[filterId];
        appliedFilterCount++;
      } else {
        delete this.advancedFiltersState[filterId];
      }
    }
    this.appliedFiltersCount = appliedFilterCount;
  }

  /**
   * Used by sidebar or sidebar layer to pick a filter which will be passed as "latest"
   * that will allow for autozoom.
   */
  public getAppliedEntityOrFirstFilter(filters: LayerFilter): FilterApplied {
    const latestFilter = filters[this.selectorConfig.entity.filterId]
      ?? Object.values(filters)?.[0];
    return latestFilter;
  }
}
