import { AfterViewInit, ChangeDetectorRef, Directive, EventEmitter, Injector, Input, OnInit,
  Output } from '@angular/core';

import { camelCase, cloneDeep, snakeCase } from 'lodash-es';

import { Button, Era, FieldOrdering, FieldSettings, Fieldset, FilterApplied, HasVesselId,
  LayerFilter } from '../helpers/types';
import { FilterHelper } from '../filters/filter-helper';
import { AdvancedModelConfig, SelectorItem } from './selector.types';
import { DataHelpers } from '../helpers/data-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { getChained } from '../data-loader/ref-data-provider';

export type VesselsUpdateData = { [vesselId: number]: unknown };

export interface AdvancedModelResults {
  vesselsData: VesselsUpdateData;
  filtersApplied: FilterApplied[];
}

type EmptyObject = Record<string, never>;

@Directive()
export abstract class AdvancedFilteringModel<
  ObligatoryParameters extends object,
  OptionalParameters extends object,
  CalculatedParameters extends object,
  Results extends { vessels: VesselsUpdateData },
> implements OnInit, AfterViewInit {
  protected currentServerRequest: Promise<object>;
  protected dataLoader: DataLoader;
  protected cdRef: ChangeDetectorRef;
  protected initialFilters: LayerFilter;

  public modelApplied: boolean = false;
  public loading: boolean = false;
  public isValid: boolean = true;

  public obligatoryFieldset: Fieldset;
  public optionalFieldset: Fieldset;
  public calculatedFieldset: Fieldset;

  public obligatoryParameters: ObligatoryParameters | EmptyObject = {};
  public optionalParameters: OptionalParameters | EmptyObject = {};
  public calculatedParameters: CalculatedParameters | EmptyObject = {};

  /**
   * List of columns (fields) that should be added to the output vessel list
   * which contain information specific to the model
   */
  public resultsFields: FieldSettings[];

  public resultsButtons: Button[];

  public savedParameters: ObligatoryParameters & OptionalParameters;

  protected results: Results;

  @Input()
  public config: AdvancedModelConfig;

  @Output()
  onQuery = new EventEmitter<void>();

  @Output()
  onResults = new EventEmitter<AdvancedModelResults>();

  @Output()
  onReset = new EventEmitter<Array<string>>();

  @Output()
  onError = new EventEmitter<string>();

  constructor(injector: Injector) {
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  public abstract getModelVessels(): Promise<readonly HasVesselId[]>;
  public abstract checkModelCoherency(): boolean;
  public abstract updateCalculatedParameters(results: Results);
  public abstract loadFiltersConfig(): Promise<void>;
  public idProp = 'vesselFeatureId';

  public fieldOrdering: FieldOrdering = {
    propOrder: 'title',
    orderDirection: 'asc',
  };

  public optionalParametersToApply(): OptionalParameters {
    const optionalParamsToApply = cloneDeep(this.optionalParameters) as OptionalParameters;
    this.optionalFieldset.fields.forEach(optionalField => {
      if (
        this.optionalParameters[optionalField.id] === null
        || this.fieldRangeInvalid(optionalField, this.optionalParameters[optionalField.id])
      ) {
        optionalParamsToApply[optionalField.id] = optionalField.default;
      }
    });
    return optionalParamsToApply;
  }

  public checkAdvancedParameters(): boolean {
    for (const advancedField of this.optionalFieldset.fields) {
      if (this.fieldRangeInvalid(advancedField, this.optionalParameters[advancedField.id])) {
        return false;
      }
    }
    return true;
  }

  public setDefaultOptionalParams(): void {
    this.optionalFieldset.fields.forEach(optionalField => {
      if (optionalField.default) {
        this.optionalParameters[optionalField.id] = optionalField.default;
      }
    });

    this.valueChange();
  }

  public ngOnInit(): void {
    this.obligatoryFieldset = this.config.obligatoryFieldset;
    this.optionalFieldset = this.config.optionalFieldset;
    this.calculatedFieldset = this.config.calculatedFieldset;
    this.resultsFields = this.config.resultsFieldset.fields;
    this.resultsButtons = this.config.resultsButtons ?? [];

    this.optionalFieldset.fields.forEach(optionalField => {
      if (optionalField.default) {
        this.optionalParameters[optionalField.id] = optionalField.default;
      }
    });

    this.loadFiltersConfig().then(() => {
      this.setInitialFilters();
      this.valueChange();
    });
  }

  public ngAfterViewInit(): void {
    this.resetOptionalParameters(false);
    this.valueChange();
  }

  /**
   * Any change in the model parameters should call this method
   */
  public valueChange(): void {
    this.isValid = this.checkModelCoherency();
    if (!this.isValid) {
      if (this.currentServerRequest !== undefined) {
        this.dataLoader.cancelRequest(this.currentServerRequest);
      }
      this.loading = false;
      this.cdRef.detectChanges();
      return;
    }
    const params = this.prepareParameters();
    this.updateModel(params);
    this.cdRef.detectChanges();
  }

  public resetCalculatedResults(): void {
    if (this.modelApplied) {
      this.calculatedParameters = {};
      this.modelApplied = false;
      this.onReset.emit(this.obligatoryFieldset.fields.map(f => f.id));
    }
  }

  public resetAllParameters(): void {
    this.obligatoryParameters = {};
    this.resetCalculatedResults();
  }

  public resetOptionalParameters(forceReset: boolean): void {
    for (const fieldId in this.optionalParameters) {
      const optionalField = this.getOptionalField(fieldId);
      if (this.optionalParameters[fieldId] && !forceReset) {
        continue;
      }
      if (optionalField.default) {
        this.optionalParameters[fieldId] = optionalField.default;
      }
    }
  }

  abstract prepareParameters(): ObligatoryParameters & OptionalParameters;

  abstract runModel(): Promise<Results>;

  public clearCalculatedParams(): void {
    this.calculatedParameters = {};
  }

  public async updateModel(calculatorParameters: ObligatoryParameters & OptionalParameters): Promise<void> {
    this.clearCalculatedParams();
    this.loading = true;
    this.cdRef.detectChanges();
    this.modelApplied = false;

    this.onQuery.emit();
    if (this.currentServerRequest !== undefined) {
      this.dataLoader.cancelRequest(this.currentServerRequest);
    }

    const advancedFilteringResults: AdvancedModelResults = {
      filtersApplied: [],
      vesselsData: [],
    };

    this.savedParameters = calculatorParameters;
    for (const parameterId in this.savedParameters) {
      if (!this.savedParameters[parameterId]) {
        continue;
      }
      const wtiFilter: FilterApplied = {
        id: parameterId,
        filterType: 'number',
        active: true,
        propValue: '__notAppliedFilter',
        notApplied: true,
        values: [this.savedParameters[parameterId]],
      };
      advancedFilteringResults.filtersApplied.push(wtiFilter);
    }
    try {
      this.results = await this.runModel();
    } catch (error) {
      this.onError.emit(error);
      return;
    }

    advancedFilteringResults.vesselsData = this.results.vessels;
    this.updateCalculatedParameters(this.results);
    this.modelApplied = true;
    this.loading = false;
    this.cdRef.detectChanges();
    this.onResults.emit(advancedFilteringResults);
  }

  public fieldRangeInvalid(field: FieldSettings, value: unknown): boolean {
    const hasValue = value || value === 0;

    if (field.filterType == 'number' || field.type == 'number') {
      return field.required
        ? (hasValue ? (!this.isNumberValueValid(value, field)) : true)
        : (hasValue ? (!this.isNumberValueValid(value, field)) : false);
    } else {
      return field.required ? !hasValue : false;
    }
  }

  private isNumberValueValid(value: any, field: FieldSettings): boolean {
    if (field.validRange?.length) {
      return value <= field.validRange[1] && value >= field.validRange[0];
    } else if (field.min || field.min == 0) {
      return value >= field.min;
    } else if (field.max) {
      return value <= field.max;
    }
    return true;
  }

  public fieldDescription(field: FieldSettings): string {
    if (field.description) {
      return field.description;
    }

    if (field.validRange) {
      return `Value must be between ${field.validRange[0]} and ${field.validRange[1]}`;
    }
  }

  public getErrorMessage(field: FieldSettings): string {
    let errorMessage = `${field.title} required.`;
    if (field.validRange) {
      errorMessage = `Value should be between ${field.validRange[0]} and ${field.validRange[1]}.`;
    }

    if (field.default) {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      const defaultToPrint: string = (field.default as Era).title ?? field.default.toString();
      errorMessage += ` Used value: ${defaultToPrint}.`;
    }
    return errorMessage;
  }

  public getObligatoryField(fieldId: string): FieldSettings {
    return FilterHelper.findFieldInFieldsets([this.obligatoryFieldset], (field: FieldSettings) => field.id === fieldId);
  }

  public getOptionalField(fieldId: string): FieldSettings {
    return FilterHelper.findFieldInFieldsets([this.optionalFieldset], (field: FieldSettings) => field.id === fieldId);
  }

  public getCapableVessels(filteredData: { [vesselId: number]: SelectorItem }): SelectorItem[] {
    if (!this.modelApplied) {
      return [];
    }

    const capableVessels: SelectorItem[] = [];
    for (const vesselId in filteredData) {
      const vessel = filteredData[vesselId];
      const meetRequirements = getChained(vessel.itemData, 'meetRequirements');
      if (meetRequirements && (meetRequirements === 'capable' || meetRequirements === 'probably_capable')) {
        capableVessels.push(vessel);
      }
    }

    return capableVessels;
  }

  public setInitialFilters(): void {
    if (!this.initialFilters) {
      this.initialFilters = {};
    }
    for (const field of this.obligatoryFieldset.fields) {
      if (field.id in this.initialFilters) {
        this.obligatoryParameters[field.id] = Number(this.initialFilters[field.id].values[0]);
      }
    }

    for (const field of this.optionalFieldset.fields) {
      if (field.id in this.initialFilters) {
        this.optionalParameters[field.id] = Number(this.initialFilters[field.id].values[0]);
      }
    }
  }

  /**
   * Takes a state with filters and applied values and fills the model with them
   */
  public set(filters: LayerFilter): void {
    this.initialFilters = filters;
    this.setInitialFilters();
  }

  public formatCalculatedParameters(): void {
    for (const calculatedField of this.calculatedFieldset.fields) {
      if (calculatedField.format) {
        this.calculatedParameters[calculatedField.id] = DataHelpers.formatNumber(
          this.calculatedParameters[calculatedField.id],
          calculatedField.format,
        );
      }
    }
  }

  /**
   * This function take an objToCopy and recursively copy it into obj.
   * If snakeToCamel is true => transform from snake case to camel case
   * If snakeToCamel is false => transform from camel case to snake case
   */
  public static recursivelyTransformCamelSnake<T extends object = object>(
    obj: object,
    objToCopy: object,
    snakeToCamel: boolean,
  ): T {
    if (typeof objToCopy !== 'object') {
      return objToCopy;
    }
    for (const field in objToCopy) {
      const transformedField = snakeToCamel ? camelCase(field) : snakeCase(field);
      if (Array.isArray(objToCopy[field])) {
        obj[transformedField] = objToCopy[field].map(row => this.recursivelyTransformCamelSnake({}, row, snakeToCamel));
      } else if (typeof objToCopy[field] === 'object') {
        obj[transformedField] = {};
        this.recursivelyTransformCamelSnake(obj[transformedField], objToCopy[field], snakeToCamel);
      } else {
        obj[transformedField] = objToCopy[field];
      }
    }
    return obj as T;
  }
}
