import { AfterViewInit, ChangeDetectionStrategy, Component, input, OnDestroy } from '@angular/core';

import { PEARL_BASE_SELECT_IMPORTS, PEARL_BASE_SELECT_PROVIDERS, PearlBaseSelect } from './pearl-base-select';
import { PearlSelectOption, PearlSelectOptionValueType } from './pearl-base-select.type';

@Component({
  selector: 'pearl-autocomplete',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: PEARL_BASE_SELECT_IMPORTS,
  providers: PEARL_BASE_SELECT_PROVIDERS,
  templateUrl: './pearl-base-select.html',
  styleUrls: ['./pearl-base-select.scss', '../pearl-form-field.component.scss'],
})
export class PearlAutocompleteComponent<T extends PearlSelectOptionValueType> extends PearlBaseSelect<T>
  implements AfterViewInit, OnDestroy
{
  public override readonly searchable = input(true);

  /**
   * @description Sort options provided by the user
   *   1 - Sort by selected status, we want those to be at the top of displayed options
   *   2 - Sort by group name, if any
   *   3 - Sort by the user provided sort function
   */
  protected override sortOptions(options: PearlSelectOption<T>[]): PearlSelectOption<T>[] {
    return options.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;

      if (a.groupName !== b.groupName) {
        if (!a.groupName) return -1;
        if (!b.groupName) return 1;
        return a.groupName.localeCompare(b.groupName);
      }

      if (this.sortFn()) return this.sortFn()(a, b);

      return a.title.localeCompare(b.title);
    });
  }
}
