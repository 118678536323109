import { NgClass, NgIf, NgSwitch, NgSwitchCase, NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, booleanAttribute, computed, inject, input,
  signal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { ThemePalette } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { Params } from '@angular/router';

import { PearlIconComponent } from '../icons/pearl-icon.component';
import { PearlIcon, PearlIconSize } from '../icons/icons';
import { RemoveParentNodeDirective } from '../../../directives/remove-parent-node.directive';
import { ProductAnalyticsService } from '../../../product-analytics/product-analytics.service';
import { TrackedActionType } from '../../../product-analytics/product-analytics.types';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'alternative'
  | 'icon'
  | 'icon-compact'
  | 'sticky'
  | 'icon-sticky';
export type ButtonMode = 'default' | 'warning';
export type ButtonSize = 'default' | 'small';

export const PearlButtonImports = [
  RemoveParentNodeDirective,
  PearlIconComponent,
  MatButtonModule,
  MatIconModule,
  NgSwitch,
  NgSwitchCase,
  NgTemplateOutlet,
  NgIf,
  NgClass,
];

@Component({
  standalone: true,
  selector: 'pearl-button',
  templateUrl: './pearl-button.component.html',
  styleUrls: ['./pearl-button.component.scss'],
  imports: PearlButtonImports,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.platform-button]': 'mode() === "default"',
    '[class.meaningful-button]': 'mode() !== "default"',
    '[class.button-small]': 'size() === "small"',
    '[class.disable-events]': 'disabled()',
    '[class.button-active]': 'isActive()',
  },
})
export class PearlButtonComponent {
  public readonly disabled = input<boolean>(false);
  public readonly href = input<string | undefined>(undefined);
  public readonly icon = input<PearlIcon>(undefined);
  public readonly isLink = input<boolean>(false);
  public readonly matIconId = input<string>('');
  public readonly mode = input<ButtonMode>('default');
  public readonly size = input<ButtonSize>('default');
  public readonly target = input<string>('');
  public readonly isActive = input(false, { transform: booleanAttribute });
  public readonly ariaLabel = input<string>('');
  public readonly trackedAction = input<TrackedActionType>(null);
  public readonly trackedParams = input<Readonly<Params>>({});
  public readonly iconBadgeContent = input<string>(undefined);

  public readonly _type = signal<ButtonType>('primary');

  public readonly class = computed(() => `pearl-${this._type()}`);
  public readonly color = computed<ThemePalette>(() => this.mode() === 'default' ? 'primary' : 'accent');
  public readonly iconSize = computed<PearlIconSize>(() => this.size() === 'small' ? 20 : 24);
  private readonly productAnalyticsService = inject(ProductAnalyticsService);

  @Input({ required: false })
  public set type(type: ButtonType) {
    this._type.set(type);
  }

  @Input({ required: false })
  /**
   * This input is required. Because when using buttons in mat-dialog type attribute as input name
   * is not allowed.
   */
  public set buttonType(type: ButtonType) {
    this._type.set(type);
  }

  public trackClick(): void {
    if (this.trackedAction()) {
      this.productAnalyticsService.trackAction(this.trackedAction(), this.trackedParams());
    }
  }
}
