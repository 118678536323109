import type { Dayjs } from 'dayjs';

export type DatetimeSection = 'date' | 'time' | 'timezone';
export type DatetimeElement = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second';
export const DATETIME_ELEMENTS: DatetimeElement[] = ['year', 'month', 'day', 'hour', 'minute', 'second'];

export type CalendarView = 'month' | 'year' | 'multi-year';
export type ClockView = 'hour' | 'minute';
export type DatepickerView = CalendarView | ClockView | 'timezone';

export function isCalendarView(view: DatepickerView): view is CalendarView {
  return ['multi-year', 'year', 'month'].includes(view);
}
export function isClockView(view: DatepickerView): view is ClockView {
  return ['hour', 'minute'].includes(view);
}

export type DatePickerControls = 'previous' | 'next';

export type DateFilter = (date: Dayjs | null) => boolean;

export type DatePrecision = 'year' | 'month' | 'day';
export type TimePrecision = 'minute' | 'second';
export type DatetimePrecision = DatePrecision | TimePrecision;

export function isDatePrecision(precision: DatetimePrecision): precision is DatePrecision {
  return ['year', 'month', 'day'].includes(precision);
}
export function isTimePrecision(precision: DatetimePrecision): precision is TimePrecision {
  return ['minute', 'second'].includes(precision);
}
