import { QUnitType } from 'dayjs';
import { Legend, PlotDatum } from 'plotly.js';

import { BaseComponentSettings, BaseEndpointPattern, BaseEndpointType, Button, CalcOperation, ChartDisplayMode,
  ChartExtraLines, ChartType, Color, DateTimezone, EntityFieldDefinition, Era, FieldSettings, FilterType, GraphOrderBy,
  InitBrushConfigValue, InitBrushUnit, Interval, IntervalField, NumOrString, SeriesSplit, SortDirection,
  SpinTimeUnit } from '../helpers/types';
import { EntityTableComponentSettings } from '../helpers/config-types';
import { AvailablePages, PageLinkHelper } from '../helpers/page-link-helper';
import { ColorDefinition } from '../helpers/legend-types';

/**
 * Plotly enumerates
 * FIXME: this could be replaced by @types/plotly
 */

// Line styles
export type PlotlyLineStyle = 'solid' | 'dash' | 'dot' | 'dashdot';
// Line shapes
export type PlotlyLineShape = 'linear' | 'spline' | 'hv' | 'vh' | 'hvh' | 'vhv';
// Axis types
export type PlotlyAxisType = '-' | 'linear' | 'log' | 'date' | 'category' | 'multicategory';
// Bar modes
export type PlotlyBarMode = 'group' | 'stack' | 'relative';
// Plotly snap method
export type PlotlySnapMode = 'data' | 'cursor' | 'hovered data';
/// Plotly range modes
export type PlotlyRangeMode = 'tozero' | 'nonnegative' | 'normal';

/**
 * Metric filters
 */

export type FilterMetricStyle = 'tab' | 'dropdown';

export interface FilterMetric {
  prop: string;
  propTitle: string;
  showAll?: boolean;
  allOptionTitle?: string;
  style: FilterMetricStyle;
  ordered?: string[];
  defaultTab?: string;
  tabFilter?: FieldSettings;
  values?: RawDataPoint[];
}

export type TimeMode = 'temporalBreakdown' | 'adjustToInterval';

/**
 * Selectable value ancestor
 */
export interface SelectableValue {
  /** Required ID (used as alias) */
  id: string;
  /** Title */
  title: string;
  /** Group values with same groupTitle */
  groupTitle?: string;
  /** Selectable value, eg. 'columnName', 'avg:columnName' or any supported formulae (SelectableMetric) */
  value: string;
  /** Is disabled */
  disabled?: boolean;
  /*
   * Define if metric is summable or not.
   * True by default, false if the 'calc' expression contains 'avg:', 'psum:', 'pcount:' or '/sum:'
   */
  summable?: boolean;
  /** Null options (to set includNullSplit and includeNullGroup) */
  includeNull?: boolean;
  nullTitle?: string;
  /** pageLink is a quick form for page url (e.g. 'vessel:vesselId') */
  pageLink?: string;
}

/**
 * Base operation
 */
export type BaseOperator = '*' | '/' | '+' | '-';

/**
 * Simple operation
 * @examples
 *   - Single variable: columnName
 *   - With operation:  avg:otherColumn
 */
export interface SimpleAggregation {
  /** Single column */
  variable: string;
  /** Single operation (optional, depends on chart type: none for scatter, q for boxplot) */
  operation?: CalcOperation;
}

/**
 * Complex operation
 * @examples
 *   - Single variable: sum:variable/avg:variable2
 *   - Multi-variables: sum(variable*variable2)/avg:variable2
 * The second form only happens with heavy charts, parsing will be incomplete with 'variables' still containing
 * operations (like 'variable*variable2') but that won't cause any errors as the 'serialized' form is sent to the back.
 */
export interface ComplexAggregation {
  /** Serialized operation (for heavy analytics) */
  serialized?: string;
  /** Left/right operator */
  arithmeticOperator: BaseOperator;
  /** List of variables */
  variables: string[];
  /** List of modes */
  operations: CalcOperation[];
}

/**
 * Complex nested operation
 * @examples
 *   - Nested simple operation:  avg(sum:backlog_years:groupby:date)
 *   - Nested with constant:     100*avg(sum:backlog_years:groupby:date)
 *   - Nested complex operation: 1000*avg((sum:backlog_years/max:supply_excl_cold_stacked):groupby:date,rig_id)
 */
export interface NestedAggregation {
  /** Numerical constant */
  const?: number;
  /** Constant operation */
  constOperator?: BaseOperator;
  /** Main operation mode */
  globalOperation?: CalcOperation;
  /** Nested operation */
  leftOperation: CalcOperation;
  leftVariable: string;
  /** Optional left/right operator */
  arithmeticOperator?: BaseOperator;
  /** Optional second nested operation */
  rightOperation?: CalcOperation;
  rightVariable?: string;
  /** Optional groupBy */
  groupBy?: string;
}

/** Y-axis metric aggregation */
export type Aggregation = SimpleAggregation | ComplexAggregation | NestedAggregation;

export function isSimpleAggregation(aggregation: Aggregation): aggregation is SimpleAggregation {
  return aggregation && !('leftOperation' in aggregation) && !('serialized' in aggregation);
}

export function isComplexAggregation(aggregation: Aggregation): aggregation is ComplexAggregation {
  return aggregation && 'serialized' in aggregation;
}

export function isNestedAggregation(aggregation: Aggregation): aggregation is NestedAggregation {
  return aggregation && 'leftOperation' in aggregation;
}

/**
 * Axis options, shared by SelectableGroupBy and SelectableMetric
 */
export interface ChartAxisOptions {
  /** X-axis format */
  format?: string;
  /** Define format from data range */
  adaptiveDecimal?: boolean;
  /** X-axis suffix */
  suffix?: string;
  /** Manual range */
  range?: [number, number];
  /** Force range mode ('tozero' forces `zeroline` to false) */
  rangemode?: PlotlyRangeMode;
}

/**
 * Line options
 */
export interface ChartBarLineOptions {
  /** Bar/line color */
  color?: Color;
  /** Bar mode (default: 'stack') */
  barMode?: PlotlyBarMode;
  /** Line width (default: 3) */
  width?: number;
  /** Line style (default: 'solid') */
  style?: PlotlyLineStyle;
  /** Line shape (default: 'linear') */
  shape?: PlotlyLineShape;
  /** Line opacity */
  lineOpacity?: number;
  /** Markers size */
  lineMarkers?: number;
}

/**
 * Common properties between SelectableMetric & ChartSeries
 */
export interface ChartSeriesOptions extends ChartAxisOptions, ChartBarLineOptions {
  /** Title */
  title: string;
  /**
   * Y-axis ID for series
   * All metrics sharing the same yaxisId can thus be plotted on the same y-axis
   * Default is to use the `suffix`, else the `value` to ensure compatibility.
   */
  yaxisId?: string;
  /** Series type: line, bar, scatter, boxplot or waterfall */
  type: SeriesTypeValues;
  /** Connect gaps policy (hide, link, tozero) */
  connectGaps?: ConnectGapsValues;
  /** Initially hidden (Plotly show/hide) FIXME: temporary, waiting for metric.presets! */
  hidden?: boolean;
  /** Hide total in tooltip */
  hideTotal?: boolean;
  /** Compute percent values from grand total (only applicable to polarbar on summable values) */
  toPercent?: boolean;
}

/**
 * Group by
 */
export interface SelectableGroupBy extends SelectableValue, ChartAxisOptions {
  /** Show title (default: false) */
  showTitle?: boolean;
  /** Polar options */
  polar?: ChartPolarOptions;
  /** Strings representing extent using relative durations (e.g "1 day and 1 hour") */
  interval?: [string, string];
  /** Sampling parameters */
  samplingUnit?: SpinTimeUnit;
  samplingDuration?: number;
  /** Include/exclude */
  include?: string[];
  exclude?: string[];
  /**
   * Order by (including fixedOrder)
   * WARNING: only Schedule supports multi orders/values, Chart will only support single order/value
   */
  orderBy?: GraphOrderBy;
  /**
   * Used in groupBy select options, on the "none" sampling option. Is an indication
   * of the granularity of the source data, used for correct tick layout on the chart
   */
  granularity?: string;
  // Tell which series range to use for the range of the whole chart
  restrainRangeToSeries?: number;
}

/**
 * Calculated X-axis: a regular groupBy metric with x-axis options
 */
export interface XAxis extends SelectableGroupBy {
  group?: string;
  /** Time extent (converted from metric relative interval) */
  extent?: Interval;
}

/**
 * Series metric
 */
export interface SelectableMetric extends SelectableValue, ChartSeriesOptions {
  /** Override timeVariable for a specific metric (see osv-cycle-analysis) */
  overrideTimeVariable?: string;
  /** Optional time mode (temporal breakdown or adjust to interval, metric-dependent) */
  timeMode?: TimeMode;
  /** Hide stack/group controls */
  hideControls?: boolean;
  /** Y-axis total */
  totalVariable?: string;
  /** When rangeMargin is set, then range = [minimum-rangeMargin; maximum+rangeMargin] */
  rangeMargin?: number;
  /** Min/max error property names  */
  errorMaxProp?: string;
  errorMinProp?: string;
  /** Duration unit that will be plot on chart, must be of type DurationUnitType */
  metricUnit?: string;
  /** Duration unit that is use for calculation, must be of type DurationUnitType */
  durationUnit?: string;
  /** Use series title instead of metric one */
  useSeriesTitle?: boolean;
  /** Limit to series (array of IDs) */
  series?: string[];
}

/**
 * Calculated Y-axis: a regular series metric with an aggregation (simple or complex)
 */
export interface YAxis extends SelectableMetric {
  /** Aggregation method (operations & variables) */
  aggregation: Aggregation;
}

/**
 * Split by metric
 */
export interface SelectableSplitBy extends SelectableValue {
  /** Color(s) */
  color?: string;
  colors?: ColorDefinition[];
  colorScale?: Color[];
}

/**
 * Define all chart selects with their corresponding metric types
 */
export interface ChartSelects {
  /** Selected metric (used to build YAxis) */
  metric: ChartSelect<SelectableMetric>;
  /** Group by (used to build XAxis) */
  groupby: ChartSelect<SelectableGroupBy>;
  /** Split by */
  splitby?: ChartSelect<SelectableSplitBy>;
  /** Scatter points size */
  size?: ChartSelect;
  selectFilter?: FilterMetric;
}

/** Selectable values group (formerly metric 'modes') */
export interface SelectableGroup {
  title: string;
  values: SelectableValue[];
}

export interface ChartSelect<T = SelectableValue> {
  title: string;
  /**
   * Default selected value(s)
   * Must be defined in values.value (if any), or used in conjunction with `force: true`
   * ChartSelect<SeriesMetric> may allow `value` to be a serialized array of values, in the form:
   * `"singleValue"`, `"value1|value2"` or (to be prepared for upcoming changes) `"value1:y1|value2:y2"`
   */
  value: string;
  values: T[];
  description?: string;
  /** Allow multiple selection (if supported by graph: light series only, no bars, no splitby) */
  multiple?: boolean;
  /*
   * This is because of schedule which instead of having the colors definitions
   * inside the values puts the colors in separate dictionary
   * (each value should potentially have it's colors)
   */
  colors?: { [colorBy: string]: ColorDefinition[] };
  /*
   * `force` is an option to force the select to be non-null
   * It's used when we can't know in-advance the possible options of a select
   * When set to true, `value` should be set as `""`
   */
  force?: boolean;
  hide?: boolean;
  /**
   * Only works for bar series, allow to deactivate the trace values display on each chart trace in fullscreen,
   * Plotly doc: https://plotly.com/javascript/reference/bar/#bar-text
   */
  hideFiguresOnChart?: boolean;
}

/**
 * Definition of a chart analysis. This represents a logical set of values for a chart options.
 */
export interface ChartAnalysis {
  /** Id of the analysis. This is mandatory and has to be unique for a given chart. */
  id: string;
  /** Title for the user to identify the analysis. This is mandatory but does not have to be unique. */
  title: string;
  /** If true, this analysis should be used as the default for the chart */
  default?: boolean;
  /**
   * Active {@link ChartSelects.metric} ids
   * @minItems 1
   */
  metrics: string[];
  /** Active {@link ChartSelects.groupby} id */
  groupby: string;
  /** Active {@link ChartSelects.splitby} id */
  splitby?: string;
  /** Active {@link ChartSelects.size} id */
  size?: string;
}

/**
 * Chart configuration, including X & Y-axis, optional split & size, and series configuration
 */
export interface ChartCalcConfig {
  /** Series config */
  series: DataSeriesConfig;
  /** X-axis metric */
  xaxis: XAxis;
  /** Y-axis metric, including metric & aggregation */
  yaxis: YAxis;
  /** Splitting options */
  splitBy?: SelectableSplitBy;
  /** Size options */
  size?: SelectableValue;

  /** Calculated from series options */
  additionalProps?: SeriesSplit[];
  titlePageLink?: PageLinkHelper;

  /** Tab settings (read from component state) */
  tabFilterSelected?: string;
  tabFilterProp?: string;
  forceNoTabFiltering?: boolean;

  /** Tail (bars) and mode (lines) */
  showTail?: boolean;
  displayMode?: ChartDisplayMode;

  /** From global config */
  timezone?: DateTimezone;
  availablePages: AvailablePages;
}

export type ChartSelectKey = 'splitby' | 'groupby' | 'metric' | 'size' | 'tabFilterSelected';
export type ChartSelectKeyRecord<T> = Partial<Record<ChartSelectKey, T>>;

/**
 * Chart tooltip options
 */
export interface ChartTooltipOptions {
  /** Key to be used as tooltip title */
  title?: string;
  /** Base link for tooltip title */
  pageLink?: string;
  /** Optional comment to be added to the tooltip */
  comment?: string;
  /** Additional key/values to be added to the tooltip */
  extend?: EntityFieldDefinition[];
}

export interface ChartMargin {
  /** Optional bottom margin (default: 25) */
  bottom?: number;
  top?: number;
  /** Optional left/right margins (default: 20) */
  left?: number;
  right?: number;
}

export interface FontSpec {
  size?: any;
}

/**
 * Plotly expected axis config
 * @see https://plotly.com/javascript/reference/layout/yaxis/
 * FIXME: should be replaced by @types/plotly when all non-Plotly options are removed
 */
export interface PlotlyAxisConfig {
  // Axis type
  type?: PlotlyAxisType;
  /**
   * Ticks config
   */
  // Ticks position (default: 'outside', has no effect when 'autoshift' set to true)
  ticks?: 'inside' | 'outside';
  // Show ticks labels (default: true)
  showticklabels?: boolean;
  // Number of categorical coordinate string
  dtick?: number | string;
  // Placement of the first tick (use with 'dtick')
  tick0?: number | string;
  // Ticks values & text
  tickmode?: 'auto' | 'linear' | 'array';
  tickvals?: (number | string)[];
  ticktext?: string[];
  // Label placement: below tick ('instant', default) or between ticks ('period')
  ticklabelmode?: 'instant' | 'period';
  // Ticks font
  tickfont?: FontSpec;
  // Ticks offset
  ticklabelstandoff?: number;
  /**
   * Tick format, d3 style
   * - Numbers (https://github.com/d3/d3-format/tree/v1.4.5#d3-format): `,.1~f`
   * - Dates (https://github.com/d3/d3-time-format/tree/v2.2.3#locale_format): `%m/%d`
   */
  tickformat?: string;
  // Tick angle (default: 'auto')
  tickangle?: number | 'auto';
  // Show tick suffix (default: 'all')
  showticksuffix?: 'all' | 'first' | 'last' | 'none';
  ticksuffix?: string;
  /** Title */
  title?: {
    text?: string;
    font?: FontSpec;
    // Distance between the axis title and the axis ticks in pixels (default: 0)
    standoff?: number;
  };
  /**
   * Spikes (x-axis)
   */
  // Spike color
  spikecolor?: Color;
  // Spike thickness (default: 3)
  spikethickness?: number;
  // Spike line style (default: 'dash')
  spikedash?: PlotlyLineStyle;
  // Determines whether spikelines are stuck to the cursor or to the closest datapoints
  spikesnap?: PlotlySnapMode;
  // Drawing mode for the spike line, combination of 'toaxis', 'across', 'marker' with '+'
  spikemode?: string; // 'toaxis+across'
  /**
   * Range
   */
  // Autorange (default: true)
  autorange?: boolean;
  range?: [number, number];
  // Is axis zoomable (default: false - zoomable)
  fixedrange?: boolean;
  // Range mode (default: 'normal')
  rangemode?: PlotlyRangeMode;
  /**
   * Layout
   */
  // Ticks position (default: 'left' for Y-axis or 'bottom' for X-axis)
  side?: 'top' | 'bottom' | 'left' | 'right';
  // Axis position between 0 (left) to 1 (right) (default: 0)
  position?: number;
  // Axis shift (in pixels, default: 0)
  shift?: number;
  // Axis ID (set all Y-axis to the same value to allow multi-series hovering)
  overlaying?: string;
  // Anchor (bound to other axis' ID or 'free' to be independent, allowing 'autoshift' to be set to true)
  anchor?: string;
  // Shift overlaying axes (required when more than 2 y-axis) FIXME: will be replaced by hovering axes
  autoshift?: boolean;
  // Show grid (default: true)
  showgrid?: boolean;
  // Show line (default: true)
  showline?: boolean;
  // Show zero-line (default: true)
  zeroline?: boolean;
  /**
   * Margins
   */
  // Grow margins with tick labels ('height', 'width', 'left', 'right', 'top' and 'bottom' joined with '+', or boolean)
  automargin?: boolean;
}

/**
 * Plotly x-axis config
 */
export interface PlotlyXAxisConfig extends PlotlyAxisConfig {
  rangeslider?: {
    visible?: boolean;
    bgcolor?: Color;
    bordercolor?: Color;
    borderwidth?: number;
    thickness?: number;
  };
}

/**
 * Polar plots options
 * Used to generate angularaxis & radialaxis
 */
export interface ChartPolarOptions {
  // Direction
  direction?: 'clockwise' | 'counterclockwise';
  // Rotation
  rotation?: number;
  // Sectors list
  sector?: number[];
}

/**
 * Plotly hovering modes
 * Currently only using 'closest' (scatter) & 'x unified' (bar & line)
 * Other available modes: 'x' & 'y' (one tooltip per series) or 'y unified' (same as 'x unified', one tooltip for all)
 */
export type ChartHoveringMode = 'closest' | 'x unified';

export interface ChartOptions {
  tooltip?: ChartTooltipOptions;
  margin?: ChartMargin;
  maxLabels?: number;
  hideFocus?: boolean;
  todayLine?: boolean;
  hideSelects?: boolean;
  hideOptions?: boolean;
  hideButtons?: boolean;
  maxWidth?: number;
  labelProperty?: string;
  showlegend?: boolean;
  width?: number;
  height?: number;
  showYLabels?: boolean;
  scheduleGraph?: boolean;
  hideInterval?: boolean;
  dynamicGroupBy?: boolean;
  legendPosition?: 'top' | 'bottom';
  yRangeMax?: number;
  holeSize?: number; // only for polar plots. In fraction of the radius
  // will change the space between bars in the plot. In plot fraction (between 0-1). For polar and cartesian
  barGap?: number;
  // for boxplot
  showOutliers?: boolean;
  outliersMax?: number;
  outliersMin?: number;
  // for waterfall
  increasing?: any;
  decreasing?: any;
  totals?: any;
  subLabel?: boolean;
  groupSpacing?: number;
  scheduleChart?: boolean;
  hideTotal?: boolean;
  hovermode?: ChartHoveringMode;
  usePlotlyTooltip?: boolean;
  legend?: Partial<Legend>;
  xAxisType?: xAxisType;
  // scatter
  'circle-spacing'?: number;
  'circle-size'?: number;
  /** Initial brush interval (may be different than masterPeriod) */
  initZoomInterval?: InitBrushConfigValue;
  /** Brush init unit (default: 'month') */
  initZoomUnit?: InitBrushUnit;
  // Show/hide barchart tail
  enableRangeSlider?: boolean;
  enableToggleTail?: boolean;
  barsTargetNumber?: number;
  barsOtherValue?: number;
  barsShowAll?: boolean;
  // Enable/disable relevant/quantile series
  enableModes?: boolean;
  linesMaxSeries?: number;
  linesRelevantSeries?: number;
  linesDisplayMode?: ChartDisplayMode;
  linesShowMore?: ChartExtraLines;
  /**
   * If true, when clicking on a page link in the X axis, we will include the current chart
   * interval as the "masterPeriod" parameter
   */
  pageLinkSendIntervalAsMasterPeriod?: boolean;
}

/**
 * Chart histogram info
 */
export interface ChartHistInfo {
  variable: string;
  size: number;
}

/**
 * Chart context, used in `ChartingHelpers.transformSeries()`
 * Contains all precalculated options, transitional data (inter) & flags
 */
export interface ChartContext {
  timeMode: TimeMode;
  isTimeGrouping: boolean;
  // Split base URLs
  splitPageLink: PageLinkHelper;
  // Tooltip base URL
  titlePageLink: PageLinkHelper;
  histInfo: ChartHistInfo | null;
  // Operations
  operations?: ComplexAggregation;
  // Group by
  group: string;
  // Header
  header: SeriesHeader;
  // Intermediate data
  inter: ChartData;
  // Split values
  splitIds: { [split: string]: NumOrString };
  totalBySplitValue: { [split: string]: number };
  // Groups type
  allGroupsValidFloats: boolean;
  allGroupsValidString: boolean;
  // Total options
  total: number;
  totalCount: number;
}

/**
 * For now only common type values (line & bar) are supported in plotly "multi" graphs (SeriesCommonTypeValues).
 */
export type SeriesCommonTypeValues = 'line' | 'bar' | 'scatter';
export type SeriesTypeValues = SeriesCommonTypeValues | 'boxplot' | 'waterfall';

export type YAxisName = 'y1' | 'y2' | 'y3' | 'y4';
export type YAxisKey = 'yaxis' | 'yaxis2' | 'yaxis3' | 'yaxis4';
export type YAxisMap = { [yaxisId: string]: number };
export type YAxisLayout = { [yaxisKey: string]: PlotlyAxisConfig };

/**
 * Base series interface, ancestor of DataSeries & ChartSeries
 */
export interface BaseSeries {
  /**
   * The series' identifier. Used in chart-wrapper to generate a unique requestorId for multi-series chart
   * Must be unique among a chart config's 'series'
   */
  id: string;
  /*
   * if true, the series is a distribution across categorical value bins
   * (i.e 500 values below 1, 30 values between 1-3, etc.). All head keys must be numeric
   * In that case we will append the unit suffix to the legend title in the tooltip, and not to the value
   */
  isBinDistribution?: boolean;
  /** Error bars config (multibar) */
  errorBars?: ErrorBarsConfig;
  /** Angle variable */
  angleVariable?: string;
  /** Optional split by (if not set, read from selects) FIXME: to be removed */
  splitby?: SelectableSplitBy;
}

/**
 * Error bars config
 */
export interface ErrorBarsConfig {
  type?: 'data' | 'percent';
  value?: string;
  color?: Color;
}

export interface DataSeriesConfig extends BaseSeries, ChartBarLineOptions {
  /** Optional title */
  title?: string;
  /** Series data type (default: 'light') */
  endpointType?: BaseEndpointType;
  /** Get data from endpoint */
  endpoint: BaseEndpointPattern;
  /** Time variable (default: 'dateStart') */
  timeVariable?: string;
  /** Time variables for temporal breakdown (default: 'dateStart' & 'dateEnd') */
  dateStart?: string;
  dateEnd?: string;
  /** From table series */
  tableSeries?: boolean;
  /** Get data from fullData[dataKey] instead */
  dataKey?: string;
  /** No tab filtering */
  forceNoTabFiltering?: boolean;
  /** FIXME: property added back for SFM vessel operation performance charts
   * where we need to specify chart type at the serie level */
  type?: SeriesTypeValues;
}

/**
 * WARNING: do not change this name, as it's used for our custom ESLint rule
 * See https://www.notion.so/adf7547944a4494f9ea90e714e975be9 for documentation
 */
export interface RawDataPoint {
  [key: string]: any;
}

export interface RawIdentityPoint extends RawDataPoint {
  id: NumOrString;
}

/**
 * Series as data & header
 */
export interface Series<T> {
  header: SeriesHeader;
  data: T[];
}

export interface SeriesHeader {
  [id: string]: string;
}

/**
 * Data series, with the same interface for both light and heavy series
 */
export interface DataSeries extends DataSeriesConfig, Series<RawDataPoint> {
  /** Initial (and pristine) data */
  fullData: readonly RawDataPoint[];
  /**
   * When heavy or heavy-custom endpoints are called on multi-metrics, each metric may return multiple split values
   * In order to filter correctly in plotDataSeries(), we keep a map { metric: header }
   */
  headerMap?: { [metric: string]: SeriesHeader };
  /** Contains page base url for heavy chart split by, used to have clickable split by in tooltips */
  splitPageBaseUrl?: string;
}

export type ConnectGapsValues = 'link' | 'tozero' | 'hide';

/**
 * Chart series
 */
export interface ChartSeries extends BaseSeries, Series<ChartValues>, ChartSeriesOptions {
  /** Y axis for series (default: 'y1') */
  yaxis?: YAxisName;
  /** Boundaries */
  maxX: number;
  minX: number;
  minY: number;
  maxY: number;
}

export type ChartExportData = Series<any>;

export interface ChartComponentSettings extends BaseComponentSettings {
  type: 'chart';
  /** Component title */
  title: string;
  /** Optional subtitle */
  subtitle?: string;
  /** Chart settings */
  chart: ChartSettings;
  /** Toggle button (schedule chart) */
  toggleText?: string;
  /** Toggle tooltip description */
  toggleDescription?: string;
}

export interface ChartFieldSettings extends FieldSettings {
  component: ChartComponentSettings;
}

export interface LinesSettings {
  endpoint?: BaseEndpointPattern;
  variables: { [id: string]: StraightLineSpec };
}

export interface ChartSettings {
  /** Chart type */
  type: ChartType;
  /** Optional chart options */
  opts?: ChartOptions;
  /**
   * Chart predefined analyses
   * @minItems 1
   */
  analyses?: ChartAnalysis[];
  /** Select options */
  selects?: ChartSelects;
  /** Series config */
  series: DataSeriesConfig[];
  /** Value bag */
  valueBag?: any;
  /** Lines settings */
  lines?: LinesSettings;
  /** Interval field */
  intervalField?: IntervalField;
  /** Outliers options */
  showOutliers?: boolean;
  outliersMax?: number;
  outliersMin?: number;
  /** Chart bridges */
  bridges?: ChartBridge[];
  /** Optional table settings */
  table?: EntityTableComponentSettings;
  /** Special timezone for component FIXME: already in parent component config */
  componentTimezone?: DateTimezone;
  /** Aggregation options (for bar charts) */
  toggleTailEnabled?: boolean;
  rangeSliderEnabled?: boolean;
  /** Keep track of the number of shown bars and their corresponding X values (to filter other series) */
  shownBarValues?: number;
  shownXValues?: Set<any>;
  /** Special display modes (for line charts) */
  modesEnabled?: boolean;
  shownSeries?: [number, number];
  /** Optional buttons to open modals */
  buttons?: Button[];
}

export interface ChartBridge {
  id: string;
  title: string;
  totalTitle: string;
  groupby: SelectableGroupBy;
  negativeGroups?: string[];
}

export interface BaseIntervalEvent {
  extent: Interval;
  intervalReleased: boolean;
  selectedEra?: Era;
}

export interface ChartIntervalChange extends BaseIntervalEvent {
  id: string;
  filterType: FilterType;
  clearInterval?: boolean;
}

export interface QuantileData {
  whiskerLow: number;
  Q1: number;
  Q2: number;
  Q3: number;
  whiskerHigh: number;
  outliers?: number[];
  nbOfObservations: number;
  values?: number[];
}

/**
 * ChartValues is the expected values for ChartSeries, including x and splits
 * It also includes "technical" properties (__*) required by `transformSeries()` and by some chart types:
 * - bar: __orderValue & __tail
 * - line: __quant
 * - boxplot: __errorMin & __errorMax
 * - scatter: __scatterSize & __additionalProps
 */
export interface ChartValues {
  // Mandatory x-axis value
  x: NumOrString;
  // Copy of the original x value if it was modified (for instance in case of enforceLocalTz)
  __originalXValue?: NumOrString;
  // Entity ID used to build page URLs
  __xId?: NumOrString;
  // Value to order bars with
  __orderValue?: string;
  // Is in the tail values
  __tail?: boolean;
  // Is a quantile value
  __quant?: boolean;
  // Min & max error values (per split)
  __errorMin?: { [split: string]: number };
  __errorMax?: { [split: string]: number };
  // Scatter size (per split)
  __scatterSize?: { [split: string]: number };
  // Additional properties (per split)
  __additionalProps?: { [split: string]: SeriesSplit[] };
  // Split values
  [split: string]: any;
}

/**
 * TransitionalSeries extends Series with an xAxisType option
 */
export interface TransitionalSeries extends Series<ChartValues> {
  splitIds: { [split: string]: NumOrString };
  xAxisType: xAxisType;
}

export interface ChartSplit {
  ops: { [operationIndex: number]: any[] };
  errorMin: number;
  errorMax: number;
  errorMinCount?: number;
  errorMaxCount?: number;
  scatterSize?: number;
  additionalProps?: SeriesSplit[];
  points?: { additionalProps: SeriesSplit[]; scatterSize: number }[];
}

export interface ChartGroup {
  splits: { [split: string]: ChartSplit };
  orderValue?: any;
  id?: string;
}

export type ChartData = { [group: string]: ChartGroup };

export interface StraightLineSpec {
  direction: 'vertical' | 'horizontal';
  title: string;
  color?: Color;
  size?: number;
  opacity?: number;
  lineStyle: {
    color?: Color;
    dash?: number;
    width?: number;
  };
}

export interface ChartStraightLine extends StraightLineSpec {
  point: number;
}

export interface PlotHTMLElement extends HTMLElement {
  on(eventName: string, handler: CallableFunction): void;
  layout: object;
  _context: { doubleClickDelay: number };
}

export type NormalizationType = 'totalCount' | 'totalSum' | null;

export interface Operation {
  operation: CalcOperation;
  normType: NormalizationType;
}

export interface PlotlyLegendTraceState {
  visible: boolean | 'legendonly';
  name: string;
}

export interface PlotlyLegendClick {
  data: PlotlyLegendTraceState[];
  curveNumber: number;
}

export interface SeriesSorterDefinition {
  prop: string;
  order?: SortDirection;
}

export interface ChartEventHandler {
  handler: (event: unknown) => void;
  /** TODO: restore PlotlyHTMLElement in union when plotly types are used. See SP-466*/
  target: HTMLElement | Window;
  event: string;
}

export enum TimeLevelEnum {
  minute,
  hour,
  day,
  week,
  month,
  quarter,
  year,
}

export type TimeLevel = keyof typeof TimeLevelEnum | 'date' | 'isoWeek' | 'minute';

/*
 * Object representing the number of lower entity needed to represent
 * the current level
 */
export const TimeLevelHalfDuration: {
  [k in TimeLevelEnum]: {
    times: number;
    level: QUnitType;
  };
} = {
  [TimeLevelEnum.minute]: { times: 30, level: 'second' },
  [TimeLevelEnum.hour]: { times: 30, level: 'minute' },
  [TimeLevelEnum.day]: { times: 12, level: 'day' },
  [TimeLevelEnum.week]: { times: 3.5 * 24, level: 'hour' },
  [TimeLevelEnum.month]: { times: 15, level: 'day' },
  [TimeLevelEnum.quarter]: { times: 45, level: 'day' },
  [TimeLevelEnum.year]: { times: 2, level: 'quarter' },
};

export interface AxisFormatting {
  // used for tick formatting
  d3Format?: string;
  formatter: (d) => string; // used for tooltip formatting
  isTimeFormatting: boolean;
  exportFormatter: (d) => string;
  dtick?: number | string;
  avgTickLength?: number;
  ticksuffix?: string;
}

// structure to hold data to be passed to customData variable of plotly
export interface CustomTooltipData {
  // xValue to use for display in the tooltip. May be different from plotly's x value because of enforceLocalTz.
  xValue: NumOrString;
  // Tooltip title
  title?: string;
  // Entity ID (used to generate ticks & tooltip links)
  xId?: NumOrString;
  comments?: { [split: string]: string };
  additionalProps?: { [split: string]: SeriesSplit[] };
}

/**
 * Real datum interface as returned by Plotly when hovering a point in a graph
 * Extends default (but incomplete) `PlotDatum` interface defined in plotly.js
 */
export interface RealPlotDatum extends PlotDatum {
  // Explicit x value
  x: NumOrString;
  // x or string (like link) used for x-axis
  label: NumOrString;
  // Duplicate y value
  y: number;
  value: number;
  // Series data & fullData (including customData)
  data: any;
  fullData: any;
  // Hovered point index & number
  pointIndex: number;
  pointNumber: number;
  // Hovered curve number
  curveNumber: number;
}

/**
 * Default chart series point
 */
export interface ChartSeriesValue {
  x: NumOrString;
  y: number | null;
}

/**
 * Chart series as expected by Plotly
 */
export interface PlotlyChartSeries<T = ChartSeriesValue> {
  key: string;
  values: T[];
}

export type xAxisType = 'numeric' | 'string' | 'mixed';

export interface ChartParamChange {
  chartParam: ChartSelectKey;
  value: any;
}

export type LegendEntryBoundingBoxes = { [key: string]: LegendBoundingBox };

export type Margin = {
  top: number;
  bottom: number;
  right: number;
  left: number;
  pad: number;
};

export type Font = {
  size: number;
};

export type LegendIcon = {
  width: number;
};

export type LegendBoundingBox = {
  width: number;
  height: number;
  x: number;
  y: number;
};

/**
 * The layout of the legends, containing the display options for the legends (ex: font, icon, etc...)
 */
export type LegendLayout = {
  margin: Margin;
  orientation: 'v' | 'h';
  font: Font;
  icon: LegendIcon;
  maxStringLength: number;
};

export type HorizontalPosition = 'left' | 'right';
export type VerticalPosition = 'top' | 'bottom';

/**
 * The layout of the legend container, used to place the customLegend <div>-<Svg> or the <g>
 * Contain the position of the container, and the margin that will be applied the <svg> or the <g>
 */
export type LegendContainerLayout = {
  /** Margin of legends */
  margin: Margin;
  boundingBox: LegendBoundingBox;
  horizontalPosition: HorizontalPosition;
  verticalPosition: VerticalPosition;
};
