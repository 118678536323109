import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { orderBy } from 'lodash-es';
import tinycolor from 'tinycolor2';

import { SidebarEditedItem } from 'src/user-saving/user-saving-types';
import { Button, DatabaseEntity, DynamicInfoForPage, EntityFieldDefinition, Fieldset, HasDisplayOrder, SectionCode,
  SortDirection, SubscriptionConfig, SubscriptionOverviewField } from './types';
import { SelectorItem } from '../selector/selector.types';
import { FilterHelper } from '../filters/filter-helper';
import { AppInfoService } from '../app/app-info-service';
import { SubscriptionTools } from '../subscription/subscription-tools';
import { DataHelpers, pluralize } from './data-helpers';
import { PearlHeroSectionStatusOptions } from '../shared/pearl-components/components/hero-section/pearl-hero-section.types';
import { PearlIcon } from '../shared/pearl-components';

@Pipe({
  name: 'OrderBy',
  standalone: true,
})
export class OrderByPipe implements PipeTransform {
  transform(input: any, key: 'title' | 'id', order: SortDirection = 'asc') {
    if (!input) return [];
    if (!key) {
      key = 'title';
    }
    return orderBy(input, [key], [order]);
  }
}

@Pipe({
  name: 'safeHtml',
  standalone: true,
})
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustHtml(value);
  }
}

@Pipe({
  name: 'safeUrl',
  standalone: true,
})
export class SafeUrlPipe implements PipeTransform {
  constructor(private sanitized: DomSanitizer) {}
  transform(value) {
    return this.sanitized.bypassSecurityTrustUrl(value);
  }
}

@Pipe({
  name: 'safeResourceUrl',
  standalone: true,
})
export class SafeResourceUrlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}

@Pipe({
  name: 'shortenUrl',
  standalone: true,
})
export class ShortenUrlPipe implements PipeTransform {
  transform(url, maxSize: number = 30) {
    if (url && url.length > maxSize) {
      const shortenUrl = url.substring(0, maxSize / 2)
        + '...'
        + url.substring(url.length - 1 - maxSize / 2, url.length);
      return shortenUrl;
    }
    return url;
  }
}

@Pipe({
  name: 'truncateString',
  standalone: true,
})
export class TruncateStringPipe implements PipeTransform {
  transform(str: string, maxSize: number = 30) {
    if (str && str.length > maxSize) {
      const shortenStr = str.substring(0, maxSize)
        + '...';
      return shortenStr;
    }
    return str;
  }
}

@Pipe({
  name: 'searchItem',
  standalone: true,
})
export class SearchItemPipe implements PipeTransform {
  transform(items: DatabaseEntity[], searchText: string): DatabaseEntity[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();

    return items.filter(it => it.title.toLowerCase().includes(searchText));
  }
}

@Pipe({
  name: 'upperFirstLetter',
  standalone: true,
})
export class UpperFirstLetterPipe implements PipeTransform {
  transform(str: string): string {
    if (!str) {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}

@Pipe({
  name: 'pluralWord',
  standalone: true,
})
export class PluralWordPipe implements PipeTransform {
  transform(str: string, length: number = 2): string {
    if (!str) {
      return '';
    }
    return pluralize(str, length);
  }
}

@Pipe({
  name: 'searchSidebarItem',
  standalone: true,
})
export class SearchSidebarItemPipe implements PipeTransform {
  transform<T extends SidebarEditedItem>(items: T[], searchText: string): T[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    /*
     * We display bookmark only if the bookmark is editing
     * or if title or dashboard include search text
     */
    return items.filter(it =>
      it.editing || it.title.toLowerCase().includes(searchText)
      || (it.dashboard && it.dashboard.toLowerCase().includes(searchText))
    );
  }
}

@Pipe({
  name: 'searchResultAdvanced',
  standalone: true,
})
export class SearchResultAdvancedPipe implements PipeTransform {
  /** This would search on the `title` field of the SelectorItem */
  transform(items: SelectorItem[], searchText: string): SelectorItem[] {
    if (!items) {
      return [];
    }
    if (!searchText) {
      return items;
    }

    searchText = searchText.toLowerCase();
    // Keep items which include the searched text in their `searchField`.
    return items.filter(item => item.title.toLowerCase().includes(searchText));
  }
}

@Pipe({
  name: 'searchSubscription',
  standalone: true,
})
export class SearchSubscriptionPipe implements PipeTransform {
  transform(
    subscriptions: SubscriptionConfig[],
    searchText: string,
    fields: SubscriptionOverviewField[],
    appInfoService: AppInfoService,
  ): SubscriptionConfig[] {
    if (!subscriptions) {
      return [];
    }
    if (!searchText) {
      return subscriptions;
    }

    searchText = searchText.toLowerCase();
    // We display advanced result only its title include the search text
    return subscriptions.filter(sub => {
      for (const field of fields) {
        const propValue = field.id;
        const fieldValue = sub[propValue];

        switch (field.type) {
          case 'string': {
            if (String(fieldValue).toLowerCase().includes(searchText)) {
              return true;
            }
            break;
          }
          case 'filterResume':
            const filters = SubscriptionTools.subscriptionFilterResume(sub);
            if (
              filters.some(filter =>
                (String(filter.filterTitle))?.toLowerCase().includes(searchText)
                || (String(filter.filterValue))?.toLowerCase().includes(searchText)
              )
            ) {
              return true;
            }
            break;
          case 'vessels_advanced_filters':
            const section: SectionCode = SubscriptionTools.subscriptionSectionFromType(sub, field.type);
            if (!sub.userConfig || !section) {
              break;
            }
            const currentFilterValues = sub.userConfig[section.key];
            if (!currentFilterValues) {
              break;
            }
            if (currentFilterValues['vesselFilters']) {
              const intelligbleFilters = FilterHelper.getIntelligibleFilters(
                currentFilterValues['vesselFilters'],
                appInfoService,
              );
              if (intelligbleFilters.some(filter => filter.intelligibleValues.toLowerCase().includes(searchText))) {
                return true;
              }
            }
            if (currentFilterValues['fleets']) {
              const vesselFleetsTitles = SubscriptionTools.vesselFleetTitles(sub, appInfoService);
              if (vesselFleetsTitles.some(fleet => fleet.toLowerCase().includes(searchText))) {
                return true;
              }
            }
            break;
          case 'slideToggle':
            break;
          case 'vesselNumber':
            if ('vesselList' in sub) {
              if (String(sub.vesselList.length).includes(searchText)) {
                return true;
              }
            }
            break;
          default:
            if (Array.isArray(fieldValue) && fieldValue.some(v => String(v).toLowerCase().includes(searchText))) {
              return true;
            } else if (String(fieldValue).toLowerCase().includes(searchText)) {
              return true;
            }
            break;
        }
      }
    });
  }
}

@Pipe({
  name: 'marked',
  standalone: true,
})
export class MarkedPipe implements PipeTransform {
  transform(text: string) {
    return DataHelpers.markdownToHtml(text);
  }
}

@Pipe({
  name: 'displayOrder',
  standalone: true,
})
export class DisplayOrderPipe implements PipeTransform {
  transform<T extends HasDisplayOrder>(array: T[]): T[] {
    array.sort(DataHelpers.displayOrderSort);
    return array;
  }
}

@Pipe({
  name: 'visibleFields',
  standalone: true,
})
export class VisibleFieldsPipe implements PipeTransform {
  transform(fieldset: Fieldset): EntityFieldDefinition[] {
    return fieldset.fields.filter(f => f.visible !== false);
  }
}

@Pipe({
  name: 'displaySize',
  standalone: true,
})
export class DisplaySize implements PipeTransform {
  transform(size: number): string {
    const kiloOctet = 1000;
    let sizeString: string;

    if (size < kiloOctet) {
      sizeString = `${this.roundSize(size).toString()} kB`;
    } else if (size / kiloOctet < kiloOctet) {
      sizeString = `${
        this.roundSize(
          size / kiloOctet,
        ).toString()
      } MB`;
    } else if (size / (kiloOctet * kiloOctet) < kiloOctet) {
      sizeString = `${
        this.roundSize(
          size / (kiloOctet * kiloOctet),
        ).toString()
      } GB`;
    }
    return sizeString;
  }

  roundSize(size: number): number {
    return Math.round(size * 100) / 100;
  }
}

/**
 * @desc Get:
 *  - the title to use to display a given report status.
 *  - the icon in which to display a given report status (optional).
 *  - the colors in which to display a given report status:
 *    - the backgound color, based on the given status color mapping if any, and slightly brightened
 *    - the text color, which is computed based on the background color
 */
@Pipe({
  name: 'entityStatus',
  standalone: true,
})
export class EntityStatusPipe implements PipeTransform {
  transform(pageInfo?: DynamicInfoForPage): PearlHeroSectionStatusOptions | null {
    const extractedStatus = pageInfo?.status;
    if (!extractedStatus) {
      return null;
    }

    const iconMapping: { [statusId: string]: PearlIcon } = { 'In progress': 'in_progress', 'Completed': 'checked' };
    const status: PearlHeroSectionStatusOptions = {
      title: extractedStatus,
      icon: iconMapping[extractedStatus],
      style: {},
    };

    const statusColor = pageInfo.statusColors?.find(c => c.id === extractedStatus)?.fill;
    if (!statusColor) {
      return status;
    }

    const backgroundColor = tinycolor(statusColor);
    const color = backgroundColor.clone();
    /**
     * Here we compute a text color based on the background color. The default and most common behavior is to have a
     * brighter color, close to white.
     * We only want to have a darker color for text if the color is above a specific brighteness level
     * This threshold seems like a good arbitrary value. We can't rely on `isLight` to check whether we should
     * brighten or darken as it breaks the behavior for some colors.
     */
    if (backgroundColor.getBrightness() < 200) {
      /**
       * We also apply a slight brightening on the background color, to slightly tame it
       * We don't want to do this if the color is already bright enough as it is, as it might become to close to white.
       */
      backgroundColor.brighten(10);
      color.brighten(90);
    } else {
      color.darken(75);
    }

    return {
      ...status,
      style: {
        backgroundColor: backgroundColor.toString(),
        borderColor: color.toString(),
        color: color.toString(),
      },
    };
  }
}

/**
 * @desc Filters the list of actions on a page according to the standalone parameter
 */
@Pipe({
  name: 'filterPageActions',
  standalone: true,
})
export class FilterPageActionsPipe implements PipeTransform {
  transform(buttons: Button[], standalone: boolean, isSmallScreen: boolean): Button[] {
    // On small screen all buttons are in the action menu
    if (isSmallScreen) {
      return standalone ? [] : buttons;
    }
    return buttons.filter(button => standalone ? button.standalone : !button.standalone);
  }
}
