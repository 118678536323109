import { PearlIcon } from '../../icons/icons';
import { PearlMenuItem } from '../../menu/pearl-menu.component';

export const ACTIONS: {
  id: string;
  icon: PearlIcon;
  mappedTypes: string[];
}[] = [
  {
    id: 'see-on-map',
    icon: 'map',
    mappedTypes: [],
  },
  {
    id: 'entity-page',
    icon: 'open_in_full',
    mappedTypes: ['openVesselPage', 'openPoiPage', 'navigate'],
  },
  {
    id: 'entity-page-in-side-bar',
    icon: 'right_panel_open',
    mappedTypes: [],
  },
  {
    id: 'pin',
    icon: 'keep',
    mappedTypes: [],
  },
  {
    id: 'historical-position',
    icon: 'historical_position',
    mappedTypes: ['historicalPositions'],
  },
  {
    id: 'edit',
    icon: 'edit',
    mappedTypes: ['editVessel', 'editPoi', 'editModal'],
  },
  {
    id: 'edit-shape',
    icon: 'edit_shape',
    mappedTypes: ['editShape'],
  },
  {
    id: 'edit-localisation',
    icon: 'edit_localisation',
    mappedTypes: ['editPosition'],
  },
  {
    id: 'source',
    icon: 'sources',
    mappedTypes: [],
  },
  {
    id: 'vessel-position',
    icon: 'poi',
    mappedTypes: [],
  },
  {
    id: 'report',
    icon: 'report',
    mappedTypes: [],
  },
];

export const SORTED_ACTIONS = ACTIONS.map(v => v.icon);

export const ACTION_ID_BY_MAPPED_TYPE: { [type: string]: string } = ACTIONS
  .reduce(
    (
      acc,
      { id, mappedTypes },
    ) => ({
      ...acc,
      ...mappedTypes.reduce((accAct, type) => ({ ...accAct, [type]: id }), {}),
    }),
    {},
  );

export function isValidActionName(val: string): boolean {
  return !!ACTIONS.find(v => v.id === val);
}

export type TooltipAction = {
  /** Action type: leave undefined for custom type */
  type?: string;
} & Omit<PearlMenuItem, 'trailingIcon'>;
