import { MatDialogRef } from '@angular/material/dialog';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';

import { FieldConfig, SimpleFormConfig } from './simple-form.types';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';
import { PearlFormFieldComponent, PearlIconComponent } from './pearl-components';

@Component({
  selector: 'simple-form',
  templateUrl: './simple-form.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrl: './simple-form.component.scss',
  imports: [
    PearlFormFieldComponent,
    MatInputModule,
    MatIconModule,
    MatTooltipModule,
    PearlButtonComponent,
    PearlIconComponent,
    FormsModule,
  ],
})
export class SimpleFormComponent {
  @Input()
  public config: SimpleFormConfig;

  constructor(
    public dialogRef: MatDialogRef<SimpleFormComponent>,
  ) {}

  public isFormInvalid(): boolean {
    return this.config.fields.flat().some(field => field.required && !field.value);
  }

  public requiredFieldAsString(): string {
    const requiredFields: string[] = [];
    this.config.fields.flat().forEach(field => {
      if (field.required && !field.value) {
        requiredFields.push(field.title);
      }
    });
    // Build a string from the list of required fields, replace the latest comma with an `and`
    let requiredString = requiredFields.join(', ');
    if (requiredFields.length > 1) {
      const lastIndex = requiredString.lastIndexOf(',');
      requiredString = requiredString.slice(0, lastIndex)
        + ' and'
        + requiredString.slice(lastIndex + 1) + ' are required.';
    } else {
      requiredString += ' is required.';
    }
    return requiredString.charAt(0).toUpperCase() + requiredString.slice(1);
  }

  public close(): void {
    this.dialogRef.close();
  }

  public excludeHiddenFields(configLine: FieldConfig[]): FieldConfig[] {
    return configLine.filter(field => field.hidden !== true);
  }

  public getFieldClass(field: FieldConfig): string {
    return field.fullWidth ? 'form-line-cell-large' : 'form-line-cell-half';
  }
}
