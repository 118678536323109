<pearl-form-field matIconPrefix="search" [small]="true">
  <input matInput type="text" placeholder="Timezone" [formControl]="searchInput" />
</pearl-form-field>

<div class="pearl-timezone-picker_option-list">
  <div
    *ngFor="let tz of displayedTimezones(); trackBy: trackOptions"
    (click)="selectTimezone(tz)"
    class="pearl-timezone-picker_option">
    {{ tz.title }} ({{ tz.subtitle }})
  </div>
</div>
