import { QueryList } from '@angular/core';

import { cloneDeep, isArray, max, min, size } from 'lodash-es';
import dayjs from 'dayjs';

import { AppInfoService } from '../app/app-info-service';
import { Config } from '../config/config';
import { SelectableColumn } from '../database/filter-columns.dialog';
import { BaseFilterConfig, COMPLETE_ERA, ComponentParameter, DeepReadonly, EntityFieldDefinition, Era, EraRelativeTo,
  EraType, FieldSettings, Fieldset, FilterApplied, FilterConfig, FilterType, FiltersApplied, FiltersState,
  FiltersStatePeriod, Interval, IntervalField, IntervalOrNull, LayerFilter, NumOrString, OptionValue,
  PREVIOUS_PERIOD_ERA, ResolvedInterval, ScalePeriodType } from '../helpers/types';
import { DashboardCommonSettings, LayerSettings } from '../helpers/config-types';
import { DateHelper } from '../helpers/date-helper';
import { DataHelpers, getNumberListFromString, isSerializedInterval } from '../helpers/data-helpers';
import { getChained } from '../data-loader/ref-data-provider';
import { AutocompleteFilterHelper } from './autocomplete-filter.helper';
import { RawDataPoint } from '../graph/chart-types';
import { CHECKBOX_EXCLUDE } from './spin-checkbox/spin-filters-checkbox.component';
import { DistanceToEntityHelper } from './distance-to-entity/distance-to-entity.helper';
import { NavigationHelper } from '../helpers/navigation-helper';
import { SidebarFieldsetComponent } from './sidebar-fieldset.component';

/**
 * Contain all the options needed when populating a filter.
 */
export interface FilterPopulateOptions extends BaseFilterConfig {
  /** Whether to keep the existing filter values or to populate only with the given dataset. */
  keepExisting: boolean;
  /** The property holding the value's title. */
  propTitle: string;
  /** The property holding the order of the value. The order is used to sort a multi filter's values. */
  propOrder: string;
  /** The ordered list of values */
  ordered?: string[];
}

export class FilterHelper {
  /**
   * @deprecated - should not be used, FieldsetsCollection and SidebarFieldsetsGroups
   * Get filters type & properties.
   * Function use to get a filter type and prop from the conf
   */
  static getFiltersTypeAndProp(
    settings: DashboardCommonSettings,
    layerSettings: LayerSettings,
  ): { [id: string]: FilterConfig } {
    const filters: FiltersApplied = {};
    let fieldsets: Fieldset[];
    if (!layerSettings) {
      return filters;
    }

    fieldsets = cloneDeep(layerSettings.fieldsets);
    if (!fieldsets || !fieldsets.length) {
      fieldsets = [{ fields: [] }] as any;
    }
    if (layerSettings.selector) {
      fieldsets = fieldsets.concat(cloneDeep(layerSettings.selector.fieldsets));
    }
    if (layerSettings.vessel) {
      /*
       * For vessel layer (latest / historical pos), we have more fields.
       * "vessel" carries the visible vessel on the map for a vessel
       * "intervalField" is the shared filter between historical mode
       * These two filters aren't in a fieldset
       */
      fieldsets[0].fields.push(layerSettings.vessel);
      if (Config.isMapSettings(settings) && settings.playbackMode && settings.playbackMode.intervalField) {
        fieldsets[0].fields.push(settings.playbackMode.intervalField);
      }
    }
    // Default filters
    const defaultFilters: FieldSettings[] = this.flattenFieldsetsIntoFields(fieldsets);
    defaultFilters.forEach(filter => {
      const filterApplied = new FilterApplied(filter);
      /*
       * if we already have prop and type for this id we keep the current version
       *  this case can happen if a filter is defined in a classic fieldset and advanced filters
       */
      filters[filter.id] = filters[filter.id] ? filters[filter.id] : filterApplied;
    });

    return filters;
  }

  // Transform an array of fieldsets in empty filters without applied values.
  public static fieldsetsToEmptyFilters(fieldsets: Fieldset[]): LayerFilter {
    return this.fieldsToEmptyFilters(this.flattenFieldsetsIntoFields(fieldsets));
  }

  // Transform an array of fields in empty filters without applied values.
  public static fieldsToEmptyFilters(fields: FieldSettings[]): LayerFilter {
    const filters: LayerFilter = {};

    fields.forEach(filter => {
      const filterApplied = new FilterApplied(filter);
      filters[filter.id] = filterApplied;
    });
    return filters;
  }

  /**
   * In fieldsets, find first field config that matches a predicate - can provide an array of extraFields.
   * @param fieldsets
   * @param predicate
   * @param extraFields e.g. [vesselField] - if provided, would be searched first!
   * @returns first field config matching predicate.
   */
  public static findFieldInFieldsets(
    fieldsets: Fieldset[],
    predicate: (field: EntityFieldDefinition) => boolean,
    extraFields: EntityFieldDefinition[] = [],
  ): EntityFieldDefinition {
    const fields = [...extraFields];
    fields.push(...this.flattenFieldsetsIntoFields(fieldsets));
    return fields.find(field => predicate(field));
  }

  /**
   * Initializes filter configuration. **FilterType** specifies how we filter (is it a multi, interval or datepicker)
   * In most cases it can be deduced directly from the "type" of the field, so if the filterType is not provided we will
   * try to deduced it.
   *
   * The *prop* of the filter is used by heavy analytics, **propValue** of the filter is used by light-analytics
   * both of them fallback to the id of the filter if not provided.
   */
  public static initializeFieldTypeAndProp(field: FieldSettings): FieldSettings {
    if (!field) {
      return;
    }
    field.propValue = field.propValue ? field.propValue : field.id;
    field.prop = field.prop ? field.prop : field.id;

    if (field.filterType) {
      /*
       * double-date and intersection filters should have the type to be defined
       * and it should be date or datetime (once doubledate will support datetimes)
       */
      if (field.filterType == 'doubledate' || field.filterType == 'intersection' && !field.type) {
        field.type = 'date';
      }
      return field;
    }

    switch (field.type) {
      case 'string':
        field.filterType = 'multi';
        break;
      case 'number':
        field.filterType = 'interval';
        break;
      case 'boolean':
      case 'checkbox':
        field.filterType = 'checkbox';
        break;
      case 'date':
        field.filterType = 'date';
        break;
      case 'datetime':
        field.filterType = 'datetime';
        break;
      case 'graph':
      case 'picture':
      case 'link':
      case 'quarter':
      default:
        break;
    }

    return field;
  }

  /** From filters and an array of matchingIds, return filters that match the matchingIds */
  public static getFiltersMatchingIds(filters: LayerFilter, matchingIds: string[]): LayerFilter {
    const matchingFilters: LayerFilter = {};

    filters && matchingIds?.forEach(fieldId => {
      if (filters[fieldId]) {
        matchingFilters[fieldId] = filters[fieldId];
      }
    });

    return matchingFilters;
  }

  public static mergeLayerFilters(...filtersCollections: LayerFilter[]): LayerFilter {
    const mergedFilters = {};
    filtersCollections.forEach(filters => Object.assign(mergedFilters, filters));
    return mergedFilters;
  }

  /**
   * Returns the fieldsets default filters, which are the filters built from all the fieldsets' fields which have a
   * default value.
   */
  public static getFieldsetsDefaultFilters(fieldsets: Fieldset[]): LayerFilter {
    const fieldsWithDefault = this.flattenFieldsetsIntoFields(fieldsets)?.filter(f => f.default != null);
    const filters: LayerFilter = {};
    fieldsWithDefault?.forEach(field => {
      if (field.default) {
        const defaultValue = FilterHelper.getDefaultFieldValue(field);
        if (defaultValue == null) return;
        const filter: FilterApplied = {
          ...field,
          active: true,
          propValue: field.propValue ?? field.id,
          values: defaultValue,
        };
        filters[field.id] = filter;
      }
    });
    return filters;
  }

  /* From a dataset, find distinct values of a given key */
  public static findDistinctPropValues<T>(dataset: RawDataPoint[], key: string): Set<T> {
    const values = new Set<T>();

    dataset.forEach(item => {
      const value = getChained<T>(item, key);
      if (value) {
        values.add(value);
      }
    });
    return values;
  }

  /**
   * Takes a full filters state (that is state with the config of each filter) and generates the
   * light filters state - just the values
   */
  public static fromFullToSimpleState(commonFiltersFullState: LayerFilter): FiltersState {
    const filtersState: FiltersState = {};
    for (const field in commonFiltersFullState) {
      filtersState[field] = commonFiltersFullState[field].values;
    }
    return filtersState;
  }

  /**
   * Serializes Era into string, eg. Last 30 days relative to utc_now: type:past;scale:day;extent:30;relativeTo:utc_now)
   */
  public static serializeEra(era: Era): string {
    if (era.isPreviousEra) return 'previous';
    if (era.isCompleteDataset) return 'all';
    let stringEra = `type:${era.type}`;
    for (const eraParam of ['scale', 'extent', 'pastExtent', 'futureExtent', 'relativeTo'] as (keyof Era)[]) {
      const paramValue = era[eraParam];
      if (paramValue !== null && paramValue !== undefined) {
        stringEra += `;${eraParam}:${paramValue}`;
      }
    }
    return stringEra;
  }

  /**
   * Transform ResolvedInterval in a string representation
   */
  public static serializeRelativeDate(resolvedInterval: ResolvedInterval, dateConfig: IntervalField): string {
    if (resolvedInterval == null || resolvedInterval?.era?.isCompleteDataset) {
      return FilterHelper.serializeEra(COMPLETE_ERA);
    }
    if (resolvedInterval.era?.isPreviousEra) return FilterHelper.serializeEra(PREVIOUS_PERIOD_ERA);

    if (resolvedInterval.era && dateConfig && Array.isArray(dateConfig.eras)) {
      const era = dateConfig.eras.find(candidateEra => DateHelper.erasAreEqual(candidateEra, resolvedInterval.era));
      return FilterHelper.serializeEra(era);
    }

    /*
     * For a double date we check if chosen date corresponds to an era
     * or if it's a customized date which should be stated as absolute date
     */
    if (Array.isArray(dateConfig.eras)) {
      const extent = resolvedInterval.extent;
      const selectedEra = DateHelper.selectCorrespondingEra(
        dateConfig.eras,
        dayjs(extent[0]),
        dayjs(extent[1]),
        0.01,
      );
      if (selectedEra) return FilterHelper.serializeEra(selectedEra);
    }

    // case when date shouldn't be serialize as relative, in this case we just return interval values
    return resolvedInterval.extent.join(',');
  }

  /**
   * Transform string url value in ResolvedInterval object
   * If value isn't a relativeDate, it's an interval as [timestampStart, timestampEnd]
   * If it's none of relativeDate or interval, return null (meaning: skip this filter)
   */
  public static parseInterval(value: string, refInterval?: Interval): ResolvedInterval | null {
    if (!value || !value.length) {
      return null;
    }
    if (value === 'all') {
      return {
        extent: null,
        era: { ...COMPLETE_ERA },
      };
    }
    if (value === 'previous') {
      return {
        extent: null,
        era: { ...PREVIOUS_PERIOD_ERA },
      };
    }
    if (!value.includes('type')) {
      if (!isSerializedInterval(value)) return null;
      const interval = getNumberListFromString(value) as Interval;
      return { extent: interval };
    }
    /** Ensure we are not working with an URI-encoded string  */
    if (NavigationHelper.isUriEncoded(value)) {
      value = NavigationHelper.decodeSingleParameterValue(value);
    }
    const period: Era = {
      title: null,
      type: 'past',
    };
    const periodParams = value.split(';');
    periodParams.forEach(param => {
      const [paramId, paramValue] = param.split(':');
      if (paramId === 'type') {
        period[paramId] = paramValue as EraType;
      } else if (paramId === 'extent') {
        period[paramId] = Number(paramValue);
      } else if (paramId === 'pastExtent') {
        period[paramId] = Number(paramValue);
      } else if (paramId === 'futureExtent') {
        period[paramId] = Number(paramValue);
      } else if (paramId === 'scale') {
        period[paramId] = paramValue as ScalePeriodType;
      } else if (paramId === 'relativeTo') {
        period[paramId] = paramValue as EraRelativeTo;
      }
    });
    return {
      extent: DateHelper.period({ era: period, refPeriod: refInterval }),
      era: period,
    };
  }

  public static allowsNulls(filter: FilterApplied): boolean {
    return filter.values?.includes('__nullValues') || filter.allowNoProperty;
  }

  public static isEraFilterType(filter: FilterConfig): boolean {
    return filter.filterType === 'doubledate' || filter.filterType === 'intersection';
  }

  /**
   * Fill the data of a 'multi' field (filter).
   * This function has impacts on perf that's why there are tricks to optimize it.
   *
   * @param field         The 'multi' field to populate
   * @param data          The data used to populate the field
   * @param options       Field options, such as prop. value, title value, whether to reset the data, ...
   */
  public static getMultiPopulateValues(
    field: FieldSettings,
    data: readonly RawDataPoint[],
    { propValue, propTitle, propOrder, keepExisting }: FilterPopulateOptions,
  ): OptionValue[] {
    /**
     * We use a dictionary to be sure to create unique values
     * In some cases (for common filters for multiple layers) we might be asked to keep all existing values
     */
    const populateDict: { [id: string]: OptionValue } = keepExisting && field.values
      ? DataHelpers.toDict(field.values, d => d.value)
      : {};

    /** Check whether propTitle and propOrder have to be computed, to avoid calling getChained too much */
    const noSpecificPropTitle = propTitle === propValue; // propTitle is always defined, because falls back on field.id
    const noSpecificPropOrder = propOrder == null || propOrder === propTitle;

    /**
     * Go over the whole dataset and push to the values dictionary.
     * Using a regular for loop instead of a foreach to avoid lambda function that obscures perf analysis.
     */
    for (let index = 0; index < data.length; index++) {
      const itemValue = getChained<NumOrString>(data[index], propValue);

      /** Multi has already been populated with this value */
      if (itemValue in populateDict) {
        continue;
      }

      const itemTitle = noSpecificPropTitle ? itemValue as string : getChained<string>(data[index], propTitle);
      /** Don't set order if it's not specific, to have smaller objects. */
      const itemOrder = noSpecificPropOrder ? undefined : getChained(data[index], propOrder);

      /*
       * If the value inside the data is an array, then we will go over all values inside this array
       * and add them to the list
       */
      if (Array.isArray(itemValue)) {
        for (let i = 0; i < itemValue.length; ++i) {
          const valueInArray = itemValue[i];
          let titleInArray = valueInArray;
          let orderInArray = null;
          if (Array.isArray(itemTitle) && i < itemTitle.length) {
            titleInArray = itemTitle[i];
          }
          if (Array.isArray(itemOrder) && i < itemOrder.length) {
            orderInArray = itemOrder[i];
          }
          populateDict[valueInArray] = { value: valueInArray, title: titleInArray, order: orderInArray };
        }
      } else if (itemValue == null || (Array.isArray(itemValue) && itemValue.length === 0)) {
        field.hasNullValue = true;
      } else {
        populateDict[itemValue] = { value: itemValue, title: itemTitle, order: itemOrder };
      }
    }

    // we create an array from the dictionary
    return Object.values(populateDict);
  }

  public static isNumeric(n): boolean {
    return !isNaN(parseFloat(n)) && isFinite(n);
  }

  public static getFieldIdForRefDataChaining(datasetName: string): string {
    return `${datasetName}Ids`;
  }

  public static fieldIdIsForRefDataChaining(fieldId: string): boolean {
    return fieldId.slice(-3) === 'Ids';
  }

  /**
   * Fills filter with data (inplace).
   *
   * It takes the field (**FieldSettings**) as parameter but it is the actual object
   * behind, be careful when overriding data inside.
   *
   * @param  {FieldSettings}  field                 Settings of the field (filter) to populate
   * @param  {RawDataPoint[]} data                  Data used to populate the field (filter)
   * @param  {boolean}        keepExisting          Keep existing filter values or populate only with given `data`
   *
   * @return {void}
   */
  public static populateField(
    field: FieldSettings,
    data: DeepReadonly<RawDataPoint[]>,
    keepExisting: boolean = false,
  ): void {
    // Return if there's no data to populate the filter
    if (!data || data.length === 0) {
      // Reset the filter values if it should not keep existing values
      if (!keepExisting) {
        field.values = [];
      }
      return;
    }

    switch (field.filterType) {
      case 'single':
      case 'multi':
      case 'excludemulti':
      case 'forceExclude':
      case 'forceInclude':
        // Get the options which determine how values are handled
        const populateOptions: FilterPopulateOptions = {
          propValue: field.propValue ?? field.id,
          propTitle: field.propTitle ?? field.id,
          /** Note that if `field.propOrder` is not defined, ordering would be done by default on propTitle */
          propOrder: field.propOrder,
          ordered: field.ordered,
          keepExisting,
        };

        field.values = FilterHelper.getMultiPopulateValues(field, data, populateOptions);
        break;
      case 'interval':
      case 'date':
      case 'datetime':
      case 'doubledate': {
        const propValue = field.propValue ?? field.id;
        const values = [];
        // Retrieve dataset values
        for (const d of data) {
          const value = getChained(d, propValue);
          if (value != null) {
            // We also handle the case when the value is an array
            if (Array.isArray(value) && value.length) {
              values.push(...value);
            } else {
              values.push(value);
            }
          }
        }

        // Finding interval limits
        const intervalField = field as IntervalField;
        /*
         * if we are populating an interval which does not have data and we did not find any new data
         * just keep it `noValue=true` and do not set any interval
         */
        if (!values.length) {
          if (intervalField.noValue !== false || !keepExisting) {
            intervalField.noValue = true;
          }
          break;
        }
        intervalField.noValue = false;

        // Min/max
        const valueMin = min(values);
        const valueMax = max(values);

        /*
         * In some case (common filters for exemple) we want to keep the larger interval if it was already populate by
         * another layer
         */
        if (keepExisting) {
          intervalField.interval = [
            (intervalField.interval && intervalField.interval[0] < valueMin) ? intervalField.interval[0] : valueMin,
            (intervalField.interval && intervalField.interval[1] > valueMax) ? intervalField.interval[1] : valueMax,
          ];
        } else {
          intervalField.interval = [valueMin, valueMax];
        }
        break;
      }
      case 'intersection': {
        let minDs: number = null;
        let maxDe: number = null;

        for (const d of data) {
          const leftValue = getChained<number>(d, field.leftPropValue);
          const rightValue = getChained<number>(d, field.rightPropValue);
          if (!minDs || leftValue < minDs) minDs = leftValue;
          if (!maxDe || rightValue > maxDe) maxDe = rightValue;
        }
        const intervalField = field as IntervalField;

        /*
         * In some case (common filters for example) we want to keep the larger interval if it was already populate by
         * another layer
         */
        if (keepExisting) {
          intervalField.interval = [
            (intervalField.interval && intervalField.interval[0] < minDs) ? intervalField.interval[0] : minDs,
            (intervalField.interval && intervalField.interval[1] > maxDe) ? intervalField.interval[1] : maxDe,
          ];
        } else {
          intervalField.interval = [minDs, maxDe];
        }
        break;
      }
      default:
    }
  }

  /**
   * Restrict the list of filters to be applied to an item. \
   * The specific filters mechanism enables to restrict the filters to be applied according to the item's properties. \
   * If the item has a property declared in the specific filters, only the corresponding specific filters will be
   * applied.
   *
   * @param filters           The initial filters apply
   * @param d                 The data item to be filtered
   * @param specificFilters   The specific filters
   * @returns                 The final filters to apply to the item
   */
  public static getSpecificFilters(
    filters: LayerFilter,
    d: RawDataPoint,
    specificFilters?: { [propId: string]: string[] },
  ): LayerFilter {
    const dataSpecificFilters: string[] = [];
    // Get the specific filters matching the item properties
    Object.keys(specificFilters).forEach(propId => {
      if (getChained(d, propId)) dataSpecificFilters.push(...specificFilters[propId]);
    });
    // Apply the initial filters if there are no specific filters for the item
    if (!dataSpecificFilters.length) {
      return filters;
    }
    // Restrict the filters to the item's specific filters, also keep the filters on custom fields
    const filtersToApply: LayerFilter = {};
    for (const filterId in filters) {
      if (dataSpecificFilters.indexOf(filterId) > -1 || filterId.startsWith('cf_')) {
        filtersToApply[filterId] = filters[filterId];
      }
    }
    return filtersToApply;
  }

  /**
   * Check whether an item matches a list of filters.
   *
   * @param filters           The filters to apply
   * @param d                 The data item to be filtered
   * @param specificFilters   Specific filters. See `FilterHelper.getSpecificFilters` function
   * @returns                 The result of the filtering : `true` if the item `d` matches all the filters,
   *                                                        `false` otherwise
   */
  public static filter(
    filters: LayerFilter,
    d: RawDataPoint,
    specificFilters?: { [propId: string]: string[] },
  ): boolean {
    // If layer has a list of specific filters for a propId and current data has this property we apply these filters
    const appliedFilters = specificFilters
      ? FilterHelper.getSpecificFilters(filters, d, specificFilters)
      : filters;

    /*
     * forceInclude and forceExclude filters are filter type that have to be taken in account before other filters
     * There are filter whose contains an array of values, if  d contains this value:
     *   -In case of forceInclude: d is automatically filter in (regardless of the values on the other filters)
     *   -In case of forceExclude: d is automatically filter out (regardless of the values on the other filters)
     */
    const forceIncludeFilters = Object.values(filters).filter(f =>
      f.filterType === 'forceInclude' || f.filterType === 'forceExclude'
    );
    for (const forceFilter of forceIncludeFilters) {
      /*
       * This valueMap dictionary is useful to avoid having to iterate over all the filter values
       * for each element to be filtered. In cases where the multi filter has many values.
       * This allows to decrease the complexity of the filter from O(n²) => O(n)
       */
      if (!forceFilter.valueMap) {
        forceFilter.valueMap = {};
        for (const value of forceFilter.values) {
          forceFilter.valueMap[value] = true;
        }
      }
      const value = getChained<NumOrString>(d, forceFilter.propValue);
      if (forceFilter.filterType === 'forceInclude' && forceFilter.valueMap[value]) {
        return true;
      }
      if (forceFilter.filterType === 'forceExclude' && forceFilter.valueMap[value]) {
        return false;
      }
    }

    for (const id in appliedFilters) {
      const filter = appliedFilters[id];

      /*
       * There is nothing to apply if a filter does not contain any values or should not be applied explicitly.
       * Some filter are saved inputs but shouldn't directly filter data
       * it's the case of the wti vessel selector. Model inputs are saved
       * through filter but shouldn't filter data
       */
      if (filter.notApplied || !filter.values?.length) {
        continue;
      }

      // Whether null value matches the current filter
      const filterAllowsNulls = FilterHelper.allowsNulls(filter);

      /** Get the value to be filtered */
      let value = getChained(d, filter.propValue);

      /*
       * Handle the case of null values (undefined is considered the same as null).
       * Checkbox filters are excluded from these checks. For checkbox, `null` value is considered as `false`.
       * Intersection and distanceToEntity filters are excluded from these checks because the filtering values
       * are `leftPropValue` (`latProp`) and `rightPropValue` (`lonProp`), not `propValue`
       */

      // Case of null value and nulls are allowed.
      if (
        value == null && filterAllowsNulls
        && !FilterHelper.hasCheckboxBehavior(filter.filterType)
        && !['intersection', 'distanceToEntity'].includes(filter.filterType)
      ) {
        continue;
      }

      if (value == null && filter.values.includes(AutocompleteFilterHelper.BLANK_OPTION_VALUE)) {
        continue;
      }

      /*
       * Case of null value and nulls are not allowed.
       * We additionally exclude multi filters from this check because the value `null`
       * can explicitely be in the filter's values.
       */
      if (
        value == null
        && !FilterHelper.hasCheckboxBehavior(filter.filterType)
        && !['intersection', 'multi', 'distanceToEntity'].includes(filter.filterType)
      ) {
        return false;
      }

      switch (filter.filterType) {
        case 'interval':
        case 'date':
        case 'datetime':
        case 'doubledate':
          /*
           * If doubledate values === [null] it means we select complete dataset
           * In this case the data shouldn't be filter
           */
          if (filter.filterType === 'doubledate' && filter.values.length === 1 && !filter.values[0]) {
            continue;
          }
          // Value can be an array
          if (Array.isArray(value)) {
            if (!value.length) return false;
            if (
              value.every(v =>
                (filter.values[0] !== null && v < filter.values[0])
                || (filter.values[1] !== null && v > filter.values[1])
              )
            ) {
              return false;
            }
          } // even for date the date used contain sometimes the hour and minutes so we can't be less precise than that
          else if (
            (filter.values[0] !== null && value < filter.values[0])
            || (filter.values[1] !== null && value > filter.values[1])
          ) {
            return false;
          }
          break;
        case 'intersection': {
          const dateStart = getChained(d, filter.leftPropValue);
          const dateEnd = getChained(d, filter.rightPropValue);

          if (dateStart === null) {
            return false;
          }

          /*
           * Can happen that sometimes with open ended interval dateStart or dateEnd
           * In this case we set the filter date start/end to null to be able to filter on it
           */
          const filterDateStart = filter.values[0];
          const filterDateEnd = filter.values[1];

          // unknown end => to infinity & beyond
          if (
            ([dateStart, filterDateEnd].every(x => x !== null) && dateStart > filterDateEnd)
            || ([dateEnd, filterDateStart].every(x => x !== null) && dateEnd < filterDateStart)
          ) {
            return false;
          }

          break;
        }
        case 'checkbox-exclude':
        case 'checkbox': {
          const checked = filter.values[0];
          if (checked === CHECKBOX_EXCLUDE) {
            if (value) return false;
            break;
          }
          if (filterAllowsNulls && value == null) break;
          if (!value && checked) return false;
          break;
        }
        case 'single':
        case 'multi':
        case 'excludemulti': {
          /*
           * This valueMap dictionnary is usefull to avoid having to iterate over all the filter values
           * for each element to be filtered. In cases where the multi filter has many values.
           * This allows to decrease the complexity of the filter from O(n²) => O(n)
           */
          if (!filter.valueMap) {
            filter.valueMap = {};
            for (const value of filter.values) {
              filter.valueMap[value] = true;
            }
          }

          if (value && Array.isArray(value)) {
            if (!value.filter((c: any) => c in filter.valueMap).length) {
              return filter.filterType === 'excludemulti';
            }
            if (filter.filterType === 'excludemulti') {
              return false;
            }
            break;
          }

          /*
           * Explicitly cast `undefined` to `null`.
           * This enables passing the `null` value directly in the filter values.
           */
          value = value === undefined ? null : value;
          // unfortunately the multifield values can come in as list of strings even for numerical values
          if (!((value as number) in filter.valueMap)) {
            if (filter.filterType === 'excludemulti') {
              break;
            }
            return false;
          }
          if (filter.filterType === 'excludemulti') {
            return false;
          }
          break;
        }
        case 'distanceToEntity': {
          /** Retrieve current item coordinates. */
          const latValue = getChained<number>(d, filter.latProp);
          const lonValue = getChained<number>(d, filter.lonProp);

          if (DistanceToEntityHelper.filterCoordDistanceToEntity([lonValue, latValue], filter) === false) {
            return false;
          }
          break;
        }
        default:
      }
    }
    return true;
  }

  /**
   * Add intelligibleValues to each FilterApplied which is a readable description of filters, where titles are displayed
   * instead of ids (vessel titles instead of ids e.g.).
   * @param filters
   * @param appInfoService
   * @returns an array of FilterApplied with intelligibleValues
   */
  public static getIntelligibleFilters(filters: LayerFilter, appInfoService: AppInfoService): FilterApplied[] {
    if (!filters) {
      return [];
    }
    return Object.keys(filters).map(filterId => {
      const filter = filters[filterId];
      if (filter.intelligibleValues) {
        return filter;
      }
      const values = filter.values ?? [];
      const filterTitle = appInfoService.getVesselFilterTitle(filterId);
      switch (filter.filterType) {
        case 'date':
        case 'datetime':
        case 'doubledate':
          const dateStart = dayjs.utc(values[0]).format('YYYY-MM-DD');
          const dateEnd = dayjs.utc(values[1]).format('YYYY-MM-DD');
          filter.intelligibleValues = `${filterTitle}: Between ${dateStart} and ${dateEnd}`;
          break;
        case 'interval':
          filter.intelligibleValues = `${filterTitle}: Between ${values[0]} and ${values[1]}`;
          break;
        case 'intersection':
          const intersectionStart = dayjs.utc(values[0]).format('YYYY-MM-DD');
          const formattedEnd = dayjs.utc(values[1]).format('YYYY-MM-DD');
          filter.intelligibleValues = `${filterTitle}: Must overlap  ${intersectionStart} and ${formattedEnd}`;
          break;
        case 'checkbox':
          filter.intelligibleValues = `${filterTitle}: ${values[0] ? 'Yes' : 'No'}`;
          break;
        case 'multi':
          filter.intelligibleValues = `${filterTitle}: ${
            values.map(v => appInfoService.getTitleForVesselFilterValue(filterId, v)).join(',')
          }`;
          break;
        case 'excludemulti':
          filter.intelligibleValues = `${filterTitle}: value not in [${
            values.map(v => appInfoService.getTitleForVesselFilterValue(filterId, v)).join(',')
          }]`;
          break;
        default:
          filter.intelligibleValues = `${filterTitle}: ${
            values.map(v => appInfoService.getTitleForVesselFilterValue(filterId, v)).join(',')
          }`;
          break;
      }
      filter.id = filterId;
      return filter;
    });
  }

  public static flattenFieldsetsIntoFields(fieldsets: Fieldset[]): EntityFieldDefinition[] {
    const fields: EntityFieldDefinition[] = [];
    // in the conf the columns can be specified as fieldsets, so we have to flatten and concatenate fields
    fieldsets?.forEach(fieldset => {
      fieldset.fields.forEach(field => field.fieldsetTitle = fieldset.title);
      fields.push(...fieldset.fields);
    });

    return fields;
  }

  // If we include advanced filters as a table, we need to discard charts and non visible fields
  public static getFieldsToShowInTable(fieldsets: Fieldset[]): EntityFieldDefinition[] {
    return this.flattenFieldsetsIntoFields(fieldsets).filter(field => this.shouldShowInTable(field));
  }

  public static shouldShowInTable(field: FieldSettings): boolean {
    return (!field.type || field.type !== 'chart')
      && (field.visible == null || field.visible);
  }

  public static createSelectableColumn(column: FieldSettings, fieldsetTitle: string): SelectableColumn {
    return { id: column.id, name: column.title, fieldsetTitle: fieldsetTitle };
  }

  // null means infinite range
  public static rangeIncludedInRange(innerRange: IntervalOrNull, outerRange: IntervalOrNull): boolean {
    // are infinite bounds are included in infinite bounds ?
    if (outerRange === null) return true;
    if (outerRange !== null && innerRange === null) return false;
    return innerRange[0] >= outerRange[0] && innerRange[1] <= outerRange[1];
  }

  public static resolvedIntervalFromFilterApplied(filter: FilterApplied): ResolvedInterval {
    const resolvedInterval: ResolvedInterval = {
      extent: filter.values as Interval,
    };
    if (filter.selectedEra) resolvedInterval.era = filter.selectedEra;
    return resolvedInterval;
  }

  public static hasCheckboxBehavior(filterType: FilterType): boolean {
    return ['checkbox', 'checkbox-exclude'].includes(filterType);
  }

  public static getDefaultFieldValue(field: EntityFieldDefinition): unknown[] {
    if (!field.default) return;
    // Double date or intersection
    if (FilterHelper.isEraFilterType(field)) {
      const intervalField = field as IntervalField;
      return DateHelper.period({ era: intervalField.default });
    } else if (field.default === 'firstAvailable') {
      return field.values?.length ? [field.values[0].value] : null;
    }
    if (Array.isArray(field.default)) return field.default;
    return [field.default];
  }

  /* Formats the total number of applied filters as a string, returning a dot if double digit number of filters */
  public static formatNumberOfFiltersAppliedAsString(nbOfFiltersApplied: number): string {
    /* Do not display anything if not filters at all */
    if (nbOfFiltersApplied === 0) {
      return '';
    }
    /* If more than 9 filters, display a simple dot `.` */
    if (nbOfFiltersApplied > 9) {
      return '.';
    }

    return nbOfFiltersApplied.toString();
  }

  public static getFiltersAppliedOnFieldsets($fieldsets: QueryList<SidebarFieldsetComponent>): LayerFilter {
    const filters: LayerFilter = {};
    $fieldsets?.forEach($fs => Object.assign(filters, $fs.appliedFilters));
    return filters;
  }

  public static filterDataset<T extends RawDataPoint>(dataset: readonly T[], filters: LayerFilter): T[] {
    const hasFilters = size(filters) > 0;
    return hasFilters ? dataset.filter(d => FilterHelper.filter(filters, d)) : dataset as T[];
  }
}

/**
 * Takes filters state (from page or from component) and transforms it into list of parameters
 * Special treatment is for ResolvedIntervals that have a different form in state then other filters
 */
export function asParams(filtersState: FiltersState | FiltersStatePeriod): ComponentParameter[] {
  const params: ComponentParameter[] = [];
  for (const filterId in filtersState) {
    const filterState = filtersState[filterId];

    /**
     * TODO: Filtering - if the value of filter is null, then it is an interval filter that should be removed
     * Make this function config aware, so we can be specific
     */
    if (filterState === null) {
      params.push({ name: filterId, value: null, shouldBeRemovedFromUrl: true });
    } // FiltersStatePeriod contains ResolvedIntervals - other filters contains only array of values
    else if (filterState['extent']) {
      params.push({ name: filterId, value: filterState['extent'] });
    } else if (isArray(filterState)) {
      params.push({ name: filterId, value: filterState });
    }
  }
  return params;
}
