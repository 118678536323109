import { ChangeDetectionStrategy, Component, TrackByFunction, computed, output, signal } from '@angular/core';
import { NgFor } from '@angular/common';
import { MatInput } from '@angular/material/input';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

import { DateHelper } from '../../../../helpers/date-helper';
import { IdentityItem } from '../../../../helpers/types';
import { PearlIconComponent } from '../icons/pearl-icon.component';
import { PearlFormFieldComponent } from '../forms/pearl-form-field.component';

interface Timezone extends IdentityItem {
  id: string;
  searchString: string;
}

const sanitizeSearchString = (s: string): string => s.replaceAll(/[/\s]/g, '').toLowerCase();
function addSearchString(timezone: IdentityItem & { id: string }): Timezone {
  const searchString = sanitizeSearchString(timezone.title.concat(timezone.subtitle));
  return {
    ...timezone,
    searchString,
  };
}

@Component({
  selector: 'pearl-timezone-picker',
  templateUrl: './timezone-picker.html',
  styleUrl: './timezone-picker.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgFor, MatInput, PearlIconComponent, ReactiveFormsModule, PearlFormFieldComponent],
})
export class PearlTimezonePickerComponent {
  private availableTimezones: Timezone[];
  protected readonly filter = signal<string>('');
  protected readonly displayedTimezones = computed<Timezone[]>(() =>
    this.availableTimezones.filter(tz => tz.searchString.includes(this.filter()))
  );

  protected searchInput = new FormControl('', { nonNullable: true });

  public readonly timezoneSelected = output<string>();

  constructor() {
    this.availableTimezones = DateHelper.getIdentityItemOfficialTimezones().map(addSearchString);

    this.searchInput.valueChanges.subscribe(v => {
      if (this.searchInput.invalid) return;
      this.filter.set(sanitizeSearchString(v));
    });
  }

  protected selectTimezone(tz: Timezone): void {
    this.searchInput.reset();
    this.timezoneSelected.emit(tz.id);
  }

  protected trackOptions: TrackByFunction<Timezone> = (_idx, option: Timezone) => option.id;
}
