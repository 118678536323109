import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation,
  inject } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgIf } from '@angular/common';

import { NavigationHelper } from '../helpers/navigation-helper';
import { ActionEvent, Button, DynamicConfig } from '../helpers/types';
import { Config } from '../config/config';
import { DataLoader } from '../data-loader/data-loader';
import { ComponentHelper } from '../helpers/component.helper';
import { PageStateService } from './services/page-state.service';
import { ButtonType, PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';

@Component({
  selector: 'field-button',
  templateUrl: 'field-button.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    PearlButtonComponent,
    MatTooltipModule,
    MatIconModule,
  ],
})
export class FieldButtonComponent {
  @Input({ required: true })
  buttonConfig: Button;
  @Input({ required: true })
  values: any;
  @Input({ required: false })
  buttonType: ButtonType = 'alternative';

  @Output()
  buttonClick = new EventEmitter<ActionEvent>();

  private readonly dataLoader = inject(DataLoader);

  private readonly pageStateService = inject(PageStateService);

  public async clickButton(event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();

    const dynamicConfig: DynamicConfig[] = Config.searchConfigForKey(this.buttonConfig, '@lazyLoadModalConfig')
      .map(config => ({
        path: config.path,
        baseUrl: config.value as string,
        latestCalledUrl: '',
        latestResultHash: '',
      }));

    if (dynamicConfig.length > 0) {
      const config = await ComponentHelper.populateDynamicConfigs(
        dynamicConfig,
        this.buttonConfig as any,
        this.dataLoader,
        { componentParameters: this.pageStateService.getStateAsComponentParameters() },
      );

      if (config.dynamicConfigHasChanged) {
        this.buttonConfig = config.componentSettings;
      }
    }

    const buttonEvent = NavigationHelper.constructActionEvent({
      event,
      action: this.buttonConfig,
      values: this.values,
    });

    if (!buttonEvent) {
      return;
    }

    this.buttonClick.emit(buttonEvent);
  }
}
