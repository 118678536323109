import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, computed,
  inject, input, output, signal, viewChild } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { MatCalendar, yearsPerPage } from '@angular/material/datepicker';
import { CdkTrapFocus } from '@angular/cdk/a11y';

import { Subject } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';

import { pearlDatepickerAnimations } from './datepicker-animations';
import { PearlClockComponent } from './clock';
import { PearlDatepickerHeaderComponent } from './datepicker-header';
import { PearlTimezonePickerComponent } from './timezone-picker';
import { CalendarView, DateFilter, DatePickerControls, DatepickerView, DatetimePrecision, DatetimeSection,
  isCalendarView, isClockView, isDatePrecision, isTimePrecision } from './datepicker-types';
import { provideDayjsDateAdapter } from './dayjs-date-adapter';
import { DateHelper } from '../../../../helpers/date-helper';
import { PearlButtonComponent } from '../buttons/pearl-button.component';

@Component({
  template: '',
  standalone: true,
})
class EmptyComponent {}

@Component({
  selector: 'pearl-datepicker-content',
  templateUrl: './datepicker-content.html',
  styleUrl: './datepicker-content.scss',
  host: {
    'class': 'pearl-datepicker-content',
    '[class.pearl-datepicker-content-touch]': 'isTouchUi()',
    '[@transformPanel]': 'animationState',
    '(@transformPanel.start)': 'handleAnimationEvent($event)',
    '(@transformPanel.done)': 'handleAnimationEvent($event)',
  },
  animations: [pearlDatepickerAnimations.transformPanel, pearlDatepickerAnimations.fadeInCalendar],
  exportAs: 'pearlDatepickerContent',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  providers: provideDayjsDateAdapter(),
  imports: [
    CdkTrapFocus,
    MatCalendar,
    PearlClockComponent,
    PearlTimezonePickerComponent,
    PearlDatepickerHeaderComponent,
    PearlButtonComponent,
  ],
})
export class PearlDatepickerContentComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Reference to the internal calendar component. */
  private calendar = viewChild<MatCalendar<Dayjs>>(MatCalendar);

  /** Current state of the animation. */
  protected animationState: 'enter-dropdown' | 'enter-dialog' | 'void';

  /** Emits when an animation has finished. */
  public readonly animationDone = new Subject<void>();

  /** Whether there is an in-progress animation. */
  public isAnimating = false;

  protected calendarHeader = EmptyComponent;

  public readonly precision = input.required<DatetimePrecision>();
  public readonly timeOnly = input<boolean>(false);
  public readonly withTimezone = input<boolean>(false);
  public readonly minuteGranularity = input<number>(1);

  public readonly selected = input.required<Dayjs | null>();
  public readonly timezone = input<string>();

  public readonly minDate = input<Dayjs>();
  public readonly maxDate = input<Dayjs>();
  public readonly dateFilter = input<DateFilter>();

  public readonly isTouchUi = input<boolean>(false);

  public readonly dateSelected = output<Dayjs>();
  public readonly timezoneSelected = output<string>();
  public readonly selectionEnded = output<void>();

  protected readonly startDate = signal<Dayjs | undefined>(undefined);
  protected readonly view = signal<DatepickerView>('month');
  protected readonly step = computed<DatetimeSection>(() =>
    isCalendarView(this.view()) ? 'date' : isClockView(this.view()) ? 'time' : 'timezone'
  );
  protected readonly calendarStartView = computed<CalendarView>(() =>
    this.step() === 'date' ? this.view() as CalendarView : 'month'
  );

  protected readonly showHeader = computed<boolean>(() =>
    isDatePrecision(this.precision()) || !this.timeOnly() || this.withTimezone()
  );

  protected elementRef = inject(ElementRef);
  private changeDetectorRef = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.animationState = this.isTouchUi() ? 'enter-dialog' : 'enter-dropdown';
    this.startDate.set(this.selected() ?? this.getCurrentDatetime());

    if (isTimePrecision(this.precision()) && this.timeOnly()) {
      this.view.set('hour');
    } else if (isDatePrecision(this.precision()) && this.precision() !== 'day') {
      this.view.set('multi-year');
    }
  }

  ngAfterViewInit(): void {
    this.calendar()?.focusActiveCell();
  }

  ngOnDestroy(): void {
    this.animationDone.complete();
  }

  selectYear(date: Dayjs): void {
    const newDate = (this.selected() ?? this.startDate())
      .year(date.year());

    if (this.selected() || this.precision() === 'year') {
      this.dateSelected.emit(newDate);
    } else {
      this.startDate.set(newDate);
    }

    if (this.precision() === 'year') {
      this.selectionEnded.emit();
    }
  }

  selectMonth(date: Dayjs): void {
    const newDate = (this.selected() ?? this.startDate())
      .year(date.year())
      .month(date.month());

    if (this.selected() || this.precision() === 'month') {
      this.dateSelected.emit(newDate);
    } else {
      this.startDate.set(newDate);
    }

    if (this.precision() === 'month') {
      this.selectionEnded.emit();
    }
  }

  selectDate(date: Dayjs): void {
    const newDate = (this.selected() ?? this.startDate())
      .year(date.year())
      .month(date.month())
      .date(date.date());

    this.dateSelected.emit(newDate);

    if (this.precision() === 'day') {
      this.selectionEnded.emit();
    } else {
      this.view.set('hour');
    }
  }

  selectTime(date: Dayjs): void {
    const newDate = (this.selected() ?? this.startDate())
      .hour(date.hour())
      .minute(date.minute());

    this.dateSelected.emit(newDate);
    this.selectionEnded.emit();
  }

  selectCurrentDatetime(): void {
    this.dateSelected.emit(this.getCurrentDatetime());
    this.selectionEnded.emit();
  }

  selectTimezone(timezone: string): void {
    this.timezoneSelected.emit(timezone);
    this.view.set('hour');

    // If no selected date, the change will not be visible in the header unless we update startDate to the new timezone.
    if (!this.selected()) {
      this.startDate.set(DateHelper.getDayjsFromDate(this.startDate(), undefined, undefined, timezone));
    }
  }

  changeView(view: DatepickerView): void {
    const currentStep = this.step();
    this.view.set(view);
    /*
     * Update calendar current view if going from one calendar view to another only:
     * - Clock and timezone views cannot be changed from the controls.
     * - When going from clock/timezone view to calendar view, this._calendar is not defined here, and the correct view
     *   will be set by [startView].
     */
    if (currentStep === 'date' && isCalendarView(view)) {
      this.calendar().currentView = view;
    }
  }

  protected handleControls(action: DatePickerControls): void {
    if (!isCalendarView(this.view())) return;

    const activeDate = this.calendar().activeDate;
    if (!dayjs.isDayjs(activeDate) || !activeDate.isValid()) return;

    if (this.view() === 'month') {
      /*
       * In month view go to the next or previous month.
       */
      const monthMove = action === 'previous' ? -1 : 1;
      this.calendar().activeDate = activeDate.add(monthMove, 'month');
    } else if (this.view() === 'year' || this.view() === 'multi-year') {
      /*
       * In year view go to the next or previous year.
       * In multi-year view go to the next or previous page of years.
       */
      let yearMove = action === 'previous' ? -1 : 1;
      if (this.view() === 'multi-year') yearMove *= yearsPerPage;
      this.calendar().activeDate = activeDate.add(yearMove, 'year');
    }
  }

  public startExitAnimation(): void {
    this.animationState = 'void';
    this.changeDetectorRef.markForCheck();
  }

  /*
   * This might appear as not used in your editor but it is called from the component metadata host property.
   * (see @Component decorator at the top of the file)
   */
  private handleAnimationEvent(event: AnimationEvent): void {
    this.isAnimating = event.phaseName === 'start';

    if (!this.isAnimating) {
      this.animationDone.next();
    }
  }

  private getCurrentDatetime(): Dayjs {
    return DateHelper.getDayjsFromDate(dayjs(), undefined, undefined, this.timezone());
  }
}
