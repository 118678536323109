import { ChangeDetectorRef, Component, Injector, Input, ViewEncapsulation } from '@angular/core';
import { NgClass, NgStyle } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

import { AutocompleteFilterComponent } from './autocomplete-filter.component';
import { SpinFiltersCheckboxComponent } from './spin-checkbox/spin-filters-checkbox.component';
import { IntervalComponent } from './interval';
import { DoubleDateComponent } from './doubledate';
import { NumberComponent } from './number';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { FieldsetComponent } from './fieldset';
import { ActionEvent, Fieldset } from '../helpers/types';
import { DialogManager } from '../database/dialog-manager';
import { PearlButtonComponent, PearlIconComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-filters-fieldset',
  templateUrl: './sidebar-fieldset.component.html',
  styleUrls: ['./fieldset.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PearlButtonComponent,
    MatIconModule,
    NgClass,
    NgStyle,
    IntervalComponent,
    NumberComponent,
    DoubleDateComponent,
    PearlButtonComponent,
    PearlIconComponent,
    SpinFiltersCheckboxComponent,
    AutocompleteFilterComponent,
    SpinTooltipDirective,
  ],
})
export class SidebarFieldsetComponent extends FieldsetComponent {
  @Input()
  override fieldset: Fieldset;
  @Input()
  public onaction: (event: ActionEvent) => void;

  public dialogManager: DialogManager;

  constructor(injector: Injector, cdRef: ChangeDetectorRef) {
    super(injector, cdRef);
    this.dialogManager = injector.get(DialogManager);
  }

  public openConfig(config: object): void {
    this.dialogManager.simpleDialog('Config', '', config);
  }
}
