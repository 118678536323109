import { Injectable, Injector } from '@angular/core';

import dayjs from 'dayjs';
import { minBy } from 'lodash-es';

import { InMemoryCache } from './data-loader.types';
import { CancellablePromise } from '../helpers/types';
import { Config } from '../config/config';
import { PersistentCacheService } from './persistent-cache.service';

/**
 * Manages frontend in-memory cache. Typical used is when multiple components call the same url in a page.
 * This cache is not shared across multiple pages and is deleted when closing the app. See PersistentCacheService for
 * frontend cache that persists.
 */
@Injectable(
  {
    providedIn: 'root',
  },
)
export class InMemoryCacheService {
  // Endpoints called only once and not worth caching
  private static readonly CACHE_BLACK_LIST = [
    '/pipelay/search/by-title',
    '/spinadmin/product-packages/right-search-items/',
    '/osv/search/by-title',
  ];
  private queries: { [url: string]: InMemoryCache } = {};
  private config: Config;
  private persistentCacheService: PersistentCacheService;

  constructor(injector: Injector) {
    this.config = injector.get(Config);
    this.persistentCacheService = injector.get(PersistentCacheService);
  }

  /** Note: We don't cache refData or persistent cache endpoints that are called once at startup. */
  public shouldCache(url: string): boolean {
    return !InMemoryCacheService.CACHE_BLACK_LIST.includes(url)
      && !this.config.appConfig.refDataConfig.some(refData => refData.url === url)
      && !this.persistentCacheService.shouldCache(url);
  }

  /** Store the response promise in memory. Side-effect: purge old entries. */
  public store(url: string, promise: CancellablePromise<unknown>, ttl: number): void {
    this.purgeOldCache();
    this.queries[url] = { url, promise, expiry: dayjs().unix() + ttl };
  }

  /** Check that cache exists for given url and that it has not expired yet. */
  public hasValidCache(url: string): boolean {
    return url in this.queries && this.queries[url].expiry > dayjs().unix();
  }

  /**
   * Retrieve cache of given url.
   * Beware! hasValidCache should be called before otherwise it will throw an error
   * @param url
   */
  public retrieve<T>(url: string): CancellablePromise<T> {
    return this.queries[url].promise as CancellablePromise<T>;
  }

  /** Cancel and delete the query. */
  public delete(url: string): void {
    this.queries[url].promise.cancel();
    delete this.queries[url];
  }

  /** Purge queries if more than 20 (starting with oldest) */
  public purgeOldCache(): void {
    if (Object.keys(this.queries).length > 20) {
      const oldestCall = minBy(Object.values(this.queries), d => d.expiry);
      /** We don't cancel queries, because some can not have yet resolved on pages with a lot of calls! */
      delete this.queries[oldestCall.url];
    }
  }

  /** Cancel and clear all cached queries. */
  public clear(): void {
    Object.values(this.queries).forEach(({ promise }) => promise.cancel());
    this.queries = {};
  }
}
