<!-- Following div is used so that the tooltip is shown when the button is disabled -->
<div *ngIf="action && !isMenuButton" [spinTooltip]="action.tooltip">
  <pearl-button
    [ngClass]="action.disabled ? '' : action.type + '-action'"
    (click)="action.onClick()"
    [disabled]="action.disabled"
    [type]="action.type === 'primary' ? 'primary' : 'alternative'"
    [icon]="action.icon">
    {{ action.label }}
  </pearl-button>
</div>

<!-- No need of div for mat-menu-item tooltip -->
<button
  *ngIf="action && isMenuButton"
  [disabled]="action.disabled"
  (click)="action.onClick()"
  [spinTooltip]="action.tooltip"
  mat-menu-item>
  <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
  {{ action.label }}
</button>
