import { Injectable, QueryList, computed, signal } from '@angular/core';

import { ComponentParameter, FieldSettings, Fieldset, FilterApplied, FiltersState, IntervalOrNull, LayerFilter,
  NumOrString, PageParameter } from '../../helpers/types';
import { RefDataProvider } from '../../data-loader/ref-data-provider';
import { FieldsetComponent } from '../../filters';
import { RawDataPoint } from '../../graph/chart-types';
import { asParams, FilterHelper } from '../../filters/filter-helper';

interface FieldsGroupedByDataset {
  datasetName: string;
  fieldId: string;
  fieldDefinitions: FieldSettings[];
  dataIds: Set<NumOrString>;
}

@Injectable({
  providedIn: 'root',
})
/**
 * @description This service is responsible for storing all the parameters of the component
 */
export class PageStateService {
  /**
   * List of fields grouped by linked ref dataset, i.e if two fields reference vessel.mainPurpose and vessel.yearBuilt,
   * we will end up with one item containing the vessel dataset and a reference to those 2 fields
   */
  public refDataInfos: FieldsGroupedByDataset[] = [];

  /**
   * List of all fieldset - standard sidebar fieldset and advanced fieldset.
   */
  public fieldsets: Fieldset[] = [];

  /**
   * List of all parameters of page, eg vesselId or any other parameter `?vesselId=12`
   * Note "masterFilter" used to be treated that way, but it is not anymore
   */
  public readonly parameters = signal<PageParameter[]>([]);

  /**
   * State of filters on the page, usually corresponds to sidebar filters state
   */
  public readonly pageFiltersState = signal<FiltersState>({});

  /** The component page type (like `vessel`, `windfarm`, ...). This is the name of the corresponding config file. */
  public readonly pageType = signal<string>('');

  /** The ID of the component page entity. Only for component page types representing an entity (eg. `vessel`). */
  public readonly pageIdField = signal<string | null>(null);
  public readonly pageId = computed<string | null>(() =>
    this.parameters()?.find(param => param.name === this.pageIdField())?.value ?? null
  );

  /**
   * When navigating between component pages, there are two situations:
   * - Transitioning from a windfarm page to a vessel page, which requires a config reload.
   * - Moving from one windfarm page to another, where a config reload is not desired.
   *
   * A boolean value is calculated in ComponentsPageGuard to inform the component page about
   * whether a page change has occurred.
   */
  public readonly hasPageChanged = signal<boolean>(true);

  /**
   * Returns a `masterPeriod` if there is one on the component-page.
   * MasterPeriod is a time-filter on page level that usually has 3 use-cases:
   * - it synchronizes it's value with the components on the page
   * - it can be used by the comparison-period if there is one (eg. KPIs)
   * - it can is usually defined as masterFilter - it will reload light charts
   */
  public get masterPeriod(): IntervalOrNull {
    const masterPeriod = this.pageFiltersState()['masterPeriod'];
    if (!masterPeriod) {
      return null;
    }

    return masterPeriod as IntervalOrNull;
  }

  public getDefaultFilters(): FiltersState {
    const defaultFiltersFullState = FilterHelper.getFieldsetsDefaultFilters(this.fieldsets);
    return FilterHelper.fromFullToSimpleState(defaultFiltersFullState);
  }

  public addParams(newParams: PageParameter[]): void {
    this.parameters.update(parameters => {
      newParams.forEach(newParam => {
        const existingParam = parameters.find(p => p.name === newParam.name);
        if (existingParam) existingParam.value = newParam.value;
        else parameters.push(newParam);
      });
      return parameters;
    });
  }

  public setParam(param: PageParameter): void {
    this.parameters.update(parameters => {
      const existing = parameters.find(({ name }) => name === param.name);
      if (existing) existing.value = param.value;
      else parameters.push(param);
      return parameters;
    });
  }

  /** Will perform a merge of current filters and provided one. On conflict, the provided ones prevail. */
  public addFilterState(filters: FiltersState): void {
    this.pageFiltersState.update(currentState => {
      for (const [newFilterId, newValue] of Object.entries(filters)) {
        currentState[newFilterId] = newValue;
      }
      return currentState;
    });
  }

  public resetPageFiltersState(): void {
    const defaultFilters = this.getDefaultFilters();
    this.pageFiltersState.set({ ...defaultFilters }); // we need to make a copy!!
  }

  public getStateAsComponentParameters(): ComponentParameter[] {
    const filterState = asParams(this.pageFiltersState());
    this.parameters().forEach(param => {
      // Ensure we don't have duplicates in the state
      if (filterState.findIndex(filter => filter.name === param.name) !== -1) return;
      filterState.push(param);
    });
    return filterState;
  }

  /**
   * Will group the fields of provided fieldset by their referenced datasets, if there are some.
   * Will also create a "fake" field definition for each used ref dataset, in order for filtering to work.
   * Called only once upon receiving the config
   */
  public buildFieldsGroupedByDataset(): void {
    const groupedByDataset: FieldsGroupedByDataset[] = [];
    /** Perform aggregation by dataset */
    this.fieldsets.flatMap(fs => fs.fields).forEach(field => {
      const valueLookupKey = field.propValue ?? field.id;
      const chainedDatasets = RefDataProvider.getChainedDatasets(valueLookupKey);
      if (!chainedDatasets.length) return;
      const datasetName = chainedDatasets[0];
      /** Get our FieldsGroupedByDataset, or create one */
      let info = groupedByDataset.find(infos => infos.datasetName === datasetName);
      if (!info) {
        info = {
          datasetName,
          fieldId: FilterHelper.getFieldIdForRefDataChaining(datasetName),
          fieldDefinitions: [],
          dataIds: new Set(),
        };
        groupedByDataset.push(info);
      }
      info.fieldDefinitions.push(field);
    });

    /** Add "hidden" field definition for each referenced dataset */
    groupedByDataset.forEach(refDataInfo => {
      const propValue = RefDataProvider.getKeyForDataset(refDataInfo.datasetName);
      const fieldDefinitionForRefData: FieldSettings = {
        id: refDataInfo.fieldId,
        propValue,
        title: refDataInfo.datasetName,
        visible: false,
        filterType: 'multi',
      };
      this.fieldsets[0].fields.push(fieldDefinitionForRefData);
    });
    this.refDataInfos = groupedByDataset;
  }

  /** Get + store each used ID used as a foreign key to a reference dataset inside appropriate FieldsGroupedByDataset */
  public storeIdsFromDataset(data: readonly RawDataPoint[], keepExisting: boolean): void {
    for (const refInfo of this.refDataInfos) {
      if (!keepExisting) refInfo.dataIds.clear();
      const idKey = RefDataProvider.getKeyForDataset(refInfo.datasetName);
      for (const d of data) {
        const idForDataset = (d as object)[idKey] as NumOrString;
        if (idForDataset == null) continue;
        refInfo.dataIds.add(idForDataset);
      }
    }
  }

  /**
   * Will populate provided fieldsSets, and also store each used ID used as a foreign key to a reference dataset
   * in the case when we have some FieldsGroupedByDataset
   */
  public populateFieldsets(
    $fieldsets: Readonly<QueryList<FieldsetComponent>>,
    data: readonly RawDataPoint[],
    keepExisting: boolean,
  ): void {
    this.storeIdsFromDataset(data, keepExisting);
    $fieldsets.forEach($fieldset => $fieldset.populate(data, keepExisting));
  }

  /**
   * From provided "raw" filter state, i.e straight from filters, construct a new one, that will:
   * - Do not retain values that are referenced in a FieldsGroupedByDataset
   * - For each grouping of dataset, will compute a list of filtered IDs for this dataset
   *
   * Example: for two fields referencing "vessel.mainPurposeId" and "vessel.typeId" for their values, we might have:
   * {'vessel.mainPurposeId': [1, 4], 'vessel.typeId': [3]}
   * From this we will call the FilterHelper.filter function with all the vesselId available, and finally return:
   * {'vesselIds': [<retainedIds>]}
   *
   * The fields not present in any FieldsGroupedByDataset are not touched
   */
  public updatePageFilterState(filterState: FiltersState): FiltersState {
    const constructedFilters: FiltersState = { ...filterState };
    this.refDataInfos.forEach(refDataInfo => {
      const filterValuesForRefData: LayerFilter = {};
      let fieldFoundInFilter = false;
      /** Iter on all fields related to dataset to build a new filter state used to filter ref dataset values only */
      for (const field of refDataInfo.fieldDefinitions) {
        if (!(field.id in filterState)) continue;
        fieldFoundInFilter = true;
        const valueLookupKey = field.propValue ?? field.id;
        filterValuesForRefData[valueLookupKey] = new FilterApplied({
          ...field,
          values: filterState[field.id],
        });
        delete constructedFilters[field.id];
      }
      /** If none of the filters provided are in the current iterated dataset, we don't have to do anything */
      if (!fieldFoundInFilter) return;
      const idKey = RefDataProvider.getKeyForDataset(refDataInfo.datasetName);
      /** By default, use data present in fields. If they were not yet populated, use all IDs of ref dataset */
      const dataToUse = refDataInfo.dataIds.size
        ? Array.from(refDataInfo.dataIds)
        : Object.keys(RefDataProvider.refData[refDataInfo.datasetName]).map(id => {
          const idAsInt = parseInt(id);
          // Some IDs aren't integers, such as country filters which have a two char string as ID (ex: fr, uk, us, etc.)
          return isNaN(idAsInt) ? id : idAsInt;
        });
      const filtered = dataToUse.map(v => ({ [idKey]: v })).filter(d => FilterHelper.filter(filterValuesForRefData, d))
        .map(d => d[idKey]);
      /** No match, should display no data. For the filter not to be ignored, add an impossible id in the list. */
      if (!filtered.length) filtered.push(Number.MAX_SAFE_INTEGER);
      constructedFilters[refDataInfo.fieldId] = filtered;
    });
    this.addFilterState(constructedFilters);
    return constructedFilters;
  }
}
