import { Injectable, computed, inject, signal } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

type DisplayMode = 'large' | 'medium' | 'small' | 'xSmall';

import { Config } from '../../config/config';

@Injectable({
  providedIn: 'root',
})
export class UIService {
  private breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  private _displayMode = signal<DisplayMode>('large');

  public customLogo = signal<string | undefined>(undefined);
  public appLogo = computed(() => {
    if (!this.isXSmallDisplay() && this.customLogo()) {
      return this.customLogo();
    }
    return Config.SPIN_LOGO_PATH;
  });

  public isXSmallDisplay = computed(() => this._displayMode() === 'xSmall');
  public isSmallDisplay = computed(() => this._displayMode() === 'small');
  public isMediumDisplay = computed(() => this._displayMode() === 'medium');
  public isLargeDisplay = computed(() => this._displayMode() === 'large');

  private static readonly ORDERED_BREAKPOINTS: DisplayMode[] = ['xSmall', 'small', 'medium', 'large'];

  public isDisplayBetween(minBreakpoint: DisplayMode, maxBreakpoint: DisplayMode): boolean {
    return this.isDisplaySmaller(maxBreakpoint) && this.isDisplayWider(minBreakpoint);
  }

  public isDisplaySmaller(maxBreakpoint: DisplayMode): boolean {
    const displayModeIndex = UIService.ORDERED_BREAKPOINTS.indexOf(this._displayMode());
    const maxBreakpointIndex = UIService.ORDERED_BREAKPOINTS.indexOf(maxBreakpoint);
    return displayModeIndex <= maxBreakpointIndex;
  }

  public isDisplayWider(minBreakpoint: DisplayMode): boolean {
    const displayModeIndex = UIService.ORDERED_BREAKPOINTS.indexOf(this._displayMode());
    const minBreakpointIndex = UIService.ORDERED_BREAKPOINTS.indexOf(minBreakpoint);
    return displayModeIndex >= minBreakpointIndex;
  }

  constructor() {
    this.breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium])
      .subscribe(result => {
        if (result.breakpoints[Breakpoints.Medium]) {
          this._displayMode.set('medium');
        } else if (result.breakpoints[Breakpoints.Small]) {
          this._displayMode.set('small');
        } else if (result.breakpoints[Breakpoints.XSmall]) {
          this._displayMode.set('xSmall');
        } else {
          this._displayMode.set('large');
        }
        this.adaptDocumentBodyCssClassToDisplayMode();
      });
  }

  public adaptDocumentBodyCssClassToDisplayMode(): void {
    const body = document.getElementsByTagName('body')[0];
    body.classList.toggle('xsmall-display', this.isXSmallDisplay());
    body.classList.toggle('small-display', this.isSmallDisplay());
    body.classList.toggle('medium-display', this.isMediumDisplay());
  }
}
