import { Injector } from '@angular/core';

import { Subject } from 'rxjs';

import { DeepReadonly, FilterApplied, LayerFilter, LayerId, NumOrString } from '../../helpers/types';
import { RawIdentityPoint } from '../../graph/chart-types';
import { LayerSettings, MapLayerSettings } from '../../helpers/config-types';
import { LayerLegendState } from '../../helpers/legend-types';
import { LegendHelper } from '../../shared/legend-helper';
import { SidebarFieldsetsGroup } from '../../filters/sidebar-fieldsets-group';
import { MapLibreHistoricalLayer } from '../../map/maplibre-historical-layer';
import { MapLibreLayer } from '../../map/maplibre-layer';
import { FilterHelper } from '../../filters/filter-helper';

export interface LayersDictionary<T extends StandardLayerState<RawIdentityPoint>> {
  [layerId: string]: T;
}

// Properties used both for StandardLayerState as well as Map-specific state
export class StandardLayerState<T extends RawIdentityPoint = RawIdentityPoint> {
  readonly settings: Readonly<LayerSettings>;
  protected _data: DeepReadonly<T[]>;
  protected _shown: DeepReadonly<T[]>;
  protected _shapeHidden?: boolean;
  protected idsPassingFilter: Set<NumOrString> = new Set();

  filters: LayerFilter;
  count: number;
  // Filters currently applied on the layer
  currentFilter: FilterApplied = {};
  /** If false, the layer is not displayed on the map. Bound to the sidebar layer toggle */
  visible: boolean;
  /** If true, layer is hidden in sidebar. If true, visible should be false */
  hideInSidebar: boolean;
  total?: number;
  loaded: boolean;
  forcedTotal?: number;
  tabData?: T[];

  public legendState: LayerLegendState;
  /** This is used to notify listeners (legend component) when there is an update on the legend */
  public _legendStateUpdate$ = new Subject<string>();
  readonly id: LayerId;
  readonly fieldsetsGroup: SidebarFieldsetsGroup;

  constructor(injector: Injector, layerSettings: LayerSettings, layerVisible: boolean) {
    this.settings = layerSettings;
    this.id = layerSettings.id;
    this.loaded = false;
    this._data = [];
    this._shown = [];
    this.filters = {};
    this.count = 0;
    this.visible = layerVisible;
    this.hideInSidebar = layerSettings.hideSidebarLayer || false;
    this.fieldsetsGroup = new SidebarFieldsetsGroup(
      injector,
      layerSettings.fieldsets,
      [layerSettings.selector],
    );
    this.legendState = LegendHelper.constructLegendState(this);
    if (this.legendState) this.legendState.visible = this.visible;
  }

  public toggle(visible: boolean): void {
    this.visible = visible;
    if (this.legendState) this.legendState.visible = visible;
    this.updateLegend();
  }

  public setData(d: DeepReadonly<T[]>, setTotal: boolean = true): void {
    this._data = d;
    if (setTotal) this.total = d.length;
    /** Changing data means filtered data must change as well */
    this.idsPassingFilter = this.idsToSet(null);
    this._shown = d;
    if (setTotal) this.count = d.length;
    this.updateLegend();
  }

  public get data(): DeepReadonly<T[]> {
    return this._data;
  }

  /** Will update this.legendState inplace */
  public updateLegend(fromMapMove: boolean = false): boolean {
    if (!this.visible) return false;
    const shouldUpdateAfterMapMove = this.legendState?.currentColorBy?.autoUpdate === true;
    if (fromMapMove && !shouldUpdateAfterMapMove) return false;
    LegendHelper.updateLayerLegend(this, this.getDataForLegend(shouldUpdateAfterMapMove));
    this._legendStateUpdate$.next(this.id);
    return true;
  }

  public getDataForLegend(forCurrentViewPort: boolean): DeepReadonly<T[]> {
    return this._data;
  }

  /**
   * Filter data, retaining only provided ids. The filtered data is stored in the "shown" property
   * If null is passed, it means no filtering will occur (whole dataset)
   * Passing null will display all elements
   */
  public filterDataIds(ids: NumOrString[] | Set<NumOrString> | null, setCount: boolean = true): void {
    const idSet = this.idsToSet(ids);
    this.idsPassingFilter = idSet;
    this._shown = this._data.filter(d => this.idsPassingFilter.has(d.id));
    if (setCount) this.count = this._shown.length;
    this.updateLegend();
  }

  /**
   * Will transform provided ids to a set if it's an array. If it's null, the set will contain all ids of the unfiltered
   * data.
   */
  protected idsToSet(ids: NumOrString[] | Set<NumOrString> | null): Set<NumOrString> {
    if (ids === null) {
      ids = new Set(this._data.map(d => d.id));
    }
    return Array.isArray(ids) ? new Set(ids) : ids;
  }

  public filterDataFromFilters(filters: LayerFilter, setCount: boolean = true): void {
    const ids = FilterHelper.filterDataset(this._data, filters).map(d => d.id as number);
    this.filterDataIds(ids, setCount);
  }

  public get shown(): DeepReadonly<T[]> {
    return this._shown;
  }

  public set shapeHidden(shapeHidden: boolean) {
    this._shapeHidden = shapeHidden;
  }

  public get shapeHidden(): boolean {
    return this._shapeHidden;
  }
}

export function isVesselLayer(layerSettings: MapLayerSettings): boolean {
  return layerSettings.layerType === 'historical' || layerSettings.layerType === 'latest';
}

export function getLatestPositionLayer(layers: LayersDictionary<MapLibreLayer>, layerGroup: string): MapLibreLayer {
  return Object.values(layers).find(layer =>
    layer.settings.layerType === 'latest' && layer.settings.layerGroup === layerGroup
  );
}

export function getHistoricalPositionLayer(
  layers: LayersDictionary<MapLibreLayer>,
  layerGroup: string,
): MapLibreHistoricalLayer {
  return Object.values(layers).find(layer =>
    layer.settings.layerType === 'historical' && layer.settings.layerGroup === layerGroup
  ) as MapLibreHistoricalLayer;
}
