import { computed, ContentChild, Directive, ElementRef, HostBinding, HostListener, input, output, signal,
  ViewChild } from '@angular/core';
import { FloatLabelType, MatFormField, MatFormFieldControl } from '@angular/material/form-field';

import { PearlIcon, PearlIconSize } from '../icons/icons';

@Directive({
  standalone: true,
  host: {
    '[class.highlighted]': 'highlighted()',
    '[class.small]': 'small()',
    '[class.editable]': 'editable()',
    '[class.hint-error]': 'hintError()',
    'class': 'pearl-form-field',
  },
})
export abstract class FormField {
  @HostBinding('class.readonly')
  get isReadonly(): boolean {
    return this.readonly();
  }

  @HostBinding('class.label-floating')
  get isLabelFloating(): boolean {
    return this.floatLabel() === 'always';
  }

  @HostListener('mouseenter')
  public onMouseEnter(): void {
    if (!this.editable()) return;
    this.displayEditSuffix.set(this.readonly() || this.ngContentControl.readonly);
  }

  @HostListener('mouseleave')
  public onMouseLeave(): void {
    if (!this.editable()) return;
    this.displayEditSuffix.set(false);
  }

  @ViewChild(MatFormField, { static: true })
  protected readonly matFormField!: MatFormField;

  @ContentChild(MatFormFieldControl, { static: true })
  public readonly ngContentControl!: MatFormFieldControl<unknown> & {
    readonly?: boolean;
    _isInFormField?: boolean;
    _parentFormField?: MatFormField;
    _elementRef?: ElementRef<HTMLInputElement>;
  };

  public readonly edit = output<void>();

  public readonly small = input<boolean>(false);
  public readonly highlighted = input<boolean>(false);
  public readonly readonly = input<boolean>(false);
  public readonly editable = input<boolean>(false);
  /** mat-label must be present on component init. search bar set search label after component has been initialized */
  public readonly hasLabel = input<boolean>(true);
  public readonly label = input<string | null>(null);
  public readonly floatLabel = input<FloatLabelType>('auto');
  public readonly iconPrefix = input<PearlIcon | null>(null);
  public readonly iconSuffix = input<PearlIcon | null>(null);
  public readonly hintStart = input<string | null>(null);
  public readonly hintEnd = input<string | null>(null);

  /**
   * In most cases, we bypass the FormsModule implementation and use our own validators, for instance data entry
   * coherency checks, to determine if an input is in an error state. In these cases, mat-error cannot be triggered,
   * so we display errors using `mat-hint`.
   */
  public readonly hintError = input<boolean>(false);
  /**
   * If we have proper FormsModule implementation using validator we can rely on `mat-error`
   */
  public readonly showError = input<boolean>(false);
  /**
   * The error message that should be displayed is the same however
   */
  public readonly errorMessage = input<string | null>(null);

  public readonly displayEditSuffix = signal<boolean>(false);
  public readonly iconSize = computed<PearlIconSize>(() => (this.small() ? 20 : 24));
}
