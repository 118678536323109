import { DATETIME_ELEMENTS, DatetimeElement, DatetimePrecision, isDatePrecision } from './datepicker-types';

// Datepicker header button labels
export const BUTTON_LABEL_FORMATS = {
  year: 'YYYY',
  date: 'MMM D',
  time: 'HH:mm',
  timezone: '[UTC]Z',
  // Placeholders for the formats above
  placeholder: {
    year: '----',
    date: '--- --',
    time: '--:--',
    timezone: 'UTC+xx',
  },
  // Hints are formats to display the "active" date before any selection has been made
  hint: {
    year: 'YYYY',
    date: 'MMM [--]',
    time: '[--:--]',
    timezone: '[UTC]Z',
  },
};

const DISPLAY_FORMATS: Record<DatetimePrecision, string> = {
  // Date only formats
  year: 'YYYY',
  month: 'YYYY-MM',
  day: 'YYYY-MM-DD',
  // Time only formats, datetime formats are created by getDatetimeDisplayFormat from date and time concatenation
  minute: 'HH:mm',
  second: 'HH:mm:ss',
};
const TIMEZONE_DISPLAY_FORMAT = '([UTC]Z)';

/*
 * Month formats include year formats, day include months and year etc.
 * We cannot use one array for each precision and concatenate them because the resulting order of formats would produce
 * some unexpected results.
 *
 * For example, YYYY must be before YY-M for "2012" to be parsed as 2012/01/01 and not 2020/02/01
 *
 * Be careful when adding a format or changing the order.
 *
 * Available formats: https://day.js.org/docs/en/parse/string-format
 */
const PARSE_FORMATS: Record<DatetimePrecision, string[]> = {
  // Date only formats
  year: ['YYYY', 'YY'],
  month: ['YYYY-MM', 'YYYY-M', 'MMM YYYY', 'MMMM YYYY', 'YYYY', 'YY-M', 'YY'],
  day: [
    'YYYY-MM-DD',
    'YYYYMMDD',
    'YYYY-M-D',
    'L',
    'YY-MM-DD',
    'LL',
    'YYYY-MM',
    'YYYY-M',
    'MMM YYYY',
    'MMMM YYYY',
    'YYYY',
    'YY-M-D',
    'YY-M',
    'YY',
  ],
  // Time only formats, datetime formats are created by getDatetimeParseFormats from date and time concatenation
  minute: ['HHmm', 'HH:mm', 'HH'],
  second: ['HH:mm:ss', 'HHmm', 'HH:mm', 'HH'],
};
const TIMEZONE_PARSE_FORMATS = ['Z', ''];
// Date formats for datetime parsing
const FULL_DATE_PARSE_FORMATS = ['YYYY-MM-DD', 'YYYYMMDD', 'YYYY-M-D', 'L', 'YY-MM-DD', 'LL'];

export function getDatetimeDisplayFormat(
  precision: DatetimePrecision,
  timeOnly: boolean,
  withTimezone: boolean,
): string {
  let format = DISPLAY_FORMATS[precision];

  if (isDatePrecision(precision)) {
    return format;
  }

  if (!timeOnly) {
    format = `${DISPLAY_FORMATS.day} ${format}`;
  }
  if (withTimezone) {
    format = `${format} ${TIMEZONE_DISPLAY_FORMAT}`;
  }

  return format;
}

export function getDatetimeParseFormats(
  precision: DatetimePrecision,
  timeOnly: boolean,
  withTimezone: boolean,
): string[] {
  let formats = PARSE_FORMATS[precision];

  if (isDatePrecision(precision)) {
    return formats;
  }

  // Create all combinations of date and time formats if time with date
  if (!timeOnly) {
    formats = formats
      .flatMap(timeFormat => FULL_DATE_PARSE_FORMATS.map(dayFormat => `${dayFormat} ${timeFormat}`))
      .concat(PARSE_FORMATS.day) // Date only, time is set to 00:00
      .concat(formats); // Time only, date is set to today
  }
  // Create all combination of (date)time and timezone formats
  if (withTimezone) {
    formats = formats.flatMap(baseFormat => TIMEZONE_PARSE_FORMATS.map(tzFormat => `${baseFormat} ${tzFormat}`));
  }

  return formats;
}

/**
 * Get the ordered list of datetime elements for a given precision.
 *
 * @example
 * ```
 * getAvailableDatetimeElements('minute', false); // -> ['year', 'month', 'day', 'hour', 'minute']
 * getAvailableDatetimeElements('month', false); // -> ['year', 'month']
 * getAvailableDatetimeElements('second', true); // -> ['hour', 'minute', 'second']
 * ```
 */
export function getAvailableDatetimeElements(precision: DatetimePrecision, timeOnly: boolean): DatetimeElement[] {
  const startIdx = timeOnly ? DATETIME_ELEMENTS.indexOf('hour') : 0;
  const endIdx = DATETIME_ELEMENTS.indexOf(precision);

  return DATETIME_ELEMENTS.slice(startIdx, endIdx + 1);
}

const DATETIME_ELEMENT_FORMAT_TOKEN: Record<DatetimeElement, string> = {
  year: 'Y',
  month: 'M',
  day: 'D',
  hour: 'H',
  minute: 'm',
  second: 's',
};
/**
 * Get the position of the given {@link DatetimeElement} in the given format.
 * @example
 * ```
 * getDatetimeElementRangeInFormat('YYYY-MM-DD', 'month') // -> [5, 7]
 * ```
 */
export function getDatetimeElementRangeInFormat(
  format: string,
  element: DatetimeElement,
): [startPos: number, endPos: number] {
  const token = DATETIME_ELEMENT_FORMAT_TOKEN[element];
  const startPos = format.indexOf(token);
  const endPos = format.lastIndexOf(token) + 1;

  return [startPos, endPos];
}

// datetimeElementFormatToken with values as keys and keys as values: {'Y': 'year', ...}
const DATETIME_FORMAT_TOKEN_ELEMENT = Object.fromEntries(
  Object.entries(DATETIME_ELEMENT_FORMAT_TOKEN).map(([a, b]) => [b, a]),
) as Record<string, DatetimeElement>;
/**
 * Get the the {@link DatetimeElement} corresponding to the given position in the given format.
 * @example
 * ```
 * getDatetimeElementFromPositionInFormat('YYYY-MM-DD', 6) // -> 'month'
 * ```
 */
export function getDatetimeElementFromPositionInFormat(format: string, position: number): DatetimeElement | null {
  // Try the character at the given position first. If it is a separator character, it will not match any element.
  if (position < format.length) {
    const nextChar = format[position];
    if (DATETIME_FORMAT_TOKEN_ELEMENT[nextChar]) {
      return DATETIME_FORMAT_TOKEN_ELEMENT[nextChar];
    }
  }
  // Then try the previous character, ie. the character before the cursor.
  if (position > 0) {
    const previousChar = format[position - 1];
    if (DATETIME_FORMAT_TOKEN_ELEMENT[previousChar]) {
      return DATETIME_FORMAT_TOKEN_ELEMENT[previousChar];
    }
  }

  return null;
}
