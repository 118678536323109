<div data-testid="create-fleet-dialog">
  <ng-container *ngIf="!data.created">
    <h1 mat-dialog-title>Name and create your fleet</h1>
    <div mat-dialog-content class="vessel-fleet-form-field">
      <pearl-form-field label="Vessel fleet name">
        <input matInput [(ngModel)]="data.title" autocomplete="off" />
      </pearl-form-field>
      <mat-checkbox *ngIf="data.canShareFleet" matTooltipPosition="after" [color]="'primary'" [(ngModel)]="data.shared">
        Share your fleet within {{ data.client }}
      </mat-checkbox>
    </div>
    <div mat-dialog-actions class="fleet-dialog-buttons">
      <button mat-button (click)="onNoClick()">Cancel</button>
      <div matTooltip="You must enter a title in order to create this vessel fleet" [matTooltipDisabled]="data.title">
        <button
          mat-button
          class="confirm-button"
          [mat-dialog-close]="getResultData()"
          cdkFocusInitial
          [analyticsAction]="{
            title: 'customFleetCreated',
            trackedValues: { title: data.title, sharedFleet: data.shared },
          }"
          [disabled]="!data.title">
          Create
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="data.created">
    <h1 mat-dialog-title>The vessel fleet has been created</h1>
    <div mat-dialog-content class="apply-dialog">Do you want to select this vessel fleet now?</div>
    <div mat-dialog-actions class="fleet-dialog-buttons">
      <button mat-button (click)="onNoClick()">Later</button>
      <button mat-button class="confirm-button" [mat-dialog-close]="data.title" cdkFocusInitial>Select now</button>
    </div>
  </ng-container>
</div>
