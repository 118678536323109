import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';

export interface JsonDialogConfig {
  title: string;
  jsonObject: object;
  onConfirm: (newConfig: object) => void;
}

@Component({
  selector: 'spin-json-dialog',
  template: `
    <h1 mat-dialog-title>{{config.title}}</h1>
      <textarea [(ngModel)]="jsonStr"> </textarea>

    <div mat-dialog-actions>
      <button mat-button [mat-dialog-close]="false">Cancel</button>
      <button mat-button class="confirm-button" [mat-dialog-close]="true" (click)="onclick($event)">
        Apply config
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styles: [
    'textarea {width: 75vw; height: 60vh; border-radius: 5px;}',
  ],
  imports: [
    MatDialogModule,
    MatButtonModule,
    FormsModule,
  ],
})
export class EditableJsonComponent {
  protected jsonStr: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public config: JsonDialogConfig,
    public dialogRef: MatDialogRef<JsonDialogConfig>,
  ) {
    this.jsonStr = JSON.stringify(this.config.jsonObject, null, 4);
  }

  public onclick(event: MouseEvent): void {
    event.preventDefault();
    this.config.onConfirm(JSON.parse(this.jsonStr) as object);
  }
}
