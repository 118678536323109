import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, ViewEncapsulation, computed,
  effect, inject, model, signal, untracked } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { MatInput } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

import dayjs, { Dayjs } from 'dayjs';
import { clamp, isEqual } from 'lodash-es';

import { NouisliderComponent } from './nouislider-component';
import { NumberHelper } from '../helpers/number-helper';
import { DateHelper } from '../helpers/date-helper';
import { Era, FieldSettings, FilterApplied, Interval, IntervalField, IntervalFilterApplied,
  ResolvedInterval } from '../helpers/types';
import { AppInfoService } from '../app/app-info-service';
import { DoubleDateComponent } from './doubledate';
import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { ProductAnalyticsService } from '../shared/product-analytics/product-analytics.service';
import { PearlDatepickerComponent, PearlDatepickerInput, PearlDatepickerToggleComponent,
  PearlFormFieldComponent } from '../shared/pearl-components';
import { TimezoneService } from '../helpers/timezone.service';

const RIGHT_INITIAL_VALUE = Number.MAX_SAFE_INTEGER;
const LEFT_INITIAL_VALUE = 0;

@Component({
  selector: 'spin-filters-interval',
  templateUrl: 'interval.html',
  styleUrls: ['interval.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatCheckboxModule,
    FormsModule,
    NgClass,
    NouisliderComponent,
    DoubleDateComponent,
    SpinTooltipDirective,
    PearlDatepickerComponent,
    PearlDatepickerToggleComponent,
    PearlFormFieldComponent,
    PearlDatepickerInput,
    MatInput,
    MatFormFieldModule,
  ],
})
export class IntervalComponent {
  @Input()
  field: IntervalField;
  @Output()
  onchange = new EventEmitter<IntervalFilterApplied>();
  @Output()
  onreleased = new EventEmitter<IntervalFilterApplied>();

  @ViewChild('doubleDate')
  $doubleDate: DoubleDateComponent;

  private productAnalyticsService: ProductAnalyticsService = inject(ProductAnalyticsService);
  private router: Router = inject(Router);
  private appInfoService: AppInfoService = inject(AppInfoService);
  private timezoneService: TimezoneService = inject(TimezoneService);

  public enabled = model(false);

  /** States if our inputs are enabled / disabled, i.e when waiting initial bounds */
  protected isEnabled = computed(() =>
    this.enabled() && (this.min() !== LEFT_INITIAL_VALUE || this.max() !== RIGHT_INITIAL_VALUE)
  );
  public min = signal<number>(LEFT_INITIAL_VALUE);
  public minDate = computed(() => dayjs.utc(this.min()).local());
  public max = signal<number>(RIGHT_INITIAL_VALUE);
  public maxDate = computed(() => dayjs.utc(this.max()).local());
  /** Internal & Range are stored as numerical values */
  public selectedInterval = model<Interval>([LEFT_INITIAL_VALUE, RIGHT_INITIAL_VALUE]);
  public left = signal(LEFT_INITIAL_VALUE);
  public right = signal(RIGHT_INITIAL_VALUE);

  public leftDate = computed(() => {
    /** Needed for TZ change reactivity */
    this.timezoneService.currentTimeZone();
    return dayjs.utc(this.left()).local();
  });

  public rightDate = computed(() => {
    /** Needed for TZ change reactivity */
    this.timezoneService.currentTimeZone();
    return dayjs.utc(this.right()).local();
  });

  public noMinMax = computed(() => this.min() === this.max());

  public usedStep = computed(() => {
    const stepCap = this.field.step ?? 1;
    if (isNaN(this.min()) || isNaN(this.max())) return stepCap;
    // Rounding the interval to the nearest multiple of 10
    const interval = Math.round((this.max() - this.min()) / 10) * 10;
    if (interval >= 1) {
      // The real interval is closer to 10
      return Math.min(stepCap, Math.pow(10, Math.floor(Math.log10(interval)) - 1));
    } // The real interval is closer to 0
    else {
      return Math.min(stepCap, Math.pow(10, Math.round(Math.log10(this.max() - this.min())) - 1));
    }
  });

  /**
   * Interconnect selectedInterval signal and left + right signals
   * It does not result in infinite loop because setting a non-changing value will not trigger effects
   */
  constructor() {
    effect(() => {
      const range = this.selectedInterval();
      this.left.set(range[0]);
      this.right.set(range[1]);
    }, { allowSignalWrites: true });

    effect(() => {
      const left = this.left(), right = this.right();
      /** Don't do anything for initial values */
      if (left === LEFT_INITIAL_VALUE && right === RIGHT_INITIAL_VALUE) return;
      const currentInterval = untracked(() => this.selectedInterval());
      /** Check if interval actually changed */
      if (isEqual(currentInterval, [left, right])) return;
      this.selectedInterval.set([left, right]);
      this.fire(true);
    }, { allowSignalWrites: true });
  }

  /**
   * Update is called by the sidebar after the populate.
   * It takes the value from field.interval and set's it as the range
   */
  public update(): void {
    if (this.field.interval == null) {
      if (!this.field.noValue) {
        // Only trigger error if values are already loaded
        console.warn('Update on interval field without data');
      }
      return;
    }
    const range: Interval = [NumberHelper.floor(this.field.interval[0]), NumberHelper.ceil(this.field.interval[1])];
    this.setNewBounds(range);
    /** After the populate we set the selected interval to the available range but we don't enable the filter */
    this.setFilter({ extent: range }, false, false);
  }

  /**
   * Sets the new bounds (min and max) values. The slider is bound to these values
   */
  public setNewBounds(range: Interval): void {
    this.min.set(range[0]);
    this.max.set(range[1]);
  }

  public clear(fire: boolean): void {
    this.selectedInterval.set([this.min(), this.max()]);
    if (fire) this.fire(true, true);
  }

  /** Reset to default value or clear if there is no default value. */
  public reset(fire: boolean = true): void {
    if (this.field.default == null) {
      this.clear(fire);
      return;
    }

    this.selectedInterval.set(DateHelper.period({ era: this.field.default }));
    if (fire) this.fire(true, true);
  }

  /**
   * Sets the selected interval in the means of numerical values
   * the slider is bound to the interval so it will update
   */
  public setFilter(interval: ResolvedInterval, enableAutomatically = true, fire: boolean = true): void {
    if (!interval) return;
    const extent = interval.extent;
    this.selectedInterval.set([NumberHelper.floor(extent[0]), NumberHelper.ceil(extent[1])]);
    if (this.$doubleDate) {
      this.$doubleDate.update();
      this.$doubleDate.setFilter(interval, false);
    }
    this.enabled.set(enableAutomatically);

    /*
     * Currently we don't listen the model changes. So after set the numerical filter
     * we need to manually emit an event to notify the interval change
     */
    if (this.enabled() && fire) {
      this.fire(true);
    }
  }

  public ontoggle(): void {
    this.fire(true, true);
    this.appInfoService.userAction(this.field.id);
  }

  /**
   * On interval change is received when the slider changes the interval.
   */
  public onInterval(): void {
    this.fire(false);
    if (this.$doubleDate) {
      this.$doubleDate.update();
      this.$doubleDate.setFilter(this.selectedInterval(), false);
    }
  }

  private getFilterApplied(): IntervalFilterApplied {
    return {
      id: this.field.id,
      filterType: this.field.filterType,
      active: this.enabled(),
      values: [this.left(), this.right()],
      propValue: this.field.propValue,
      changeEnded: false,
    };
  }

  public onIntervalReleased(selectedEra?: Era): void {
    // when the interval is released, we send the latest interval applied
    const event = new IntervalFilterApplied({
      ...this.getFilterApplied(),
      changeEnded: true,
    });
    if (selectedEra) event.selectedEra = selectedEra;
    this.onreleased.emit(event);
  }

  private fire(changeEnded: boolean, reset: boolean = false): void {
    if (this.enabled() || reset) {
      /**
       * We ensure left/right and selectedInterval are in sync by calling setTimeout, because signal tracking callbacks
       * happen is another unit of work
       */
      setTimeout(() => {
        this.onchange.emit({
          ...this.getFilterApplied(),
          changeEnded: changeEnded,
        });
      }, 0);
    }
  }

  public onLeftDateChanged($event: Dayjs): void {
    this.appInfoService.userAction(this.field.id);
    const newValue = clamp(DateHelper.numericalValue($event), this.min(), this.right());
    this.left.set(newValue);
    this.fire(true);
    this.resetDoubleDate();
  }

  public onRightDateChanged($event: Dayjs): void {
    this.appInfoService.userAction(this.field.id);
    const newValue = clamp(DateHelper.numericalValue($event), this.left(), this.max());
    this.right.set(newValue);
    this.fire(true);
    this.resetDoubleDate();
  }

  /**
   * This event handler is called when the interval's doubledate component emits a change.
   * We need to change both left and right bounds "together".
   */
  public onDoubleDateChange($event: FilterApplied): void {
    if ($event.values?.length !== 2 || $event.values.some(v => v === null)) return;
    const interval = $event.values as Interval;
    // Update left and right bounds accordingly
    const leftValue = clamp(interval[0], this.min(), interval[1]);
    const rightValue = clamp(interval[1], interval[0], this.max());

    // If the new left value is above the current right value, update the right value first
    if (leftValue > this.right()) {
      this.right.set(rightValue);
      this.left.set(leftValue);
    } else {
      this.left.set(leftValue);
      this.right.set(rightValue);
    }

    this.productAnalyticsService.trackAction('scheduleSelectPeriodUpdated', {
      path: this.router.url,
      selectedPeriod: $event.selectedEra,
    });

    // Fire an interval released event
    this.onIntervalReleased($event.selectedEra);
  }

  private resetDoubleDate(): void {
    if (!this.$doubleDate) return;
    this.$doubleDate.reset(false);
    // Remove input focus from DoubleDate Select
    (this.$doubleDate.$preset._elementRef.nativeElement as HTMLElement).blur();
  }

  protected getSuffix(): string {
    return (this.field as FieldSettings).suffix;
  }
}
