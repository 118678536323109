<fieldset class="spin-filters-fieldset" [ngClass]="{ 'internal-use-feature': config.isInternal }">
  @if (fieldset.fields.length) {
    @if (config.isInternal) {
      <pearl-button icon="search" type="icon" size="small" (click)="openConfig(fieldset)" />
    }
    @if (fieldset.title) {
      <div (click)="legendOnclick()" [spinTooltip]="fieldset.description" class="fieldset-legend">
        <pearl-icon [icon]="this.fieldset.expanded ? 'chevron_down' : 'chevron_right'" [size]="24" />
        <span [ngClass]="getLegendClass()">{{ fieldset.title }} </span>
        @if (count > 0 && showCount) {
          <div class="spin-padded spin-rounded-div spin-bg-primary">
            {{ count }}
          </div>
        }
      </div>
    }
    <div class="fieldset-fields" [ngStyle]="getFieldsetFieldsStyle()">
      @for (field of fieldset.fields; track field) {
        @if (field.filteringEnabled != false && field.filterType && field.visible != false) {
          @if (["interval", "datetime", "date"].includes(field.filterType)) {
            <spin-filters-interval [field]="$any(field)" (onchange)="onbubblingchange($event)" />
          } @else if (field.filterType === "number") {
            <spin-filters-number [field]="$any(field)" (onchange)="onbubblingchange($event)" />
          } @else if (["doubledate", "intersection"].includes(field.filterType)) {
            <spin-filters-doubledate [field]="$any(field)" (onchange)="onbubblingchange($event)" />
          } @else if (["checkbox-exclude", "checkbox"].includes(field.filterType)) {
            <spin-filters-checkbox [field]="$any(field)" (checkboxFilterChange)="onbubblingchange($event)" />
          } @else if (["multi", "excludemulti", "single"].includes(field.filterType)) {
            <autocomplete-filter [field]="field" (onchange)="onbubblingchange($event)" />
          }
        }
      }
    </div>
  }
</fieldset>
