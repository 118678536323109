import { Injectable, Injector, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatDialog } from '@angular/material/dialog';

import { Observable, takeWhile } from 'rxjs';

import { ActionEvent, SnackbarStatus } from '../helpers/types';
import { SelectorComponent } from '../selector/selector.component';
import { SelectorFullConfig } from '../selector/selector.types';
import { DialogComponent, DialogConfig } from '../shared/dialog';
import { Config } from '../config/config';
import { LoadingComponent } from '../shared';
import { EditableJsonComponent, JsonDialogConfig } from './simple-json-dialog';

const DEFAULT_MESSAGE_DISPLAY_DURATION = 4_000;
const MESSAGE_TOO_LONG_THRESHOLD = 300;

type ShowMessageOptions = {
  duration?: number;
  removeDefaultMessagePrefix?: boolean;
};

@Injectable()
export class DialogManager {
  private snackBar: MatSnackBar;
  private readonly dialog: MatDialog;
  private ngZone: NgZone;

  private openTimeoutId: number;

  constructor(injector: Injector) {
    this.dialog = injector.get(MatDialog);
    this.snackBar = injector.get(MatSnackBar);
    this.ngZone = injector.get(NgZone);
  }

  /**
   * Function opening a wheel in a dialog at panel loading
   */
  public panelLoading(taskId: string, msg: string = '', disabledNavBar: boolean = false): void {
    LoadingComponent.push(this.dialog, taskId, msg, disabledNavBar);
  }

  /**
   * When panel content has been draawait wn (and generally has finished loading)
   */
  public panelLoaded(taskId: string): void {
    LoadingComponent.pull(taskId);
  }

  public showMessage(
    message: string,
    status: SnackbarStatus,
    { duration, removeDefaultMessagePrefix }: ShowMessageOptions = {},
  ): void {
    /*
     * If the message is too long, never automatically close the dialog to give enough time to the user to read the
     * message and take relevant actions.
     * We always use the supplied duration if it is provided.
     */
    const isMessageTooLong = message && message.length > MESSAGE_TOO_LONG_THRESHOLD;
    const displayDuration = duration ?? (isMessageTooLong ? undefined : DEFAULT_MESSAGE_DISPLAY_DURATION);

    const messageToDisplay = removeDefaultMessagePrefix
      ? message.replace('Error while loading endpoint:', '').trim()
      : message;

    this.ngZone.run(() => this.open(messageToDisplay, status, displayDuration));
  }

  private open(message: string, status: SnackbarStatus, duration?: number): void {
    const panelClass = `${status}-snackbar`;
    this.snackBar.open(message, 'X', {
      duration: duration,
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass,
    });
  }

  /**
   * Will display the provided message after an initial delay, if provided.
   * The snackbar does not have a display duration, but rather will be removed when the provided observable emits
   * a "true" value.
   * To prevent flickering, the snackbar will be displayed at least 3 seconds, even if the Observable emits before.
   * @param message The message to display
   * @param status Status to display: will change the snackbar theme
   * @param until An observable, that will eventually emit a "true" value. Then, the snackbar will disappear
   * @param delayMs An initial delay before the snackbar appears
   */
  public showMessageUntil(
    message: string,
    status: SnackbarStatus,
    until: Observable<boolean>,
    delayMs?: number,
  ): void {
    const nbMsOpenedAtMinimum = 3000;
    let openTimestamp: number;
    if (delayMs) {
      clearTimeout(this.openTimeoutId);
      this.openTimeoutId = setTimeout(() => {
        this.open(message, status);
        openTimestamp = performance.now();
      }, delayMs);
    } else this.open(message, status);

    until.pipe(takeWhile(x => !x, true)).subscribe(completed => {
      if (!completed) return;
      /** In case the modal was not opened yet */
      clearTimeout(this.openTimeoutId);
      setTimeout(
        () => this.snackBar.dismiss(),
        openTimestamp ? nbMsOpenedAtMinimum - (performance.now() - openTimestamp) : 0,
      );
    });
  }

  public openSelectorDialog(selectorState: SelectorFullConfig): void {
    const dialogRef = this.dialog.open(SelectorComponent, {
      disableClose: true,
      width: '95vw',
      maxWidth: '95vw',
    });

    dialogRef.componentInstance.initialize(selectorState);
    dialogRef.afterOpened().subscribe(() => {
      // by default as soon as opened, populate the first tab
      dialogRef.componentInstance.populateAndSet();

      // if any tab should be openned, switch to it, once the first tab is populated
      if (selectorState.tab) {
        dialogRef.componentInstance.openTab(selectorState.tab);
      }
    });

    dialogRef.afterClosed().subscribe(action => {
      if (action != null) {
        selectorState.afterFilterAction(action);
      }
    });
  }

  public editJsonDialog(title: string, json: object, onConfirm: (newConfig: object) => void): void {
    this.ngZone.run(() => {
      this.dialog.open(EditableJsonComponent, {
        data: { title, jsonObject: json, onConfirm } as JsonDialogConfig,
        panelClass: 'spin-dialog-box',
      });
    });
  }

  /**
   * @param title The title of the opened modal
   * @param text  The text to display inside the modal body
   * @param json  If provided, the modal body will contain JSON
   */
  public simpleDialog(title: string, text: string, json?: object): void {
    this.ngZone.run(() => {
      this.dialog.open(DialogComponent, {
        data: { title: title, text: text, buttons: [], datum: {}, json } as DialogConfig,
        panelClass: 'spin-dialog-box',
      });
    });
  }

  public noLatestPositionWarning(
    data: object,
    onaction: (actionEvent: ActionEvent) => void,
    dialogConfig: DialogConfig,
  ) {
    dialogConfig.datum = data;
    dialogConfig.text = Config.parse(dialogConfig.text, dialogConfig.datum);
    dialogConfig.onAction = onaction;

    this.ngZone.run(() => {
      this.dialog.open(DialogComponent, {
        data: dialogConfig,
        panelClass: 'spin-dialog-box',
      });
    });
  }
}
