export type PearlSelectOptionValueType = string | number | boolean;

export class PearlSelectOption<T extends PearlSelectOptionValueType = PearlSelectOptionValueType> {
  public selected = false;
  public value: T;
  public id: T;
  public title: string;
  public groupName?: string;
  public optionDescription?: string;
  public icon?: string;
  public order?: string;

  constructor(option: Partial<PearlSelectOption<T>>) {
    Object.assign(this, option);
  }
}

export type PearlOptionComparator = (a: PearlSelectOption, b: PearlSelectOption) => number;
