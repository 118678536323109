export const ICON_SUFFIX_WIDTH = 48;

export class AutocompleteFilterHelper {
  public static SELECT_ALL_VALUE = -1;
  public static BLANK_OPTION_VALUE = '__blank';

  /**
   * @description - return whether the search string is included in the option
   * When passed from url option value can either be string or number depending
   * on the way it was passed
   *
   * @param searchIn - array of options
   * @param searchFor - search string or number
   */
  public static includesStringOrNumber(searchIn: unknown[], searchFor: string | number): boolean {
    if (Array.isArray(searchIn)) {
      return searchIn?.includes(searchFor) || searchIn?.includes(searchFor.toString());
    }

    return false;
  }

  public static includesSelectAll(searchIn: unknown[]): boolean {
    return AutocompleteFilterHelper.includesStringOrNumber(searchIn, AutocompleteFilterHelper.SELECT_ALL_VALUE);
  }

  public static includesBlank(searchIn: unknown[]): boolean {
    return AutocompleteFilterHelper.includesStringOrNumber(searchIn, AutocompleteFilterHelper.BLANK_OPTION_VALUE);
  }
}
