<ng-template #tooltipContent>
  <ng-container [ngComponentOutlet]="HeaderComponent" [ngComponentOutletInputs]="innerComponentDefs().header" />

  <div class="pearl-tooltip--spacing"></div>

  @if (!innerComponentDefs().contents.length) {
    <!-- no content -->
  } @else if (innerComponentDefs().contents.length <= 1) {
    <ng-container [ngComponentOutlet]="ContentComponent" [ngComponentOutletInputs]="innerComponentDefs().contents[0]" />
  } @else {
    <mat-tab-group
      #tabGroup
      disableRipple="true"
      dynamicHeight
      mat-stretch-tabs
      (wheel)="scrollTabs($event)"
      [selectedIndex]="selectedTab()"
      (selectedIndexChange)="onSelectedTabChange($event)">
      @for (content of innerComponentDefs().contents; track content.renderId) {
        <mat-tab [label]="content.title">
          <ng-container [ngComponentOutlet]="ContentComponent" [ngComponentOutletInputs]="content" />
        </mat-tab>
      }
    </mat-tab-group>
  }

  @if (innerComponentDefs().actions?.items?.length) {
    <div class="pearl-tooltip--sticky-actions">
      <div class="pearl-tooltip--divider"></div>
      <ng-container [ngComponentOutlet]="ActionsComponent" [ngComponentOutletInputs]="innerComponentDefs().actions" />
    </div>
  }
</ng-template>

@if (useDrawer()) {
  <div>
    <!-- TODO: Implement drawer mode -->
    <ng-container *ngTemplateOutlet="tooltipContent" />
  </div>
} @else {
  <div>
    <ng-container *ngTemplateOutlet="tooltipContent" />
  </div>
}
