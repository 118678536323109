import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation, inject,
  input } from '@angular/core';
import { MatSelect as MatSelect, MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatOptionModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';

import dayjs, { Dayjs } from 'dayjs';
import { isEqual } from 'lodash-es';

import { AppInfoService } from '../app/app-info-service';
import { PageStateService } from '../shared/services/page-state.service';
import { DateHelper } from '../helpers/date-helper';
import { COMPLETE_ERA, Era, FilterApplied, FilterType, Interval, IntervalField, IntervalOrNull,
  ResolvedInterval } from '../helpers/types';
import { isValidRecentInterval } from '../helpers/data-helpers';
import { PearlButtonComponent, PearlDatepickerComponent, PearlDatepickerInput, PearlDatepickerToggleComponent,
  PearlFormFieldComponent, PearlIconComponent } from '../shared/pearl-components';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { ScrollIntoViewDirective } from '../shared/directives/scroll-into-view.directive';
import { DatetimePrecision } from '../shared/pearl-components/components/datepicker/datepicker-types';
import { getDatetimeDisplayFormat } from '../shared/pearl-components/components/datepicker/datepicker-formats';

@Component({
  selector: 'spin-filters-doubledate',
  templateUrl: 'doubledate.html',
  styleUrls: ['doubledate.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    ScrollIntoViewDirective,
    PearlFormFieldComponent,
    MatTooltipModule,
    MatSelectModule,
    NgFor,
    MatOptionModule,
    MatIconModule,
    PearlButtonComponent,
    PearlDatepickerComponent,
    PearlDatepickerToggleComponent,
    PearlDatepickerInput,
    PearlIconComponent,
    MatInput,
  ],
})
export class DoubleDateComponent implements OnInit {
  @ViewChild(MatSelect)
  $preset: MatSelect;
  @Input()
  field: IntervalField;
  @Input()
  disabled: boolean = false;
  @Output()
  onchange = new EventEmitter<FilterApplied>();

  public readonly small = input<boolean>(true);

  public filterType: FilterType;
  public min: Dayjs | null = null;
  public max: Dayjs | null = null;
  public start: Dayjs | null = null;
  public end: Dayjs | null = null;
  /*
   *  last [start, end] sent to avoid duplicate fires
   * private lastSent: Array<Dayjs> = []
   *  custom era selector for preset mode
   */
  public presetCustom: boolean = false;
  public serializedEra: Era = { title: '', type: 'past' };
  public readonly completeEra = COMPLETE_ERA;
  // MatSelect option value to display start & end
  public selectedEra: Era = null;
  public eras: Array<Era> = [
    {
      title: 'Today',
      type: 'current',
      scale: 'day',
    },
    {
      title: 'Yesterday',
      type: 'previous',
      scale: 'day',
    },
    {
      title: 'Last 7 days',
      scale: 'day',
      extent: 7,
      type: 'past',
      relativeTo: 'end_of_today_utc',
    },
    {
      title: 'Last 30 days',
      scale: 'day',
      extent: 30,
      type: 'past',
      relativeTo: 'end_of_today_utc',
    },
    {
      title: 'Last 3 months',
      scale: 'month',
      extent: 3,
      type: 'past',
      relativeTo: 'end_of_today_utc',
    },
    {
      title: 'Last year',
      scale: 'year',
      extent: 1,
      type: 'past',
      relativeTo: 'end_of_today_utc',
    },
  ];

  private readonly appInfoService = inject(AppInfoService);
  private readonly pageStateService = inject(PageStateService);

  /**
   * Return field granularity if explicitly set, or the default granularity based on the type of field.
   */
  public get granularity(): DatetimePrecision {
    if (this.field.timeGranularity) {
      return this.field.timeGranularity;
    }
    if (this.field.type === 'date') {
      return 'day';
    }
    if (this.field.type === 'datetime') {
      return 'second';
    }

    throw new Error('Double date filter used with non-time field type');
  }

  public get asResolvedInterval(): ResolvedInterval {
    return {
      extent: this.start ? [this.start, this.end].map(t => t.valueOf()) as Interval : null,
      era: this.selectedEra,
    };
  }

  public isDisabled(): boolean {
    return this.disabled || this.eras?.length === 1;
  }

  /**
   * Manually setting the selected Era. If it is in the list it will be found
   * If not a custom era will be loaded
   */
  public selectEra(era: Era, fire = true, userAction: boolean = false): void {
    if (!era || typeof era === 'string') {
      return;
    }

    if (userAction) {
      this.appInfoService.userAction(this.field.id);
    }
    if (era.type === 'previousExtent') {
      return this.selectPreviousEra(era, fire);
    }
    if (era.isCompleteDataset) {
      this.setCompletePeriod();
      this.selectedEra = COMPLETE_ERA;
    } else {
      const period = DateHelper.period({ era });
      this.start = dayjs(period[0]);
      this.end = dayjs(period[1]);

      // find the selected era in the list of available eras
      const availableEra = this.eras.find(d => isEqual(d, era));

      this.selectedEra = availableEra ?? this.serialize();
    }
    if (fire) {
      this.fire();
    }
  }

  private selectPreviousEra(era: Era, fire = true): void {
    this.selectedEra = era;
    const refPeriod = this.pageStateService.masterPeriod;
    if (!isValidRecentInterval(refPeriod)) return;
    const previousPeriod = DateHelper.period({ era, refPeriod });
    [this.start, this.end] = previousPeriod.map(date => dayjs(date));
    if (fire) this.fire();
  }

  private setCompletePeriod(): void {
    this.start = null;
    this.end = null;
  }

  public onOpened(opened: boolean): void {
    if (!opened || !this.selectedEra) return this.close();
    const isCompleteEra = this.field.onlyConfigOptions ? false : this.selectedEra.isCompleteDataset;
    // scroll to top when we are in a undefined state (no displayed era selected)
    if (!isCompleteEra && this.eras.find(era => era.title === this.selectedEra?.title) === undefined) {
      this.$preset.panel.nativeElement.scrollTop = 0;
    }
  }

  public close(): void {
    this.presetCustom = false;
  }

  ngOnInit(): void {
    this.initEras();
    if (this.field.default) {
      this.selectEra(this.field.default, false);
    }
  }

  /**
   * Click on the custom done button
   * Close & disabled custom mode
   */
  public validateCustom(): void {
    this.appInfoService.userAction(this.field.id);
    this.presetCustom = false;

    if (!this.field.type) {
      console.error('Double date without field type');
    }

    if (this.field.type === 'datetime') {
      console.warn("DoubleDate component can't work with datetimes");
    }

    this.$preset.close();
    this.selectedEra = this.serialize();
    this.fire();
  }

  /**
   * Title display for custom mode in MatSelect instead of era title
   */
  private serialize(): Era {
    if (this.start || this.end) {
      const dateFormat = getDatetimeDisplayFormat(this.granularity, false, false);
      this.serializedEra.title = (this.start ? this.start.format(dateFormat) : '…')
        + ' − '
        + (this.end ? this.end.format(dateFormat) : '…');
    }
    return this.serializedEra;
  }

  /**
   * Filling all variables to a proper init state
   */
  public update(): void {
    this.initEras();
    this.filterType = this.field.filterType;
    if (!this.field.interval || this.field.interval.includes(NaN)) {
      return;
    }

    const range = this.field.interval.slice(0);
    this.min = dayjs(range[0]);
    this.max = dayjs(range[1]);

    /*
     * Init start and end only if we didn't have a value for start and end
     * or if these values are outside the interval bounds
     */
    if (!this.start || this.start < this.min) {
      this.start = this.min.clone();
    }
    if (!this.end || this.end > this.max) {
      this.end = this.max.clone();
    }
  }

  public clear(fire: boolean): void {
    this.selectEra(COMPLETE_ERA, fire);
  }

  /** Reset to default value or clear if there is no default value. */
  public reset(fire: boolean = true): void {
    if (this.field.default) {
      this.selectEra(this.field.default, fire);
    } else {
      this.clear(fire);
    }
  }

  public initEras(): void {
    // boolean => default eras, or custom eras array
    if (this.field && this.field.eras && this.field.eras instanceof Array) {
      this.eras = this.field.eras;
    }
  }

  // For a given doubledate component, title will always be unique
  public compareEras(era1: Era, era2: Era): boolean {
    if (!era1 || !era2) return false;
    return era1.title === era2.title;
  }

  // From fieldset
  public setFilter(interval: ResolvedInterval | IntervalOrNull, fire: boolean = true): void {
    if (interval === undefined) return;
    let resolvedInterval: ResolvedInterval;
    /** Be resilient: make the function work with IntervalOrNull as well */
    if (interval === null) {
      resolvedInterval = { era: COMPLETE_ERA, extent: null };
    } else if (Array.isArray(interval)) {
      resolvedInterval = { extent: interval };
    } else resolvedInterval = interval;

    const era = resolvedInterval.era;
    if (era?.isCompleteDataset) {
      this.setCompletePeriod();
      this.selectedEra = COMPLETE_ERA;
    } else {
      const extent = resolvedInterval.extent;
      // Init underlying dates, if provided interval has an extent (not the case with complete dataset)
      if (extent) {
        if (extent.some(v => v == null)) {
          console.error(
            new ErrorWithFingerprint(`Interval should always contain values, ${JSON.stringify(extent)} does not:`, [
              'doubledate-interval-should-contain-values',
            ]),
          );
        }
        this.start = (extent[0] || extent[0] === 0) ? dayjs(extent[0]) : null;
        this.end = (extent[1] || extent[1] === 0) ? dayjs(extent[1]) : null;
        if (!era) this.selectCorrespondingEra();
      }
      // Set selected era last because serialize uses this.start and this.end values
      if (era) {
        const matchedEra = this.field.eras.find(
          candidateEra => DateHelper.erasAreEqual(candidateEra, era),
        );
        this.selectedEra = matchedEra ?? this.serialize();
      }
    }

    if (fire) {
      this.fire();
    }
  }

  private selectCorrespondingEra(): void {
    const closestEra = DateHelper.selectCorrespondingEra(this.eras, this.start, this.end);
    this.selectedEra = closestEra ?? this.serialize();
  }

  /**
   * Fires filter change.
   *
   * @emits onchange<FilterApplied>
   */
  public fire(): void {
    const values = (this.start && this.end) ? [this.start.valueOf(), this.end.valueOf()] : null;
    // TODO: Filtering - this copies the whole field, instead of just sending what is necessary
    const filterData = new FilterApplied({
      ...this.field,
      // TODO: Filtering - we should use the same naming for timeSync
      timeSync: this.field.syncComponentIntervals,
      active: true,
      values,
    });
    if (this.selectedEra) filterData.selectedEra = this.selectedEra;
    this.onchange.emit(filterData);
  }

  public dateChange(): void {
    this.appInfoService.userAction(this.field.id);
    this.fire();
  }

  public selectStartDate(date: Dayjs | null, triggerChange = false): void {
    if (date && this.end && date.isAfter(this.end)) {
      date = this.end.startOf(this.granularity);
    }

    this.start = date;

    if (triggerChange) {
      this.dateChange();
    }
  }

  public selectEndDate(date: Dayjs | null, triggerChange = false): void {
    if (date && this.start && date.isBefore(this.start)) {
      date = this.start.endOf(this.granularity);
    }

    this.end = date;

    if (triggerChange) {
      this.dateChange();
    }
  }

  public resetFields(): void {
    this.start = null;
    this.end = null;
    this.dateChange();
  }
}
