<div class="spin-filters-interval mat-interval">
  <div class="interval-brush-and-dates">
    @if (!noMinMax()) {
      @if (!field.force) {
        <mat-checkbox
          [color]="'primary'"
          [(ngModel)]="enabled"
          [spinTooltip]="field.description"
          [disabled]="field.noValue"
          (change)="ontoggle()">
          {{ field.title }}
          @let suffix = getSuffix();
          @if (suffix) {
            <span>({{ suffix }})</span>
          }
        </mat-checkbox>
      }

      <div class="interval-slider" [ngClass]="{ 'desactivate-slider': field.noValue }">
        <nouislider
          [disabled]="!isEnabled()"
          [connect]="true"
          [min]="min()"
          [max]="max()"
          [step]="usedStep()"
          [(value)]="selectedInterval"
          (slideChange)="onInterval()"
          (slideEnd)="onIntervalReleased()" />
      </div>
    } @else if (selectedInterval().length) {
      <div>
        <span>{{ field.title }} {{ selectedInterval()[0] }}</span>
        <span></span>
      </div>
    }
    <!-- Datetime container interval -->
    @let isDateType = field.filterType === "date" || field.filterType === "datetime";
    <div class="interval-range" [ngClass]="{ 'with-datepicker': isDateType }">
      @if (isDateType) {
        <div class="left-date" data-testid="min">
          <pearl-form-field [small]="true" [hasLabel]="false">
            <input
              matInput
              [value]="leftDate()"
              [pearlDatepicker]="leftDatepicker"
              [disabled]="!isEnabled()"
              [minDate]="minDate()"
              [maxDate]="right()"
              (dateChange)="onLeftDateChanged($event.value)" />
            <ng-container pearlFormSuffix>
              <pearl-datepicker-toggle size="small" [for]="leftDatepicker"></pearl-datepicker-toggle>
              <pearl-datepicker
                #leftDatepicker
                [precision]="this.field.filterType === 'date' ? 'day' : 'second'"></pearl-datepicker>
            </ng-container>
          </pearl-form-field>
        </div>
        <div class="right-date" data-testid="max">
          <pearl-form-field [hasLabel]="false" [small]="true">
            <input
              matInput
              [value]="rightDate()"
              [pearlDatepicker]="rightDatepicker"
              [disabled]="!isEnabled()"
              [minDate]="left()"
              [maxDate]="maxDate()"
              (dateChange)="onRightDateChanged($event.value)" />
            <ng-container pearlFormSuffix>
              <pearl-datepicker-toggle [for]="rightDatepicker" size="small" />
              <pearl-datepicker #rightDatepicker [precision]="this.field.filterType === 'date' ? 'day' : 'second'" />
            </ng-container>
          </pearl-form-field>
        </div>
      } @else if (!noMinMax()) {
        <!-- standard integer interval -->
        <input
          #leftInput
          data-testid="min"
          [(ngModel)]="left"
          [min]="min()"
          [max]="right()"
          [disabled]="!isEnabled()"
          type="number"
          class="min"
          (mouseover)="leftInput.focus()"
          (mouseout)="leftInput.blur()"
          autocomplete="off" />
        <input
          #rightInput
          data-testid="max"
          [(ngModel)]="right"
          [min]="left()"
          [max]="max()"
          [disabled]="!isEnabled()"
          type="number"
          class="max"
          (mouseover)="rightInput.focus()"
          (mouseout)="rightInput.blur()"
          autocomplete="off" />
      }
    </div>
  </div>

  @if (field.selectPeriod) {
    <div class="interval-select-period">
      <spin-filters-doubledate
        #doubleDate
        [field]="field"
        (onchange)="onDoubleDateChange($event)"
        [disabled]="!isEnabled()" />
    </div>
  }
</div>
