@if (description) {
  <pearl-button
    class="description-icon-button"
    icon="information"
    size="small"
    type="icon"
    [spinTooltip]="this.content"
    [spinTooltipClassName]="'description-tooltip'"
    [spinTooltipMarkdownSupport]="!this.skipMarkdown" />
} @else {
  <span class="description-button-placeholder"></span>
}
