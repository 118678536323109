import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';

import { flatten } from 'lodash-es';

import { PearlButtonComponent, PearlFormFieldComponent } from '../shared/pearl-components';

/**
 * This interface represents a field that is ready to be shown in the FilterColumnsDialog.
 * It has a the fieldset title copied on the object
 */
export interface SelectableColumn {
  id: string;
  name: string;
  fieldsetTitle?: string;
  visible?: boolean;
  checked?: boolean;
}

interface SelectFieldset {
  fields: SelectableColumn[];
  title: string;
  checked: boolean;
}

export interface FilterColumnsData {
  allFields: SelectableColumn[];
  shownColumnsIds: string[];
  default: string[];
}

@Component({
  selector: 'filter-columns',
  templateUrl: 'filter-columns.dialog.html',
  styleUrl: 'filter-columns.dialog.scss',
  standalone: true,
  imports: [
    PearlButtonComponent,
    MatIconModule,
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    MatCheckboxModule,
    MatDividerModule,
  ],
})
export class FilterColumnsDialogComponent implements OnInit {
  public hasFieldsets: boolean;
  public fieldsets: SelectFieldset[];
  public searchText: string;

  constructor(
    public dialogRef: MatDialogRef<FilterColumnsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: FilterColumnsData,
  ) {
  }

  ngOnInit(): void {
    this.hasFieldsets = this.data.allFields.every(field => field.fieldsetTitle !== undefined);
    this.prepareFields(this.data.allFields);
    // we have to handle two types of lists: one that has only fields and one with fieldsets

    if (this.hasFieldsets) {
      this.fieldsets = [];
      this.data.allFields.forEach(field => {
        const fieldset = this.fieldsets.find(fieldset => fieldset.title === field.fieldsetTitle);
        if (fieldset !== undefined) {
          fieldset.fields.push(field);
        } else {
          this.fieldsets.push({ title: field.fieldsetTitle, fields: [field], checked: false });
        }
      });

      this.fieldsets.forEach(fieldset => fieldset.checked = fieldset.fields.every(field => field.checked));
    }
  }

  private prepareFields(fields: SelectableColumn[]): void {
    fields.forEach(field => {
      field.checked = this.data.shownColumnsIds.includes(field.id);
      field.visible = true;
    });
  }

  fieldsetSelectionChange(_, fieldset): void {
    fieldset.fields.forEach(field => field.checked = fieldset.checked);
  }

  searchField(): void {
    if (this.hasFieldsets) {
      this.fieldsets.forEach(fieldset =>
        fieldset.fields.forEach(field =>
          field.visible = field.name.toLowerCase().includes(this.searchText.toLowerCase())
        )
      );
      return;
    }

    this.data.allFields.forEach(field =>
      field.visible = field.name.toLowerCase().includes(this.searchText.toLowerCase())
    );
  }

  selectAll(select: boolean) {
    if (!this.hasFieldsets) {
      this.data.allFields.forEach(field => field.checked = select);
      return;
    }
    this.fieldsets.forEach(fieldset => {
      fieldset.checked = select;
      fieldset.fields.forEach(field => field.checked = select);
    });
  }

  selectDefault() {
    if (!this.hasFieldsets) {
      this.data.allFields.forEach(field => field.checked = this.data.default.includes(field.id));
      return;
    }
    this.fieldsets.forEach(fieldset => {
      fieldset.fields.forEach(field => field.checked = this.data.default.includes(field.id));
      fieldset.checked = fieldset.fields.every(field => field.checked);
    });
  }

  save() {
    if (!this.hasFieldsets) {
      this.dialogRef.close(this.data.allFields.filter(field => field.checked).map(field => field.id));
      return;
    }
    const selectedFieldsIds = [];
    this.fieldsets.forEach(fieldset =>
      selectedFieldsIds.push(fieldset.fields.filter(field => field.checked).map(field => field.id))
    );
    this.dialogRef.close(flatten(selectedFieldsIds));
  }

  close() {
    this.dialogRef.close();
  }
}
