<mat-form-field [floatLabel]="floatLabel()">
  @if (iconPrefix() && !readonly()) {
    <pearl-icon matIconPrefix [icon]="iconPrefix()" [size]="iconSize()" />
  }

  @if (hasLabel()) {
    <mat-label>{{ label() }}</mat-label>
  }

  <input
    matInput
    [placeholder]="label()"
    [(ngModel)]="value"
    (keypress)="isNumericCharacter($event)"
    (ngModelChange)="setNumber($event)"
    [ngModelOptions]="ngModelOptions()"
    [required]="required()"
    type="text"
    inputmode="decimal"
    autocomplete="off"
    [readonly]="readonly()"
    [disabled]="readonly() || disabled()" />

  <div class="form-suffix" matSuffix>
    @if (!readonly() && !hideButtons()) {
      <pearl-button
        (click)="onIncreaseDecreaseButtonClick($event, 'desc')"
        (touchstart)="touchStart($event, 'desc')"
        (touchend)="touchEnd()"
        [size]="buttonSize()"
        type="icon-compact"
        icon="remove"
        [disabled]="disabled()" />
      <pearl-button
        (click)="onIncreaseDecreaseButtonClick($event, 'asc')"
        (touchstart)="touchStart($event, 'asc')"
        (touchend)="touchEnd()"
        [size]="buttonSize()"
        type="icon-compact"
        icon="add"
        [disabled]="disabled()" />
    }
    <ng-content select="[pearlFormSuffix]" />
  </div>

  @if (hintStart() && !hintError()) {
    <mat-hint [innerHTML]="hintStart()" />
  }

  @if (hintError()) {
    <mat-hint [innerHTML]="errorMessage()" />
  }

  @if (hintEnd()) {
    <mat-hint align="end" [innerHTML]="hintEnd()" />
  }

  @if (showError()) {
    <mat-error align="end">{{ errorMessage() }}</mat-error>
  }

  @if (iconSuffix() && !displayEditSuffix()) {
    <pearl-icon matIconSuffix [icon]="iconSuffix()" [size]="iconSize()" />
  }

  @if (displayEditSuffix()) {
    <pearl-icon (click)="edit.emit()" matIconSuffix icon="edit" [size]="iconSize()" />
  }
</mat-form-field>
