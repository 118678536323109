<fieldset class="spin-filters-fieldset selector-fieldset">
  <div (click)="legendOnclick()" [matTooltip]="fieldset.description" matTooltipPosition="after" class="fieldset-legend">
    <pearl-icon [icon]="this.fieldset.expanded ? 'chevron_down' : 'chevron_right'" [size]="24" />

    <span [ngClass]="getLegendClass()">{{ fieldset.title }} </span>
    @if (count > 0 && showCount) {
      <div class="spin-rounded-div spin-bg-primary spin-padded">
        {{ count }}
      </div>
    }
  </div>
  <div class="fieldset-fields" [ngStyle]="getFieldsetFieldsStyle()">
    @for (field of fieldset.fields; track field) {
      <div>
        @if (field.filteringEnabled !== false && field.filterType) {
          <div class="field-box" [hidden]="isFieldHidden(field)">
            @if (field.filterType == "interval" || field.filterType == "datetime" || field.filterType == "date") {
              <spin-filters-interval [field]="$any(field)" (onchange)="onbubblingchange($event)" />
            } @else if (["doubledate", "intersection"].includes(field.filterType)) {
              <spin-filters-doubledate [field]="$any(field)" (onchange)="onbubblingchange($event)" />
            } @else if (["checkbox-exclude", "checkbox"].includes(field.filterType)) {
              <spin-filters-checkbox [field]="field" (checkboxFilterChange)="onbubblingchange($event)" />
            } @else if (["multi", "excludemulti"].includes(field.filterType)) {
              <autocomplete-filter [field]="field" (onchange)="onbubblingchange($event)" />
            } @else if (field.filterType == "distanceToEntity") {
              <spin-filters-distance-to-entity [field]="field" (filterChange)="onbubblingchange($event)" />
            }
          </div>
        }
      </div>
    }
  </div>
</fieldset>
