import { max, sum } from 'lodash-es';
import { Selection, BaseType as SelectionBaseType } from 'd3-selection';

import { AdditionalProperty, LayersColors, ScheduleLayer, SchedulePatterns,
  ScheduleShape } from '../schedule/schedule-types';
import { ChartLegendOptions, Color, ListTooltipSettings } from './types';
import { SimpleTooltipComponent } from '../shared/simple-tooltip';
import { ListTooltipComponent } from '../shared/list-tooltip';
import { PositionedTooltip } from '../shared/tooltip';
import { ColorDefinition } from './legend-types';

const CIRCLE_SIZE = 15;
/**
 * Default background color for legend pattern rectangle (gray)
 * to work both on top legend (white background) and tooltip one (black)
 */
const LEGEND_DEFAULT_BACKGROUND = '#888888';

export class LegendDrawingHelper {
  static LEGEND_BOTTOM_MARIN = 10;
  static LEGEND_LAYERS_SPACING = 15;
  static DEFAULT_FILL_PATTERN = 'dot';

  /**
   * Adds all Spinergie patterns and corresponding masks into the defs of given svg element.
   * Any element inside the svg can then reference any of these masks by url.
   */
  public static addPatternsIntoSvgDefs(defs: Selection<SVGDefsElement, unknown, SelectionBaseType, unknown>): void {
    // Dotted pattern
    defs
      .append('pattern')
      .attr('id', 'dot-pattern')
      .attr('width', 4)
      .attr('height', 4)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45)')
      .append('circle')
      .attr('r', 1.5)
      .attr('cx', 2)
      .attr('cy', 2)
      .attr('fill', 'white');
    // Checked
    const checked = defs
      .append('pattern')
      .attr('id', 'checked-pattern')
      .attr('width', 6)
      .attr('height', 6)
      .attr('patternUnits', 'userSpaceOnUse');
    checked
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 3)
      .attr('height', 3)
      .attr('fill', 'white');
    checked
      .append('rect')
      .attr('x', 3)
      .attr('y', 3)
      .attr('width', 3)
      .attr('height', 3)
      .attr('fill', 'white');
    // Striped
    defs
      .append('pattern')
      .attr('id', 'striped-pattern')
      .attr('width', 4)
      .attr('height', 4)
      .attr('patternUnits', 'userSpaceOnUse')
      .attr('patternTransform', 'rotate(45)')
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', 2)
      .attr('height', 4)
      .attr('fill', 'white');
    // Masks
    ['dot', 'checked', 'striped'].forEach((name: string) => {
      defs
        .append('mask')
        .attr('id', `${name}-mask`)
        .append('rect')
        // Start at -10, -10 to include punctual shapes
        .attr('x', -10)
        .attr('y', -10)
        // End at 2000, 200 for loooong and/or high bars
        .attr('width', 2000)
        .attr('height', 200)
        .attr('fill', `url(#${name}-pattern)`);
    });
  }

  /**
   * Adds gradients for every color in the colorBy list, that can be applied to future contracts without dateEnd
   */
  public static addGradientsIntoSvgDefs(
    defs: Selection<SVGDefsElement, unknown, SelectionBaseType, unknown>,
    layersColors: LayersColors,
  ): void {
    Object.values(layersColors).forEach(
      layerColors =>
        layerColors.forEach(
          (color: ColorDefinition) => LegendDrawingHelper.addGradientIntoSvgDefs(defs, color.fill),
        ),
    );
  }

  public static addGradientIntoSvgDefs(
    defs: Selection<SVGDefsElement, unknown, SelectionBaseType, unknown>,
    stringColor: Color,
  ): string {
    // Lets strip all non alphanumeric characters and create a unique ID out of it
    const uniqueId = 'gradient-' + LegendDrawingHelper.computeColorUniqueId(stringColor);
    const gradient = defs.append('linearGradient')
      .attr('id', uniqueId)
      .attr('x1', '0%')
      .attr('x2', '100%');
    gradient.append('stop')
      .attr('class', 'start')
      .attr('offset', '90%')
      .attr('stop-color', stringColor)
      .attr('stop-opacity', 1);
    gradient.append('stop')
      .attr('class', 'end')
      .attr('offset', '100%')
      .attr('stop-color', stringColor)
      .attr('stop-opacity', 0);

    return uniqueId;
  }

  /**
   * Add inset shadows for highlighting rules (additional properties)
   * (inspired by https://jsfiddle.net/t7p9jb3d/38/)
   *
   * @param  {Selection} defs    SVG definitions
   * @param  {object}    layers  Schedule layers
   * @return {void}
   */
  public static addShadowsIntoSvgDefs(
    defs: Selection<SVGDefsElement, unknown, SelectionBaseType, unknown>,
    layers: { [layerId: string]: ScheduleLayer },
  ): void {
    const colors = [];
    Object.keys(layers).forEach((layerId: string) => {
      const layerOptions = layers[layerId].options;
      if (!layerOptions.additionalProperties) return;
      layerOptions.additionalProperties.forEach((prop: AdditionalProperty) => {
        const shadow = prop.highlight.shadow;
        if (!shadow || !shadow.inset || colors.includes(shadow.color)) return;
        const stringColor = shadow.color;
        const uniqueId = 'shadow-' + LegendDrawingHelper.computeColorUniqueId(stringColor);
        const filter = defs.append('filter')
          .attr('id', uniqueId)
          .attr('x0', '-50%')
          .attr('y0', '-50%')
          .attr('width', '200%')
          .attr('height', '200%');
        filter.append('feGaussianBlur')
          .attr('in', 'SourceAlpha')
          .attr('stdDeviation', 4)
          .attr('result', 'blur');
        filter.append('feComposite')
          .attr('in2', 'SourceAlpha')
          .attr('operator', 'arithmetic')
          .attr('k2', -1)
          .attr('k3', 1)
          .attr('result', 'shadowDiff');
        filter.append('feFlood')
          .attr('flood-color', stringColor)
          .attr('flood-opacity', 1);
        filter.append('feComposite')
          .attr('in2', 'shadowDiff')
          .attr('operator', 'in');
        filter.append('feComposite')
          .attr('in2', 'SourceGraphic')
          .attr('operator', 'over');
      });
    });
  }

  /**
   * Transform colors (hex/name/rgb/rgba) to valid ID
   * Used to create gradient and shadow names:
   *   white             => 'white'
   *   #abcdef           => 'abcdef'
   *   rgba(0, 0, 0, .2) => 'rgba0002'
   *
   * @param  {string} color  Color name/code
   * @return {string}        Color ID
   */
  public static computeColorUniqueId(color: Color): string {
    return color.replace(/[^a-zA-Z0-9]/g, '');
  }
}

/**
 * Returns the string **for a translate operation in an SVG transform attribute**: `translate(<x> <y>)`.
 *
 * `transform` being a presentation attribute, it could be applied via a css rule instead, but the syntax is not the
 * same in both cases!
 * in attribute: translate(x y)  (https://developer.mozilla.org/en-US/docs/Web/SVG/Attribute/transform#translate)
 * in css:       translate(x, y) (https://developer.mozilla.org/en-US/docs/Web/CSS/transform-function/translate)
 */
export function svgAttrTranslate(x: number, y: number): string {
  return `translate(${x} ${y})`;
}

export function pointsToPolygon(points: number[][]): string {
  return points.reduce((a, b) => a + b.join(',') + ' ', '');
}

export const relative_shapes: { [shapeId: string]: Array<[number, number]> } = {
  hexagon: [[0, 2 / 3], [1 / 2, 1], [1, 2 / 3], [1, 1 / 3], [1 / 2, 0], [0, 1 / 3], [0, 2 / 3]],
  cross: [
    [0, 1],
    [1 / 4, 1],
    [1 / 2, 5 / 8],
    [3 / 4, 1],
    [1, 1],
    [5 / 8, 1 / 2],
    [1, 0],
    [3 / 4, 0],
    [1 / 2, 3 / 8],
    [1 / 4, 0],
    [0, 0],
    [3 / 8, 1 / 2],
    [0, 1],
  ],
  diamond: [[-1 / 2, 0], [0, 1 / 2], [1 / 2, 0], [0, -1 / 2]],
  square: [[-1 / 2, -1 / 2], [-1 / 2, 1 / 2], [-1 / 2, 1 / 2], [1 / 2, 1 / 2], [1 / 2, -1 / 2]],
};

// Contains the pixel size of the shape: {shape_id: shape_size}
export const POLYGON_PUNCTUAL_SHAPES: { [shape in ScheduleShape]?: number } = { diamond: 15, square: 12 };

export function shortenInto(text: string, width: number, fontSize: number): string {
  const fullTextWidth = textWidth(text, fontSize);
  // how much the three spaces will need at the end
  const dotSizes = 3 * charWidth('.', fontSize);
  const availableSpace = fullTextWidth <= width ? width : width - dotSizes;
  let shortText = '';
  let currentWidth = 0;
  for (const c of text) {
    const newCharWidth = charWidth(c, fontSize);
    if (currentWidth + newCharWidth < availableSpace) {
      shortText += c;
      currentWidth += newCharWidth;
    } else {
      return shortText + '...';
    }
  }

  return text;
}

/**
 * Estimates maximum number of characters to fit given width in pixels
 */
export function maxChars(pixelsWidth: number, fontSize: number): number {
  return Math.round(pixelsWidth / (fontSize * fontSize / 14 * 0.71));
}

export const scheduleSmallChars = new Set(['i', 'l', 'j', 'I', ' ', '(', ')']);
export const scheduleMidChars = new Set(['f', 'r', 't', '-', 'z', 'J']);
export const scheduleBigChars = new Set(['A', 'B', 'C', 'D', 'H', 'K', 'N', 'R', 'U', 'V', 'X', 'Y']);
export const scheduleVeryBigChars = new Set(['W', 'G', 'M', 'O', 'Q', 'w', 'm']);

export function scheduleCharWidth(c: string, fontSize: number): number {
  const schedulecharWidth = fontSize * 0.62;
  const scheduleMidCharsWidth = fontSize * 0.42;
  const scheduleSmallCharsWidth = fontSize * 0.357;
  const scheduleBigCharsWidth = fontSize * 0.82;
  const scheduleVeryBigCharsWidth = fontSize * 1;
  if (scheduleSmallChars.has(c)) {
    return scheduleSmallCharsWidth;
  } else if (scheduleMidChars.has(c)) {
    return scheduleMidCharsWidth;
  } else if (scheduleBigChars.has(c)) {
    return scheduleBigCharsWidth;
  } else if (scheduleVeryBigChars.has(c)) {
    return scheduleVeryBigCharsWidth;
  } else {
    return schedulecharWidth;
  }
}

export const smallChars = new Set(['i', 'l', 'f', 't', ' ', '1', 'I', 'y', '(', ')']);
export const midChars = new Set(['e', 'r', 's', 'n', 'd']);

export function charWidth(c: string, fontSize: number): number {
  const charWidth = fontSize * 0.7;
  const midCharWidth = fontSize * 0.55;
  const smallCharWidth = fontSize * 0.35;
  if (smallChars.has(c)) {
    return smallCharWidth;
  } else if (midChars.has(c)) {
    return midCharWidth;
  } else {
    return charWidth;
  }
}

export function textWidth(text: string, fontSize: number): number {
  let width = 0;
  for (const c of text) {
    width += charWidth(c, fontSize);
  }
  return width;
}

export function scheduleTextWidth(text: string, fontSize: number): number {
  let width = 0;
  for (const c of text) {
    width += scheduleCharWidth(c, fontSize);
  }
  return width;
}

export function shorten(text: string, chars: number): string {
  return text.substring(0, Math.min(text.length, chars));
}

export function shortenWithPoints(text: string, chars: number): string {
  if (!text) {
    return '';
  }

  if (text.length <= chars) {
    return text;
  }

  const shorted = shorten(text, Math.max(chars - 3, 1));
  return shorted + '...';
}

export function shortenWithPointsBySize(text: string, fontSize: number, maxPx: number): string {
  if (!text) {
    return '';
  }
  let chars = 0;
  let px = 0;
  if (maxPx < 150) {
    maxPx -= 15;
  }
  // '.' take 0.35 * fontSize pixel
  while (px < maxPx - (0.35 * fontSize * 3) && chars < text.length) {
    px += scheduleCharWidth(text[chars], fontSize);
    chars++;
  }
  if (text.length <= chars) {
    return text;
  }

  const shorted = shorten(text, Math.max(chars, 1));
  return shorted + '...';
}

export function drawLegendItems(
  svg: Selection<SVGElement, unknown, SelectionBaseType, unknown>,
  colorForASpecificKey: unknown,
  legendOptions: ChartLegendOptions,
  layers: { [layerId: string]: ScheduleLayer } = null,
): number {
  const width = legendOptions.width;
  const nbItemPerLine = legendOptions.nbItemPerLine;
  const fontSize = legendOptions.fontSize;
  const minLegendItemSize = legendOptions.minLegendItemSize ? legendOptions.minLegendItemSize : 100;
  const dashLegendIniPercentage = legendOptions.dashLegendPercentage ? legendOptions.dashLegendPercentage : 0;
  const layerTitlePercentage = legendOptions.layerTitlePercentage ? legendOptions.layerTitlePercentage : 0;
  let currentY = 0;
  const layersToDraw = Object.keys(colorForASpecificKey);

  // if layers have a default color by config we have to collect the list of layer which apply this default config
  let globalConfigLayerList = [];
  if (colorForASpecificKey['globalColorBy'] && colorForASpecificKey['globalColorBy']['globalConfigLayerList']) {
    globalConfigLayerList = colorForASpecificKey['globalColorBy']['globalConfigLayerList'];
    // we remove this layer list from the colorBy Config
    delete colorForASpecificKey['globalColorBy']['globalConfigLayerList'];
  }

  svg.selectAll('.color-legend').remove();

  const legend = svg
    .append('g')
    .classed('color-legend', true);

  layersToDraw.forEach(layerId => {
    const layerItems = Object.keys(colorForASpecificKey[layerId]);

    if (!layerItems.length) {
      return;
    }

    // majority of the legend is occupied with the colors, the rest with the dashing and the layer title
    const patternNumber = Object.keys(layerFillPatterns(layerId, globalConfigLayerList, layers)).length;
    const dashLegendPercentage = dashLegendIniPercentage && patternNumber ? dashLegendIniPercentage * patternNumber : 0;
    const colorsLegendWidth = width * (1 - dashLegendPercentage - layerTitlePercentage) - 5;
    const dashLegendWidth = Math.floor(width * dashLegendPercentage);
    const layerTitleWidth = width * layerTitlePercentage;

    // max size is the size of the biggest text
    const maxLegendItemSize = max(layerItems.map(d => textWidth(d, fontSize) + CIRCLE_SIZE));

    // make sure we are never too small
    let itemLength = Math.max(colorsLegendWidth / Math.min(nbItemPerLine, layerItems.length), minLegendItemSize);

    // make sure we are never too big
    itemLength = Math.min(itemLength, maxLegendItemSize);

    /*
     * theoretical per item - but to use the maximum space the short items are inserted as they are
     * and the long items are shorthend
     */
    const perItem = (colorsLegendWidth - (CIRCLE_SIZE * nbItemPerLine)) / nbItemPerLine;

    // we start aligning from the right
    let currentX = dashLegendWidth + layerTitleWidth + 5;

    currentY += LegendDrawingHelper.LEGEND_LAYERS_SPACING;
    const previousLayerY = currentY;

    const margin = 10;
    const legendSize = sum(layerItems.map(d => textWidth(d, fontSize) + CIRCLE_SIZE + margin));

    function processLegendLayerTitle(): void {
      // Draw layer title part
      if (layerTitlePercentage) {
        let layerTitle = layerId;
        if (layers && layers[layerId] && layers[layerId]) {
          const layer = layers[layerId];
          if (layer.options && layer.options.title) {
            layerTitle = layers[layerId].options.title;
          }
        }
        drawLegendLayerTitle(
          legend,
          currentY,
          previousLayerY,
          layerTitle,
          fontSize,
          layerTitleWidth,
          legendOptions.commonLegendTitle,
        );
      }
      // Draw dash part
      if (dashLegendWidth && layers) {
        const dashLegendOptions: ChartLegendOptions = {
          width: dashLegendWidth,
          nbItemPerLine: null,
          fontSize: fontSize,
          currentX: layerTitleWidth,
          currentY: currentY,
          previousLegendY: previousLayerY,
        };
        addDashToLegend(dashLegendOptions, legend, layerId, layers, globalConfigLayerList);
      }
    }
    /**
     * if the legend has only one layer and if the legend fit in one line we will adapt the item size to fit the legend
     * on this line
     */
    if (legendSize <= colorsLegendWidth && layersToDraw.length === 1) {
      currentY = oneLineLegend(
        legend,
        legendSize,
        width,
        layerItems,
        fontSize,
        currentY,
        colorForASpecificKey[layerId],
      );
      processLegendLayerTitle();
      currentY += LegendDrawingHelper.LEGEND_BOTTOM_MARIN;
      return;
    }

    for (const value of layerItems) {
      const fitsInLine = (currentX + itemLength) <= width;

      if (!fitsInLine) {
        currentX = dashLegendWidth + layerTitleWidth + 5;
        currentY += 20;
      }
      const color = colorForASpecificKey[layerId][value];
      addItemLegend(legend, currentX, currentY, upperCaseFirstLetter(value), color, perItem, fontSize);
      legendSize;
      currentX += itemLength;
    }
    /*
     * Draw layer title part
     * If the legend colors come from the global color by we don't draw the layer title
     */
    processLegendLayerTitle();
    currentY += 15;
  });
  return currentY;
}

/**
 * check if a layer has a dash legend
 * There is a particular case. If the legend layer to draw is the one from the default config, we have to iterate
 * through each layer using this config and check if a layer has a dash config
 */
export function layerHasDashLegend(
  layerId: string,
  globalConfigLayerList: string[],
  layers: { [layerId: string]: ScheduleLayer },
): boolean {
  const layerListToCheck = (layerId === 'globalColorBy') ? globalConfigLayerList : [layerId];
  for (const layerToCheck of layerListToCheck) {
    const layer = layers[layerToCheck];
    if (layer?.options?.fill?.some(fill => fill.field)) {
      return true;
    }
  }
  return false;
}

/**
 * Build schedule patterns Config/State for a given layer.
 *
 * @param  {string}           layerId                Layer ID
 * @param  {string[]}         globalConfigLayerList  Layers list ('globalColorBy' layer)
 * @param  {object}           layers                 Schedule layers as an object
 * @return {SchedulePatterns}                        Config/State patterns
 */
export function layerFillPatterns(
  layerId: string,
  globalConfigLayerList: string[],
  layers: { [layerId: string]: ScheduleLayer },
): SchedulePatterns {
  // Layers to check
  const layerListToCheck = layerId === 'globalColorBy' ? globalConfigLayerList : [layerId];

  // Loop
  const patterns: SchedulePatterns = {};
  for (const layerToCheck of layerListToCheck) {
    const layer = layers[layerToCheck];
    // Only if layer has patterns
    layer?.options?.fill?.forEach(fill => {
      const patternId = fill.patternId ?? LegendDrawingHelper.DEFAULT_FILL_PATTERN;
      if (!(patternId in patterns)) {
        patterns[patternId] = [];
      }
      // Push Config/State
      patterns[patternId].push({
        title: fill.title,
        field: fill.field,
        fill: LEGEND_DEFAULT_BACKGROUND,
        count: 0,
      });

      // When there is stroke, push an extra pattern 'fill' to signify the opposite
      if (patternId === 'stroke') {
        patterns['fill'] ??= [];
        patterns['fill'].push({
          title: 'Firm',
          field: fill.field + 'NoFill',
          fill: LEGEND_DEFAULT_BACKGROUND,
          count: 0,
        });
      }
    });
    // Patterns, fills & strokes from additional properties
    layer?.additionalProperties?.forEach((additionalProperty, index) => {
      const { pattern, mask, fill, stroke } = additionalProperty.highlight;
      let patternId = pattern ?? mask;
      const color = fill ?? stroke ?? LEGEND_DEFAULT_BACKGROUND;
      if (!patternId && !fill && !stroke) return;
      // Special case for strokes & fills (one per color)
      if (!patternId) {
        patternId = (fill ? 'fill-' : 'stroke-') + LegendDrawingHelper.computeColorUniqueId(color);
      }
      patterns[patternId] ??= [];
      patterns[patternId].push({
        title: additionalProperty.title,
        field: 'additionalProperty' + index,
        fill: color,
        count: 0,
      });
    });
  }
  return patterns;
}

export function drawLegendLayerTitle(
  legend: Selection<SVGGElement, unknown, SelectionBaseType, unknown>,
  currentY: number,
  previousLayerY: number,
  layerId: string,
  fontSize: number,
  layerTitleWidth: number,
  commonLegendTitle: string = null,
): void {
  // If the legend colors come from the global color by we don't draw the layer title
  if (layerId === 'globalColorBy') {
    layerId = commonLegendTitle ?? 'Common legend';
  }

  const layerLegendHeight = currentY - previousLayerY;

  const layerTitleX = 5;
  const layerTitleY = previousLayerY + layerLegendHeight / 2;

  const item = legend
    .append('g')
    .classed('title-layer-item', true)
    .attr('transform', svgAttrTranslate(layerTitleX, layerTitleY));

  item
    .append('text')
    .attr('x', 0)
    .attr('dy', '0.35em')
    .attr('y', 0)
    .style('font-size', (fontSize + 1) + 'px')
    .style('font-weight', 600)
    // a bit of cleanup in the legend we want to get rid of everything un-necessary
    .text(shortenInto(upperCaseFirstLetter(layerId), layerTitleWidth, fontSize + 3));
}

export function addDashToLegend(
  dashLegendOptions: ChartLegendOptions,
  legend: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
  legendLayerId: string,
  layers: { [layerId: string]: ScheduleLayer },
  globalConfigLayerList: string[],
): void {
  const patternLegendWidth = dashLegendOptions.width;
  const fontSize = dashLegendOptions.fontSize;
  const currentX = dashLegendOptions.currentX ? dashLegendOptions.currentX : 0;
  const currentY = dashLegendOptions.currentY ? dashLegendOptions.currentY : 0;
  const previousLayerY = dashLegendOptions.previousLegendY ? dashLegendOptions.previousLegendY : 0;
  const legendFillPatterns = layerFillPatterns(legendLayerId, globalConfigLayerList, layers);

  /*
   * Found used pattern
   * TODO: At this point legendFillPatterns count is still 0
   *       We may have to call this method later when layer is fully loaded and .count are up-to-date
   */
  const patterns = Object.keys(legendFillPatterns);
  // .filter((patternId) => { legendFillPatterns[patternId].reduce((v, p) => (v + p.count), 0) > 0 })

  // Iterate
  if (patterns.length) {
    const patternWidth = Math.floor(patternLegendWidth / patterns.length);

    const patternListX = currentX + 5;
    const layerLegendHeight = currentY - previousLayerY;
    const patternY = (previousLayerY + layerLegendHeight / 2) - 7.5;
    const patternRectWidth = 25;
    const patternLabelMaxWidth = maxChars(patternWidth - patternRectWidth, fontSize);
    const patternItemList = legend
      .append('g')
      .classed('color-legend-item-pattern-list', true)
      .attr('transform', svgAttrTranslate(patternListX, patternY));
    let patternIndex = 1;

    for (const patternId of patterns) {
      const pattern = legendFillPatterns[patternId];
      const patternX = (patternIndex - 1) * patternWidth;
      // the filled part is aligned completely to the left
      const patternElement = patternItemList
        .append('g')
        .classed('color-legend-item-pattern', true)
        .attr('transform', svgAttrTranslate(patternX, 0));

      if (patternId.startsWith('stroke')) {
        patternElement
          .append('rect')
          .attr('fill', 'white')
          .attr('stroke', pattern[0].fill)
          .attr('stroke-width', 2)
          .attr('width', patternRectWidth)
          .attr('height', 15);
      } else {
        patternElement
          .append('rect')
          .attr('fill', pattern[0].fill)
          .attr('width', patternRectWidth)
          .attr('height', 15);
        if (!patternId.startsWith('fill')) {
          patternElement
            .append('rect')
            .attr('fill', `url(#${patternId}-pattern)`)
            .attr('width', patternRectWidth)
            .attr('height', 15);
        }
      }

      /*
       * Write all labels corresponding to the pattern
       * TODO Same goes here, count is still 0
       *      Would be solved if method is called later
       */
      const active = pattern; // .filter((p) => p.count > 0)
      const labelNumber = active.length;
      if (labelNumber === 1) {
        const label = active[0].title;
        patternElement
          .append('text')
          .attr('x', patternRectWidth + 4)
          .attr('y', 8)
          .attr('dy', '0.35em')
          .style('font-size', fontSize + 'px')
          .text(shorten(label, patternLabelMaxWidth));
      } else {
        let x = 0;
        let y = 8;
        let index = 0;

        active.forEach(patternConf => {
          const label = index === labelNumber ? patternConf.title : `${patternConf.title},`;
          /*
           * Multi line legend, if we have space to put the pattern legend label
           * on more that one line we use this space
           * Otherwise we define a minimum heigh to draw multidash legend corresponding to 10pixel * item number
           */
          const legendMinHeight = labelNumber * 10;
          const appliedPatternHeight = Math.max(layerLegendHeight + 10, legendMinHeight);

          y = index * (appliedPatternHeight + 10) / labelNumber;
          x = patternRectWidth + 4;
          patternElement
            .append('text')
            .attr('x', x)
            .attr('y', y)
            .attr('dy', '0.35em')
            .style('font-size', fontSize + 'px')
            .attr('fill', patternConf.fill)
            .text(shorten(label, patternLabelMaxWidth));

          index++;
        });
      }
      patternIndex++;
    }
  }
}

export function upperCaseFirstLetter(str: string): string {
  if (!str) {
    return '';
  }
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function oneLineLegend(
  legend: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
  legendSize: number,
  width: number,
  legendItems: string[],
  fontSize: number,
  currentY: number,
  layerColors: { [key: string]: Color },
): number {
  let currentX = width - legendSize;
  const margin = 10;
  legendItems.map(item => {
    const color = layerColors[item];
    const textSize = textWidth(item, fontSize);
    const itemLength = textSize + CIRCLE_SIZE + margin;

    addItemLegend(legend, currentX, currentY, item, color, itemLength, fontSize);
    currentX += itemLength;
  });
  return currentY;
}

export function addItemLegend(
  legend: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
  currentX: number,
  currentY: number,
  legendItem: string,
  color: Color,
  textSize: number,
  fontSize: number,
): void {
  const item = legend
    .append('g')
    .classed('color-legend-item', true)
    .attr('transform', svgAttrTranslate(currentX, currentY));

  item
    .append('circle')
    .attr('fill', color)
    .attr('r', 6)
    .attr('cx', 6);

  item
    .append('text')
    .attr('x', CIRCLE_SIZE)
    .attr('dy', '0.35em')
    .style('font-size', fontSize + 'px')
    // a bit of cleanup in the legend we want to get rid of everything un-necessary
    .text(shortenInto(legendItem, textSize, fontSize));

  item.append('title').text(legendItem);
}

export function showSmallTooltip(tooltipText: string, tooltip: SimpleTooltipComponent, coords: [number, number]): void {
  tooltip.show(tooltipText, coords);
}

export function showListTooltip(
  tooltipText: string,
  items: string[],
  tooltip: ListTooltipComponent,
  coords: [number, number],
): void {
  const tooltipOpts: ListTooltipSettings = {
    title: tooltipText,
    items: items,
  };
  tooltip.show(tooltipOpts, coords);
}

export function hideTooltip(tooltip: PositionedTooltip): void {
  tooltip.hide();
}

export function reportStyle(
  ref: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
  target: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
): void {
  if (!ref || !target) return;
  const style = ref.attr('style');
  if (!style || style === 'null') return;
  target.attr('style', style);
}

export function getTranslateFromTransform(
  sel: Selection<SelectionBaseType, unknown, SelectionBaseType, unknown>,
): { x: number; y: number } | null {
  const existingTransform = sel.attr('transform');
  if (!existingTransform) return null;
  /*
   * optional minus, group of digit / point, followed by optional comma and any number of space (non capture),
   * and a group of digits
   */
  const matched = existingTransform.match(/translate\((-?[0-9.]+),?(?:\s+)?(-?[0-9.]+)/);
  if (matched?.length === 3) return { x: parseFloat(matched[1]), y: parseFloat(matched[2]) };
  return null;
}
