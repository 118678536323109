import { AfterViewInit, ElementRef, Input, computed, input, model, signal, viewChild, OnDestroy, Directive, InputSignal,
  Output, EventEmitter, HostListener, effect } from '@angular/core';
import { MatAutocompleteModule, MatAutocompleteTrigger, MatOption } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { MatChipsModule } from '@angular/material/chips';
import { MatDividerModule } from '@angular/material/divider';

import { fromEvent, Subscription } from 'rxjs';

import { getTextWidth } from '../../helpers/dom.helper';
import { DeepReadonly } from './../../../../../helpers/types';
import { PearlButtonComponent } from '../../buttons/pearl-button.component';
import { PearlIconComponent } from '../../icons/pearl-icon.component';
import { FormField } from '../form-field';
import { PearlOptionComparator, PearlSelectOption, PearlSelectOptionValueType } from './pearl-base-select.type';
import { AutocompleteFilterHelper, ICON_SUFFIX_WIDTH } from '../../../../../filters/autocomplete-filter.helper';

export function toPearlOption(
  options: DeepReadonly<Partial<PearlSelectOption>[]>,
): PearlSelectOption[] {
  return options.map(o => ({ ...o, value: o.value ?? o.id })).filter(option =>
    typeof option.value === 'boolean' || (option.title != null && option.value != null)
  ).map((option) => new PearlSelectOption(option));
}

export const PEARL_BASE_SELECT_IMPORTS = [
  MatAutocompleteModule,
  PearlIconComponent,
  ScrollingModule,
  NgClass,
  FormsModule,
  MatChipsModule,
  MatDividerModule,
  MatFormFieldModule,
  PearlButtonComponent,
  MatCheckboxModule,
  MatInputModule,
  NgTemplateOutlet,
];

export const PEARL_BASE_SELECT_PROVIDERS = [
  {
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
      appearance: 'outline',
    },
  },
];

@Directive({
  standalone: true,
  host: {
    '[class.highlighted]': 'highlighted()',
    '[class.small]': 'small()',
    '[class.editable]': 'editable()',
    '[class.hint-error]': 'hintError()',
    '[class.disable-events]': 'disabled()',
  },
})
export abstract class PearlBaseSelect<T extends PearlSelectOptionValueType> extends FormField
  implements AfterViewInit, OnDestroy
{
  private sub$ = new Subscription();
  public abstract readonly searchable: InputSignal<boolean>;

  protected readonly resizeObserver = new ResizeObserver(_ => this.inputWidth.set(this.getInputWidth()));

  public readonly inputWidth = signal<number>(0);
  public readonly hasFocus = signal<boolean>(false);
  public readonly panelOpened = signal<boolean>(false);
  public readonly pearlOptions = signal<PearlSelectOption<T>[]>([]);
  public readonly selectAllChecked = signal<boolean>(false);

  public readonly search = model<string>('');

  public readonly disabled = input<boolean>(false);
  public readonly clearable = input<boolean>(false);
  public readonly searchOnly = input<boolean>(false);
  public readonly multiple = input<boolean>(false);
  public readonly showSelectAll = input<boolean>(false);
  public readonly showBlankOption = input<boolean>(false);
  public readonly sortFn = input<PearlOptionComparator>((a, b) => a.title.localeCompare(b.title));

  @Input({ transform: toPearlOption, required: true })
  set options(options: PearlSelectOption<T>[]) {
    this.pearlOptions.set(options);
  }

  constructor() {
    super();

    effect(() => {
      if (
        this.showBlankOption()
        && !this.pearlOptions().find((option) => option.value === AutocompleteFilterHelper.BLANK_OPTION_VALUE)
      ) {
        this.pearlOptions.update((options) => {
          options.push(
            new PearlSelectOption<T>({ title: '(Blanks)', value: AutocompleteFilterHelper.BLANK_OPTION_VALUE as T }),
          );

          return [...options];
        });
      }
    }, { allowSignalWrites: true });
  }

  @Input()
  set selectedValues(values: PearlSelectOptionValueType[]) {
    if (values.length === 0) return;
    let needUpdate = false;

    if (!this.showSelectAll()) {
      this.selectAllChecked.set(false);
      needUpdate = AutocompleteFilterHelper.includesSelectAll(values);
    } else if (AutocompleteFilterHelper.includesSelectAll(values)) {
      this.selectAllChecked.set(true);
      values = values.filter(value => value.toString() !== AutocompleteFilterHelper.SELECT_ALL_VALUE.toString());
    }

    let noOptionSelected = values.length;

    this.pearlOptions.update((options) => {
      if (
        this.showBlankOption()
        && !options.find((option) => option.value === AutocompleteFilterHelper.BLANK_OPTION_VALUE)
      ) {
        options.push(
          new PearlSelectOption<T>({ title: '(Blanks)', value: AutocompleteFilterHelper.BLANK_OPTION_VALUE as T }),
        );
      }

      options.forEach((option) => {
        option.selected = values.includes(option.value) || values.includes(option.value.toString());
        if (option.selected) noOptionSelected--;
      });
      return [...options];
    });

    if (this.selectAllChecked() && !this.isIndeterminate()) {
      this.resetField();
      return;
    }

    if (noOptionSelected > 0 || needUpdate) {
      const selectedValues = this.getSelectedOptions().map((option) => option.value);
      if (this.multiple()) this.optionsSelected.emit(selectedValues ?? []);
      else this.optionSelected.emit(selectedValues?.[0]);
    }
  }

  public readonly $autocomplete = viewChild<MatAutocompleteTrigger, MatAutocompleteTrigger>('input', {
    read: MatAutocompleteTrigger,
  });
  public readonly $input = viewChild<ElementRef<HTMLInputElement>>('input');
  public readonly $viewport = viewChild(CdkVirtualScrollViewport);

  @Output()
  public readonly optionsSelected = new EventEmitter<T[]>();
  @Output()
  public readonly optionSelected = new EventEmitter<T | null>();
  @Output()
  public readonly resetSelect = new EventEmitter<void>();
  @HostListener('focusout')
  public onFocusOut(): void {
    this.hasFocus.set(false);
  }

  public readonly isMultipleAutocomplete = computed(() => this.multiple() && !this.searchOnly());

  public readonly hasSelectAll = computed(() =>
    this.showSelectAll() && this.pearlOptions().length > 2 && this.isMultipleAutocomplete()
  );

  public readonly chipTitles = computed(() => {
    const titles = this.getSortedTitles();
    const inputContainerElement = this.getInputContainerElement();
    if (!inputContainerElement) return [];

    const availableWidth = this.getAvailableWidth(inputContainerElement);

    if (getTextWidth(titles.join(', ')) < availableWidth) {
      return titles;
    }

    if (titles.length === 1) {
      return titles;
    }

    return this.getFittingTitles(titles, this.inputWidth() - ICON_SUFFIX_WIDTH);
  });

  private getSortedTitles(): string[] {
    return this.getSelectedOptions(!this.selectAllChecked())
      .map((option) => option.title)
      .sort((a, b) => a.length - b.length);
  }

  public getAvailableWidth(inputContainerElement: HTMLElement): number {
    return inputContainerElement.offsetWidth - ICON_SUFFIX_WIDTH;
  }

  private getFittingTitles(titles: string[], availableWidth: number): string[] {
    const truncatedTitles: string[] = [];
    for (let i = 0; i < titles.length; i++) {
      availableWidth -= getTextWidth(titles[i]);
      if (availableWidth < 0) break;
      truncatedTitles.push(titles[i]);
    }
    return truncatedTitles;
  }

  public readonly filteredOptions = computed(() => {
    if (typeof this.search() !== 'string') return this.sortOptions(this.pearlOptions());
    const search = this.search().toLowerCase();
    return this.sortOptions(
      this.pearlOptions().filter((option) =>
        option.title.toString().toLowerCase().includes(search)
        || option.optionDescription?.toString().toLowerCase().includes(search)
      ),
    );
  });

  public readonly isIndeterminate = computed(
    () =>
      this.pearlOptions().some((option) => !option.selected) && this.pearlOptions().some((option) => option.selected),
  );

  public readonly itemSize = computed(() => this.small() ? 32 : 48);

  public ngAfterViewInit(): void {
    // Here, we monkey patch the default keydown handler to prevent the default behavior of the enter key
    // & space key define within angular material
    // eslint-disable-next-line @typescript-eslint/unbound-method
    const func = this.$autocomplete()._handleKeydown;

    /**
     * @description Override the default keydown handler to prevent the default behavior of the enter key
     * & space key define within angular material
     */
    this.$autocomplete()._handleKeydown = function(event: KeyboardEvent): void {
      if (event.key === 'Enter' || event.key === 'Space') {
        return;
      }

      return (func.bind(this) as typeof func)(event);
    };

    const inputContainerElement = this.getInputContainerElement();
    if (inputContainerElement) this.resizeObserver.observe(inputContainerElement);
  }

  ngOnDestroy(): void {
    const inputContainerElement = this.getInputContainerElement();
    if (inputContainerElement) this.resizeObserver.unobserve(inputContainerElement);
    this.sub$.unsubscribe();
  }

  public toggle(option: -1 | PearlSelectOption<T>, event?: Event): void {
    event?.stopPropagation();

    if (option === -1) this.toggleAll(event);
    else if (this.multiple()) this.multiToggle(option);
    else this.singleToggle(option);
  }

  public toggleAll(event: Event = null, toggleValue?: false): void {
    event?.stopPropagation();

    this.selectAllChecked.set(toggleValue ?? !this.selectAllChecked());

    this.pearlOptions.update((options) => {
      options.forEach((option) => (option.selected = this.selectAllChecked()));
      return [...options];
    });

    this.optionsSelected.emit(this.getSelectedOptions().map((option) => option.value));
  }

  public getSelectedOptions(selected = true): PearlSelectOption<T>[] {
    return this.pearlOptions().filter((option) => option.selected === selected);
  }

  public keyboardEvent(event: KeyboardEvent): void {
    switch (event.key) {
      case 'Enter':
        this.toggle(this.$autocomplete()?.activeOption?.value as PearlSelectOption<T>, event);
        this.updatePanelPosition();
        break;
      case 'Escape':
        this.clear();
        break;
      case 'ArrowDown':
      case 'ArrowUp':
        this.updatePanelPosition();
        break;
      default:
        break;
    }
  }

  public clear(): void {
    this.search.set('');

    const inputElement = this.$input()?.nativeElement;
    if (inputElement) {
      inputElement.value = '';
      inputElement.blur();
    }
  }

  public resetField(event: MouseEvent = null): void {
    this.toggleAll(event, false);
    this.clear();
    this.resetSelect.emit();
  }

  public openPanel(event: MouseEvent): void {
    if (this.disabled() || this.readonly()) return;
    event.stopPropagation();
    this.$autocomplete()?.openPanel();
    this.sub$ = fromEvent<MouseEvent>(document, 'click', { capture: true }).subscribe((ev) => {
      const clickOutsideOverlay = !this.$viewport().elementRef.nativeElement.contains(ev.target as Node);
      if (clickOutsideOverlay && this.$autocomplete().panelOpen) {
        this.$autocomplete().closePanel();
        if (this.selectAllChecked() && !this.isIndeterminate()) this.resetField();
        this.clear();
        this.sub$.unsubscribe();
      }
    });
  }

  protected updatePanelPosition(): void {
    if (this.$viewport() && this.$autocomplete()) {
      const activeOptionIndex = this.filteredOptions().indexOf(
        (this.$autocomplete()?.activeOption as MatOption<PearlSelectOption<T>>).value,
      );

      if (activeOptionIndex !== -1) {
        this.$viewport()?.scrollToIndex(activeOptionIndex, 'smooth');
      }
    }
  }

  protected abstract sortOptions(options: PearlSelectOption<T>[]): PearlSelectOption<T>[];

  protected singleToggle(option: PearlSelectOption<T>): void {
    this.pearlOptions.update((options) => {
      return options.map((o) => {
        o.selected = o.value === option.value;
        return o;
      });
    });

    this.clear();
    this.$autocomplete()?.closePanel();

    const selectedOption = this.getSelectedOptions()?.find((o) => o.selected);

    this.optionSelected.emit(selectedOption?.value ?? null);
  }

  protected multiToggle(option: PearlSelectOption<T>): void {
    this.pearlOptions.update((options) => {
      const index = options.indexOf(option);
      options[index].selected = !options[index].selected;
      return [...options];
    });

    const selectedOptions = this.checkAllSelected();
    this.optionsSelected.emit(selectedOptions);
  }

  private checkAllSelected(): T[] {
    const selectedOptions = this.getSelectedOptions().map((option) => option.value);
    if (!this.hasSelectAll()) return selectedOptions;

    const allOptionsSelected = selectedOptions.length === this.pearlOptions().length;
    const noOptionsSelected = selectedOptions.length === 0;

    if (noOptionsSelected) this.selectAllChecked.set(false);
    if (allOptionsSelected) this.selectAllChecked.set(true);

    return selectedOptions;
  }

  protected getInputContainerElement(): HTMLElement | null | undefined {
    return this.$input()?.nativeElement.closest<HTMLElement>('.mat-mdc-form-field-infix');
  }

  protected getInputWidth(): number {
    const width = getTextWidth(`${this.getSelectedOptions(this.selectAllChecked()).length} selected`);
    return (this.getInputContainerElement()?.offsetWidth ?? 0) - width;
  }
}
