<div cdkTrapFocus role="dialog" [attr.aria-modal]="true" class="pearl-datepicker-content-container">
  @if (showHeader()) {
    <pearl-datepicker-header
      [activeDate]="selected()"
      [activeView]="view()"
      [dateHint]="startDate()"
      [precision]="precision()"
      [timeOnly]="timeOnly()"
      [withTimezone]="withTimezone()"
      (viewSelected)="changeView($event)"
      (controlClicked)="handleControls($event)" />
  }
  @switch (step()) {
    @case ("date") {
      <mat-calendar
        [startView]="calendarStartView()"
        [minDate]="minDate()"
        [maxDate]="maxDate()"
        [dateFilter]="dateFilter()"
        [selected]="selected()"
        [startAt]="startDate()"
        [headerComponent]="calendarHeader"
        [@fadeInCalendar]="'enter'"
        (_userSelection)="selectDate($event.value)"
        (yearSelected)="selectYear($event)"
        (monthSelected)="selectMonth($event)"
        (viewChanged)="view.set($event)" />
    }
    @case ("time") {
      <div class="clock-wrapper">
        <pearl-clock
          [selected]="selected()"
          [step]="minuteGranularity()"
          (selectedChange)="selectTime($event)"
          (viewChange)="view.set($event)" />
      </div>
    }
    @case ("timezone") {
      <pearl-timezone-picker (timezoneSelected)="selectTimezone($event)" />
    }
  }
  @if (timeOnly() && step() === "time") {
    <pearl-button (click)="selectCurrentDatetime()" type="alternative" class="time-now">Now</pearl-button>
  }
</div>
