import { Injectable } from '@angular/core';

import { SimpleTooltipComponent } from '../simple-tooltip';

@Injectable({
  providedIn: 'root',
})
export class SimpleTooltipService {
  /** Responsible for exposing a SimpleTooltipComponent as a service, this component is set in App.ts. */
  private $simpleTooltipComponent: SimpleTooltipComponent;

  public set component($component: SimpleTooltipComponent) {
    this.$simpleTooltipComponent = $component;
  }

  public get component(): SimpleTooltipComponent {
    return this.$simpleTooltipComponent;
  }
}
