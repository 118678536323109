import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { NgIf } from '@angular/common';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PearlIconComponent } from '../icons/pearl-icon.component';

@Component({
  standalone: true,
  selector: 'pearl-progress-bar',
  templateUrl: './pearl-progress-bar.component.html',
  styleUrls: ['./pearl-progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgIf, MatProgressBarModule, MatTooltipModule, PearlIconComponent],
})
export class PearlProgressBarComponent {
  public readonly value = input.required<number>();
  public readonly maxValue = input<number>(100);

  public readonly valueInPercent = computed<number>(() => this.value() * 100 / this.maxValue());
  public readonly isFull = computed<boolean>(() => this.value() >= this.maxValue());
  public readonly isMaxExceeded = computed<boolean>(() => this.value() > this.maxValue());

  public readonly maxExceededMessage = input<string>();
}
