import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, inject } from '@angular/core';

import { SimpleTooltipService } from '../services/simple-tooltip.service';
import { SimpleTooltipComponent } from '../simple-tooltip';

@Directive({
  selector: '[spinTooltip]',
  standalone: true,
})
export class SpinTooltipDirective implements OnInit, OnDestroy {
  @Input()
  spinTooltip: string;
  @Input()
  spinTooltipClassName = '';
  @Input()
  spinTooltipMarkdownSupport = false;

  private tooltipComponent: SimpleTooltipComponent = inject(SimpleTooltipService).component;
  private readonly htmlElement: HTMLElement = inject(ElementRef).nativeElement;
  private readonly ngZone = inject(NgZone);

  ngOnInit(): void {
    /** Add event listeners outside Angular zone to prevent from performance issues due to change detection */
    this.ngZone.runOutsideAngular(() => {
      this.htmlElement.addEventListener('mouseenter', (event: MouseEvent) => this.displayTooltip(event));
      this.htmlElement.addEventListener('mouseleave', () => this.hideTooltip());
    });
  }

  private displayTooltip(event: MouseEvent): void {
    // If no data in the tooltip, do not show anything
    if (!this.spinTooltip) return;
    const mousePosition: [number, number] = [event.clientX, event.clientY];

    this.tooltipComponent.specificStylingClassName = this.spinTooltipClassName;
    this.tooltipComponent.markdownSupport = this.spinTooltipMarkdownSupport;
    this.tooltipComponent.show(this.spinTooltip, mousePosition);
  }

  private hideTooltip(): void {
    this.tooltipComponent?.hide();
  }

  ngOnDestroy(): void {
    this.hideTooltip();
  }
}
