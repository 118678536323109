import { Component, Input } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { NgClass, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { SpinTooltipDirective } from '../shared/directives/spin-tooltip.directive';
import { SelectorActionConfig } from './selector.types';
import { PearlButtonComponent } from '../shared/pearl-components';

@Component({
  selector: 'selector-action-button',
  templateUrl: './selector-action-button.component.html',
  styleUrls: ['./selector-action-button.component.css'],
  standalone: true,
  imports: [
    NgIf,
    SpinTooltipDirective,
    PearlButtonComponent,
    MatButtonModule,
    MatIconModule,
    NgClass,
    MatMenuModule,
  ],
})
export class SelectorActionButtonComponent {
  @Input()
  public action?: SelectorActionConfig;

  @Input()
  public isMenuButton: boolean = false;
}
