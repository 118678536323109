@let selectedOptions = getSelectedOptions(true);
@let chipOptions = selectAllChecked() ? getSelectedOptions(false).length : selectedOptions.length;

<mat-form-field [floatLabel]="floatLabel()" (click)="openPanel($event)">
  @if (iconPrefix()) {
    <pearl-icon matIconPrefix [icon]="iconPrefix()" [size]="iconSize()" />
  }

  @if (hasLabel()) {
    <mat-label>{{ label() }}</mat-label>
  }

  <mat-chip-grid
    [disabled]="disabled()"
    #chipList
    [ngClass]="{ small: small(), 'show-unselected-chips': selectAllChecked() }">
    @let autocompleteSuggestionsShowed = hasFocus() || $autocomplete()?.panelOpen;
    @if (!searchable() || (!searchOnly() && !autocompleteSuggestionsShowed)) {
      @for (option of chipTitles(); track option; let index = $index) {
        <mat-chip-row
          [ngClass]="{ 'other-option-chip': !searchable() }"
          [disableRipple]="true"
          (click)="openPanel($event)">
          {{ option + (!searchable() && index < chipTitles().length - 1 ? ", " : "") }}
        </mat-chip-row>
      }

      @if (chipTitles().length < chipOptions) {
        <mat-chip-row class="other-option-chip additional-text" [disableRipple]="true" (click)="openPanel($event)">
          ({{ chipOptions }} {{ selectAllChecked() ? "excluded" : "selected" }})
        </mat-chip-row>
      }
    }

    <input
      [placeholder]="selectedOptions.length ? null : label()"
      [disabled]="disabled()"
      [readonly]="readonly()"
      [class.hidden]="!searchable()"
      type="text"
      #input
      matInput
      (focus)="hasFocus.set(true)"
      (focusout)="hasFocus.set(false)"
      [(ngModel)]="search"
      [matChipInputFor]="chipList"
      [matAutocomplete]="autoGroup"
      (keydown)="keyboardEvent($event)" />
  </mat-chip-grid>

  <mat-autocomplete
    autoActiveFirstOption
    (opened)="panelOpened.set(true)"
    (closed)="panelOpened.set(false)"
    [hideSingleSelectionIndicator]="true"
    #autoGroup="matAutocomplete">
    @let options = filteredOptions();
    @let selectAllCount = hasSelectAll() ? 1 : 0;
    @let scrollable = options.length + selectAllCount > 6;
    @let panelHeight = scrollable ? itemSize() * 6 : itemSize() * (options.length + selectAllCount);

    <cdk-virtual-scroll-viewport
      [style.height.px]="panelHeight"
      [style.overflow]="scrollable ? 'auto' : 'hidden'"
      [class]="small() ? 'small' : ''"
      [itemSize]="itemSize()"
      [minBufferPx]="10 * itemSize()"
      [maxBufferPx]="10 * itemSize()"
      appendOnly>
      @if (hasSelectAll()) {
        <mat-option [value]="-1" cdkVirtualScrollingElement>
          @let allOptionsChecked = selectedOptions.length === pearlOptions().length;
          <div
            class="autocomplete-option-div"
            [ngClass]="{ selected: selectAllChecked() || isIndeterminate() || allOptionsChecked }"
            (click)="toggleAll($event)">
            Select all
            <mat-checkbox [checked]="selectAllChecked() && !isIndeterminate()" [indeterminate]="isIndeterminate()" />
          </div>
        </mat-option>
      }

      <div *cdkVirtualFor="let option of options; let index = index">
        @let previousOption = options[index - 1];
        @let newGroup = option?.groupName && option.groupName !== previousOption?.groupName && !option.selected;

        @if (searchable() && previousOption?.selected && !option.selected) {
          <mat-divider cdkVirtualScrollingElement />
        }

        @if (newGroup) {
          <mat-optgroup [label]="option.groupName" cdkVirtualScrollingElement> </mat-optgroup>
        }

        <mat-option [value]="option">
          <div
            class="autocomplete-option-div"
            [ngClass]="{ selected: option.selected }"
            (click)="toggle(option, $event)">
            <div class="autocomplete-option-content">
              <span>
                @if (option?.icon) {
                  <pearl-icon [icon]="option.icon" [size]="iconSize()" />
                }
              </span>

              <div class="autocomplete-option-label">
                <span>{{ option.title }}</span>

                @if (option.optionDescription) {
                  <span class="autocomplete-option-description">{{ option.optionDescription }}</span>
                }
              </div>
            </div>

            @if (isMultipleAutocomplete()) {
              <mat-checkbox [checked]="option.selected" />
            }

            @if (!multiple() && option.selected) {
              <pearl-icon class="checked-icon" [size]="iconSize()" icon="checked" />
            }
          </div>
        </mat-option>
      </div>
    </cdk-virtual-scroll-viewport>
  </mat-autocomplete>

  @if (clearable() && (selectedOptions.length || search())) {
    <pearl-button
      matSuffix
      type="icon"
      [size]="small() ? 'small' : 'default'"
      icon="cancel"
      (click)="resetField($event)" />
  } @else if (!clearable()) {
    <div class="form-suffix" matSuffix>
      <ng-content select="[pearlFormSuffix]" />
    </div>
  }

  @if (hintStart() && !hintError()) {
    <mat-hint [innerHTML]="hintStart()" />
  }

  @if (hintError()) {
    <mat-hint [innerHTML]="errorMessage()" />
  }

  @if (hintEnd()) {
    <mat-hint align="end" [innerHTML]="hintEnd()" />
  }

  @if (showError()) {
    <mat-error align="end">{{ errorMessage() }}</mat-error>
  }

  @if (iconSuffix() && !displayEditSuffix()) {
    <pearl-icon matIconSuffix [icon]="iconSuffix()" [size]="iconSize()" />
  }

  @if (displayEditSuffix()) {
    <pearl-icon (click)="edit.emit()" matIconSuffix icon="edit" [size]="iconSize()" />
  }
</mat-form-field>
