import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, NgZone, ViewChild,
  ViewEncapsulation } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';

import { PositionedTooltip } from './tooltip';
import { ListTooltipSettings } from '../helpers/types';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';

@Component({
  selector: 'list-tooltip',
  templateUrl: 'list-tooltip.html',
  styleUrls: ['list-tooltip.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PearlButtonComponent,
    MatDividerModule,
    CdkVirtualScrollViewport,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
  ],
})
export class ListTooltipComponent extends PositionedTooltip {
  public items: string[] = [];
  public description: string = '';

  @ViewChild('scroll', { static: false })
  $scroller: CdkVirtualScrollViewport;

  constructor(elRef: ElementRef<HTMLElement>, cdRef: ChangeDetectorRef, protected ngZone: NgZone) {
    super(elRef, cdRef);
  }

  public show(opts: ListTooltipSettings, coords: [number, number]): void {
    this.resetTimeout();
    this.items = opts.items;
    this.description = opts.title;
    this.showTooltipAtPosition(coords);

    this.cdRef.detectChanges();
    this.scrolled();
  }

  public setTooltip(tooltipOpts: ListTooltipSettings, e: MouseEvent): void {
    const coords = [e.x, e.y] as [number, number];
    this.show(tooltipOpts, coords);
  }

  public scrolled(): void {
    this.$scroller.checkViewportSize();
  }
}
