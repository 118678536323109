<section *ngIf="field.hide; else matSelect"></section>

<ng-template #matSelect>
  <div class="spin-filters-select">
    <!-- Sometimes we set group by with no sampling values. In that case we don't want to show the group by select so
     that it is no confusing for the users -->
    <pearl-form-field *ngIf="!field.hide" [label]="field.title" [small]="true">
      <mat-select
        #select
        [matTooltip]="field.description"
        matTooltipPosition="above"
        [disabled]="!field.values || field.values.length < 2"
        [multiple]="multiple"
        [(ngModel)]="_value"
        (selectionChange)="onbubblingchange()">
        <mat-optgroup *ngIf="message" disabled class="message" [label]="message"></mat-optgroup>
        <mat-option
          *ngFor="let item of singleValues; let i = index"
          [value]="getItemValue(item)"
          [disabled]="isDisabled(item, i)">
          {{ item.title }}
        </mat-option>
        <mat-optgroup *ngFor="let group of valueGroups" [label]="group.title">
          <mat-option
            *ngFor="let item of group.values; let i = index"
            [value]="getItemValue(item)"
            [disabled]="isDisabled(item)">
            {{ item.title }}
          </mat-option>
        </mat-optgroup>
      </mat-select>
    </pearl-form-field>
  </div>
</ng-template>
