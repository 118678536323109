/**
 *  @desc Use this error to force a fingerprint on an error message to manage Sentry errors grouping.
 *  All errors with the same fingerprint will be automatically merged in Sentry.
 */
export class ErrorWithFingerprint extends Error {
  public fingerprint: string[];
  constructor(message: string, fingerprint: string[]) {
    super(message);
    // remove the first element of the stacktrace which corresponds to super constructor
    this.stack = this.stack.split('\n').slice(1).join('\n');
    this.fingerprint = fingerprint;
  }
}
