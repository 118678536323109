import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { PEARL_BASE_SELECT_IMPORTS, PEARL_BASE_SELECT_PROVIDERS, PearlBaseSelect } from './pearl-base-select';
import { PearlIcon } from '../../icons/icons';
import { PearlSelectOption, PearlSelectOptionValueType } from './pearl-base-select.type';

@Component({
  selector: 'pearl-select',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: PEARL_BASE_SELECT_IMPORTS,
  templateUrl: './pearl-base-select.html',
  providers: PEARL_BASE_SELECT_PROVIDERS,
  styleUrls: ['./pearl-base-select.scss', '../pearl-form-field.component.scss'],
})
export class PearlSelectComponent<T extends PearlSelectOptionValueType> extends PearlBaseSelect<T> {
  public override readonly searchable = input<boolean>(false);
  public override readonly iconSuffix = input<PearlIcon>('down');

  protected override sortOptions(options: PearlSelectOption<T>[]): PearlSelectOption<T>[] {
    return options.sort((a, b) => {
      if (a.groupName !== b.groupName) {
        if (!a.groupName) return -1;
        if (!b.groupName) return 1;
        return a.groupName.localeCompare(b.groupName);
      }

      return this.sortFn()(a, b);
    });
  }
}
