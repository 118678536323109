import { StringDuration } from '../helpers/types';

/**
 * This config is sent by the back-end, look for "/frontend-persistent-cache.json5", which is defined per product.
 *
 * Note that all times are computed by the server, sent through `serverTime` or `ResponseWithMetadata.__serverTime`.
 */
export interface PersistentCacheConfig {
  /** The backend is sending the current serverTime to evaluate expiration out of ttl using server time. */
  serverTime: number;
  cachedEndpoints: PersistentCacheEndpointConfig[];
}

export interface PersistentCacheEndpointConfig {
  /** `endpoint` mustn't have query parameters.  */
  endpoint: string;
  /** Time-to-live is evaluated against `serverTime` and cache entry `cachedTimestamp` */
  ttl: StringDuration;
  /** Timestamp of last invalidation in backend */
  lastInvalidatedTimestamp: number;
}

export const PERSISTENT_CACHE_TABLE_NAME = 'cache_table';

export interface PersistentCacheDatabaseSchema {
  [PERSISTENT_CACHE_TABLE_NAME]: string;
}

/** Describes the data that are stored in the persistent cache index. */
export interface PersistentCacheDatabaseEntry<T> {
  data: T;
  /** Complete url used as **primary key**, possibly with query parameters. */
  url: string;
  /** Base url (without parameters), used as **index**. */
  endpoint: string;
  /** this time is given by the server, through `ResponseWithMetadata.__serverTime`. */
  cachedTimestamp: number;
}
