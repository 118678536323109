<div class="action-buttons">
  <div class="secondary-actions">
    <pearl-button
      *ngIf="uiService.isXSmallDisplay() && secondaryActions.length > 1; else trueButtons"
      type="alternative"
      icon="chevron_down"
      [style.backgroundColor]="'white'"
      [matMenuTriggerFor]="menu">
      More actions
    </pearl-button>
    <mat-menu #menu="matMenu">
      <selector-action-button *ngFor="let action of secondaryActions" [action]="action" [isMenuButton]="true" />
    </mat-menu>

    <ng-template #trueButtons>
      <selector-action-button *ngFor="let action of secondaryActions" [action]="action" />
    </ng-template>
  </div>

  <selector-action-button [action]="primaryAction" />
</div>
