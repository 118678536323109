<ng-container *ngIf="buttonConfig">
  <pearl-button
    *ngIf="buttonConfig.icon; else textButton"
    [matTooltip]="buttonConfig.description"
    class="mat-button field-button"
    [icon]="buttonConfig.icon"
    type="icon"
    (click)="clickButton($event)" />
  <ng-template #textButton>
    <pearl-button
      [matTooltip]="buttonConfig.description"
      [type]="buttonType"
      class="mat-button field-button"
      (click)="clickButton($event)">
      <ng-container *ngIf="buttonConfig.contentProjection; else title">
        <ng-content />
      </ng-container>
      <ng-template #title> {{ buttonConfig.title }} </ng-template>
    </pearl-button>
  </ng-template>
</ng-container>
