import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NgStyle } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ScaleLinear, scaleLinear } from 'd3-scale';

import { Color, EntityFieldDefinition, FieldSettings } from '../helpers/types';

@Component({
  selector: 'score',
  templateUrl: './score.html',
  styleUrls: ['score.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [MatTooltipModule, NgStyle],
})
export class ScoreComponent {
  @Input()
  scoreValue: number;
  @Input()
  field: FieldSettings | EntityFieldDefinition;
  @Input()
  description: string = null;

  private scoreScale: ScaleLinear<Color, Color>;

  // Score is rounded to an integer
  protected get roundedScore(): number {
    return Math.round(this.scoreValue);
  }

  protected get scoreStyle(): { stroke: Color } {
    if (!this.scoreScale) {
      const domain = this.field.scoreDomain ?? [0, 25, 50, 75, 100];
      const colorRange: Color[] = this.field.scoreColorScale ?? ['#fd0303', '#fd9a03', '#fbfd03', '#67d611', '#127427'];
      this.scoreScale = scaleLinear<Color>(domain, colorRange);
    }

    return {
      stroke: this.scoreScale(this.scoreValue),
    };
  }
}
