<div>
  @if (showYearButton()) {
    <pearl-button type="alternative" ariaLabel="Select year" (click)="viewSelected.emit('multi-year')">
      <span class="pearl-header-button-content" [class.active]="activeView() === 'multi-year'">
        <span>{{ yearLabel() }}</span> <pearl-icon icon="down" [size]="20" />
      </span>
    </pearl-button>
  }
  @if (showDateButton()) {
    <pearl-button type="alternative" ariaLabel="Select month and date" (click)="viewSelected.emit('month')">
      <span class="pearl-header-button-content" [class.active]="activeView() === 'month' || activeView() === 'year'"
        ><span>{{ dateLabel() }}</span> <pearl-icon icon="down" [size]="20"
      /></span>
    </pearl-button>
  }
  @if (showTimeButton()) {
    <pearl-button type="alternative" ariaLabel="Select date and time" (click)="viewSelected.emit('hour')">
      <span class="pearl-header-button-content" [class.active]="activeView() === 'hour' || activeView() === 'minute'"
        ><span>{{ hourLabel() }}</span> <pearl-icon icon="down" [size]="20"
      /></span>
    </pearl-button>
  }
  @if (showTimezoneButton()) {
    <pearl-button type="alternative" ariaLabel="Select timezone" (click)="viewSelected.emit('timezone')">
      <span class="pearl-header-button-content" [class.active]="activeView() === 'timezone'"
        ><span>{{ timezoneLabel() }}</span> <pearl-icon icon="down" [size]="20"
      /></span>
    </pearl-button>
  }
</div>
@if (showControlButtons()) {
  <div class="pearl-header-controls">
    <pearl-button icon="chevron_left" type="icon" size="small" (click)="controlClicked.emit('previous')" />
    <pearl-button icon="chevron_right" type="icon" size="small" (click)="controlClicked.emit('next')" />
  </div>
}
