import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { Provider } from '@angular/core';

import dayjs, { Dayjs } from 'dayjs';

const DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'YYYY-MM-DD HH:mm',
  },
  display: {
    dateInput: 'YYYY-MM-DD HH:mm',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export function provideDayjsDateAdapter(): Provider[] {
  return [{
    provide: DateAdapter,
    useClass: DayjsDateAdapter,
  }, {
    provide: MAT_DATE_FORMATS,
    useValue: DATE_FORMATS,
  }];
}

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
  const valuesArray = Array<T>(length);
  for (let i = 0; i < length; i++) {
    valuesArray[i] = valueFunction(i);
  }
  return valuesArray;
}

/** Adapts Dayjs Dates for use with Angular Material. */
export class DayjsDateAdapter extends DateAdapter<Dayjs> {
  private localeData: {
    firstDayOfWeek: number;
    dates: string[];
    longMonths: string[];
    shortMonths: string[];
    longDaysOfWeek: string[];
    shortDaysOfWeek: string[];
    narrowDaysOfWeek: string[];
  };

  constructor() {
    super();

    this.setLocale(dayjs.locale());
  }

  override setLocale(locale: string): void {
    super.setLocale(locale);

    const refDate = this.createDate(2024, 0, 1);
    const dayJsLocaleData = refDate.localeData();
    this.localeData = {
      firstDayOfWeek: dayJsLocaleData.firstDayOfWeek(),
      dates: range(31, i => refDate.date(i + 1).format('D')),
      longMonths: dayJsLocaleData.months(),
      shortMonths: dayJsLocaleData.monthsShort(),
      longDaysOfWeek: range(7, i => refDate.day(i).format('dddd')),
      shortDaysOfWeek: dayJsLocaleData.weekdaysShort(),
      narrowDaysOfWeek: dayJsLocaleData.weekdaysMin(),
    };
  }

  getYear(date: Dayjs): number {
    return date.year();
  }

  getMonth(date: Dayjs): number {
    return date.month();
  }

  getDate(date: Dayjs): number {
    return date.date();
  }

  getDayOfWeek(date: Dayjs): number {
    return date.day();
  }

  getMonthNames(style: 'long' | 'short' | 'narrow'): string[] {
    return style === 'long' ? this.localeData.longMonths : this.localeData.shortMonths;
  }

  getDateNames(): string[] {
    return this.localeData.dates;
  }

  getDayOfWeekNames(style: 'long' | 'short' | 'narrow'): string[] {
    if (style === 'long') {
      return this.localeData.longDaysOfWeek;
    }
    if (style === 'short') {
      return this.localeData.shortDaysOfWeek;
    }
    return this.localeData.narrowDaysOfWeek;
  }

  getYearName(date: Dayjs): string {
    return date.format('YYYY');
  }

  getFirstDayOfWeek(): number {
    return this.localeData.firstDayOfWeek;
  }

  getNumDaysInMonth(date: Dayjs): number {
    return date.daysInMonth();
  }

  clone(date: Dayjs): Dayjs {
    return date.clone();
  }

  createDate(year: number, month: number, date: number): Dayjs {
    return this.today()
      .year(year)
      .month(month)
      .date(date);
  }

  today(): Dayjs {
    return dayjs();
  }

  parse(value: unknown, parseFormats: string[] = []): Dayjs | null {
    if (typeof value !== 'string') {
      return null;
    }

    return dayjs(value, parseFormats);
  }

  format(date: Dayjs, displayFormat: string): string {
    return date.format(displayFormat);
  }

  addCalendarYears(date: Dayjs, years: number): Dayjs {
    return date.add(years, 'year');
  }

  addCalendarMonths(date: Dayjs, months: number): Dayjs {
    return date.add(months, 'month');
  }

  addCalendarDays(date: Dayjs, days: number): Dayjs {
    return date.add(days, 'day');
  }

  toIso8601(date: Dayjs): string {
    return date.toISOString();
  }

  isDateInstance(obj: unknown): boolean {
    return dayjs.isDayjs(obj);
  }

  isValid(date: Dayjs): boolean {
    return date.isValid();
  }

  invalid(): Dayjs {
    return dayjs(null);
  }
}
