import { Component, signal } from '@angular/core';
import { NgIf } from '@angular/common';

import { WrapperComponent } from '../../../pages/component-wrapper';
import { AfterSave, KpiData, RefreshType } from '../../../helpers/types';
import { KpisComponent } from '../kpis';
import { DoubleDateComponent } from '../../../filters';
import { KpisComponentSettings } from '../../../helpers/config-types';

@Component({
  selector: 'kpi-wrapper',
  templateUrl: 'kpi-wrapper.component.html',
  standalone: true,
  imports: [NgIf, KpisComponent, DoubleDateComponent],
})
export class KpiWrapperComponent extends WrapperComponent<KpisComponentSettings> {
  public readonly data = signal<KpiData | undefined>(undefined);
  public readonly isDataLoading = signal<boolean>(false);

  public async reloadData(_: AfterSave = null): Promise<void> {
    this.isDataLoading.set(true);
    const endpointData = await this.dataReloadFetch<KpiData>();
    const kpiData = Array.isArray(endpointData) ? endpointData[0] : endpointData;

    this.data.set(kpiData);
    this.isDataLoading.set(false);
  }

  public override async refresh(refreshType: RefreshType): Promise<void> {
    await super.refresh(refreshType);
    this.reloadData();
  }

  public viewInitialization(): void {}
}
