import { normalizePassiveListenerOptions } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, computed, inject, input,
  output, signal } from '@angular/core';

import dayjs, { Dayjs } from 'dayjs';

import { ClockView } from './datepicker-types';

const activeEventOptions = normalizePassiveListenerOptions({ passive: false });

export const CLOCK_RADIUS = 50;
export const CLOCK_INNER_RADIUS = 27.5;
export const CLOCK_OUTER_RADIUS = 41.25;
export const CLOCK_TICK_RADIUS = 7.0833;

interface ClockGraduationDetails {
  value: number;
  displayValue: string;
  style: Record<string, string>;
  class?: Record<string, boolean>;
}

/**
 * Material time selector dial version
 * @see https://m3.material.io/components/time-pickers/
 *
 * Implementation based on https://github.com/ng-matero/extensions/blob/main/projects/extensions/datetimepicker/clock.ts
 */
@Component({
  selector: 'pearl-clock',
  templateUrl: './clock.html',
  styleUrl: './clock.scss',
  host: {
    'class': 'pearl-clock',
    'role': 'clock',
    '(mousedown)': 'pointerDown($event)',
    '(touchstart)': 'pointerDown($event)',
    '(mousemove)': 'highlightMinute($event)',
    '(touchmove)': 'highlightMinute($event)',
  },
  exportAs: 'pearlClock',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PearlClockComponent implements OnInit, OnDestroy {
  public readonly selected = input<Dayjs>();

  /** Step in minutes. */
  public readonly step = input<number>(1);

  /** Emits when the currently selected date changes. */
  public readonly selectedChange = output<Dayjs>();
  /** Emits when the current view changes */
  public readonly viewChange = output<ClockView>();

  private document = inject(DOCUMENT);
  private elementRef = inject(ElementRef);
  private changeDetectorRef = inject(ChangeDetectorRef);

  protected readonly view = signal<ClockView>('hour');
  protected readonly activeDate = signal<Dayjs>(dayjs());

  protected readonly activeHour = computed<number>(() => this.activeDate().hour());
  protected readonly activeMinute = computed<number>(() => this.activeDate().minute());

  protected readonly hoveredMinute = signal<number | null>(null);

  protected readonly hours: ClockGraduationDetails[] = [];
  protected readonly minutes: ClockGraduationDetails[] = [];

  private timeChanged = false;

  constructor() {
    // Create hours to display on the clock
    for (let i = 1; i <= 24; i++) {
      const angle = (i / 6) * Math.PI;
      const isOnOuterRing = i < 13;
      const radius = isOnOuterRing ? CLOCK_OUTER_RADIUS : CLOCK_INNER_RADIUS;

      const h = i === 24 ? 0 : i;

      this.hours.push({
        value: h,
        displayValue: h === 0 ? '00' : h.toString(),
        style: {
          top: `${CLOCK_RADIUS - Math.cos(angle) * radius - CLOCK_TICK_RADIUS}%`,
          left: `${CLOCK_RADIUS + Math.sin(angle) * radius - CLOCK_TICK_RADIUS}%`,
          fontSize: isOnOuterRing ? '' : '80%',
        },
      });
    }

    // Create minutes to display on the clock
    for (let i = 0; i < 60; i++) {
      const angle = (i / 30) * Math.PI;
      const showOnDial = i % 5 === 0;

      this.minutes.push({
        value: i,
        displayValue: i.toString(),
        style: {
          top: `${CLOCK_RADIUS - Math.cos(angle) * CLOCK_OUTER_RADIUS - CLOCK_TICK_RADIUS}%`,
          left: `${CLOCK_RADIUS + Math.sin(angle) * CLOCK_OUTER_RADIUS - CLOCK_TICK_RADIUS}%`,
        },
        class: { 'show-on-dial': showOnDial },
      });
    }
  }

  ngOnInit(): void {
    if (this.selected()) {
      this.activeDate.set(this.selected());
    }
  }

  protected readonly handStyle = computed<Record<string, string>>(() => {
    let radius = CLOCK_OUTER_RADIUS;
    let angle: number;

    if (this.view() === 'hour') {
      if (this.activeHour() === 0 || this.activeHour() >= 13) radius = CLOCK_INNER_RADIUS;
      angle = Math.round(this.activeHour() * (360 / 12));
    } else {
      angle = Math.round(this.activeMinute() * (360 / 60));
    }

    return {
      height: `${radius}%`,
      marginTop: `${50 - radius}%`,
      transform: `rotate(${angle}deg)`,
    };
  });

  ngOnDestroy(): void {
    this.removeGlobalEvents();
  }

  /** Called when the user has put their pointer down on the clock. */
  private pointerDown = (event: TouchEvent | MouseEvent): void => {
    this.timeChanged = false;
    this.setTime(event);
    this.bindGlobalEvents(event);
  };

  /**
   * Called when the user has moved their pointer after
   * starting to drag. Bound on the document level.
   */
  private pointerMove = (event: TouchEvent | MouseEvent): void => {
    if (event.cancelable) {
      event.preventDefault();
    }
    this.setTime(event);
    this.changeDetectorRef.detectChanges();
  };

  /** Called when the user has lifted their pointer. Bound on the document level. */
  private pointerUp = (event: TouchEvent | MouseEvent): void => {
    if (event.cancelable) {
      event.preventDefault();
    }
    this.removeGlobalEvents();

    if (this.timeChanged) {
      if (this.view() === 'hour') {
        this.changeView('minute');
      } else {
        this.selectedChange.emit(this.activeDate());
      }
    }
  };

  /** Binds our global move and end events. */
  private bindGlobalEvents(triggerEvent: TouchEvent | MouseEvent): void {
    /*
     * Note that we bind the events to the `document`, because it allows us to capture
     * drag cancel events where the user's pointer is outside the browser window.
     */
    const isTouch = isTouchEvent(triggerEvent);
    const moveEventName = isTouch ? 'touchmove' : 'mousemove';
    const endEventName = isTouch ? 'touchend' : 'mouseup';

    this.document.addEventListener(moveEventName, this.pointerMove, activeEventOptions);
    this.document.addEventListener(endEventName, this.pointerUp, activeEventOptions);

    if (isTouch) {
      this.document.addEventListener('touchcancel', this.pointerUp, activeEventOptions);
    }
  }

  /** Removes any global event listeners that we may have added. */
  private removeGlobalEvents(): void {
    this.document.removeEventListener('mousemove', this.pointerMove, activeEventOptions);
    this.document.removeEventListener('mouseup', this.pointerUp, activeEventOptions);
    this.document.removeEventListener('touchmove', this.pointerMove, activeEventOptions);
    this.document.removeEventListener('touchend', this.pointerUp, activeEventOptions);
    this.document.removeEventListener('touchcancel', this.pointerUp, activeEventOptions);
  }

  private changeView(view: ClockView): void {
    this.view.set(view);
    this.viewChange.emit(view);
  }

  /**
   * Updates the time when the user selects an hour or minute.
   * @param event The click or touch event
   */
  private setTime(event: TouchEvent | MouseEvent): void {
    const value = this.getValueUnderCursor(event);

    this.timeChanged = true;
    this.activeDate.set(
      this.view() === 'hour' ? this.createDateFromTime(value) : this.createDateFromTime(undefined, value),
    );
  }

  /** Called when the user moves the cursor to display the minute */
  private highlightMinute = (event: TouchEvent | MouseEvent): void => {
    if (this.view() !== 'minute') {
      return;
    }

    this.hoveredMinute.set(this.getValueUnderCursor(event));
    this.changeDetectorRef.detectChanges();
  };

  private getValueUnderCursor(event: TouchEvent | MouseEvent): number {
    const trigger = this.elementRef.nativeElement as HTMLElement;
    const triggerRect = trigger.getBoundingClientRect();
    const elementWidth = trigger.offsetWidth;
    const elementHeight = trigger.offsetHeight;

    const { pageX, pageY } = getClickPosition(event);
    // Coordinates of the click event relative to the clock container top left
    const clickXInElement = pageX - triggerRect.left - window.scrollX;
    const clickYInElement = pageY - triggerRect.top - window.scrollY;

    // Coordinates of the click event relative to the clock center
    const x = clickXInElement - elementWidth / 2;
    const y = elementHeight / 2 - clickYInElement;

    // Angle with the Y axis
    let angle = Math.atan2(x, y);
    // Use only positive angles
    if (angle < 0) {
      angle += 2 * Math.PI;
    }

    // Number of selectable values
    const numberOfSteps = this.view() === 'hour' ? 12 : 60 / this.step();
    // Angle between two consecutive values
    const stepAngle = (2 * Math.PI) / numberOfSteps;

    let value = Math.round(angle / stepAngle);

    // Distance of click from the origin (center of the clock)
    const distance = Math.sqrt(x ** 2 + y ** 2);

    const isOnOuterRing = this.view() === 'hour'
      && distance > (elementWidth * (CLOCK_OUTER_RADIUS / 100) + elementWidth * (CLOCK_INNER_RADIUS / 100)) / 2;

    if (this.view() === 'hour') {
      // Hours from 1 to 12 on outer ring
      if (value === 0) value = 12;

      // Hours from 13 to 00 on inner ring
      if (!isOnOuterRing) {
        value += 12;
        if (value === 24) value = 0;
      }
    } else {
      // Minutes from 0 to 59
      value *= this.step();
      if (value === 60) value = 0;
    }

    return value;
  }

  private createDateFromTime(hours?: number, minutes?: number): Dayjs {
    return (this.selected() ?? this.activeDate())
      .hour(hours ?? this.activeDate().hour())
      .minute(minutes ?? this.activeDate().minute())
      .second(0)
      .millisecond(0);
  }
}

function isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
  /*
   * This function is called for every pixel that the user has dragged so we need it to be
   * as fast as possible. Since we only bind mouse events and touch events, we can assume
   * that if the event's name starts with `t`, it's a touch event.
   */
  return event.type[0] === 't';
}

/** Gets the coordinates of a touch or mouse event relative to the document. */
function getClickPosition(event: MouseEvent | TouchEvent): { pageX: number; pageY: number } {
  // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
  return isTouchEvent(event) ? event.touches[0] || event.changedTouches[0] : event;
}
