import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';

import { safeString } from '../helpers/data-helpers';
import { ChartSelect, SelectableGroup, SelectableValue } from '../graph/chart-types';
import { AppInfoService } from '../app/app-info-service';
import { StringHelper } from '../helpers/string-helper';
import { PearlFormFieldComponent } from '../shared/pearl-components';

@Component({
  selector: 'spin-filters-select',
  templateUrl: 'select.html',
  styleUrl: 'select.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    PearlFormFieldComponent,
    NgFor,
  ],
})
export class SelectComponent {
  public _value: string | string[];
  private _field: ChartSelect;
  private appInfoService = inject(AppInfoService);

  /** Grouped values (displayed first) */
  public valueGroups: SelectableGroup[] = [];
  /** Single values */
  public singleValues: SelectableValue[] = [];

  @Output()
  valueChange = new EventEmitter<string>();
  @Output()
  onchange = new EventEmitter<string>();

  @Input()
  id: string = null;
  @Input()
  minSelectable?: number; // index from which the elements are not disabled (if defined)
  // Allow multiple selection
  @Input()
  multiple: boolean;
  @Input()
  message?: string; // message to display (like when max number of options reached, or dropdown is disabled)

  @Input({ required: true })
  set field(field: ChartSelect) {
    this._field = field;

    /** Build option groups */
    const groups: { [title: string]: SelectableGroup } = {};
    field.values.forEach((value: SelectableValue) => {
      if (!value.groupTitle) return;
      groups[value.groupTitle] ??= { title: value.groupTitle, values: [] };
      groups[value.groupTitle].values.push(value);
    });
    this.valueGroups = Object.values(groups);
    this.singleValues = field.values.filter((value: SelectableValue) => !value.groupTitle);
  }

  get field(): ChartSelect {
    return this._field;
  }

  @Input()
  set value(val: string) {
    this._value = this.multiple
      ? (val ? StringHelper.splitByCommas(val).map(v => v?.toString()) : [])
      : val?.toString();
  }

  get value(): string {
    return this.multiple ? (this._value as string[]).join(',') : this._value as string;
  }

  public onbubblingchange(): void {
    this.appInfoService.userAction(this.id);
    this.onchange.emit(this.value);
  }

  /**
   * Option disabled if item is or in minSelectable is set (only apply to ungrouped items)
   */
  public isDisabled(item: SelectableValue, index?: number): boolean {
    const originalItem = this.field.values.find(v => v.value === item.value);
    return originalItem.disabled
      || (this.minSelectable !== undefined && index !== undefined && index < this.minSelectable);
  }

  /**
   * Returns the value of a selected item .
   */
  public getItemValue(item: SelectableValue): string | null {
    // If the item has a value, return it as a safe string
    const value = item.id ?? item.value;
    if (value || value == '') {
      return safeString(value);
    }

    // If there is no value, return null
    return null;
  }
}
