<div class="comparison-select" *ngIf="componentSettings.comparisonConfig">
  <spin-filters-doubledate
    #comparisonComponent
    [field]="componentSettings.comparisonConfig"
    (onchange)="onComparisonPeriodChanged($event)"
    [disabled]="comparisonPeriodDisabled" />
  <div *ngIf="getComparedPeriods() as periods" class="period-str">
    <span> {{ intervalToString(periods[0]) }} </span>
    <div class="previous">prev. {{ intervalToString(periods[1]) }}</div>
  </div>
</div>
<kpis
  (onaction)="onaction($event)"
  [data]="data()"
  [isDataLoading]="isDataLoading()"
  [componentSettings]="componentSettings" />
