import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, ViewEncapsulation } from '@angular/core';
import { NgClass } from '@angular/common';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';

import { AdvancedFilteringModel } from './advanced-filtering-model';
import { DeepReadonly, FieldOrdering, FieldSettings, HasVesselId, OptionValue } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { DataHelpers } from '../helpers/data-helpers';
import { PearlAutocompleteComponent, PearlButtonComponent, PearlFormFieldComponent } from '../shared/pearl-components';

interface WTIObligatoryParameters {
  nacelleWeight: number;
  nacelleHeight: number;
  turbineHubHeight: number;
  wtiTurbineCapacity: number;
  turbineModel: number;
  waterDepth: number;
}

interface WTIOptionalParameters {
  safetyFactor: number;
  weightInaccuracy: number;
  riggingWeight: number;
  riggingLengthHook: number;
  nacelleTowerClearance: number;
  averageWindSpeed: number;
  legPenetrationIntoSeabed: number;
  minRequiredAirGap: number;
}

type WTIParameters = WTIObligatoryParameters & WTIOptionalParameters;

interface WTIQuery {
  wtiParameters: WTIParameters;
}

interface WTICalculatedParameters {
  requiredCraneCapacity?: number;
  requiredHookHeightSeaLevel?: number;
  nacelleHeight?: number;
  nacelleWeight?: number;
  turbineHubHeight?: number;
  nacelleWeightEstimated?: boolean;
  nacelleHeightEstimated?: boolean;
  turbineHubHeightEstimated?: boolean;
}

interface WTIJackupRequiredParameters {
  requiredCraneCapacity: number;
  requiredHookHeightSeaLevel: number;
}

interface WTIResults {
  jackupRequiredParameters: WTIJackupRequiredParameters;
  turbineParameters: WTITurbineParameters;
  vessels: { [vesselFeatureId: number]: any };
}

/**
 * WTI Model results as provided by the server
 */
interface WTIModelResults {
  vessels: { vessel_feature_id: number }[];
  jackupRequiredParameters: WTIJackupRequiredParameters;
  turbineParameters: WTITurbineParameters;
}

interface WTITurbineParameters {
  nacelleHeight: number;
  nacelleWeight: number;
  hubHeight: number;
  isEstimatedNacelleWeight: boolean;
  isEstimatedNacelleHeight: boolean;
  isEstimatedHubHeight: boolean;
}

interface WTITurbineCapacityAndModel {
  models: OptionValue[];
  capacities: number[];
}

type ObligatoryParametersModel = 'turbineCapacity' | 'turbineModel' | 'heightAndWeight';

@Component({
  selector: 'wti-vessel-selector',
  templateUrl: 'wti-vessel-selector.html',
  styleUrls: ['advanced-filtering-model.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PearlButtonComponent,
    PearlAutocompleteComponent,
    FormsModule,
    PearlFormFieldComponent,
    NgClass,
    MatProgressSpinner,
    MatButtonToggleModule,
    MatInputModule,
  ],
})
export class WTIVesselSelectorComponent
  extends AdvancedFilteringModel<WTIObligatoryParameters, WTIOptionalParameters, WTICalculatedParameters, WTIResults>
{
  public obligatoryParametersPick: ObligatoryParametersModel = 'turbineCapacity';
  public turbineModels: DeepReadonly<OptionValue[]>;
  public turbineCapacities: DeepReadonly<number[]>;

  public static wtiSelectorPopulateUrl = '/spindjango/wti-populate-model-capacity';
  public static wtiVesselSelectorUrl: string = '/spindjango/wti';
  public static wtiVesselListUrl: string = '/spindjango/wti-vessels';

  public override fieldOrdering: FieldOrdering = {
    propOrder: 'meetRequirements',
    orderDirection: 'desc',
  };

  constructor(injector: Injector) {
    super(injector);
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  public async loadFiltersConfig(): Promise<void> {
    const results = await this.dataLoader.get<WTITurbineCapacityAndModel>(
      WTIVesselSelectorComponent.wtiSelectorPopulateUrl,
    );
    this.turbineCapacities = results.capacities;
    this.turbineModels = results.models;

    if (this.obligatoryParameters.wtiTurbineCapacity) {
      this.obligatoryParametersPick = 'turbineCapacity';
    } else if (this.obligatoryParameters.turbineModel) {
      this.obligatoryParametersPick = 'turbineModel';
    } else if (this.obligatoryParameters.turbineHubHeight && this.obligatoryParameters.nacelleWeight) {
      this.obligatoryParametersPick = 'heightAndWeight';
    }
  }

  public modelChanged(turbineModelId: number): void {
    if (turbineModelId) {
      this.obligatoryParameters.turbineModel = +turbineModelId;
      this.valueChange();
    }
  }

  public prepareParameters(): WTIParameters {
    const optionalParameters = this.optionalParametersToApply();
    /*
     * We only emit parameters corresponding to the selected model otherwise the spindata script which parameter
     * to choose if the information is contradictory
     */
    const params: WTIParameters = {
      wtiTurbineCapacity: this.obligatoryParametersPick === 'turbineCapacity'
        ? this.obligatoryParameters.wtiTurbineCapacity
        : null,
      turbineModel: this.obligatoryParametersPick === 'turbineModel' ? this.obligatoryParameters.turbineModel : null,
      turbineHubHeight: this.obligatoryParametersPick === 'heightAndWeight'
        ? this.obligatoryParameters.turbineHubHeight
        : null,
      nacelleWeight: this.obligatoryParametersPick === 'heightAndWeight'
        ? this.obligatoryParameters.nacelleWeight
        : null,
      nacelleHeight: this.obligatoryParametersPick === 'heightAndWeight'
        ? this.obligatoryParameters.nacelleHeight
        : null,
      waterDepth: this.obligatoryParameters.waterDepth,
      ...optionalParameters,
    };
    return params;
  }

  public get nacelleHeightField(): FieldSettings {
    return this.getObligatoryField('nacelleHeight');
  }

  public get nacelleWeightField(): FieldSettings {
    return this.getObligatoryField('nacelleWeight');
  }

  public get turbineHubHeightField(): FieldSettings {
    return this.getObligatoryField('turbineHubHeight');
  }

  public get waterDepthField(): FieldSettings {
    return this.getObligatoryField('waterDepth');
  }

  public get wtiTurbineCapacityField(): FieldSettings {
    return this.getObligatoryField('wtiTurbineCapacity');
  }

  public get turbineModelField(): FieldSettings {
    return this.getObligatoryField('turbineModel');
  }

  public async getModelVessels(): Promise<readonly HasVesselId[]> {
    return await this.dataLoader.get<HasVesselId[]>(WTIVesselSelectorComponent.wtiVesselListUrl);
  }

  public checkModelCoherency(): boolean {
    // water depth is alway required and has to fill in required range
    if (this.fieldRangeInvalid(this.waterDepthField, this.obligatoryParameters.waterDepth)) {
      return false;
    }

    // Other obligatory parameters depend on obligatory parameters pick by user
    if (
      this.obligatoryParametersPick === 'heightAndWeight'
      && (
        this.fieldRangeInvalid(this.turbineHubHeightField, this.obligatoryParameters.turbineHubHeight)
        || this.fieldRangeInvalid(this.nacelleWeightField, this.obligatoryParameters.nacelleWeight)
        || this.fieldRangeInvalid(this.nacelleHeightField, this.obligatoryParameters.nacelleHeight)
      )
    ) {
      return false;
    }

    if (
      this.obligatoryParametersPick === 'turbineCapacity'
      && this.fieldRangeInvalid(this.wtiTurbineCapacityField, this.obligatoryParameters.wtiTurbineCapacity)
    ) {
      return false;
    }

    if (
      this.obligatoryParametersPick === 'turbineModel'
      && this.fieldRangeInvalid(this.turbineModelField, this.obligatoryParameters.turbineModel)
    ) {
      return false;
    }

    if (!this.checkAdvancedParameters()) {
      return false;
    }

    return true;
  }

  public updateCalculatedParameters(results: WTIResults): void {
    if (!results) {
      return;
    }
    const jackupParams = results.jackupRequiredParameters;
    const turbineParams = results.turbineParameters;
    this.calculatedParameters = {
      requiredCraneCapacity: jackupParams.requiredCraneCapacity,
      requiredHookHeightSeaLevel: jackupParams.requiredHookHeightSeaLevel,
      nacelleHeight: turbineParams.nacelleHeight,
      turbineHubHeight: turbineParams.hubHeight,
      nacelleWeight: turbineParams.nacelleWeight,
      nacelleHeightEstimated: turbineParams.isEstimatedNacelleHeight,
      nacelleWeightEstimated: turbineParams.isEstimatedNacelleWeight,
      turbineHubHeightEstimated: turbineParams.isEstimatedHubHeight,
    };

    this.formatCalculatedParameters();
    this.cdRef.detectChanges();
  }

  public async runModel(): Promise<WTIResults> {
    const wtiVesselScoreParameters = {
      wtiParameters: this.savedParameters,
    };
    const serverQuery = this.dataLoader.post<WTIQuery, WTIModelResults>(
      WTIVesselSelectorComponent.wtiVesselSelectorUrl,
      wtiVesselScoreParameters,
    );

    this.currentServerRequest = serverQuery;
    return serverQuery.then(serverResult => {
      const vesselsByFeatureIdDictionary = DataHelpers.toDict(serverResult.vessels, d => d[this.idProp]);
      const result: WTIResults = {
        ...serverResult,
        vessels: vesselsByFeatureIdDictionary,
      };
      return result;
    });
  }
}
