import { isString } from 'lodash-es';

import { ErrorWithFingerprint } from 'src/helpers/sentry.helper';
import { RefDataProvider } from './ref-data-provider';
import { FilterHelper } from '../filters/filter-helper';
import { ComponentParameter } from 'src/helpers/types';

const LOCAL_URL_DATASET_REGEX = /@local<(\w+)>(\w+)(?:\?(.*))?/;
const VESSEL_FLEET_NAME = 'userVesselFleet';

export interface LocalRequestInfo {
  /** If true, we only wan to load a single item. Otherwise, we want an array. */
  singleItemWanted: boolean;
  datasetName: string;
  filters: string;
}

/** @param convertUserFleetToVessel: if true, the dataset 'userVesselFleet' will be converted to 'vessel' */
export function getLocalRequestInfo(localUrl: string, convertUserFleetToVessel = false): LocalRequestInfo {
  const match = localUrl.match(LOCAL_URL_DATASET_REGEX);
  if (!match) return null;
  if (match[1] !== 'item' && match[1] !== 'items') {
    console.error(
      new ErrorWithFingerprint(
        `Provided local url "${localUrl}" is not of valid type`,
        ['local-loader-error-invalid-url-type'],
      ),
    );
  }
  const datasetName = match[2];
  return {
    singleItemWanted: match[1] === 'item',
    datasetName: convertUserFleetToVessel && datasetName === VESSEL_FLEET_NAME ? 'vessel' : datasetName,
    filters: match[3],
  };
}

/** Match words (denoting access to property), also capturing surrounding quotes, if some */
const PROPERTY_ACCESS_REGEX = /(?:"|')?\w+(?:"|')?/g;

/**
 * From provided filter expression (e.g 'hasInstallation&&countryId!='cn'&&yearBuilt>=26), return a function taking
 * a single argument and returning whether provided condition match or not.
 * Provided filter should only use valid JS operations (&&, ||, <, <=, >, >=, ==)
 * String literals must be quoted to not be interpreted as an accessed property
 */
function createFilterFunction(filters: string): (value: object) => boolean {
  if (!filters.length) return () => true;
  const propertyAccessInjected = filters.replace(PROPERTY_ACCESS_REGEX, (match: string): string => {
    /** Ignore strings */
    if ((match[0] === '"' || match[0] === "'") && match[0] === match[match.length - 1]) return match;
    /** Numeric expression matched: it's not a property access, ignore it */
    if (FilterHelper.isNumeric(match)) return match;
    /** Booleans should be interpret as such */
    if (match === 'true' || match === 'false') return match;
    return `item.${match}`;
  });
  try {
    return new Function('item', `return ${propertyAccessInjected}`) as (value: object) => boolean;
  } catch (e) {
    throw new ErrorWithFingerprint(
      `Error creating local filter function from "${filters}". Probably an error in config. `
        + `Original error: "${e.toString()}". Constructed function string is "${propertyAccessInjected}".`,
      ['local-filter-function-creation-error'],
    );
  }
}

/**
 * Takes an object referenced as a cache and returns an array of filtered data from it
 * The cache arguments is any object but the url is very specific its format is
 * vessel?key1=='any-string'||key2==42&&k3!=null
 */
export function loadLocally(url: string): unknown {
  const { singleItemWanted, datasetName, filters } = getLocalRequestInfo(url);
  const refData = datasetName === VESSEL_FLEET_NAME
    ? RefDataProvider.userVesselFleet
    : RefDataProvider.refData[datasetName];
  if (refData === undefined) {
    throw new ErrorWithFingerprint(
      `Asked dataset "${datasetName}" not found in ref data for "${url}".`,
      ['local-loader-error-ref-data-does-not-exist', datasetName],
    );
  }
  const rawValues = Object.values(refData);
  if (filters === undefined) return rawValues;
  const filterFunction = createFilterFunction(filters);
  try {
    const filtered = rawValues.filter(v => filterFunction(v));
    if (!singleItemWanted) return filtered;
    else return filtered.length ? filtered[0] : null;
  } catch (err) {
    const toDisplay = singleItemWanted ? rawValues : Object.values(rawValues).map(x => x?.id).filter(x => x);
    console.error(
      new ErrorWithFingerprint(
        `Local loader error: ${err}. Searched values for dataset ${datasetName} are (ids): ${
          JSON.stringify(toDisplay)
        }`,
        ['local-loader-error', datasetName],
      ),
    );
    return singleItemWanted ? null : [];
  }
}

/** See https://regex101.com/r/snynHn/1 for explanation and example */
const INJECT_REGEX = /(?:\w+(?:>|<|=)=?)?(\[:(\w+):\](?:\[(\d)\])?)/g;
/**
 * Will inject provided parameters marked as [:var:] in the template URL if found in paramBag
 * e.g. 'test>=[:testValue:]' will replace [:testValue:] by paramBag.testValue
 * If the params is found, the expression is replaced by "false", because we don't want the condition to pass
 * If the param is marked as to be removed, we want to always pass the condition: we replace it with "true"
 * This also handles bracket access, i.e [:masterPeriod:][0] will return 5 if masterPeriod=5,6
 */
export function injectParamsInLocalUrl(
  templateUrl: string,
  params: ComponentParameter[] = [],
): string {
  if (!templateUrl) return templateUrl;
  const replacer = (fullMatch: string, partToReplace: string, paramName: string, indexAsStr?: string): string => {
    const param = params.find(p => p.name === paramName);
    if (!param) {
      console.warn(`local-loader: parameter ${paramName} because it not present in the parameters`);
      return 'false';
    }

    /* Parameters that should be removed */
    if (param.shouldBeRemovedFromUrl) return 'true';
    const value = param.value;

    // Incorrect config & param: param is an array and no indexer provided
    if (Array.isArray(value) && !indexAsStr) {
      throw new ErrorWithFingerprint(
        `local-loader: param is an array, but no indexer was provided "${paramName}"`,
        ['local-parameters-injection-of-array-without-index'],
      );
    }

    // Incorrect config & param: param is not an array, but indexer provided
    if (!Array.isArray(value) && indexAsStr) {
      throw new ErrorWithFingerprint(
        `local-loader: bracket access non-array argument for param "${paramName}"`,
        ['local-parameters-injection-wrong-bracket-access'],
      );
    }

    let targetValue = param.value;
    if (Array.isArray(value)) {
      const accessedIndex = parseInt(indexAsStr);
      if (accessedIndex >= value.length) {
        throw new ErrorWithFingerprint(
          `local-loader: param is an array, but indexer is out of bounds "${paramName}"`,
          ['local-parameters-injection-out-of-bound-index'],
        );
      }
      targetValue = value[accessedIndex];
    }

    /** Wrap around quotes */
    if (typeof targetValue === 'string' && isString(param.value)) targetValue = `"${targetValue}"`;

    /** We don't want to replace the whole match as we match the whole expression, but only inject the desired param */
    return fullMatch.replace(partToReplace, targetValue.toString());
  };
  return templateUrl.replace(INJECT_REGEX, replacer);
}
