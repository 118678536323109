import { Injector } from '@angular/core';

import { groupBy } from 'lodash-es';

import { PageStateService } from 'src/shared/services/page-state.service';
import { DataLoader } from 'src/data-loader/data-loader';
import { SelectorConfig } from '../selector/selector.types';
import { EntityFieldDefinition, FieldSettings, Fieldset, FieldsetUpdate, FilterConfig, LayerFilter,
  LayerId } from '../helpers/types';
import { AdvancedFieldsetCollection, FieldsetCollection } from './fieldsets-collection';
import { FilterHelper } from './filter-helper';
import { RefDataProvider } from 'src/data-loader/ref-data-provider';
import { RawDataPoint } from 'src/graph/chart-types';

/**
 * Holds 2 fieldsets-collections. Standard and advanced.
 * Provides methods to easily lookup and manipulate filters config.
 */
export class SidebarFieldsetsGroup {
  private readonly layerId: LayerId;
  private readonly advancedFiltersState: LayerFilter;
  private readonly extraFieldsetsCollection: FieldsetCollection;
  private pageStateService: PageStateService;
  private dataLoader: DataLoader;

  public standardFieldsets: FieldsetCollection;
  public advancedFieldsets: AdvancedFieldsetCollection[];
  /**
   * @deprecated Will be replaced by horizontal sidebar
   */
  public headerFieldsets: FieldsetCollection;

  public hasStandardFilters: boolean;

  /**
   * @deprecated
   */
  public hasHeaderFilters: boolean;

  constructor(
    injector: Injector,
    fieldsets: Fieldset[] = [],
    selectorsConfig: SelectorConfig[],
    extraFilters: EntityFieldDefinition[] = [],
  ) {
    this.extraFieldsetsCollection = new FieldsetCollection(injector, [{ fields: extraFilters }]);
    this.standardFieldsets = new FieldsetCollection(
      injector,
      fieldsets.filter(fieldset => !fieldset.position),
    );
    this.headerFieldsets = new FieldsetCollection(
      injector,
      fieldsets.filter(fieldset => fieldset.position === 'header'),
    );
    this.hasStandardFilters = this.standardFieldsets.fieldsets.length > 0;
    this.hasHeaderFilters = this.headerFieldsets.fieldsets.length > 0;
    this.advancedFieldsets = [];
    this.advancedFiltersState = {};
    this.pageStateService = injector.get(PageStateService);
    this.dataLoader = injector.get(DataLoader);

    if (selectorsConfig) {
      for (const selector of selectorsConfig) {
        if (selector) {
          const collection = new AdvancedFieldsetCollection(
            injector,
            selector,
            this.advancedFiltersState,
            this.standardFieldsets,
          );
          this.advancedFieldsets.push(collection);
        }
      }
    }
  }

  public getAllAdvancedFilters(): FieldSettings[] {
    const allAdvancedFilters: FieldSettings[] = [];

    // only those filters not applied in standard filters will be returned in the advancedFilters selection
    for (const advancedFieldset of this.advancedFieldsets) {
      for (const filterId in advancedFieldset.filterById) {
        if (filterId in this.standardFieldsets.filterById) {
          continue;
        }
        allAdvancedFilters[filterId] = advancedFieldset.filterById[filterId];
      }
    }
    return allAdvancedFilters;
  }

  /**
   * Takes applied filters (sent by the dashboard) and splits the according to their ids
   * to 2 groups: those applied in standard fieldsets and those applied in advanced filters
   */
  public splitSelectorFilters(
    filters: LayerFilter,
  ): { standardFilters: LayerFilter; advancedFilters: LayerFilter } {
    const standardFieldIds = this.standardFieldsets.getAllFilterIds();
    const advancedFieldIds: string[] = [];

    // only those filters not applied in standard filters will be returned in the advancedFilters selection
    for (const advancedFieldset of this.advancedFieldsets) {
      advancedFieldIds.push(...advancedFieldset.getAllFilterIdsExceptThoseInOtherCollection(this.standardFieldsets));
    }

    const standardFilters = FilterHelper.getFiltersMatchingIds(filters, standardFieldIds);
    const advancedFilters = FilterHelper.getFiltersMatchingIds(filters, advancedFieldIds);

    return { standardFilters, advancedFilters };
  }

  /**
   * Returns the view-model for the selector if there is only one selector
   * otherwise return null;
   */
  public mainAdvancedFilters(): AdvancedFieldsetCollection {
    if (this.advancedFieldsets.length !== 1) {
      return null;
    }
    return this.advancedFieldsets[0];
  }

  /**
   * Finds the filter inside fieldsets that are part of this fieldsets-group (advanced or standard)
   */
  public findFilter(filterId: string): FieldSettings {
    for (const fieldsetCollection of this.allFieldsetsCollections) {
      const filter = fieldsetCollection.findFilter(filterId);
      if (filter) {
        return filter;
      }
    }
  }

  private get allFieldsetsCollections(): FieldsetCollection[] {
    return [this.standardFieldsets, ...this.advancedFieldsets, this.headerFieldsets, this.extraFieldsetsCollection];
  }

  public getAllFilters(): FieldSettings[] {
    return this.allFieldsetsCollections.flatMap(d => d.filters);
  }

  /**
   * Finds all filters inside all fieldsets (standard/advanced)
   * matching the passed predicate
   */
  public findFiltersBy(predicate: (filter: FieldSettings) => boolean): FieldSettings[] {
    return this.getAllFilters().filter(predicate);
  }

  /**
   * Find filter config, look in advanced filters only
   */
  public findFilterInAdvanced(filterId: string): FieldSettings | undefined {
    for (const advancedFieldset of this.advancedFieldsets) {
      const filter = advancedFieldset.findFilter(filterId);
      if (filter) {
        return filter;
      }
    }
    return undefined;
  }

  public resetFilters(): void {
    this.advancedFieldsets.forEach(advanced => advanced.setFilters({}));
  }

  /**
   * Used to pre-filter the selector by the entities actually available on the dashboard data. This is done by getting
   * the populate values of the field corresponding to the selector entity.
   * @param vesselField can also be the entity field whether we are in vessel layer with special vessel field
   * @returns an empty Set if entity field not found or without populate values, otherwise a Set with populate values.
   */
  public getEntityIdsFromSidebarPopulateValues(
    selectorConfig: SelectorConfig,
    vesselField?: FieldSettings,
  ): Set<number> {
    const entityIds = new Set<number>([]);
    // retrieve entity field config
    let entityField = this.standardFieldsets.findFilter(selectorConfig?.entity?.filterId);

    if (!entityField && vesselField.id === selectorConfig.entity.filterId) {
      entityField = vesselField;
    }

    if (entityField) {
      entityField.values?.forEach(v => entityIds.add(v.value as number));
    }
    return entityIds;
  }

  /**
   * Finds a filter's config by it's ID in multiple SidebarFieldsetsGroup
   */
  public static findInMultipleSidebars(filterId: string, sidebarFieldsetGroups: SidebarFieldsetsGroup[]): FilterConfig {
    for (const sidebarFieldsetGroup of sidebarFieldsetGroups) {
      const filter = sidebarFieldsetGroup?.findFilter(filterId);
      if (filter) {
        return filter;
      }
    }
    return null;
  }

  /**
   * Takes applied filters and sets their state into all advanced-filters (selectors).
   * That are part of this sidebar-fieldsets-group.
   */
  public setAdvancedFilters(filters: LayerFilter): void {
    for (const advancedFieldset of this.advancedFieldsets) {
      advancedFieldset.setFilters(filters);
    }
  }

  /**
   * Takes a shallow snapshot of current applied advanced filters to be used for filtering.
   * Layer filter holds AppliedFilters which should be read-only
   */
  public getAdvancedFiltersStateSnapshot(): LayerFilter {
    return { ...this.advancedFiltersState };
  }

  /**
   * For a given standard fieldset find a advanced-filters that are associated
   */
  public getSelector(fieldset: Fieldset): AdvancedFieldsetCollection {
    if (!fieldset.id) return null;
    return this.advancedFieldsets.find(selector => selector.selectorConfig.fieldsetId === fieldset.id);
  }

  public getFiltersNotInAdvanced(filters: LayerFilter): LayerFilter {
    const pureStandardFiltersIds: string[] = [];
    for (const filterId in filters) {
      if (
        this.advancedFieldsets.some(advanced => advanced.findFilter(filterId) || advanced.entityFilterId === filterId)
      ) {
        continue;
      }
      pureStandardFiltersIds.push(filterId);
    }
    return FilterHelper.getFiltersMatchingIds(filters, pureStandardFiltersIds);
  }

  /**
   * Heavy populate all filters that might have a populateUrl.
   * There are 2 ways of heavy filters:
   * - On the sidebar-level - we pass the populateUrl here as parameter, 2 endpoint types:
   *   -> Heavy analytics populate
   *   -> Custom heavy populate
   * - On a fieldset level (tender scenario)
   * Returns true if at least one filter was populated
   */
  public async heavyPopulateFilters(sidebarPopulateUrl: string): Promise<boolean> {
    const fullSidebarPopulated = await this.heavyPopulateSidebar(sidebarPopulateUrl);
    const fieldsetPopulated = await this.standardFieldsets.heavyPopulateFieldsets();
    return fieldsetPopulated || fullSidebarPopulated;
  }

  /**
   * Heavy populate the sidebar. It has 2 forms:
   * -> Heavy analytics populate:
   *    Heavy analytics endpoint returns the full equivalent of a fieldset
   * -> Custom heavy populate
   *    Any custom endpoint which returns a list of values
   * Returns true if anything was populated
   */
  public async heavyPopulateSidebar(populateUrl: string): Promise<boolean> {
    if (!populateUrl) return false;
    let fullUrl = populateUrl;
    let isHeavyPopulate = false;
    if (populateUrl.startsWith('/base/filters/analytics')) {
      isHeavyPopulate = true;
      const argSeparation = populateUrl.includes('?') ? '&' : '?';
      fullUrl = `${populateUrl}${argSeparation}_filtersPath=page/${this.pageStateService.pageType()}`;
    }

    /*
     * If forceUpdate is false, filters populated from calls to analytics won't populate
     * every other call
     */
    const populateResponse = await this.dataLoader.get<Fieldset[] | FieldsetUpdate>(fullUrl, { forceUpdate: true });

    if (isHeavyPopulate) {
      // Heavy populate returns the whole content of fieldset. Cf description of `populateUrl` in config-types.ts
      this.standardFieldsets.fieldsets = populateResponse as Fieldset[];
    } else {
      this.customHeavyPopulateFilters(populateResponse as RawDataPoint[]);
    }
    return true;
  }

  /**
   * Will populate filters from populate values returned from an endpoint.
   * This data has the same shape as "regular" data received for a component, except that it contains only
   * one property per object (e.g [{vesselId: 4}, {projectId: 4}, ...])
   * This function will populate "smartly" the filters, by taking the appropriate data depending on the populated
   * filter. For instance, a filter having "propValue" = "vessel.type" will only take objects with "vesselId" defined
   * to be populated.
   */
  public customHeavyPopulateFilters(data: RawDataPoint[]): void {
    const groupedByProperty = groupBy(data, d => Object.keys(d)[0]);
    this.pageStateService.storeIdsFromDataset(data, false);
    for (const filter of this.standardFieldsets.filters) {
      let valuesForPopulate = data;
      const chainedDataset = RefDataProvider.getChainedDatasets(filter.propValue)[0];
      if (chainedDataset && RefDataProvider.getKeyForDataset(chainedDataset) in groupedByProperty) {
        valuesForPopulate = groupedByProperty[RefDataProvider.getKeyForDataset(chainedDataset)];
      }
      FilterHelper.populateField(filter, valuesForPopulate, false);
    }
  }
}
