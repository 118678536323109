import { TooltipOptions } from './pearl-tooltip.types';

export class PearlTooltipUtils {
  /** Mouse offset for mouse move events */
  static readonly DEFAULT_MOUSE_OFFSET = 10;

  static lockMaxHeight(el: HTMLElement): void {
    const boundingRect = el.getBoundingClientRect();

    const maxAvailSpace = window.innerHeight - boundingRect.top;
    el.style.setProperty('max-height', `${maxAvailSpace - 20}px`);
  }

  static unlockMaxHeight(el: HTMLElement): void {
    el.style.setProperty('max-height', '95vh');
  }

  static getActualElementSize(el: HTMLElement): { width: number; height: number } {
    const elStyle = window.getComputedStyle(el);
    return {
      width: Number(elStyle.getPropertyValue('width').slice(0, -2)),
      height: Number(elStyle.getPropertyValue('height').slice(0, -2)),
    };
  }

  static getIdealPosition(
    origin: { x: number; y: number },
    width: number,
    height: number,
    offset: number = PearlTooltipUtils.DEFAULT_MOUSE_OFFSET,
  ): { x: number; y: number } {
    return {
      x: Math.max(offset, origin.x + ((origin.x + width > window.innerWidth) ? -(width + offset) : offset)),
      y: Math.max(offset, origin.y + ((origin.y + height > window.innerHeight) ? -(height + offset) : offset)),
    };
  }

  /** Post-validate options and provide an acceptable structure or throw */
  static validateOptions(options: TooltipOptions): TooltipOptions {
    if (options.kpis.length > 3) {
      console.error('More than 3 KPIs were provided. The first 3 were kept.');
    }

    return {
      ...options,
      kpis: options.kpis.slice(0, 3),
      actions: options.actions ?? { items: [] },
    };
  }
}
