import { AfterViewInit, ChangeDetectorRef, DestroyRef, Directive, EventEmitter, Host, Injector, Input, OnDestroy,
  OnInit, Output, ViewChild, inject } from '@angular/core';

import { cloneDeep } from 'lodash-es';
import dayjs from 'dayjs';

import { Config } from '../config/config';
import { FilterHelper, asParams } from '../filters/filter-helper';
import { FilterMetric, RawDataPoint } from '../graph/chart-types';
import { ActionEvent, AfterSave, CompleteExport, ComponentParameter, ComponentSettings, ComponentStateOptions,
  DeepReadonly, DynamicConfig, DynamicConfigParameters, FieldSettings, Fieldset, FilterApplied, FiltersApplied,
  FiltersState, FiltersStatePeriod, HeavyQuery, HeavyQueryBase, Interval, IntervalOrNull, PngExport, RefreshType,
  ResolvedInterval } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { TimezoneService } from '../helpers/timezone.service';
import { PopulatePageFiltersEvent } from './page-system-types';
import { PageStateService } from '../shared/services/page-state.service';
import { ComponentHelper } from '../helpers/component.helper';
import { DoubleDateComponent } from '../filters';
import { DateHelper } from '../helpers/date-helper';
import { NavigationHelper } from '../helpers/navigation-helper';

/**
 * Component wrapper, abstract parent class for all graph components
 */
@Directive()
export abstract class WrapperComponent<T extends ComponentSettings> implements AfterViewInit, OnInit, OnDestroy {
  public appConfig: Config;
  public cdRef: ChangeDetectorRef;
  public noRawData: boolean;
  public selectFilter: FilterMetric;
  public stateInitiated: boolean; /// < True if component has finished initializing from state
  public comparisonPeriodDisabled: boolean = false;
  // State of filter of only this component, mainly used for interval field and comparison interval
  public ownFiltersState: FiltersStatePeriod = {};

  protected _loading = true;

  public set isLoading(loading: boolean) {
    this._loading = loading;
  }

  public get isLoading(): boolean {
    return this._loading;
  }

  // Timezone
  @Host()
  public timezoneService: TimezoneService;

  protected readonly dataLoader = inject(DataLoader);
  protected readonly destroyRef = inject(DestroyRef);
  protected latestFilter: FilterApplied;
  protected injector: Injector;
  protected readonly pageStateService = inject(PageStateService);
  protected dynamicConfigHasChanged: boolean = false;
  /**
   * This holds the state of the filters that are linked to a modal if component (usually chart) is part of a modal.
   * In most cases modals do not have their on filters-state (they do not have any visual filters),
   * but modal can be linked to a single line in a dataset (single line in a table or KPI).
   * In that case it is linked to single vessel or, vessel and kpi - and the data that it shows has to be filtered.
   * So the vesselId will be stored inside modalComponentState.
   * It is the same as if there would be a visible filter on vessel available directly on the modal.
   */
  public modalComponentState: FiltersState;

  @Input()
  public onaction: (event: ActionEvent) => void;
  @Input()
  public dynamicConfigs: DynamicConfig[] = [];
  @Input()
  public componentSettings: T;
  @Input({ required: false })
  comparatorParameters: ComponentParameter[] = [];
  @Input({ required: false })
  comparatorFieldsets: Fieldset[] = [];
  @Input({ required: false })
  isComparatorComponent: boolean = false;
  @Input()
  configFiltersPath?: string;

  @Output()
  onexport = new EventEmitter<CompleteExport>();
  @Output()
  onexportpng = new EventEmitter<PngExport>();
  @Output()
  onComponentInit = new EventEmitter<string>();
  @Output()
  public onDataReceived = new EventEmitter<PopulatePageFiltersEvent>();
  @Output()
  componentSelect = new EventEmitter<ComponentStateOptions>();
  @Output()
  onreload = new EventEmitter<AfterSave>();

  @ViewChild('comparisonComponent', { static: false })
  private $comparisonComponent: DoubleDateComponent;

  /**
   * @constructor
   *
   * @param  {Injector} injector  Injector
   */
  constructor(injector: Injector) {
    this.injector = injector;
    this.appConfig = injector.get(Config);
    this.cdRef = injector.get(ChangeDetectorRef);
    this.timezoneService = injector.get(TimezoneService);
    this.timezoneService.name = this.constructor.name;
  }

  protected dataReloadFetch: <K = RawDataPoint | RawDataPoint[]>() => Promise<DeepReadonly<K | K[]>>;

  protected get isHeavyCustom(): boolean {
    return ('endpointType' in this.componentSettings) && this.componentSettings.endpointType === 'heavy-custom';
  }

  public get isLight(): boolean {
    return !this.isHeavyCustom;
  }

  /** Overridden by children component to get their state */
  protected get componentStateOptions(): ComponentStateOptions {
    const stateOptions: ComponentStateOptions = {};
    if (this.ownFiltersState['comparisonPeriod']) {
      stateOptions.comparisonPeriod = this.ownFiltersState['comparisonPeriod'] as ResolvedInterval;
    }
    return stateOptions;
  }

  /**
   * On init
   *
   * @return {void}
   */
  public ngOnInit(): void {
    if (['table', 'summary', 'map'].includes(this.componentSettings.type)) {
      // table/map/summary all handle timestamps locally
      this.timezoneService.timezoneConfig = { timezone: 'local' };
    } else {
      // standard case the component specifies it's config
      this.timezoneService.timezoneConfig = this.componentSettings.timezone
        ? { timezone: this.componentSettings.timezone }
        : {};
    }
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.dataReloadFetch = this.isHeavyCustom ? this.fetchCustomHeavyData : this.fetchLightData;
    this.cdRef.detectChanges();
  }

  protected async fetchLightData<K = RawDataPoint | RawDataPoint[]>(): Promise<DeepReadonly<K>> {
    if (!('endpoint' in this.componentSettings)) {
      return;
    }
    const url = this.injectParameters(this.componentSettings.endpoint);
    return this.dataLoader.get<K>(url);
  }

  protected fetchCustomHeavyData<K = unknown | unknown[]>(): Promise<DeepReadonly<K>> {
    if (!('endpoint' in this.componentSettings)) {
      return;
    }
    const fullUrl = NavigationHelper.injectParametersInURL(
      this.componentSettings.endpoint,
      this.getParamsForInjection(),
    );
    const { path, queryParams } = NavigationHelper.getUrlPathAndQueryParams(fullUrl);
    const query: HeavyQueryBase = {
      baseUrl: path,
      searchParams: queryParams,
      endpointType: 'heavy-custom',
      filtersState: this.filtersState,
      filterConfig: this.constructAppliedFiltersFromState(this.filtersState),
      requestorId: this.componentSettings.id,
    };

    return this.dataLoader.queryHeavyEndpoint<K>(query as HeavyQuery);
  }

  /**
   * After init view
   */
  public async ngAfterViewInit(): Promise<void> {
    await this.viewInitialization();
    // let the page know that the drawing is finished
    this.onComponentInit.emit(this.componentSettings.id);
  }

  /**
   * On destroy
   *
   * @return {void}
   */
  public ngOnDestroy(): void {
    // Reset timezone service
    this.timezoneService.reset();
  }

  /* * Getters/setters * */

  /**
   * The full filters state.
   * Comparator components have no filter state.
   * For other components, it merges:
   *  - the dashboard's filters state if the component is not configured to ignore it
   *  - the component's own filters state
   */
  protected get filtersState(): FiltersState {
    if (this.isComparatorComponent) {
      return {};
    }

    const fullFiltersState = this.componentSettings.ignoreDashboardFilters
      ? {}
      : { ...this.pageStateService.pageFiltersState() };

    // Add local filters state if the current component has its own filters
    for (const localFilter in this.ownFiltersState) {
      const value: any = this.ownFiltersState[localFilter];
      fullFiltersState[localFilter] = value.extent !== undefined ? value.extent : value;
    }

    return fullFiltersState;
  }

  public reloadIfPossible(afterSave: AfterSave, forceChangeDetection: boolean): Promise<void> {
    if (!this.dataLoader || !this.componentSettings) {
      return;
    }

    this.reloadData(afterSave);

    if (forceChangeDetection) {
      this.cdRef.detectChanges();
    }
  }

  /**
   * Returns the information about the interval select for given component.
   * The response is either:
   *  - noFilter - if the component has not interval field at all
   *  - null - if the component has interval with "complete-data-set"
   *  - [number, number] with given interval in ms
   */
  public getChartIntervalInfo(): 'noFilter' | IntervalOrNull {
    return 'noFilter';
  }

  /**
   * @param interval to set, can be undefined, in which case we will simply initialize the interval
   */
  public setComponentInitialInterval(_?: ResolvedInterval): void {}

  /**
   * @returns Whether the components needs actual data to define its range (i.e light charts).
   * If true, it means that we must first get the data before initializing the interval.
   */
  public componentNeedsDataForInterval(): boolean {
    return false;
  }

  /**
   * @description returns parameters passed to the component upon initialization.
   * When used within components-page, they aren't unique to one wrapper, we get
   * them from `pageStateService.parameters()`
   *
   * Wrappers can also be used by `comparator`, in which case we need to pass
   * them, since they are unique to an entity, and we can display multiple entities with
   * different parameters.
   */
  protected get parameters(): ComponentParameter[] {
    if (this.isComparatorComponent) {
      return this.comparatorParameters;
    }

    return this.pageStateService.parameters();
  }

  /**
   * @description returns fieldsets passed to the component upon initialization.
   * We isolate fieldset when initialized by components page vs vessel-comparator
   * since for now `componentParametersService` isn't injected in vessel-comparator
   */
  protected get fieldsets(): Fieldset[] {
    if (this.isComparatorComponent) {
      return this.comparatorFieldsets;
    }

    return this.pageStateService.fieldsets;
  }

  /**
   * Returns a bag of parameters that should be available for injection into endpoints, these contain:
   *  - URL page params (eg. vesselId)
   *  - page state parameters (sidebar-filters) state
   *  - component's own filters (if component has eg. interval filter)
   *  - modalComponentState in case of a chart being part of a modal. State of the modal contains information
   *    about the entity to which the modal was attached, eg. vessel in vessel-table
   */
  public getParamsForInjection(): ComponentParameter[] {
    const paramsCopy: ComponentParameter[] = cloneDeep(this.parameters);
    const stateParams = this.pageStateService.getStateAsComponentParameters();
    for (const stateParam of stateParams) {
      paramsCopy.push(stateParam);
    }

    // State of the modal injection
    if (this.isLight && this.modalComponentState) {
      paramsCopy.push(...asParams(this.modalComponentState));
    }

    // Own filters state (interval filter on the chart level), TODO: JF - Filtering
    if (this.ownFiltersState) {
      paramsCopy.push(...asParams(this.ownFiltersState));
    }

    // TODO: JF - Filtering - remove, once sidebar is used
    if (this.pageStateService.masterPeriod) {
      paramsCopy.push({ name: 'masterPeriod', value: this.pageStateService.masterPeriod.join(',') });
    }

    return paramsCopy;
  }

  public injectParameters(url: string): string {
    if (url === null) {
      console.error('Cannot inject parameters into empty endpoint');
    }
    if (!this.parameters) return url;
    return NavigationHelper.injectParametersInURL(url, this.getParamsForInjection());
  }

  public shouldRefreshLightDataAfterFilter(latestFilter: FilterApplied): boolean {
    return latestFilter?.masterFilter;
  }

  // if refreshType is provided, it means we refresh after applying filters
  public setFilterState(newPageFilters: FiltersState, latestFilter?: FilterApplied, refreshType?: RefreshType): void {
    if (latestFilter) this.latestFilter = latestFilter;
    const newFilterState = { ...newPageFilters };
    this.pageStateService.pageFiltersState.set(newFilterState);
    this.manageComparisonPeriodFromFilter(latestFilter);
    this.updateComponentCalcConfig();
    if (refreshType) this.refresh(refreshType);
  }

  /**
   * Construct filters to be used for filtering of light data-sets (in-front app)
   * Takes the filter's state as parameters and uses the config of each filter.
   */
  public constructAppliedFiltersFromState(stateParams: FiltersState = this.filtersState): FiltersApplied {
    const filters: FiltersApplied = {};
    for (const filterId in stateParams) {
      // try to look up the config of the field in all fieldsets to get the most information
      let filterConfig = FilterHelper.findFieldInFieldsets(this.fieldsets, f => f.id === filterId);

      // if a filter is a masterFilter it is not used for light datasets filtering but only for data-reloading
      if (filterConfig?.masterFilter) {
        continue;
      }

      if (!filterConfig) {
        /*
         * If we have a tab selectFilter we check if current filterId is corresponding to this filter
         * in this case we get the filter config in selectFilter config
         */
        if (this.selectFilter && this.selectFilter.prop === filterId) {
          filterConfig = this.getTabFilterConfig();
        }

        /**
         * In some cases like for modal chart, we give to ownFiltersState params to override pageFiltersState
         * Those filters aren't in fieldsets so we provide custom config within filterTypes
         * to create the filterApplied
         */
        if (this.componentSettings.filterTypes?.[filterId]) {
          filterConfig = {
            id: filterId,
            title: filterId,
            filterType: this.componentSettings.filterTypes[filterId],
            values: stateParams[filterId],
          };
        }
        if (this.componentSettings.comparisonConfig?.id === filterId) {
          filterConfig = this.componentSettings.comparisonConfig;
        }

        if (!filterConfig) {
          /**
           * if the applied filter was not found in fieldset - could can happen for filters
           * from unrelated dashboards/pages. If the page does not have a sidebar, no need to log a warning.
           */
          if (this.pageStateService.fieldsets.length > 0) {
            console.warn(
              `Filter "${filterId}" not found in the standard or selector filters, then not applied.`
                + ' Logged from WrapperComponent.',
            );
          }
          continue;
        }
      }

      const filterApplied = new FilterApplied({
        ...filterConfig,
        prop: filterConfig.prop ?? undefined,
        propValue: filterConfig.propValue ?? filterId,
        values: stateParams[filterId],
      });

      filters[filterId] = filterApplied;
    }
    return filters;
  }

  public onPngExport(pngExportParameters: PngExport) {
    if (!pngExportParameters.title) {
      pngExportParameters.title = this.componentSettings.title;
    }
    this.onexportpng.emit(pngExportParameters);
  }

  /*
   * Responsible for updating visually the component. Always called at startup, and during usage for some components
   * This base implementation handles setting the comparisonPeriod
   */
  public updateDisplayOptions(componentState: ComponentStateOptions): void {
    if (!this.componentSettings.comparisonConfig) return;
    const comparisonPeriod = componentState.comparisonPeriod;
    const masterPeriod = this.pageStateService.masterPeriod;
    const isCompleteDataset = masterPeriod === null;
    // We have a comparison period in component state: store and display it
    if (comparisonPeriod) {
      /*
       * ownFiltersState will be set when the doubledate fires its update event. Do not fire an event
       * for complete dataset as we don't want to trigger another backend call
       */
      this.$comparisonComponent.setFilter(comparisonPeriod, !isCompleteDataset);
      // The comparison selector was init from default value: store this value in state
    } else if (!this.ownFiltersState['comparisonPeriod'] && !isCompleteDataset) {
      this.updateComparisonPeriodToRelativePeriod();
    }
    this.comparisonPeriodDisabled = masterPeriod === null;
  }

  /*
   * Sending this event to parent triggers the serialization of the state to the url, and
   * can also trigger a call to save the state of the component to user preferences.
   * This method will get the componentStateOptions as defined in the getter of each component.
   * Those values can be overridden by the provided object argument
   */
  public emitComponentSelect(override: ComponentStateOptions = {}): void {
    let stateOptions = this.componentStateOptions;
    stateOptions = { ...stateOptions, ...override };
    this.componentSelect.emit(stateOptions);
  }

  public updateComponentCalcConfig(): void {
    return;
  }

  public resetComponentFilters(): void {
    return;
  }

  public getTabFilterConfig(): FieldSettings {
    if (!this.selectFilter) {
      return;
    }
    return {
      id: this.selectFilter.prop,
      title: this.selectFilter.prop,
      filterType: 'multi',
    };
  }

  /* * Abstract * */

  /**
   * All components need to implement this method. It is responsible for complete reload of the data
   */
  public abstract reloadData(afterSave: AfterSave): void;

  public setFullScreenIndicator(fullScreen: boolean): void {}
  public forceRelayout(): void {}

  /**
   * All components implement this method which is responsible for refreshing the component when the filters change
   */
  public async refresh(_: RefreshType): Promise<void> {
    // Generate DynamicConfigParameters object that contains all information needed by the dynamicConfig
    const filtersState = this.filtersState;
    const parameters: DynamicConfigParameters = {
      componentFilters: {
        filtersState: filtersState,
        filtersConfig: this.constructAppliedFiltersFromState(filtersState),
      },
      configFiltersPath: this.configFiltersPath,
      componentParameters: this.getParamsForInjection(),
    };

    const config = await ComponentHelper.populateDynamicConfigs(
      this.dynamicConfigs,
      this.componentSettings,
      this.dataLoader,
      parameters,
    );

    this.dynamicConfigHasChanged = config.dynamicConfigHasChanged;

    if (this.dynamicConfigHasChanged) {
      this.componentSettings = config.componentSettings;
    }
  }

  /**
   * This methods is called inside ngAfterViewInit of each component, it should be responsible for initial
   * configuration and setup (loading config of the component and setting up flags). No data-loading should
   * be inside this function
   */
  public abstract viewInitialization(): void | Promise<void>;

  /**
   * Only applicable for component with a comparison period. If master period changed, we must update the
   * comparison period as well
   */
  public manageComparisonPeriodFromFilter(appliedFilter: FilterApplied): void {
    if (!this.componentSettings.comparisonConfig) return;
    if (!appliedFilter.timeSync) return;
    this.updateComparisonPeriodToRelativePeriod();
  }

  public updateComparisonPeriodToRelativePeriod(): void {
    if (!this.componentSettings.comparisonConfig) return;
    const isCompleteDataset = !this.pageStateService.masterPeriod;
    if (!isCompleteDataset) {
      const selectedEra = this.$comparisonComponent.selectedEra;
      const compPeriodExtent = DateHelper.period({ era: selectedEra, refPeriod: this.pageStateService.masterPeriod });
      this.ownFiltersState['comparisonPeriod'] = {
        extent: compPeriodExtent,
        era: selectedEra,
      };
      this.comparisonPeriodDisabled = false;
    } else {
      delete this.ownFiltersState['comparisonPeriod'];
      this.comparisonPeriodDisabled = true;
    }
  }

  public onComparisonPeriodChanged(event: FilterApplied): void {
    const comparisonPeriod: ResolvedInterval = { extent: event.values as Interval, era: event.selectedEra };
    this.ownFiltersState['comparisonPeriod'] = comparisonPeriod;
    this.emitComponentSelect();
    this.refresh(RefreshType.Full);
  }

  public getComparedPeriods(): [Interval, Interval] | null {
    const masterPeriodValue = this.pageStateService.masterPeriod;
    const comparisonPeriod = this.ownFiltersState['comparisonPeriod'] as ResolvedInterval;
    if (!masterPeriodValue || !comparisonPeriod) return null;
    if (masterPeriodValue && !comparisonPeriod.extent && comparisonPeriod.era) {
      console.error('should be initialized already');
    }
    return [masterPeriodValue, comparisonPeriod.extent];
  }

  public intervalToString(interval: Interval): string {
    return interval.map(time => dayjs.utc(time).format(DateHelper.displayComparisonDateFormat)).join(' - ');
  }

  /**
   * Perform light filtering on any data-set according to the current filter state,
   * but ignoring any masterFilters.
   */
  protected lightFilteringOfDataSet(data: readonly RawDataPoint[]): RawDataPoint[] {
    const filters = this.constructAppliedFiltersFromState();
    return FilterHelper.filterDataset(data, filters);
  }
}
