import { ChangeDetectionStrategy, Component, computed, EventEmitter, inject, input, Output, signal,
  viewChild } from '@angular/core';
import { CdkScrollable } from '@angular/cdk/scrolling';

import { uniq } from 'lodash-es';

import { FieldSettings, FilterApplied, OptionValue } from '../helpers/types';
import { AppInfoService } from '../app/app-info-service';
import { AutocompleteFilterHelper } from './autocomplete-filter.helper';
import { FilterHelper } from './filter-helper';
import { PearlAutocompleteComponent, PearlSelectOption, PearlSelectOptionValueType } from '../shared/pearl-components';
import { Ordering } from '../helpers/ordering';

@Component({
  selector: 'autocomplete-filter',
  templateUrl: 'autocomplete-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CdkScrollable, PearlAutocompleteComponent],
})
export class AutocompleteFilterComponent<T extends PearlSelectOptionValueType = PearlSelectOptionValueType> {
  public readonly field = input<FieldSettings>();
  public readonly small = input<boolean>(true);
  public readonly hideSelectAll = input<boolean>(false);
  public readonly disabled = input<boolean>(false);

  public readonly values = signal<OptionValue[]>([]);
  public readonly defaultValues = signal<T[]>([]);

  public readonly clearing = signal<boolean>(false);
  public readonly selectedOptions = computed(() => this.$autocomplete().getSelectedOptions(true).map((o) => o.value));

  @Output()
  public readonly onchange = new EventEmitter<FilterApplied>();

  public readonly $autocomplete = viewChild<PearlAutocompleteComponent<T>>('autocomplete');

  private readonly appInfoService = inject(AppInfoService);

  public update(checkForDefault: boolean = false): void {
    this.defaultValues.set(
      uniq(this.defaultValues().concat(this.$autocomplete().getSelectedOptions(true).map((o) => o.value))),
    );
    this.values.set(this.field().values ?? []);

    if (checkForDefault) this.setDefault();
  }

  public sort<T extends PearlSelectOption>(): (a: T, b: T) => number {
    return (a: T, b: T) => {
      let sort = 1;

      if (this.field()?.ordered) {
        sort = Ordering.fixedOrder(a.title, b.title, this.field().ordered);
      } else if (a.order && b.order) sort = +a.order - +b.order;
      else if (a.title && b.title) sort = a.title.toString().localeCompare(b.title.toString());

      if (this.field().orderDirection) sort = this.field().orderDirection === 'asc' ? sort : -sort;

      return sort;
    };
  }

  public setDefault(): void {
    if (!this.field().default) return this.defaultValues.set([]);
    this.defaultValues.set(FilterHelper.getDefaultFieldValue(this.field()) as T[]);
  }

  public reset(): void {
    this.clearing.set(true);

    if (this.field().default === undefined) this.clear();
    else this.setDefault();

    this.clearing.set(false);
  }

  public clear(): void {
    this.$autocomplete().resetField();
  }

  public set(valuesToSelect: unknown[] = [], _fire: boolean = false): void {
    this.defaultValues.set(valuesToSelect as T[]);
  }

  public filterChange(options: PearlSelectOptionValueType[]): void {
    if (this.clearing()) return;
    if (this.$autocomplete().selectAllChecked()) options.push(AutocompleteFilterHelper.SELECT_ALL_VALUE);
    this.appInfoService.userAction(this.field().id, false, true);
    this.onchange.emit(
      new FilterApplied({
        ...this.field(),
        filterTitle: this.field().title,
        active: !!options.length,
        values: options,
      }),
    );
  }
}
