import { ChangeDetectionStrategy, Component, input } from '@angular/core';
import { NgClass } from '@angular/common';

import { PartialProperties } from '../../helpers/componentProperties.types';
import { PearlIcon } from '../../icons/icons';
import { PearlIconComponent } from '../../icons/pearl-icon.component';
import { PearlHeroSectionComponent } from '../../hero-section/pearl-hero-section.component';

@Component({
  selector: 'pearl-tooltip-header',
  standalone: true,
  imports: [
    NgClass,
    PearlIconComponent,
    PearlHeroSectionComponent,
  ],
  templateUrl: './pearl-tooltip-header.component.html',
  styleUrl: './pearl-tooltip-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PearlTooltipHeaderComponent {
  public static readonly EMPTY: PartialProperties<PearlTooltipHeaderComponent> = {
    title: '-',
  };

  public readonly closeHandler = input<() => void>();

  /** Main title part, mandatory */
  public readonly title = input.required<string>();

  /** Subtitle, below mandatory title (optional) */
  public readonly subtitle = input<string>();

  /** Define icon to be displayed in front, mandatory */
  public readonly icon = input<PearlIcon>();

  public readonly iconColor = input<string>('inherit');

  public readonly imageSrc = input<string>();

  protected handleClose(): void {
    if (!this.closeHandler()) {
      console.warn('No close handler provided');
      return;
    }
    this.closeHandler()();
  }
}
