import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, OnInit, booleanAttribute, inject,
  input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { PearlDatepickerBase } from './datepicker-base';
import { PearlButtonComponent } from '../buttons/pearl-button.component';
import { PearlIcon } from '../icons/icons';
import { isDatePrecision } from './datepicker-types';

@Component({
  selector: 'pearl-datepicker-toggle',
  templateUrl: './datepicker-toggle.html',
  styleUrl: './datepicker-toggle.scss',
  host: {
    '[attr.tabindex]': 'null',
    /*
     * Bind the `click` on the host, rather than the inner `pearl-button`, so that we can call `stopPropagation` on it
     * without affecting the user's `click` handlers (`<pearl-datepicker-toggle (click)="userHandler()" />`).
     * We need to stop it so that the input doesn't get focused automatically by the form field
     * (See https://github.com/angular/components/pull/21856).
     */
    '(click)': 'open($event)',
  },
  exportAs: 'pearlDatepickerToggle',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PearlButtonComponent],
})
export class PearlDatepickerToggleComponent implements OnInit {
  private cdRef = inject(ChangeDetectorRef);
  private destroyRef = inject(DestroyRef);

  /** Datepicker instance that the button will toggle. */
  public readonly datepicker = input.required<PearlDatepickerBase>({ alias: 'for' });

  /** Tabindex for the toggle. */
  public readonly tabIndex = input<number>();

  /** Screen-reader label for the button. */
  public readonly ariaLabel = input<string>();

  public readonly size = input<'default' | 'small'>('default');

  /** Explicitly disable the toggle button. */
  public readonly explicitlyDisabled = input<boolean, boolean | string>(false, {
    transform: booleanAttribute,
    alias: 'disabled',
  });

  /**
   * Subscribe to datepicker state changes (disabled state changes in particular).
   * Subscribing in an `effect` in the constructor misses the first updates of datepicker.disabled so the disabled state
   * of the toggle can be out of sync until the first rerender. `ngOnInit` runs before `effect` so it is a better
   * solution until we can get `disabled` as a signal (see. comment on `disabled` getter).
   */
  ngOnInit(): void {
    this.datepicker().stateChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.cdRef.markForCheck();
    });
  }

  /*
   * Deactivate the button if it is explicitly deactivated, or if the datepicker is disabled
   *
   * Computed signals cannot be used for `disabled` and `icon` because their value would only be updated if the
   * reference to this.datepicker changes. Until a solution is provided with computed signals, we use getters +
   * `this.cdRef.markForCheck()` in datepicker.stateChanges callback.
   */
  get disabled(): boolean {
    return this.explicitlyDisabled() || this.datepicker().disabled;
  }

  get icon(): PearlIcon {
    return isDatePrecision(this.datepicker().precision)
      ? 'calendar'
      : this.datepicker().timeOnly
      ? 'time'
      : 'date_time';
  }

  protected open(event: Event): void {
    if (this.datepicker() && !this.disabled) {
      this.datepicker().open();
      event.stopPropagation();
    }
  }
}
