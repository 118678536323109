<ng-container [ngSwitch]="_type()">
  <ng-container *ngSwitchCase="['primary', 'sticky'].includes(_type()) ? _type() : ''">
    <a
      [target]="target()"
      *ngIf="isLink()"
      [href]="href()"
      mat-raised-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </a>
    <button
      *ngIf="!isLink()"
      mat-raised-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      [attr.aria-label]="ariaLabel()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'secondary'">
    <a
      [target]="target()"
      *ngIf="isLink()"
      [href]="href()"
      mat-stroked-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </a>
    <button
      *ngIf="!isLink()"
      mat-stroked-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      [attr.aria-label]="ariaLabel()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="'alternative'">
    <a
      [target]="target()"
      *ngIf="isLink()"
      [href]="href()"
      mat-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </a>
    <button
      *ngIf="!isLink()"
      mat-button
      [disabled]="disabled()"
      [class]="class()"
      [color]="color()"
      [attr.aria-label]="ariaLabel()"
      (click)="trackClick()">
      <ng-container [ngTemplateOutlet]="content" />
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </button>
  </ng-container>
  <ng-container *ngSwitchCase="['icon', 'icon-compact', 'icon-sticky'].includes(_type()) ? _type() : ''">
    <a
      [target]="target()"
      [href]="href()"
      *ngIf="isLink() && icon()"
      mat-icon-button
      [ngClass]="{
        'pearl-icon': true,
        'pearl-sticky': _type() === 'icon-sticky',
        'pearl-icon-compact': _type() === 'icon-compact',
      }"
      [disabled]="disabled()"
      [color]="color()"
      (click)="trackClick()">
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </a>
    <button
      *ngIf="!isLink() && icon()"
      mat-icon-button
      [ngClass]="{
        'pearl-icon': true,
        'pearl-sticky': _type() === 'icon-sticky',
        'pearl-icon-compact': _type() === 'icon-compact',
      }"
      [disabled]="disabled()"
      [color]="color()"
      [attr.aria-label]="ariaLabel()"
      (click)="trackClick()">
      <pearl-icon
        removeParentNode
        matButtonIcon
        *ngIf="icon()"
        [size]="iconSize()"
        [icon]="icon()"
        [iconBadgeContent]="iconBadgeContent()" />
    </button>
  </ng-container>
</ng-container>

<ng-template #content>
  <ng-content />
</ng-template>
