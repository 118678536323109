<div class="vessel-request-form">
  <!-- This part of the form, before submitting -->
  @if (!config.formSent) {
    <div>
      <div class="form-close-button">
        <pearl-button type="icon" icon="close" matTooltip="Close" (click)="close()" />
      </div>
      <h3>{{ config.title }}</h3>
      <span>{{ config.subTitle }}</span>
      @for (configLine of config.fields; track $index) {
        <div class="form-line">
          @for (field of excludeHiddenFields(configLine); track field.id) {
            @if (field.multiline) {
              <pearl-form-field [class]="getFieldClass(field)" [small]="true" [label]="field.placeholder">
                <textarea
                  matInput
                  [disabled]="field.disabled"
                  [required]="field.required"
                  [(ngModel)]="field.value"></textarea>
              </pearl-form-field>
            } @else {
              <pearl-form-field [class]="getFieldClass(field)" [label]="field.placeholder" [small]="true">
                <textarea
                  rows="1"
                  cdkTextareaAutosize
                  #autosize="cdkTextareaAutosize"
                  matInput
                  [disabled]="field.disabled"
                  [required]="field.required"
                  [(ngModel)]="field.value"></textarea>
              </pearl-form-field>
            }
          }
        </div>
      }
      @if (isFormInvalid()) {
        <div class="errors-footer">
          <pearl-icon icon="error" [size]="24" />
          <span class="error-label">Invalid fields : {{ requiredFieldAsString() }}</span>
        </div>
      }
      <div class="info-message">
        <span>{{ config.infoMessage }}</span>
        @if (config.errored) {
          <span class="error"> There was an error submitting the form. Please try again or contact us. </span>
        }
      </div>
      <div class="form-send-button">
        <pearl-button
          type="primary"
          [icon]="config.actionButton.icon"
          [disabled]="isFormInvalid()"
          (click)="config.actionButton.onClick()">
          {{ config.actionButton.label }}
        </pearl-button>
      </div>
    </div>
  }
  <!-- This is displayed once the form is submitted -->
  @if (config.formSent) {
    <div class="sent-request-message">
      @if (!config.duplicateMessage) {
        <div class="sent-request-message">
          <h2>Request successfully sent!</h2>
          <img src="assets/img/mail_sent.svg" />
          <span>{{ config.successMessage }}</span>
        </div>
      } @else {
        <h2>Vessel already requested</h2>
        <img src="assets/img/vessel.svg" />
        <span>{{ config.duplicateMessage }}</span>
      }
      <pearl-button type="primary" matTooltip="Close" (click)="close()">Close</pearl-button>
    </div>
  }
</div>
