import { Injectable, NgZone, inject } from '@angular/core';
import { NavigationEnd, Params, Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { BehaviorSubject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

import { NavigationExtraState } from './types';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  private static _offlineViewList = [
    '/dashboard/dpr/vessels',
    '/dashboard/dpr/selected-vessel',
    '/dashboard/dpr/selected-report',
  ];

  private readonly _isOfflineViewActivated$ = new BehaviorSubject<boolean>(false);

  public readonly isOfflineViewActivated$ = this._isOfflineViewActivated$.asObservable();

  private readonly router = inject(Router);
  private readonly ngZone = inject(NgZone);

  constructor() {
    /**
     * In case we are offline we display some toggle component. We want to display this component only on pages
     * where offline behavior are actually implemented and prevent displaying it on every pages.
     */

    this.router.events.pipe(
      takeUntilDestroyed(),
      filter(events => events instanceof NavigationEnd),
      tap((events: NavigationEnd) => {
        const url = events.url.split('?')[0];
        if (RouterService._offlineViewList.includes(url)) {
          this._isOfflineViewActivated$.next(true);
          return;
        }

        this._isOfflineViewActivated$.next(false);
      }),
    ).subscribe();
  }

  public navigateOnSameUrl(state?: NavigationExtraState): void {
    const url = this.getCurrentDashboardUrl();
    const queryParams = Object.fromEntries(new URLSearchParams(location.search));
    this.router.navigate([url], { queryParams, state });
  }

  public navigateWithExtraState(url: string, queryParams: Params, state?: NavigationExtraState): void {
    this.router.navigate([url], { queryParams, state });
  }

  /**
   * Get NavigationExtraState, as it can contain flags reloadData or resetState
   * By default, we will get the latest successful navigation, but if "currentOnly" is true, we will return only the
   * current navigation (which is populated while navigating)
   */
  public getNavigationExtraState(
    { currentOnly }: { currentOnly: boolean } = { currentOnly: false },
  ): NavigationExtraState {
    let navigation = this.router.getCurrentNavigation();
    if (!navigation && !currentOnly) navigation = this.router.lastSuccessfulNavigation;
    return navigation?.extras?.state ?? {};
  }

  public redirect(url: string, queryParams = {}): void {
    this.ngZone.run(() => this.router.navigate([url], { queryParams }));
  }

  public getCurrentDashboardUrl(): string {
    return this.router.url.split('?')[0];
  }
}
