import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgIf, NgTemplateOutlet } from '@angular/common';

import { PearlProgressBarComponent } from '../progress-bar/pearl-progress-bar.component';
import { FieldButtonComponent } from '../../../field-button';
import { DataHelpers } from '../../../../helpers/data-helpers';
import { ActionEvent, ArrowComparison, ComparisonData, FieldSettings, KpiValue } from '../../../../helpers/types';
import { PearlIcon } from '../icons/icons';
import { PearlIconComponent } from '../icons/pearl-icon.component';
import { DescriptionButtonComponent } from '../../../description-button';

const ARROW_DIRECTION_ICON: Record<ArrowComparison['direction'], PearlIcon> = {
  'flat': 'trend_flat',
  'up': 'trend_up',
  'down': 'trend_down',
};

/**
 * Temporary implementation of the Pearl KPI Organism.
 * This component was created to separate concerns and make it easier to implement the Pearl component once it is ready.
 *
 * For the latest status
 * @see https://www.notion.so/spinergie/KPI-d1cd3fff5c524d62b2831224a70e8b4a
 */
@Component({
  standalone: true,
  selector: 'pearl-kpi',
  templateUrl: './pearl-kpi.component.html',
  styleUrls: ['./pearl-kpi.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    DescriptionButtonComponent,
    FieldButtonComponent,
    MatProgressSpinnerModule,
    PearlIconComponent,
    PearlProgressBarComponent,
    NgTemplateOutlet,
    NgIf,
  ],
})
export class PearlKpiComponent {
  public readonly fieldSettings = input.required<FieldSettings>();
  public readonly loading = input<boolean>(false);
  public readonly value = input<KpiValue>();
  public readonly comparison = input<ComparisonData<KpiValue>>();
  public readonly target = input<KpiValue>();
  public readonly targetExceededMessage = input<string>();

  public readonly hasValue = computed<boolean>(() => this.value() !== undefined && this.value() !== null);
  public readonly formattedValue = computed<string>(() => {
    const value = this.value();
    if (value === undefined || value === null) return '';
    if (typeof value === 'string') return value;
    return DataHelpers.formatNumber(value, this.fieldSettings()?.format);
  });

  public readonly previousValue = computed<KpiValue | undefined>(() => this.comparison()?.previousValue);
  public readonly hasPreviousValue = computed<boolean>(() =>
    this.previousValue() !== undefined && this.previousValue() !== null
  );
  public readonly formattedPreviousValue = computed<string>(() => {
    const previousValue = this.previousValue();
    if (previousValue === undefined || previousValue === null) return '';
    if (typeof previousValue === 'string') return previousValue;
    return DataHelpers.formatNumber(previousValue, this.fieldSettings()?.format);
  });

  public readonly comparisonArrow = computed<ArrowComparison | undefined>(() => this.comparison()?.arrow);
  public readonly comparisonArrowIcon = computed<PearlIcon | undefined>(() =>
    ARROW_DIRECTION_ICON[this.comparisonArrow()?.direction]
  );

  public readonly hasTarget = computed<boolean>(() => this.target() !== undefined && this.target() !== null);
  /*
   * Type narrowing is not working in templates with signals (yet), so we use this computed signal returning correctly
   * typed value and target only if they are numbers, instead of a simple type check.
   * In the future we can replace it with `hasNumericTarget: () => boolean` that just checks the types are number
   * and use this.value() and this.target() directly which should be typed as `number` by TS.
   * @see https://github.com/angular/angular/pull/55456
   */
  public readonly numericTarget = computed<{ value: number; target: number } | null>(() => {
    const value = this.value();
    const target = this.target();
    if (typeof target !== 'number' || typeof value !== 'number') return null;
    return { value, target };
  });

  public buttonClick = output<ActionEvent>();
}
