import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { FieldSettings, FilterApplied } from '../../helpers/types';
import { DistanceToEntityHelper } from './distance-to-entity.helper';
import { AutocompleteFilterComponent } from '../autocomplete-filter.component';
import { FilterHelper, FilterPopulateOptions } from '../filter-helper';
import { RawDataPoint } from '../../graph/chart-types';
import { RefDataProvider } from '../../data-loader/ref-data-provider';
import { NumberComponent } from '../number';
import { DistanceToEntityFilterConfig } from './distance-to-entity.types';

@Component({
  selector: 'spin-filters-distance-to-entity',
  templateUrl: './distance-to-entity.component.html',
  styleUrls: [],
  standalone: true,
  imports: [
    AutocompleteFilterComponent,
    NumberComponent,
  ],
})
export class DistanceToEntityComponent implements OnInit {
  @Input()
  field: DistanceToEntityFilterConfig;

  @Output()
  filterChange = new EventEmitter<FilterApplied>();

  @ViewChild('$entityField')
  public $entityField: AutocompleteFilterComponent<number>;

  @ViewChild('$distanceField')
  public $distanceField: NumberComponent;

  public entityFieldConfig: FieldSettings;
  public distanceFieldConfig: FieldSettings;

  public get maxDistance(): number {
    return this.$distanceField.selectedValue();
  }

  public get selectedEntityIds(): number[] {
    return this.$entityField.selectedOptions();
  }

  private entityDataset: { [id: number]: RawDataPoint };

  public ngOnInit(): void {
    this.entityFieldConfig = DistanceToEntityHelper.buildEntityFieldConfig(this.field);
    this.distanceFieldConfig = DistanceToEntityHelper.buildDistanceFieldConfig(this.field);
    this.populateEntityField();
  }

  public setFilter(filter: FilterApplied): void {
    const { entityIds, maxDistance } = DistanceToEntityHelper.deserializeFilterValues(filter.values);
    this.$distanceField.selectedValue.set(maxDistance);
    this.$entityField.set(entityIds, false);
  }

  public clear(): void {
    this.$distanceField.clear();
    this.$entityField.clear();
    this.updateFiltering();
  }

  public reset(): void {
    this.$distanceField.reset();
    this.$entityField.reset();
    this.updateFiltering();
  }

  private populateEntityField(): void {
    this.loadEntityDataset().then(() => this.populateFields());
  }

  private async loadEntityDataset(): Promise<void> {
    this.entityDataset = await RefDataProvider.loadRefDataset(this.field.entityConfig.refDataset);
  }

  private populateFields(): void {
    const options: FilterPopulateOptions = {
      propOrder: this.field.entityConfig.titleProp,
      propTitle: this.field.entityConfig.titleProp,
      propValue: this.field.entityConfig.idProp,
      keepExisting: true,
    };

    this.$entityField.field().values = FilterHelper.getMultiPopulateValues(
      this.$entityField.field(),
      Object.values(this.entityDataset),
      options,
    );
    this.$entityField.update(true);
  }

  public updateFiltering(): void {
    if (!this.selectedEntityIds?.length || this.maxDistance == null) {
      this.filterChange.emit({ ...this.field, active: false });
      return;
    }

    const distanceToEntityValues = DistanceToEntityHelper.serializeFilterValues(
      this.selectedEntityIds,
      this.maxDistance,
    );

    const filterApplied: FilterApplied = {
      ...this.field,
      values: distanceToEntityValues,
      active: true,
    };

    this.filterChange.emit(filterApplied);
  }
}
