import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, NgZone, Output, QueryList, ViewChild,
  ViewChildren, ViewEncapsulation, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatIconModule } from '@angular/material/icon';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule } from '@angular/forms';
import { MatSlideToggle, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';

import { uniq } from 'lodash-es';
import tinycolor from 'tinycolor2';

import { DataLoader } from '../data-loader/data-loader';
import { StandardLayerState, isVesselLayer } from '../dashboards/model/layer-state';
import { ActionEvent, Button, DEFAULT_MAX_TRACES, DeepReadonly, FieldsetUpdate, FilterApplied, FiltersChanged,
  LayerFilter, LayerHiddenShapeInfo, PreparedVesselData, SwitchLayerModeInfo } from '../helpers/types';
import { DialogComponent } from '../shared/dialog';
import { AutocompleteFilterComponent } from './autocomplete-filter.component';
import { Config } from '../config/config';
import { DialogManager } from '../database/dialog-manager';
import { SelectorFullConfig } from '../selector/selector.types';
import { SimpleTooltipService } from '../shared/services/simple-tooltip.service';
import { UpperFirstLetterPipe } from '../helpers/pipes';
import { RawDataPoint } from '../graph/chart-types';
import { SidebarFieldsetComponent } from './sidebar-fieldset.component';
import { setMatSlideToggleColorsSelected } from '../helpers/ux-helper';
import { SPINERGIE_DEFAULT_GREEN } from '../helpers/color-helper';
import { PearlButtonComponent } from '../shared/pearl-components';
import { RouterService } from '../helpers/router.service';
import { UIService } from '../shared/services/ui.service';
import { getChained } from '../data-loader/ref-data-provider';
import { MapLayerSettings } from '../helpers/config-types';
import { AdvancedFieldsetCollection } from './fieldsets-collection';
import { MapLibreLayer } from '../map/maplibre-layer';

@Component({
  selector: 'sidebar-layer',
  templateUrl: 'sidebar-layer.html',
  styleUrls: ['sidebar-layer.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PearlButtonComponent,
    NgIf,
    NgClass,
    MatSlideToggleModule,
    FormsModule,
    MatDividerModule,
    NgStyle,
    MatCheckboxModule,
    SidebarFieldsetComponent,
    AutocompleteFilterComponent,
    PearlButtonComponent,
    MatIconModule,
    NgFor,
    MatProgressSpinnerModule,
    UpperFirstLetterPipe,
  ],
})
export class SidebarLayerComponent implements AfterViewInit {
  @Input()
  layer: StandardLayerState = null;
  @Input()
  layerSetting?: MapLayerSettings = null;
  @Input()
  layerNumber: number = 0;
  @Input()
  public onaction: (event: ActionEvent) => void;

  @Output()
  onbubblinglayer = new EventEmitter<any>();
  @Output()
  onbubblingfilters = new EventEmitter<FiltersChanged>();
  @Output()
  onserializeurl = new EventEmitter<any>();
  @Output()
  onswitchlayermode = new EventEmitter<SwitchLayerModeInfo>();
  @Output()
  onButtonClick = new EventEmitter<Button>();
  @Output()
  onToggleShapeVisibility = new EventEmitter<LayerHiddenShapeInfo>();

  @ViewChildren(SidebarFieldsetComponent)
  public $fieldsets: QueryList<SidebarFieldsetComponent>;
  @ViewChild('vessel_field')
  public $vesselField: AutocompleteFilterComponent;
  @ViewChild(MatSlideToggle)
  private $toggle: MatSlideToggle;

  public ready: boolean = false;

  public ngZone: NgZone = inject(NgZone);
  public dialog: MatDialog = inject(MatDialog);
  public config: Config = inject(Config);
  public cdRef: ChangeDetectorRef = inject(ChangeDetectorRef);

  public route: ActivatedRoute = inject(ActivatedRoute);
  public panelId: string = '';
  public vesselFieldInitialization: boolean = false;
  public maxTraces: number;
  public dialogManager: DialogManager = inject(DialogManager);
  public advancedFiltersData: DeepReadonly<RawDataPoint[]>;

  /* Vessel filter state is handled separately because vessel field stands outside of fieldsets. */
  private vesselFilter: FilterApplied;

  protected dataLoader: DataLoader = inject(DataLoader);
  private readonly routerService = inject(RouterService);
  public $tooltip = inject(SimpleTooltipService).component;

  public uiService = inject(UIService);
  public isVesselLayer = isVesselLayer;
  constructor() {
    this.panelId = this.route.snapshot.url.slice(-1)[0].path;
  }

  ngAfterViewInit(): void {
    if (this.layerSetting?.hideShapeLabel && this.layerSetting?.hideShapeDefault !== undefined) {
      this.hideShapeFilterChanged(this.layerSetting?.hideShapeDefault);
    }
    this.setUpMatSlideToggle();
  }

  protected get mapLayer(): MapLibreLayer {
    return this.layer as MapLibreLayer;
  }

  public onfilterbubblinglayer(layerToggled: boolean): void {
    this.onbubblinglayer.emit(layerToggled);
  }

  public onFilterApplied(filter: FilterApplied): void {
    this.mergeAndEmitFilters(filter);
  }

  public mergeAndEmitFilters(latestFilter: FilterApplied): void {
    const filtersChanged: FiltersChanged = {
      values: this.getLayerFilters(),
      latest: latestFilter,
    };
    this.onbubblingfilters.emit(filtersChanged);
  }

  /* Get all filters applied to this layer. */
  public getLayerFilters(): LayerFilter {
    const filters: LayerFilter = {
      ...this.layer.fieldsetsGroup.getAdvancedFiltersStateSnapshot(),
    };
    if (this.vesselFilter) {
      filters.vessel = this.vesselFilter;
    }
    this.$fieldsets?.forEach($fs => Object.assign(filters, $fs.appliedFilters));
    return filters;
  }

  public checkIfCanEnterHistoricalMode(): boolean {
    this.maxTraces = this.mapLayer.settings.maxTraces ?? DEFAULT_MAX_TRACES;
    return (this.layer.shown.length <= this.maxTraces && this.layer.shown.length > 0) ? true : false;
  }

  public populateWithValues(fieldsetUpdate: FieldsetUpdate): void {
    this.$fieldsets.forEach(fieldset => fieldset.populateWithValues(fieldsetUpdate));
  }

  /**
   * Note that all filters are set on their fields without being fired, except from $vesselField.
   * The firing is done once with a batch of filters at the end if fire flag is set. It improves performances.
   */
  public set(filters: LayerFilter, fire: boolean = false): void {
    const { standardFilters, advancedFilters } = this.layer.fieldsetsGroup.splitSelectorFilters(filters);

    this.$fieldsets.forEach($fieldset => {
      $fieldset.set(standardFilters, false);
    });

    if (this.$vesselField) {
      this.applyVesselFilter(filters, false);
    }

    this.layer.fieldsetsGroup.setAdvancedFilters(advancedFilters);

    if (fire) {
      this.mergeAndEmitFilters(null);
    }
  }

  /* Handle vessel filters if given */
  private applyVesselFilter(filters: LayerFilter, fire: boolean): void {
    if (filters.vessel != null) {
      this.$vesselField.set(filters.vessel.values, fire);
      filters.vessel = { ...this.mapLayer.vessel, ...filters.vessel };
      this.vesselFilter = filters.vessel;
    } else {
      this.resetVesselFilter(fire);
    }
  }

  public onvesselchange(filter: FilterApplied): void {
    /*
     * normally this is a list of ids, but when coming from url
     * it might be a list of strings
     */
    const vessels = filter.values.map(id => parseInt(id + ''));

    /*
     * no vessels - bellow we handle the situation when last vessel was removed
     * and the vessel list is now empty
     */
    if (!vessels.length) {
      /*
       * if removed from the search bar it has to be remove to the old latest historical mode filters state
       * if we go back to latest mode we keep the old state filters and we don't want to keep the vessel filter
       */
      if (this.layer.filters?.vessel) {
        delete this.layer.filters.vessel;
      }
      // if last vessel was removed from historicalPosition mode, we will get back to latestPosition mode
      if (this.mapLayer.settings.layerType === 'historical') {
        this.switchLayerMode([]);
        return;
      }
      // if last vessel was removed and we are in latestPosition mode - we just filter with empty list
      this.filterLayerForVessels([]);
      return;
    }
    this.filterLayerForVessels(vessels);
  }

  public reset(fire: boolean = true): void {
    if (this.$vesselField) {
      this.resetVesselFilter(false);
    }
    /*
     * "Reset filters" switches the vessel layer mode back to "Latest positions".
     * In this mode, there's a default period filter for the latest positions.
     * We have to hard reset the filters to remove this period filter and show all the vessels.
     */
    this.layer.fieldsetsGroup.resetFilters();
    this.$fieldsets.forEach(fieldset => fieldset.reset(false));

    if (fire) {
      this.mergeAndEmitFilters(null);
    }
  }

  private resetVesselFilter(keepLayerMode: boolean = false): void {
    this.$vesselField?.reset();
    this.vesselFilter = null;
    // Reset to latest position mode if needed and currently in historical positions
    if (!keepLayerMode && this.mapLayer.settings.layerType === 'historical') {
      this.switchLayerMode([]);
      return;
    }
  }

  /**
   * Populate the layer filters with the given data.
   *
   * @param data          The data used to populate the filters.
   * @param keepExisting  Whether to keep the existing filter values or to populate only with the given dataset.
   */
  public populate(data: DeepReadonly<RawDataPoint[]>, keepExisting: boolean): void {
    this.vesselFieldInitialization = true;
    this.cdRef.detectChanges();

    this.advancedFiltersData = keepExisting ? [...this.advancedFiltersData, ...data] : data;
    this.$fieldsets.forEach(
      $fieldset => $fieldset.populate(this.advancedFiltersData, keepExisting),
    );

    // if the layer has vessel field, than vessel field is populated as well
    if (this.$vesselField) {
      this.$vesselField.update();
    }
  }

  public reloadAfterConfig(): void {
    this.$fieldsets.forEach($fieldset => $fieldset.reloadAfterConfig());
  }

  handleButtonSwitchMode(): void {
    /*
     * the button was enabled - either vessels were selected manually or
     * vessels were filtered by different filters, but in order to switch the mode
     * we have to collect the IDs of the vessels.
     */

    /*
     * if we go from latestPosition to historical - a set of vessels is shown (less then the limit)
     * so we will use the IDs
     */

    /*
     * if we go from historical to latestPosition - we might used the visible data as well, but in case
     * there is no visible data, there must be some vessel in the filter
     */
    let vesselIds: any[];
    if (this.layer.shown?.length > 0) {
      const vesselProp = this.mapLayer.vessel.propValue ?? this.mapLayer.vessel.id;
      vesselIds = uniq(this.mapLayer.shown.map((d: RawDataPoint) => getChained<number>(d, vesselProp)));
    } else {
      vesselIds = this.layer.filters.vessel.values;
    }
    /** Ensure we have less than 11 vessels to avoid problems */
    if (this.mapLayer.settings.layerType === 'latest' && !this.checkIfCanEnterHistoricalMode()) return;
    this.switchLayerMode(vesselIds);
  }

  /**
   * This function emits an event addressed to the map dashboard to switch the layer mode
   */
  public switchLayerMode(vesselIds: number[]): void {
    if (!isVesselLayer(this.layer.settings)) {
      console.warn("Can't switch mode for this layer. This layer has only one mode.");
      return;
    }

    const switchModeInfo: SwitchLayerModeInfo = {
      layerId: this.layer.id,
      switchMode: this.mapLayer.settings.layerType === 'latest' ? 'historical' : 'latest',
      vesselIds,
    };

    this.onswitchlayermode.emit(switchModeInfo);
  }

  private filterLayerForVessels(vesselIds: number[]): void {
    const vesselField = this.mapLayer.vessel;
    if (!vesselField) {
      return;
    }

    this.vesselFilter = {
      id: vesselField.id,
      active: !!vesselIds.length,
      values: vesselIds,
      propValue: vesselField.propValue,
      filterType: 'multi',
      autozoom: vesselField.autozoom,
      isVesselFilter: true,
    };

    this.onFilterApplied(this.vesselFilter);
  }

  private setUpMatSlideToggle(): void {
    const handleColor = this.layer.settings.toggleColor ?? SPINERGIE_DEFAULT_GREEN;
    const trackColor = tinycolor(handleColor).setAlpha(0.35).toRgbString();
    const matSlideToggleElement = this.$toggle?._switchElement.nativeElement;

    if (matSlideToggleElement) setMatSlideToggleColorsSelected(matSlideToggleElement, handleColor, trackColor);
  }

  public getLayerDividerStyle() {
    if (this.layer.settings.checkable) {
      const toggleColor = this.layer.settings.toggleColor ?? SPINERGIE_DEFAULT_GREEN;
      const borderColor = tinycolor(toggleColor).setAlpha(0.5).toRgbString();
      return { 'border-color': borderColor };
    }

    return {};
  }

  public showLayerModeTooltip({ clientX, clientY }: MouseEvent): void {
    let tooltipText = 'Go back to latest positions mode'; // tooltip by default
    if (this.mapLayer.settings.layerType === 'latest') {
      tooltipText = this.checkIfCanEnterHistoricalMode()
        ? 'Access historical positions'
        : `You can access historical positions \n
      when less than ${this.maxTraces} vessels are selected`;
    }
    this.$tooltip.show(tooltipText, [clientX, clientY]);
  }

  public showAdvancedFiltersTooltip({ clientX, clientY }: MouseEvent): void {
    if (this.uiService.isXSmallDisplay()) {
      return;
    }
    const tooltipText = 'Show advanced filters';
    this.$tooltip.show(tooltipText, [clientX, clientY]);
  }

  public openSelector(advancedFieldsetsCollection: AdvancedFieldsetCollection): void {
    const entityIdsFromData = this.layer.fieldsetsGroup.getEntityIdsFromSidebarPopulateValues(
      advancedFieldsetsCollection.selectorConfig,
      this.$vesselField?.field(),
    );
    const selectorState: SelectorFullConfig = {
      advancedFieldsets: advancedFieldsetsCollection,
      afterFilterAction: filters => this.applySelectorFilters(filters, advancedFieldsetsCollection),
      appliedFilters: this.getLayerFilters(),
      entityIdsFromData,
      onAction: this.onaction,
    };
    this.dialogManager.openSelectorDialog(selectorState);
  }

  public applySelectorFilters(filters: LayerFilter, advancedFieldsetsCollection: AdvancedFieldsetCollection): void {
    if (filters.vessel != null) {
      filters.vessel.values = this.excludeVesselsWithoutCurrentLocation(filters.vessel.values);
    }

    this.$fieldsets.forEach($fieldset => {
      $fieldset.clear(false);
    });

    // pick a filter that will be send as latest-filter
    const latestFilter = advancedFieldsetsCollection.getAppliedEntityOrFirstFilter(filters);

    /** To be able to use latestFilter, we set without firing and call mergeAndEmit from here. */
    this.set(filters, false);
    this.mergeAndEmitFilters(latestFilter);
  }

  /**
   * Determine whether the sidebar layer has visible fields (it could have a toggle and no fields).
   * Fields can come from the layer's fieldsets->fields or be a checkbox to hide shapes and only keep a point instead.
   */
  public hasVisibleFields(): boolean {
    const hasFields = this.layer.fieldsetsGroup.getAllFilters().length > 0;
    const canHideShape = this.layerSetting?.showPointAlongsideShape && Boolean(this.layerSetting?.hideShapeLabel);

    return hasFields || canHideShape;
  }
  /**
   * Take a list of vessels and check for each vessel if it has a position.
   * If not the vessel is removed from the vessel list and a warning message is displayed
   */
  public excludeVesselsWithoutCurrentLocation(vesselIds: number[]): number[] {
    /**
     * if the layer has a historical mode we want to search on the latest position data as the historical position
     * state has only the selected vessels data
     */
    const vesselData = this.layer.data as DeepReadonly<PreparedVesselData[]>;
    const entityName = this.mapLayer.vessel.propTitle; // could be vessel or rig

    const excludeList = [];

    const vesselWithPosition = vesselIds.filter(vesselId => {
      const vessel = vesselData.find(v => v.vesselId === vesselId);
      const hasPosition = !(vessel.geometry.coordinates as number[]).some(c => c == null);
      if (!hasPosition) {
        excludeList.push(getChained(vessel, this.mapLayer.vessel.propTitle));
      }
      return hasPosition;
    });
    if (vesselIds.length > vesselWithPosition.length) {
      const dialog = {
        title: `Missing ${entityName} positions`,
        text: `The following ${entityName}(s) you selected don\'t have position: \n -`
          + excludeList.join('\n -') + '.\n These vessels have been removed from the filter',
      };

      this.ngZone.runTask(() => {
        this.dialog.open(DialogComponent, {
          data: {
            title: dialog.title,
            text: dialog.text,
          },
        });
      });
    }
    return vesselWithPosition;
  }

  public hideShapeFilterChanged(checked: boolean): void {
    this.onToggleShapeVisibility.emit({ layerId: this.layer.id, shapeHidden: checked });
  }

  public redirectLinkClick(event: MouseEvent, url: string): void {
    event.preventDefault();
    this.routerService.redirect(url);
  }

  public buttonClick(button: Button): void {
    if (!button.layer) button.layer = this.layer.id;
    this.onButtonClick.emit(button);
  }

  public openConfig(config: object): void {
    this.dialogManager.simpleDialog('Config', '', config);
  }

  public switchButtonContainer(): boolean {
    return this.isVesselLayer(this.layer.settings) && this.layer.visible && !this.layerSetting?.switchModeDisabled
      && this.layer?.filters?.vessel?.values?.length > 0;
  }
}
