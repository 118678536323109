import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector } from '@angular/core';
import { DecimalPipe, NgSwitch, NgSwitchCase } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CoordinateAxes, Coords, DMSCoords } from '../helpers/types';
import { PearlButtonComponent } from '../shared/pearl-components';

/**
 * DDM (decimal degree minute), DMS (decimal minute seconde), DD (decimal degree)
 *
 * DDM = 41°36.36N 041°36.00E;
 * DMS = 41°36'21.6″N 041°36'00"E;
 * DD  = 41.606 041.6
 */
export type CoordsType = 'DD' | 'DDM' | 'DMS';

export const COORDINATE_SEPARATOR = '-°,;.';

@Component({
  selector: 'spin-map-coords',
  templateUrl: 'map-coords.html',
  styles: [
    '.coords {width: 14rem; text-wrap: nowrap; display: flex; align-items: center; height: 100%; }',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgSwitch,
    NgSwitchCase,
    DecimalPipe,
    PearlButtonComponent,
    MatTooltipModule,
  ],
})
export class MapCoordsComponent {
  public coords: Coords = [0, 0];

  public showCoords: CoordsType = 'DDM';

  public dmsCoordinates: DMSCoords = ['', ''];
  public ddmCoordinates: DMSCoords = ['', ''];
  private cdRef: ChangeDetectorRef;

  /*
   * Forcing dot because there was an issue with macOS
   * Browser lang: en-UK
   * 17.37.toLocaleString('en-EN').substring(1, 2) would return . but <input type="number">
   * was expecting a , so for now we enforce the usage of dot, and remove type="number"
   *
   * Side effect, if you have two input side by side containing number you could have one
   * that use . and another using ,
   */

  public static DDM_REGEXP = {
    latitude: new RegExp(
      `^(([0-8][0-9][${COORDINATE_SEPARATOR}])+(([0-5][0-9])[${COORDINATE_SEPARATOR}])+([0-9]{1,2})[NS])$`,
    ),
    longitude: new RegExp(
      `^(([0-1]?[0-9]{1,2}[${COORDINATE_SEPARATOR}])+(([0-5][0-9])[${COORDINATE_SEPARATOR}])+([0-9]{1,2})[WE])$`,
    ),
  };

  private static DDM_AUTOCOMPLETE = new RegExp(
    `^([0-9]{0,3})[${COORDINATE_SEPARATOR}]?([0-9]{0,2})[${COORDINATE_SEPARATOR}]?([0-9]{0,2})[NSWE]?$`,
  );

  public static DDM_INPUT = {
    latitude: new RegExp(`[0-9${COORDINATE_SEPARATOR}NS']`),
    longitude: new RegExp(`[0-9${COORDINATE_SEPARATOR}EW']`),
  };

  constructor(injector: Injector) {
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  public updateCoords(latLng: [number, number]): void {
    this.coords = latLng;
    if (this.showCoords === 'DMS') {
      this.getDMSCoordinates();
    }

    if (this.showCoords === 'DDM') {
      this.getDDMCoordinates();
    }

    this.cdRef.detectChanges();
  }

  private getDMSCoordinates(): void {
    const lat = MapCoordsComponent.convertToDMS(this.coords[0], false);
    const long = MapCoordsComponent.convertToDMS(this.coords[1], true);
    this.dmsCoordinates = [lat, long];
  }

  private getDDMCoordinates(): void {
    const lat = MapCoordsComponent.convertToDDM(this.coords[0], false);
    const long = MapCoordsComponent.convertToDDM(this.coords[1], true);
    this.ddmCoordinates = [lat, long];
  }

  // convert decimal degrees to degrees minutes and seconds
  public static convertToDMS(dd: number, isLongitude: boolean): string {
    const direction = dd < 0 ? (isLongitude ? 'W' : 'S') : isLongitude ? 'E' : 'N';
    const absDd = Math.abs(dd);
    const degrees = absDd | 0;
    const frac = absDd - degrees;
    const min = (frac * 60) | 0;
    const sec = Math.round((frac * 3600 - min * 60) * 100) / 100;
    return `${degrees}°${min}.${sec}${direction}`;
  }

  private static degreesToString(deg: number, isLongitude: boolean): string {
    const paddingZeros = isLongitude ? 3 : 2;
    const degrees = deg.toString().padStart(paddingZeros, '0');
    return degrees;
  }

  private static minutesToString(min: number): string {
    const minutes = parseFloat(min.toString()).toFixed(2);
    const minParts = minutes.split('.');

    minParts[0] = minParts[0].padStart(2, '0');
    return minParts.join('.');
  }

  /**
   * @description this function convert decimal degrees to decimal degrees minutes
   * @param dd number - coordinate as decimal degree (example: 12.00)
   * @param isLongitude boolean
   * @returns string - coordinate as decimal degree minutes (example 12°00.00N)
   * @example
   * ```typescript
   * convertToDDM(12.00, true); // -> 12°00.00E
   * convertToDDM(12.00, false); // -> 12°00.00N
   * ```
   */
  public static convertToDDM(dd: number, isLongitude: boolean): string {
    if (!dd) {
      return '';
    }

    const direction = dd < 0 ? (isLongitude ? 'W' : 'S') : isLongitude ? 'E' : 'N';
    const absDd = Math.abs(dd);
    const deg = absDd | 0;
    const frac = absDd - deg;
    const min = frac * 60;

    const minutes = this.minutesToString(min);
    const degrees = this.degreesToString(deg, isLongitude);

    return `${degrees}°${minutes}${direction}`;
  }

  /**
   * @description this function convert decimal degrees to decimal degrees minutes
   * @param ddm number - coordinate as decimal degree minutes (example 12°00.00N)
   * @param coordinateType CoordinateAxes
   * @returns string - coordinate as decimal degree (example: 12.00)
   *
   * @example
   * ```typescript
   * convertToDDM('12°00.00E'); // -> 12.00
   * convertToDDM('12°00.00N'); // -> 12.00
   * ```
   */
  public static convertToDD(ddm: string, coordinateType: CoordinateAxes): number {
    if (!MapCoordsComponent.DDM_REGEXP[coordinateType].test(ddm)) return 0;

    const result = MapCoordsComponent.DDM_AUTOCOMPLETE.exec(ddm).slice(1, 4);
    const coefficient = ddm.charAt(ddm.length - 1) === 'S' || ddm.charAt(ddm.length - 1) === 'W' ? -1 : 1;

    return coefficient * +(+result[0] + (+(`${result[1]}.${result[2]}`) / 60)).toFixed(4);
  }

  /**
   * @description complete decimal degress minutes from string with part that is missing
   * @param ddm string - current string entered by user or given by the code part calling this methode
   * @param coordinateType LatLng
   * @returns string - completed string as valid decimal degrees minutes
   *
   * @example
   * ```typescript
   * completeDDM('12', 'latitude') // -> 12°00.00N
   * completeDDM('12', 'longitude') // -> 012°00.00E
   *
   * completeDDM('12°00', 'latitude') // -> 12°00.00N
   * completeDDM('12°00', 'longitude') // -> 012°00.00E
   *
   * completeDDM('12°00.00', 'latitude') // -> 12°00.00N
   * completeDDM('12°00.00', 'longitude') // -> 012°00.00E
   * ```
   */
  public static completeDDM(ddm: string, coordinateType: CoordinateAxes): string {
    if (this.DDM_REGEXP[coordinateType].test(ddm)) return ddm;

    const coordinateToBeCompleted = this.DDM_AUTOCOMPLETE.exec(ddm);
    if (!coordinateToBeCompleted) return '';

    let cardinalPoint = coordinateType === 'latitude' ? 'N' : 'E';
    if (coordinateToBeCompleted[0].endsWith('S') && coordinateType === 'latitude') cardinalPoint = 'S';
    if (coordinateToBeCompleted[0].endsWith('W') && coordinateType === 'longitude') cardinalPoint = 'W';

    const coordinatePart = coordinateToBeCompleted.slice(1, 4).map((part, i) => {
      if (part === '') return '00';
      return i === 2 ? part.padEnd(2, '0') : part.padStart(2, '0');
    });

    if (coordinateType === 'longitude' && coordinatePart[0].length < 3) {
      coordinatePart[0] = `0${coordinatePart[0]}`;
    }

    return `${coordinatePart[0]}°${coordinatePart[1]}.${coordinatePart[2]}${cardinalPoint}`;
  }

  // switch to decimal degrees
  public switchToDD(): void {
    this.showCoords = 'DD';
  }

  public switchToDDM(): void {
    this.showCoords = 'DDM';
    this.getDDMCoordinates();
  }

  public switch(): void {
    if (this.showCoords === 'DDM') {
      this.switchToDD();
    } else {
      this.switchToDDM();
    }
  }
}
