@if (showLegend) {
  <div class="label-selects">
    @if (baseMapLabelsToggleType() !== "hidden") {
      <mat-slide-toggle
        [matTooltip]="labelsTooltip()"
        [checked]="showBaseMapLabels"
        [disabled]="baseMapLabelsToggleType() === 'disabled'"
        (change)="toggleBaseMapLabels($event)">
        Base map labels
      </mat-slide-toggle>
    }
    <mat-slide-toggle [matTooltip]="labelsTooltip()" [checked]="showLabels" (change)="toggleLabels($event)">
      Entity labels
    </mat-slide-toggle>
  </div>
  <div class="legend-body body-small" #legendBody>
    @for (legendState of visibleLegends; track legendState) {
      <div [hidden]="!legendState.show" class="layer">
        <div class="layer-header">
          @if (legendState.colorBys.length > 1) {
            <pearl-select
              [small]="true"
              [label]="legendState.title"
              [options]="legendState.colorBys"
              [id]="legendState.id"
              [options]="legendState.colorBys"
              [disabled]="!legendState.colorBys"
              [selectedValues]="[legendState.currentColorBy.value]"
              (optionSelected)="colorByChanged(legendState, $event)" />
          } @else {
            <div class="layer-title body-default">
              <span>{{ legendState.title }}</span>
              @if (legendState.colorBys.length == 1) {
                <span>: {{ legendState.colorBys[0].title }}</span>
              }
            </div>
          }
          @if (!legendState.noExport && legendState.total > 0) {
            <span [matTooltip]="getExportTooltipText(legendState)">
              <pearl-button
                type="icon"
                size="small"
                icon="download"
                class="legend-export-icon"
                [ngClass]="{ disabled: isLayerLegendInvalidForExport(legendState) }"
                [disabled]="isLayerLegendInvalidForExport(legendState) || config.trialModeDownloadsDisabled"
                (click)="export(legendState)" />
            </span>
          }
        </div>
        @if (layerHasPatterns(legendState.patterns)) {
          <ul>
            @for (pattern of prepareLayerPatterns(legendState.patterns); track pattern) {
              <li>
                <svg class="square-border">
                  @if (pattern.id.startsWith("stroke")) {
                    <rect [attr.stroke]="pattern.fill" stroke-width="3" width="100%" height="100%"></rect>
                  } @else if (pattern.id.startsWith("fill")) {
                    <rect [attr.fill]="pattern.fill" width="100%" height="100%"></rect>
                  } @else {
                    <g class="square-pattern">
                      <rect fill="#888888" width="100%" height="100%"></rect>
                      <rect [attr.fill]="pattern.url" width="100%" height="100%"></rect>
                    </g>
                  }
                </svg>
                <span class="legend-title">{{ pattern.title }}</span>
                <span class="legend-count">{{ pattern.count || 0 }}</span>
              </li>
            }
          </ul>
        }
        @if (legendState.mapDash) {
          <div>
            <svg class="dash-line" width="16" height="16">
              <line x1="0" y1="8" x2="16" y2="8"></line>
            </svg>
            <span>{{ legendState.mapDash.title }}</span>
          </div>
        }
        <!-- Layer.colorBy -->
        @if (legendState.currentColorBy?.continuous) {
          <div class="legend-continuous" [style.background]="getGradient(legendState.currentColorBy)"></div>
          <div class="bounds">
            <span>{{ legendState.currentColorBy.min | number: "1.0-3" }}</span>
            <span>{{ legendState.currentColorBy.max | number: "1.0-3" }}</span>
          </div>
        } @else if (legendState.currentColorBy?.colors) {
          <ul>
            @for (color of legendState.currentColorBy.colors; track color) {
              <li [ngClass]="{ hide: !color.visible }">
                @if (color.visible) {
                  <div class="square-border" [ngStyle]="svgPaintAttributesToCss(color.style)"></div>
                  <span class="legend-title">{{ color.title || color.id }}&nbsp;</span>
                  @if (color.count != null) {
                    <span class="legend-count">{{ color.count }} </span>
                  }
                }
              </li>
            }
          </ul>
          <!-- Shown only on layers without any colorBy, but with a global style -->
        } @else if (legendState.total) {
          <ul>
            <li>
              @if (legendState.globalStyle) {
                <div class="square-border" [ngStyle]="svgPaintAttributesToCss(legendState.globalStyle)"></div>
              }
              <span class="legend-title">No colouring</span>
              <span class="legend-count">{{ legendState.total }} </span>
            </li>
          </ul>
        }
      </div>
    }
    <!-- /Layer -->
    @if (showShomDocumentation()) {
      <!-- Original link is https://diffusion.shom.fr/shom_downloadable/download/freelink/product_id/22/ -->
      <a
        href="https://spinpublicdocuments.s3.eu-central-1.amazonaws.com/_misc/shom_symbols_on_marine_maps_Xdhr343F8.pdf"
        target="_blank"
        class="external-link">
        Nautical charts legend
        <pearl-icon icon="open_in_new" [size]="24"></pearl-icon>
      </a>
    }
    @if (showSatelliteDocumentation()) {
      <a
        href="https://docs.mapbox.com/data/tilesets/reference/mapbox-satellite/#data-sources"
        target="_blank"
        class="external-link">
        Satellite imagery sources
        <pearl-icon icon="open_in_new" [size]="24"></pearl-icon>
      </a>
    }
  </div>
  <!-- /Body -->
}
