<div
  *ngIf="componentSettings()"
  class="kpis-container"
  [ngClass]="{ 'with-comparison': componentSettings().comparisonConfig }">
  <div class="component-title" *ngIf="componentSettings().title || componentSettings().description">
    <div class="kpis-title-subtitle">
      <div *ngIf="componentSettings().title" class="kpis-title">{{ componentSettings().title }}</div>
      <div class="kpis-subtitle" *ngIf="componentSettings().subtitle">{{ componentSettings().subtitle }}</div>
    </div>
    <description-button [description]="componentSettings().description"></description-button>
  </div>
  <div class="kpis">
    <ng-container *ngFor="let field of componentSettings().fields">
      <pearl-kpi
        [fieldSettings]="field"
        [value]="getValue(field)"
        [comparison]="getComparisonData(field)"
        [target]="getTargetValue(field)"
        targetExceededMessage="Initial target has been met! Please update target if necessary"
        [loading]="isDataLoading()"
        (buttonClick)="onFieldButton($event)" />
    </ng-container>
  </div>
</div>
