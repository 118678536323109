import { Config } from '../config/config';
import { LayerFilter } from '../helpers/types';
import { SelectableEntityConfig, SelectableEntityInfo, SelectorConfig } from './selector.types';
import { PageLinkHelper } from '../helpers/page-link-helper';

export class SelectorHelper {
  public static getNumberAdvancedFilterApplied(advancedFilters: LayerFilter): number {
    let count = 0;
    const filters = Object.values(advancedFilters ?? {});
    filters.forEach(filter => {
      if (filter.active === false || filter.notApplied) {
        return;
      }
      if (filter.filterType === 'checkbox' && (!filter.values || !filter.values.length || !filter.values[0])) {
        return;
      }
      if (filter.filterType === 'excludemulti' && !filter.values.length) {
        return;
      }
      count++;
    });
    return count;
  }

  public static getSelectorButtonLabel(selectorConfig: SelectorConfig): string {
    if (selectorConfig === null || selectorConfig === undefined) {
      return null;
    }
    return `${selectorConfig.entity.name} selection`;
  }

  /**
   * From a SelectableEntityConfig and main Config, compute properties url and pageLinkHelper.
   * Config is used to compute pageLinkHelper and check if user fleet has to be skipped.
   */
  public static buildEntityInfo(
    { name, titleProp, idProp, url, filterId }: SelectableEntityConfig,
    config: Config,
  ): SelectableEntityInfo {
    return {
      name,
      titleProp,
      idProp,
      filterId,
      /**
       * If given entity is vessel and skipUserFleet is set, take whole client vessel scope instead of userVesselFleet
       * as input data.
       */
      url: name === 'vessel' && config.skipUserFleet ? '@local<items>vessel' : url,
      pageLinkHelper: PageLinkHelper.inferFromKey(name, config.availablePages),
    };
  }

  /* For now, this function is only used by the comparator selector, so the wording is "compared". */
  public static computeMaxSelectionMsg(maxItemCount: number): string {
    return `Only ${maxItemCount} elements can be compared simultaneously.`;
  }
}
