import { Coord, Units } from '@turf/helpers';
import distance from '@turf/distance';
import { memoize } from 'lodash-es';

import { FieldSettings, FilterApplied } from '../../helpers/types';
import { DistanceToEntityDeserializedValues, DistanceToEntityFilterConfig, DistanceUnit,
  EntityForDistanceConfig } from './distance-to-entity.types';
import { RefDataProvider } from '../../data-loader/ref-data-provider';
import { upperCaseFirstLetter } from '../../helpers/d3-helpers';

const TURF_UNITS_MAPPING: { [unit: string]: Units } = {
  nm: 'nauticalmiles',
  km: 'kilometers',
};

export class DistanceToEntityHelper {
  /** CoordProvider implements a memoizing mechanism, so coord for a given entity are retrieved once. */
  private static coordProviders: { [refData: string]: CoordProvider } = {};

  /**
   * Filter an item with respect to a distanceToEntity FilterApplied config.
   * @param coord coord of item to be filtered
   * @param filterApplied config of distanceToEntity FilterApplied
   * @returns boolean
   */
  public static filterCoordDistanceToEntity(
    coord: Coord,
    { values, entityConfig, distanceConfig }: FilterApplied,
  ): boolean {
    if (values.length < 2) {
      // error handling to be done
      return true;
    }
    const { entityIds, maxDistance } = this.deserializeFilterValues(values);

    this.coordProviders[entityConfig.refDataset] ??= new CoordProvider(entityConfig);

    return entityIds.every(entityId => {
      const entityCoord = this.coordProviders[entityConfig.refDataset].getCoord(entityId);
      return this.distance(entityCoord, coord, distanceConfig.unit) <= maxDistance;
    });
  }

  /** Compute distance between 2 points */
  public static distance(coord1: Coord, coord2: Coord, unit: DistanceUnit): number {
    const turfUnit = TURF_UNITS_MAPPING[unit];

    return distance(coord1, coord2, { units: turfUnit });
  }

  /** Return an array of entityIds + the distance appended at the end. */
  public static serializeFilterValues(entityIds: number[], distance: number): number[] {
    return [...entityIds, distance];
  }

  /** Return an object containing entityIds and maxDistance */
  public static deserializeFilterValues(values: number[]): DistanceToEntityDeserializedValues {
    const entityIds = values.slice(0, -1).map(value => Number(value));
    const maxDistance = Number(values.at(-1));

    return { entityIds, maxDistance };
  }

  /** Build entity field settings from distanceToEntity Field settings */
  public static buildEntityFieldConfig(field: DistanceToEntityFilterConfig): FieldSettings {
    const { name } = field.entityConfig;
    return {
      id: name,
      filterType: 'multi',
      visible: true,
      value: [],
      title: upperCaseFirstLetter(`${name}s`),
    };
  }

  /** Build distance field settings from distanceToEntity Field settings */
  public static buildDistanceFieldConfig(field: DistanceToEntityFilterConfig): FieldSettings {
    const { unit, max, step } = field.distanceConfig;
    const min = field.distanceConfig.min ?? 0;
    const value = field.distanceConfig.default ?? (min + max) / 2;

    return {
      id: 'maxDistance',
      filterType: 'single',
      visible: true,
      title: `Max radial distance (${unit})`,
      min,
      max,
      step,
      default: value,
    };
  }
}

/**
 * Class responsible to get coord for an entityId.
 * Implement a memoizing mechanism to quickly access entities.
 */
class CoordProvider {
  constructor(private entityConfig: EntityForDistanceConfig) {
  }

  /** memoize stores function result to be accessed quickly. */
  public getCoord = memoize((entityId: number) => {
    const entity = RefDataProvider.getLocalItemIfLoaded(this.entityConfig.refDataset, entityId);

    return [entity[this.entityConfig.lonProp] as number, entity[this.entityConfig.latProp] as number];
  });
}
