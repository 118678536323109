import { StringHelper } from '../helpers/string-helper';
import { ChartSelect, SelectableMetric, SelectableValue } from './chart-types';

export class ChartSelectsHelper {
  /**
   * Find selected value from component state.
   * FIXME: remove legacy support when all JSON & dynamic configs are OK.
   */
  public static findSelectValue<T extends SelectableValue>(valueId: string, select: ChartSelect<T>): T | null {
    /*
     * in some situations the metric is not in the list of metrics
     * that can happen if the selects are not there, eg metric not in the list
     */
    if (!select?.values) {
      return null;
    }

    /*
     * We are looking for the object of `select.values` with `id === valueId`
     * or `value === valueId` (legacy).
     * Only look for the first value if multiple are supported.
     */
    valueId = StringHelper.splitByCommas(valueId)[0];

    /*
     * go over all options for the given select (all groupbys/metrics etc)
     * and find the one that is selected
     */
    for (const selectValue of select.values) {
      // Look in `id` first (default) else in `value` (legacy)
      if (selectValue.id === valueId || selectValue.value === valueId) {
        return selectValue;
      }
    }

    // If the value could not be found we return the first one it was a custom field id or if `force === true`
    if (select.force || this.selectValueIsACustomField(valueId)) {
      const value = this.firstSelectValue<T>(select);
      if (value) {
        return value;
      }
    }

    // if we are here the select wasn't found inside the above loop.
    console.warn(
      'Could not find ' + valueId + ': ' + JSON.stringify(valueId) + ' in: ' + JSON.stringify(select),
    );
    return null;
  }

  /** Find given metrics by IDs */
  public static findSelectMetrics(metricIds: string[], select: ChartSelect<SelectableMetric>): SelectableMetric[];
  /**
   * Find selected metrics by ID (default), or by value (fallback)
   * @param serializedValue ids (or values) as a comma-separated string
   * @deprecated pass metric ids as an array
   */
  public static findSelectMetrics(serializedValue: string, select: ChartSelect<SelectableMetric>): SelectableMetric[];
  public static findSelectMetrics(
    metricIds: string | string[],
    select: ChartSelect<SelectableMetric>,
  ): SelectableMetric[] {
    const metrics: SelectableMetric[] = [];

    // TODO: Remove when the deprecated overload is deleted
    const selectValueIdsToFind = typeof metricIds === 'string' ? this.convertMetricStringToArray(metricIds) : metricIds;

    for (const selectValue of select.values) {
      if (selectValueIdsToFind.includes(selectValue.id) || selectValueIdsToFind.includes(selectValue.value)) {
        metrics.push(selectValue);
      }
    }
    return metrics;
  }

  /**
   * Return the first value of a ChartSelect
   */
  public static firstSelectValue<T extends SelectableValue>(select: ChartSelect<T>): T | null {
    return select?.values?.[0] ?? null;
  }

  /**
   * Find selected value from its ID (default) or value (legacy).
   * FIXME: remove legacy support when all JSON & dynamic configs are OK.
   */
  public static findSelectValueById<T extends SelectableValue>(values: T[], idOrValue: string): T {
    return values?.find(v => (v.id === idOrValue || v.value === idOrValue));
  }

  /** TODO: delete once comma separated metric strings are removed from config */
  private static convertMetricStringToArray(metric: string | null): string[] {
    return metric ? StringHelper.splitByCommas(metric) : [];
  }

  /** Check if select value property name starts with 'cf_'. */
  private static selectValueIsACustomField(selectValue: string): boolean {
    return /cf_\w+$/gm.test(selectValue);
  }
}
