<mat-form-field [floatLabel]="forceFloatingLabel()">
  @if (iconPrefix() && !readonly()) {
    <pearl-icon matIconPrefix [icon]="iconPrefix()" [size]="iconSize()" />
  }

  @if (hasLabel()) {
    <mat-label>{{ label() }}</mat-label>
  }

  <ng-content />

  <div class="form-suffix" matSuffix>
    <ng-content select="[pearlFormSuffix]" />
  </div>

  @if (hintStart() && !hintError()) {
    <mat-hint [innerHTML]="hintStart()" />
  }

  @if (hintError()) {
    <mat-hint [innerHTML]="errorMessage()" />
  }

  @if (hintEnd()) {
    <mat-hint align="end" [innerHTML]="hintEnd()" />
  }

  @if (showError()) {
    <mat-error align="end">{{ errorMessage() }}</mat-error>
  }

  @if (iconSuffix() && !displayEditSuffix()) {
    <pearl-icon matIconSuffix [icon]="iconSuffix()" [size]="iconSize()" />
  }

  @if (displayEditSuffix()) {
    <pearl-icon (click)="edit.emit()" matIconSuffix icon="edit" [size]="iconSize()" />
  }
</mat-form-field>
