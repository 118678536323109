import { Directive, ElementRef, Input, OnDestroy, OnInit, booleanAttribute, forwardRef, inject,
  output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator,
  ValidatorFn, Validators } from '@angular/forms';
import { MAT_FORM_FIELD, MatFormField } from '@angular/material/form-field';
import { MAT_INPUT_VALUE_ACCESSOR } from '@angular/material/input';

import { Subject, Subscription } from 'rxjs';
import dayjs, { Dayjs } from 'dayjs';

import { PearlDatepickerBase } from './datepicker-base';
import { DateFilter } from './datepicker-types';
import { TimezoneService } from '../../../../helpers/timezone.service';
import { DateHelper } from '../../../../helpers/date-helper';
import { getDatetimeElementFromPositionInFormat, getDatetimeElementRangeInFormat } from './datepicker-formats';

const DATEPICKER_VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => PearlDatepickerInputDirective),
  multi: true,
};

const DATEPICKER_VALIDATORS = {
  provide: NG_VALIDATORS,
  useExisting: forwardRef(() => PearlDatepickerInputDirective),
  multi: true,
};

/**
 * Date input transform function
 * @param date a Dayjs date or ISO 8601 string
 * @returns Dayjs date or `null` if the date parameter is not of the expected type or format
 */
function dateAttribute(date: unknown): Dayjs | null {
  return dayjs.isDayjs(date) || typeof date === 'string' ? dayjs(date) : null;
}

/**
 * @param date the date to check
 * @returns `date` if it is valid, `null` otherwise
 */
function getValidDateOrNull(date: Dayjs | null): Dayjs | null {
  return date === null || !date.isValid() ? null : date;
}

/**
 * An event used for datepicker input and change events. We don't always have access to a native
 * input or change event because the event may have been triggered by the user clicking on the
 * calendar popup. For consistency, we always use PearlDatepickerInputEvent instead.
 */
class PearlDatepickerInputEvent {
  /** The new value for the target datepicker input. */
  value: Dayjs | null;

  constructor(
    /** Reference to the datepicker input component that emitted the event. */
    public target: PearlDatepickerInputDirective,
    /** Reference to the native input element associated with the datepicker input. */
    public targetElement: HTMLElement,
  ) {
    this.value = this.target.value;
  }
}

/** Directive used to connect an input to a MatDatepicker. */
@Directive({
  selector: 'input[pearlDatepicker]',
  providers: [
    DATEPICKER_VALUE_ACCESSOR,
    DATEPICKER_VALIDATORS,
    { provide: MAT_INPUT_VALUE_ACCESSOR, useExisting: PearlDatepickerInputDirective },
    { provide: MAT_FORM_FIELD, useClass: MatFormField },
  ],
  host: {
    '[attr.aria-haspopup]': '_datepicker ? "dialog" : null',
    '[attr.aria-owns]': '(_datepicker?.opened && _datepicker.id) || null',
    '[attr.min]': 'min ? min.toISOString() : null',
    '[attr.max]': 'max ? max.toISOString() : null',
    '[disabled]': 'disabled',
    '(input)': 'onInput($event.target.value)',
    '(change)': 'onChange()',
    '(blur)': 'onBlur()',
    '(keydown)': 'onKeydown($event)',
    '(click)': 'onClick()',
  },
  exportAs: 'pearlDatepickerInput',
  standalone: true,
})
export class PearlDatepickerInputDirective implements ControlValueAccessor, OnInit, OnDestroy, Validator {
  private formField = inject(MAT_FORM_FIELD);
  private elementRef = inject<ElementRef<HTMLInputElement>>(ElementRef);
  private timezoneService = inject(TimezoneService);

  /** Emits when a `change` event is fired on this `<input>` or when a date is selected in the datepicker. */
  public readonly dateChange = output<PearlDatepickerInputEvent>();
  /** Emits when a timezone is selected in the datepicker */
  public readonly timezoneChange = output<string>();

  /** Emits when the value changes (either due to user input or programmatic change). */
  public readonly valueChange = new Subject<Dayjs | null>();

  /** Emits when the internal state has changed */
  public readonly stateChanges = new Subject<string>();

  /** Whether the last value set on the input was valid. */
  protected lastValueValid = false;

  /** Control value accessor callbacks */
  private onTouched = (): void => {/* noop */};
  private validatorOnChange = (): void => {/* noop */};
  private cvaOnChange = (_value: unknown): void => {/* noop */};

  private datepickerSubscription = Subscription.EMPTY;

  /** The datepicker that this input is associated with. */
  @Input()
  set pearlDatepicker(datepicker: PearlDatepickerBase) {
    if (datepicker) {
      this._datepicker = datepicker;
      this._datepicker.registerInput(this);
      this.subscribeToDatepicker();
    }
  }
  private _datepicker: PearlDatepickerBase;

  /**
   * Selected value on init. Changing this value after the init of the pearlDatepickerInput will have no effect.
   */
  @Input({ transform: dateAttribute })
  protected readonly initialValue: Dayjs | null = null;

  /** The value of the input. */
  @Input({ transform: dateAttribute })
  get value(): Dayjs | null {
    return this._value;
  }
  set value(value: Dayjs | null) {
    this.lastValueValid = value === null || value.isValid();

    const previousValue = this._value;

    const validDate = getValidDateOrNull(value);
    this._value = this.convertDateToCurrentTimezone(validDate);

    /*
     * Format and emit `valueChange` on actual change of date
     */
    if (this.datesAreDifferent(this._value, previousValue)) {
      /*
       * Only format if datepicker is instantiated, so we can get the type for formatting.
       * For the initial value, formatValue is called by ngOnInit.
       */
      if (this._datepicker) {
        this.formatValue();
      }
      this.valueChange.next(this._value);
    }
  }
  private _value: Dayjs | null = null;

  @Input()
  get timezone(): string {
    return this._timezone ?? this.timezoneService.timezone;
  }
  set timezone(timezone: string) {
    if (timezone === this._timezone) return;
    this._timezone = timezone;
    this.timezoneChange.emit(timezone);

    if (this.value) {
      // Keep the same local time in the new timezone (eg. 15:30 UTC+1 -> 15:30 UTC+4)
      this.value = this.convertDateToCurrentTimezone(this.value, true);
    }
  }
  private _timezone: string | null = null;

  /** The minimum valid date. */
  @Input({ transform: dateAttribute })
  get minDate(): Dayjs | null {
    return this._minDate;
  }
  set minDate(value: Dayjs | null) {
    const validValue = getValidDateOrNull(value);

    if (this.datesAreDifferent(validValue, this._minDate)) {
      this._minDate = validValue;
      this.validatorOnChange();
    }
  }
  private _minDate: Dayjs | null = null;

  /** The maximum valid date. */
  @Input({ transform: dateAttribute })
  get maxDate(): Dayjs | null {
    return this._maxDate;
  }
  set maxDate(value: Dayjs | null) {
    const validValue = getValidDateOrNull(value);

    if (this.datesAreDifferent(validValue, this._maxDate)) {
      this._maxDate = validValue;
      this.validatorOnChange();
    }
  }
  private _maxDate: Dayjs | null = null;

  /** Function that can be used to filter out dates within the datepicker. */
  @Input()
  get dateFilter(): DateFilter | null {
    return this._dateFilter;
  }
  set dateFilter(value: DateFilter) {
    this._dateFilter = value;
    this.validatorOnChange();
  }
  private _dateFilter?: DateFilter;

  /** Whether the datepicker-input is disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    if (this._disabled !== value) {
      this._disabled = value;
      this.stateChanges.next('disabled');
    }
  }
  private _disabled: boolean;

  subscribeToDatepicker(): void {
    if (this._datepicker) {
      this.datepickerSubscription = this._datepicker.dateSelected.subscribe((selected: Dayjs | null) => {
        this.value = selected;
        this.cvaOnChange(selected);
        this.onTouched();
        this.dateChange.emit(new PearlDatepickerInputEvent(this, this.elementRef.nativeElement));
      });
      this.datepickerSubscription.add(this._datepicker.timezoneSelected.subscribe((tz: string) => {
        this.timezone = tz;
      }));
      this.datepickerSubscription.add(this._datepicker.stateChanges.subscribe((changes: string[]) => {
        if (changes.some(c => ['precision', 'timeOnly', 'withTimezone'].includes(c))) {
          this.formatValue();
        }
      }));
    }
  }

  ngOnInit(): void {
    this.value = this.initialValue ?? this.value;
    // Explicit call to formatValue here because it may not have been called by this.value setter yet.
    this.formatValue();
  }

  ngOnDestroy(): void {
    this.datepickerSubscription.unsubscribe();
    this.valueChange.complete();
    this.stateChanges.complete();
  }

  registerOnValidatorChange(fn: () => void): void {
    this.validatorOnChange = fn;
  }

  validate(c: AbstractControl): ValidationErrors | null {
    return this.validator ? this.validator(c) : null;
  }

  /** Gets the element that the datepicker popup should be connected to. */
  getConnectedOverlayOrigin(): Element | ElementRef {
    // Connect to the field wrapper rather than on the input if possible not to hide part of the field.
    return this.elementRef.nativeElement.closest('.mat-mdc-text-field-wrapper')
      ?? this.formField?.getConnectedOverlayOrigin()
      ?? this.elementRef;
  }

  getParseFormats(): string[] {
    return this._datepicker.getParseFormats();
  }

  getDisplayFormat(): string {
    return this._datepicker.getDisplayFormat();
  }

  // Implemented as part of ControlValueAccessor
  writeValue(value: Dayjs): void {
    this.value = value;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnChange(fn: typeof this.cvaOnChange): void {
    this.cvaOnChange = fn;
  }

  // Implemented as part of ControlValueAccessor.
  registerOnTouched(fn: typeof this.onTouched): void {
    this.onTouched = fn;
  }

  // Implemented as part of ControlValueAccessor.
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  private onInput(value: string): void {
    /*
     * If the input strictly matches the display format, the user is probably editing DatetimeElements one by one.
     * In this case we want to automatically select the next DatetimeElement for quick editing.
     */
    let autoSelectNextDatetimeElement = true;
    let date = dayjs(value, this.getDisplayFormat(), true);
    // If a date could not be parsed, try non-strict parsing with all parsing formats & do not auto-select next element.
    if (!date.isValid()) {
      autoSelectNextDatetimeElement = false;
      date = dayjs(value, this.getParseFormats());
    }

    date = getValidDateOrNull(date);

    if (date) {
      /*
       * For time-only picker, keep the date part if set.
       * Because of DST there can be a different UTC offset depending on the date. Emitting `dateChange` with the date
       * used for [value]="..." in the parent template reduces the risk of manipulation errors, likely to result in
       * unwanted time shifts.
       */
      if (this._datepicker.timeOnly && this.value?.isValid()) {
        date = this.value
          .hour(date.hour())
          .minute(date.minute())
          .second(date.second());
      }

      // If the datepicker mode does not allow selecting timezone, we parse inputs in the current timezone
      date = this.convertDateToCurrentTimezone(date, !this._datepicker.withTimezone);
      date = this._datepicker.roundDateToPrecision(date);
    }

    this._value = date;
    this.lastValueValid = date !== null;

    this.cvaOnChange(date);
    this.valueChange.next(date);

    if (autoSelectNextDatetimeElement) {
      // Trigger Right arrow action: select next DatetimeElement.
      this.executeArrowKeyAction('ArrowRight');
    }
  }

  private onChange(): void {
    this.dateChange.emit(new PearlDatepickerInputEvent(this, this.elementRef.nativeElement));
  }

  /** Handles blur events on the input. */
  private onBlur(): void {
    // Reformat the input only if we have a valid value.
    if (this.value) {
      this.formatValue();
    }

    this.onTouched();
  }

  private onKeydown(event: KeyboardEvent): void {
    // Open popup on Alt + Down arrow
    if (event.altKey && event.key === 'ArrowDown') {
      this._datepicker.open();
      event.preventDefault();
      return;
    }

    const input = this.elementRef.nativeElement;
    /*
     * Navigate between datetime elements with left/right arrows, change value with up/down.
     * Only handle arrow keys if the value is set and a part of the input is selected.
     */
    if (event.key.startsWith('Arrow') && this.value !== null && input.selectionEnd > input.selectionStart) {
      event.preventDefault();
      event.stopPropagation();
      this.executeArrowKeyAction(event.key);
      return;
    }

    // Unselect the selected range on Escape to let the user use the arrows to move the cursor.
    if (event.key === 'Escape') {
      input.selectionStart = input.selectionEnd;
      return;
    }
  }

  private onClick(): void {
    const input = this.elementRef.nativeElement;
    // Do not interfere if the user selects a range
    if (!input.value || input.selectionStart !== input.selectionEnd) return;

    // Get the selection range for the datetime element under the cursor, and select it.
    const format = this.getDisplayFormat();
    const dateElement = getDatetimeElementFromPositionInFormat(format, input.selectionStart);
    const selectionRange = getDatetimeElementRangeInFormat(format, dateElement);

    input.setSelectionRange(...selectionRange);
  }

  /** Form control validator for whether the input parses. */
  private parseValidator: ValidatorFn = (): ValidationErrors | null => {
    return this.lastValueValid
      ? null
      : { 'pearlDatepickerParse': { 'text': this.elementRef.nativeElement.value } };
  };

  // TODO use isSameOrAfter ( or isSame || isAfter)
  /** Form control validator for the min date. */
  private minValidator: ValidatorFn = (control: AbstractControl<string>): ValidationErrors | null => {
    const controlValue = dayjs(control.value, this.getParseFormats());
    return !this.minDate || !controlValue.isValid() || controlValue.isAfter(this.minDate)
      ? null
      : { 'pearlDatepickerMinDate': { 'minDate': this.minDate, 'actual': controlValue } };
  };

  /** The form control validator for the max date. */
  private maxValidator: ValidatorFn = (control: AbstractControl<string>): ValidationErrors | null => {
    const controlValue = dayjs(control.value, this.getParseFormats());
    return !this.maxDate || !controlValue.isValid() || controlValue.isBefore(this.maxDate)
      ? null
      : { 'pearlDatepickerMaxDate': { 'maxDate': this.maxDate, 'actual': controlValue } };
  };

  /** Form control validator for the date filter. */
  private filterValidator: ValidatorFn = (control: AbstractControl<string>): ValidationErrors | null => {
    const controlValue = dayjs(control.value, this.getParseFormats());
    return !this._dateFilter || !controlValue.isValid() || this._dateFilter(controlValue)
      ? null
      : { 'pearlDatepickerFilter': true };
  };

  /** Combined form control validator for this input. */
  private validator: ValidatorFn | null = Validators.compose([
    this.parseValidator,
    this.minValidator,
    this.maxValidator,
    this.filterValidator,
  ]);

  private convertDateToCurrentTimezone(date: Dayjs | null, keepLocalTime = false): Dayjs | null {
    if (date === null) return null;

    if (this.timezone === 'local') {
      return date.local();
    }
    /*
     * keepLocalTime = true does not seem to work with tz() when utc offset === 0 -> using utc() instead
     * Several (many) issues on github linked to tz() not handling timezones with 0 offset properly,
     * for example: https://github.com/iamkun/dayjs/issues/2676
     */
    if (DateHelper.isUtcOffsetZero(date, this.timezone)) {
      return date.utc(keepLocalTime);
    }
    return date.tz(this.timezone, keepLocalTime);
  }

  /** Formats a value and sets it on the input element. */
  private formatValue(): void {
    this.elementRef.nativeElement.value = this.value !== null
      /*
       * Remove the minutes in UTC offset if it is 00 as formatting on 2 digits is not available in the formatter.
       * We do not remove the leading 0 on the hours because this format (1 digit) is not available in the parser.
       * ie: [...] (UTC+02:00) -> [...] (UTC+02)
       */
      ? this.value.format(this.getDisplayFormat()).replace(/:00\)$/, ')')
      : '';
  }

  /**
   * Checks that 2 nullable dates are different
   * @returns
   * `true` if
   *  - `date1` or `date2` is null and the other is a date object
   *  - both are date objects representing different dates, or the same date in different timezones
   *
   * `false` otherwise ie. `date1` and `date2` are both null, or the same date in the same timezone
   */
  private datesAreDifferent(date1: Dayjs | null, date2: Dayjs | null): boolean {
    return date1 !== date2 && (date1 === null || date2 === null
      || !date1.isSame(date2) || date1.utcOffset() !== date2.utcOffset());
  }

  /**
   * Update the input value and selected range for quick edition with keyboard arrow keys.
   *
   * Left and Right arrows select the previous/next DatetimeElement, Up and Down arrows increment/decrement the selected
   * DatetimeElement.
   *
   * This is called by {@link onKeydown} to handle actual keyboard events, ans by other methods to programmatically
   * trigger an action (eg. in {@link onInput} to go to the next element automatically).
   *
   * @param arrowKey the `KeyboardEvent.key` value for the desired action.
   * Possible values: `ArrowLeft`, `ArrowRight`, `ArrowUp`, `ArrowDown`
   */
  private executeArrowKeyAction(arrowKey: string): void {
    const input = this.elementRef.nativeElement;

    // Save selected range to re-focus it after value changes
    let selectionRange: [number, number] = [input.selectionStart, input.selectionEnd];

    const format = this.getDisplayFormat();
    const currentDatetimeElement = getDatetimeElementFromPositionInFormat(format, input.selectionStart);
    const availableDatetimeElements = this._datepicker.getAvailableDatetimeElements();

    // Left/Right arrow: find the previous/next element in datetime based on current format, and select it
    if (['ArrowLeft', 'ArrowRight'].includes(arrowKey)) {
      let elementIdx = availableDatetimeElements.indexOf(currentDatetimeElement);
      elementIdx += arrowKey === 'ArrowLeft' ? -1 : 1;
      elementIdx %= availableDatetimeElements.length; // go back to 0 if we are at the end of the array
      const nextElement = availableDatetimeElements.at(elementIdx); // use at() to get the last element if index is -1
      selectionRange = getDatetimeElementRangeInFormat(format, nextElement);
    } // Up/Down arrow: increment/decrement the current date element (Dayjs handles overflows)
    else if (['ArrowUp', 'ArrowDown'].includes(arrowKey)) {
      const elementCurrentValue = this.value.get(currentDatetimeElement);
      const elementNewValue = arrowKey === 'ArrowDown' ? elementCurrentValue - 1 : elementCurrentValue + 1;
      this.value = this.value.set(currentDatetimeElement, elementNewValue);
      this.onChange();
    }

    // Refocus the input and keep (Up/Down) or update (Left/Right) the selection range.
    input.focus();
    input.setSelectionRange(...selectionRange);
    return;
  }
}
