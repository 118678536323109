import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, inject, input, output,
  viewChild } from '@angular/core';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSlideToggleChange, MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DecimalPipe, NgClass, NgStyle } from '@angular/common';

import { Subscription, merge } from 'rxjs';

import { SchedulePatterns } from '../schedule/schedule-types';
import { ExportConfig } from '../helpers/types';
import { BasePaintStyle, ColorByState, LayerLegendState, ToggleDisplayType } from '../helpers/legend-types';
import { PearlButtonComponent } from './pearl-components/components/buttons/pearl-button.component';
import { Config } from '../config/config';
import { PearlFormFieldComponent, PearlIconComponent, PearlSelectComponent,
  PearlSelectOptionValueType } from './pearl-components';

@Component({
  selector: 'spin-legend',
  templateUrl: 'legend.html',
  styleUrls: ['legend.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgStyle,
    MatSlideToggleModule,
    MatTooltipModule,
    PearlButtonComponent,
    PearlFormFieldComponent,
    MatSelectModule,
    MatOptionModule,
    NgClass,
    DecimalPipe,
    PearlIconComponent,
    PearlSelectComponent,
  ],
})
export class LegendComponent implements OnDestroy {
  onexport = output<ExportConfig>();
  onToggleLabels = output<boolean>();
  baseMapLabelsToggled = output<boolean>();

  public readonly labelsTooltip = input<string>('');
  public readonly askedDisplayedLayers = input<string[]>([]);
  public readonly showShomDocumentation = input<boolean>(false);
  public readonly showSatelliteDocumentation = input<boolean>(false);
  public readonly baseMapLabelsToggleType = input<ToggleDisplayType>();

  @Input()
  public showBaseMapLabels = true;

  @Input()
  public showLabels = true;

  public readonly legendBody = viewChild<ElementRef<HTMLInputElement>>('legendBody');

  public toggleBaseMapLabels(event: MatSlideToggleChange): void {
    this.showBaseMapLabels = event.checked;
    this.baseMapLabelsToggled.emit(this.showBaseMapLabels);
  }

  private legendChangeSubscription: Subscription;
  private _legends: LayerLegendState[];

  public showLegend: boolean = false;

  public readonly config = inject(Config);
  public readonly cdRef = inject(ChangeDetectorRef);

  @Input()
  set legends(newLegends: LayerLegendState[]) {
    if (newLegends) {
      if (this.legendChangeSubscription) this.legendChangeSubscription.unsubscribe();
      this.legendChangeSubscription = merge(
        ...newLegends.map(legend => legend.layer?._legendStateUpdate$).filter(l => l),
      ).subscribe(() => this.cdRef.detectChanges());
    }
    this._legends = newLegends;
  }

  get legends(): LayerLegendState[] {
    return this._legends;
  }

  public ngOnDestroy(): void {
    this.legendChangeSubscription?.unsubscribe();
  }

  /**
   * Get visible layers to display
   */
  public get visibleLegends(): LayerLegendState[] {
    return (this.legends ?? []).filter(d =>
      d.id !== 'eta' && (Array.isArray(this.askedDisplayedLayers()) && this.askedDisplayedLayers().length > 0
        ? d.visible && this.askedDisplayedLayers().includes(d.id)
        : d.visible)
    );
  }

  public colorByChanged(legendState: LayerLegendState, newColorBy: PearlSelectOptionValueType): void {
    legendState.currentColorBy = legendState.colorBys.find(cb => cb.value === newColorBy);
    legendState.layer.updateLegend();
  }

  public export(layer: LayerLegendState): void {
    this.onexport.emit({
      layerId: layer.id,
      filename: layer.id,
    });
  }

  /**
   * Check if layer has patterns
   */
  public layerHasPatterns(patterns: SchedulePatterns): boolean {
    return patterns && Object.keys(patterns).length > 0;
  }

  /**
   * Create and prepare a list of patterns from the SchedulePattern object.
   *
   * @param  {SchedulePatterns} patterns          Schedule patterns
   * @return {object[]}         preparedPatterns  { SVG url, title, count } pairs
   */
  public prepareLayerPatterns(
    patterns: SchedulePatterns,
  ): { url: string; title: string; fill: string; count: number; id: string }[] {
    const preparedPatterns: { url: string; title: string; fill: string; count: number; id: string }[] = [];
    Object.entries(patterns).forEach(([patternId, pattern]) => {
      const title = pattern.map(p => p.title).join(', ');
      const fill = pattern[0].fill;
      const count = pattern.reduce((v, p) => v + p.count, 0);
      preparedPatterns.push({
        url: `url(#${patternId}-pattern)`,
        title,
        fill,
        count,
        id: patternId,
      });
    });
    return preparedPatterns;
  }

  /**
   * A layer can have a maximum
   */
  public isLayerLegendInvalidForExport(layer: LayerLegendState): boolean {
    return layer.maxExportableNumber && layer.total && layer.total > layer.maxExportableNumber;
  }

  protected getExportTooltipText(legendState: LayerLegendState): string {
    if (this.isLayerLegendInvalidForExport(legendState)) {
      return `You cannot export more than ${legendState.maxExportableNumber} entities. \
      Filter out some elements to enable XLSX download`;
    }
    return this.config.getXlsxDownloadTooltip(legendState.noExport);
  }

  public getGradient(continuousColorBy: ColorByState): string {
    return `linear-gradient(to right in hsl, ${continuousColorBy.colorsRange[0]}, ${continuousColorBy.colorsRange[1]})`;
  }

  /**
   * Toggle labels
   *
   * @param  {Event} event  Toggle bubbling event
   * @emits  onToggleLabels<boolean>
   * @return {void}
   */
  public toggleLabels(event: MatSlideToggleChange): void {
    this.showLabels = event.checked;
    this.onToggleLabels.emit(this.showLabels);
  }

  public svgPaintAttributesToCss(svgAttributes: BasePaintStyle): object {
    const style = {
      'background': svgAttributes.fill ?? svgAttributes.stroke,
    };

    if (svgAttributes['stroke-width']) {
      const borderColor = svgAttributes.stroke === '#000000' ? svgAttributes?.fill : svgAttributes?.stroke;
      style['border'] = `1px solid ${borderColor}`;
    }

    return style;
  }
}
