export function spinHTMLElement(element: HTMLElement): void {
  element.style.transitionDuration = '1s';
  element.style.transform = 'rotate(360deg)';
  setTimeout(() => {
    element.style.transitionDuration = '0s';
    element.style.transform = 'rotate(0deg)';
  }, 1000);
}

const MAT_SLIDE_TOGGLE_STYLE_PROPERTIES = {
  'handle': [
    '--mdc-switch-selected-hover-handle-color',
    '--mdc-switch-selected-focus-handle-color',
    '--mdc-switch-selected-pressed-handle-color',
    '--mdc-switch-selected-handle-color',
  ],

  'track': [
    '--mdc-switch-selected-track-color',
    '--mdc-switch-selected-hover-track-color',
    '--mdc-switch-selected-focus-track-color',
    '--mdc-switch-selected-pressed-track-color',
  ],
};

export function setMatSlideToggleColorsSelected(
  matSlideToggleElement: HTMLElement,
  handleColor: string,
  trackColor: string,
): void {
  MAT_SLIDE_TOGGLE_STYLE_PROPERTIES.handle.forEach(property => {
    matSlideToggleElement.style.setProperty(property, handleColor, 'important');
  });

  MAT_SLIDE_TOGGLE_STYLE_PROPERTIES.track.forEach(property => {
    matSlideToggleElement.style.setProperty(property, trackColor, 'important');
  });
}
