import { Component, ViewContainerRef, input, effect, viewChildren, ChangeDetectionStrategy } from '@angular/core';
import { CommonModule, KeyValuePipe } from '@angular/common';

import { TypeofPipe } from '../../../../pipes/typeof.pipe';

/** Define an helper formatter as a function to hook the resulting component to a container:
 * - viewContainerRef: where the result will be hooked (using viewContainerRef.createComponent)
 * - label: name of the represented value
 * - data: [field, fieldReq] providing, through the component, you value and some ctx required to instanciate */
export type FormatterHelper<Field, FieldReq> = (
  viewContainerRef: ViewContainerRef,
  label: string,
  data: [Field, FieldReq],
) => void;

@Component({
  selector: 'pearl-tooltip-content',
  standalone: true,
  imports: [TypeofPipe, KeyValuePipe, CommonModule],
  templateUrl: './pearl-tooltip-content.component.html',
  styleUrl: './pearl-tooltip-content.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PearlTooltipContentComponent<TooltipContentField = unknown, TooltipContentFieldReq = unknown> {
  protected readonly fieldContainers = viewChildren('container', { read: ViewContainerRef });

  public readonly formatter = input<FormatterHelper<TooltipContentField, TooltipContentFieldReq>>();

  public readonly renderId = input<string>(crypto.randomUUID());

  public readonly title = input<string>();

  public readonly content = input<{ [key: string]: string | [TooltipContentField, TooltipContentFieldReq] }>();

  constructor() {
    effect(() => {
      // Keeping only content that need special formatting
      const contents = Object
        .entries(this.content())
        .filter(([, content]) => typeof content !== 'string') as [
          string,
          [TooltipContentField, TooltipContentFieldReq],
        ][];

      // Inserting in template containers through the provided formatter
      this.fieldContainers().forEach((container, i) => {
        const [label, content] = contents[i];
        this.formatter()(container, label, content);
      });
    });
  }
}
