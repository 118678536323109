<div class="spin-filters-checkbox">
  <mat-checkbox
    attr.data-testid="spin-filters-checkbox-{{ field.id }}"
    #checkbox
    [matTooltip]="field.description"
    matTooltipPosition="above"
    [ngClass]="{ 'checkbox-line-through-label': isIndeterminate() }"
    [color]="isIndeterminate() ? 'warn' : 'primary'"
    (change)="checkboxChange(checkbox)">
    {{ field.title }}
  </mat-checkbox>

  <span
    attr.data-testid="spin-filters-checkbox-exclude-{{ field.id }}"
    *ngIf="field.filterType === 'checkbox-exclude'"
    (click)="checkboxIndeterminateChange()">
    {{ spanExcludeLabel() }}
  </span>
</div>
