@if (icon()) {
  <pearl-icon class="page-icon" [size]="compact() ? 40 : 48" [icon]="icon()" />
}

<div class="primary-information">
  <div class="title-status">
    @if (displayMode() === "embedded") {
      <h4>{{ title() }}</h4>
    } @else {
      <h1>{{ title() }}</h1>
    }
    @if (status()) {
      <div class="status" [ngStyle]="status().style">
        @if (status().icon) {
          <pearl-icon [icon]="icon()" [size]="24" />
        }

        @if (!compact() || (compact() && !status().icon)) {
          {{ status().title }}
        }
      </div>
    }
    @if (quickNavListItems() && quickNavListItems().length) {
      <div>
        <pearl-button type="icon" icon="chevron_down" size="small" [pearlMenuTriggerFor]="quickNavList" />
        <pearl-menu #quickNavList [categories]="[{ items: quickNavListItems() }]" />
      </div>
    }
  </div>
  <div class="subtitle caption-default">
    {{ subtitle() }}
  </div>
</div>
