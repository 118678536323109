import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule, NgTemplateOutlet } from '@angular/common';
import { AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, ViewContainerRef, effect, inject, input, output,
  signal, viewChild } from '@angular/core';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';

import type { HidingBehavior, TooltipOptions } from './pearl-tooltip.types';
import { PearlTooltipUtils } from './pearl-tooltip.utils';
import { PearlTooltipHeaderComponent } from './pearl-tooltip-header/pearl-tooltip-header.component';
import { PearlTooltipContentComponent } from './pearl-tooltip-content/pearl-tooltip-content.component';
import { PearlTooltipActionsComponent } from './pearl-tooltip-actions/pearl-tooltip-actions.component';
import { ActionEvent, AfterSave } from '../../../../helpers/types';

const DEFAULT_DRAWER_BREAKPOINTS: string[] = [Breakpoints.XSmall];
const NO_COORDS = { x: 0, y: 0 };

@Component({
  selector: 'pearl-tooltip',
  host: {
    // non-navigable but focusable
    tabindex: '-1',
    '(focusout)': 'onFocusOut($event)',
  },
  standalone: true,
  imports: [
    CommonModule,
    NgTemplateOutlet,
    MatTabsModule,
  ],
  templateUrl: './pearl-tooltip.component.html',
  styleUrl: './pearl-tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PearlTooltipComponent implements AfterViewInit, OnDestroy {
  protected HeaderComponent = PearlTooltipHeaderComponent;
  protected ContentComponent = PearlTooltipContentComponent;
  protected ActionsComponent = PearlTooltipActionsComponent;

  protected readonly resizeObserver = new ResizeObserver(() => this.adjustPosition(true));

  private vcr = inject(ViewContainerRef);
  get host(): HTMLElement {
    return this.vcr.element.nativeElement as HTMLElement;
  }

  private tabGroup = viewChild('tabGroup', { read: MatTabGroup });

  private readonly breakpointObserver: BreakpointObserver = inject(BreakpointObserver);

  protected readonly selectedTab = signal<number>(0);
  protected onSelectedTabChange(tabIndex: number): void {
    this.selectedTab.set(tabIndex);
  }

  public readonly actionEventTriggered = output<ActionEvent>();

  public readonly updateEventTriggered = output<AfterSave>();

  /** Breakpoints under which we use drawer mode */
  public readonly breakpoints = input<string[]>(DEFAULT_DRAWER_BREAKPOINTS);

  /** Defining tooltip hiding behavior */
  public readonly hidingBehavior = input<HidingBehavior>('blur');

  /** Prevent tooltip to trigger */
  public readonly preventOpening = signal<boolean>(false);

  /** Where to position the tooltip */
  protected readonly cursorPosition = signal<{ x: number; y: number }>({ x: 0, y: 0 });

  /** Tell template to behave as a drawer (or floating) */
  protected readonly useDrawer = signal(false);

  /** List of components being rendered inside the tooltip */
  protected readonly innerComponentDefs = signal<TooltipOptions>({
    header: PearlTooltipHeaderComponent.EMPTY,
    widgets: [],
    kpis: [],
    contents: [],
  });

  private _onHideCallback?: () => void;

  private _requiredCoords: { x: number; y: number } = { x: 0, y: 0 };

  constructor() {
    // Ensure we look at the expected breakpoints
    effect(() => {
      const sub = this.breakpointObserver.observe(this.breakpoints()).subscribe(result => {
        this.useDrawer.set(result.matches);
      });

      return (): void => sub.unsubscribe();
    }, { allowSignalWrites: true });

    // Hide tooltip if currently open and prevent opening change to true
    effect(() => this.preventOpening() && this.hide());
  }

  public ngAfterViewInit(): void {
    if (!this.host) return;
    this.resizeObserver.observe(this.host);
  }

  public ngOnDestroy(): void {
    if (!this.host) return;
    this.resizeObserver.unobserve(this.host);
  }

  protected onFocusOut(event: FocusEvent): void {
    if (this.hidingBehavior() !== 'blur') {
      return;
    }

    const target = event.relatedTarget instanceof HTMLElement ? event.relatedTarget : null;
    if (
      !target
      || (
        // Losing focus to a child or to pearl-menu
        !this.host.contains(target) && !target.classList.contains('pearl-menu-item')
      )
    ) {
      this.hide();
    }
  }

  public show(
    options: TooltipOptions,
    event?: MouseEvent,
    coords: { x: number; y: number } = NO_COORDS,
    onHideCallback?: () => void,
  ): void {
    this._onHideCallback = onHideCallback;
    const validatedOptions = PearlTooltipUtils.validateOptions(options);
    this._requiredCoords = coords;

    // Reset selected tab to 0, preventing random tab to stay open
    this.selectedTab.set(0);

    // Defining it's own data
    this.innerComponentDefs.set({
      ...validatedOptions,
      header: {
        ...validatedOptions.header,
        // Extending close handler to bind local behavior
        closeHandler: () => {
          if (validatedOptions.header.closeHandler) {
            validatedOptions.header.closeHandler();
          }
          this.hide();
        },
      },
      actions: {
        ...validatedOptions.actions,
        closeMenuHandler: () => {
          // Claiming back focus when action menu is closed
          this.host.focus();
        },
      },
    });

    // Position the element if we have an MouseEvent, and no coords
    if (event && coords.x === 0 && coords.y === 0) {
      const { width, height } = PearlTooltipUtils.getActualElementSize(this.host);
      this._requiredCoords = { x: event.clientX, y: event.clientY };
      const idealCoords = PearlTooltipUtils.getIdealPosition(this._requiredCoords, width, height);
      this.position(idealCoords);
    } else {
      this.position(coords);
    }

    // Making the component visible
    this.host.classList.add('visible');

    // Refers hiding behavior specifics
    if (this.hidingBehavior() === 'blur') {
      this.host.focus();
    } else if (typeof this.hidingBehavior() === 'number') {
      setTimeout(() => this.host.classList.remove('visible'), Number(this.hidingBehavior()));
    }

    // Re-running positionning after in case the content was adjusted a bit too slowly
    setTimeout(() => this.adjustPosition(), 0);
  }

  public hide(): void {
    // Removing focus on the host or child item contained inside the tooltip
    this.host.classList.remove('visible');
    if (this.hidingBehavior() === 'blur' && this.host.contains(document.activeElement)) {
      (document.activeElement as HTMLElement).blur();
    }

    if (this._onHideCallback) {
      this._onHideCallback();
      delete this._onHideCallback;
    }
  }

  public position({ x, y }: { x: number; y: number }): void {
    this.host.style.left = `${x}px`;
    this.host.style.top = `${y}px`;
  }

  /** Hooking into mat header internals to enable scroll by virtualizing click on hidden previous/next buttons */
  protected scrollTabs(event: WheelEvent): void {
    if (!this.tabGroup()) {
      return;
    }

    const tabHeader = this.tabGroup()._tabHeader;
    const children = tabHeader['_elementRef'].nativeElement.children;
    const back = children[0] as HTMLElement;
    const forward = children[2] as HTMLElement;
    if (event.deltaY > 0) {
      forward.click();
    } else {
      back.click();
    }
  }

  private adjustPosition(isResizing: boolean = false): void {
    if (!this.host.classList.contains('visible')) {
      return;
    }

    if (isResizing) {
      PearlTooltipUtils.lockMaxHeight(this.host);
      return;
    }

    PearlTooltipUtils.unlockMaxHeight(this.host);

    const { width, height } = PearlTooltipUtils.getActualElementSize(this.host);
    const idealCoords = PearlTooltipUtils.getIdealPosition(this._requiredCoords, width, height);
    this.position(idealCoords);
  }
}
