/**
 * This file generated using:
 * ```bash
 * $ spin update-pearl-icon
 * ```
 */
export type PearlIconSize = 20 | 24 | 40 | 48;
export const PEARL_ICON_NAMES = [
  'active_alert_20px.svg',
  'active_alert_24px.svg',
  'active_alert_40px.svg',
  'active_alert_48px.svg',
  'add_20px.svg',
  'add_24px.svg',
  'add_40px.svg',
  'add_48px.svg',
  'add_alert_20px.svg',
  'add_alert_24px.svg',
  'add_alert_40px.svg',
  'add_alert_48px.svg',
  'add_poi_20px.svg',
  'add_poi_24px.svg',
  'add_poi_40px.svg',
  'add_poi_48px.svg',
  'alert_20px.svg',
  'alert_24px.svg',
  'alert_40px.svg',
  'alert_48px.svg',
  'analytics_20px.svg',
  'analytics_24px.svg',
  'analytics_40px.svg',
  'analytics_48px.svg',
  'anchor_20px.svg',
  'anchor_24px.svg',
  'anchor_40px.svg',
  'anchor_48px.svg',
  'antenna_20px.svg',
  'antenna_24px.svg',
  'antenna_40px.svg',
  'antenna_48px.svg',
  'api_20px.svg',
  'api_24px.svg',
  'api_40px.svg',
  'api_48px.svg',
  'arrow_back_20px.svg',
  'arrow_back_24px.svg',
  'arrow_back_40px.svg',
  'arrow_back_48px.svg',
  'arrow_bi-directional_20px.svg',
  'arrow_bi-directional_24px.svg',
  'arrow_bi-directional_40px.svg',
  'arrow_bi-directional_48px.svg',
  'arrow_forward_20px.svg',
  'arrow_forward_24px.svg',
  'arrow_forward_40px.svg',
  'arrow_forward_48px.svg',
  'attach_file_20px.svg',
  'attach_file_24px.svg',
  'attach_file_40px.svg',
  'attach_file_48px.svg',
  'block_20px.svg',
  'block_24px.svg',
  'block_40px.svg',
  'block_48px.svg',
  'bookmark_20px.svg',
  'bookmark_24px.svg',
  'bookmark_40px.svg',
  'bookmark_48px.svg',
  'cable_20px.svg',
  'cable_24px.svg',
  'cable_40px.svg',
  'cable_48px.svg',
  'calendar_20px.svg',
  'calendar_24px.svg',
  'calendar_40px.svg',
  'calendar_48px.svg',
  'cancel_20px.svg',
  'cancel_24px.svg',
  'cancel_40px.svg',
  'cancel_48px.svg',
  'carbon_intensity_20px.svg',
  'carbon_intensity_24px.svg',
  'carbon_intensity_40px.svg',
  'carbon_intensity_48px.svg',
  'checked_20px.svg',
  'checked_24px.svg',
  'checked_40px.svg',
  'checked_48px.svg',
  'chevron_down_20px.svg',
  'chevron_down_24px.svg',
  'chevron_down_40px.svg',
  'chevron_down_48px.svg',
  'chevron_left_20px.svg',
  'chevron_left_24px.svg',
  'chevron_left_40px.svg',
  'chevron_left_48px.svg',
  'chevron_right_20px.svg',
  'chevron_right_24px.svg',
  'chevron_right_40px.svg',
  'chevron_right_48px.svg',
  'chevron_up_20px.svg',
  'chevron_up_24px.svg',
  'chevron_up_40px.svg',
  'chevron_up_48px.svg',
  'circle_20px.svg',
  'circle_24px.svg',
  'circle_40px.svg',
  'circle_48px.svg',
  'close_20px.svg',
  'close_24px.svg',
  'close_40px.svg',
  'close_48px.svg',
  'contract_20px.svg',
  'contract_24px.svg',
  'contract_40px.svg',
  'contract_48px.svg',
  'cost_20px.svg',
  'cost_24px.svg',
  'cost_40px.svg',
  'cost_48px.svg',
  'country_20px.svg',
  'country_24px.svg',
  'country_40px.svg',
  'country_48px.svg',
  'custom_shape_20px.svg',
  'custom_shape_24px.svg',
  'custom_shape_40px.svg',
  'custom_shape_48px.svg',
  'cycle_20px.svg',
  'cycle_24px.svg',
  'cycle_40px.svg',
  'cycle_48px.svg',
  'database_20px.svg',
  'database_24px.svg',
  'database_40px.svg',
  'database_48px.svg',
  'date_range_20px.svg',
  'date_range_24px.svg',
  'date_range_40px.svg',
  'date_range_48px.svg',
  'date_time_20px.svg',
  'date_time_24px.svg',
  'date_time_40px.svg',
  'date_time_48px.svg',
  'delete_20px.svg',
  'delete_24px.svg',
  'delete_40px.svg',
  'delete_48px.svg',
  'down_20px.svg',
  'down_24px.svg',
  'down_40px.svg',
  'down_48px.svg',
  'download_20px.svg',
  'download_24px.svg',
  'download_40px.svg',
  'download_48px.svg',
  'download_pdf_20px.svg',
  'download_pdf_24px.svg',
  'download_pdf_40px.svg',
  'download_pdf_48px.svg',
  'download_png_20px.svg',
  'download_png_24px.svg',
  'download_png_40px.svg',
  'download_png_48px.svg',
  'drag_handle_20px.svg',
  'drag_handle_24px.svg',
  'drag_handle_40px.svg',
  'drag_handle_48px.svg',
  'dredging_project_20px.svg',
  'dredging_project_24px.svg',
  'dredging_project_40px.svg',
  'dredging_project_48px.svg',
  'duplicate_20px.svg',
  'duplicate_24px.svg',
  'duplicate_40px.svg',
  'duplicate_48px.svg',
  'edit_20px.svg',
  'edit_24px.svg',
  'edit_40px.svg',
  'edit_48px.svg',
  'edit_localisation_20px.svg',
  'edit_localisation_24px.svg',
  'edit_localisation_40px.svg',
  'edit_localisation_48px.svg',
  'edit_off_20px.svg',
  'edit_off_24px.svg',
  'edit_off_40px.svg',
  'edit_off_48px.svg',
  'edit_shape_20px.svg',
  'edit_shape_24px.svg',
  'edit_shape_40px.svg',
  'edit_shape_48px.svg',
  'error_20px.svg',
  'error_24px.svg',
  'error_40px.svg',
  'error_48px.svg',
  'eta_20px.svg',
  'eta_24px.svg',
  'eta_40px.svg',
  'eta_48px.svg',
  'expand_20px.svg',
  'expand_24px.svg',
  'expand_40px.svg',
  'expand_48px.svg',
  'exploration_well_12px.svg',
  'exploration_well_20px.svg',
  'exploration_well_24px.svg',
  'exploration_well_40px.svg',
  'exploration_well_48px.svg',
  'factory_20px.svg',
  'factory_24px.svg',
  'factory_40px.svg',
  'factory_48px.svg',
  'fast_forward_20px.svg',
  'fast_forward_24px.svg',
  'fast_forward_40px.svg',
  'fast_forward_48px.svg',
  'fast_rewind_20px.svg',
  'fast_rewind_24px.svg',
  'fast_rewind_40px.svg',
  'fast_rewind_48px.svg',
  'field_20px.svg',
  'field_24px.svg',
  'field_40px.svg',
  'field_48px.svg',
  'filter_20px.svg',
  'filter_24px.svg',
  'filter_40px.svg',
  'filter_48px.svg',
  'flat_20px.svg',
  'flat_24px.svg',
  'flat_40px.svg',
  'flat_48px.svg',
  'forward_10_20px.svg',
  'forward_10_24px.svg',
  'forward_10_40px.svg',
  'forward_10_48px.svg',
  'fuel_20px.svg',
  'fuel_24px.svg',
  'fuel_40px.svg',
  'fuel_48px.svg',
  'fullscreen_20px.svg',
  'fullscreen_24px.svg',
  'fullscreen_40px.svg',
  'fullscreen_48px.svg',
  'fullscreen_exit_20px.svg',
  'fullscreen_exit_24px.svg',
  'fullscreen_exit_40px.svg',
  'fullscreen_exit_48px.svg',
  'geometry_20px.svg',
  'geometry_24px.svg',
  'geometry_40px.svg',
  'geometry_48px.svg',
  'grouped_bar_chart_20px.svg',
  'grouped_bar_chart_24px.svg',
  'grouped_bar_chart_40px.svg',
  'grouped_bar_chart_48px.svg',
  'hand_20px.svg',
  'hand_24px.svg',
  'hand_40px.svg',
  'hand_48px.svg',
  'highlight_20px.svg',
  'highlight_24px.svg',
  'highlight_40px.svg',
  'highlight_48px.svg',
  'historical_position_20px.svg',
  'historical_position_24px.svg',
  'historical_position_40px.svg',
  'historical_position_48px.svg',
  'history_20px.svg',
  'history_24px.svg',
  'history_40px.svg',
  'history_48px.svg',
  'in_progress_20px.svg',
  'in_progress_24px.svg',
  'in_progress_40px.svg',
  'in_progress_48px.svg',
  'information_20px.svg',
  'information_24px.svg',
  'information_40px.svg',
  'information_48px.svg',
  'keep_20px.svg',
  'keep_24px.svg',
  'keep_40px.svg',
  'keep_48px.svg',
  'label_20px.svg',
  'label_24px.svg',
  'label_40px.svg',
  'label_48px.svg',
  'label_off_20px.svg',
  'label_off_24px.svg',
  'label_off_40px.svg',
  'label_off_48px.svg',
  'layers_20px.svg',
  'layers_24px.svg',
  'layers_40px.svg',
  'layers_48px.svg',
  'legend_20px.svg',
  'legend_24px.svg',
  'legend_40px.svg',
  'legend_48px.svg',
  'line_20px.svg',
  'line_24px.svg',
  'line_40px.svg',
  'line_48px.svg',
  'link_20px.svg',
  'link_24px.svg',
  'link_40px.svg',
  'link_48px.svg',
  'link_off_20px.svg',
  'link_off_24px.svg',
  'link_off_40px.svg',
  'link_off_48px.svg',
  'logout_20px.svg',
  'logout_24px.svg',
  'logout_40px.svg',
  'logout_48px.svg',
  'mail_20px.svg',
  'mail_24px.svg',
  'mail_40px.svg',
  'mail_48px.svg',
  'manager_20px.svg',
  'manager_24px.svg',
  'manager_40px.svg',
  'manager_48px.svg',
  'map_20px.svg',
  'map_24px.svg',
  'map_40px.svg',
  'map_48px.svg',
  'map_type_20px.svg',
  'map_type_24px.svg',
  'map_type_40px.svg',
  'map_type_48px.svg',
  'maritime_area_20px.svg',
  'maritime_area_24px.svg',
  'maritime_area_40px.svg',
  'maritime_area_48px.svg',
  'measure_tool_20px.svg',
  'measure_tool_24px.svg',
  'measure_tool_40px.svg',
  'measure_tool_48px.svg',
  'menu_20px.svg',
  'menu_24px.svg',
  'menu_40px.svg',
  'menu_48px.svg',
  'more_20px.svg',
  'more_24px.svg',
  'more_40px.svg',
  'more_48px.svg',
  'move_first_20px.svg',
  'move_first_24px.svg',
  'move_first_40px.svg',
  'move_first_48px.svg',
  'move_last_20px.svg',
  'move_last_24px.svg',
  'move_last_40px.svg',
  'move_last_48px.svg',
  'my_location_20px.svg',
  'my_location_24px.svg',
  'my_location_40px.svg',
  'my_location_48px.svg',
  'navigation_20px.svg',
  'navigation_24px.svg',
  'navigation_40px.svg',
  'navigation_48px.svg',
  'offline_20px.svg',
  'offline_24px.svg',
  'offline_40px.svg',
  'offline_48px.svg',
  'offshore_substation_12px.svg',
  'offshore_substation_20px.svg',
  'offshore_substation_24px.svg',
  'offshore_substation_40px.svg',
  'offshore_substation_48px.svg',
  'online_20px.svg',
  'online_24px.svg',
  'online_40px.svg',
  'online_48px.svg',
  'open_20px.svg',
  'open_24px.svg',
  'open_40px.svg',
  'open_48px.svg',
  'open_in_full_20px.svg',
  'open_in_full_24px.svg',
  'open_in_full_40px.svg',
  'open_in_full_48px.svg',
  'open_in_new_20px.svg',
  'open_in_new_24px.svg',
  'open_in_new_40px.svg',
  'open_in_new_48px.svg',
  'operator_20px.svg',
  'operator_24px.svg',
  'operator_40px.svg',
  'operator_48px.svg',
  'pause_20px.svg',
  'pause_24px.svg',
  'pause_40px.svg',
  'pause_48px.svg',
  'performance_20px.svg',
  'performance_24px.svg',
  'performance_40px.svg',
  'performance_48px.svg',
  'pipe_20px.svg',
  'pipe_24px.svg',
  'pipe_40px.svg',
  'pipe_48px.svg',
  'place_20px.svg',
  'place_24px.svg',
  'place_40px.svg',
  'place_48px.svg',
  'place_off_20px.svg',
  'place_off_24px.svg',
  'place_off_40px.svg',
  'place_off_48px.svg',
  'play_20px.svg',
  'play_24px.svg',
  'play_40px.svg',
  'play_48px.svg',
  'playlist_add_20px.svg',
  'playlist_add_24px.svg',
  'playlist_add_40px.svg',
  'playlist_add_48px.svg',
  'poi_20px.svg',
  'poi_24px.svg',
  'poi_40px.svg',
  'poi_48px.svg',
  'pois_20px.svg',
  'pois_24px.svg',
  'pois_40px.svg',
  'pois_48px.svg',
  'port_multipurpose_12px.svg',
  'port_multipurpose_20px.svg',
  'port_multipurpose_24px.svg',
  'port_multipurpose_40px.svg',
  'port_multipurpose_48px.svg',
  'port_shipyard_12px.svg',
  'port_shipyard_20px.svg',
  'port_shipyard_24px.svg',
  'port_shipyard_40px.svg',
  'port_shipyard_48px.svg',
  'private_gis_12px.svg',
  'private_gis_20px.svg',
  'private_gis_24px.svg',
  'private_gis_40px.svg',
  'private_gis_48px.svg',
  'private_tender_20px.svg',
  'private_tender_24px.svg',
  'private_tender_40px.svg',
  'private_tender_48px.svg',
  'profil_20px.svg',
  'profil_24px.svg',
  'profil_40px.svg',
  'profil_48px.svg',
  'project_20px.svg',
  'project_24px.svg',
  'project_40px.svg',
  'project_48px.svg',
  'quay_20px.svg',
  'quay_24px.svg',
  'quay_40px.svg',
  'quay_48px.svg',
  'range_circle_20px.svg',
  'range_circle_24px.svg',
  'range_circle_40px.svg',
  'range_circle_48px.svg',
  'ready_to_submit_20px.svg',
  'ready_to_submit_24px.svg',
  'ready_to_submit_40px.svg',
  'ready_to_submit_48px.svg',
  'refresh_20px.svg',
  'refresh_24px.svg',
  'refresh_40px.svg',
  'refresh_48px.svg',
  'remove_20px.svg',
  'remove_24px.svg',
  'remove_40px.svg',
  'remove_48px.svg',
  'replay_10_20px.svg',
  'replay_10_24px.svg',
  'replay_10_40px.svg',
  'replay_10_48px.svg',
  'report_20px.svg',
  'report_24px.svg',
  'report_40px.svg',
  'report_48px.svg',
  'resource_20px.svg',
  'resource_24px.svg',
  'resource_40px.svg',
  'resource_48px.svg',
  'restart_alt_20px.svg',
  'restart_alt_24px.svg',
  'restart_alt_40px.svg',
  'restart_alt_48px.svg',
  'rig_12px.svg',
  'rig_20px.svg',
  'rig_24px.svg',
  'rig_40px.svg',
  'rig_48px.svg',
  'right_panel_open_20px.svg',
  'right_panel_open_24px.svg',
  'right_panel_open_40px.svg',
  'right_panel_open_48px.svg',
  'save_20px.svg',
  'save_24px.svg',
  'save_40px.svg',
  'save_48px.svg',
  'saved_20px.svg',
  'saved_24px.svg',
  'saved_40px.svg',
  'saved_48px.svg',
  'scheduled_20px.svg',
  'scheduled_24px.svg',
  'scheduled_40px.svg',
  'scheduled_48px.svg',
  'search_20px.svg',
  'search_24px.svg',
  'search_40px.svg',
  'search_48px.svg',
  'send_20px.svg',
  'send_24px.svg',
  'send_40px.svg',
  'send_48px.svg',
  'settings_20px.svg',
  'settings_24px.svg',
  'settings_40px.svg',
  'settings_48px.svg',
  'show_data_table_20px.svg',
  'show_data_table_24px.svg',
  'show_data_table_40px.svg',
  'show_data_table_48px.svg',
  'signature_20px.svg',
  'signature_24px.svg',
  'signature_40px.svg',
  'signature_48px.svg',
  'sort_20px.svg',
  'sort_24px.svg',
  'sort_40px.svg',
  'sort_48px.svg',
  'sources_20px.svg',
  'sources_24px.svg',
  'sources_40px.svg',
  'sources_48px.svg',
  'speed_20px.svg',
  'speed_24px.svg',
  'speed_40px.svg',
  'speed_48px.svg',
  'splitscreen_right_20px.svg',
  'splitscreen_right_24px.svg',
  'splitscreen_right_40px.svg',
  'splitscreen_right_48px.svg',
  'square_20px.svg',
  'square_24px.svg',
  'square_40px.svg',
  'square_48px.svg',
  'stacked_bar_chart_20px.svg',
  'stacked_bar_chart_24px.svg',
  'stacked_bar_chart_40px.svg',
  'stacked_bar_chart_48px.svg',
  'stop_20px.svg',
  'stop_24px.svg',
  'stop_40px.svg',
  'stop_48px.svg',
  'submit_20px.svg',
  'submit_24px.svg',
  'submit_40px.svg',
  'submit_48px.svg',
  'suggestion.svg',
  'surface_infrastructure_fixed_12px.svg',
  'surface_infrastructure_fixed_20px.svg',
  'surface_infrastructure_fixed_24px.svg',
  'surface_infrastructure_fixed_40px.svg',
  'surface_infrastructure_fixed_48px.svg',
  'surface_infrastructure_floating_12px.svg',
  'surface_infrastructure_floating_20px.svg',
  'surface_infrastructure_floating_24px.svg',
  'surface_infrastructure_floating_40px.svg',
  'surface_infrastructure_floating_48px.svg',
  'time_20px.svg',
  'time_24px.svg',
  'time_40px.svg',
  'time_48px.svg',
  'timeline_20px.svg',
  'timeline_24px.svg',
  'timeline_40px.svg',
  'timeline_48px.svg',
  'timezone_20px.svg',
  'timezone_24px.svg',
  'timezone_40px.svg',
  'timezone_48px.svg',
  'trend_20px.svg',
  'trend_24px.svg',
  'trend_40px.svg',
  'trend_48px.svg',
  'trend_down_20px.svg',
  'trend_down_24px.svg',
  'trend_down_40px.svg',
  'trend_down_48px.svg',
  'trend_flat_20px.svg',
  'trend_flat_24px.svg',
  'trend_flat_40px.svg',
  'trend_flat_48px.svg',
  'trend_up_20px.svg',
  'trend_up_24px.svg',
  'trend_up_40px.svg',
  'trend_up_48px.svg',
  'up_20px.svg',
  'up_24px.svg',
  'up_40px.svg',
  'up_48px.svg',
  'upload_20px.svg',
  'upload_24px.svg',
  'upload_40px.svg',
  'upload_48px.svg',
  'vessel_20px.svg',
  'vessel_24px.svg',
  'vessel_40px.svg',
  'vessel_48px.svg',
  'visibility_20px.svg',
  'visibility_24px.svg',
  'visibility_40px.svg',
  'visibility_48px.svg',
  'visibility_off_20px.svg',
  'visibility_off_24px.svg',
  'visibility_off_40px.svg',
  'visibility_off_48px.svg',
  'voyage_20px.svg',
  'voyage_24px.svg',
  'voyage_40px.svg',
  'voyage_48px.svg',
  'warning_20px.svg',
  'warning_24px.svg',
  'warning_40px.svg',
  'warning_48px.svg',
  'weather_20px.svg',
  'weather_24px.svg',
  'weather_40px.svg',
  'weather_48px.svg',
  'weather_source_20px.svg',
  'weather_source_24px.svg',
  'weather_source_40px.svg',
  'weather_source_48px.svg',
  'well_20px.svg',
  'well_24px.svg',
  'well_40px.svg',
  'well_48px.svg',
  'wind_farm_20px.svg',
  'wind_farm_24px.svg',
  'wind_farm_40px.svg',
  'wind_farm_48px.svg',
  'wind_turbine_fixed_12px.svg',
  'wind_turbine_fixed_20px.svg',
  'wind_turbine_fixed_24px.svg',
  'wind_turbine_fixed_40px.svg',
  'wind_turbine_fixed_48px.svg',
  'wind_turbine_floating_12px.svg',
  'wind_turbine_floating_20px.svg',
  'wind_turbine_floating_24px.svg',
  'wind_turbine_floating_40px.svg',
  'wind_turbine_floating_48px.svg',
  'windfarm_20px.svg',
  'windfarm_24px.svg',
  'windfarm_40px.svg',
  'windfarm_48px.svg',
  'workpackage_20px.svg',
  'workpackage_24px.svg',
  'workpackage_40px.svg',
  'workpackage_48px.svg',
];

export type PearlIcon =
  | 'active_alert'
  | 'add'
  | 'add_alert'
  | 'add_poi'
  | 'alert'
  | 'analytics'
  | 'anchor'
  | 'antenna'
  | 'api'
  | 'arrow_back'
  | 'arrow_bi-directional'
  | 'arrow_forward'
  | 'attach_file'
  | 'block'
  | 'bookmark'
  | 'cable'
  | 'calendar'
  | 'cancel'
  | 'carbon_intensity'
  | 'checked'
  | 'chevron_down'
  | 'chevron_left'
  | 'chevron_right'
  | 'chevron_up'
  | 'circle'
  | 'close'
  | 'contract'
  | 'cost'
  | 'country'
  | 'custom_shape'
  | 'cycle'
  | 'database'
  | 'date_range'
  | 'date_time'
  | 'delete'
  | 'down'
  | 'download'
  | 'download_pdf'
  | 'download_png'
  | 'drag_handle'
  | 'dredging_project'
  | 'duplicate'
  | 'edit'
  | 'edit_localisation'
  | 'edit_off'
  | 'edit_shape'
  | 'error'
  | 'eta'
  | 'expand'
  | 'exploration_well'
  | 'factory'
  | 'fast_forward'
  | 'fast_rewind'
  | 'field'
  | 'filter'
  | 'flat'
  | 'forward_10'
  | 'fuel'
  | 'fullscreen'
  | 'fullscreen_exit'
  | 'geometry'
  | 'grouped_bar_chart'
  | 'hand'
  | 'highlight'
  | 'historical_position'
  | 'history'
  | 'in_progress'
  | 'information'
  | 'keep'
  | 'label'
  | 'label_off'
  | 'layers'
  | 'legend'
  | 'line'
  | 'link'
  | 'link_off'
  | 'logout'
  | 'mail'
  | 'manager'
  | 'map'
  | 'map_type'
  | 'maritime_area'
  | 'measure_tool'
  | 'menu'
  | 'more'
  | 'move_first'
  | 'move_last'
  | 'my_location'
  | 'navigation'
  | 'offline'
  | 'offshore_substation'
  | 'online'
  | 'open'
  | 'open_in_full'
  | 'open_in_new'
  | 'operator'
  | 'pause'
  | 'performance'
  | 'pipe'
  | 'place'
  | 'place_off'
  | 'play'
  | 'playlist_add'
  | 'poi'
  | 'pois'
  | 'port_multipurpose'
  | 'port_shipyard'
  | 'private_gis'
  | 'private_tender'
  | 'profil'
  | 'project'
  | 'quay'
  | 'range_circle'
  | 'ready_to_submit'
  | 'refresh'
  | 'remove'
  | 'replay_10'
  | 'report'
  | 'resource'
  | 'restart_alt'
  | 'rig'
  | 'right_panel_open'
  | 'save'
  | 'saved'
  | 'scheduled'
  | 'search'
  | 'send'
  | 'settings'
  | 'show_data_table'
  | 'signature'
  | 'sort'
  | 'sources'
  | 'speed'
  | 'splitscreen_right'
  | 'square'
  | 'stacked_bar_chart'
  | 'stop'
  | 'submit'
  | 'suggestion'
  | 'surface_infrastructure_fixed'
  | 'surface_infrastructure_floating'
  | 'time'
  | 'timeline'
  | 'timezone'
  | 'trend'
  | 'trend_down'
  | 'trend_flat'
  | 'trend_up'
  | 'up'
  | 'upload'
  | 'vessel'
  | 'visibility'
  | 'visibility_off'
  | 'voyage'
  | 'warning'
  | 'weather'
  | 'weather_source'
  | 'well'
  | 'wind_farm'
  | 'wind_turbine_fixed'
  | 'wind_turbine_floating'
  | 'windfarm'
  | 'workpackage';

export const UNIQUE_SIZE_ICONS = ['suggestion'];
