<aside #child tabindex="-1" class="spin-tooltip list-tooltip">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="header">
        <div class="title">{{ description }}</div>
        <pearl-button type="icon" icon="close" (click)="hide()" />
      </div>

      <mat-divider />

      <cdk-virtual-scroll-viewport #scroll itemSize="22" class="item-list" (scrolledIndexChange)="scrolled()">
        <div class="item-row" *cdkVirtualFor="let item of items">-{{ item }}</div>
      </cdk-virtual-scroll-viewport>
    </div>
  </div>
</aside>
