<div class="filter-columns-dialog">
  <div class="header">
    <h2>Select columns:</h2>
    <div class="header-close">
      <pearl-button class="close-button" type="icon" icon="close" (click)="close()" />
    </div>
  </div>

  <div class="dialog-body">
    <div class="list-search">
      <pearl-form-field iconPrefix="search" label="Search">
        <input matInput #specsSearchInput [(ngModel)]="searchText" (ngModelChange)="searchField()" autocomplete="off" />
      </pearl-form-field>
      <pearl-button type="alternative" (click)="selectAll(true)">Select all</pearl-button>
      <pearl-button type="alternative" (click)="selectAll(false)">Clear</pearl-button>
    </div>
    <div class="columns-list">
      @if (!hasFieldsets) {
        @for (field of data.allFields; track field.id) {
          <div>
            @if (field.visible) {
              <div class="field-div">
                <div>
                  <mat-checkbox #checkbox [color]="'primary'" [(ngModel)]="field.checked" />
                </div>
                <span class="field-label">{{ field.name }}</span>
              </div>
            }
          </div>
        }
      }

      @if (hasFieldsets) {
        @for (fieldset of fieldsets; track fieldset.title; let i = $index) {
          <div>
            <div class="fieldset-title">
              <mat-checkbox
                #checkbox
                [color]="'primary'"
                [(ngModel)]="fieldset.checked"
                (change)="fieldsetSelectionChange($event, fieldset)" />
              <span class="fieldset-label">{{ fieldset.title }}</span>
            </div>
            <mat-divider class="fieldset-divider" aria-orientation="horizontal" role="separator" />
            @for (field of fieldset.fields; track field.id) {
              @if (field.visible) {
                <div class="field-div">
                  <div>
                    <mat-checkbox #checkbox [color]="'primary'" [(ngModel)]="field.checked" />
                  </div>
                  <span class="field-label">{{ field.name || field.id }}</span>
                </div>
              }
            }
          </div>
        }
      }
    </div>
  </div>

  <div class="dialog-footer">
    <div class="footer-left">
      <span (click)="selectDefault()">Reset selection</span>
    </div>
    <div class="footer-right">
      <pearl-button type="alternative" (click)="close()">Cancel</pearl-button>
      <pearl-button type="alternative" (click)="save()">Save</pearl-button>
    </div>
  </div>
</div>
