import { Injectable } from '@angular/core';

import { datadogLogs } from '@datadog/browser-logs';

export type DatadogLog = {
  type: 'error' | 'warn' | 'info' | 'debug';
  message: string;
  [key: string]: string;
};

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public urlSource: string = null;
  public isActive: boolean = false;
  public parameters: string[] = [];
  public pageName: string;

  public handle(error: Error | string): void {
    console.error(`${error}`);
  }

  /**
   * @param {DatadogLog} datadogLog
   * @returns {void}
   * @description method to handle datadog logs, according to the type of log
   */
  public static datadogHandle(datadogLog: DatadogLog): void {
    const messageContext = Object.entries(datadogLog)
      .map(([key, value]) => ({ [key]: value }));

    datadogLogs.logger[datadogLog.type](datadogLog.message, messageContext);
  }
}
