import { Component, Input, OnInit } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { PearlButtonComponent } from '../pearl-components/components/buttons/pearl-button.component';

export interface EmptyContentButtonDefinition {
  label: string;
  onClick: () => void;
}

export interface EmptyContentDefinition {
  imageSrc?: string;
  messages?: string[];
  button?: EmptyContentButtonDefinition;
  // In some schedule, the image has to resized to fit in the available height
  height?: number;
}

@Component({
  selector: 'empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    NgFor,
    NgIf,
    PearlButtonComponent,
  ],
})
export class EmptyContentComponent implements OnInit {
  @Input()
  definition: EmptyContentDefinition = {
    imageSrc: 'assets/img/empty-state.svg',
    messages: ['No data.'],
  };
  public sizeStylingClassname: string = '';

  ngOnInit(): void {
    // By default, container is not small
    const isContainerSmall = this.definition.height ? this.definition.height < 300 : false;
    this.sizeStylingClassname = isContainerSmall ? 'small-container' : '';
  }
}
