<aside #child tabindex="-1" class="spin-tooltip">
  <div class="content">
    <table>
      <tbody class="chart-tooltip-table">
        <ng-container *ngFor="let line of tooltipLines">
          <tr [ngClass]="line.rowClass">
            <td *ngIf="line.entryStyle" class="legend">
              <div [ngStyle]="line.entryStyle"></div>
            </td>
            <td [attr.colspan]="getTitleColSpan(line)">
              <a *ngIf="line.pageUrl; else noPageUrl" [href]="line.pageUrl" (click)="navigate.emit($event)">
                {{ line.title }}
              </a>
              <ng-template #noPageUrl>{{ line.title }}</ng-template>
            </td>
            <td *ngIf="line.value" class="value">
              <span>
                <a
                  *ngIf="line.valuePageUrl; else noValuePageUrl"
                  [href]="line.valuePageUrl"
                  (click)="navigate.emit($event)"
                  >{{ line.value }}</a
                >
                <ng-template #noValuePageUrl>{{ line.value }}</ng-template>
              </span>
              <span *ngIf="line.spinergieValue" class="spin-value">&nbsp;{{ line.spinergieValue }} </span>
              <span *ngIf="line.errors" class="error-part">&nbsp;{{ line.errors }} </span>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</aside>
