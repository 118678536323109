import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, EventEmitter, Output,
  ViewChild } from '@angular/core';

import { ActionEvent, TooltipHidingOption, TooltipSettings, TooltipTitle } from '../helpers/types';

type MousePos = { x: number; y: number };

@Directive()
/**
 * Responsible for toggling and positioning the tooltip.
 */
export abstract class PositionedTooltip implements AfterViewInit {
  @Output()
  onaction = new EventEmitter<ActionEvent>();
  @ViewChild('child')
  child: ElementRef;

  protected nativeDom: HTMLElement;
  private hideTimeout: number;
  private removeTimeout: number = null;
  public pos: MousePos;
  public title: TooltipTitle = { name: 'date', type: 'date', format: 'y-MM-dd' };
  public imageURIField: string = '';
  public offset: number = 10; // Offset (mouse move)
  private fadeDuration: number = 200; // Fade effect duration in ms

  public hideTooltip: boolean = true;
  protected defaultHidingOptions = [TooltipHidingOption.mouseInAndOut]; // could be redefined by subclasses
  public hidingOptions: TooltipHidingOption[] = [];

  private mouseoverListener = (): void => {
    this.resetTimeout();
  };
  private mouseleaveListener = (): void => {
    this.hide();
  };
  private focusoutListener = (event: FocusEvent): void => {
    if (event.relatedTarget === null) return this.hide();
    if (!(event.relatedTarget instanceof HTMLElement)) return;

    // For focusout event, event.relatedTarget refers to the element that acquired the focus
    const tooltipContainsRelatedTarget = this.nativeDom.contains(event.relatedTarget);
    const cdkOverlayContainsRelatedTarget = document.querySelector<HTMLElement>('.cdk-overlay-container').contains(
      event.relatedTarget,
    );
    // Don't hide the tooltip if focus moved to an element inside the tooltip or to cdkOverlayContainer (datepicker)
    if (!tooltipContainsRelatedTarget && !cdkOverlayContainsRelatedTarget) {
      this.hide();
    }
  };

  constructor(
    public elRef: ElementRef<HTMLElement>,
    public cdRef: ChangeDetectorRef,
  ) {
    this.pos = { x: 0, y: 0 };
  }

  ngAfterViewInit(): void {
    this.nativeDom = this.elRef.nativeElement.querySelector<HTMLElement>('aside.spin-tooltip');
    this.nativeDom.style.setProperty('transition', `opacity ${this.fadeDuration}ms`);
  }

  public showTooltipAtPosition(coords: [number, number], options?: TooltipSettings): void {
    this.resetTimeout();
    this.pos.x = coords[0];
    this.pos.y = coords[1];

    this.nativeDom.style.setProperty('display', 'flex');

    /*
     * the first time we show a tooltip the width and height of the window aren't correctly set before detectChanges
     */
    this.cdRef.detectChanges();
    this.preventOverflow();

    this.nativeDom.style.setProperty('opacity', '1');

    this.nativeDom.removeEventListener('mouseover', this.mouseoverListener);
    this.nativeDom.addEventListener('mouseover', this.mouseoverListener);

    this.applyHidingOptions(options?.hidingOptions);
  }

  private applyHidingOptions(options?: TooltipHidingOption[]): void {
    this.hidingOptions = options ?? this.defaultHidingOptions;
    this.nativeDom.removeEventListener('focusout', this.focusoutListener);
    this.nativeDom.removeEventListener('mouseleave', this.mouseleaveListener);

    if (this.hidingOptions.includes(TooltipHidingOption.focusOut)) {
      this.nativeDom.focus();
      this.nativeDom.addEventListener('focusout', this.focusoutListener);
    }

    if (this.hidingOptions.includes(TooltipHidingOption.mouseInAndOut)) {
      this.nativeDom.addEventListener('mouseleave', this.mouseleaveListener);
    }

    if (this.hidingOptions.includes(TooltipHidingOption.afterTimeout)) {
      this.hideTimeout = setTimeout(() => this.hide(), 2000);
    }
  }

  private preventOverflow(): void {
    // adapt position to dimensions to avoid overflow
    const style = window.getComputedStyle(this.child.nativeElement);
    const width = parseInt(style.getPropertyValue('width').slice(0, -2));
    const height = parseInt(style.getPropertyValue('height').slice(0, -2));

    this.setPosX(width);
    this.setPosY(height);

    this.nativeDom.style.setProperty('left', this.pos.x + 'px');
    this.nativeDom.style.setProperty('top', this.pos.y + 'px');
  }

  private setPosX(width: number): void {
    if (this.pos.x + width > window.innerWidth) {
      this.pos.x -= width + this.offset;
    } else {
      this.pos.x += this.offset;
    }
    if (this.pos.x < 10) {
      this.pos.x = 10 + this.offset;
    }
  }

  private setPosY(height: number): void {
    if (this.pos.y + height > window.innerHeight) {
      this.pos.y -= height + this.offset;
    } else {
      this.pos.y += this.offset;
    }
    if (this.pos.y < 10) {
      this.pos.y = 10 + this.offset;
    }
  }

  public get posAsCoords(): [number, number] {
    return [this.pos.x, this.pos.y];
  }

  protected setHideTooltip(hideTooltip: boolean): void {
    this.hideTooltip = hideTooltip;
    this.hide();
  }

  /**
   * Hide the tooltip after 100ms, if not reset (by hover event)
   */
  public hide(): void {
    if (!this.hideTooltip) return;
    this.resetTimeout();
    this.hideTimeout = setTimeout(() => this.forceHide(), 100);
  }

  protected forceHide(): void {
    this.resetTimeout();
    this.remove();
  }

  /**
   * Remove the closing timer from the tooltip.
   * The tooltip will close on a mouseout event or if the tooltip is closed manually.
   */
  protected resetTimeout(): void {
    clearTimeout(this.hideTimeout);
    this.hideTimeout = null;
    clearTimeout(this.removeTimeout);
    this.removeTimeout = null;
  }

  protected remove(): void {
    // Remove the mouse event listener to prevent it from resetting this.removeTimeout via this.resetTimeout
    this.nativeDom.removeEventListener('mouseover', this.mouseoverListener);
    this.nativeDom.style.setProperty('opacity', '0');
    this.removeTimeout = setTimeout(() => {
      this.nativeDom.style.setProperty('display', 'none');
    }, this.fadeDuration);

    this.resetTogglingOptions();
  }

  protected resetTogglingOptions(): void {
    this.hidingOptions = [];
  }
}
