import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { PearlButtonComponent, PearlButtonImports } from './pearl-button.component';

@Component({
  standalone: true,
  selector: 'pearl-button-link',
  templateUrl: './pearl-button.component.html',
  styleUrls: ['./pearl-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: PearlButtonImports,
})
export class PearlButtonLinkComponent extends PearlButtonComponent {
  public override readonly isLink = input<boolean>(true);
}
