export class NumberHelper {
  public static floor(value: number, precision: number = 2): number {
    return Math.floor(value * 10 ** precision) / 10 ** precision;
  }

  public static ceil(value: number, precision: number = 2): number {
    return Math.ceil(value * 10 ** precision) / 10 ** precision;
  }

  /**
   * Provide default rounding
   * Javascript like most languages does not support arbitrary precision decimals and because of the way decimals
   * are represented they loose precision, so in JS:
   *  0.3 - 0.1  = 0.19999999999999999
   * Double precision is standard with 15 decimal places,
   * so theoretically if we round to 10 digits after the decimal point,
   * we should solve the problem of floating point arithmetics. (0.999999999)
   * So if we put 10 as precision we shouldn't loose precision
   * (if there was more precision at some point it was already lost due DB storage/JSON serialization).
   * Note: if we ever need more precision for calculations in the future -
   * we should use some arbitrary precision library (like decimal.js).
   */
  public static fixPrecision(value: number, decimalPrecision = 10): number {
    if (value == null) {
      return value;
    }
    const fixedNumber = Math.round(value * Math.pow(10, decimalPrecision)) / Math.pow(10, decimalPrecision);
    /*
     * If we fix the precision on a negative number close to 0 the result would be -0 and not 0
     * So we test to enforce 0 to be positive otherwise we can simply return fixedNumber
     * There is not many differences between 0 and -0 it mostly impact division
     * (Check here for more information:
     * https://medium.com/coding-at-dawn/is-negative-zero-0-a-number-in-javascript-c62739f80114)
     */
    return Object.is(fixedNumber, -0) ? 0 : fixedNumber;
  }
}
