import { ChangeDetectionStrategy, Component, ElementRef, OnInit, OutputEmitterRef, effect, input, model,
  output } from '@angular/core';

import { debounce, isEqual, isNil, omitBy, size } from 'lodash-es';
import { API, Options, create } from 'nouislider';

import { NumberHelper } from '../helpers/number-helper';

type SliderValue = number | number[];

@Component({
  selector: 'nouislider',
  template: '<div class="slider-styled"></div>',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NouisliderComponent implements OnInit {
  public min = input.required<number>();
  public max = input.required<number>();
  public disabled = input<boolean>(false);
  public behaviour = input<string>('drag');
  public connect = input<Options['connect']>(true);
  public limit = input<number>();
  public snap = input<boolean>(false);
  public animate = input<boolean>(false);
  public step = input<number>();

  public slideStart = output<void>();
  public slideChange = output<void>();
  public slideEnd = output<void>();

  private slider: API;
  public value = model<SliderValue>(0);

  constructor(private el: ElementRef<HTMLElement>) {
    /** Listen for any property change to modify slider options */
    effect(() => {
      if (!this.slider) return;
      const newOptions = omitBy({
        behaviour: this.behaviour(),
        connect: this.connect(),
        limit: this.limit(),
        step: this.step(),
        range: { min: this.min(), max: this.max() },
        snap: this.snap(),
        animate: this.animate(),
        /** Check that params are actually new */
      } as Options, (v, k) => isEqual(this.slider.options[k], v)) as Options;
      if (size(newOptions)) {
        this.slider.updateOptions(newOptions, false);
      }
    }, /** We allow signal write as calling "updateOptions" will trigger "updateValue", which writes "value" */ {
      allowSignalWrites: true,
    });

    effect(() => {
      if (this.disabled()) this.slider.disable();
      else this.slider.enable();
    });

    effect(() => {
      const newValue = this.value();
      /** Check that value is different, meaning it was updated from parent component */
      if (isEqual(newValue, this.slider.get(true))) return;
      this.slider.set(newValue, false);
    }, { allowSignalWrites: true });
  }

  ngOnInit(): void {
    const config: Options = omitBy({
      behaviour: this.behaviour(),
      connect: this.connect(),
      limit: this.limit(),
      start: this.value(),
      step: this.step(),
      range: { min: this.min(), max: this.max() },
      snap: this.snap(),
      animate: this.animate(),
      /** Remove undefined / null properties */
    } as Options, isNil) as Options;

    this.slider = create(this.el.nativeElement.firstChild as HTMLDivElement, config);
    this.slider.on('start', () => this.updateValue(this.slideStart));
    this.slider.on('slide', () => this.updateValue(this.slideChange));
    /** Call debounced because when dragging a range, this event is called twice (once per handle) */
    this.slider.on('end', () => this.updateValueDebounced(this.slideEnd));
  }

  public updateValue(eventEmitter: OutputEmitterRef<void>): void {
    let value = this.slider.get(true) as SliderValue;
    if (Array.isArray(value)) value = value.map(v => NumberHelper.fixPrecision(v));
    else value = NumberHelper.fixPrecision(value);
    this.value.set(value);
    eventEmitter.emit();
  }

  public updateValueDebounced = debounce((eventEmitter: OutputEmitterRef<void>) => this.updateValue(eventEmitter), 20);
}
