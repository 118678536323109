@for (field of this.content() | keyvalue; track [$index, field.key]) {
  @let definition = field.value;

  @if ((definition | typeof) === "string") {
    <div class="row">
      <span>{{ field.key }}</span>
      <span>{{ definition }}</span>
    </div>
  } @else {
    <ng-container #container />
  }
}
