<div class="coords">
  <pearl-button
    (click)="switch()"
    type="alternative"
    size="small"
    matTooltip="Change notation"
    matTooltipShowDelay="500">
    {{ showCoords }}
  </pearl-button>

  @if (showCoords === "DD") {
    <span>{{ coords[0] | number: "1.0-5" }}, {{ coords[1] | number: "1.0-5" }} </span>
  } @else {
    <span> {{ ddmCoordinates[0] }}, {{ ddmCoordinates[1] }} </span>
  }
</div>
