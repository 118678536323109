import { AppInfoService } from 'src/app/app-info-service';
import { OptionValue, SectionCode, SubscriptionConfig, SubscriptionFieldType } from '../helpers/types';

export class SubscriptionTools {
  public static subscriptionFilterResume(
    subscription: SubscriptionConfig,
  ): { filterTitle: string; filterValue: string }[] {
    if (!subscription.userConfig) {
      return [];
    }
    return Object.keys(subscription.userConfig).filter(filterId => {
      const section = subscription.sections?.find(section => section.key == filterId);
      return !(filterId in subscription) && subscription.userConfig[filterId]
        && (section?.type != 'vessels_advanced_filters');
    }).map((filterId: string) => {
      const filterSection = subscription.sections.find(filter => filter.key == filterId);
      return {
        filterTitle: filterSection.title,
        filterValue: this.getSectionValueTitle(subscription.userConfig[filterId], filterSection),
      };
    }).filter(filter => filter.filterTitle && filter.filterValue);
  }

  /**
   * Get for a section the title corresponding to selected values
   */
  public static getSectionValueTitle(sectionValues: any, section: SectionCode) {
    switch (section.type) {
      case 'entity':
      case 'dropdown':
      case 'checkbox':
      case 'multi':
        /*
         * Inside the subscription_user we are expecting to get a list of items
         * TODO: Check the types here, currently values has strange typings
         */
        if (Array.isArray(sectionValues)) {
          return sectionValues.map(value => {
            const selectionValue = (section.values as any[]).find(sectionValue =>
              sectionValue.value
                ? sectionValue.value.id == value
                : sectionValue == value
            );
            return selectionValue ? selectionValue.title : value;
          }).join(', ');
        }
        const selectionValue = (section.values as OptionValue[]).find(sectionValue => sectionValue.id == sectionValues);
        return selectionValue ? selectionValue.title : sectionValues;
      case 'regions':
        return sectionValues.values.map(region => {
          const sectionValue = (section.values as any[]).find(sectionValue => sectionValue.value.id == region);
          return sectionValue ? sectionValue.title : region;
        }).join(', ');
      case 'number':
        return sectionValues;
      default:
    }
  }

  public static subscriptionSectionFromType(
    subscription: SubscriptionConfig,
    type: SubscriptionFieldType,
  ): SectionCode {
    return subscription.sections.find(s => s.type == type);
  }

  public static vesselFleetTitles(subscription: SubscriptionConfig, appInfoService: AppInfoService) {
    const section: SectionCode = SubscriptionTools.subscriptionSectionFromType(
      subscription,
      'vessels_advanced_filters',
    );
    const vesselSelectionValues = subscription.userConfig[section.key];
    if (!vesselSelectionValues || !vesselSelectionValues.fleets) {
      return [];
    }
    const selectedFleets = vesselSelectionValues.fleets;
    const vesselFleets = appInfoService.config.getAvailableVesselFleets();
    return vesselFleets.filter(fleet => selectedFleets.includes(fleet.id)).map(fleet => fleet.title);
  }
}
