import { QueryList } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material/select';

import dayjs, { Dayjs } from 'dayjs';
import { Duration } from 'dayjs/plugin/duration';

import { ActionEvent, Button, CoordinateAxes, DateTimezone, DeepReadonly, EntityAction, EntityDefinition,
  EntityFieldDefinition, EntityInformation, EntityRightsCheckable, FieldsDefinition, SomeEntity, SomeEntityChainable,
  SortDirection, ValidityDateEntity } from '../helpers/types';
import { DataHelpers, getAdditionalData, getOriginalValue, getPropKeyForNonFilterField } from '../helpers/data-helpers';
import { DateHelper } from '../helpers/date-helper';
import { MapCoordsComponent } from '../map/map-coords';
import { getChained } from '../data-loader/ref-data-provider';
import { RawDataPoint } from '../graph/chart-types';
import { Config } from '../config/config';

export const OverrideFieldStyle = {
  'background-color': '#1DE9B6',
  'padding-left': '0.5em',
  'padding-right': '0.5em',
  'border-radius': '3px',
};

export class DatabaseHelper {
  public static INNER_WINDOW_PX_MARGIN = 50;
  public static INNER_WINDOW_FACTOR_MARGIN = 0.99;

  public static handleKeyEventForPanel(query: QueryList<MatAutocomplete | MatSelect>, event: KeyboardEvent): void {
    /*
     * If we press up/down with the metakey or the altkey we will access the first/last element of the options
     * We use metakey and altkey because the select have a special comportment on windows where it close on alt+arrowKey
     * And this comportment is reproduce on mat-select for all platform
     * So autocomplete will work with both key and matselect will work with only meta-key
     */
    if ((event.key === 'ArrowDown' || event.key === 'ArrowUp') && (event.metaKey || event.altKey)) {
      const direction = event.key === 'ArrowDown' ? 1 : -1;

      query.forEach(item => {
        if (item.panel) {
          if (event.key === 'ArrowDown') {
            item._keyManager.setLastItemActive();
          } else {
            item._keyManager.setFirstItemActive();
          }
          (item.panel.nativeElement as HTMLDivElement).scrollBy({
            top: direction * (item.panel.nativeElement as HTMLDivElement).scrollHeight,
            left: 0,
            behavior: 'auto',
          });
        }
      });
    }
  }

  public static sortByDefinition(
    values: DeepReadonly<SomeEntity>[],
    definition: EntityDefinition,
  ): SomeEntity[] {
    if (definition.sortFields) {
      const sortFieldNames = Object.keys(definition.sortFields);
      if (sortFieldNames.length > 0) {
        const sortFieldValues = Object.values(definition.sortFields);
        return this.sortFormattedValue(values, sortFieldNames, sortFieldValues, definition);
      }
    }
    return values;
  }

  private static sortFormattedValue(
    values: SomeEntity[],
    sortFields: string[],
    sortDir: SortDirection[],
    definition: EntityDefinition,
  ): SomeEntity[] {
    return values.sort((a, b) => {
      for (let index = 0; index < sortFields.length; index++) {
        const fieldId = sortFields[index];
        const field = definition.fields.find(d => d.id == fieldId);
        const sortDirection = sortDir[index];
        const order = this.compare(a, b, sortDirection, field);
        if (order !== 0) {
          return order;
        }
      }
      return 0;
    });
  }

  public static sortBy(
    values: SomeEntity[],
    sortFields: string[],
    sortDir: SortDirection[],
    definition: EntityDefinition,
  ): SomeEntity[] | null {
    if (sortFields.length === 0) {
      return null;
    }

    return this.sortFormattedValue(values, sortFields, sortDir, definition);
  }

  public static isSpecialClick(event: KeyboardEvent | MouseEvent): boolean {
    return event.ctrlKey || event.metaKey || event.altKey || event.shiftKey;
  }

  public static compareRaw(a: unknown, b: unknown, sortDirection: SortDirection): number {
    // null and undefined values will always be put at end
    if ((a == null || Number.isNaN(a as number)) && b) {
      return 1;
    }
    if (a && (b == null || Number.isNaN(b as number))) {
      return -1;
    }

    const isAsc = sortDirection === 'asc';
    const result = (a < b ? -1 : b < a ? 1 : 0) * (isAsc ? 1 : -1);

    return result;
  }

  /**
   * Compares 2 values using the Field definition and the sort.
   * Numbers and datetimes are sorted using the raw values, other values will be formatted
   */
  public static compare(
    a: SomeEntityChainable,
    b: SomeEntityChainable,
    sortDirection: SortDirection,
    field: EntityFieldDefinition,
  ): number {
    if (field) {
      const sortProperty = field.sortProperty ?? getPropKeyForNonFilterField(field);
      const sortPropertyType = field.sortPropertyType ?? field.type;
      // check if we have number that we must not sort in alphabetical order
      if (sortPropertyType === 'number') {
        return this.compareRaw(Number(getChained(a, sortProperty)), Number(getChained(b, sortProperty)), sortDirection);
      }

      /*
       * If we have a date we must order by date value and not by alphabetical value on the formatted date
       * If a sortingProperty is specified, we will sort by alphabetical value too
       */
      if (sortPropertyType === 'date' || sortPropertyType === 'datetime' || field.sortProperty != null) {
        return this.compareRaw(getChained(a, sortProperty), getChained(b, sortProperty), sortDirection);
      }

      // by default we will format both fields, and compare formatted results
      return this.compareRaw(
        DatabaseHelper.formatFieldValue(field, a),
        DatabaseHelper.formatFieldValue(field, b),
        sortDirection,
      );
    }

    return this.compareRaw(a, b, sortDirection);
  }

  public static editEntityInfo(
    actionEvent: ActionEvent,
    entity?: SomeEntity,
    editMode: boolean = true,
  ): EntityInformation {
    const action = actionEvent.action as EntityAction;
    const entityInformation: EntityInformation = {
      entityName: action.entityName,
      entity: entity,
      editMode: editMode,
      idField: 'id',
      closeAfterSave: true,
      afterSaveAction: actionEvent.afterEntitySave,
      afterCloseAction: actionEvent.afterClose,
      creation: true,
      layerId: action.layer,
      /*
       * if the user specified a specific id to be reloaded then we will keep it
       * otherwise we consider that after the safe we should reload the row/data item
       * with the ID of the entity
       */
      idToReload: actionEvent.idOfOriginalItem,
      prefill: actionEvent.data,
      reloadAllLayers: action.reloadAllLayers,
      reloadDashboardConfig: action.reloadDashboardConfig,
      isNew: actionEvent.isNew,
    };
    if (!entityInformation.idToReload && entity && entity.id) {
      entityInformation.idToReload = entity.id;
    }

    return entityInformation;
  }

  public static shouldShowOverride(row: SomeEntityChainable, field: EntityFieldDefinition): boolean {
    return getOriginalValue(row, field.id) !== undefined;
  }

  public static getOverrideFieldStyle(row: SomeEntity, field: EntityFieldDefinition): typeof OverrideFieldStyle | null {
    return DatabaseHelper.shouldShowOverride(row, field) ? OverrideFieldStyle : null;
  }

  public static getSpinergieValue(row: SomeEntityChainable, field: EntityFieldDefinition): string {
    const fieldAdditionalData = getAdditionalData(row, field.id);
    const spinValue = DatabaseHelper.getFormatFieldPureValue(field, fieldAdditionalData, 'originalValue');

    return '(Spinergie value: '
      + (spinValue && spinValue !== '' ? spinValue + (field.suffix ? ' ' + field.suffix : '') : 'no public information')
      + ')';
  }

  /**
   * Return false if:
   * - value is null or an empty string,
   * - value is false or 0, unless option showFalseOrZero is true,
   * - required value is falsy.
   * Otherwise return true.
   */
  public static shouldShowField(field: EntityFieldDefinition, values: RawDataPoint): boolean {
    const fieldValue = getChained(values, getPropKeyForNonFilterField(field));

    if (
      this.isNullOrEmpty(fieldValue) || this.shouldHideFalseOrZero(field, fieldValue)
      || this.requireValueIsFalse(field, values)
    ) {
      return false;
    }

    return true;
  }

  /**
   * This function should test if the button must appear by looking at his parameters
   * (initially extracted from complex-tooltip component)
   * @param button button that should appear which contain the parameters
   * @param datum data that must contain the information needed by the button
   */
  public static shouldShowButton(button: Button, datum: RawDataPoint, config: Config): boolean {
    if (button.type === 'openSelector' || button.type === 'openIntercom') {
      return true;
    }

    const hasData = datum != null;
    const buttonShown =
      // if there is a requirement it must be respected
      (!button.require || (hasData && getChained(datum, button.require)))
      // if layer exclude is specified it must be respected
      && (!button.excludeForLayers || (hasData && !button.excludeForLayers.includes(getChained(datum, 'layerId'))))
      // Then the datum must either own a value corresponding to the button key
      && (!button.key || (hasData && getChained(datum, button.key)))
      // Or containing value for each key in button.keys
      && (!button.keys || (hasData && button.keys.reduce((bool, key) => bool && !!getChained(datum, key), true)))
      /*
       * Or having at least one value for the parameters in button.params or not params at all
       * check if there is at least either one static parameter or either one parameter
       * in the data object (removing the prefix ":")
       */
      && (!button.params || !Object.values(button.params).length
        || Object.values(button.params).some(param => {
          return typeof param !== 'string' || !DataHelpers.isDataParam(param)
            || (hasData && !!getChained(datum, DataHelpers.extractDataParam(param)));
        }));
    if (buttonShown && button.type === 'navigate') {
      return config.checkRightsFromUrl(button.href);
    }

    return buttonShown;
  }

  private static isNullOrEmpty(value: unknown): boolean {
    return value == null || value === '';
  }

  /** Zero and false values are hidden unless option showFalseOrZeroValue is set to true in config */
  private static shouldHideFalseOrZero(field: EntityFieldDefinition, value: unknown): boolean {
    return !field.showFalseOrZeroValue && this.isFalse(value);
  }

  private static requireValueIsFalse(field: EntityFieldDefinition, values: unknown): boolean {
    return field.require && !values[field.require];
  }

  public static getFieldTooltip(row: SomeEntityChainable, field: EntityFieldDefinition): string | null {
    const additionalData = getAdditionalData(row, field.id);

    if (additionalData?.originalValue !== undefined) {
      if (additionalData.originalValue) {
        const formattedOriginalValue = DatabaseHelper.formatFieldValue(field, additionalData, 'originalValue');

        return `Spinergie value: ${formattedOriginalValue}`;
      }
      return 'Spinergie value: No public information';
    }

    if (additionalData?.comparison) {
      // Get the __additionalData.comparison object and treat 'previousValue' like a field
      const previousFormatted = DatabaseHelper.formatFieldValue(
        field,
        additionalData.comparison as SomeEntity,
        'previousValue',
      );
      return `Previous: ${previousFormatted}`;
    }
    return DatabaseHelper.getDescriptionFromProperty(row, field);
  }

  /**
   * Returns formatted field value with prefix or suffix if defined
   */
  public static formatFieldValue(
    field: EntityFieldDefinition,
    entity: SomeEntityChainable,
    fieldId: string | null = null,
    xlsxExport: boolean = false,
    timezone: DateTimezone = 'local',
  ): string | null {
    if (!entity) {
      return null;
    }
    const descriptionProperty = DatabaseHelper.getDescriptionFromProperty(entity, field);
    const finalFieldId = fieldId ?? getPropKeyForNonFilterField(field);
    const formattedFieldId = 'formatted_' + finalFieldId;

    if (formattedFieldId in entity && !field.truncate) {
      if (xlsxExport && descriptionProperty) {
        /*
         * If we are in xls export handling description property, we need to remove the  ending * if there is one
         * because we put the description property in xls file
         */
        const fieldStringValue = getChained<string>(entity, formattedFieldId);
        return fieldStringValue[fieldStringValue.length - 1] === '*' ? fieldStringValue.slice(0, -1) : fieldStringValue;
      }
      return getChained(entity, formattedFieldId);
    }

    let value = DatabaseHelper.getFormatFieldPureValue(field, entity, fieldId, timezone);
    if (field.truncate && !xlsxExport) {
      const maxLength = field.truncateLength ?? 150;
      const stringValue = value;
      if (stringValue.length > maxLength) {
        const halfLength = Math.floor(maxLength / 2);
        value = `${stringValue.substring(0, halfLength)} [...] ${
          stringValue.substring(stringValue.length - halfLength)
        }`;
      }
    }

    // Empty value: early return
    if (!value || value === '') {
      entity[formattedFieldId] = value;
      return '';
    }

    if (field.prefix) {
      value = field.prefix + ' ' + value;
    }

    // suffix is added to the field, but it will be skipped if the raw value is textual
    if (field.suffix) {
      const rawValue = getChained<number>(entity, finalFieldId);
      if (field.type === 'range' || (!isNaN(rawValue))) {
        value += ' ' + field.suffix;
      }
    }

    // Add * if we have a description property and no override and no excel export
    if (descriptionProperty && !xlsxExport && (!fieldId && !DatabaseHelper.shouldShowOverride(entity, field))) {
      value += '*';
    }

    /*
     * fieldId is set when this method is called to format the original value
     * of an overridden field. In this case we add the description in brackets
     */
    if (fieldId && descriptionProperty) {
      value += ' (' + descriptionProperty + ')';
    }

    if (field.type === 'coordinate' && getChained(entity, finalFieldId)) {
      const rawValue = getChained<number>(entity, finalFieldId);
      const isLongitude = DatabaseHelper.isLongitudeElseLatitude(field.id as CoordinateAxes);
      value = DatabaseHelper.formatCoordinate(rawValue, field.format, isLongitude);
    }

    entity[formattedFieldId] = value;
    return value;
  }

  public static formatCoordinate(value: number, format: string, isLongitude: boolean = false): string {
    switch (format) {
      case 'dms':
        return MapCoordsComponent.convertToDMS(value, isLongitude);
      case 'ddm':
        return MapCoordsComponent.convertToDDM(value, isLongitude);
      default:
        // TODO return value.toString() after checking potential side effects
        return value as unknown as string;
    }
  }

  /**
   * Interprets any strings with placeholders in format ({{field}}) based on the entity
   */
  public static interpretTextForEntity(entity: SomeEntity, definition: FieldsDefinition, titleString: string): string {
    const matches = DatabaseHelper.extractTokens(titleString);

    for (const match of matches) {
      const fieldDef = definition.fields.find(d => d.id === match);
      if (!fieldDef) {
        console.warn('field supposed to be part of the title and not in data: ' + match);
        continue;
      }
      const formattedValue = this.formatFieldValue(fieldDef, entity);
      titleString = titleString.replace(`{{${fieldDef.id}}}`, formattedValue);
    }

    return titleString;
  }

  /**
   * Title is a calculated string, the format is specified inside the entity definition titleString variable
   * and is interpreted.
   */
  public static getTitleForEntityAndDefinition(entity: SomeEntity, definition: EntityDefinition): string {
    const titleString = definition.titleString;
    return DatabaseHelper.interpretTextForEntity(entity, definition, titleString);
  }

  /**
   * Returns formatted field value without prefix or suffix
   * Transforms dates, choices, entities.
   *
   * FIXME-TYPES: this does not only return string values
   *
   * @param  {EntityFieldDefinition} field     Field config
   * @param  {SomeEntity}            entity    Entity values
   * @param  {string}                fieldId   Override field ID
   * @param  {DateTimezone}          timezone  Component timezone
   * @return {mixed}                           Formatted value
   */
  public static getFormatFieldPureValue(
    field: EntityFieldDefinition,
    entity: SomeEntityChainable,
    fieldId: string = null,
    timezone: DateTimezone = 'local',
  ): string {
    /*
     * We can pass a specific fieldId to retrieve a specific data value in the entity
     * using all the formatting process available in the rest of the system
     */
    const finalFieldId = fieldId ?? getPropKeyForNonFilterField(field);
    const entityValueOfField = getChained(entity, finalFieldId);

    if (field.nullItem && entityValueOfField === null) {
      return field.nullItem;
    }

    /** Range type does not use regular propTitle/propValue/id */
    if (
      (field.type !== 'range' && entityValueOfField == null) || (field.type === 'choice' && entityValueOfField === 0)
    ) {
      return '';
    }

    switch (field.type) {
      // Numbers
      case 'number':
        return DataHelpers.formatNumber(entityValueOfField as number, field.format);

      // Ranges
      case 'range': {
        const formattedRangeValues = DataHelpers.formatRangeValue(
          getChained(entity, field.leftPropValue),
          getChained(entity, field.rightPropValue),
        );
        return formattedRangeValues ?? getChained(entity, finalFieldId);
      }

      /**
       * In case we are using date, we don't want to apply timezone
       * as date will always convert in midnight, applying timezone
       * will change the date. So we want to keep as we store them,
       * i.e in UTC
       */
      case 'date':
        return DatabaseHelper.getFormattedDate(entity, field, 'UTC');
      /**
       * In case we are using datetime, we want to apply timezone
       */
      case 'datetime':
      case 'time':
        return DatabaseHelper.getFormattedDate(entity, field, timezone);

      // Durations
      case 'duration': {
        return DataHelpers.formatMillisecondsDuration(
          entityValueOfField as number,
          field.format,
          field.inputUnit ?? field.durationUnit,
        );
      }
      case 'durationRange':
        return DataHelpers.formatDurationRange(entityValueOfField as string, field.format, field.durationUnit);

      // Entities, collections & files
      case 'entity':
      case 'client_entity':
      case 'collection':
      case 'file':
      case 'files':
        return DatabaseHelper.getLinkedEntityTitleForDefinition(field, entity, entityValueOfField);
      // Choices
      case 'choice': {
        if (!field.values) {
          /*
           * Choices are transformed by the endpoint to simple text values (post-processing),
           * so if `values` are not provided no mapping needs to be done.
           */
          break; // default: return entityValueOfField
        }

        /*
         * customDataFields can be of type choice and return directly values (already mapped)
         * so we don't want this warning to appear
         */
        const choice = field.values.find(d => d.id == entityValueOfField);
        if (choice === undefined && !field.customDataField) {
          console.warn(`Choice with id '${entityValueOfField as string}' wasn't found for the field '${field.title}'`);
        }

        /*
         * Some choice depend of the config and, when it was declared under another config
         * it's not yet available with another config which can cause the choice to not be
         * in field.values
         */
        if (choice) return choice.title;
        break; // default: return entityValueOfField
      }
      // Yes/No
      case 'checkbox':
      case 'boolean':
        /*
         * normally we don't show anything for boolean FALSE values, we will return an empty string
         * but for overrides we can't just return an empty string, because if TRUE is overridden with FALSE
         * empty string would look strange (with the highlight)
         * We also return 'No' if it is explicitly specified by FieldSettings option `showFalseOrZeroValue`
         */
        field.showFalseOrZeroValue ??= getOriginalValue(entity, field.id) !== undefined;

        if (DatabaseHelper.isTrue(entityValueOfField)) {
          return 'Yes';
        } else if (field.showFalseOrZeroValue && DatabaseHelper.isFalse(entityValueOfField)) {
          return 'No';
        }
        return '';

      // Checkbox w/ details
      case 'checkbox-details-required': {
        const checkboxValue = DatabaseHelper.isTrue(entityValueOfField['value'])
          ? `Yes, ${entityValueOfField['comment']}`
          : '';
        const details = getChained<string>(entity, `${field.id}_data`);
        return details ? details : checkboxValue;
      }

      /*
       * Links
       * pageLinkList items should be returned as arrays from the back
       */
      case 'pageLinkList': {
        const values = getChained<string[] | string>(entity, field.id);
        if (values === undefined) return '';

        return Array.isArray(values) ? values.join(', ') : values;
      }

      case 'datetimeWithTimezone': {
        if (!entityValueOfField['datetimeUtc']) {
          return;
        }
        const dateTimezone = entityValueOfField['timezone'] as string ?? 'utc';
        const dateInTz = DateHelper.dateInTz(entityValueOfField['datetimeUtc'] as string, dateTimezone);
        const offset = DateHelper.getOffsetFromTimezone(dateTimezone, 'UTC', dateInTz);
        return `${dateInTz.format('YYYY-MM-DD HH:mm')} (${offset})`;
      }
      default:
    }

    // default case: return entityValueOfField
    if (!entityValueOfField) return '';
    /*
     * return entityValueOfField without casting as a last resort to avoid side effects in code relying on it being
     * an object.
     * TODO: explicitly handle such cases above and return a specific object type
     * OR remove usage of getFormatFieldPureValue when something else than a string is expected
     */
    return Array.isArray(entityValueOfField) ? entityValueOfField.join(', ') : entityValueOfField as string;
  }

  /**
   * Interprets title using the fields specified in {{token}} format, but without formatting
   */
  public static interpretSimpleTitle(title: string, entity: SomeEntityChainable): string {
    // If the title does not contain a token, it is just a text to display as is
    if (!title.includes('{{')) {
      return title;
    }

    // title contains {{ token }} -> we interpret it
    const titleKey = DatabaseHelper.extractTokens(title)[0];
    const titleValue = getChained(entity, titleKey);

    if (typeof titleValue !== 'string') {
      console.error(`Trying to get title from entity with token ${title}, but value is ${typeof titleValue}`);
      return '';
    }

    return titleValue;
  }

  public static getPropertyOrReplaceTokens(propertyOrTitleString: string, params: RawDataPoint): string {
    if (propertyOrTitleString.indexOf('{{') < 0) {
      return getChained(params, propertyOrTitleString);
    }
    return this.replaceTokens(propertyOrTitleString, params);
  }

  public static replaceTokens(titleString: string, params: RawDataPoint): string {
    if (titleString.indexOf('{{') < 0) {
      return titleString;
    }
    let completedString = titleString;
    const tokens = DatabaseHelper.extractTokens(completedString);
    for (const token of tokens) {
      completedString = completedString.replace(`{{${token}}}`, getChained(params, token) ?? '');
    }
    return completedString;
  }

  public static extractTokens(titleString: string): string[] {
    const tokenRegex = /{{([^}]+)}}/g;
    const matches: string[] = [];
    let match = tokenRegex.exec(titleString);
    while (match != null) {
      matches.push(match[1]);
      match = tokenRegex.exec(titleString);
    }
    return matches;
  }

  public static isTrue(value: unknown): boolean {
    return value === 1 || value === true || value === '1';
  }

  public static isFalse(value: unknown): boolean {
    return value === 0 || value === false || value === '0';
  }

  /**
   * Get formatted date from field (def + value)
   * Used by `entity-table.ts`, `entity-detail.ts` & `entity-detail-field.ts`
   *
   * @param  {SomeEntity}            row       Row value
   * @param  {EntityFieldDefinition} field     Field definition (including format)
   * @param  {DateTimezone}          timezone  Component timezone
   * @return {mixed}                           Formatted date as a string (or mixed value found)
   */
  public static getFormattedDate(
    row: SomeEntityChainable,
    field: EntityFieldDefinition,
    timezone: DateTimezone = 'local',
  ): string {
    // Just get timestamp
    const ms = DatabaseHelper.getDateOnValue(field, row, true);
    const format = this.getFieldFormat(field);

    if (ms !== null) { // could be 0
      // Call DateHelper.formatDateTime with timestamp
      return DateHelper.formatDatetime(ms, format, timezone);
    }

    return getChained(row, getPropKeyForNonFilterField(field));
  }

  /**
   * Get date from field (def + value)
   * Used in `entity-data-accessor.ts` & `getFormattedDate()`
   *
   * 2 overload signatures:
   * - with `timestampOnly: false | undefined` -> returns `Dayjs`
   * - with `timestampOnly: true` -> returns `number`
   *
   * @param  {EntityFieldDefinition} field          Field definition (including format)
   * @param  {SomeEntity}            value          Row value
   * @param  {boolean}               timestampOnly  Get timestamp, not DayJS object
   * @return {Dayjs|number}                         DayJS or UNIX timestamp
   */
  static getDateOnValue(
    field: EntityFieldDefinition,
    value: SomeEntityChainable,
    timestampOnly?: false,
  ): Dayjs;
  static getDateOnValue(field: EntityFieldDefinition, value: SomeEntityChainable, timestampOnly: true): number;
  static getDateOnValue(
    field: EntityFieldDefinition,
    value: SomeEntityChainable,
    timestampOnly: boolean = false,
  ): Dayjs | number {
    /*
     * IMPORTANT: this is datepickers limitation, as soon as it is bound to new instance
     * it will retrigger change detection - going into infinite loop, so we have to store the
     * the date instance somewhere
     */
    const usedId = field.propValue ?? field.id;
    if (value) {
      const daysJsFieldId = this.dayJsField(field.id);

      /*
       * existing moment value - we will return it only if the timestamp value corresponds correctly
       * in case where the timestamp would be different this old moment value will be overridden
       */
      let momentValue = getChained<Dayjs>(value, daysJsFieldId);

      // this is the first time that we try to create the wrapper
      const timestampValue = getChained(value, usedId);
      if (timestampValue !== null && timestampValue !== undefined) {
        const ms = getChained<number>(value, usedId);

        // Return timestamp only
        if (timestampOnly) {
          return ms;
        }

        /*
         * from the docs - "Despite Unix timestamps being UTC-based,
         * this function creates a moment object in local mode
         * if the field is of type date we will force it to be UTC date
         */
        let freshDayjsValue: Dayjs;
        if (field.type === 'datetime' || field.type === 'time') {
          freshDayjsValue = dayjs(ms);
        } else if (field.type === 'date') {
          freshDayjsValue = dayjs(ms).utc();
        } else {
          console.warn('Getting date value on not-a-datetime field');
        }

        /*
         * if this is the first time the moment value is defined or
         * if the current moment value is not the one of the timestamp (eg, timestamp changed due to calculation)
         * or the date was not created as local date - that means it was created by the datepicker
         * as UTC date. And UTC dates are not formatted by momentjs
         */
        if (
          !momentValue || momentValue.valueOf() !== freshDayjsValue.valueOf()
          || freshDayjsValue.locale() !== momentValue.locale()
        ) {
          momentValue = freshDayjsValue;
        }

        // IMPORTANT: here we store the instance of dayJS
        value[daysJsFieldId] = momentValue;

        return momentValue;
      } else {
        value[daysJsFieldId] = null;
      }
    }
    return null;
  }

  static dayJsField(id: string): string {
    return id + '__dayjs';
  }

  public static getFieldFormat(field: EntityFieldDefinition): string {
    if (field.fmt) {
      return field.fmt;
    }
    if (field.format) {
      return field.format;
    }
    if (field.type === 'date') {
      return 'YYYY-MM-DD';
    }
    if (field.type === 'time') {
      return 'HH:mm';
    }
  }

  public static getLinkedEntityTitleForDefinition(
    field: EntityFieldDefinition,
    entity: SomeEntityChainable,
    valueOnEntity: unknown,
  ): string {
    /*
     * if we don't have listed values or if the value don't have an id (new element), then we should be able to
     * get this directly from the table
     */
    if (!field.values || !valueOnEntity) {
      const tableValue = getChained<string>(entity, DatabaseHelper.getReadableTitleFieldId(field.id));
      if (tableValue) {
        return tableValue;
      } else {
        /*
         * if we didn't find the necessary value we will try to show
         * at least the id or whatever is in the  field
         *
         * return the value without casting to string because we rely on the value being returned unchanged in some
         * cases (eg. for field.type === 'files')
         * TODO: return objects explicitly and add the types to the function return type
         * OR return string only and stop using getLinkedEntityTitleForDefinition when an object is expected
         */
        return valueOnEntity ? valueOnEntity as string : '';
      }
    }

    /*
     * Note: this must stay a loose comparison with == (and not ===!). We can pass initial data as string and have them
     * as int in the autocomplete. For instance to autofill a vessel id when opening an addModal modal.
     */
    const value = field.values.find(d => d.id == valueOnEntity);
    return value?.title ?? '';
  }

  public static getReadableTitleFieldId(fieldId: string): string {
    return fieldId + '_title';
  }

  /**
   * Remove all 'formatted_' values to force entity-table to refresh data
   * Used by search-specs (re-format highlighted values)
   * and entity-table-wrapper / summary (re-format dates in local timezone)
   *
   * @param  {object[]} array  Entity table rows
   * @return {object[]}        Clean data
   */
  public static removeFormattedFields(entity: SomeEntity): SomeEntity {
    for (const field in entity) {
      if (field.indexOf('formatted_') > -1) {
        delete entity[field];
      }
    }
    return entity;
  }

  public static removeFormattedFieldsEntities(array: SomeEntity[]): SomeEntity[] {
    array.forEach(DatabaseHelper.removeFormattedFields);
    return array;
  }

  public static hasEditRight(
    entityRightsCheckable: DeepReadonly<EntityRightsCheckable>,
  ): boolean {
    return entityRightsCheckable.userEntityRights.includes('write');
  }

  public static hasCustomEditRight(entityRightsCheckable: EntityRightsCheckable): boolean {
    return entityRightsCheckable.userEntityRights.includes('customWrite');
  }

  public static hasOverrideEditRight(entityRightsCheckable: EntityRightsCheckable): boolean {
    return entityRightsCheckable.userEntityRights.includes('overrideWrite');
  }

  public static hasOverrideReadRight(entityRightsCheckable: EntityRightsCheckable): boolean {
    return entityRightsCheckable.userEntityRights.includes('overrideRead')
      || entityRightsCheckable.userEntityRights.includes('overrideWrite');
  }

  public static getDescriptionFromProperty(row: SomeEntityChainable, field: EntityFieldDefinition): string | null {
    const descriptionValue: string = getChained(row, field.descriptionProperty);
    if (field.descriptionProperty && descriptionValue) {
      return descriptionValue;
    }
    return null;
  }

  public static isLongitudeElseLatitude(axes: CoordinateAxes): boolean {
    return axes.toLocaleLowerCase() === 'longitude';
  }

  public static getFieldWidth(field: EntityFieldDefinition): number {
    const type = field.type;
    const fullWidth = window.innerWidth * DatabaseHelper.INNER_WINDOW_FACTOR_MARGIN
      - DatabaseHelper.INNER_WINDOW_PX_MARGIN;
    if (field.scale) return fullWidth * field.scale;
    if (
      [
        'date',
        'datetime',
        'duration',
        'text',
        'number',
        'entity',
        'client_entity',
        'checkbox',
        'coordinate',
        'checkbox-details-required',
      ].includes(type)
    ) {
      return fullWidth * 0.25;
    }
    if (type === 'choice' && !field.style) return fullWidth * 0.05;

    if (type === 'collection') {
      return (field.formType === 'multi') ? fullWidth * 0.25 : fullWidth;
    }
    return fullWidth * 0.5;
  }

  /**
   * Checks whether an entity has validity date fields configured. Used to know when it is possible to display or hide
   * invalid entities.
   */
  public static isValidityDateEntity(definition: EntityDefinition): boolean {
    const validityDateAttributes = definition?.validityDateAttributes;
    if (!validityDateAttributes) return false;

    return Object.values(validityDateAttributes).some(attr =>
      definition.fields.some(field => Boolean(field.id === attr))
    );
  }

  /**
   *  Some entities can be outdated:
   *  - activity type, form field, activity field, liquid capacity, equipment, liquid type, aoi
   *  have a validity date start and a validity date end, if current entity date is outside this range
   *  the entity won't be visible.
   */
  public static isEntityOutDated(
    entity: ValidityDateEntity,
    filteringDate: Dayjs,
    bufferEnd: Duration = dayjs.duration(0, 'd'),
  ): boolean {
    const validityStartDate: number = entity.validityDateStart
      ? dayjs(entity.validityDateStart).startOf('d').valueOf()
      : 0;
    const validityEndDate: number = entity.validityDateEnd
      ? entity.validityDateEnd + bufferEnd.asMilliseconds()
      : Number.MAX_SAFE_INTEGER;
    // since we can have date or datetime, need to use start of filtering date
    const reportDateStartOfDay = filteringDate.startOf('d');
    const reportDateTimestamp = DateHelper.numericalValue(reportDateStartOfDay);
    return reportDateTimestamp > validityEndDate || reportDateTimestamp < validityStartDate;
  }
}
