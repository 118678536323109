import { Geometry as GeoJSONGeometry, GeometryCollection } from 'geojson';
import * as CSS from 'csstype';
import { Dayjs, UnitType } from 'dayjs';
import { Selection, BaseType as SelectionBaseType } from 'd3-selection';
import { PlotDatum } from 'plotly.js';
import { DurationUnitType } from 'dayjs/plugin/duration';
import { LngLat } from 'maplibre-gl';

import { EntityDataAccessor } from '../database/entity-data-accessor';
import { FileObject } from '../database/file-uploader';
import { Aggregation, ChartComponentSettings, ChartSelect, ChartValues, PlotlyLineStyle, RawDataPoint, Series,
  SeriesTypeValues } from '../graph/chart-types';
import { EntityTableComponentSettings, Filter, IHaveTimezone, InviteComponentSettings, KpisComponentSettings,
  NavigationComponentDashboardConfig, NavigationComponentSectionConfig, PhaseDetailsComponentSettings,
  PictureComponentSettings, SummaryComponentSettings, WidgetComponentSettings } from './config-types';
import { SyncableConfig } from '../live-dpr/models/reporting-config-types';
import { SelectorConfig } from '../selector/selector.types';
import { MapComponentSettings } from '../pages';
import { ScheduleComponentSettings } from '../pages/schedule-wrapper';
import { LinkData } from '../shared';
import { RefDatasetItem } from '../data-loader/ref-data.types';
import { DistanceToEntityFilterConfig } from '../filters/distance-to-entity/distance-to-entity.types';
import { PearlIcon } from '../shared/pearl-components';
import { ButtonType } from '../shared/pearl-components/components/buttons/pearl-button.component';
import { DatePrecision } from '../shared/pearl-components/components/datepicker/datepicker-types';
import { ColorDefinition } from './legend-types';
import { PearlHeroSectionStatusOptions } from '../shared/pearl-components/components/hero-section/pearl-hero-section.types';

/** Used for modifying some properties on a given type */
export type Modify<T, R> = Omit<T, keyof R> & R;
/** Used to pick some properties of K */
export type Subset<K, T extends K> = T;
/** Make an object and his nested properties readonly */
export type DeepReadonly<T> = T extends Map<infer K, infer V> ? ReadonlyMap<DeepReadonly<K>, DeepReadonly<V>>
  : T extends Set<infer S> ? ReadonlySet<DeepReadonly<S>>
  : T extends (...args: unknown[]) => unknown ? T
  : T extends object ? { readonly [K in keyof T]: DeepReadonly<T[K]> }
  : T;

export type DeepWriteable<T> = { -readonly [P in keyof T]: DeepWriteable<T[P]> };
export type ExtractReadonlyType<T> = T extends DeepReadonly<infer Y> ? Y : never;

export type SpinProduct = 'construction' | 'spinrig' | 'osv' | 'bitumen' | 'analysts' | 'monitoring';
export const OSV_PRODUCTS: SpinProduct[] = ['bitumen', 'osv'];
export type SpecName = 'rig' | 'vessel' | 'quay';
export type SpinTimeUnit =
  | 'D'
  | 'M'
  | 'd'
  | 'day'
  | 'days'
  | 'h'
  | 'hour'
  | 'hours'
  | 'ms'
  | 'millisecond'
  | 'milliseconds'
  | 'm'
  | 'minute'
  | 'minutes'
  | 'month'
  | 'months'
  | 's'
  | 'second'
  | 'seconds'
  | 'w'
  | 'week'
  | 'weeks'
  | 'y'
  | 'year'
  | 'years';
export type DurationUnit = 'd' | 'h' | 'm' | 's' | 'ms';
export type CoordinateType = 'degrees' | 'decimal';
export type CoordinateAxes = 'latitude' | 'longitude';
export const INIT_BRUSH_KEYWORDS_FROM_DATA = [
  'fixed',
  'date_max',
  'date_min',
  'relative_min',
  'relative_max',
  'past_relative',
] as const;
export type InitBrushKeyword = typeof INIT_BRUSH_KEYWORDS_FROM_DATA[number] | 'now';
export type InitBrushValue =
  | number
  | InitBrushKeyword
  | `${number} ${InitBrushKeyword}`
  | `-${number} ${InitBrushKeyword}`;
export type InitBrushConfigValue = InitBrushValue[] | 'auto';
export type InitBrushUnit = 'year' | 'month' | 'day' | 'hour' | 'fixed';

export enum ModeEnum {
  NORMAL = 'NORMAL',
  EDIT = 'EDIT',
}

export const milliSecondInPeriod = {
  'second': 1000,
  'minute': 60 * 1000,
  'hour': 3600 * 1000,
  'day': 3600 * 24 * 1000,
  'isoWeek': 3600 * 24 * 1000 * 7,
  'month': 3600 * 24 * 1000 * (365.25 / 12),
  'quarter': 3600 * 24 * 1000 * (365.25 / 12) * 3,
  'year': 3600 * 24 * 1000 * 365.25,
};

export const DEFAULT_MAX_TRACES = 10;
/**
 * @see DateHelper.period method for more details
 */
export type EraType = 'current' | 'past' | 'future' | 'previous' | 'previousExtent' | 'pastAndFuture';
export type ScalePeriodType = 'day' | 'hour' | 'week' | 'month' | 'quarter' | 'year';
export type StringDuration = `${number | ''}${UnitType}`;

export interface Duration {
  n: number;
  unit: DurationUnitType;
}

export type EraRelativeTo = 'now_utc' | 'end_of_today_utc' | 'end_of_yesterday_utc' | 'end_of_month_utc';

/**
 * @description
 * This interface describe eras used in fieldset (filter) dropdown, when choosing
 * a period that will shape x axis on graphs, or period of data in a table, ...
 *
 * @example
 * Last seven days
 * {
 *   "title": "Last 7 days",
 *   "scale": "day",
 *   "extent": 7,
 *   "type": "past"
 * },
 */
export interface Era {
  /**
   * string displayed within dropdown
   */
  title: string;
  id?: string;
  type: EraType;
  /*
   * When extent is used type, gives timeline direction in which we want to move
   * scale gives a sense to extent: <type>past <extent>1 <scale>week
   */
  extent?: number;
  scale?: ScalePeriodType;
  /**
   * Only for KPI comparison. Means "shift the master period in the past by its duration
   */
  isPreviousEra?: boolean;
  isCompleteDataset?: boolean;
  /**
   * Used by dpr when selecting last 7 days, we actually mean:
   *    7 days in past, until furthest report created in the future
   */
  filterOnlyPast?: boolean;
  /**
   * Used only in one type of era : pastAndFuture.
   */
  pastExtent?: number;
  /**
   * Used only in one type of era : pastAndFuture.
   */
  futureExtent?: number;
  /**
   * Used to indicate explicitly reference timestamp that will be the end of intervals of type "past".
   *
   * @example
   * Last seven days: for heavy analytics we compute date only at midnight and don't
   * have data in the future. This means we want to have last seven days from yesterday
   * back to 7 days before.
   * {
   *   "title": "Last 7 days",
   *   "scale": "day",
   *   "extent": 7,
   *   "relativeTo": "end_of_yesterday_utc"
   * }
   */
  relativeTo?: EraRelativeTo;
}

export const COMPLETE_ERA: Era = {
  title: 'Complete dataset',
  type: 'past',
  isCompleteDataset: true,
};

export const PREVIOUS_PERIOD_ERA: Era = {
  title: 'Previous period',
  isPreviousEra: true,
  type: 'previousExtent',
};

export type LayerLoadingOptions = {
  clearCache: boolean;
  displayLoadingPanel: boolean;
  autoRefresh?: boolean;
  urlParams?: { name: string; value: any }[];
};

export type Geometry = Exclude<GeoJSONGeometry, GeometryCollection>;

export interface GeoEntity extends SomeEntity {
  latitude?: number;
  longitude?: number;
  geometry?: Geometry;
}

export interface EntityOverlay {
  id: NumOrString;
  entityName: string;
  entity: GeoEntity;
  layer: LayerId;
  geometryField?: string;
}

type RGB = `rgb(${number}, ${number}, ${number})`;
type RGBA = `rgba(${number}, ${number}, ${number}, ${number})`;
export type HEX = `#${string}`;

export type Color = RGB | RGBA | HEX | CSS.DataType.NamedColor;

/**
 * Aggregation operations
 * 'none' is a special value for scatter plots, eg. no aggregation
 */
export type CalcOperation =
  | 'none'
  // Average
  | 'avg'
  // Absolute sum
  | 'sum'
  // Maximum
  | 'max'
  // Minimum
  | 'min'
  // Relative sum (percent)
  | 'psum'
  // Sum and normalize by the group duration
  | 'nsum'
  // Sum and normalize by the calc interval duration
  | 'xsum'
  // Sum and normalize by overall sum
  | 'normSum'
  // Absolute count
  | 'count'
  // Relative count (percent)
  | 'pcount'
  // Count distinct values
  | 'dcount'
  // Count and normalize by overall count
  | 'normCount'
  // Cumulative
  | 'cum'
  // Cumulative percents
  | 'pcum'
  // Quantiles
  | 'q'
  // Many-to-many (Analytics, used by the back only)
  | 'm2m';

export interface HeavyDataSeries extends Series<ChartValues> {
  splitPageBaseUrl?: string;
}

export interface HeavyQueryBase {
  baseUrl: string;
  searchParams: URLSearchParams;
  requestorId: string;
  endpointType: HeavyEndpointType;
  filtersState: { [id: string]: any[] };
  filterConfig: { [fieldName: string]: FilterConfig };
}

export interface HeavyQuery extends HeavyQueryBase {
  group: string;
  split: string;
  operation: Aggregation;
  tabFilterSelected: string;
}

export interface HeavyAnalyticsQuery extends HeavyQuery {
  endpointType: 'heavy';
  componentId: string;
  configFiltersPath: string;
  nullGroupTitle: string;
  nullSplitTitle: string;
}

export interface HeavyActivityQuery {
  selectsConfig?: any;
  filterByInterval?: boolean;
  specificFilters?: { [propId: string]: string[] };
  appliedFilters?: { [id: string]: any[] };
  group: string;
  intervalExtent: Interval;
  /**
   * `restrictedVesselList` is applied when we applied a filter on a other hard layer (eg. filter on Charters)
   * in this case this endpoint should return only vessel having data on those other hard layers
   */
  restrictedVesselList: number[];
  tabFilterSelected: string;
}

export enum DataSource {
  /** Original data, from endpoint */
  ORIGINAL = 1,
  /** Data point calculated to show alongside polygons (when showPointAlongsideShape is true) */
  ALONGSIDE = 2,
  /**
   * Due to limitation of MapLibre, we have a separate layer for displaying dashed lines.
   * See https://github.com/maplibre/maplibre-gl-js/issues/1235
   */
  LINE_DASH = 3,
  /** Only used to display polygons / lines labels */
  FOR_LABELS = 4,
}

export type BBox2d = [number, number, number, number];

/**
 * Base type containing geometry information straight from endpoint. It can be extended to reflect additional data
 * returned by the endpoint.
 */
export interface MinimalGeometryResponse {
  id: number;
  geometry?: Geometry | null;
  latitude?: number;
  longitude?: number;
  area?: number;
  centerLat?: number;
  centerLon?: number;
  center?: [number, number];
  shapeId?: number;
}

export interface TraceData extends MinimalGeometryResponse {
  datetime: number;
  activityStatusId: number;
  noCoverage?: boolean;
  latestLoc?: boolean;
  priority?: number;
  centerLat?: number;
  centerLon?: number;
  vesselId: number;
  course?: number;
  locationId: number;
  nbFilteredOut?: number;
  /**
   * Store original index in trace data received from endpoint. Useful to detect if two points are consecutive
   * after pruning the points for performance.
   */
  originalIndex?: number;
}

interface VesselData {
  vesselId: number;
  traceAvailable?: boolean;
  course: number;
  speed: number;
  activityStatusId: number;
  datetime: number;
  layerId: LayerId;
  vessel: string;
  latitude?: number;
  longitude?: number;
  isNotRetiredNorCancelled?: boolean;
  fTension?: number;
  jTension?: number;
  jMaxOd?: number;
  rTension?: number;
  sTension?: number;
  sMaxOd?: number;
  rMaxOd?: number;
  vesselImageURI?: string;
}

export interface VesselDataRaw extends VesselData, RawDataPoint {}

export interface VesselDataGeom extends VesselData, MinimalGeometryResponse {}

export interface CamembertDataPoint {
  title: string;
  color: string;
  completed: boolean;
}

export interface CamembertData extends MinimalGeometryResponse {
  camembertData: CamembertDataPoint[];
}

/**
 * Created properties after preparation of map item. We extend RawDataPoint here for linting to work, if we do
 * it in MinimalGeometryResponse typescript can't figure out some properties origins
 */
export interface PreparedGeomItem extends RawDataPoint {
  id: number;
  layerId: LayerId;
  /** Can require ref data at creation, so it's init directly in the layer */
  mapLabel?: string;
  /** Area in km² */
  area?: number | null;
  geometry?: Geometry | null;
  /** Deduced from shapeId */
  iconCode: string | null;
  /** Specify where the data comes from (original data vs computed for alongside for instance) */
  dataSource?: DataSource;
  lngLat: LngLat | null;
}

export interface PreparedTraceData extends TraceData, PreparedGeomItem {}
export interface PreparedVesselData extends VesselDataGeom, PreparedGeomItem {}
export interface PreparedCamembertData extends CamembertData, PreparedGeomItem {}

export type LayerId = string;

export type LayerDrawingMode = Subset<LayerType, 'historical' | 'latest'>;

/** If geo coordinates, it's [longitude, latitude] */
export type Coords = [number, number];

export type DMSCoords = [string, string];

/** Date range, min and max. */
export interface DateRange {
  /** The min. date */
  minDate: number;
  /** The max. date */
  maxDate: number;
}

export type FieldType =
  | 'boolean'
  | 'button'
  | 'chart'
  | 'checkbox'
  | 'checkbox-details-required'
  | 'choice'
  | 'client_entity'
  | 'collection'
  | 'colorPicker'
  | 'coordinate'
  | 'date'
  | 'datetime'
  | 'datetimeWithTimezone'
  | 'duration'
  | 'durationRange'
  | 'entity'
  | 'externalLink'
  | 'file'
  | 'files'
  | 'filterResume'
  | 'graph'
  | 'image'
  | 'link'
  | 'number'
  | 'pageLink'
  | 'pageLinkList'
  | 'picture'
  | 'quarter'
  | 'range'
  | 'score'
  | 'string'
  | 'html'
  | 'text'
  | 'textLong'
  | 'timezone'
  | 'vesselNumber'
  | 'booleanDropdown'
  | 'aisTracking'
  | 'time'
  | 'tank'
  | 'aoi'
  | 'vesselType'
  | 'vessel'
  | 'poi'
  | 'garbageCategory'
  | 'unit'
  | 'integrated-table'
  | 'json'
  | 'blank'
  | 'performedActivity'
  | 'composite'
  | 'flexibleTableFixedRowsHeader'
  | 'workphase';

export type FilterType =
  | 'datetime'
  | 'multi'
  | 'date'
  | 'checkbox'
  | 'checkbox-exclude'
  | 'interval'
  | 'intersection'
  | 'doubledate'
  | 'number'
  | 'quarter'
  | 'excludemulti'
  | 'dropdown'
  | 'forceInclude'
  | 'forceExclude'
  | 'single'
  | 'regions'
  | 'entity'
  | 'vessels_advanced_filters'
  | 'text'
  | 'hourTime'
  | 'searchEngineFilter'
  | 'distanceToEntity';

export type MultiFieldStyle = 'searchBar' | 'simpleField' | 'dropdown' | 'checkbox' | 'chiplist' | 'integrated';

/**
 * - `soft` (default) this layer does not affect other layers
 * - `hard` if a filter is applied on this layer then an item (vessel/windfarm etc) which is filtered out due to filter
 *   on this layer will not be visible - even if it has contracts on other layers
 * - `excludeFiltered` if the item is filtered out due to interval it will make the line disappear, similar to "hard"
 *   filtering but based on interval filter
 */
export type ScheduleLayerFiltering = 'soft' | 'hard' | 'excludeFiltered';

export interface ColorMapping {
  [value: string]: string;
}

/**
 * A date time interval expressed in milliseconds
 */
export type Interval = [number, number];
export type IntervalOrNull = Interval | null;

/**
 * An interval with Era information, if applicable.
 */
export interface ResolvedInterval {
  /** Defined when the extent comes from an Era selection */
  era?: Era;
  /** Resolved interval, always defined. In the case of "Complete dataset" Era, it is null */
  extent: IntervalOrNull;
}
export interface IntervalField extends FilterConfig {
  id: string;
  title: string;
  /** Filter type (default: 'datetime') */
  filterType?: 'date' | 'datetime' | 'interval' | 'doubledate' | 'intersection';
  propValue?: string;
  force?: boolean;
  step?: number;
  /** FIXME: this is on the config but is only used to store the computed interval value */
  interval?: Interval;
  default?: Era;
  minusPlus?: boolean;
  defaultFocus?: string;
  description?: string;
  noValue?: boolean;
  /** If true, a dropdown select will be present. In this case, eras must be defined*/
  selectPeriod?: boolean;
  eras?: Era[];
  empty?: any;
  /** When selectPeriod is true, must be true for the select to render */
  preset?: boolean;
  /** Labels for left and right date of the double-date */
  startLabel?: string;
  endLabel?: string;
  /** The unit that the picker allows to pick */
  timeGranularity?: DatePrecision;
  /** If not only config option, we will display "complete dataset" in era options */
  onlyConfigOptions?: boolean;
  fixedInterval?: boolean;
  /** Initial brush interval (may be different than masterPeriod) */
  initBrushInterval?: InitBrushConfigValue;
  /** Brush init unit (default: 'month') */
  initBrushUnit?: InitBrushUnit;
  format?: 'dateMinusPlus';
  /** If true, will synchronize components when changed */
  syncComponentIntervals?: boolean;
}

export interface FieldOrdering {
  /** The property holding the order of the value. */
  propOrder?: string;
  /** Option required for wfi-vessel-selector */
  orderDirection?: SortDirection;
  /** The ordered list of values */
  ordered?: string[];
}

export interface ConditionalStyle {
  css: any;
  condition: string;
}

/* Type of value that can be set to a field or a data item property. */
export type FieldValue =
  | 'firstAvailable' /** Choose first available option after the field has been populated */
  | string
  | number
  | boolean
  | string[]
  | number[]
  | boolean[]
  | Era
  | LinkData;

export interface FieldSettings extends FilterConfig, FieldOrdering {
  id: string;
  title: string;
  code?: string;
  description?: string;
  default?: FieldValue;
  /**
   * If an `autozoom` value is set, it tells that autozoom must be applied if this is the last filter.
   * The specified number would be the max applied zoom.
   *
   * For example:
   * - a zoom of 9 is equivalent to zooming over Bretagne (France).
   * - if a field config specifies an autozoom of 9 and there are only one vessel filtered in, the zoom would still be
   * of the Bretagne size.
   * - However, if there are vessels all over the seas the zoom would be on the entire Earth.
   */
  autozoom?: number | boolean;
  values?: OptionValue[];
  value?: any;
  suffix?: string;
  format?: string;
  colors?: { [value: string]: ColorMapping };
  precision?: number;
  step?: number;
  restricted?: boolean;
  noEdit?: boolean;
  limit?: number;
  style?: MultiFieldStyle;
  minSearchLength?: number;
  defaultFocus?: string;
  visible?: boolean;
  filteringEnabled?: boolean;
  hint?: string;
  placeholder?: string;
  icon?: PearlIcon;
  prefix?: string;
  /** Used for filter columns dialog */
  fieldsetTitle?: string;

  /** Used only by extra sidebar */
  required?: boolean;

  /** Used by vessel comparator */
  comparatorType?: string;

  /**
   * Used by coloring values by Vessel Comparator, possible values "scale" and "best"
   * - "scale" - vessel comparator will create continuous color scale
   * - "best" - vessel comparator will just highlight the best color
   */
  comparison?: 'best' | 'scale';

  comparisonOrder?: SortDirection;

  /**
   * Used for coloring values by Vessel Comparator
   * When multiple vessels are selected, the comparator will create color scales to highlight values.
   * In general the best vessel value will be green and the worst red. But in some cases it does not
   * make sense to compare the same value for all types of vessels. Eg. it does not make sense to compare the
   * depthDrillingMax for jackups and drillships. So if that happens a separate color scale will be created for
   * each rigType inside selected vessels. So that if you happen to compare 4 vessels, to jackups and 2 drillships,
   * the best drillship and the best jackup will both have green color.
   *
   * Most used values are "rigType" and "vesselMainPurpose"
   */
  datasetSplitBy?: string;
  conditionalCss?: ConditionalStyle[];
  checked?: boolean;
  layerFiltering?: ScheduleLayerFiltering;

  /** Used to navigate to page (pageLink) */
  params?: any;
  href?: string;
  require?: string;

  /** Used for external links */
  url?: string;

  descriptionProperty?: string;
  masterFilter?: boolean;
  filteredOut?: boolean;
  hideTags?: boolean;
  scoreDomain?: number[];
  scoreColorScale?: Color[];
  colorRanges?: FieldRangeSpecs[];
  validRange?: Interval;
  activateLabel?: string;
  button?: Button;
  deactivateLabel?: string;
  exportTitle?: string;
  cssStyle?: any;

  min?: any;
  max?: any;

  linkPropTitle?: string;
  filter?: string;
  calc?: string;
  modeSensitive?: boolean;
  /**
   * a field id can be alterate (when using component mode for exemple)
   * in this case we want to preserve the originalId
   */
  originalId?: string;
  /**
   * Indicates whether for this field id dataset contains falsy values
   */
  hasNullValue?: boolean;

  /**
   * Config exceptions
   */
  colId?: string;
}

export interface SubscriptionListTypeConfig {
  fields: SubscriptionOverviewField[];
  title: string;
  addNewAlert?: boolean;
  canDelete?: boolean;
}

export interface SubscriptionListTypeState extends SubscriptionListTypeConfig {
  subscriptions: SubscriptionConfig[];
}

/**
 * In tables/list a field result can have a color, or icon with a tooltip
 * depending on the value range
 */
export interface FieldRangeSpecs {
  range: Array<number | 'auto' | string>;
  color?: Color;
  icon?: PearlIcon;
  tooltip?: string;
  validity?: 'valid' | 'borderline' | 'invalid';
  exportValue?: string;
}

/**
 * Action types
 */
type GenericActionType =
  | 'separator'
  | 'reportingConfigCheck'
  | 'voyageConfigCheck'
  | 'postprocessReportingVoyages'
  | 'afterCreation'
  | 'removeLink'
  | 'showEta';
type EntityActionType =
  | 'editModal'
  | 'deleteModal'
  | 'addModal'
  | 'openModal'
  | 'duplicateEndpointIntoEntityAndOpen'
  | 'editPosition'
  | 'editShape';
type ComponentActionType =
  | 'tableModal'
  | 'chartModal';
type LinkActionType =
  | 'link'
  | 'externalLink'
  | 'pageLink'
  | 'pageLinkList'
  | 'navigate';
type EndpointActionType = 'endpoint';
type DrawioActionType = 'drawioDiagram';
type TabActionType = 'openSelector';
type IntercomActionType = 'openIntercom';
type PhaseActionType = 'downloadPhase';

export type ActionType =
  | GenericActionType
  | EntityActionType
  | ComponentActionType
  | LinkActionType
  | EndpointActionType
  | DrawioActionType
  | TabActionType
  | IntercomActionType
  | PhaseActionType;
/**
 * TODO: Will end up as a `{ [key: string]: string | number | boolean | array }`
 */
export interface ConfigParams {
  [key: string]: string | number | boolean | string[] | number[];
}

/**
 * Ability to define a drop-down menu for quick navigation between items on the same entity page.
 * Define the endpoint to retrieve the items available for navigation.
 * The destination page type and the paramName defining the id of the target page.
 */
export interface QuickNavParameters {
  endpoint: BaseEndpointPattern;
  pageType: string;
  paramName: string;
}

interface BaseAction {
  /** Action type */
  type: ActionType;
  /** Action parameters */
  params?: ConfigParams;
  /** Layer ID **/
  layer?: LayerId;
  /** Name of a field that is required to have data to show the button */
  require?: string;
  /** Data key used to build URL */
  key?: string;
  /** Data keys used to build URL */
  keys?: string[];
  /** Index of the parameter in values[key] if it's an array */
  valueIndex?: number;
  /** Exclude button for given layer IDs */
  excludeForLayers?: string[];
  /** Don't show layer if no filters applied (for 'map:active' only) */
  removeLayerWhenNoFilter?: boolean;
  /** navBackButton is a tag to identify the top left navigation button on a component page  */
  navBackButton?: boolean;
  /** Button should be main displayed or display in an action menu on a component page  */
  standalone?: boolean;
  /** quickNavParameters is a tag to identify the quick nav button navigation on a component page */
  quickNavParameters?: QuickNavParameters;
}

/**
 * Generic actions
 */
export interface GenericAction extends BaseAction {
  type: GenericActionType;
}

interface ModalSpecificConfig {
  modalButtons?: LinkButton[];
}

export interface ChartModalConfig extends ChartComponentSettings, ModalSpecificConfig {}
export interface EntityTableModalConfig extends EntityTableComponentSettings, ModalSpecificConfig {}
/**
 * Actions to open component modals
 */
export interface ComponentAction extends BaseAction {
  type: ComponentActionType;
  /** Modal configuration (for modal actions) */
  modalConfig: ChartModalConfig | EntityTableModalConfig;
}
export function isComponentAction(action: Action): action is ComponentAction {
  return 'modalConfig' in action;
}

/**
 * Actions to open entity modals
 */
export interface EntityAction extends BaseAction {
  type: EntityActionType;
  /** Entity name (Symfony-style table name, eg. 'spinergie_wind:wind_farm_simulation') */
  entityName: string;
  /** Optional entity title (delete confirmation message & dialog title, eg. 'Simulation') */
  entityTitle?: string;
  /** Duplication mask (for `duplicateEndpointIntoEntityAndOpen`) */
  duplication?: object;
  /**
   * This is a placeholder to find out the ID which should be reloaded after the save.
   * Typically it might hold value :locationId to tell the system to reload the location
   * for which id is stored in data['locationId'], even if the button will edit something
   * different (eg. vessel)
   */
  reloadIdField?: string;
  /** Indicates whether the open modal should be stored in the url */
  skipUrlModalStateChange?: boolean;
  /** Reload all layers after change */
  reloadAllLayers?: boolean;
  /** Reload dashboard config after change */
  reloadDashboardConfig?: boolean;
  /** Geometry type (default 'geometry') */
  geometryField?: 'geometry' | 'minimalGeometry';
  /** Prefill with current position */
  includePosition?: boolean;
}
export function isEntityAction(action: Action): action is EntityAction {
  return 'entityName' in action;
}

/**
 * Navigation links (navigate, link & externalLink)
 */
export interface LinkAction extends BaseAction {
  type: LinkActionType;
  /** Button link (internal or external) */
  href: string;
}
export function isLinkAction(action: Action): action is LinkAction {
  return 'href' in action;
}

/**
 * Other specific actions
 */
export interface EndpointAction extends BaseAction {
  type: EndpointActionType;
  /** Endpoint URL (for POST request) */
  endpoint: BaseEndpointPattern;
}
export interface DrawioAction extends BaseAction {
  type: DrawioActionType;
  /** Compare Drawio diagrams */
  isDrawioForCompare?: boolean;
}
export interface TabAction extends BaseAction {
  type: TabActionType;
  /** Tab ID */
  tab: string;
}
export interface IntercomAction extends BaseAction {
  type: IntercomActionType;
  /** Field to be used for intercom message e.g. (there is a button type that allows to open intercom with a message) */
  message: string;
}

export interface PhaseAction extends BaseAction {
  type: PhaseActionType;
}

/**
 * Button extra UI properties
 * Union type of all available actions
 */
export type Action =
  | GenericAction
  | EntityAction
  | ComponentAction
  | LinkAction
  | EndpointAction
  | DrawioAction
  | TabAction
  | IntercomAction
  | PhaseAction;

/**
 * Additional button properties (UX)
 */
interface ButtonProperties {
  /** Button label */
  title?: string;
  /** Don't display title in button (overlay button) */
  contentProjection?: boolean;
  /** Button ID */
  id?: string;
  /** Button position */
  position?: 'left' | 'right';
  /** Button icon (material names) */
  icon?: PearlIcon;
  /** Tooltip text */
  description?: string;
  /** Layer full name, displayed along button title in map */
  layerTitle?: string;
  /** Pearl button type, configuring the different button layouts */
  pearlButtonType?: ButtonType;
}

/**
 * Corresponding Button types & union type
 */
export interface GenericButton extends GenericAction, ButtonProperties {
}
export interface EntityButton extends EntityAction, ButtonProperties {
}
export interface ComponentButton extends ComponentAction, ButtonProperties {
}
export interface LinkButton extends LinkAction, ButtonProperties {
}
export interface EndpointButton extends EndpointAction, ButtonProperties {
}
export interface DrawioButton extends DrawioAction, ButtonProperties {
}
export interface TabButton extends TabAction, ButtonProperties {
}
export interface IntercomButton extends IntercomAction, ButtonProperties {
}
export interface PhaseButton extends PhaseAction, ButtonProperties {
}

/**
 * Union type of allowed button types
 */
export type Button =
  | GenericButton
  | EntityButton
  | ComponentButton
  | LinkButton
  | EndpointButton
  | DrawioButton
  | TabButton
  | IntercomButton
  | PhaseButton;

/**
 * Interface used to create action events
 */
export interface ActionEventParams {
  /** Caller action */
  action: Action;
  /** Mouse event (for button clicks) */
  event?: MouseEvent;
  /** Target data (graph point, schedule bar...) */
  values?: object;
}

export interface ActionEvent {
  /** Referral action */
  action: Action;
  /** Mouse event */
  event?: MouseEvent;
  /** Entity or component data, merged from action params, query params & values */
  data?: object;
  /** Modal component state */
  modalComponentState?: FiltersState;
  /** Reset state after action (for EntityAction) */
  resetState?: boolean;
  /** ID of the item to reload after save (may be different from the edited one), see ConfigParams */
  idOfOriginalItem?: NumOrString;
  /** Is modal creating a new entity (for ActionEvent) */
  isNew?: boolean;
  /** Callback after save */
  afterEntitySave?: (data: AfterSave) => void;
  /** Callback after close */
  afterClose?: () => void;
}

export interface NavigationExtraState {
  reloadData?: boolean;
  resetState?: boolean;
}

export interface TooltipTabTable extends Fieldset {
  // Width of title column. Null mean auto-layout
  titleColWidth: number | null;
  /*
   * For each column and each cell, the computed text width
   * only 2 columns since in a tooltip, there are only title and value cols
   */
  columnTextWidths: [number[], number[]];
}

export enum TooltipShowingOption {
  click,
  hover,
}
export enum TooltipHidingOption {
  focusOut,
  mouseInAndOut,
  afterTimeout,
}

export interface TooltipTitle {
  name: string;
  type?: string;
  format?: string;
}
export interface TooltipSettings {
  url?: string;
  title?: TooltipTitle;
  imageURIField?: string;
  tabFieldsets?: Fieldset[];
  buttons?: Button[];
  text?: string;
  hidingOptions?: TooltipHidingOption[];
  downloadParams?: { [param: string]: string };
  onHide?: () => void;
}

export interface LayerHeaderTooltipSettings {
  titleProp: string;
  subtitle?: string;
  subtitleProp?: string;
  icon?: PearlIcon;
  status?: PearlHeroSectionStatusOptions;
}

export interface LayerTooltipSettings {
  /** URL defined to fetch more data when tooltip is shown */
  url?: string;
  downloadParams?: { [param: string]: string };
  /** Image that represent the entity we try show */
  imageURIField?: string;
  /** KV content, categorized */
  tabFieldsets?: Fieldset[];
  /** Actions proposed on the tooltip */
  buttons?: Button[];
  /** Definition of the header (title, subtitle, icon...) */
  header: LayerHeaderTooltipSettings;
}

export interface ListTooltipSettings {
  items: string[];
  title: string;
}

export interface ChartTooltipSettings extends TooltipSettings {
  series: TooltipSeries[];
  timezone?: DateTimezone;
}

export interface TooltipSeriesBasicInfo {
  points: PlotDatum[];
  xval: any;
  seriesName: string;
}

export interface TooltipSeries {
  type: SeriesTypeValues;
  style?: PlotlyLineStyle;
  splits: SeriesSplit[];
  xValue: string;
  total?: any;
  name?: string;
  title?: string;
  showTotal?: boolean;
  showErrors?: boolean;
  errorMins?: any;
  errorMaxs?: any;
  hideColorCircle?: boolean;
  hideTotal?: boolean;
  // Custom series page link
  xPageUrl?: string;
}

/**
 * FIXME: SeriesSplit, like AdditionalProperty, should extend from FieldSettings
 * This will allow formatting options (fmt, prefix, ...)
 * @see charting-helper.ts
 */
export interface SeriesSplit {
  title: string;
  prop?: string;
  value?: string;
  type?: FieldType;
  format?: string;
  suffix?: string;
  color?: Color;
  rawValue?: any;
  spinergieValue?: any;
  comment?: string;
  // So that tooltip split KEYS are link to pages (e.g. vessel)
  pageUrl?: string;
  // So that tooltip split VALUES are link to pages
  valuePageUrl?: string;
}

export interface Fieldset<Fields = EntityFieldDefinition> {
  /** Optional ID */
  id?: string;
  /** Fieldset title */
  title?: string;
  /** Fieldset description */
  description?: string;
  /** Fields definition */
  fields: Fields[];
  /** Whether the fieldset appears expanded (default: false - the fieldset is folded then) */
  expanded?: boolean;
  /** CSS class @see SP-3780 */
  class?: string;

  /** Contains a link to a page which explain the fieldset, like help page */
  redirection?: Link;

  /** If the fieldset should be populated with an endpoint */
  populateUrl?: string;

  /** @deprecated to be removed once the horizontal sidebar is introduced */
  position?: 'header' | 'sidebar';
}

export type SubscriptionFieldType =
  | 'entity'
  | 'slideToggle'
  | 'vessels_advanced_filters'
  | 'vesselNumber'
  | 'html'
  | 'filterResume'
  | 'string';

export interface SubscriptionOverviewField {
  id: string;
  title: string;
  type: SubscriptionFieldType;
  description?: string;
  css?: object;
  activateLabel?: string;
  deactivateLabel?: string;
}

/** Navigation sections are only used in the sidebar-navigation component (and not in the tab-navigation component) */
export interface NavigationComponentSectionState extends NavigationComponentSectionConfig {
  dashboards: NavigationComponentDashboardState[];
}

export interface NavigationComponentDashboardState extends NavigationComponentDashboardConfig {
  isCurrent?: boolean;
}

export interface Link {
  url: string;
  text: string;
}

export interface TilesLayerOptions {
  /** The image that will be displayed in the menu to change base layer */
  previewImageUrl: string;
  maxZoom: number;
  legendTooltip?: LayerTooltipSettings;
  /** Opacity in which we will render the raster layer. 1 by default. */
  opacity?: number;
  isOneOcean?: boolean;
  /** "{z}/{x}/{y}" by default */
  coordinateScheme?: string;
}

export interface OneOceanTokenResponse {
  accessToken: string;
  /** In seconds */
  tokenDuration: number;
}

export interface OneOceanTokenStored extends OneOceanTokenResponse {
  /** Unix timestamp of storage date */
  storedAt: number;
}

export type LayerType =
  | 'latest' // vessel latest positions
  | 'historical' // vessel historical positions
  | 'geometries' // standard type
  | 'tiles' // tiles layer
  | 'camembert' // camembert layer
  | 'weather'; // Weather layer

export type ChartType =
  | 'boxplot'
  | 'multi'
  | 'polar'
  | 'barpolar'
  | 'waterfall';

export type ChartDisplayMode = 'all' | 'relevant' | 'quantiles';
export type ChartExtraLines = 'none' | 'median' | 'average';

export type HeavyEndpointType = 'heavy' | 'heavy-custom';
export type BaseEndpointType = 'light' | HeavyEndpointType;
export type BaseEndpointPrefix = '/' | 'https://' | '@local<' | 'output_';
export type BaseEndpointPattern = 'output' | `${BaseEndpointPrefix}${string}`;

export interface BaseComponentSettings extends IHaveTimezone {
  /**
   * Component ID FIXME: should be mandatory and replace wrapper ID
   * As for now, always set on wrapper and copied to component in components-page.ts
   */
  id?: string;
  /** Component type */
  type: ComponentType;
  /** Component title */
  title?: string;
  /** Optional description */
  description?: string;
  /** These are the types for filters inside fixed filters */
  filterTypes?: { [fieldName: string]: FilterType };
  /**
   * filter contains url with conditions that determines
   * whether or not we want to display the content of a component
   *
   * ```typescript
   * "filter": "@local<item>vessel:[:vesselId:]?maxConnectionWaveHeightClc
   *            ||maxRegularConnectionWaveHeightClc",
   * ```
   */
  filter?: string | Filter;
  /**
   * If defined, a dropdown will be displayed on the component. A selection will trigger a new endpoint call
   * with the 'comparisonPeriod' argument in the URL.
   */
  comparisonConfig?: IntervalField;
  /**
   * If defined and true, ignore the dashboard's filters when retrieving the component's filters state.
   * Used for instance for some components displayed in modals.
   */
  ignoreDashboardFilters?: boolean;
}

/**
 * Union type of all wrapper configs
 */
export type ComponentSettings =
  | ChartComponentSettings
  | SummaryComponentSettings
  | PictureComponentSettings
  | WidgetComponentSettings
  | EntityTableComponentSettings
  | MapComponentSettings
  | ScheduleComponentSettings
  | InviteComponentSettings
  | KpisComponentSettings
  | PhaseDetailsComponentSettings;

export interface DatabaseOverview {
  entities: string[];
}

/**
 * Allowed features (used at least once in classes or templates)
 *
 * Features list should stay exhaustive for VS Code completion
 * @see https://www.notion.so/spinergie/Feature-flags-harmonize-PP-TS-JSON-PHP-380833f1f55c44adb78477a180a2f8ed?pvs=4
 *
 * @see config.ts: `hasFeature()`
 * @see ConfigManager.php: `hasFeature()`
 * @see config-types.ts: `Dashboard*Settings` & `ProjectConfig` interfaces
 */
export type Feature =
  | 'aoi'
  | 'aoi_edit'
  | 'ahts'
  | 'ais_management'
  | 'analyst'
  | 'anchor_installations'
  | 'autofill_ais_activity'
  | 'cable_laying'
  | 'cable_specs'
  | 'consumption'
  | 'consumption_comparison'
  | 'consumption_model'
  | 'contract_intel'
  | 'ctv'
  | 'custom_equipment'
  | 'custom_equipment_write'
  | 'custom_fixture'
  | 'custom_geometry'
  | 'custom_prospects'
  | 'custom_schedule'
  | 'custom_tender'
  | 'custom_tender_edit'
  | 'cvs'
  | 'download_coordinates'
  | 'dredging'
  | 'drilling_equipment_tooltip'
  | 'equipment_market_share'
  | 'eta'
  | 'explo_well'
  | 'fixture'
  | 'garbage-management'
  | 'internal_use'
  | 'kpi_management'
  | 'ldpl'
  | 'leasing_round'
  | 'light-admin'
  | 'light-map'
  | 'map_edit'
  | 'osv-dpr-admin'
  | 'osv-dpr-autofill'
  | 'osv-dpr-read-all'
  | 'osv-dpr-read'
  | 'osv-dpr-write'
  | 'osv-dpr-sign'
  | 'osv-dpr-distribution-list'
  | 'osv-reporting-download-customer-report'
  | 'over_consumption'
  | 'perenco'
  | 'perenco_congo'
  | 'perenco_gabon'
  | 'pipelay'
  | 'platform-rigs'
  | 'port_basic'
  | 'port_intelligence'
  | 'project-tracking-management'
  | 'public_eta'
  | 'reporting'
  | 'right-summary-edit'
  | 'running_hours'
  | 'sensor_data'
  | 'shareFleet'
  | 'site'
  | 'tinko'
  | 'trips'
  | 'user-admin'
  | 'user-project-management'
  | 'vessel_status'
  | 'wfi'
  | 'wind_filters'
  | 'wind_forecast'
  | 'wind_installation'
  | 'wind_vessel_supply'
  | 'wti'
  | 'frontend_persistent_cache'
  | 'wind_supply_chain'
  | 'cable_supply_chain';

type SingleItemType = 'dashboard' | 'page';
type LinkItemType = 'link';
type SubmenuItemType = 'submenu';

export type MenuItemType = SingleItemType | LinkItemType | SubmenuItemType;

interface BaseMenuItem {
  /** Panel type (default: 'dashboard') see `App.getPanelType()` */
  type: MenuItemType;
  /** Panel path to build URL (eg. /{type}/{value}) */
  value?: string;
  /** Panel title */
  title?: string;
  /** Flag to display after title (like 'NEW') */
  mark?: { text: string };
  /** Icon */
  icon?: PearlIcon;
  /** Background color */
  background?: Color;
  /** Title to be used in the menu */
  menuTitle?: string;
}

export interface SingleMenuItem extends BaseMenuItem {
  /** Panel type */
  type: SingleItemType;
  /** Panel path to build URL */
  value: string;
  /** Panel custom URL if defined (else /{type}/{value}) */
  url?: string;
}

export interface LinkMenuItem extends BaseMenuItem {
  /** Panel type */
  type: LinkItemType;
  /** URL for custom panels */
  ref: string;
}

export interface SubMenuItem extends BaseMenuItem {
  /** Panel type */
  type: SubmenuItemType;
  /** Embedded sub-panels */
  subpanels: MenuItem[];
}

export type MenuItem = SingleMenuItem | LinkMenuItem | SubMenuItem;

export interface OsvProject {
  id: number;
  title: string;
}

/**
 * Basic user specific informations.
 */
export interface UserInfo {
  /** User full name */
  spinergieUser: string;
  /** User email */
  spinergieUserEmail: string;
  /** Whether the user is an admin */
  isAdmin: boolean;
  /** Whether the user is allowed to impersonate  */
  canImpersonate: boolean;
  /** Whether the user is the client application owner */
  isAppOwner: boolean;
  /** User ID */
  spinergieUserId: number;
  /** User group id */
  userGroupId: number | null;
  /** User external ID, to use for user identification in external softwares */
  externalUserId: string;
  /** User client (company) */
  client: string;
  /** Client status can be 1 ('Paying') or 2 ('Trial (downloads forbidden)') */
  clientStatus: number;
  /** URL to redirect to on logout */
  logoutUrl: string;
  /** Whether the user is activated */
  userActivated: boolean;
  /** The URL to API docs. It is project dependent. */
  apiDocsUrl?: string;
  /** The user preferences */
  preferences?: UserPreferences;
  /** The specific user's client (company) config */
  config?: ClientConfig;
  /** Defines whether we are in development environment, based on env variable */
  isDevMode: boolean;
  /** List of projects accessible by user (SFM only) */
  accessibleOsvProjects?: OsvProject[];
  /** List of vessel ids accessible to the user (SFM only) */
  accessibleOsvVesselIds?: number[];
  /** User's job category */
  jobCategory: string | null;
  /** Flag when user offline db is bugged and should be reset  */
  shouldResetOfflineDb?: boolean;
}

export interface ClientConfig {
  disableRUM?: boolean;
}

export interface UserPreferences {
  dpr?: { showDescriptionColumn: boolean };
  dashboards?: { [dashboardId: string]: DashboardUserPreferences };
  searchItems?: IdentityItem[];
  selectedFleets?: number[];
  selectedOsvProjectIds?: number[];
  timezone?: DateTimezone;
  pages?: { [pageId: string]: PageUserPreferences };
}

export interface DashboardUserPreferences {
  layers: Record<LayerId, LayerUserPreferences>;
  components?: ComponentsUserPreferences;
  baseMapLabels?: LayerUserPreferences;
}

export interface PageUserPreferences {
  components?: ComponentsUserPreferences;
  sections?: PageSectionsUserPreferences;
}

export interface PageSectionsUserPreferences {
  [sectionId: string]: SingleSectionUserPreferences;
}

export interface SingleSectionUserPreferences {
  isCollapsed: boolean;
}

export interface LayerUserPreferences {
  visible: boolean;
  filters?: FiltersUserPreferences;
}

export interface FiltersUserPreferences {
  [filterId: string]: FilterUserPreferences;
}

export interface FilterUserPreferences {
  active: boolean;
  values?: unknown[];
}

export interface ComponentsUserPreferences {
  [componentId: string]: ComponentStateOptions;
}

export interface LegendSerializableState {
  layers: { [layerId: string]: string };
}

export interface SearchEngineConfig {
  sortByField: ChartSelect;
  numberResultPerPage: number;
}

export interface SearchEngineSearchParameters {
  after?: string;
  before?: string;
  body_or_title_search?: string;
  url_regexp_pos?: string;
  url_regexp_neg?: string;
  sort_by?: string;
  page_number?: number;
  include_website_id?: boolean;
  exclude_website_id?: boolean;
  tag_id?: number;
  file_type_id?: number;
}

export interface DailyArticleItem {
  id: number;
  url: string;
  title: string;
  fileTypeName: string;
  fileTypeId: number;
  content: string;
  predictions?: any[];
  subscriptions?: any[];
  cluster?: number;
  comment?: string;
  websiteId: number;
  websiteName?: string;
  date: string;
  whiteBackground?: boolean;
  ignored?: boolean;
  chunkStatus?: string;
}

export interface ComparatorData {
  [prop: string]: FieldValue;
}

export interface BaseFilterConfig {
  /**
   * The property holding the value to be filtered. Can have an alternative behavior using a special syntax:
   * - the '|' character denotes a COALESCE-like behavior, i.e taking the first non-undefined item
   * This is useful for instance if multiple datasets link to the same end property but from different ref datasets
   * (e.g 'document.vessel.title' vs 'cycle.vessel.title'), or if we want to fallback to a property if the accessed
   * object does not contain it (for instance 'customTitle|vessel.title').
   */
  propValue?: string;
  /** The property holding the value's title */
  propTitle?: string;
  /** The property holding the left boundary value for interval intersection. */
  leftPropValue?: string;
  /** The property holding the right boundary value for interval intersection. */
  rightPropValue?: string;
  /** The type of the filter. */
  filterType?: FilterType;
}

export interface FilterConfig extends BaseFilterConfig, DistanceToEntityFilterConfig {
  id?: string;
  title?: string;
  /** Used for heavy analytics */
  prop?: string;
  timeMultiPeriod?: 'weekday';
  type?: FieldType;
  /**
   * Equivalent of `allowNulls` in advanced filters system.
   * Whether `null` and `undefined` values are considered to match the filter
   */
  allowNoProperty?: boolean;
  /**
   * Some filters are used to state information related to inputs
   * (e.g. vessel selector parameters). However, these "filters" should not be applied
   * directly to the data and should be ignored when filtering in back or in front
   */
  notApplied?: boolean;
}

export type LayerMode = 'shapeHidden';

export interface ModeFilterApplied {
  values: LayerMode[];
  filterType: null;
}

/**
 * @see https://github.com/microsoft/TypeScript/issues/340#issuecomment-184964440
 * Using this notation enables FilterApplied class to use attribute from FilterApplied
 * interface and its ancestors, without the need to redeclare attribute, as classic composition
 * would require
 */
export interface FilterApplied extends FilterConfig {
  active?: boolean;
  autozoom?: any;
  autoZoomAfterLatest?: boolean;
  filterTitle?: string;
  intelligibleValues?: string;
  masterFilter?: boolean;
  timeSync?: boolean;
  values?: any[];
  valueMap?: any;
  valuesTitle?: any[];
  selectedEra?: Era;
  /** If true, the applied filter comes from the special "vessel" field in the map dashboard component sidebar*/
  isVesselFilter?: boolean;
}
export class FilterApplied {
  constructor(filter: Partial<FilterApplied>) {
    this.active = filter?.active;
    this.allowNoProperty = filter?.allowNoProperty;
    this.autozoom = filter?.autozoom;
    this.autoZoomAfterLatest = filter?.autoZoomAfterLatest;
    this.filterTitle = filter?.filterTitle;
    this.filterType = filter?.filterType;
    this.id = filter?.id;
    this.intelligibleValues = filter?.intelligibleValues;
    this.leftPropValue = filter?.leftPropValue;
    this.masterFilter = filter?.masterFilter;
    this.notApplied = filter?.notApplied;
    this.prop = filter?.prop;
    this.propTitle = filter?.propTitle;
    this.propValue = filter?.propValue ?? filter.id;
    this.latProp = filter?.latProp;
    this.lonProp = filter?.lonProp;
    this.rightPropValue = filter?.rightPropValue;
    this.timeMultiPeriod = filter?.timeMultiPeriod;
    this.timeSync = filter?.timeSync;
    this.type = filter?.type;
    this.values = filter?.values;
    this.valueMap = filter?.valueMap;
    this.valuesTitle = filter?.valuesTitle;
    this.selectedEra = filter?.selectedEra;
    this.entityConfig = filter?.entityConfig;
    this.distanceConfig = filter?.distanceConfig;
  }
}

export interface LayerFilter {
  vessel?: FilterApplied;
  mode?: ModeFilterApplied;
  [id: string]: FilterApplied;
}

export interface ContracLayerFilter extends LayerFilter {
  rig: FilterApplied;
}

export interface ComponentState {
  active?: LayerId[];
}

export interface ComparatorState {
  [entityFilterId: string]: number[];
}

export interface ModalState {
  entityName: [string];
  entityId?: [string];
  reloadLayer?: [LayerId];
  readonly?: boolean;
}

export interface DashboardState {
  rigs?: LayerFilter;
  hmt?: LayerFilter;
  map?: MapState;
  modal?: ModalState;
  schedule?: ScheduleState;
  legend?: LegendSerializableState;
  comparator?: ComparatorState;
  tableSchedule?: TableScheduleState;
  'common-filters'?: FiltersState;
}

export interface QualityCheckState extends DashboardState {
  display?: {
    tabIndex?: string[];
  };
}

export interface ScheduleState extends ComponentState {
  contracts: ContracLayerFilter;
  period: ResolvedInterval;
  // url serializations stores everything as arrays :(
  groupby: [string];
  splitby: [string];
  tabFilterSelected: [string];
}

export interface TableScheduleState extends ComponentState {
  // url serializations stores everything as arrays :(
  showDescriptionColumn: boolean[];
  filteredColumns?: string[];
}

export interface MapState extends ComponentState {
  /** Optional position [lat, lng, zoom] as strings (fixed numbers) */
  pos?: string[];
  /** Option map types */
  type?: string[];
  /** Show base map labels */
  baseMapsLabelsShown?: boolean;
  /** Show Spinergie labels */
  spinergieLabelsShown?: boolean;
  /** Currently selected weather layer */
  weatherLayerShown?: string;
}

export class IntervalFilterApplied extends FilterApplied {
  changeEnded: boolean;

  constructor(filter: Partial<IntervalFilterApplied>) {
    super(filter);
    this.changeEnded = filter.changeEnded;
  }
}

export interface FiltersChanged {
  values: LayerFilter;
  latest?: FilterApplied | IntervalFilterApplied;
}

export interface FiltersOnLayerChanged {
  layerId: LayerId;
  filters: FiltersChanged;
}

export type ContractStatus =
  | 'Ready stacked'
  | 'Cold stacked'
  | 'Converted / Retired'
  | 'Cancelled'
  | 'Under construction'
  | 'Contracted'
  | 'Contracted - accommodation'
  | 'Contract option'
  | 'Contracted - standby'
  | 'Under modif/maintenance'
  | 'Transit / Contract Preparation';

export type Region =
  | 'North Sea'
  | 'West Africa'
  | 'East Africa'
  | 'Mediterranean'
  | 'Middle East'
  | 'Black Sea'
  | 'Central Asia'
  | 'South Asia'
  | 'South East Asia'
  | 'Australasia'
  | 'East Asia'
  | 'North America (Inc. Mexico)'
  | 'South America';

export interface AvailabilityFilter {
  start: Dayjs | undefined;
  end: Dayjs | undefined;
  excludeConstruction: boolean;
  excludeColdStacked: boolean;
  excludeMaintenance: boolean;
  regions: number[];
  countries: string[];
}

export interface MultiOption<T> extends OptionValue {
  title: string;
  alreadyChosen?: boolean;
  /** data-point linked to the option for multi component */
  data?: T;
}

export interface LayerToggledEvent {
  layerId: LayerId;
  visible: boolean;
}

export interface ColumnOption {
  /** Method to apply to a column */
  col?: (col) => void;
  /** Increased precision? */
  increasedPrecision?: boolean;
  /** Is column containing html richText */
  htmlRichText?: boolean;
}

export type ExportSource = ComponentType | CustomDashboardType;

export interface ExportTrackingInfo {
  exportSource: ExportSource;
  componentTitle?: string;
  additionalData?: any;
}

export interface ExportData {
  data: any[];
  header?: any[];
  columnOpts?: ColumnOption[];
  filename?: any;
  trackingInfo?: ExportTrackingInfo;
}

export interface PreparedExportData {
  /** The data is prepared as a table to be injected in the sheets. */
  data: NumOrString[][];
  fileName: string;
  header: string[];
  columnOpts: ColumnOption[];
  trackingInfo: ExportTrackingInfo;
}

export interface ScheduleExportConfig {
  endpoint?: BaseEndpointPattern;
  columns?: Fieldset[];
}

export interface ExportConfig {
  columns?: any[];
  tooltip?: TooltipSettings;
  layer?: Fieldset[];
  definition?: EntityDefinition;
  layerId?: LayerId;
  filename?: string;
}

export interface CompleteExport {
  exportData: ExportData;
  config: ExportConfig;
  /** flag served for schedule export that needs special treatment */
  isSpecific?: boolean;
}

export interface PngExport {
  svgId: string;
  svg: Selection<SVGSVGElement, unknown, SelectionBaseType, unknown>;
  contentId: string;
  fileName: string;
  width: number;
  height: number;
  scale: number;
  title?: string;
  afterExport: () => void;
  trackingInfo: ExportTrackingInfo;
}

export type SortDirection = 'asc' | 'desc';

export interface SomeEntity {
  id?: NumOrString;
  editable?: boolean;
  deleteMode?: boolean;
  duplicate?: boolean;
  exactMatch?: boolean;
  order?: number;
  __constructedId?: number;
}

export interface DynamicInfoForPageFromEndpoint {
  pageTitle?: string;
  pageSubtitle?: string;
  title: string;
  /** Dyanmic icon that overrides the top level static icon */
  overrideIcon?: PearlIcon;
  breadcrumbs?: readonly BreadCrumbResponse[];
  /** Object of properties to add to URL upon receiving the response. */
  addUrlParams?: { [urlParamName: string]: string };
  status?: string;
  /** Color mapping for the header status, passed on from the config */
  statusColors?: ColorDefinition[];
  headerDescription?: string;
  extraParameters?: PageParameter[];
}

export interface DynamicInfoForPage extends DynamicInfoForPageFromEndpoint {
  fullTitle: string;
}

export interface SomeEntityChainable extends SomeEntity, RawDataPoint {}

export interface EntityOrder {
  id: NumOrString;
  order: number;
}

export interface OptionValue {
  id?: any;
  title: string;
  value?: any;
  order?: any;
  description?: string;
  typeTitle?: string;
  /**
   * garbage category specific fields
   */
  defaultDestinationTypeId?: number;
  defaultUnitId?: number;
  childFieldId?: string;
}

export interface FieldValues {
  values: OptionValue[];
}

export type FieldsetUpdate =
  & {
    [field: string]: FieldValues;
  }
  & /*
   * Intersection is needed there to avoid "Property '__total' of type 'number'
   * is not assignable to 'string' index type 'FieldValues' (same for '__populateSidebarFromRefDatasets')
   */ {
    __populateSidebarFromRefDatasets: string[];
    __total: number;
  };

export type EntityTabType = 'standard' | 'freestyle';

/**
 * Entity tab definition
 */
export interface EntityTab extends Fieldset {
  /** Tab type ('standard' or 'freestyle') */
  type: EntityTabType;
  /** Tab ID */
  tabId: string;
  /** List of regular field IDs */
  fieldIds: any[];
  /** Fields without layout (as lines of fields) */
  fieldsWithLayout?: EntityFieldDefinition[][];
  /** Ordered fields */
  orderedFields?: EntityFieldDefinition[];
  /** All fields valid? */
  valid?: boolean;
}

export type CollectionType = 'ManyToMany' | 'OneToMany';

export interface CalculatedField {
  calc?: string;
  /**
   * TypeScript Exception message when there is an error in some field that contains code evaluation
   */
  configError?: string;
}

/**
 * Field state
 * Holds state-related properties and flags
 */
export interface FieldState {
  filteredValues?: OptionValue[];
  valid?: boolean;
}

/**
 * Used in entity table category header above columns
 */
export interface EntityTableCategory {
  id: string;
  title: string;
}

/**
 * Entity field definition, which is for the moment an extension of the field settings defined in configuration file
 * and some state-relative values that should be kept separated
 *
 * TODO:
 * - move into FieldDefinitionConfig all fields mapped to configuration files (req, nullItem...)
 * - create FieldState as an extension of FieldDefinitionConfig that will copy initial values of configuration
 *   and add special flags & options state-related (valid, filteredValues...)
 *
 * As for now, only filteredValues & valid flag are deported to a property of EntityFieldDefinition
 */
export interface EntityFieldDefinition extends FieldSettings, CalculatedField {
  /** If the entity value is null, display this string instead of nothing */
  nullItem?: string;
  req?: boolean;
  /** Map a field to a category inside EntityTableComponentSettings.categories */
  category?: string;
  items?: any[];
  /** If true, do not order by value on search */
  resultAlreadySorted?: boolean;
  icon?: PearlIcon;
  order?: string;
  /** 'desc' or 'asc' for the order in comboBox */
  comboBoxOrder?: SortDirection;
  /**
   * Field that should be used to order. If not provided order by title.
   * There are only those two possible value because those are the fields of OptionValue
   */
  orderField?: 'title' | 'id'; // field use in comboBox
  /** Field use to override the default order of the class in entity-table */
  collectionOrderFields?: string[];
  /** Direction used to override the default order of the class in entity-table */
  collectionOrders?: SortDirection[];
  /** Runtime value: should appear */
  row?: boolean;
  /** Runtime value */
  values?: OptionValue[];
  class?: string;
  editable?: boolean;
  readable?: boolean;
  url?: string;
  link?: boolean;
  /** `fmt` is the old format on all entities, supported by the old back */
  fmt?: string;
  /** `format` is the one defined in dashboard configs */
  format?: string;
  /** Specific for field of type duration when the inputted isn't milliseconds */
  inputUnit?: DurationUnit;
  /** This is the name of the field that holds the id case this column is a link  to other entity */
  idField?: string;
  collectionType?: CollectionType;
  /** on duplicate do we duplicate this collection too. true by default for manyToMany and false for OneToMany */
  duplicateChildren?: boolean;
  inlineAdd?: boolean;
  addNew?: boolean;
  addExisting?: boolean;
  inlineEdit?: boolean;
  inlineDuplicate?: boolean;
  removeLink?: boolean;
  inlineDelete?: boolean;
  mappedBy?: string;
  prefix?: string;
  /** for ManyToMany or OneToMany fields we may limit the columns that should be visible inside the nested table */
  columns?: string[];
  sort?: 'ascending' | 'descending';
  /** for newer override system - the field, specifies which field it tries to override */
  overrides?: string;

  displayStyle?: 'override' | 'intel';

  /** for complex fields we keep the information so that we can load values directly in the front */
  ref?: string;
  field?: string;

  /** In case when a field represents a button, the parameters passed to the button */
  params?: any;

  /**
   * In case when the field can have additional button next to it inside double-column
   * component, this is the label of the button
   */
  buttonLabel?: string;
  showMoreText?: string;
  showMoreVisible?: boolean;
  latestSearchValue?: string;
  customDataField?: boolean;
  /** For entity-table */
  hideHeader?: boolean;
  defaultAdd?: boolean;
  /** Fields can open a selector, e.g. competitive vessels on custom tender data entry. */
  selector?: SelectorConfig;
  /**
   * TODO: we keep any here for schema generation, otherwise PreparedGeomItem is embedded in the schema, which in turn
   * embedded type declarations outside of our own, which breaks on the CI since the python generation has no clue of
   * outside types
   * Fixed by SP-3778 https://github.com/spinergie/spinapp/pull/6661
   */
  advancedFiltersData?: readonly any[];
  tabIndex?: number;
  formType?: string;
  unopenable?: boolean;
  formTable?: boolean;

  duplicate?: boolean;
  durationUnit?: SpinTimeUnit;

  descriptionProperty?: string;

  /** Link field */
  href?: string;
  openInNewTab?: boolean;
  prefill?: { [fielId: string]: string };
  hideHint?: boolean;
  footer?: boolean;
  footerLabel?: string;
  entityName?: string;
  entityId?: string;
  coalesce?: string;
  /** Property that will be compared to perform sorting */
  sortProperty?: string;
  sortPropertyType?:
    | 'number' // will cast value to a number when comparing
    | 'datetime'
    | 'date'; // assume that value is a numerical value
  /** If true, we truncate the field when displaying it to the `truncateLength` number of character */
  truncate?: boolean;
  truncateLength?: number;
  groups?: string[];
  scale?: number;
  line?: number;
  linePos?: number;
  validTemplate?: 'url' | 'email' | 'imo' | 'mmsi' | 'camelcase' | 'emailDomain' | 'numberFormat';
  tooltip?: string;
  checkBoxTitle?: string;

  /** specific coherency checks on a field list of {check :"code evaluation", message: "messsage if check false"} */
  coherencyChecks?: CoherencyCheck[];

  /** For Time fields: datetime of the report start hour in iso string. Used to compute the suffix ('d+1')  */
  timeDate?: string;
  /** For Time fields especially for Activity End fields to be able to set the End time to end of the report duration */
  isEndTime?: boolean;
  timezone?: string;
  syncable?: SyncableConfig;

  /** Field state (see comment above), used for entities */
  fieldState?: FieldState;

  /** configuration of file uploader field */
  loadedFiles?: FileObject[];
  uploadAreaHidden?: boolean;
  maxNumberOfFiles?: number;
  compactFileUploader?: boolean;
  parentFilePath?: string;
  allowedFileExtensions?: string[];
  /** Maximum allowed file size in kilobytes */
  maxFileSize?: number;

  /** To display False values as "No" (field of type checkbox) and 0 (field of type number) */
  showFalseOrZeroValue?: boolean;

  /** Embeded component config (chart, tables...) */
  component?: ComponentSettings;

  /**
   * Properties of IntervalField, incorrectly inheriting from FieldSettings
   * TODO: fix inheritance tree
   * @see SP-3778
   */
  startLabel?: string;
  endLabel?: string;
  syncComponentIntervals?: boolean;
  eras?: Era[];
  timeGranularity?: DatePrecision;
  empty?: boolean;

  /** Set true to disable light edit on a field, regardless if the endpoint returns mapping information */
  disableLightEdit?: boolean;
  hideNumberButtons?: boolean;
}

export interface IntegratedTableDefinition extends EntityFieldDefinition {
  definition?: EntityDefinition;
  rows?: RowDefinition[];
  deletedRows?: RowDefinition[];
}

export interface RowDefinition {
  fields: EntityFieldDefinition[];
  entityAccessor: EntityDataAccessor;
}

type CodeDict = { [name: string]: string };
type EntityGeneralGroupFunctionDict = { [name: string]: (accessor: EntityDataAccessor) => boolean };

export class EntityGeneralInfo {
  addNew: boolean;
  delete: boolean;
  edit: boolean;
  duplicate: boolean;
  inlineEdit: boolean;
  inlineDelete: boolean;
  inlineAdd: boolean;
  inlineDuplicate: boolean;
  exportCsv: boolean;

  groupVisibility?: EntityGeneralGroupFunctionDict;
  groupDisabled?: EntityGeneralGroupFunctionDict;
  canChooseColumns?: boolean;

  constructor(params: EntityGeneralInfoParam) {
    const { groupVisibility, groupDisabled, ...remainder } = params;
    Object.assign(this, remainder);
    this.groupVisibility = EntityGeneralInfo.initDictFunction(groupVisibility);
    this.groupDisabled = EntityGeneralInfo.initDictFunction(groupDisabled);
  }

  static initDictFunction(codeDict: CodeDict): EntityGeneralGroupFunctionDict {
    if (!codeDict || Object.keys(codeDict).length === 0) {
      return {};
    }

    return Object.entries(codeDict).reduce((parsedDict: EntityGeneralGroupFunctionDict, [group, code]) => {
      parsedDict[group] = Function('accessor', `return ${code}`) as (accessor: EntityDataAccessor) => boolean;
      return parsedDict;
    }, {});
  }
}

export type EntityGeneralInfoParam = Modify<EntityGeneralInfo, {
  groupVisibility?: CodeDict;
  groupDisabled?: CodeDict;
}>;

export interface CoherencyCheck {
  check: string;
  message: string;
  checkResult?: boolean;
  function?: (value: SomeEntity) => boolean | undefined;
}

type EntityDefinitionFunctionDict = { [name: string]: (value: SomeEntity) => boolean };

export class FieldsDefinition {
  fields: EntityFieldDefinition[];
  coherencyChecks: { [fieldId: string]: CoherencyCheck[] } | null;

  constructor(remainder) {
    Object.assign(this, remainder);
  }

  static initDictFunction(codeDict: CodeDict): EntityDefinitionFunctionDict {
    if (!codeDict || Object.keys(codeDict).length === 0) {
      return {};
    }

    return Object.entries(codeDict).reduce((parsedDict: EntityDefinitionFunctionDict, [group, code]) => {
      parsedDict[group] = Function('value', code) as (value: SomeEntity) => boolean;
      return parsedDict;
    }, {});
  }
}

/**
 * Classes implementing this interface can be checked for entity rights.
 */
export interface EntityRightsCheckable {
  userEntityRights: string[];
}

export class EntityDefinition extends FieldsDefinition implements EntityRightsCheckable {
  header: string;
  emptyEntity: SomeEntity;
  class: string;
  generalInfo: EntityGeneralInfo | null;
  idField: string;
  sortFields: { [fieldId: string]: SortDirection } | null;
  titleString: string;
  userEntityRights: string[];
  calc: EntityDefinitionFunctionDict;
  tabs?: EntityTab[];
  freeStyleFields?: EntityFieldDefinition[];
  freeStyleTabs?: EntityTab[];
  validityDateAttributes?: { start: string; end: string };
  deleteMessage?: string;

  constructor(params: EntityDefinitionParam) {
    const { calc, generalInfo, ...remainder } = params;
    super(remainder);
    this.calc = EntityDefinition.initDictFunction(calc);
    this.generalInfo = new EntityGeneralInfo(generalInfo);
  }
}
export type EntityDefinitionParam = Modify<EntityDefinition, {
  calc: CodeDict;
  generalInfo: EntityGeneralInfoParam;
}>;

export interface EntityUpdateDto {
  success: boolean;
  message: string;
  id?: NumOrString;
  entity?: SomeEntity;
  error?: string;
  /** For dpr Config entities, it is possible to save a config with some coherency errors */
  partialSuccess?: boolean;
}

/** Dto For Multiple Entities Update */
export interface EntitiesUpdateDto {
  success: boolean;
  message: string;
  ids?: string[] | number[];
  entities?: SomeEntity[];
  error?: string;
}

export interface LinkedTableQuery {
  parentEntity: string;
  parentEntityId?: number;
  linkedEntity: string;
  linkedCollection: string;
  forOptions: boolean;
  idsSearched?: number[];
}

export interface EntityDeletedDto {
  success: boolean;
  message: string;
}

export class DatabaseEntity implements EntityRightsCheckable {
  title: string;
  class: string;
  description: string;
  descriptionLong: string;
  img: string;
  /**
   * Database icon comes from static attribute in php entity
   *
   * ```php
   * public static string $icon = '<icon_name>';
   * ```
   */
  icon: PearlIcon;
  userEntityRights: string[];
}

export interface LinkEntitiesQuery {
  parentEntity: string;
  parentEntityId: number;
  linkedEntity: string;
  linkedEntityId?: NumOrString;
  linkedCollection: string;
}

export interface LinkEntitiesResponse {
  success: boolean;
}

export type SnackbarStatus = 'info' | 'warn' | 'success' | 'error';

export enum UpdateEventType {
  InlineUpdate,
  InlineNew,
  InlineDelete,
  InlineDuplicate,
  New,
  Update,
  Delete,
  LightUpdate,
}

export type LinkType = 'OneToOne' | 'OneToMany' | 'ManyToMany';

export class LinkBy {
  linkType: LinkType;
  parentWillSave: boolean;
  fieldName?: string;
  id?: NumOrString;
  linkQuery?: LinkEntitiesQuery;
}

export interface AfterSave {
  entityName: string;
  eventType: UpdateEventType;
  entity: SomeEntity;
  layerId: LayerId;
  /**
   * Specific id to be reloaded after we save the entity. Handles a situation when
   * a button (or other action) opens an entity which is not the same as the one
   * that should be reloaded by the dashboard once we finish. (eg edit vessel but reload location)
   * This value will come from idOfOriginalItem specified on button parameters
   */
  idToReload: NumOrString;
  modifiedEntityId: NumOrString;
  reloadAllLayers?: boolean;
  reloadDashboardConfig?: boolean;
  /** Only set in the case of updating an entity present in ref data */
  reloadedLocalEntity?: RefDatasetItem<number>;
}

export class EntityInformation {
  entityName: string;
  entity: SomeEntity;
  editMode: boolean;
  linkBy?: LinkBy;
  idField?: string;
  /** Stores the id that should be used to reload any layer/component */
  idToReload?: NumOrString;
  closeAfterSave?: boolean;
  creation: boolean;
  layerId?: LayerId;
  afterSaveAction?: (data: AfterSave) => void;
  afterCloseAction?: () => void;
  prefill?: any;
  reloadAllLayers?: boolean;
  reloadDashboardConfig?: boolean;
  isNew?: boolean;
}

export interface FieldValidityResult {
  valid: boolean;
  msg?: string;
}

export interface ValidityDateEntity {
  validityDateStart?: number;
  validityDateEnd?: number;
}

export interface ProjectEntity {
  /** null or undefined means applicable to all projects */
  projectIds?: number[] | null;
}

export type SummaryType = 'endpoint' | 'entity';

export type CustomDashboardType =
  | 'map-dashboard'
  | 'comparator'
  | 'qc'
  | 'search-specs'
  | 'user-admin'
  | 'dpr-vessels'
  | 'advanced-filters'
  | 'entity-table'
  | 'right-summary';

export type ComponentType =
  | 'map'
  | 'chart'
  | 'schedule'
  | 'table'
  | 'image'
  | 'summary'
  | 'widget'
  | 'kpis'
  | 'invite'
  | 'dpr-selected-report'
  | 'entity-linked-collection'
  | 'phase-details';

/**
 * Injection parameter that ends up stringified and injected in URL for data-loading
 * or processed by the local-loader
 */
export interface ComponentParameter {
  name: string;
  value: number | string | boolean | number[] | string[];
  shouldBeRemovedFromUrl?: boolean;
}

/**
 * Parameter sur as vesselId, stored as queryParam in the URL
 */
export interface PageParameter extends ComponentParameter {
  name: string;
  value: string;
}

/**
 * DynamicConfigParameters contains the parameters required for a @dynamicConfig endpoint call.
 *
 * In case of @dynamicConfig for chart tabs, we pass the filters using componentFilters.
 */
export interface DynamicConfigParameters {
  // Filters to apply to the component. Required for chart tabs dynamicConfig
  componentFilters?: {
    // Contains component filtersState. Required for chart tabs dynamicConfig
    filtersState: FiltersState;
    // Used by removeDuplicatedFilter() to remove unrelevant filtersState
    filtersConfig: FiltersApplied;
  };
  // Path to the component config. Required for chart tabs dynamicConfig
  configFiltersPath?: string;
  componentParameters: ComponentParameter[];
}

export interface EntitySummary {
  entity: SomeEntity;
  definition: EntityDefinition;
}

export type TableType = 'entity' | 'query';

export interface SectionCode {
  /** Key of the section. Used to save and load */
  key: string;
  /** Section title */
  title: string;
  /** Id of the section */
  id: string;
  /** Type of the section */
  type: FilterType;
  /** If filling this is section required */
  required?: boolean;
  /** Details of the `SectionCode` */
  details?: string;
  /** Description for multi component */
  description?: string;
  /** Field style for multi component */
  style?: MultiFieldStyle;

  /*
   * The 4 next parameters where existant in the object from the database
   * but where unset in SubscriptionController.php
   * If someone want to use the parameter {param} they first have to edit the .php file
   * by deleting the line looking like 'unset($value['{param}']);'
   */

  /*
   * class?: string // for entities based options. ex: spinergie_osv.aoi.
   * null_value?: string // for entities based options. optional. If not null, name given to the null option.
   * prefix?: string // for entities based options. optional. To add a prefix to the options from the FB
   * suffix?: string // for entities based options. optional. To add a prefix to the options from the FB
   */

  /*
   * different value that can be taken for choice or multi based option
   * OptionValue[] for a choice and MultiOption<OptionValue>[] for a multi
   */
  values: OptionValue[] | MultiOption<OptionValue>[];
  /** Optional, minimum value for datetime and number */
  min?: number | Dayjs;
  /** Optional, maximum value for datetime and number */
  max?: number | Dayjs;
  /** Optional, default value for datetime, number & txt */
  default?: number | Dayjs | string;
  vesselType?: string;
  vesselNumber?: number;
  numberFilterApplied?: number;
  vesselSelectionMode?: 'vesselFilters' | 'vesselFleets';
  customRegions?: boolean;
  filterType?: FilterType;
}

export interface HasDisplayOrder {
  displayOrder: number;
}

export interface SubscriptionConfig extends HasDisplayOrder {
  id: number;
  code: string;
  subscriptionId: number;
  title: string;
  isSubscribed: boolean;
  typeCode: string;
  description?: string;
  comment?: string;
  userConfigId?: number;
  frequency?: string;
  sections: SectionCode[];
  userConfig: any;
  multipleSubscriptions?: boolean;
  vesselList?: any[];
  deleteMode?: boolean;
  /** the reports of this subscription can be viewed in resource dashboard */
  viewInResourceDashboard?: boolean;
  exampleLink?: string;
  subscriptionHidden?: boolean;
  subscriptionAllowed?: boolean;
}

export interface SubscriptionConfigRaw extends SubscriptionConfig {
  allUserConfigs: any[];
}

export interface IdentityItem extends OptionValue {
  optionDescription?: string;
  coords?: Coords;
  /** Subtitle is the other string used for the search but it's not the title */
  subtitle?: string;
  /** Alias used by clients to find managers or windfarms under different names */
  alias?: string;
  /** Type of the result (vessel, project, etc) used in the URL composition when navigating to the result */
  type?: string;
  /** Label of the type */
  typeTitle?: string;
  /**
   * name of the field that holds the id, the composed url will have form
   * `/page/{type}?{idField}={id}`
   */
  idField?: string;
  icon?: PearlIcon;
  routerLink?: string;

  /** Properties used for search */
  historyIndex?: number;
  /** Is set to true only if the element is an exact match, which takes higher priority than the history index */
  isExactMatch?: boolean;
  cleanTitle?: string;
  cleanSubtitle?: string;
  cleanTypeTitle?: string;
  /** Mainly for vessels: indicates if the record matches the latest vessel feature. This is used to order results */
  latest?: number;
  imo?: number;
  mmsi?: number;
  /** Item display order */
  priority?: number;
  /** Vessel flag */
  isOutOfScope?: boolean;

  /** Properties used for filtering */
  liquidTypeId?: number; // will be populated only for tank type field

  typeOrder?: number; // used to order items that have the same type during user search
  /*
   * This is used to display more information about the matched result. If we match a record using the IMO, MMSI or
   * alias, we'll store the matched field and value in this string and display it in the search result option
   */
  additionalSearchInfo?: string;
}

export interface FitGeometryBoundsInfo {
  data: any;
  zoom?: number;
  lock?: boolean;
}

export interface GeometryInfoForFit {
  geometry?: Geometry;
  center?: Coords;
}

export interface UpdateMapInfo {
  data: DeepReadonly<PreparedTraceData>[];
  layerId: LayerId;
}

export interface SwitchLayerModeInfo {
  layerId: LayerId;
  switchMode: LayerDrawingMode;
  vesselIds?: number[];
}

export interface LayerHiddenShapeInfo {
  layerId: LayerId;
  shapeHidden: boolean;
}

export const KEY_CODES = {
  ESCAPE: 27,
  SPACE: 32,
};
export interface FeedbackAnalyst {
  feedbackParameters: any;
  feedbackType: AnalystFeedbackType;
}

export type AnalystFeedbackType = 'feedback' | 'ignore_chunk';

export interface PredictionFeedback {
  classifierId: number;
  classifierName: string;
  prediction: boolean;
  feedback: boolean;
}

export interface Article {
  url: string;
  title: string;
  date: string;
  fileTypeName: string;
  content: string;
}

export interface DailyArticle extends Article {
  id: number;
  documentId: number;
  predictions: PredictionFeedback[];
  subscriptions: string[];
  whiteBackground: boolean;
  cluster: number;
  ignorable: boolean;
  ignored: boolean;
  chunkStatus: string;
}

export interface VesselIntervalChanges {
  layerId: LayerId;
  intervalFilter: FilterApplied;
}

export interface PlayBackSpeed {
  label: string;
  coef: number;
}

export interface PlaybackMode {
  intervalField: IntervalField;
  dateFormat?: string;
  defaultSpeed?: string;
}

export interface ChartLegendOptions {
  width: number;
  nbItemPerLine: number;
  fontSize: number;
  minLegendItemSize?: number;
  dashLegendPercentage?: number;
  layerTitlePercentage?: number;
  currentX?: number;
  currentY?: number;
  previousLegendY?: number;
  commonLegendTitle?: string;
}

/**
 * Use for vessel layer map to draw lines between vessels positions or keep only point of
 * vessels positions in historical mode
 */
export type HistoricalModeStyle = 'lineMode' | 'pointMode';

export interface AisActivity {
  dateStart: number;
  dateEnd: number;
  activityStatusId: number;
  suggestedActivityTypeId?: number;
  suggestedActivityTypeProba?: number;
}

export interface DetailedActivity extends AisActivity {
  activityStatus: string;
}

export interface ConditionedMessage {
  prop: string;
  value: any;
  message: string;
}

/*
 * Allowed timezones
 * local:   Local timezone
 * utc|UTC: UTC
 * inherit: Read from parent service
 * none:    Not applicable (heavy charts, components without dates)
 */
export type DateTimezone = 'local' | 'utc' | 'dprDay' | 'inherit' | 'none' | string;

/**
 * Filters state & config
 */
export type FiltersState = { [id: string]: any[] };
export type FiltersStatePeriod = { [id: string]: ResolvedInterval | any[] };
export type FiltersApplied = { [id: string]: FilterApplied };

export interface GraphOrderBy {
  /** Single order/value FIXME: to be converted to single-value arrays in orders/values */
  order?: SortDirection;
  value?: string;
  /** Multiple orders/values */
  values?: string[];
  orders?: SortDirection[];
  /** Fixed values order */
  fixedOrder?: string[];
  /** Group order mode */
  mode?: string;
  /** @deprecated Read but never set (neither in TS nor in JSON) */
  firstStartDate?: boolean;
}

export interface ChartSelectOptions {
  /** Analysis id - TODO: make mandatory once analyses are defined on all charts */
  analysis?: string;
  /** Split by */
  splitby?: string;
  /** Group by */
  groupby?: string;
  /**
   * Metric - Operation to be performed on the dataset. Basic syntax: `operation:column`.
   * See `ChartingHelpers.metricColumn` for more info
   */
  metric?: string;
  size?: string;
  period?: ResolvedInterval;
  tabFilterSelected?: string;
  showTail?: boolean;
  displayMode?: ChartDisplayMode;
  /** 'Show all' tooltip and details (bars & lines) */
  aggregateTooltip?: string;
  aggregateDetails?: string;
}

export interface DynamicGroupByState {
  groupByManuallySelected: boolean; // Last groupby option was selected by the user
  groupByMinSelectable: number; // Option index from which the user can select
  serverSide: boolean; // Whether or not the component will fetch data from server dynamically
}

export interface ComponentStateOptions extends ChartSelectOptions {
  filteredColumns?: string[];
  excludedEntities?: number[];
  switched?: boolean;
  showDescriptionColumn?: boolean;
  layers?: { [layerId: string]: { visible: boolean } };
  comparisonPeriod?: ResolvedInterval;
}

export class ErrPromise<TSuccess, TError = string> extends Promise<TSuccess> {
  constructor(
    executor: (resolve: (value: TSuccess | PromiseLike<TSuccess>) => void, reject: (reason: TError) => void) => void,
  ) {
    super(executor);
  }
}

export interface ErrPromise<TSuccess, TError = string> {
  then<TResult1 = TSuccess, TResult2 = never>(
    onfulfilled?: ((value: TSuccess) => TResult1 | PromiseLike<TResult1>) | null,
    onrejected?: ((reason: TError) => TResult2 | PromiseLike<TResult2>) | null,
  ): Promise<TResult1 | TResult2>;

  catch<TResult = never>(
    onrejected?: ((reason: TError) => TResult | PromiseLike<TResult>) | null,
  ): Promise<TSuccess | TResult>;
}

export class CancellablePromise<T> extends ErrPromise<T> {
  public cancel: () => void;

  /** Since then / catch create new Promise, we have to re-bind the cancel() function... */
  override then<TResult1 = T, TResult2 = never>(
    onfulfilled?: (value: T) => TResult1 | PromiseLike<TResult1>,
    onrejected?: (reason: string) => TResult2 | PromiseLike<TResult2>,
  ): CancellablePromise<TResult1 | TResult2> {
    const promise = super.then(onfulfilled, onrejected) as CancellablePromise<TResult1 | TResult2>;
    promise.cancel = (): void => this.cancel();
    return promise;
  }

  override catch<TResult = never>(
    onrejected?: (reason: string) => TResult | PromiseLike<TResult>,
  ): CancellablePromise<T | TResult> {
    const promise = super.catch(onrejected) as CancellablePromise<T>;
    promise.cancel = (): void => this.cancel();
    return promise;
  }
}

export type UrlParseType = 'resetState' | 'mergeActive';

export const DEFAULT_MAP_POSITION = {
  lat: 28.8,
  lng: 30.3,
};
export const DEFAULT_MAP_ZOOM = 3;

export type NumOrString = number | string;

/**
 * Basic ref. data item
 */
export interface IdTitle<IdType = NumOrString> {
  id: IdType;
  title: string;
}

/**
 * Windfarm ref. data item
 */
export interface WindFarm extends IdTitle<number> {
  operator?: string;
  turbineNumber?: number;
  countryId?: string;
  country?: string;
  regionId?: number;
  region?: string;
  operatingPortDistance?: number;
  hasGeometry?: boolean;
}

export enum RefreshType {
  Default = 1, // Will refresh all except light charts
  Full = 2, // Will refresh all, even light chart
  ViewOnly = 3, // Will only redraw the component
  AllExceptTabs = 4, // Will refresh all, except tabs
}

export interface PageLinkSpec {
  href: string;
  title: string;
  index: number;
  linkListLength: number;
}

export interface UpdateCollectionParameters {
  entityId: string;
  valueToUpdate: string[];
  collection: string;
  delete: boolean;
}

export type ExcludeActionType = 'filtering' | 'removeFromCollection';

export interface ExcludeOptions {
  action: ExcludeActionType;
  excludeFilter?: LayerFilter;
}

export interface ExcludeParameters {
  action?: ExcludeActionType;
  /** Required property (only mandatory for action: 'filtering') */
  require?: string;
  entity?: string;
  collection?: string;
  collectionTitle?: string;
  entityId?: string;
  excludeItemFilterProp?: string;
  disabledExcludeProp?: string;
  tooltip?: string;
  entityDefinition?: EntityDefinition;
}

export interface IncludeParameters extends ExcludeParameters {
  buttonTitle: string;
  entityValue?: SomeEntity;
  collectionField?: EntityFieldDefinition;
}

export interface SwitchArticleEvent {
  currentIndex: number;
  direction: 'next' | 'previous';
}

export interface HasVesselId {
  vesselId: number;
  vessel?: string;
  title?: string;
}

export interface ComponentOptionsTab {
  title: string;
  value: string;
  selectedTab?: boolean;
}

export interface DrawioDiagramRequestParams {
  datetime: number;
  projectId: number;
  vesselId: number;
  cloudUrl?: string;
}

export interface VesselFleetInfo {
  title: string;
  filters: LayerFilter;
  sharedFleet: boolean;
}

export interface DynamicConfig {
  path: string;
  baseUrl: string;
  latestCalledUrl: string;
  latestResultHash: string;
}

export interface ArrowComparison {
  color: Color;
  direction: 'down' | 'up' | 'flat';
}

export interface ComparisonData<T> {
  previousValue: T;
  arrow: ArrowComparison;
}

/**
 * Additional data object for a field of type T
 *
 * @example
 * ```ts
 * type Obj = {
 *  a: number;
 *  a__additionalData: AdditionalData<number>
 * ```
 * }
 */
export interface AdditionalData<T> {
  comparison?: ComparisonData<T>;
  originalValue?: T;
  targetValue?: T;
}

/**
 * Union of all possible keys for additional data on object of type T
 *
 * @example
 * ```ts
 * type T = { a: number, b: string }
 * const k: AdditionalDataKeys<T> // 'a__additionalData' | 'b__additionalData'
 * ```
 */
export type AdditionalDataKeys<T> = `${keyof T & string}__additionalData` & keyof WithAdditionalData<T>;

/**
 * Enriches object type T with all possible additional data fields
 *
 * @example
 * ```ts
 * type T = { a: number, b: string }
 * type WithAdditionalData<T> = {
 *   a: number;
 *   a__additionalData?: AdditionalData<number>;
 *   b: string;
 *   b__additionalData?: AdditionalData<string>;
 * }
 * ```
 */
export type WithAdditionalData<T> =
  & T
  & {
    [K in keyof T as `${K & string}__additionalData`]?: AdditionalData<T[K]>;
  };

export type KpiValue = number | string | null;
export type KpiData = {
  [key: string]: KpiValue;
};

export interface BreadCrumbResponse {
  /** A page ID present in "config.pages" */
  pageId: string;
  /** Arguments that should be passed in the link */
  params?: { [id: string]: unknown };
}

export interface BreadCrumbItem extends BreadCrumbResponse {
  /** The displayed title, deduced at runtime from the config.pages definitions */
  pageTitle?: string;
}

/*
 * Taken from https://stackoverflow.com/a/41980288
 * Same as default Partial but recursive over all nested types.
 */
export type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>;
};
