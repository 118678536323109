import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector,
  ViewEncapsulation } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { NgClass } from '@angular/common';

import { DataHelpers } from '../helpers/data-helpers';
import { DataLoader } from '../data-loader/data-loader';
import { FieldOrdering, FieldSettings, HasVesselId } from '../helpers/types';
import { AdvancedFilteringModel } from './advanced-filtering-model';
import { PearlButtonComponent, PearlFormFieldComponent } from '../shared/pearl-components';

interface WfiObligatoryParameters {
  monopileHeight: number;
  monopileWeight: number;
  foundationUpperRadius: number;
  foundationBottomRadius: number;
  windFarmWaterDepth: number;
}

interface WfiOptionalParameters {
  safetyFactor: number;
  weightInaccuracy: number;
}

type WfiParameters = WfiObligatoryParameters & WfiOptionalParameters;

interface WfiQuery {
  wfiParameters: WfiParameters;
}

interface WfiVesselRequiredParameters {
  requiredCraneCapacity: number;
  requiredHookHeight: number;
}

interface WfiFoundationCalculatedParameters {
  dynamicAmplificationFactor: number;
  foundationStaticHeight: number;
}

interface WfiCalculatedParameters {
  foundationStaticWeight?: number;
  dynamicAmplificationFactor?: number;
  requiredCraneCapacity?: number;
  requiredHookHeight?: number;
}

interface WfiModelResults {
  vessels: { vessel_feature_id: number }[];
  vesselRequiredParameters: WfiVesselRequiredParameters;
  foundationCalculatedParameters: WfiFoundationCalculatedParameters;
}

interface WfiResults {
  vessels: { [vesselFeatureId: number]: any };
  vesselRequiredParameters: WfiVesselRequiredParameters;
  foundationCalculatedParameters: WfiFoundationCalculatedParameters;
}

@Component({
  selector: 'wfi-vessel-selector',
  templateUrl: 'wfi-vessel-selector.html',
  styleUrls: ['advanced-filtering-model.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    PearlFormFieldComponent,
    NgClass,
    FormsModule,
    MatInputModule,
    MatProgressSpinnerModule,
    PearlButtonComponent,
  ],
})
export class WfiVesselSelectorComponent
  extends AdvancedFilteringModel<WfiObligatoryParameters, WfiOptionalParameters, WfiCalculatedParameters, WfiResults>
  implements AfterViewInit
{
  public loadFiltersConfig(): Promise<void> {
    return Promise.resolve();
  }

  public static wfiVesselSelectorUrl: string = '/spindjango/wfi';
  public static wfiVesselListUrl: string = '/spindjango/wfi-vessels';

  public override fieldOrdering: FieldOrdering = {
    propOrder: 'meetRequirements',
    orderDirection: 'desc',
  };

  constructor(injector: Injector) {
    super(injector);
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  public override ngAfterViewInit(): void {
    this.resetOptionalParameters(false);
    this.valueChange();
  }

  public prepareParameters(): WfiParameters {
    const optionalParameters = this.optionalParametersToApply();

    const params = {
      monopileHeight: this.obligatoryParameters.monopileHeight,
      monopileWeight: this.obligatoryParameters.monopileWeight,
      foundationUpperRadius: this.obligatoryParameters.foundationUpperRadius,
      foundationBottomRadius: this.obligatoryParameters.foundationBottomRadius,
      windFarmWaterDepth: this.obligatoryParameters.windFarmWaterDepth,
      ...optionalParameters,
    };
    return params;
  }

  public get monopileWeightField() {
    return this.getObligatoryField('monopileWeight');
  }

  public get monopileHeightField() {
    return this.getObligatoryField('monopileHeight');
  }

  public get windFarmWaterDepthField() {
    return this.getObligatoryField('windFarmWaterDepth');
  }

  public get foundationUpperRadiusField() {
    return this.getObligatoryField('foundationUpperRadius');
  }

  public get foundationBottomRadiusField() {
    return this.getObligatoryField('foundationBottomRadius');
  }

  public async getModelVessels(): Promise<readonly HasVesselId[]> {
    const vesselsForModel = await this.dataLoader.get<HasVesselId[]>(
      WfiVesselSelectorComponent.wfiVesselListUrl,
    );
    return vesselsForModel;
  }

  public foundationInvalid(field: FieldSettings, value: any): boolean {
    if (this.fieldRangeInvalid(field, value)) {
      return true;
    }
    if (this.obligatoryParameters.foundationBottomRadius && this.obligatoryParameters.foundationUpperRadius) {
      return this.obligatoryParameters.foundationBottomRadius < this.obligatoryParameters.foundationUpperRadius;
    }
  }

  public override getErrorMessage(field: FieldSettings, value?: any): string {
    // Covers all the cases but foundation radius inequality
    if (!value || this.fieldRangeInvalid(field, value)) {
      return super.getErrorMessage(field);
    }
    return 'Foundation bottom radius must be superior or equal to foundation upper radius';
  }

  public checkModelCoherency(): boolean {
    const heightOk = !this.fieldRangeInvalid(this.monopileHeightField, this.obligatoryParameters.monopileHeight);
    const weightOk = !this.fieldRangeInvalid(this.monopileWeightField, this.obligatoryParameters.monopileWeight);
    const upperRadiusOk = !this.fieldRangeInvalid(
      this.foundationUpperRadiusField,
      this.obligatoryParameters.foundationUpperRadius,
    );
    const bottomRadiusOk = !this.fieldRangeInvalid(
      this.foundationBottomRadiusField,
      this.obligatoryParameters.foundationBottomRadius,
    );
    const waterDepthOk = !this.fieldRangeInvalid(
      this.windFarmWaterDepthField,
      this.obligatoryParameters.windFarmWaterDepth,
    );

    // No request if one of the parameters is explicitly set as invalid
    if (
      !bottomRadiusOk || !upperRadiusOk || !heightOk || !weightOk || !waterDepthOk || !this.checkAdvancedParameters()
    ) {
      return false;
    }

    if (this.obligatoryParameters.foundationUpperRadius && this.obligatoryParameters.foundationBottomRadius) {
      return this.obligatoryParameters.foundationUpperRadius <= this.obligatoryParameters.foundationBottomRadius;
    }

    return true;
  }

  public updateCalculatedParameters(results: WfiResults) {
    this.calculatedParameters = { ...this.calculatedParameters, ...results.vesselRequiredParameters };
    this.calculatedParameters = { ...this.calculatedParameters, ...results.foundationCalculatedParameters };
    this.formatCalculatedParameters();
    this.cdRef.detectChanges();
  }

  public runModel(): Promise<WfiResults> {
    const wfiQuery = {
      wfiParameters: this.savedParameters,
    };
    const serverQuery = this.dataLoader.post<WfiQuery, WfiModelResults>(
      WfiVesselSelectorComponent.wfiVesselSelectorUrl,
      wfiQuery,
    );

    this.currentServerRequest = serverQuery;
    return serverQuery.then(serverResult => {
      const vesselsByFeatureIdDictionary = DataHelpers.toDict(serverResult.vessels, d => d[this.idProp]);
      const result: WfiResults = {
        ...serverResult,
        vessels: vesselsByFeatureIdDictionary,
      };
      return result;
    });
  }
}
