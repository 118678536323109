import { IdTitle, NumOrString } from '../helpers/types';
import { DataPoint } from './data-loader.types';

export type RefDatasetItem<IdType extends NumOrString = NumOrString> = DataPoint & IdTitle<IdType>;

/**
 * Holds a referential dataset, dict. like.
 */
export interface RefDataset {
  [id: NumOrString]: RefDatasetItem;
}

/**
 * Holds all referential datasets used in the app.
 * Only one instance of this interface should exist (`RefDataProvider.refData`)
 */
export interface RefDatasets {
  [entity: string]: RefDataset;
}

interface RefDataConfigBase {
  /** Ref dataset name */
  id: string;
  url: string;
  /** True if should be loaded at init. Undefined or false if lazy-loaded */
  loadAtInit?: boolean;
}

export interface RefDataConfig extends RefDataConfigBase {
  dbEntityName?: string;
}

interface RefData {
  id: string;
  dbEntityName?: string;
}

export interface SimpleRefDataConfig extends RefDataConfigBase {
  datasets: RefData[];
}

export type RefDataConfigs = (RefDataConfig | SimpleRefDataConfig)[];
export function isSimpleRefData(conf: RefDataConfig | SimpleRefDataConfig): conf is SimpleRefDataConfig {
  return (conf as SimpleRefDataConfig).datasets !== undefined;
}
