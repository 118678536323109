<mat-menu
  dir="column"
  class="pearl-menu"
  [hasBackdrop]="hasBackdrop()"
  [class]="menuPanelClass()"
  (close)="onClose()"
  [xPosition]="xPosition()"
  [yPosition]="yPosition()">
  <div class="items-ctn" [style.width.px]="panelWidth()">
    @for (category of categories(); track $index) {
      @if (category.label) {
        <div class="pearl-menu-category">
          <span>{{ category.label | uppercase }}</span>
        </div>
      }
      @for (item of category.items; track $index) {
        <div
          mat-menu-item
          class="pearl-menu-item"
          [ngClass]="{ active: item.active, upsell: item.upsell }"
          (click)="onPearlMenuItemClick($event, item)"
          [matTooltip]="item.tooltip?.message"
          [matTooltipPosition]="item.tooltip?.position"
          [disabled]="item.disabled ?? false">
          @if (item.leadingImageUrl) {
            <img class="leading-image" [src]="item.leadingImageUrl" />
          }
          @if (item.leadingIcon) {
            <pearl-icon
              class="leading-icon"
              [ngClass]="{ warning: item.leadingIcon.mode === 'warning' }"
              [icon]="item.leadingIcon.icon"
              [size]="iconSize()" />
          }
          <div class="text-ctn">
            <div class="label-ctn">
              <span class="label">{{ item.label }}</span>
            </div>
            @if (item.supportingText) {
              <div class="supporting-text">
                {{ item.supportingText }}
              </div>
            }
          </div>
          @if (item.trailingIcon) {
            <pearl-icon class="trailing-icon" [icon]="item.trailingIcon" [size]="iconSize()" />
          }
          @if (item.active) {
            <pearl-icon class="trailing-icon" icon="checked" [size]="iconSize()" />
          }
        </div>
        @if (item.divider) {
          <div class="pearl-menu-divider"></div>
        }
      }
    }
  </div>
</mat-menu>
