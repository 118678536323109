import { Component, computed, input } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatBadgeModule } from '@angular/material/badge';

import { PearlIcon, PearlIconSize, UNIQUE_SIZE_ICONS } from './icons';

@Component({
  selector: 'pearl-icon',
  standalone: true,
  imports: [MatIconModule, MatBadgeModule],
  styleUrls: ['./pearl-icon.component.scss'],
  templateUrl: 'pearl-icon.component.html',
})
/**
 * To find out more about icons visit:
 *   - https://material.io/resources/icons/?style=baseline
 *   - https://www.notion.so/spinergie/Pearl-icon-b3a63ceab1944962b4266592b1a693aa
 */
export class PearlIconComponent {
  public readonly size = input<PearlIconSize | undefined>(undefined);
  public readonly icon = input<PearlIcon | undefined>(undefined);
  public readonly iconBadgeContent = input<string>(undefined);

  public readonly iconClass = computed(() => `icon-${this.size()}`);
  public readonly iconName = computed(() => {
    if (this.size() && !UNIQUE_SIZE_ICONS.includes(this.icon())) {
      return `${this.icon()}_${this.size()}px`;
    }

    // In case we use custom icon in one size
    return this.icon();
  });
}
