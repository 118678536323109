import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, Output, QueryList, ViewChildren,
  ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgFor, NgSwitch, NgSwitchCase } from '@angular/common';

import { FilterHelper } from '../filters/filter-helper';
import { SelectorFullConfig } from '../selector/selector.types';
import { Config } from '../config/config';
import { LayersDictionary, StandardLayerState, getLatestPositionLayer } from '../dashboards/model/layer-state';
import { DialogManager } from '../database/dialog-manager';
import { SidebarLayerComponent, SidebarLayerComponent as SidebarLayerComponent_1 } from '../filters/sidebar-layer';
import { RawDataPoint } from '../graph/chart-types';
import { ActionEvent, Button, DEFAULT_MAX_TRACES, DeepReadonly, FieldsetUpdate, FilterApplied, FiltersChanged,
  FiltersOnLayerChanged, LayerFilter, LayerHiddenShapeInfo, LayerId, LayerToggledEvent, PreparedGeomItem,
  PreparedVesselData, SwitchLayerModeInfo } from '../helpers/types';
import { SidebarFieldsetComponent } from './sidebar-fieldset.component';
import { PageStateService } from '../shared/services/page-state.service';
import { PearlButtonComponent } from '../shared/pearl-components';
import { MapLayerSettings } from '../helpers/config-types';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';
import { SidebarFieldsetsGroup } from './sidebar-fieldsets-group';
import { AdvancedFieldsetCollection } from './fieldsets-collection';
import { MapLibreLayer } from '../map/maplibre-layer';

@Component({
  selector: 'spin-sidebar',
  templateUrl: 'sidebar.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    PearlButtonComponent,
    NgFor,
    SidebarLayerComponent_1,
    PearlButtonComponent,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class SidebarComponent<U extends StandardLayerState> {
  @Input()
  public layers: LayersDictionary<U>;
  @Input()
  public layersSettings: { [layerId: string]: MapLayerSettings };
  @Input()
  public commonFieldsetsGroup: SidebarFieldsetsGroup;
  @Input()
  public buttons: Array<any> = [];
  @Input()
  public spinergieLabelsShown: boolean;
  @Input()
  public onaction: (event: ActionEvent) => void;
  @Input()
  public sidebarCollapsed: boolean = false;

  @Output()
  onfilters = new EventEmitter<FiltersOnLayerChanged>();
  @Output()
  onlayer = new EventEmitter<LayerToggledEvent>();
  @Output()
  onButtonClick = new EventEmitter<Button>();
  @Output()
  onToggleShapeVisibility = new EventEmitter<LayerHiddenShapeInfo>();
  @Output()
  onserializeurl = new EventEmitter<any>();
  @Output()
  onsidebarswitchlayermode = new EventEmitter<SwitchLayerModeInfo>();
  @Output()
  sidebarCollapseRequested = new EventEmitter<boolean>();

  @ViewChildren(SidebarLayerComponent)
  public $sidebarLayers: QueryList<SidebarLayerComponent>;
  @ViewChildren(SidebarFieldsetComponent)
  public $fieldsets: QueryList<SidebarFieldsetComponent>;

  constructor(
    @Inject(MatDialog) public dialog: MatDialog,
    @Inject(Config) public config: Config,
    @Inject(ChangeDetectorRef) public cdRef: ChangeDetectorRef,
    @Inject(DialogManager) public dialogManager: DialogManager,
    @Inject(PageStateService) public pageStateService: PageStateService,
  ) {}

  public populateLayer(
    layerId: LayerId,
    data: DeepReadonly<RawDataPoint[]> | RawDataPoint[],
    keepExisting: boolean,
  ): void {
    const sidebarLayer = this.$sidebarLayers.find($sl => $sl.layer.id === layerId);
    sidebarLayer.populate(data, keepExisting);
    sidebarLayer.vesselFieldInitialization = false;
    sidebarLayer.ready = true;
  }

  public populateVesselField(layerId: LayerId, data: DeepReadonly<PreparedGeomItem[]>): void {
    const sidebarLayer = this.$sidebarLayers.find($sl => $sl.layer.id === layerId);
    const vesselFieldComponent = sidebarLayer.$vesselField;
    const vesselField = vesselFieldComponent?.field() ?? (this.layers[layerId] as unknown as MapLibreLayer).vessel;
    if (!vesselField) return;
    FilterHelper.populateField(vesselField, data);
    if (vesselFieldComponent) vesselFieldComponent.update();
  }

  public set(layerId: string, filters: LayerFilter, fire: boolean = false): void {
    const sidebarLayer = this.$sidebarLayers.find($sidebarLayer => $sidebarLayer.layer.id === layerId);
    if (!sidebarLayer) {
      return;
    }
    sidebarLayer.reloadAfterConfig();
    sidebarLayer.set(filters, fire);
  }

  /*
   * Note that all filters are set on there fields without being fired. The firing is done once with a batch of filters
   * at the end if fire flag is set. It improves performances.
   */
  public setCommonFilters(filters: LayerFilter, fire = false): void {
    const { standardFilters, advancedFilters } = this.commonFieldsetsGroup.splitSelectorFilters(filters);
    this.commonFieldsetsGroup.setAdvancedFilters(advancedFilters);
    this.$fieldsets.forEach($fieldset => $fieldset.set(standardFilters, false));

    if (fire) {
      this.mergeAndEmitFilters(null);
    }
  }

  public onCommonFilterApplied(filter: FilterApplied): void {
    this.mergeAndEmitFilters(filter);
  }

  public mergeAndEmitFilters(latestFilter: FilterApplied): void {
    // is the event to notify the dashboard
    const filtersChanged: FiltersChanged = {
      values: {
        ...this.appliedFilters,
      },
      latest: latestFilter,
    };

    const layerFilterChanged: FiltersOnLayerChanged = {
      layerId: 'common-filters',
      filters: filtersChanged,
    };
    this.onfilters.emit(layerFilterChanged);
  }

  /*
   * Get all filters applied to this sidebar, from all fieldsets. The state of
   * advanced filters is maintained by commonFieldsetsGroup and state of the
   * standard fields is on the fieldsets components
   */
  public get appliedFilters(): LayerFilter {
    const filters = this.commonFieldsetsGroup.getAdvancedFiltersStateSnapshot();
    this.$fieldsets?.forEach($fs => Object.assign(filters, $fs.appliedFilters));

    return filters;
  }

  public get standardAppliedFilters(): LayerFilter {
    return FilterHelper.getFiltersAppliedOnFieldsets(this.$fieldsets);
  }

  public openSelector(advancedFieldsetsCollection: AdvancedFieldsetCollection): void {
    const state: SelectorFullConfig = {
      advancedFieldsets: advancedFieldsetsCollection,
      afterFilterAction: filters => this.applySelectorFilters(filters, advancedFieldsetsCollection),
      appliedFilters: this.appliedFilters,
      entityIdsFromData: this.commonFieldsetsGroup.getEntityIdsFromSidebarPopulateValues(
        advancedFieldsetsCollection.selectorConfig,
      ),
    };

    state.onAction = this.onaction;
    this.dialogManager.openSelectorDialog(state);
  }

  /**
   * Handle apply of advanced filters. Almost the same as sidebar-layer
   * This is very similar to `setCommonFilters` - it will be unified in the follow up
   */
  public applySelectorFilters(filters: LayerFilter, advancedFieldsetsCollection: AdvancedFieldsetCollection): void {
    // from applied advanced filters extract the once to be applied in standard fields
    const { standardFilters, advancedFilters } = this.commonFieldsetsGroup.splitSelectorFilters(filters);

    // set the filters on the advanced-filters inside fieldset-collection
    advancedFieldsetsCollection.setFilters(advancedFilters);

    // pure standard filters are those that are only in standard filters and not present in advanced
    const pureStandardFilters = this.commonFieldsetsGroup.getFiltersNotInAdvanced(this.standardAppliedFilters);

    // all standard filters to be set, those activated before and those coming from advanced filters
    const allStandardFilters = FilterHelper.mergeLayerFilters(standardFilters, pureStandardFilters);

    /*
     * set them visually on the common filters inside the sidebar
     * that will set the filters which might be part of the advanced filters
     */
    this.$fieldsets.forEach($fieldset => $fieldset.clearAndSet(allStandardFilters, false));

    // pick a filter that will be send as latest-filter
    const latestFilter = advancedFieldsetsCollection.getAppliedEntityOrFirstFilter(filters);
    this.mergeAndEmitFilters(latestFilter);
  }

  public onreset(e): void {
    e.preventDefault();
    this.reset();
  }

  public onbubblinglayer(layerId: LayerId, layerToggled: false): void {
    this.onlayer.emit({
      layerId: layerId,
      visible: layerToggled,
    });
  }

  public onbubblingfilters(layerId: LayerId, filters: FiltersChanged): void {
    /** Special case for vessel field */
    if (filters.latest?.isVesselFilter && filters.latest?.active) {
      this.handleVesselFilter(layerId, filters);
      return;
    }

    const filtersChangedParams: FiltersOnLayerChanged = {
      layerId,
      filters,
    };
    this.onfilters.emit(filtersChangedParams);
    // we need to refresh the ui while sliding/ filtering
    this.cdRef.detectChanges();
  }

  /**
   * When the vessel filter is changed, we have a special behavior for handling adding / removing vessels from the list
   * of historical or latest positions to load
   */
  public handleVesselFilter(layerId: LayerId, filters: FiltersChanged): void {
    const vessels = filters.latest.values.map(id => parseInt(id));
    const lastAddedVesselId = vessels[vessels.length - 1];
    const layers = this.layers as unknown as LayersDictionary<MapLibreLayer>;
    const currentLayer = layers[layerId];
    const latestPosLayer = getLatestPositionLayer(layers, currentLayer.settings.layerGroup);
    /** We look for the data on latest positions layer, since historical layer does not contain the info we want*/
    const vesselData = latestPosLayer.data as DeepReadonly<PreparedVesselData[]>;
    const vessel = vesselData.find(d => d.vesselId === lastAddedVesselId);

    if (!vessel) {
      console.error(
        new ErrorWithFingerprint('Vessel not found in vesselData from sidebar', [
          'sidebar-vessel-not-find-for-filter',
        ]),
      );
      return;
    }

    const $sidebarLayer = this.$sidebarLayers.find($sl => $sl.layer.id === layerId);

    /*
     * if there is no trace available indicator on the data, it means that the trace is available,
     * otherwise it would have to be explicitly false we have to modify the VesselData here
     */
    (vessel as PreparedVesselData).traceAvailable = vessel.traceAvailable
      || vessel.traceAvailable === undefined;

    // we need the list of previously selected vessels, to potentially clear it or use it
    const allExceptTheLastOne = vessels.filter(vesselId => vesselId !== vessel.vesselId);

    /*
     * we are in the latest position mode
     * if the latest vessel doesn't have the latest position, we will show a dialog with option to see
     * the historical position if available
     */
    if (currentLayer.settings.layerType === 'latest') {
      /*
       * last added vessel does not have latest position
       * no latest position - the user is prompted whether he wants to see the historical position
       */
      if (!vessel.geometry) {
        this.dialogManager.noLatestPositionWarning(vessel, this.onaction, currentLayer.settings.dialog);

        // we have to remove the graphical tag as well and remove the value from both filters
        $sidebarLayer.$vesselField.clear();
        this.updateAndSendVesselFilter(layerId, filters, allExceptTheLastOne);
        return;
      } // if we have the position of all vessels, we just set the filter
      else {
        this.updateAndSendVesselFilter(layerId, filters, vessels);
      }
    } else if (currentLayer.settings.layerType === 'historical') {
      /*
       * the added latest vessel doesn't have the trace and we are in trace mode
       * when the dialog is closed, we will remove the last added vessel
       */
      if (!vessel.traceAvailable) {
        this.dialogManager.simpleDialog(
          'No trace data for added vessel',
          'The vessels does not have historical position data, it will be removed from the list.',
        );
        $sidebarLayer.$vesselField.clear();
        this.updateAndSendVesselFilter(layerId, filters, allExceptTheLastOne);
        return;
      }

      /*
       * we have to make sure that in historical mode we never have more then predefined number of vessels
       * if that would be the case we would switch to latest position mode
       */
      const maxTraces = currentLayer.settings.maxTraces ?? DEFAULT_MAX_TRACES;

      // we have more vessels then allowed, we have to switch the mode
      if (vessels.length > maxTraces) {
        this.dialogManager.simpleDialog(
          'Too much vessels',
          `Only ${maxTraces} vessels can be displayed in historical mode.`,
        );
        $sidebarLayer.$vesselField.clear();
        return;
      }
      // we are all good we can just filter the vessels in historical mode
      this.updateAndSendVesselFilter(layerId, filters, vessels);
      // We load the new vessels positions
      this.onsidebarswitchlayermode.emit({ layerId, switchMode: 'historical' });
    }
  }

  /** Must be only called when latest filter is the vessel filter */
  private updateAndSendVesselFilter(layerId: LayerId, filters: FiltersChanged, vesselIds: number[]): void {
    filters.latest.values = vesselIds;
    filters.latest.active = vesselIds.length > 0;
    filters.values.vessel = filters.latest;
    const filtersChangedParams: FiltersOnLayerChanged = {
      layerId,
      filters,
    };
    this.onfilters.emit(filtersChangedParams);
  }

  public reset(fire: boolean = true): void {
    const visibleLayers = this.$sidebarLayers.filter($sl => $sl.layer.visible);
    visibleLayers.forEach($sl => $sl.reset(fire));

    if (fire) {
      visibleLayers.forEach($sl =>
        this.onfilters.emit({
          layerId: $sl.layer.id,
          filters: {
            values: $sl.getLayerFilters(),
          },
        })
      );
    }

    /*
     * case where we have common filters
     * and potentially advanced filters on it
     */
    if (this.commonFieldsetsGroup) {
      this.resetCommonFilters(fire);
    }
  }

  public resetSpecificLayer(layerId: LayerId, fire: boolean = true): void {
    const $layer = this.$sidebarLayers.find($sl => $sl.layer.id === layerId);
    $layer.reset(fire);
  }

  public get layerList(): U[] {
    return Object.values(this.layers);
  }

  public windowOpen(url: string): void {
    window.open(url);
  }

  public buttonClick(button: Button): void {
    this.onButtonClick.emit(button);
  }

  public toggleShapeVisibility(event: LayerHiddenShapeInfo): void {
    this.onToggleShapeVisibility.emit(event);
  }

  public handleSwitchLayerMode(switchModeInfo: SwitchLayerModeInfo): void {
    this.onsidebarswitchlayermode.emit(switchModeInfo);
  }

  public populateWithValues(layerId: LayerId, fieldsetUpdate: FieldsetUpdate): void {
    // populate all sidebar-layers with values
    const affectedLayer = this.$sidebarLayers.find(d => d.layer.id === layerId);
    affectedLayer.populateWithValues(fieldsetUpdate);

    // and populate all common filters with values if there are any
    this.$fieldsets.forEach(fieldset => fieldset.populateWithValues(fieldsetUpdate));
  }

  /**
   * Populate the common filters with the given data.
   *
   * @param data        The data used to populate the filters.
   */
  public populateCommon(data: RawDataPoint[]): void {
    if (!this.commonFieldsetsGroup) {
      return;
    }

    this.pageStateService.populateFieldsets(this.$fieldsets, data, true);
    this.cdRef.detectChanges();
  }

  public resetCommonFilters(fire: boolean = true): void {
    this.commonFieldsetsGroup.resetFilters();
    this.$fieldsets?.forEach($fieldset => $fieldset.reset(false));

    if (fire) {
      this.mergeAndEmitFilters(null);
    }
  }

  public openConfig(config: object): void {
    this.dialogManager.simpleDialog('Config', '', config);
  }
}
