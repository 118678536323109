import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';

import { ProductAnalyticsDirective } from '../shared/product-analytics/product-analytics.directive';
import { PearlFormFieldComponent } from '../shared/pearl-components';

export interface VesselFleetDialogData {
  title: string;
  filters: any;
  created: boolean;
  canShareFleet: boolean;
  shared: boolean;
  client: string;
}

@Component({
  selector: 'vessel-fleet-dialog',
  templateUrl: 'vessel-fleet-dialog.html',
  styleUrls: ['vessel-fleet-dialog.scss'],
  standalone: true,
  imports: [
    NgIf,
    MatDialogModule,
    PearlFormFieldComponent,
    MatInputModule,
    FormsModule,
    MatCheckboxModule,
    MatButtonModule,
    MatTooltipModule,
    ProductAnalyticsDirective,
  ],
})
export class VesselFleetDialog {
  constructor(
    public dialogRef: MatDialogRef<VesselFleetDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: VesselFleetDialogData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  getResultData() {
    return {
      title: this.data.title,
      shared: this.data.shared,
    };
  }
}
