import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';

import { DataHelpers } from '../helpers/data-helpers';
import { PositionedTooltip } from './tooltip';
import { TooltipSettings } from '../helpers/types';
import { SafeHtmlPipe } from '../helpers/pipes';

@Component({
  selector: 'simple-tooltip',
  templateUrl: 'simple-tooltip.html',
  styleUrls: ['simple-tooltip.css'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, NgIf, SafeHtmlPipe],
})
export class SimpleTooltipComponent extends PositionedTooltip {
  // Content can be plain text or HTML
  public content: string = '';
  public specificStylingClassName: string = '';
  public markdownSupport: boolean = false;

  constructor(elRef: ElementRef<HTMLElement>, cdRef: ChangeDetectorRef) {
    super(elRef, cdRef);
  }

  public get formattedContent(): string {
    if (this.markdownSupport) {
      return DataHelpers.markdownToHtml(this.content);
    }

    return this.content;
  }

  public show(content: string, coords: [number, number], options?: TooltipSettings): void {
    this.resetTimeout();

    this.content = content;
    this.showTooltipAtPosition(coords, options);

    this.cdRef.detectChanges();
  }
}
