import { ModifierKey, hasModifierKey } from '@angular/cdk/keycodes';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef,
  PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, DestroyRef, Directive, Injector, Input, OnChanges, OnDestroy, SimpleChanges, ViewContainerRef,
  afterNextRender, booleanAttribute, inject, numberAttribute } from '@angular/core';
import { _getFocusedElementPierceShadowDom as getFocusedElementPierceShadowDom } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, take } from 'rxjs/operators';
import { Observable, Subject, merge } from 'rxjs';
import { Dayjs } from 'dayjs';

import { PearlDatepickerContentComponent } from './datepicker-content';
import { DateFilter, DatetimeElement, DatetimePrecision, isTimePrecision } from './datepicker-types';
import { PearlDatepickerInputDirective } from './datepicker-input';
import { getAvailableDatetimeElements, getDatetimeDisplayFormat, getDatetimeParseFormats } from './datepicker-formats';

/** Base class for a datepicker. */
@Directive()
export abstract class PearlDatepickerBase implements OnDestroy, OnChanges {
  private document = inject(DOCUMENT);
  private overlay = inject(Overlay);
  private viewContainerRef = inject(ViewContainerRef);
  private injector = inject(Injector);
  private destroyRef = inject(DestroyRef);

  /** The input element this datepicker is associated with. */
  private datepickerInput: PearlDatepickerInputDirective;

  /**
   * Precision of the date picker, ie. smallest period of time the user can select or type.
   *
   * Date selected from the date picker or parsed form the input will be rounded to the closest unit of precision.
   *
   * @example
   * ```html
   * <pearl-datepicker precision='minute' />` <!-- dates are rounded to the minute, etc. -->
   * ```
   */
  @Input()
  public readonly precision: DatetimePrecision = 'day';

  /**
   * Hide date in date picker and input, effectively rendering a time picker.
   *
   * This can only be `true` for time precisions.
   *
   * @example
   * ```html
   * <pearl-datepicker precision='minute' timeOnly /> <!-- OK -->
   * <pearl-datepicker precision='day' timeOnly /> <!-- throws an error -->
   * ```
   */
  @Input({ transform: booleanAttribute })
  public readonly timeOnly: boolean = false;

  /**
   * Let the user select the timezone in addition to the time and date (or time only if `timeOnly=true`)
   *
   * This can only be `true` for time precisions.
   *
   * @example
   * ```html
   * <pearl-datepicker precision='second' withTimezone /> <!-- OK -->
   * <pearl-datepicker precision='month' withTimezone /> <!-- throws an error -->
   * ```
   */
  @Input({ transform: booleanAttribute })
  public readonly withTimezone: boolean = false;

  /**
   * Round dates to the the end of the period corresponding to `precision` instead of the beginning.
   *
   * @example
   * ```html
   * <!-- Selecting Jan, 15 2024 form the datepicker -->
   * <pearl-datepicker precision='day' /> <!-- will return 2024-01-15 00:00:00 -->
   * <pearl-datepicker precision='day' endOfPeriod /> <!-- will return 2024-01-15 23:59:59 -->
   * ```
   */
  @Input({ transform: booleanAttribute })
  public readonly endOfPeriod: boolean = false;

  /**
   * Granularity of the minute picker. Sensible values are 1 (default), 5, 10, 15, 20, 30.
   *
   * This has no effect for date precisions.
   */
  @Input({ transform: numberAttribute })
  public readonly minuteGranularity: number = 1;

  /** Preferred position of the datepicker in the X axis. */
  @Input()
  protected readonly xPosition: 'start' | 'end' = 'start';

  /** Preferred position of the datepicker in the Y axis. */
  @Input()
  protected readonly yPosition: 'top' | 'bottom' = 'bottom';

  /**
   * Whether the calendar UI is in touch mode. In touch mode the calendar opens in a dialog rather
   * than a dropdown and elements have more padding to allow for bigger touch targets.
   */
  @Input({ transform: booleanAttribute })
  public readonly touchUi: boolean = false;

  /** Emits when a value is selected */
  public readonly dateSelected = new Subject<Dayjs | null>();
  /** Emits when a timezone is selected */
  public readonly timezoneSelected = new Subject<string>();
  /** Emits when the datepicker's state changes. */
  public readonly stateChanges = new Subject<string[]>();

  /** A reference to the overlay into which we've rendered the calendar. */
  private overlayRef: OverlayRef | null;

  /** Reference to the component instance rendered in the overlay. */
  private componentRef: ComponentRef<PearlDatepickerContentComponent> | null;

  /** The element that was focused before the datepicker was opened. */
  private focusedElementBeforeOpen: HTMLElement | null = null;

  /** Whether the calendar is open. */
  @Input({ transform: booleanAttribute })
  get opened(): boolean {
    return this._opened;
  }
  set opened(value: boolean) {
    if (value) {
      this.open();
    } else {
      this.close();
    }
  }
  private _opened = false;

  /** Whether the datepicker pop-up should be disabled. */
  @Input({ transform: booleanAttribute })
  get disabled(): boolean {
    return this._disabled === undefined && this.datepickerInput
      ? this.datepickerInput.disabled
      : !!this._disabled;
  }
  set disabled(value: boolean) {
    if (value !== this._disabled) {
      this._disabled = value;
      this.stateChanges.next(['disabled']);
    }
  }
  private _disabled: boolean;

  /**
   * Whether to restore focus to the previously-focused element when the calendar is closed.
   * Note that automatic focus restoration is an accessibility feature and it is recommended that
   * you provide your own equivalent, if you decide to turn it off.
   */
  @Input({ transform: booleanAttribute })
  protected readonly restoreFocus: boolean = true;

  /** The minimum selectable date. */
  getMinDate(): Dayjs | null {
    return this.datepickerInput && this.datepickerInput.minDate;
  }

  /** The maximum selectable date. */
  getMaxDate(): Dayjs | null {
    return this.datepickerInput && this.datepickerInput.maxDate;
  }

  getDateFilter(): DateFilter | undefined {
    return this.datepickerInput && this.datepickerInput.dateFilter;
  }

  getDisplayFormat(): string {
    return getDatetimeDisplayFormat(this.precision, this.timeOnly, this.withTimezone);
  }

  getParseFormats(): string[] {
    return getDatetimeParseFormats(this.precision, this.timeOnly, this.withTimezone);
  }

  getAvailableDatetimeElements(): DatetimeElement[] {
    return getAvailableDatetimeElements(this.precision, this.timeOnly);
  }

  /** Round a date to the minute, day etc. depending on datepicker mode */
  roundDateToPrecision(date: Dayjs): Dayjs {
    return this.endOfPeriod ? date.endOf(this.precision) : date.startOf(this.precision);
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Preferred position changes: update overlay position
    const positionChange = changes['xPosition'] || changes['yPosition'];

    if (positionChange && !positionChange.firstChange && this.overlayRef) {
      const positionStrategy = this.overlayRef.getConfig().positionStrategy;

      if (positionStrategy instanceof FlexibleConnectedPositionStrategy) {
        this.setConnectedPositions(positionStrategy);

        if (this.opened) {
          this.overlayRef.updatePosition();
        }
      }
    }

    // Datepicker mode changes (should usually not change after init): check values consistency
    if (changes['precision'] || changes['timeOnly'] || changes['withTimezone']) {
      this.checkInputsConsistency();
    }

    // Propagate changed property names
    this.stateChanges.next(Object.keys(changes));
  }

  ngOnDestroy(): void {
    this.destroyOverlay();
    this.close();
    this.stateChanges.complete();
  }

  /**
   * Register an input with this datepicker.
   * @param input The datepicker input to register with this datepicker.
   */
  registerInput(input: PearlDatepickerInputDirective): void {
    if (this.datepickerInput) {
      throw new Error('A Pearl Datepicker can only be associated with a single input');
    }

    this.datepickerInput = input;
    input.valueChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((newValue: Dayjs | null) => {
      // Update the datepicker content input if it is open
      this.componentRef?.setInput('selected', newValue);
    });
    input.timezoneChange.subscribe((tz: string) => {
      this.componentRef?.setInput('timezone', tz);
    });
    input.stateChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((changed: string) => {
      if (changed === 'disabled') {
        this.stateChanges.next(['disabled']);
      }
    });
  }

  /** Open the calendar. */
  open(): void {
    /*
     * Skip reopening if there's an in-progress animation to avoid overlapping
     * sequences which can cause "changed after checked" errors.
     * See https://github.com/angular/components/issues/25837.
     */
    if (this._opened || this.disabled || this.componentRef?.instance.isAnimating) {
      return;
    }

    if (!this.datepickerInput) {
      throw new Error('Attempted to open a Pearl Datepicker with no associated input.');
    }

    this.focusedElementBeforeOpen = getFocusedElementPierceShadowDom();
    this.openOverlay();
    this._opened = true;
  }

  /** Close the calendar. */
  close(): void {
    /*
     * Skip reopening if there's an in-progress animation to avoid overlapping
     * sequences which can cause "changed after checked" errors.
     * See https://github.com/angular/components/issues/25837.
     */
    if (!this._opened || this.componentRef?.instance.isAnimating) {
      return;
    }

    const canRestoreFocus = this.restoreFocus
      && this.focusedElementBeforeOpen
      && typeof this.focusedElementBeforeOpen.focus === 'function';

    const completeClose = (): void => {
      /*
       * The `_opened` could've been reset already if
       * we got two events in quick succession.
       */
      if (this._opened) {
        this._opened = false;
      }
    };

    if (this.componentRef) {
      const { instance, location } = this.componentRef;
      instance.startExitAnimation();
      instance.animationDone.pipe(take(1)).subscribe(() => {
        const activeElement = this.document.activeElement;

        /*
         * Since we restore focus after the exit animation, we have to check that
         * the user didn't move focus themselves inside the `close` handler.
         */
        if (
          canRestoreFocus
          && (!activeElement
            || activeElement === this.document.activeElement
            || (location.nativeElement as Element).contains(activeElement))
        ) {
          this.focusedElementBeforeOpen.focus();
        }

        this.focusedElementBeforeOpen = null;
        this.destroyOverlay();
      });
    }

    if (canRestoreFocus) {
      /*
       * Because IE moves focus asynchronously, we can't count on it being restored before we've
       * marked the datepicker as closed. If the event fires out of sequence and the element that
       * we're refocusing opens the datepicker on focus, the user could be stuck with not being
       * able to close the calendar at all. We work around it by making the logic, that marks
       * the datepicker as closed, async as well.
       */
      setTimeout(completeClose);
    } else {
      completeClose();
    }
  }

  /** Forwards relevant values from the datepicker to the datepicker content inside the overlay. */
  protected setOverlayInputs(componentRef: ComponentRef<PearlDatepickerContentComponent>): void {
    componentRef.setInput('precision', this.precision);
    componentRef.setInput('timeOnly', this.timeOnly);
    componentRef.setInput('withTimezone', this.withTimezone);
    componentRef.setInput('minuteGranularity', this.minuteGranularity);
    componentRef.setInput('selected', this.datepickerInput.value);
    componentRef.setInput('timezone', this.datepickerInput.timezone);
    componentRef.setInput('minDate', this.getMinDate());
    componentRef.setInput('maxDate', this.getMaxDate());
    componentRef.setInput('dateFilter', this.getDateFilter());

    componentRef.instance.dateSelected.subscribe((d: Dayjs) => this.dateSelected.next(this.roundDateToPrecision(d)));
    componentRef.instance.timezoneSelected.subscribe((tz: string) => this.timezoneSelected.next(tz));
    componentRef.instance.selectionEnded.subscribe(() => this.close());
  }

  /** Opens the overlay with the calendar. */
  private openOverlay(): void {
    this.destroyOverlay();

    const isDialog = this.touchUi;
    const portal = new ComponentPortal<PearlDatepickerContentComponent>(
      PearlDatepickerContentComponent,
      this.viewContainerRef,
    );

    const overlayConfig = new OverlayConfig({
      positionStrategy: isDialog ? this.getDialogStrategy() : this.getDropdownStrategy(),
      hasBackdrop: true,
      backdropClass: isDialog ? 'cdk-overlay-dark-backdrop' : '',
      scrollStrategy: this.overlay.scrollStrategies.block(),
    });

    this.overlayRef = this.overlay.create(overlayConfig);
    const overlayRef = this.overlayRef;

    this.getCloseStream(overlayRef).subscribe(event => {
      if (event) {
        event.preventDefault();
      }
      this.close();
    });

    /*
     * The `preventDefault` call happens inside the calendar as well, however focus moves into
     * it inside a timeout which can give browsers a chance to fire off a keyboard event in-between
     * that can scroll the page (see #24969). Always block default actions of arrow keys for the
     * entire overlay so the page doesn't get scrolled by accident.
     */
    overlayRef.keydownEvents()
      .pipe(
        filter(event => ['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'PageUp', 'PageDown'].includes(event.key)),
      )
      .subscribe(event => event.preventDefault());

    this.componentRef = overlayRef.attach(portal);
    this.setOverlayInputs(this.componentRef);

    // Update the position once the calendar has rendered. Only relevant in dropdown mode.
    if (!isDialog) {
      afterNextRender(
        () => {
          overlayRef.updatePosition();
        },
        { injector: this.injector },
      );
    }
  }

  /** Destroys the current overlay. */
  private destroyOverlay(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
      this.componentRef = null;
    }
  }

  /** Gets a position strategy that will open the calendar as a dropdown. */
  private getDialogStrategy(): PositionStrategy {
    return this.overlay.position().global().centerHorizontally().centerVertically();
  }

  /** Gets a position strategy that will open the calendar as a dropdown. */
  private getDropdownStrategy(): PositionStrategy {
    const strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.datepickerInput.getConnectedOverlayOrigin())
      .withTransformOriginOn('.pearl-datepicker-content')
      .withFlexibleDimensions(false)
      .withViewportMargin(8)
      .withLockedPosition();

    return this.setConnectedPositions(strategy);
  }

  /** Sets the positions of the datepicker in dropdown mode based on the current configuration. */
  private setConnectedPositions(strategy: FlexibleConnectedPositionStrategy): PositionStrategy {
    /*
     * All the positions used in the comments are based on the default values of xPosition and yPosition. This is
     * meant to help understand and validate the logic, but it will not necessarily match the actual positions.
     */
    const primaryX = this.xPosition; // start
    const secondaryX = primaryX === 'start' ? 'end' : 'start'; // end
    const primaryY = this.yPosition; // bottom
    const secondaryY = primaryY === 'top' ? 'bottom' : 'top'; // top

    return strategy.withPositions([
      // Overlay below input, aligned left
      {
        originX: primaryX,
        originY: primaryY,
        overlayX: primaryX,
        overlayY: secondaryY,
      },
      // Overlay below input, aligned right
      {
        originX: secondaryX,
        originY: primaryY,
        overlayX: secondaryX,
        overlayY: secondaryY,
      },
      // Overlay above input, aligned left
      {
        originX: primaryX,
        originY: secondaryY,
        overlayX: primaryX,
        overlayY: primaryY,
      },
      // Overlay above input, aligned right
      {
        originX: secondaryX,
        originY: secondaryY,
        overlayX: secondaryX,
        overlayY: primaryY,
      },
      // Overlay on the right of input, aligned top
      {
        originX: secondaryX,
        originY: secondaryY,
        overlayX: primaryX,
        overlayY: secondaryY,
      },
      // Overlay on the left of input, aligned top
      {
        originX: primaryX,
        originY: secondaryY,
        overlayX: secondaryX,
        overlayY: secondaryY,
      },
    ]);
  }

  /** Gets an observable that will emit when the overlay is supposed to be closed. */
  private getCloseStream(overlayRef: OverlayRef): Observable<UIEvent | void> {
    const ctrlShiftMetaModifiers: ModifierKey[] = ['ctrlKey', 'shiftKey', 'metaKey'];

    return merge(
      overlayRef.backdropClick(),
      overlayRef.detachments(),
      overlayRef.keydownEvents().pipe(
        // Close on Escape & Alt + Arrow up
        filter(event =>
          (event.key === 'Escape' && !hasModifierKey(event))
          // Closing on alt + up is only valid when there's an input associated with the datepicker.
          || (this.datepickerInput && hasModifierKey(event, 'altKey')
            && event.key === 'ArrowUp' && ctrlShiftMetaModifiers.every(
              (modifier: ModifierKey) => !hasModifierKey(event, modifier),
            ))
        ),
      ),
    );
  }

  private checkInputsConsistency(): void {
    if (!isTimePrecision(this.precision) && (this.timeOnly || this.withTimezone)) {
      console.error(`Inconsistent settings on Pearl Datepicker:
timeOnly and withTimezone make sense for time only, they must be false in ${this.precision} precision.`);
    }
  }
}
