@let multiple = field().filterType !== "single";
@let isEntity = field().filterType === "entity";
@let showSelectAll = multiple && !isEntity && !hideSelectAll();

<pearl-autocomplete
  cdk-scrollable
  #autocomplete
  [clearable]="true"
  [hasLabel]="!!field().title"
  [small]="small()"
  [options]="values()"
  [disabled]="values().length === 0 || disabled()"
  [readonly]="false"
  [label]="field().title"
  (resetSelect)="setDefault()"
  [showBlankOption]="!!field().hasNullValue"
  [multiple]="multiple"
  [selectedValues]="values().length > 0 && $autocomplete().pearlOptions().length > 0 ? defaultValues() : []"
  [sortFn]="sort()"
  (optionSelected)="filterChange([$event])"
  (optionsSelected)="filterChange($event)"
  [showSelectAll]="showSelectAll" />
