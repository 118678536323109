import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';

import { PearlButtonComponent } from '../../buttons/pearl-button.component';
import { PearlMenuComponent } from '../../menu/pearl-menu.component';
import { PearlMenuTriggerForDirective } from '../../menu/pearl-menu-trigger-for.directive';
import { SORTED_ACTIONS, TooltipAction } from './pearl-tooltip-actions.types';

@Component({
  selector: 'pearl-tooltip-actions',
  standalone: true,
  imports: [
    MatTooltipModule,
    PearlButtonComponent,
    PearlMenuComponent,
    PearlMenuTriggerForDirective,
  ],
  templateUrl: './pearl-tooltip-actions.component.html',
  styleUrl: './pearl-tooltip-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PearlTooltipActionsComponent {
  readonly MAX_VISIBLE_ACTIONS = 5;

  public readonly items = input<TooltipAction[]>([]);

  public readonly closeMenuHandler = input<() => void>(() => {/* no-op */});

  /** Only actions that have an icon, without repetition, sorted by component's logic */
  public readonly regularActions = computed<TooltipAction[]>(() => {
    // Count how many occurrences of each icon (type is no more reliable alone)
    const representedIcons = this.items()
      .map(action => action.leadingIcon.icon)
      .reduce((acc, type) => ({ ...acc, [type]: (acc[type] as number | undefined ?? 0) + 1 }), {});

    return this.items()
      .filter(action => action.leadingIcon.icon && representedIcons[action.leadingIcon.icon] === 1)
      .sort(
        (a, b) => {
          if (!a.leadingIcon.icon || !a.leadingIcon.icon) return 0;
          return SORTED_ACTIONS.indexOf(a.leadingIcon.icon) - SORTED_ACTIONS.indexOf(b.leadingIcon.icon);
        },
      );
  });

  /** Extract action that are not defined through a type OR a typw which is represented more than once */
  public readonly customActions = computed(() =>
    this.items().filter(action => !this.regularActions().includes(action))
  );

  /** Does there is any custom action in the list to display */
  public readonly hasCustomActions = computed(() => !!this.customActions().length);

  /** Count how many action we should display */
  public readonly nbVisibleActions = computed(() =>
    Math.min(this.regularActions().length, this.MAX_VISIBLE_ACTIONS - (this.hasCustomActions() ? 1 : 0))
  );

  /** Provide list of the visible actions  */
  public readonly visibleSortedActions = computed(() => this.regularActions().slice(0, this.nbVisibleActions()));

  /** All actions that couldn't fit the main row, plus custom action that shouldn't be presented in main row */
  public readonly otherSortedActions = computed<TooltipAction[]>(
    () =>
      [
        ...(this.nbVisibleActions() !== this.regularActions().length
          ? this.regularActions().slice(this.nbVisibleActions())
          : []),
        ...this.customActions(),
      ].map((action) => {
        if (!action.leadingIcon.icon) {
          delete action.leadingIcon;
        }
        return action;
      }),
  );
}
