import { ChangeDetectionStrategy, Component, computed, input, output } from '@angular/core';

import { Dayjs } from 'dayjs';

import { PearlButtonComponent } from '../buttons/pearl-button.component';
import { PearlIconComponent } from '../icons/pearl-icon.component';
import { DatePickerControls, DatepickerView, DatetimePrecision, isCalendarView,
  isTimePrecision } from './datepicker-types';
import { BUTTON_LABEL_FORMATS } from './datepicker-formats';

@Component({
  selector: 'pearl-datepicker-header',
  templateUrl: './datepicker-header.html',
  styleUrl: './datepicker-header.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PearlButtonComponent, PearlIconComponent],
  host: {
    '[class.calendar-view]': 'isCalendarView()',
  },
})
export class PearlDatepickerHeaderComponent {
  public readonly activeDate = input<Dayjs>();
  public readonly activeView = input.required<DatepickerView>();
  public readonly dateHint = input<Dayjs>();

  public readonly precision = input.required<DatetimePrecision>();
  public readonly timeOnly = input.required<boolean>();
  public readonly withTimezone = input.required<boolean>();

  public readonly viewSelected = output<DatepickerView>();
  public readonly controlClicked = output<DatePickerControls>();

  protected readonly isCalendarView = computed(() => isCalendarView(this.activeView()));

  // Hide or show buttons depending on datepicker precision, withTimezone, timeOnly and current view
  protected readonly showTimeButton = computed<boolean>(() => isTimePrecision(this.precision()));
  protected readonly showDateButton = computed<boolean>(() =>
    (this.showTimeButton() && !this.timeOnly()) || this.precision() === 'day'
  );
  protected readonly showYearButton = computed<boolean>(() => this.showDateButton() || this.precision() === 'month');
  protected readonly showTimezoneButton = computed<boolean>(() => this.withTimezone() && !this.isCalendarView());
  protected readonly showControlButtons = computed<boolean>(() => this.isCalendarView());

  protected readonly yearLabel = computed<string>(() => this.formatLabel('year'));
  protected readonly dateLabel = computed<string>(() => this.formatLabel('date'));
  protected readonly hourLabel = computed<string>(() => this.formatLabel('time'));
  protected readonly timezoneLabel = computed<string>(() => this.formatLabel('timezone'));

  private formatLabel(labelType: 'year' | 'date' | 'time' | 'timezone'): string {
    const label = this.activeDate()?.format(BUTTON_LABEL_FORMATS[labelType])
      ?? this.dateHint()?.format(BUTTON_LABEL_FORMATS.hint[labelType]);

    if (!label) {
      return BUTTON_LABEL_FORMATS.placeholder[labelType];
    }

    // Special post-processing for timezone label to remove some 0's
    if (labelType === 'timezone') {
      return label.replace(/0?(\d{1,2}):00$/, '$1');
    }

    return label;
  }
}
