export class StringHelper {
  // Regular expression to split by commas
  public static SPLIT_BY_COMMAS_REGEXP = /,(?![^(]*\))/;

  /**
   * Split string by commas only if they are not between parenthesis
   * This is useful when serialized array passed as URL paremeters may contain comas (like selected metrics)
   * @example
   *   - `"a,b(c,d),e"  =>  ["a", "b(c,d)", "e"]`
   *   - `"metric1,avg(metric2:groupby:key1,key2)"  =>  ["metric1", "avg(metric2:groupby:key1,key2)"]
   */
  public static splitByCommas(str: string): string[] {
    return str.toString().split(StringHelper.SPLIT_BY_COMMAS_REGEXP);
  }

  /**
   * Decamelizes a string with/without a custom separator (underscore by default).
   *
   * @param str String in camelcase
   * @param separator Separator for the new decamelized string.
   */
  public static decamelize(str: string, separator: string): string {
    separator = typeof separator === 'undefined' ? '_' : separator;

    return str
      .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
      .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
      .toLowerCase();
  }

  /**
   * Remove accents, replace special characters with a white space, toLowerCase and trim.
   * @param string
   * @returns cleaned string
   */
  public static cleanString(string: string): string {
    if (!string) {
      return '';
    }

    return string.toString()
      .normalize('NFD').replace(/\p{Diacritic}/gu, '')
      .replace(/[^a-zA-Z0-9_.]/g, ' ')
      .toLowerCase()
      .trim();
  }

  public static stringInfos(candidate: string): { onlyUpperCase: boolean; hasSpace: boolean; isCamelCase: boolean } {
    return {
      onlyUpperCase: /^[A-Z]+$/.test(candidate),
      hasSpace: /\s/.test(candidate),
      isCamelCase: /^[a-z]+(?:[A-Z]{1,2}[a-z]+)*$/.test(candidate),
    };
  }
}
