@for (action of visibleSortedActions(); track $index) {
  <pearl-button
    type="icon"
    size="small"
    [matTooltip]="action.label"
    matTooltipPosition="below"
    [icon]="action.leadingIcon.icon"
    (click)="action.onClick($event, action)">
    {{ action.label }}
  </pearl-button>
}

@if (otherSortedActions().length) {
  <div [style.margin-left]="!visibleSortedActions().length ? 'auto' : null">
    <pearl-button
      type="icon"
      size="small"
      class="more-action-button"
      icon="more"
      (click)="$event.stopPropagation()"
      [pearlMenuTriggerFor]="otherActionsMenu">
    </pearl-button>
    <pearl-menu
      #otherActionsMenu
      compact
      (close)="closeMenuHandler()()"
      [panelWidth]="200"
      [categories]="[{ items: otherSortedActions() }]" />
  </div>
}
