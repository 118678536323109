import { Component, Injector, ViewEncapsulation, input, output } from '@angular/core';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { ActionEvent, Button, ComparisonData, FieldSettings, KpiData, KpiValue } from '../../helpers/types';
import { ProductAnalyticsService } from '../product-analytics/product-analytics.service';
import { getAdditionalData } from '../../helpers/data-helpers';
import { PearlKpiComponent } from '../pearl-components';
import { KpisComponentSettings } from '../../helpers/config-types';
import { DescriptionButtonComponent } from '..';

@Component({
  selector: 'kpis',
  templateUrl: 'kpis.html',
  styleUrls: ['kpis.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    NgClass,
    PearlKpiComponent,
    DescriptionButtonComponent,
  ],
})
export class KpisComponent {
  public componentSettings = input.required<KpisComponentSettings>();
  public data = input.required<KpiData | undefined>();
  public isDataLoading = input<boolean>(false);

  public onaction = output<ActionEvent>();

  private productAnalyticsService: ProductAnalyticsService;

  constructor(injector: Injector) {
    this.productAnalyticsService = injector.get(ProductAnalyticsService);
  }

  public getValue(field: FieldSettings): KpiValue | undefined {
    return this.data()?.[field.id];
  }

  public getComparisonData(field: FieldSettings): ComparisonData<KpiValue> | undefined {
    return this.data() && getAdditionalData(this.data(), field.id)?.comparison;
  }

  public getTargetValue(field: FieldSettings): KpiValue | undefined {
    return this.data() && getAdditionalData(this.data(), field.id)?.targetValue;
  }

  public onFieldButton(actionEvent: ActionEvent): void {
    this.onaction.emit(actionEvent);
    const kpiId = (actionEvent.action as Button).title;
    this.productAnalyticsService.trackAction('clickOnKpiViewed', { kpiId });
  }
}
