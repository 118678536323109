import { ChangeDetectionStrategy, Component } from '@angular/core';

import { PearlDatepickerBase } from './datepicker-base';

/**
 * Component responsible for managing the datepicker popup/dialog.
 *
 * This is a temporary implementation of the Pearl Datetime Picker. Use this component only if you know what you are
 * doing. If you need a Datepicker only, use MatDatepicker.
 * @link https://material.angular.io/components/datepicker/
 *
 * We use a component instead of a directive here so the user can use implicit
 * template reference variables (e.g. #d vs #d="pearlDatepicker"). We can change this to a directive
 * if angular adds support for `exportAs: '$implicit'` on directives.
 */
@Component({
  selector: 'pearl-datepicker',
  template: '',
  exportAs: 'pearlDatepicker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
})
export class PearlDatepickerComponent extends PearlDatepickerBase {}
