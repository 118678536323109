import dayjs, { Dayjs } from 'dayjs';
import { DurationUnitType } from 'dayjs/plugin/duration';

import { DateTimezone, Duration, Era, IdentityItem, Interval, StringDuration, milliSecondInPeriod } from './types';
import { isValidRecentInterval } from './data-helpers';

export class DateHelper {
  public static readonly displayComparisonDateFormat = 'MMM DD, YY';
  private static _timezones: IdentityItem[] | null = null;

  public static defaultFormat: { [value: string]: string } = {
    hour: 'DD-MMM HH:00',
    day: 'DD-MMM-YYYY',
    week: 'DD-MMM-YYYY (ww)',
    month: 'MMM YYYY',
    quarter: '[Q]Q YYYY',
    year: 'YYYY',

    // modulo periods
    dayhour: 'HH:00',
    weekday: 'dddd',
    monthday: 'Do',
    yearmonth: 'MMMM',
  };

  public static excelFormats = ['YYYY-MM-DD HH:mm', 'YYYY-MM-DD', 'YYYY'];

  public static weekDays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
    'Sunday',
  ];

  private static keywordsDuration: { [value: string]: number } = {
    milliseconds: 1,
    millisecond: 1,
    ms: 1,

    seconds: milliSecondInPeriod.second,
    second: milliSecondInPeriod.second,
    sec: milliSecondInPeriod.second,
    s: milliSecondInPeriod.second,
    null: milliSecondInPeriod.second, // if no unit, second is assumed
    '': milliSecondInPeriod.second,

    minutes: milliSecondInPeriod.minute,
    minute: milliSecondInPeriod.minute,
    min: milliSecondInPeriod.minute,
    m: milliSecondInPeriod.minute,

    hours: milliSecondInPeriod.hour,
    hour: milliSecondInPeriod.hour,
    hr: milliSecondInPeriod.hour,
    h: milliSecondInPeriod.hour,

    days: milliSecondInPeriod.day,
    day: milliSecondInPeriod.day,
    d: milliSecondInPeriod.day,

    week: milliSecondInPeriod.isoWeek,
    weeks: milliSecondInPeriod.isoWeek,
    wk: milliSecondInPeriod.isoWeek,
    w: milliSecondInPeriod.isoWeek,

    months: milliSecondInPeriod.month,
    month: milliSecondInPeriod.month,

    quarter: milliSecondInPeriod.quarter,
    quarters: milliSecondInPeriod.quarter,

    years: milliSecondInPeriod.year,
    year: milliSecondInPeriod.year,
    yr: milliSecondInPeriod.year,
    y: milliSecondInPeriod.year,
  };

  // Match decimal notation, following by any number of spaces (not captured), followed by the key word
  private static durationRegex = /((?:\d+\.?\d*|\d*\.?\d+))\s*([\p{L}]*)/uig;

  /**
   * Deduce quarter from date
   *
   * @param timestamp date
   * @return quarter number and year
   */
  public static quarter(timestamp: number): string {
    const date = new Date(timestamp);
    return (Math.floor(date.getMonth() / 3) + 1) + ', ' + date.getFullYear();
  }

  public static isUtc(timezone: DateTimezone): boolean {
    return ['utc', 'UTC', 'none', 'dprDay'].indexOf(timezone) > -1;
  }

  /**
   *  Return true if timezone at given datetime has a 0 offset. Datetime is needed bc of DST (some timezones like
   *  Europe/London) can be UTC+0 part of the year and UTC+1 the other part.
   *  This function is necessary bc of a bug in Dayjs not properly handling timezones with a 0 offset. All datetimes
   *  with a UTC+0 offset should be set to UTC.
   */
  public static isUtcOffsetZero(datetime: Dayjs, timezone: string): boolean {
    if (this.isUtc(timezone)) return true;

    let datetimeInTimezone: Dayjs;
    if (timezone === 'local') {
      datetimeInTimezone = datetime.local();
    } else {
      datetimeInTimezone = datetime.tz(timezone);
    }
    return datetimeInTimezone.utcOffset() === 0;
  }

  /**
   * Format date & datetimes using DayJs
   * If timezone is set (and different from none, dprDay & local), append 'human' timezone
   *
   * @param  {number}       cell      Timestamp to format
   * @param  {string}       format    dayjs.js compatible format − default
   *                                  is equivalent to Angular default ('mediumDate')
   * @param  {DateTimezone} timezone  Timezone
   * @return {string}                 Formatted date
   */
  public static formatDatetime(cell: number, format: string = 'YYYY-MM-DD', timezone: DateTimezone = 'local'): string {
    /*
     * if the date is a  numerical timestamp we convert to local format, but if the date is already in a date
     * form (like 1970-01-01) we assume that this date has already been converted in a local format so we don't apply
     * local change
     */
    if (cell === null || cell === undefined) {
      return '';
    }

    // If the date is already formatted as a string, throw an error (should not happen)
    if (!this.isNumericalTimestamp(cell)) {
      console.error('formatDatetime() expects a timestamp, not a formatted date, please fix!');
      return '';
    }

    /*
     * Append timezone suffix if datetime & timezone is not 'none' (heavy charts)
     * Formats containing hours: H, h, lll, llll, LT, LTS, LLL, LLLL
     */
    const suffix = timezone !== 'none' && timezone !== 'dprDay'
      && (format.toLowerCase().includes('h') || format.toLowerCase().includes('lll') || format.includes('LT'));

    if (DateHelper.isUtc(timezone)) {
      return dayjs.utc(cell).format(format) + (suffix ? ' (UTC)' : '');
    } else if (timezone === 'local') {
      return dayjs.utc(cell).local().format(format);
    }

    // Custom timezone
    const name = timezone.split('/')[1];
    return dayjs.utc(cell).tz(timezone).format(format) + (suffix ? ' (' + name + ')' : '');
  }

  /**
   * If the date is a date and a not a datetime we assume that we don't want to convert it to a local date
   */
  public static formatDate(cell: number, format: string = 'YYYY-MM-DD'): string {
    return DateHelper.formatDatetime(cell, format, 'utc');
  }

  /**
   * Create dayJs instance from timestamp and timezone
   *
   * @param  {number}       timestamp  Timestamp
   * @param  {DateTimezone} timezone   User timezone
   * @return {Dayjs}                   DaysJS object
   */
  public static getDayjs(timestamp: number, timezone: DateTimezone): Dayjs {
    if (DateHelper.isUtc(timezone)) {
      return dayjs.utc(timestamp);
    } else if (timezone === 'local') {
      return dayjs(timestamp);
    }
    return dayjs.tz(timestamp, timezone);
  }

  /**
   * Create dayJs instance from formatted datetime, locale and timezone
   *
   * @param  {any}          input      Formatted datetime
   * @param  {string}       format     Datetime format
   * @param  {DateTimezone} timezone   User timezone
   * @return {Dayjs}                   DaysJS object
   */
  public static getDayjsFromDate(
    input?: string | number | Date | Dayjs,
    format?: string,
    locale?: string,
    timezone: DateTimezone = 'local',
  ): Dayjs {
    if (DateHelper.isUtc(timezone)) {
      return dayjs.utc(input, format);
    } else if (timezone === 'local') {
      return dayjs(input, format, locale);
    }
    return dayjs.tz(input, format, timezone);
  }

  /**
   * @param cell timestamp or date to test
   * return true if the cell is a numerical Timestamp (e.g. 1550506299) or false
   * if it's a string (e.g. 02/18/2019)
   */
  public static isNumericalTimestamp(cell: number): boolean {
    return !isNaN(cell) && isFinite(cell);
  }

  /**
   * Get timestamp of value
   * BEWARE : DO NOT mess with instances, capitals or anything type related
   */
  public static numericalValue(value: Dayjs | Date | number): number {
    if (dayjs.isDayjs(value)) {
      return value.valueOf();
    } else if (value instanceof Date) {
      return value.getTime();
    } else if (typeof value === 'number') {
      return value;
    }
  }

  public static weekdays(): string[] {
    const weekdays = dayjs.weekdays();
    weekdays.push(weekdays.shift());

    return weekdays;
  }

  /**
   * Extract relative time period from era
   */
  public static period(
    periodParams: { era: Era; refPeriod?: Interval },
  ): Interval {
    let ts: Dayjs[];
    const now = dayjs.utc();
    const { era, refPeriod } = periodParams;

    switch (era.type) {
      /**
       * For instance era.scale can take value week. In which case we want
       * to return the start of the week.
       */
      case 'current':
        ts = [now.startOf(era.scale), now.endOf(era.scale)];
        break;

      /**
       * We take current scale remove one. Then we take the start of the scale & end of the scale as delimiter
       * For instance if era.scale is week, we take the start of the previous week, and the end of the previous week
       */
      case 'previous':
        if (era.extent) console.error(`Error in ${era.title}. Extent should not be defined for type: previous`);
        ts = [now.subtract(1, era.scale as any).startOf(era.scale), now.subtract(1, era.scale as any).endOf(era.scale)];
        break;

      /**
       * If a ref period is given, we are in shift mode. We shift provided interval by the extent of the Era
       */
      case 'previousExtent': {
        if (!refPeriod || !isValidRecentInterval(refPeriod)) {
          throw new Error('For previousExtent era type, refPeriod should be defined and a valid Interval');
        }
        const duration = era.isPreviousEra
          ? refPeriod[1] - refPeriod[0]
          : dayjs.duration(era.extent, era.scale as any).asMilliseconds();
        const beginning = refPeriod[0] - duration;
        const end = refPeriod[1] - duration - 1;
        return [beginning, end];
      }

      /**
       * Future is implemented as symmetrical to past. Implementation is a bit simpler though, as we chose not to
       * introduce relativeTo for the sake of simplicity, as basically, we have very few cases of future periods.
       * If needed one day, we can introduce relativeTo with values like "start_of_tomorrow_utc",
       * "start_of_next_month_utc"...
       * For now it behaves as if there was only "now_utc"
       */
      case 'future':
        if (era.futureExtent) {
          console.error(`Error in ${era.title}. futureExtent should not be defined for type: future`);
        }
        ts = [now, now.add(era.extent, era.scale as any)];
        break;

      /**
       * Get interval based on relativeTo (end_of_month_utc, end_of_today_utc, end_of_yesterday_utc, now_utc),
       * and extent (number) and scale (day, week, month, year)
       */
      case 'past':
        if (era.pastExtent) {
          console.error(`Error in ${era.title}. pastExtent should not be defined for type: past`);
        }
        ts = this.getPastIntervalBasedOnRelativeTo(era, now);
        break;

      /**
       * Asymmetrical on past and future extent. This era is used to show forecasted data and past data at the same
       * time
       */
      case 'pastAndFuture':
        ts = [now.subtract(era.pastExtent, era.scale as any), now.add(era.futureExtent, era.scale as any)];
        break;

      default:
        console.warn(`Unknown era type for ${era.title}`);
        document.location.assign(window.location.href.split('?')[0]);
        return [null, null];
    }

    // Era option, the filter is applied only on start date
    if (era.filterOnlyPast) {
      return [ts[0].valueOf(), null];
    }

    return ts.map(d => d.valueOf()) as Interval;
  }

  private static getPastIntervalBasedOnRelativeTo(era: Era, now: dayjs.Dayjs): Dayjs[] {
    let end: Dayjs;

    switch (era.relativeTo) {
      case 'end_of_month_utc':
        if (['hour', 'day', 'week'].includes(era.scale)) {
          console.error(`Error in ${era.title}. Scale ${era.scale} is not supported for relativeTo ${era.relativeTo}`);
        }
        end = now.endOf('month');
        break;

      case 'end_of_today_utc':
        end = now.endOf('day');
        break;

      case 'end_of_yesterday_utc':
        end = now.subtract(1, 'day').endOf('day');
        break;

      case 'now_utc':
        /**
         * Keep symmetry with end milliseconds ending in 59.999
         */
        end = now.endOf('minute');
        break;

      default:
        console.warn(`Unknown relativeTo for ${era.title}`);
        document.location.assign(window.location.href.split('?')[0]);
        return [now, now];
    }

    const start = end.subtract(era.extent, era.scale as any).add(1, 'millisecond');
    return [start, end];
  }

  /**
   * Will try to match current start / end to closest era
   * To do that, we compute the diff between start of era and provided start, same for end
   * add them, and check if the ratio of this sum vs the inspected interval is small enough
   * @returns the closest Era, of null if no match was found
   */
  public static selectCorrespondingEra(
    erasToInspect: Era[],
    startDate: Dayjs,
    endDate: Dayjs,
    acceptedRatio = 0.1,
  ): Era | null {
    let selectedEra = null;
    let minDiffRatio = Number.MAX_SAFE_INTEGER;
    erasToInspect.forEach(era => {
      // We cannot match previousExtent type, since we need a reference period
      if (era.type === 'previousExtent') return;
      const period = DateHelper.period({ era });
      const periodExtent = period[1] - period[0];
      const diff = Math.abs(dayjs.utc(period[0]).diff(startDate)) + Math.abs(dayjs.utc(period[1]).diff(endDate));
      const diffRatio = diff / periodExtent;
      if (diffRatio < acceptedRatio && diffRatio < minDiffRatio) {
        selectedEra = era;
        minDiffRatio = diffRatio;
      }
    });
    return selectedEra;
  }

  /**
   * This function adds the local timezone offset to a given timestamp.
   * It is mainly used because of a specific issue with Plotly described below.
   *
   * -- Plotly issue
   * There is a problem with plotly which is that plotly always renders timestamps with the local
   * timezone but we want most of our graphs to be rendered in UTC, this is why there is an option
   * 'timezone' which has been to graph defaulting to 'UTC' and that changes the timestamps so
   * that they are always rendered as UTC
   * To do that we take the offset of the local timezone and we add it to the timestamp given in argument
   *
   * Plotly Issue which is marked as NEEDS SPONSOR:
   *  - https://github.com/plotly/plotly.js/issues/3870
   *
   * There is more information in these to github issues:
   *  - https://github.com/plotly/plotly.js/issues/1956
   *  - https://github.com/plotly/plotly.js/issues/1532
   *
   * From the Plotly authors:
   *   To be precise (and yes, we're aware that this is a mess), Plotly ONLY shows dates in UTC
   *   (you'll notice every day on a plotly plot has 24 hours, for example),
   * but if you feed in native js Date objects or milliseconds to mean dates
   * (rather than date strings) we assume what you really wanted was the (UTC)
   * date with the same representation as the date you provided has in the local timezone.
   *
   * => Because we are using Timestamps everywhere plotly is showing them in local
   *
   * @param  {number}       ts        The timestamp we want to enforce in local timezone
   * @param  {DateTimezone} timezone  Component timezone
   * @param  {bool}         reverse   Applied reversed transformation (for intervals)
   * @return {number}                 Timestamp with offset
   */
  public static enforceLocalTz(ts: number, timezone: DateTimezone = 'utc', reverse: boolean = false): number {
    // Local: don't alter timestamp
    if (timezone === 'local') {
      return ts;
    }

    let offset = 0;

    /*
     * Substract local timezone offset (back to UTC)
     * `getTimezoneOffset()` returns the negative value, thus '+'
     */
    offset += (new Date(ts).getTimezoneOffset()) * 60 * 1000;

    // Apply user timezone offset
    if (!DateHelper.isUtc(timezone)) {
      const [hour, minutes] = dayjs(ts).tz(timezone).format('Z').split(':');
      offset += (parseInt(hour) * 60 + parseInt(minutes)) * 60 * 1000;
    }

    return reverse ? ts - offset : ts + offset;
  }

  public static utcFromString(date: string): dayjs.Dayjs {
    return dayjs.utc(date);
  }

  public static utcFromTimestamp(timestamp: number): dayjs.Dayjs {
    return dayjs(timestamp).utc();
  }

  /**
   * Create DayJS from string datetime
   *
   * @param  {string}       date       Date as as string
   * @param  {DateTimezone} timezone   Timezone code ('utc', 'local' or 'Region/Zone')
   * @return {Dayjs}                   DayJS day object with timezone set
   */
  public static dateInTz(date: string, timezone: DateTimezone): dayjs.Dayjs {
    switch (timezone) {
      case 'utc':
      case 'UTC':
      case 'none':
      case 'dprDay':
        return dayjs.utc(date);
      case 'local':
        return dayjs(date);
    }
    return dayjs.utc(date).tz(timezone);
  }

  public static isBetween(isoStart: string, isoEnd: string, checkedDate: string): boolean {
    const startDate = DateHelper.utcFromString(isoStart);
    const endDate = DateHelper.utcFromString(isoEnd);
    const dateValue = DateHelper.utcFromString(checkedDate);
    return dateValue.valueOf() <= endDate.valueOf() && dateValue.valueOf() >= startDate.valueOf();
  }

  public static isoInTimestamp(isoDate: string): number {
    return DateHelper.utcFromString(isoDate).valueOf();
  }

  public static nowIsoString(): string {
    return dayjs.utc().toISOString();
  }

  /**
   * Returns the number of milliseconds of the parsed duration, `null` if no duration could be parsed from the input
   *
   * @param strDuration: string representing the duration (e.g "5 days 6.5 months 37 seconds").
   * No unit is interpreted as second.
   * @param returnUnit: string representing the returned unit. Accepted keywords are the same as those for strDuration
   */
  public static parseDurationFromString(strDuration: string, returnUnit: string = 'ms'): number | null {
    if (!(returnUnit in this.keywordsDuration)) {
      console.error('Return unit not accepted.');
      return null;
    }
    if (strDuration === 'max') return Number.MAX_SAFE_INTEGER;

    let duration: number | null = null;
    [...strDuration.matchAll(this.durationRegex)].forEach(([_, num, unit]) => {
      unit = unit.toLowerCase();
      if (!(unit in this.keywordsDuration)) {
        console.warn(`Provided unit ${unit} does not exist. Ignoring.`);
        return;
      }
      const nbMsUnit = this.keywordsDuration[unit];
      duration = (duration ?? 0) + parseFloat(num) * nbMsUnit;
    });

    return duration === null ? null : duration / this.keywordsDuration[returnUnit];
  }

  /**
   * Returns unit and number parsed from a string (e.g. "5day" return {unit: 'day', n: 5})
   *
   * @param strDuration: string representing the duration (e.g "5day").
   */
  public static parseDurationUnitFromString(strDuration: StringDuration): Duration | null {
    let n = 1;
    let unit = strDuration as DurationUnitType;
    if (strDuration in this.keywordsDuration) {
      return { unit, n };
    } else {
      const matches = [...strDuration.matchAll(this.durationRegex)];
      if (!matches.length) {
        return null;
      }
      if (matches.length !== 1) {
        throw new Error('Trying to parse several units: ' + strDuration);
      }
      unit = matches[0][2].toLowerCase() as DurationUnitType;
      n = parseFloat(matches[0][1]);
      return { unit, n };
    }
  }

  /**
   * Return the start time of a given "time to live" window with respect to an optional referenceDayjs.
   * E.g. for a ttl of 1 hour, return now - 1h.
   * @param ttlStringDuration e.g. '1h' or '2 days'
   * @param referenceDayjs default to utc now, to be used with a serverTime e.g.
   */
  public static getTtlStartTime(ttlStringDuration: StringDuration, referenceDayjs: Dayjs = dayjs.utc()) {
    const { n, unit } = DateHelper.parseDurationUnitFromString(ttlStringDuration);
    return referenceDayjs.subtract(n, unit).valueOf();
  }

  /** Return true if date1 and date2 diff in absolute value is less than range */
  public static areDatesInSameRange(date1: number, date2: number, range: Duration): boolean {
    const { n, unit } = range;
    return Math.abs(dayjs.utc(date1).diff(dayjs.utc(date2), unit)) < n;
  }

  /**
   *  @desc Return true if both datetimes as UTC string are same date (without time consideration)
   */
  public static areSameDates(date1: string, date2: string): boolean {
    return date1.split('T')[0] === date2.split('T')[0];
  }

  /**
   * Get Official Timezones
   * Filter custom timezones, that are different for one web browser to another
   */
  public static getOfficialTimezones(): string[] {
    /**
     * Each Browser can have a different list of timezone
     * Filter the array of timezone and keep official timezone denominations.
     * Delete the other ones
     *
     * From Firefox, delete:
     * - prefixed Etc/ timezones
     * - timezones that are not 'Country/City' format. example: WET, CET, etc. including UTC
     *
     * Then add 'UTC' since:
     * - it is not in supported values for Chromium-based browsers
     * - we just deleted it for other browsers
     */
    return Intl.supportedValuesOf('timeZone')
      .filter(tz => tz.includes('/') && !tz.includes('Etc/'))
      .concat('UTC');
  }

  /**
   * @param timezone the timezone identifier
   * @param withPrefix Prefix value, ex.: UTC, GMT
   * @param specificDate date at which to compute the offset (useful for tz with DST)
   * @returns the UTC value of the timezone, ex. : +1, -5:30, GMT-1:30, UTC+10, etc...
   */
  public static getOffsetFromTimezone(
    timezone: string,
    withPrefix: string = '',
    specificDate: dayjs.Dayjs = dayjs(),
  ): string {
    /** Check timezone validity */
    try {
      // throws an error if timezone is not valid
      Intl.DateTimeFormat(undefined, { timeZone: timezone });
    } catch (ex) {
      return '';
    }

    const nowTimezone = specificDate.tz(timezone);

    // If offset is negative, '-' will be added, otherwise '+' will be added
    const offsetSign = nowTimezone.utcOffset() < 0 ? '-' : '+';

    const offsetHours = Math.floor(Math.abs(nowTimezone.utcOffset()) / 60);
    const minutesRemainder = Math.abs(nowTimezone.utcOffset()) / 60 % 1;
    /** Calc minutes from remainder */
    const offsetMinutes = minutesRemainder ? `:${minutesRemainder * 60}` : '';

    return withPrefix.concat(
      nowTimezone.utcOffset()
        ? `${offsetSign}${offsetHours}${offsetMinutes}`
        : '+0',
    );
  }

  /**
   * Get Official timezones as IdentityItem objects
   * Exclude the ones specified.
   *
   * @param excludeItems Timezone Ids to exclude from the list returned
   * @returns
   */
  public static getIdentityItemOfficialTimezones(excludeItems: IdentityItem[] = []): IdentityItem[] {
    // Get official timezones
    if (!this._timezones) {
      const timezones = DateHelper.getOfficialTimezones();
      // Build as IdentityItem
      this._timezones = timezones
        .map(this.getIdentityItemTimezone)
        .filter((timezone: IdentityItem) => excludeItems && !excludeItems.includes(timezone.id));
    }

    return this._timezones;
  }

  /**
   * Get a single timezone as IdentityItem object
   *
   * @param code  The code of the timezone
   * @returns     The IdentityItem for the timezone
   */
  public static getIdentityItemTimezone(code: string): IdentityItem {
    return {
      id: code,
      title: code,
      subtitle: DateHelper.getOffsetFromTimezone(code, 'UTC'),
    };
  }

  public static erasAreEqual(era1: Era, era2: Era): boolean {
    return era1.extent === era2.extent && era1.scale === era2.scale && era1.type === era2.type;
  }

  public static getDiffDateMs(endDate: string, startDate: string): number {
    return dayjs(endDate).diff(dayjs(startDate));
  }

  public static getDiffDateHours(endDate: string, startDate: string): number {
    return DateHelper.getDiffDateMs(endDate, startDate) / 3_600_000;
  }

  /**
   * Returns true if two dates are in the same day in a defined timezone
   *
   * @param datetime1  First datetime in UNIX TIMESTAMP
   * @param datetime2  Second datetime in UNIX TIMESTAMP
   * @param timezone  The code of the timezone
   * @returns boolean
   */
  public static isSameDayInTimezone(datetime1: number, datetime2: number, timezone: string): boolean {
    const day1 = DateHelper.getDayjs(datetime1, timezone);
    const day2 = DateHelper.getDayjs(datetime2, timezone);

    return day2.isSame(day1, 'day');
  }
}
