<a (click)="dialogRef.close()" class="close-button">
  <pearl-icon icon="close" [size]="24" />
</a>
<h3 mat-dialog-title>{{ config.title }}</h3>
<div mat-dialog-content>
  <ng-container *ngIf="config.text">
    <p *ngFor="let line of config.text.split('\n')">{{ line }}</p>
  </ng-container>
  <ng-container *ngIf="config.json">
    <pre>{{ config.json | json }}</pre>
  </ng-container>
</div>

<mat-dialog-actions>
  <ng-container *ngFor="let button of config.buttons">
    <a [href]="getButtonLink(button)" mat-button mat-dialog-close (click)="onclick($event, button)">
      <pearl-icon [icon]="button.icon" [size]="24" />
      {{ button.title }}
    </a>
  </ng-container>
</mat-dialog-actions>
