import { Component, computed, input } from '@angular/core';
import { NgStyle } from '@angular/common';

import { PearlButtonComponent } from '../buttons/pearl-button.component';
import { PearlIconComponent } from '../icons/pearl-icon.component';
import { PearlMenuComponent, PearlMenuItem } from '../menu/pearl-menu.component';
import { PearlIcon } from '../icons/icons';
import { PearlMenuTriggerForDirective } from '../menu/pearl-menu-trigger-for.directive';
import { HeroSectionDisplayMode, PearlHeroSectionStatusOptions } from './pearl-hero-section.types';

@Component({
  selector: 'pearl-hero-section',
  standalone: true,
  host: {
    '[class.compact]': 'compact()',
  },
  imports: [
    NgStyle,
    PearlButtonComponent,
    PearlMenuComponent,
    PearlMenuTriggerForDirective,
    PearlIconComponent,
  ],
  templateUrl: './pearl-hero-section.component.html',
  styleUrl: './pearl-hero-section.component.scss',
})
export class PearlHeroSectionComponent {
  public readonly icon = input<PearlIcon>();

  public readonly title = input.required<string>();

  public readonly subtitle = input<string>();

  public readonly quickNavListItems = input<PearlMenuItem[]>([]);

  public readonly status = input<PearlHeroSectionStatusOptions | null>();

  public readonly displayMode = input<HeroSectionDisplayMode>('expanded');

  protected readonly compact = computed(() => this.displayMode() !== 'expanded');
}
