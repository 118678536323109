import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injector, Input,
  Output } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgClass, NgFor, NgIf } from '@angular/common';

import { groupBy } from 'lodash-es';

import { Geometry } from '../helpers/types';
import { DataLoader } from '../data-loader/data-loader';
import { DateHelper } from '../helpers/date-helper';
import { RefDataProvider, getChained } from '../data-loader/ref-data-provider';
import { DataPoint } from '../data-loader/data-loader.types';

export interface VesselEta {
  id: number;
  poiId: number;
  poiTitle: string;
  vesselId: number;
  eta: number;
  distance?: number;
  geometry: Geometry;
}

export interface VesselEtaViz extends VesselEta {
  vesselTitle: string;
  distance: number;
  distanceReadable: string;
  destination: string;
  etaFormatted: string;
  vesselTitleRowSpan: number;
}

export interface Poi {
  id: number;
  clusterId: number;
  poiId: number;
  point?: boolean;
  title: string;
  latitude: number;
  longitude: number;
}

export interface ShowEtaEvent {
  eta: VesselEtaViz;
  showVesselView: boolean;
  autozoom: boolean;
  clear: boolean;
}

@Component({
  selector: 'eta',
  templateUrl: 'eta.html',
  styleUrls: ['eta.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgIf,
    MatProgressSpinnerModule,
    NgFor,
    NgClass,
  ],
})
export class EtaComponent {
  // main panel background color on which legend colored elts will be displayed
  @Input()
  background: string;
  @Output()
  onEtaHighlight = new EventEmitter<ShowEtaEvent>();

  public showEta = false;

  private dataLoader: DataLoader;
  public vesselsEta: VesselEtaViz[];

  public selectedEta: VesselEtaViz;
  public loading: boolean = false;
  private cdRef: ChangeDetectorRef;

  constructor(injector: Injector) {
    this.dataLoader = injector.get(DataLoader);
    this.cdRef = injector.get(ChangeDetectorRef);
  }

  public showOrHide(showEta: boolean): void {
    /*
     * when we are asked to hide the ETA we will also notify the map
     * to remove the overlay
     */
    if (!showEta) {
      this.highlightEtaVessel(null, false);
    }
    this.showEta = showEta;
    this.cdRef.detectChanges();
  }

  public async updateEta(): Promise<number> {
    this.loading = true;
    this.cdRef.detectChanges();
    this.vesselsEta = [];
    const etas = await this.dataLoader.get<VesselEta[]>('/osv/routing/etas', { forceUpdate: true });

    const byVessel: { [vesselId: number]: VesselEta[] } = groupBy(etas, 'vesselId');

    for (const vid in byVessel) {
      const vesselId = parseInt(vid);
      const vessel = RefDataProvider.refData['vessel']?.[vesselId];

      if (!vessel) continue;

      const etas = byVessel[vesselId];
      const vesselTitleRowSpan = etas.length;

      /** For each ETA, build more info thanks to related Vessel */
      for (let i = 0; i < etas.length; i++) {
        const eta = etas[i];

        const finalLine = eta.geometry;
        const distanceToGo = eta.distance;

        const formattedEta = DateHelper.formatDatetime(eta.eta, 'HH:mm');
        const formattedDistance = (distanceToGo * 0.53996)?.toFixed(1);
        // inject ETA information into the ref dataset
        vessel['__eta-' + i] = `${eta.poiTitle} ${formattedEta} (${formattedDistance} NM)`;

        this.vesselsEta.push({
          ...eta,
          geometry: finalLine,
          destination: eta.poiTitle,
          distance: distanceToGo ?? null,
          distanceReadable: formattedDistance,
          vesselTitle: getChained(vessel as unknown as DataPoint, 'vessel.title'),
          etaFormatted: formattedEta,
          vesselTitleRowSpan: i === 0 ? vesselTitleRowSpan : 0,
        });
      }
    }
    /*
     * one of the etas was selected previously, user is looking at it
     * we will try to find it in the new set of ETAs, but do not autozoom
     */
    if (this.selectedEta) {
      const foundPreviouslySelected = this.vesselsEta.find(d =>
        d.vesselId === this.selectedEta.vesselId && d.poiId === this.selectedEta.poiId
      );
      if (foundPreviouslySelected) {
        this.highlightEtaVessel(foundPreviouslySelected, false);
      } else {
        this.highlightEtaVessel(null, false);
      }
    }
    this.loading = false;
    this.cdRef.detectChanges();
    return this.vesselsEta.length;
  }

  public requestEtaHighlight(vesselId: number): void {
    const foundEta = this.vesselsEta.find(d => d.vesselId === vesselId);
    if (foundEta) {
      this.selectedEta = foundEta;
      this.highlightEtaVessel(foundEta, true);
    }
  }

  public getRouteClass(eta: VesselEtaViz): string {
    if (eta === this.selectedEta) {
      return 'eta-route-highlighted';
    }

    return null;
  }

  public highlightEtaVessel(eta: VesselEtaViz, autozoom: boolean): void {
    this.selectedEta = eta;
    this.cdRef.detectChanges();

    const eventData: ShowEtaEvent = {
      eta: eta,
      showVesselView: false,
      autozoom: autozoom,
      clear: eta == null,
    };
    this.onEtaHighlight.emit(eventData);
  }
}
