import { AfterViewInit, Directive, ElementRef, inject } from '@angular/core';

@Directive({
  standalone: true,
  selector: '[removeParentNode]',
})
/**
 * @description This directive is employed to shift all child nodes from a specific parent to outside of the parent,
 * and subsequently delete the parent node itself.
 *
 * @example
 * ```html
 * <div removeParentNode>
 *  <span>Child 1</span>
 *  <span>Child 2</span>
 *  <span>Child 3</span>
 * </div>
 *
 * <!-- The above code will be rendered as -->
 * <span>Child 1</span>
 * <span>Child 2</span>
 * <span>Child 3</span>
 * ```
 */
export class RemoveParentNodeDirective implements AfterViewInit {
  private readonly el = inject(ElementRef);

  // wait for the component to render completely
  ngAfterViewInit(): void {
    const nativeElement = this.el.nativeElement as HTMLElement;
    const parentElement: HTMLElement = nativeElement.parentElement;

    // move all children out of the element
    while (nativeElement.firstChild) {
      parentElement.insertBefore(nativeElement.firstChild, nativeElement);
    }

    // remove the empty element(the host)
    parentElement.removeChild(nativeElement);
  }
}
