import { Subject } from 'rxjs';

import { NumOrString, SpecName, VesselDataRaw } from '../helpers/types';
import { DataPoint } from './data-loader.types';
import { RefDataConfig, RefDataset, RefDatasetItem, RefDatasets, isSimpleRefData } from './ref-data.types';
import { Config } from '../config/config';
import { DataLoader } from './data-loader';
import { StringHelper } from '../helpers/string-helper';
import { NavigationHelper } from '../helpers/navigation-helper';
import { ErrorWithFingerprint } from '../helpers/sentry.helper';

export class RefDataProvider {
  public static get LAZY_DATASET_CONFIG(): RefDataConfig[] {
    return this.config.appConfig.refDataConfig.filter(refDataConfig => !refDataConfig.loadAtInit);
  }

  /**
   * Mapping of asked dataset to actual dataset, i.e "currentCountry.title" will actually look in "country.title",
   * but will use "currentCountryId" as the key from the provided data point
   */
  private static readonly REF_DATA_MAPPINGS: { [datasetName: string]: string } = {
    currentCountry: 'country',
    vesselManager: 'manager',
  };

  /**
   * To improve perf, we're mapping:
   * - dataset names of keys with chaining;
   * - keys extracted from coalesced keys. (coalesced keys are keys with a `|` symbol)
   */
  private static readonly DATASET_NAMES_PER_CHAINED_KEY: Map<string, string[]> = new Map();
  private static readonly COALESCED_KEYS_PER_KEY: Map<string, string[]> = new Map();

  /**
   * This unique reference holds the referential data needed throughout the app.
   * Some of its datasets can be loaded by components on demand.
   */
  public static refData: RefDatasets = {};
  /** Contains promises for lazy-loaded datasets. Cleared as soon as the data arrives */
  public static loadingPromises: { [datasetName: string]: Promise<RefDataset> } = {};
  public static vesselDataLoaded: boolean = false;
  /** Will be all vessels for construction app, all rigs for spinrig */
  public static currentProjectVesselDataset: VesselDataRaw[];
  public static userVesselFleet: RefDataset = {};
  /** Set at init, will not change afterwards */
  public static config: Config;
  private static dataLoader: DataLoader;

  public static initialDataLoaded$ = new Subject();

  private static getLazyDatasetUrl(datasetName: string): string {
    return RefDataProvider.LAZY_DATASET_CONFIG.find(config => config.id === datasetName)?.url;
  }

  public static getRefDataConfigFromEntityName(entityName: string): RefDataConfig {
    for (const conf of this.config.appConfig.refDataConfig) {
      if (!isSimpleRefData(conf) && conf.dbEntityName === entityName && conf.id in RefDataProvider.refData) return conf;
    }
  }

  public static async refreshRefDataItem(
    id: NumOrString,
    refDataConfig: RefDataConfig,
  ): Promise<RefDatasetItem<number>> {
    const idProp = RefDataProvider.getKeyForDataset(refDataConfig.id);
    const url = NavigationHelper.addParameterToUrl(refDataConfig.url, idProp, id.toString());
    const newItem = (await this.dataLoader.get<RefDataset>(url, { forceUpdate: true }))[id] as RefDatasetItem<number>;
    RefDataProvider.refData[refDataConfig.id][id] = newItem;
    return newItem;
  }

  /** Directly get a local item. Beware! The caller should be sure that data has been loaded. */
  public static getLocalItemIfLoaded(datasetName: string, id: NumOrString): RefDatasetItem {
    return RefDataProvider.refData[datasetName]?.[id];
  }

  /** Empty ref data, and reload essential ref datasets*/
  public static resetRefData(): Promise<void> {
    RefDataProvider.refData = {};
    return RefDataProvider.loadInitialData(this.dataLoader);
  }

  public static getEagerRefDataPromises(): Promise<void>[] {
    return RefDataProvider.config.appConfig.refDataConfig.filter(conf => {
      /** Exclude simple-ref-data, as it's loaded in a special method*/
      if (isSimpleRefData(conf)) return false;
      return conf.loadAtInit;
    }).map(async conf => {
      const results = await RefDataProvider.dataLoader.get<RefDataset>(conf.url);
      RefDataProvider.refData[conf.id] = results;
    });
  }

  public static async loadInitialData(dataLoader: DataLoader): Promise<void> {
    RefDataProvider.dataLoader = dataLoader;
    /** "Simple" refData, essentially id/title pairs */
    await RefDataProvider.loadSimpleRefData();
    await Promise.all(RefDataProvider.getEagerRefDataPromises());
    if (RefDataProvider.config.product !== 'osv' && ('vessel' in RefDataProvider.refData)) {
      const vesselFleet = await RefDataProvider.dataLoader.get<number[]>('/base/filters/user-fleet-specs');
      RefDataProvider.restrictCurrentFleet(vesselFleet);
    }

    /*
     * If the current project match loaded ref data (spinrig, construction)
     * we will construct the current vessel dataset
     */
    if (RefDataProvider.config.defaultRefDatasetName in RefDataProvider.refData) {
      RefDataProvider.currentProjectVesselDataset = Object.values(
        RefDataProvider.refData[RefDataProvider.config.defaultRefDatasetName],
      ) as unknown as VesselDataRaw[];
    }
    RefDataProvider.vesselDataLoaded = true;
    RefDataProvider.initialDataLoaded$.next(true);
  }

  /**
   * Load referential data from the ref. data endpoint.
   * Stores the results in `refDataProvider.refData` static object
   */
  public static async loadSimpleRefData(): Promise<void> {
    const simpleRefDataDef = RefDataProvider.config.appConfig.refDataConfig.find(isSimpleRefData);
    if (!simpleRefDataDef) return;
    const refDataFromServer = await RefDataProvider.dataLoader.get<RefDatasets>(simpleRefDataDef.url).catch(() => {
      console.info('Error while getting ref data');
      return {};
    });
    for (const refItemId in refDataFromServer) {
      RefDataProvider.refData[refItemId] = refDataFromServer[refItemId] as RefDataset;
    }
  }

  /** Lazy load a dataset. */
  public static async loadRefDataset(datasetName: string): Promise<RefDataset> {
    if (RefDataProvider.refData[datasetName]) {
      /* Dataset has already been loaded, return a directly resolved promise. */
      return Promise.resolve(RefDataProvider.refData[datasetName]);
    }

    /** We are already loading the asked dataset: return the Promise in charge of loading it */
    if (RefDataProvider.loadingPromises[datasetName]) {
      return RefDataProvider.loadingPromises[datasetName];
    }

    const datasetUrl = RefDataProvider.getLazyDatasetUrl(datasetName);
    if (!datasetUrl) {
      console.error(`Trying to load dataset "${datasetName}", but it does not have a configured URL.`);
      return Promise.resolve({});
    }

    /* Load local dataset if Promise is not set. */
    RefDataProvider.loadingPromises[datasetName] = RefDataProvider.loadAndStoreLocalDataset(datasetName, datasetUrl);
    return RefDataProvider.loadingPromises[datasetName];
  }

  private static loadAndStoreLocalDataset(datasetName: string, url: string): Promise<RefDataset> {
    return RefDataProvider.dataLoader.get<RefDataset>(url)
      .then(data => {
        RefDataProvider.refData[datasetName] = data;
        delete RefDataProvider.loadingPromises[datasetName];
        return data;
      });
  }

  /**
   * By default we return current user vessel fleet
   * Except if we asked for a specific dataset
   */
  public static getVesselDataSet(specificDataset?: SpecName): any[] {
    /*
     * We return full project vessel dataset if the specific dataset asked is the fullDataset
     * or if the current project doesn't have user vessel fleet (e.g spinrig)
     */
    if (!specificDataset && !RefDataProvider.config.vesselFleetVisible) {
      return RefDataProvider.currentProjectVesselDataset;
    }
    if (specificDataset) {
      return Object.values(RefDataProvider.refData[specificDataset]);
    }
    return Object.values(RefDataProvider.userVesselFleet);
  }

  public static restrictCurrentFleet(fleet: readonly number[]): void {
    RefDataProvider.userVesselFleet = {};
    for (const vesselId of fleet) {
      RefDataProvider.userVesselFleet[vesselId] = RefDataProvider.refData['vessel'][vesselId];
    }
  }

  /**
   * Returns an array of dataset names, or an empty array if the provided string is not a chained notation access
   * i.e 'vessel.country.title' returns ['vessel', 'country'] (if discardAccessedProperty is true),
   * but 'vesselId' returns []
   */
  private static _getChainedDatasets(splitted: string[], discardAccessedProperty): string[] {
    if (splitted.length < 2) {
      return [];
    }
    /** We don't care about the last element, as it's the key used to retrieve data */
    const datasetPart = splitted.slice(0, splitted.length - 1);
    if (!datasetPart.every(s => RefDataProvider.isValidDatasetName(s))) return [];
    if (!RefDataProvider.isValidAccessedProperty(splitted[splitted.length - 1])) return [];
    if (discardAccessedProperty) return datasetPart;
    return splitted;
  }

  public static isValidAccessedProperty(accessedProperty: string): boolean {
    const { onlyUpperCase, hasSpace } = StringHelper.stringInfos(accessedProperty);
    return !onlyUpperCase && !hasSpace && accessedProperty !== 'py' /** Monitoring app exception */;
  }

  /** Dataset names are alpha ascii only, written in camelCase */
  public static isValidDatasetName(potentialDatasetName: string): boolean {
    return StringHelper.stringInfos(potentialDatasetName).isCamelCase;
  }

  public static getChainedDatasets(key: string, discardAccessedProperty = true): string[] {
    if (key == null) return undefined;

    const chainedExpressions = RefDataProvider.splitCoalescedKeys(key);
    return chainedExpressions.flatMap(value =>
      RefDataProvider._getChainedDatasets(value.split('.'), discardAccessedProperty)
    );
  }

  /** From e.g. "vessel.title|managerAwards" return ["vessel.title", "managerAwards"] */
  private static splitCoalescedKeys(key: string): string[] {
    /** We still want to handle numeric keys */
    if (typeof key !== 'string') return [key];
    return key.split('|');
  }

  public static getKeyForDataset(key: string): string {
    return `${key}Id`;
  }

  /**
   * Get chained data from provided data and path.
   * For a given key (i.e 'vessel'), look inside the data object containing to get the corresponding instance id of the
   * ref dataset (i.e 'vesselId'). Also check that the asked dataset exists.
   * A mapping exists to change the reference of the asked dataset:  for instance, 'currentCountry' will look at
   * 'currentCountryId', but will lookup the 'country' dataset.
   * A recursive call is made to handle any depth of chaining, although it will most often be only 1 level.
   */
  private static _getChained(data: DataPoint, path: readonly string[], pathIndex = 0): unknown {
    const askedKey = path[pathIndex];
    /** No more key to search, asked key is in dataset */
    if (pathIndex === path.length - 1) {
      /** Could happen when we look for data that is defined on only a part of the specs */
      return data[askedKey];
    }
    const askedDataset = RefDataProvider.REF_DATA_MAPPINGS[askedKey] ?? askedKey;
    if (!(askedDataset in RefDataProvider.refData)) {
      console.error(
        new ErrorWithFingerprint(`Asked ref dataset ${askedKey}, but it does not exist.`, [
          'chained-ref-data-does-not-exist',
          askedKey,
        ]),
      );
      return undefined;
    }
    const keyForDatasetStr = RefDataProvider.getKeyForDataset(askedKey);
    /** Could happen when only parts of the input data contains an ID to a ref dataset */
    if (!(keyForDatasetStr in data)) {
      return undefined;
    }
    const datasetKey = data[keyForDatasetStr] as NumOrString;
    /** Could happen when referencing a non-existing key in the ref dataset */
    if (!(datasetKey in RefDataProvider.refData[askedDataset])) {
      return undefined;
    }
    return RefDataProvider._getChained(
      RefDataProvider.refData[askedDataset][datasetKey] as DataPoint,
      path,
      pathIndex + 1,
    );
  }

  /**
   * @param data The data to get data from, typically an Object
   * @param key The property to look for in provided data. This can be a simple property (i.e 'vesselId'), or a chained
   * access using dot notation (i.e 'vessel.mainPurpose')
   * @returns If provided key in object, return the corresponding value. If the key is a chained access and the chain is
   * valid, will get data from the corresponding dataset. If any other case, returns undefined.
   */
  public static getChainedSingle<T = unknown>(data: DataPoint, key: string): T {
    if (key in data) return data[key] as T;
    if (typeof key !== 'string') return undefined;

    let splitted = RefDataProvider.DATASET_NAMES_PER_CHAINED_KEY.get(key);
    if (!splitted) {
      splitted = RefDataProvider._getChainedDatasets(key.split('.'), false);
      RefDataProvider.DATASET_NAMES_PER_CHAINED_KEY.set(key, splitted);
    }

    /** 0 or 1 element means no dot in string or invalid dataset: key does not exist. */
    if (splitted.length < 2) {
      return undefined;
    }
    return RefDataProvider._getChained(data, splitted) as T;
  }

  /**
   * Wrapper around getChainedSingle to handle multiple keys
   * The only supported separator is '|', meaning COALESCE (get the first non-undefined value),
   * for instance, 'vessel|vessel.manager'.
   * If the string does not contain any special keyword, the behavior is the default one.
   */
  public static getChained<T = unknown>(data: DataPoint, specialSyntaxKey: string): T {
    if (specialSyntaxKey == null) return undefined;

    let keys = RefDataProvider.COALESCED_KEYS_PER_KEY.get(specialSyntaxKey);
    if (!keys) {
      keys = RefDataProvider.splitCoalescedKeys(specialSyntaxKey);
      RefDataProvider.COALESCED_KEYS_PER_KEY.set(specialSyntaxKey, keys);
    }

    for (const key of keys) {
      const value = RefDataProvider.getChainedSingle<T>(data, key);
      if (value !== undefined) return value;
    }
    return undefined;
  }
}

/** Alias to static method, for easier import / usage */
export const getChained = RefDataProvider.getChained;
