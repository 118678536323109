<div class="wti-selector-sidebar">
  <fieldset class="obligatory-fieldset">
    <div class="reset-filters-container">
      <pearl-button type="secondary" size="small" (click)="resetAllParameters()">Clear</pearl-button>
    </div>
    <legend>{{ obligatoryFieldset.title }}</legend>

    <!-- Monopile weight field -->
    <pearl-form-field
      [small]="true"
      [label]="monopileWeightField.title"
      [errorMessage]="getErrorMessage(monopileWeightField)"
      [hintError]="fieldRangeInvalid(monopileWeightField, obligatoryParameters.monopileWeight)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.monopileWeight"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="monopileWeightField.required" />
    </pearl-form-field>

    <!-- Monopile height field -->
    <pearl-form-field
      [small]="true"
      [label]="monopileHeightField.title"
      [errorMessage]="getErrorMessage(monopileHeightField)"
      [hintError]="fieldRangeInvalid(monopileHeightField, obligatoryParameters.monopileHeight)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.monopileHeight"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="monopileHeightField.required" />
    </pearl-form-field>

    <!-- Foundation upper radius -->
    <pearl-form-field
      [small]="true"
      [label]="foundationUpperRadiusField.title"
      [errorMessage]="getErrorMessage(foundationUpperRadiusField)"
      [hintError]="foundationInvalid(foundationUpperRadiusField, obligatoryParameters.foundationUpperRadius)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.foundationUpperRadius"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="foundationUpperRadiusField.required" />
    </pearl-form-field>

    <!-- Foundation bottom radius -->
    <pearl-form-field
      [small]="true"
      [label]="foundationBottomRadiusField.title"
      [errorMessage]="getErrorMessage(foundationBottomRadiusField)"
      [hintError]="foundationInvalid(foundationBottomRadiusField, obligatoryParameters.foundationBottomRadius)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.foundationBottomRadius"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="foundationBottomRadiusField.required" />
    </pearl-form-field>

    <!-- Wind farm water depth -->
    <pearl-form-field
      [small]="true"
      [label]="windFarmWaterDepthField.title"
      [errorMessage]="getErrorMessage(windFarmWaterDepthField)"
      [hintError]="foundationInvalid(windFarmWaterDepthField, obligatoryParameters.windFarmWaterDepth)">
      <input
        type="number"
        matInput
        [(ngModel)]="obligatoryParameters.windFarmWaterDepth"
        (input)="valueChange()"
        autocomplete="off"
        [min]="0"
        [required]="windFarmWaterDepthField.required" />
    </pearl-form-field>
  </fieldset>

  <fieldset
    class="optional-parameters-fields"
    [ngClass]="{ 'optional-parameters-fieldset-rolled': !optionalFieldset.expanded }">
    <legend (click)="optionalFieldset.expanded = !optionalFieldset.expanded">Advanced parameters</legend>
    <div [ngClass]="{ 'optional-parameters-rolled': !optionalFieldset.expanded }">
      <div class="reset-filters-container">
        <pearl-button type="secondary" size="small" (click)="setDefaultOptionalParams()">Reset to default</pearl-button>
      </div>
      @for (optionalField of optionalFieldset.fields; track optionalField.id) {
        <pearl-form-field
          [small]="true"
          [label]="optionalField.title"
          [errorMessage]="getErrorMessage(optionalField)"
          [hintError]="foundationInvalid(optionalField, optionalParameters[optionalField.id])">
          <input
            type="number"
            matInput
            [(ngModel)]="optionalParameters[optionalField.id]"
            (input)="valueChange()"
            autocomplete="off"
            [min]="0" />
        </pearl-form-field>
      }
    </div>
  </fieldset>

  @if (loading) {
    <mat-spinner diameter="20" />
  }

  @if (modelApplied) {
    <fieldset class="calculated-parameters-fieldset">
      <legend>{{ calculatedFieldset.title }}</legend>
      <i>These parameters are calculated by the model:</i>
      @for (calculatedParameterField of calculatedFieldset.fields; track calculatedParameterField.id) {
        <pearl-form-field [small]="true" [label]="calculatedParameterField.title">
          <input
            type="text"
            matInput
            disabled
            [(ngModel)]="calculatedParameters[calculatedParameterField.id]"
            (input)="valueChange()"
            autocomplete="off" />
        </pearl-form-field>
      }
    </fieldset>
  }
</div>
