<div class="close-container">
  <button mat-icon-button class="delete-button" (click)="handleClose()" title="Close">
    <pearl-icon icon="close" class="icon-drop-shadow" [size]="20" />
  </button>
</div>

<div>
  @if (imageSrc()) {
    <img [src]="imageSrc()" class="header-background-image" />
    <img [src]="imageSrc()" class="header-image" />
  }

  <pearl-hero-section
    displayMode="embedded"
    class="title-section"
    [ngClass]="{ 'with-margin': !imageSrc() }"
    [icon]="icon()"
    [title]="title()"
    [subtitle]="subtitle()" />
</div>
