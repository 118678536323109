import { signal, AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, HostBinding,
  OnInit } from '@angular/core';
import { FloatLabelType, MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

import { PearlIconComponent } from '../icons/pearl-icon.component';
import { FormField } from './form-field';

@Component({
  selector: 'pearl-form-field',
  standalone: true,
  styleUrl: './pearl-form-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatFormFieldModule, PearlIconComponent],
  providers: [{
    provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
    useValue: {
      subscriptSizing: 'dynamic',
      appearance: 'outline',
    },
  }],
  templateUrl: './pearl-form-field.component.html',
})
export class PearlFormFieldComponent extends FormField implements OnInit, AfterViewInit {
  @HostBinding('class.readonly')
  override get isReadonly(): boolean {
    return this.ngContentControl.readonly || this.readonly();
  }

  @HostBinding('class.label-floating')
  override get isLabelFloating(): boolean {
    return this.ngContentControl.shouldLabelFloat || this.floatLabel() === 'always';
  }

  @ContentChild(MatSelect, { static: true })
  public readonly ngContentSelect!: MatSelect;

  @ContentChild(MatAutocompleteTrigger, { static: true })
  public readonly ngContentAutocompleteTrigger!: MatAutocompleteTrigger;

  public readonly forceFloatingLabel = signal<FloatLabelType>(null);

  ngOnInit(): void {
    this.matFormField._control = this.ngContentControl;

    this.ngContentControl._isInFormField = true;

    if (this.ngContentSelect) {
      this.ngContentControl._parentFormField = this.matFormField;
    }

    if (this.ngContentAutocompleteTrigger) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      (this.ngContentAutocompleteTrigger as any)._formField = this.matFormField;
    }
  }

  ngAfterViewInit(): void {
    this.forceFloatingLabel.set(this.floatLabel());

    if (this.readonly() && !this.ngContentControl.value) {
      const placeholder = this.ngContentControl._elementRef?.nativeElement?.placeholder;
      if (placeholder) {
        this.ngContentControl._elementRef.nativeElement.placeholder = '-';
        this.forceFloatingLabel.set('always');
      }
    }
  }
}
